export default (entities) => ({
  buyCredits(amount) {
    return entities.Transaction.create(amount);
  },
  updateTransactionAddress(id, newAddressId) {
    const data = { address_id: newAddressId };
    return entities.Transaction.update(id, data);
  },
  confirmPayment(data) {
    return entities.Transaction.confirmPayment(data);
  },
});
