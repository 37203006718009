import React from 'react';
import { Button, T3, Icon } from 'react-ittsu/components';
import { Container } from 'react-ittsu/layout';
import autobind from 'autobind-decorator';
import compose from 'lodash/flowRight';
import { connect } from 'react-redux';

import Link from '~/components/Link';
import Spinner from '~/components/Spinner';
import CartItem from '../../components/CartItem';
import { createTranslate, translate as t } from '~/utils/translation';
import { withUser } from '~/utils/with-user';
import { changeCurrency } from '~/actions/session';
import styles from './styles';

import cartBackground from '../../images/cart-background.jpg';
import emptyState from '../../images/empty-state.svg';


const tt = createTranslate('pods.order.routes.cart');


class Cart extends React.Component {
  componentDidMount() {
    const { sessionUser, changeCurrency } = this.props;
    changeCurrency(sessionUser.currency);
  }

  render() {
    const { cartData } = this.props;
    const { estates, loading } = cartData;
    if (loading) {
      return <Spinner
        size={50}
        containerStyle={{ margin: 'auto' }}
        text={t('pods.order.loading')} />
    }
    if (estates == null) {
      window.location.reload();
      return null;  // Shame on Apollo
    }
    const sortedEstates = estates.sort((a, b) => b.orderCompleted - a.orderCompleted);
    return (
      <div className={styles.cart} style={{ backgroundImage: `url(${cartBackground})` }}>
        <Container className={styles.container}>
          <div className={styles.panel}>
            <T3 className={styles.title}>
              {tt('cart')}
            </T3>
            <div className={styles.info}>
              Swipe left or click to modify projects
            </div>
            <div className={styles.newProject}>
              <Button href="/order/new" className={styles.newProjectButton} linkComponent={Link} category="primary" round>
                <Icon name="plus-bold" bold />
              </Button>
              <div className={styles.help} data-element="help">
                {tt('start_project')}
              </div>
            </div>
            <div className={styles.items}>
              {sortedEstates.map((estate) => (
                <div key={estate.id} className={styles.item}>
                  <CartItem
                    estate={estate}
                    onClickOrder={estate.orderCompleted ? () => this._handleClickOrder(estate.id) : null}
                    onClickEdit={() => this._handleClickEdit(estate.id)}
                    onClickDelete={(e) => this._handleClickDelete(estate.id, e)} />
                </div>
              ))}
              {do{
                if (sortedEstates.length === 0) {
                  <div className={styles.emptyState}>
                    <div className={styles.note}>
                      {tt('no_projects')}
                    </div>
                    <img className={styles.emptyImage} src={emptyState} />
                  </div>
                }
              }}
            </div>
          </div>
          <Link to="/">
            <div className={styles.continueShopping}>
              <Icon name="caret-left-bold" />
              <span>{tt('back_to_previous_page')}</span>
            </div>
          </Link>
        </Container>
      </div>
    );
  }

  @autobind
  _handleClickEdit(id) {
    const { history } = this.props;
    history.push(`new/${id}`);
  }

  @autobind
  _handleClickOrder(id) {
    const { history } = this.props;
    history.push(`recap/${id}`);
  }

  @autobind
  async _handleClickDelete(id, e) {
    e.stopPropagation();
    const { deleteEstate } = this.props;
    const { deleteEstate: deletedEstate } = await deleteEstate({ variables: { estateId: id } });
    return deletedEstate;
  }
}


const mapDispatchToProps = {
  changeCurrency,
};


export default compose(
  withUser(),
  connect(null, mapDispatchToProps),
)(Cart);
