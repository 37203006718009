import gql from 'fraql';

const ClientUser = {
  typeName: 'ClientUser',
  fields: {
    fullName: {
      dependencies: gql`
        fragment _ on ClientUser {
          firstName
          lastName
        }
      `,
      resolver: (user) => `${user.firstName} ${user.lastName}`,
    },
  },
};

export default ClientUser;
