import Cookies from 'js-cookie';
import urlJoin from 'url-join';

import { Session } from '~/types';

export const CLIENT_URL = urlJoin(process.env.API_HOST, '/api/frontoffice/v1/client');

export function writeSession(session: Session, rememberMe = false): Session {
  if (rememberMe) {
    Cookies.remove('session');
    localStorage.setItem('session', JSON.stringify(session));
  } else {
    localStorage.removeItem('session');
    Cookies.set('session', JSON.stringify(session));
  }
  return session;
}

export async function fetchSessionData(token: string): Promise<Session | undefined> {
  const response = await fetch(CLIENT_URL, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });

  try {
    const data = await response.json();
    return {
      authToken: token,
      currency: data.client.currency.code,
      locale: data.client.locale,
      type: data.client.type,
      user: data.client.id,
    };
  } catch {
    return;
  }
}
