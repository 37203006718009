import { combineReducers } from 'redux';

import * as UIPartsActions from 'actions/ui-parts';


function showFooter(state=true, action) {
  switch (action.type) {
    case UIPartsActions.SHOW_FOOTER: {
      return true;
    }
    case UIPartsActions.HIDE_FOOTER: {
      return false;
    }
    default: {
      return state;
    }
  }
}


function useFullWidthNavbar(state=false, action) {
  switch (action.type) {
    case UIPartsActions.USE_FULL_WIDTH_NAVBAR: {
      return true;
    }
    case UIPartsActions.USE_NAVBAR_WITH_CONTAINER: {
      return false;
    }
    default: {
      return state;
    }
  }
}


export default combineReducers({
  showFooter,
  useFullWidthNavbar,
});
