import { useRef, useState } from 'react';

/**
 * Fires the callback after ms have passed without
 * any state update.
 */
export function useDebouncedState<T>(initial: T, callback: (value: T) => void, ms: number) {
  const [value, setValue] = useState(initial);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const set = (value: T): void => {
    setValue(value);
    clearTimeout(timeoutRef.current!);
    timeoutRef.current = setTimeout(() => callback(value), ms);
  };

  return [value, set] as const;
}
