import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import isAuthenticated from '~/utils/is-authenticated';
import Route, { Switch } from '~/components/Route';
import Invoices from './Invoices';
import SalesOrders from './SalesOrders';
import Orders from './Orders';
import EditSalesOrder from './EditSalesOrder';
import BuyCredits from './BuyCredits';
import Transaction from './Transaction';
import AfterOrder from './AfterOrder';

import styles from 'pods/transactions/styles/routes/transactions';


class Transactions extends React.Component {
  render() {
    const { match } = this.props;
    return (
      <div className={styles.transactions}>
        <Switch>
          <Route path={`${match.url}/new`} component={BuyCredits} />
          <Route path={`${match.url}/new`} component={BuyCredits} />
          <Route path={`${match.url}/orders`} component={Orders} />
          <Route path={`${match.url}/sales-order/:id`} component={EditSalesOrder} />
          <Route path={`${match.url}/sales-order`} component={SalesOrders} />
          <Route path={`${match.url}/after-order/:id`} component={AfterOrder} />
          <Route path={`${match.url}/:id`} component={Transaction} />
          <Route path={match.url} exact component={Invoices} />
        </Switch>
      </div>
    );
  }
}


export default compose(
  withRouter,
  isAuthenticated(),
)(Transactions);
