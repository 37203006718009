import get from 'lodash/get';

import * as LoadingActions from './actions';


export default function createLoadingMiddleware() {
  return (store) => (next) => (action) => {
    const isLoadingAction = get(action, 'meta.loading.isLoadingAction', false);
    if ( ! isLoadingAction) {
      return next(action);
    }

    const { startLoading, stopLoading, key } = action.meta.loading;

    if (startLoading) {
      store.dispatch(LoadingActions.startLoading(key));
      next(action);
    }
    else if (stopLoading) {
      next(action);
      store.dispatch(LoadingActions.stopLoading(key));
    }
  };
}
