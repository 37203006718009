import { fork } from 'redux-saga/effects';

import studioSaga from 'pods/studio/sagas';


export default function* root() {
  try {
    yield fork(studioSaga);
  }
  catch (err) {
    console.error('SAGA ERRR');
  }
}
