import { combineEpics } from 'redux-observable';

import { podsEpics } from 'pods';
import { handleEpicErrors } from 'utils/errors';

import sessionEpic from './session';
import invitationEpic from './invitation';
import publicProjectEpic from './public-project';
import testErrors from './test-errors';


const childrenEpics = combineEpics(
  ...podsEpics,
  sessionEpic,
  invitationEpic,
  publicProjectEpic,
  testErrors,
);


export default function rootEpic(action$, store, extra) {
  return childrenEpics(action$, store, extra).catch(handleEpicErrors);
}
