import React from 'react';
import sv from '@drawbotics/style-vars';
import autobind from 'autobind-decorator';
import hoistNonReactStatic from 'hoist-non-react-statics';


const { Provider, Consumer } = React.createContext(null);


const mediaQueries = [
  sv.phoneS,
  sv.phone,
  sv.phoneXl,
  sv.ipad,
  sv.ipadLandscape,
  sv.desktopS,
  sv.greaterThanMinimumWidth,
];


export class MediaProvider extends React.PureComponent {
  state = {}

  componentDidMount() {
    window.addEventListener('resize', this._handleWindowResize);
    this._handleWindowResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._handleWindowResize);
  }

  @autobind
  _handleWindowResize() {
    const query = mediaQueries.find((query) => window.matchMedia(query).matches);
    this.setState({ query });
  }

  render() {
    const { children } = this.props;
    const { query } = this.state;
    const mediaSize = { value: query };
    mediaSize.isMobile = [sv.phoneXl, sv.phone, sv.phoneS].includes(query);
    mediaSize.isTablet = [sv.ipadLandscape, sv.ipad].includes(query) || mediaSize.isMobile;
    return (
      <Provider value={mediaSize}>
        {children}
      </Provider>
    );
  }
}


export function withMedia() {
  return (Component) => {
    class WithMedia extends React.Component {
      render() {
        return (
          <Consumer>
            {(mediaSize) => <Component {...this.props} mediaSize={mediaSize} />}
          </Consumer>
        );
      }
    }
    // NOTE: as seen here https://reactjs.org/docs/higher-order-components.html#static-methods-must-be-copied-over
    hoistNonReactStatic(WithMedia, Component);
    return WithMedia;
  }
}
