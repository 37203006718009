import { createEntities, deleteEntities, updateEntityId, updateEntities } from 'entman';
import v4 from 'uuid/v4';

import schemas from 'schemas';


export const CREATE_ADDRESS = 'CREATE_ADDRESS';

export function createAddress(address) {
  return createEntities(schemas.Address, 'payload.address', {
    type: CREATE_ADDRESS,
    payload: { address: { ...address, id: v4() } },
  });
}


export const CREATE_ADDRESS_SUCCESS = 'CREATE_ADDRESS_SUCCESS';

export function createAddressSuccess(oldId, address) {
  return updateEntityId(schemas.Address, oldId, address.id, {
    type: CREATE_ADDRESS_SUCCESS,
    payload: {
      address,
    },
  });
}


export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';

export function updateAddress(address, noServer) {
  const organization = (address.organization && address.organization.id) ? address.organization.id : address.organization;
  return updateEntities(schemas.Address, address.id, 'payload.address', {
    type: UPDATE_ADDRESS,
    payload: {
      address: {
        ...address,
        organization,
      },
      noServer,
    },
  });
}

export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';

export function updateAddressSuccess(address) {
  return {
    type: UPDATE_ADDRESS_SUCCESS,
    payload: {
      address,
    },
  };
}


export const UPDATE_ADDRESS_FAIL = 'UPDATE_ADDRESS_FAIL';

export function updateAddressFail(error) {
  return {
    type: UPDATE_ADDRESS_FAIL,
    meta: { error },
  };
}


export const CREATE_ADDRESS_FAIL = 'CREATE_ADDRESS_FAIL';

export function createAddressFail(error, address) {
  return {
    type: CREATE_ADDRESS_FAIL,
    payload: { address },
    meta: { error },
  };
}


export const DELETE_ADDRESS = 'DELETE_ADDRESS';

export function deleteAddress(address, noServer) {
  return deleteEntities(schemas.Address, address.id, {
    type: DELETE_ADDRESS,
    payload: { address, noServer },
  });
}


export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS';

export function deleteAddressSuccess() {
  return {
    type: DELETE_ADDRESS_SUCCESS,
  };
}


export const DELETE_ADDRESS_FAIL = 'DELETE_ADDRESS_FAIL';

export function deleteAddressFail(error) {
  return {
    type: DELETE_ADDRESS_FAIL,
    meta: { error },
  };
}
