import Rx from 'rxjs-legacy';
import { combineEpics } from 'redux-observable';

import * as OrganizationActions from 'pods/profile/actions/organization';
import * as AlertsActions from 'actions/alerts';
import { translate as t } from 'utils/translation';
import { formatErrorMessage, wrapError } from 'utils/errors';


function updateOrganization(action$, store, extra) {
  const { api } = extra;
  return action$
    .filter((action) => action.type === OrganizationActions.UPDATE_ORGANIZATION)
    .flatMap((action) => {
      const { organization } = action.payload;
      return Rx.Observable.fromPromise(api.mutations.updateOrganization(organization))
        .flatMap((updatedOrganization) => Rx.Observable.concat(
          Rx.Observable.of(OrganizationActions.updateOrganizationSuccess(updatedOrganization)),
          Rx.Observable.of(AlertsActions.showAlert(OrganizationActions.UPDATE_ORGANIZATION_SUCCESS, 'success', t('containers.alerts.profile.organization_created')))
        ))
        .catch((err) => {
          const message = formatErrorMessage(err.info.body.message, 'containers.alerts.profile.errors');
          return Rx.Observable.concat(
            Rx.Observable.of(AlertsActions.showAlert(OrganizationActions.UPDATE_ORGANIZATION_FAIL, 'error', message)),
            Rx.Observable.of(OrganizationActions.updateOrganizationFail(wrapError(err))),
          );
        });
    });
}


export default combineEpics(
  updateOrganization,
);
