import React, { useState } from 'react';
import { Input, Label, InputGroup, FormGroup, ErrorLabel } from 'react-ittsu/forms';
import { Icon, Button } from 'react-ittsu/components';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';
import get from 'lodash/get';

import { createTranslate } from '~/utils/translation';


const t = createTranslate('pods.order.components.questions_generator');


const styles = {
  emailSelect: css`
    width: 400px;
  `,
  emails: css`
    margin-bottom: ${sv.baseMargin};
  `,
  emailContainer: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${sv.textPrimaryDark};
    margin-bottom: ${sv.baseMarginSmall};
  `,
  email: css`
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  remove: css`
    height: ${sv.baseMargin};
    width: ${sv.baseMargin};
    border-radius: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${sv.brandRed};

    &:hover {
      background: ${sv.grey300};
      cursor: pointer;
    }
  `,
};


const Email = ({
  value,
  onClickRemove,
}) => {
  return (
    <div className={styles.emailContainer}>
      <div className={styles.email}>{value}</div>
      <div className={styles.remove} onClick={onClickRemove}>
        <Icon name="close" bold />
      </div>
    </div>
  );
};


const EmailSelect = ({ modifyAnswer, resultKey, answers }) => {
  const [ emails, setEmails ] = useState(get(answers, resultKey, []) || []);
  const [ email, setEmail ] = useState('');
  const [ error, setError ] = useState('');

  const handleAddEmail = () => {
    const isValid = new RegExp(/\S+@\S+\.\S+/).test(email);

    if (isValid) {
      const updatedEmails = [ ...emails, email ];
      modifyAnswer(resultKey, updatedEmails);
      setEmails(updatedEmails);
      setEmail('');
      setError('');
    }
    else {
      setError(t('invalid_address'));
    }
  };

  const handleRemoveEmail = (email) => {
    const updatedEmails = emails.filter((e) => e !== email);
    setEmails(updatedEmails);
    modifyAnswer(resultKey, updatedEmails);
  };

  return (
    <div className={styles.emailSelect}>
      <Label>{t('recipient_emails')}</Label>
      <div className={styles.emails}>
        {emails.map((email) => (
          <Email
            key={email}
            value={email}
            onClickRemove={() => handleRemoveEmail(email)} />
        ))}
      </div>
      <div className={styles.addEmail}>
        <FormGroup>
          <InputGroup
            input={
              <Input
                error={error}
                value={email}
                onChange={(v) => setEmail(v)}
                placeholder="info@agent.com" />
            }
            addon={
              <Button onClick={handleAddEmail}>
                {t('add_email')}
              </Button>
            } />
          {do {
            if (error != null) {
              <ErrorLabel text={error} />
            }
          }}
        </FormGroup>
      </div>
    </div>
  );
};


export default EmailSelect;