import React from 'react';
import sv from '@drawbotics/style-vars';
import { css } from 'emotion';


const styles = {
  sectionTitle: css`
    color: ${sv.textTertiaryDark};
    font-size: 0.8rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    font-family: ${sv.baseFontFamilyAlt};
    margin-top: ${sv.baseMargin};
    margin-bottom: ${sv.baseMarginSmall};
  `,
};


const SectionTitle = ({ children }) => {
  return (
    <div className={styles.sectionTitle}>
      {children}
    </div>
  );
};


export default SectionTitle;
