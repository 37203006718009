import React, { Fragment } from 'react';
import { Icon, Tooltip, showTooltip, hideTooltip, Link } from 'react-ittsu/components';
import gql from 'fraql';
import autobind from 'autobind-decorator';
import { css, cx } from 'emotion';
import sv from '@drawbotics/style-vars';
import compose from 'lodash/flowRight';
import dayjs from 'dayjs';

import { getItemsByService } from '../utils';
import { withMedia } from '~/utils/media-provider';
import { withUser } from '~/utils/with-user';
import { createTranslate, translate as t } from '~/utils/translation';
import SummaryButton from './SummaryButton';
import DiscountModal from './DiscountModal';
import PriceLabel from './PriceLabel';


const tt = createTranslate('pods.order.components.estate_sidebar');


const styles = {
  estateSidebar: css`
    width: 100%;
    max-width: 300px;
    background: ${sv.white};
    padding: ${sv.basePadding};
    margin: auto;
    margin-top: ${sv.baseMargin};
    margin-bottom: ${sv.baseMargin};
    color: ${sv.textPrimaryDark};
    border-radius: ${sv.baseBorderRadius};
    position: relative;
    z-index: 9999;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.05);

    @media ${sv.ipadLandscape} {
      margin: ${sv.baseMargin} ${sv.baseMarginSmall};
      width: calc(100% - ${sv.baseMargin});
    }

    @media ${sv.ipad} {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      max-width: none;
      z-index: 999999;
      transform: translateY(100%);
      pointer-events: none;
      transition: all ${sv.baseTransitionTime} ${sv.bouncyTransitionCurve};
      box-shadow: 0px 40px 0px ${sv.white};
      display: flex;
      flex-direction: column;
      max-height: calc(100vh - ${sv.baseMargin} * 3);
      margin: 0 auto;
    }
  `,
  visibleSidebar: css`
    pointer-events: auto !important;
    transform: translateY(0%) !important;
  `,
  header: css`
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-bottom: ${sv.baseMargin};
    color: ${sv.textPrimaryDark};
  `,
  headerIcon: css`
    margin-right: ${sv.baseMarginSmall};
    font-size: 1.4rem;
    color: ${sv.brandPrimary};
  `,
  itemsGroup: css`
    @media ${sv.ipad} {
      overflow-y: scroll;
      background:
    		linear-gradient(white 30%, rgba(255,255,255,0)),
    		linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,
    		radial-gradient(50% 0, farthest-side, rgba(0,0,0,.2), rgba(0,0,0,0)),
    		radial-gradient(50% 100%, farthest-side, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
    	background:
    		linear-gradient(white 30%, rgba(255,255,255,0)),
    		linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,
    		radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)),
    		radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
    	background-repeat: no-repeat;
    	background-color: white;
    	background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
      background-attachment: local, local, scroll, scroll;
    }
  `,
  abstractItems: css`
    margin-top: ${sv.baseMarginSmall};
    border-top: 1px solid ${sv.grey300};
  `,
  item: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: ${sv.baseMarginSmall} 0;
  `,
  labelWithTooltip: css`
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    > i {
      margin-left: 5px;
      font-size: 1.1rem;
      color: ${sv.textSecondaryDark};
    }
  `,
  total: css`
    font-weight: bold;
    font-size: 1.1rem;
  `,
  goBack: css`
    margin-top: ${sv.baseMargin};
    text-align: center;

    button {
      width: 100%;
    }

    @media ${sv.ipad} {
      text-align: right;

      button {
        width: auto;
      }
    }

    @media ${sv.phoneXl} {
      margin-top: 5px;
      text-align: center;

      button {
        width: 100%;
      }
    }
  `,
  footer: css`
    margin: calc(${sv.baseMargin} * -1);
    margin-top: ${sv.baseMargin};
    background: ${sv.grey50};
    padding: ${sv.basePaddingSmall} ${sv.basePadding};
  `,
  backdrop: css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.8);
    opacity: 0;
    transition: all ${sv.baseTransitionTimeShort} ease-in-out;
  `,
  backdropVisible: css`
    opacity: 1;
  `,
  close: css`
    position: absolute;
    top: ${sv.baseMargin};
    right: ${sv.baseMargin};
    color: ${sv.textTertiaryDark};

    &:active {
      color: ${sv.textPrimaryDark};
    }
  `,
  summaryButton: css`
    position: fixed;
    left: ${sv.baseMarginSmall};
    bottom: ${sv.baseMarginSmall};
    z-index: 99999;
  `,
  mobileMenuBackground: css`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(2px);
    box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.1);
    height: 68px;
  `,
  itemValue: css`
    font-style: normal;
    margin-left: ${sv.baseMargin};
  `,
  extraItems: css`
  `,
  small: css`
    justify-content: flex-end;

    & [data-element="label"] {
      font-size: 0.9rem;
    }

    & [data-element="value"] {
      display: none;
    }
  `,
  discountCTA: css`
    font-size: 0.9rem;
    text-align: right;

    i {
      margin-right: 0;
    }
  `,
};


const Item = ({
  label,
  value,
}) => {
  return (
    <div className={styles.item}>
      <div className={styles.itemLabel}>
        {label}
      </div>
      <div className={styles.itemValue}>
        {value}
      </div>
    </div>
  );
};


class ExtraItem extends React.Component {
  state = {
    tooltipState: {},
  }

  render() {
    const { tooltipState } = this.state;
    const { abstract, label, value, tip, small } = this.props;
    const text = tip ? tip : (abstract ? tt('added_because_required', { label }) : tt('added_as_option', { label }));
    return (
      <div className={cx(styles.item, { [styles.small]: small })}>
        <div data-element="label" className={styles.labelWithTooltip} onMouseEnter={this._handleShowTooltip} onMouseLeave={this._handleHideTooltip}>
          {label}
          <i className="ion-ios-information-outline" />
        </div>
        <div className={styles.itemValue} data-element="value">
          {value}
        </div>
        <Tooltip
          text={true}
          label="tooltip"
          position="left"
          visible={tooltipState?.visible}
          parentRect={tooltipState?.parentRect}>
          {text}
        </Tooltip>
      </div>
    );
  }

  @autobind
  _handleShowTooltip(e) {
    e.preventDefault();
    const { tooltipState } = this.state;
    this.setState({ tooltipState: showTooltip(tooltipState, e) });
  }

  @autobind
  _handleHideTooltip() {
    const { tooltipState } = this.state;
    this.setState({ tooltipState: hideTooltip(tooltipState) });
  }
}


class EstateSidebar extends React.Component {
  static fragments = {
    Item: () => gql`
      fragment _ on Item {
        id
        price @client(type: Item)
        basePrice @client(type: Item)
        product {
          id @client(type: Product)
          custom
          modelingRequired
        }
        service {
          id @client(type: ProductService)
          abstract
        }
        estate {
          surface {
            value
            unit
          }
          delivery
        }
      }
    `,
    Estate: () => gql`
      fragment _ on Estate {
        id
        delivery
        items {
          ${EstateSidebar.fragments.Item()}
        }
        baseModelingPrice @client(type: Estate)
        modelingPrice @client(type: Estate)
        modelingRequired
        discountedPrice {
          value
          currency
        }
        discount {
          availability
          minAmount
          amount
          rate
          discountType
        }
      }
    `,
  }

  state = {
    visible: false,
    discountModalVisible: false,
  }

  render() {
    const { visible, discountModalVisible } = this.state;
    const {
      estate,
      onClickAction,
      mediaSize,
      mobileWithBackground,
      mobileButtonText,
      isMobileFinalAction,
      sessionUser,
    } = this.props;
    if ( ! estate) {
      return null;
    }
    const itemsByService = getItemsByService(estate);
    const concreteServices = Object.keys(itemsByService).filter((serviceKey) => ! itemsByService[serviceKey][0].service.abstract);
    const extraServices = Object.keys(itemsByService).filter((serviceKey) => itemsByService[serviceKey][0].service.abstract);
    const hasDiscount = estate.discountedPrice != null && estate.discount != null;
    const estatePrice = this._getEstatePrice();
    return (
      <Fragment>
        {do{
          if (mediaSize.isMobile || mediaSize.value === sv.ipad) {
            <Fragment>
              <div className={cx(styles.backdrop, { [styles.backdropVisible]: visible })} />
              <div className={styles.summaryButton}>
                <SummaryButton
                  count={estate.items.length}
                  amount={sessionUser.addCurrency(estatePrice)}
                  label={mobileButtonText}
                  isPrimary={isMobileFinalAction}
                  onClick={isMobileFinalAction ? onClickAction : () => this.setState({ visible: true })} />
              </div>
              {do{
                if (mobileWithBackground) {
                  <div className={styles.mobileMenuBackground} />
                }
              }}
            </Fragment>
          }
        }}
        <div className={cx(styles.estateSidebar, { [styles.visibleSidebar]: visible })}>
          {do{
            if (mediaSize.isMobile || mediaSize.value === sv.ipad) {
              <div className={styles.close} onClick={() => this.setState({ visible: false })}>
                <Icon name="close-bold" bold size="lg" />
              </div>
            }
          }}
          <div className={styles.header}>
            <div className={styles.headerIcon}>
              <Icon name="quick-services" size="2x" />
            </div>
            {tt('project_recap')}
          </div>
          <div className={styles.itemsGroup}>
            <div className={styles.items}>
              {concreteServices.map((serviceName) => (
                <Item
                  key={serviceName}
                  label={t(`services.${itemsByService[serviceName][0].service.id}`)}
                  value={sessionUser.addCurrency(
                    itemsByService[serviceName].reduce((t, i) => parseInt(i.basePrice) + t, 0)
                  )} />
              ))}
            </div>
            {do{
              if (extraServices.length > 0) {
                <div className={styles.items}>
                  {extraServices.map((serviceName) => (
                    <ExtraItem
                      key={serviceName}
                      abstract={itemsByService[serviceName][0].service.abstract}
                      label={t(`services.${itemsByService[serviceName][0].service.id}`)}
                      value={sessionUser.addCurrency(
                        itemsByService[serviceName].reduce((t, i) => parseInt(i.basePrice) + t, 0)
                      )} />
                  ))}
                </div>
              }
            }}
            {do{
              if (estate.modelingRequired) {
                <div className={cx(styles.abstractItems, styles.extraItems)} style={{ fontStyle: 'italic' }}>
                  <ExtraItem
                    abstract={true}
                    label={tt('extra_cost', { cost_name: t('services.modeling') })}
                    value={sessionUser.addCurrency(estate.baseModelingPrice)} />
                </div>
              }
            }}
            {do{
              if (estate.delivery === 'relaxed' || estate.delivery === 'express') {
                <div style={{ fontStyle: 'italic' }}>
                  <ExtraItem
                    abstract={true}
                    tip={t('pods.order.routes.estate_delivery.delivery_applied')}
                    label={t('pods.order.routes.estate_delivery.delivery', {
                      delivery_type: t(`pods.order.routes.estate_delivery.${estate.delivery}`),
                    })}
                    value={estate.delivery === 'express' ? '+50%' : '-10%'} />
                </div>
              }
            }}
          </div>
          <div className={styles.footer}>
            <div className={styles.total}>
              <ExtraItem
                tip={tt('taxes_excluded')}
                label={tt('total')}
                value={
                  <PriceLabel
                    amount={estatePrice}
                    discountedAmount={hasDiscount ? estate.discountedPrice?.value : null} />
                } />
              {do{
                if (estate.discountedPrice && estate.discount) {
                  <ExtraItem
                    small
                    tip={t('pods.order.components.discount_modal.discount_terms', {
                      date: dayjs(estate.discount.availability).format('DD/MM/YYYY'),
                      minimum_price: sessionUser.addCurrency(estate.discount.minAmount),
                    })}
                    label={do {
                      if (estate.discount.discountType === 'percentage_discount') {
                        t('pods.order.components.discount_modal.discount', { rate: Math.round(estate.discount.rate * 100) })
                      }
                      else if (estate.discount.discountType === 'amount_discount') {
                        t('pods.order.components.discount_modal.discount_amount', {
                          amount: sessionUser.addCurrency(estate.discount.amount),
                        })
                      }
                    }} />
                }
                else if (estate.discount) {
                  <div className={styles.discountCTA}>
                    <Link
                      onClick={() => this.setState({ discountModalVisible: true })}
                      category="classic">
                      Discount available <i className="ion-ios-information" />
                    </Link>
                    <DiscountModal
                      visible={discountModalVisible}
                      onClickClose={() => this.setState({ discountModalVisible: false })}
                      date={dayjs(estate.discount.availability).format('DD/MM/YYYY')}
                      rate={Math.round(estate.discount.rate * 100)}
                      amount={sessionUser.addCurrency(estate.discount.amount)}
                      discountType={estate.discount.discountType}
                      minAmount={sessionUser.addCurrency(estate.discount.minAmount)} />
                  </div>
                }
              }}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  _getEstatePrice() {
    const { estate } = this.props;
    return estate.items.reduce((memo, i) => i.price + memo, 0) + estate.modelingPrice;
  }
}


export default compose(
  withMedia(),
  withUser(),
)(EstateSidebar);
