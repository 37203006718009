import { Add, Switch, Conditional, ForEach, RelativeAdd } from 'drawbotics-pricer';


const description = {
  Base: [
    Switch('estate.projectType').is({
      'residential': [
        Add({ EUR: 1080, USD: 1400, GBP: 1080 }),
        ForEach('details.numberOfPages').minimum(4).do([
          Add({ EUR: 100, USD: 120, GBP: 100 }),
        ]),
        Conditional('details.copywriting').equals(true).then([
          Add({ EUR: 200, USD: 300, GBP: 200 }),
          ForEach('details.numberOfLanguages').do([
            ForEach('details.numberOfPages').do([
              Add({ EUR: 80, USD: 120, GBP: 80 }),
            ]),
          ]),
        ]),
        ForEach('details.numberOfLanguages').threshold(1).do([
          RelativeAdd(0.15).from('Base'),
        ]),
      ],
      'commercial': [
        Add({ EUR: 1580, USD: 2000, GBP: 1580 }),
        ForEach('details.numberOfPages').minimum(4).do([
          Add({ EUR: 120, USD: 150, GBP: 120 }),
        ]),
        Conditional('details.copywriting').equals(true).then([
          Add({ EUR: 300, USD: 400, GBP: 300 }),
          ForEach('details.numberOfLanguages').do([
            ForEach('details.numberOfPages').do([
              Add({ EUR: 100, USD: 150, GBP: 100 }),
            ]),
          ]),
        ]),
        ForEach('details.numberOfLanguages').threshold(1).do([
          RelativeAdd(0.15).from('Base'),
        ]),
      ],
    }),
  ],
  Delivery: [
    Switch('estate.delivery').is({
      'relaxed': RelativeAdd(-0.1).from('Base'),
      'standard': RelativeAdd(0).from('Base'),
      'express': RelativeAdd(0.5).from('Base'),
    })
  ],
};


export default description;
