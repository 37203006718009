export default (request, entities, translators) => ({
  findAll(ids) {
    return Promise.all(ids.map(entities.ProjectItem.find));
  },
  find(id) {
    return request('GET', `items/${id}`)
      .then(translators.ProjectItem.fromServer);
  },
  archive(id) {
    return request('POST', `items/${id}/archives`);
  },
  reopen(id) {
    return request('POST', `items/${id}/reopenings`)
      .then(translators.ProjectItem.fromServer);
  },
  generateLink(id) {
    return request('POST', `items/${id}/public-links`)
      .then((response) => ({ ...translators.ProjectItem.fromServer(response), message: response.message }));
  },
  revokeLink(id) {
    return request('DELETE', `items/${id}/public-links`);
  },
  findPublic(id) {
    return request('GET', `public/items/${id}`)
      .then(translators.ProjectItem.fromServer);
  },
  resolveProblem(id, problem) {
    const data = translators.Problem.toServer(problem);
    return request('POST', `items/${id}/problems`, { item: data });
  },
  cancel(id) {
    return request('DELETE', `items/${id}`);
  },
});
