import React from 'react';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';
import PropTypes from 'prop-types';

import Feature from '../Feature';
import Switch from 'components/Switch';
import Counter from 'components/Counter';


const styles = {
  stripe: css`
    background: ${sv.grey50};
    border: 1px solid ${sv.grey300};
    border-radius: ${sv.baseBorderRadius};
    padding: ${sv.basePaddingSmall};
    display: flex;
  `,
  stripeGroup: css`
    & [data-element="stripe"] {
      border-radius: 0;
      border-bottom: 0;

      &:first-child {
        border-top-left-radius: ${sv.baseBorderRadius};
        border-top-right-radius: ${sv.baseBorderRadius};
        border-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: ${sv.baseBorderRadius};
        border-bottom-right-radius: ${sv.baseBorderRadius};
        border-bottom: 1px solid ${sv.grey300};

        &:not(&:nth-child(2)) {
          border-top: 0;
        }
      }

      &:only-child {
        border: 1px solid ${sv.grey300} !important;
        border-radius: ${sv.baseBorderRadius};
      }
    }
  `,
  leftColumn: css`
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
  `,
  rightColumn: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  title: css`
    font-weight: 600;
    margin-right: 10px;
    color: ${sv.textPrimaryDark};
    font-size: 0.95em;
  `,
  subtitle: css`
    color: ${sv.textSecondaryDark};
    font-size: 0.9em;
  `,
  info: css`
    display: flex;
    align-items: center;
  `,
  feature: css`
    margin-top: ${sv.baseMarginSmall};

    &:first-child {
      margin-top: 0;
    }
  `,
}


const Stripe = ({
  title,
  features=[],
  component,
  subtitle,
  value,
  onChange,
  name,
  disabled,
}) => {
  return (
    <div className={styles.stripe} data-element="stripe">
      <div className={styles.leftColumn}>
        {do{
          if (title) {
            <div className={styles.info}>
              <div className={styles.title}>
                {title}
              </div>
              {do {
                if (subtitle) {
                  <div className={styles.subtitle}>
                    {subtitle}
                  </div>
                }
              }}
            </div>
          }
        }}
        {features.map((feature, i) => (
          <div key={i} className={styles.feature}>
            <Feature {...feature} />
          </div>
        ))}
      </div>
      <div className={styles.rightColumn}>
        {do{
          if (component === 'counter') {
            <Counter value={value} onChange={onChange} name={name} disabled={disabled} />
          }
          else if (component === 'switch') {
            <Switch value={value} onChange={onChange} name={name} disabled={disabled} />
          }
        }}
      </div>
    </div>
  );
};


Stripe.propTypes = {
  component: PropTypes.oneOf(['counter', 'switch']),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  value: PropTypes.oneOfType([ PropTypes.number, PropTypes.bool ]),
  onChange: PropTypes.func,
  features: PropTypes.array,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};


export const StripeGroup = ({ children }) => {
  return (
    <div className={styles.stripeGroup}>
      {children}
    </div>
  );
}


export default Stripe;
