const k = (key) => `vr_headset.briefing.${key}`;

const residential = {
  1: {
    type: 'section',
    filter: {
      type: 'service',
      value: 'brandId',
    },
    components: [
      {
        type: 'component',
        label: k('branding'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('branding_selection'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'SingleSelect',
        key: 'brandId',
        keyLabel: k('keys.brand_id'),
        required: 'all',
        values: [
          {
            image: require('../images/shared/company-branding.jpg'),
            value: 'company',
            label: k('company_branding'),
            meta: {
              type: 'meta',
              noToggle: true,
              components: [
                {
                  type: 'question-component',
                  label: k('upload_company_files'),
                  component: 'FileUploader',
                  key: 'brandIdAttachments',
                  keyLabel: k('keys.brand_id_attachments'),
                  required: 'all',
                },
              ],
            },
          }, {
            image: require('../images/shared/project-branding.jpg'),
            value: 'project',
            label: k('project_branding'),
            meta: {
              type: 'meta',
              condition: {
                required: 'one',
                conditions: [{
                  type: 'service',
                  value: 'brandId',
                }],
              },
              components: [
                {
                  type: 'question-component',
                  label: k('upload_project_files'),
                  component: 'FileUploader',
                  key: 'brandIdAttachments',
                  keyLabel: k('keys.brand_id_attachments'),
                  required: 'all',
                },
              ],
            },
          },
        ],
      },
    ],
  },
  2: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'shared.additional_info',
        component: 'Title',
      }, {
        type: 'component',
        label: 'shared.anything_to_add',
        component: 'Note',
      }, {
        type: 'question-component',
        label: 'shared.additional_comments',
        component: 'Input',
        key: 'otherInfo',
        keyLabel: 'shared.keys.other_info',
      }, {
        type: 'question-component',
        label: 'shared.upload_files',
        component: 'FileUploader',
        key: 'otherInfoAttachments',
        keyLabel: 'shared.keys.other_info_attachments',
      },
    ],
  },
};


export default { residential };
