import { combineReducers } from 'redux';
import { reducer as loading } from 'loading-manager';

import alerts from './alerts';
import modals from './modals';
import parts from './parts';
import errors from './errors';
import session from './session';


export default combineReducers({
  alerts,
  loading,
  modals,
  parts,
  errors,
  session,
});
