export default (entities) => ({
  archiveProjectItem(id) {
    return entities.ProjectItem.archive(id);
  },
  reopenProjectItem(id) {
    return entities.ProjectItem.reopen(id);
  },
  generateLink(id) {
    return entities.ProjectItem.generateLink(id);
  },
  revokeLink(id) {
    return entities.ProjectItem.revokeLink(id);
  },
  resolveProblem(id, problem) {
    return entities.ProjectItem.resolveProblem(id, problem);
  },
  cancelProjectItem(id) {
    return entities.ProjectItem.cancel(id);
  },
});
