import React from 'react';
import autobind from 'autobind-decorator';
import { Grid, Cell } from 'react-ittsu/layout';
import gql from 'fraql';

import BaseServiceModal from '../BaseServiceModal';
import Paragraph from '../Paragraph';
import SectionTitle from '../SectionTitle';
import { ProductCard } from '../../forms';
import { priceItem, applyPrice } from '../../../utils/pricing';
import { specsToItems, itemsToSpecs } from '~/pods/order/utils/services/specs-to-items';
import applyChanges from '~/pods/order/utils/services/specs-to-items/apply-changes';
import { getItemsByService, merge, getFeaturesForProduct, openIntercom } from '~/pods/order/utils';
import { createTranslate, translate as t } from '~/utils/translation';
import { EstateEnums } from '~/pods/order/utils/estate-enums';

import serviceImages from '~/pods/order/images/services';


const tt = createTranslate('pods.order.services.panorama_360.order');
const tm = createTranslate('pods.order.components.service_modal');


class Panorama360 extends React.Component {

  static fragments = {
    Item: gql`
      fragment _ on Item {
        id
        product {
          id @client(type: Product)
          custom
          features {
            content
            locale
          }
        }
      }
    `,
  }

  state = {
    custom: null,
    specs: {
      standard: false,
    },
    price: undefined,
  };

  componentDidUpdate(prevProps) {
    const { service, estate } = this.props;
    const { service: prevService } = prevProps;
    if (service?.id === 'panorama360' && ! prevService) {
      const specs = itemsToSpecs({ estate, service });
      const items = getItemsByService(estate).panorama360 ?? [];
      const customItem = items.find((item) => item.product.custom);
      const price = items != null ? items.reduce((memo, i) => memo + priceItem(i, this.props.sessionUser.currency.code, true), 0) : 0;
      this.setState({ specs: merge(this.state.specs, specs), price, custom: customItem ? true : null });
    }
  }

  render() {
    const { onClickClose, isVisible, service, estate, sessionUser } = this.props;
    const { specs: { standard }, price, custom } = this.state;
    const { panorama360 } = serviceImages;
    const items = getItemsByService(estate).panorama360 ?? [];
    const customItem = items.find((item) => item.product.custom);
    const customFeatures = custom !== null ? getFeaturesForProduct(customItem?.product) : null;
    const isResidential = estate.projectType === EstateEnums.ProjectType.RESIDENTIAL;
    return (
      <BaseServiceModal
        isVisible={isVisible && service?.id === 'panorama360'}
        title={service?.id ? t(`services.${service.id}`) : ''}
        images={Object.values(panorama360)}
        price={sessionUser.addCurrency(price)}
        disclaimer={{
          label: tt('disclaimer.text'),
          description: tt('disclaimer.info'),
        }}
        priceDisclaimer={tt('price_disclaimer')}
        onClickConfirm={this._handleClickConfirm}
        confirmAsUpdate={items?.length > 0}
        onClickClose={onClickClose}
        addDisabled={price === 0}>
        <div className="childcontent">
          <Paragraph>
            {tt('description')}
          </Paragraph>
          <SectionTitle>
            {tt('select_number')}
          </SectionTitle>
          <Grid withVGutters withHGutters>
            <Cell size="1of2">
              <ProductCard
                name="standard"
                label={tm('standard')}
                note={tm('from_price', { price: applyPrice(isResidential ? { EUR: 1250, USD: 1500, GBP: 1100 } : { EUR: 1500, USD: 1800, GBP: 1350 }, sessionUser) })}
                fullHeight
                formComponent="radio"
                nonFeatures={[
                  {
                    text: tt('non_features.first'),
                  },
                  {
                    text: tt('non_features.second'),
                  },
                ]}
                features={[
                  {
                    text: tt('features.first'),
                  },
                  {
                    text: tt('features.second'),
                  },
                  {
                    text: tt('features.third'),
                  },
                  {
                    text: tt('features.fourth'),
                  },
                ]}
                value={standard}
                onChange={this._handleClickStandard} />
            </Cell>
            <Cell size="1of2">
              <ProductCard
                name="custom"
                label={custom !== null ? `${customItem?.product.name}` : tm('custom')}
                note={custom !== null && customItem ? `${sessionUser.addCurrency(customItem?.price)}` : tm('contact_us')}
                formComponent="radio"
                fullHeight
                features={customFeatures}
                onChange={this._handleClickCustom}
                value={!! custom} />
            </Cell>
          </Grid>
        </div>
      </BaseServiceModal>
    );
  }

  @autobind
  _syncState() {
    const { estate, service } = this.props;
    const { specs } = this.state;
    const changes = specsToItems({ specs, estate, service });
    const updatedEstate = applyChanges(changes, estate);
    const items = getItemsByService(updatedEstate).panorama360;
    const price = items != null
      ? items
          .map((item) => item.estate != null ? item : { ...item, estate: updatedEstate })
          .reduce((memo, i) => memo + priceItem(i, this.props.sessionUser.currency.code, true), 0)
      : 0;
    this.setState({ price });
    return { changes, updatedEstate, price };
  }

  @autobind
  _handleClickStandard() {
    const { specs: { standard }, custom } = this.state;
    const newValue = ! standard;
    this.setState({
      specs: { standard: newValue }, custom: custom ? ! custom : custom,
    }, this._syncState);
  }

  @autobind
  _handleClickCustom() {
    const { custom } = this.state;
    if (custom === null) {
      openIntercom();
      this._resetSpecs(this._syncState);
    }
    else {
      const newValue = ! custom;
      this._resetSpecs(() => this.setState({ custom: newValue }, this._syncState));
    }
  }

  @autobind
  _resetSpecs(onReset) {
    this.setState({ specs: { standard: false  }}, onReset);
  }

  @autobind
  _handleClickConfirm() {
    const { onModifyService, estate } = this.props;
    const { custom } = this.state;
    const extraRemove = custom === false ? [getItemsByService(estate).sitePlan.find((item) => item.product.custom)] : [];
    const { changes } = this._syncState();
    onModifyService({ ...changes, remove: [ ...changes.remove, ...extraRemove ] });
  }
}


export default Panorama360;
