import produce from 'immer';
import React, { useState } from 'react';
import { StepObject } from 'react-albus';
import { RouteComponentProps } from 'react-router-dom';

import Spinner from '~/components/Spinner';
import EstateSidebar from '~/pods/order/components/EstateSidebar';
import OrderWizard, { Step } from '~/pods/order/components/OrderWizard';
import SidebarLayout from '~/pods/order/components/SidebarLayout';
import { createTranslate, translate as t } from '~/utils/translation';

import { ApolloMutation, ID, JsonMeta } from '../../types';
import PaymentAddress from '../PaymentAddress';
import PaymentSynthesis from '../PaymentSynthesis';
import styles from './styles';

const tt = createTranslate('pods.order.routes.payment');

export interface PaymentProcessAddress {
  id: ID;
  main: boolean;
  label: string;
  street: string;
  zipCode: string;
  city: string;
  countryCode: string;
  vat: string;
  vatValidated?: boolean;
}

export interface PaymentProcess {
  id: string | number;
  estate: {
    id: string | number;
    jsonMeta: JsonMeta;
    isNewDevelopment: boolean;
  };
  address: PaymentProcessAddress;
  downPaymentTransaction: { id: ID; currency: string; transactionToken: string };
  invoices: Array<{ id: ID; currency: string }>;
  salesOrder?: {
    id: ID;
    clientReference: string;
    daysUntilPayment: number;
    downPaymentPercentage: number;
  };
  status: 'INITIAL' | string;
}

interface PaymentProcessData {
  refetch: VoidFunction;
  paymentProcess: PaymentProcess;
}

interface PaymentProps extends RouteComponentProps {
  paymentProcessData: PaymentProcessData;
  checkVat: ApolloMutation<{ vat: string; addressId?: ID }, { payload: { validVAT: boolean } }>;
  updateAddress: ApolloMutation<
    {
      id: ID;
      street: string;
      zipCode: string;
      city: string;
      countryCode: string;
      label?: string;
      vat?: string;
    },
    {
      payload: {
        updatedAddress: {
          id: ID;
          main: boolean;
          label: string;
          street: string;
          zipCode: string;
          city: string;
          countryCode: string;
          vat: string;
        };
      };
    }
  >;
  updateMeta: ApolloMutation<
    {
      id: ID;
      metadata: string;
    },
    {
      payload: {
        updatedEstate: {
          id: ID;
          metadata: string;
          jsonMeta: JsonMeta;
        };
      };
    }
  >;
}

const Payment = ({
  history,
  match,
  paymentProcessData,
  checkVat,
  updateAddress,
  updateMeta,
}: PaymentProps) => {
  const [refetched, setRefetched] = useState(false);
  const { paymentProcess, refetch } = paymentProcessData;

  if (!refetched && paymentProcess == null) {
    return <Spinner size={50} containerStyle={{ margin: 'auto' }} text={t('pods.order.loading')} />;
  }

  const { estate } = paymentProcess;
  const { steps } = estate.jsonMeta;

  const _setAsVisited = (
    currentStep: StepObject,
    nextStep: StepObject,
    estate: { id: string | number; jsonMeta: JsonMeta },
  ) => {
    const { id } = currentStep;
    const { id: nextId } = nextStep;

    const newMetadata = produce(estate.jsonMeta, (metadata) => {
      metadata.steps[id] = metadata.steps[id] || {};
      metadata.steps[id].visited = true;
      metadata.steps[nextId] = metadata.steps[nextId] || {};
      metadata.steps[nextId].visited = true;
    });

    updateMeta({
      variables: {
        id: estate.id,
        metadata: JSON.stringify(newMetadata),
      },
      optimisticResponse: {
        __typename: 'Mutation',
        payload: {
          __typename: 'UpdateOrderEstatePayload',
          updatedEstate: {
            id: estate.id,
            metadata: JSON.stringify(newMetadata),
            jsonMeta: newMetadata,
            __typename: 'Estate',
          },
        },
      },
    });
  };

  return (
    <div className={styles.payment}>
      <SidebarLayout
        sidebar={() => <EstateSidebar estate={estate} mobileButtonText={tt('project_recap')} />}
        content={() => (
          <div className={styles.container}>
            <OrderWizard
              history={history}
              basename={match.url}
              onGoNext={(cs, ns) => _setAsVisited(cs, ns, estate)}>
              <Step
                id="address"
                title={tt('address')}
                checked={steps.address?.visited}
                component={PaymentAddress}
                withProps={{
                  paymentProcess,
                  checkVat,
                  updateAddress,
                  refetch: () => {
                    setRefetched(true);
                    refetch();
                  },
                }}
              />
              <Step
                id="synthesis"
                title={tt('synthesis')}
                checked={steps.synthesis?.visited}
                component={PaymentSynthesis}
                withProps={{
                  paymentProcess,
                  checkVat,
                  updateAddress,
                  refetch: () => {
                    setRefetched(true);
                    refetch();
                  },
                }}
              />
            </OrderWizard>
          </div>
        )}
      />
    </div>
  );
};

export default Payment;
