import * as AlertsActions from 'actions/alerts';


const initialState = [
];


export default function alerts(state=initialState, action) {
  switch (action.type) {
    case AlertsActions.SHOW_ALERT: {
      return [ ...state, action.payload ];
    }
    case AlertsActions.HIDE_ALERT: {
      const { id } = action.payload;
      return state.filter((a) => String(a.id) !== String(id));
    }
    default: {
      return state;
    }
  }
}
