import { EntityState, EntityStore, StoreConfig, applyTransaction } from '@datorama/akita';
import { Injectable } from '@tokamakjs/react';

import { Project } from '../types';

export interface ProjectsState extends EntityState<Project, number> {
  ui: {
    total: number;
  };
}

@Injectable()
@StoreConfig({ name: 'projects', resettable: true })
export class ProjectsStore extends EntityStore<ProjectsState> {
  constructor() {
    super({ ui: { total: 0 } });
  }

  public resetAndAdd(projects: Array<Project>, total: number): void {
    applyTransaction(() => {
      this.reset();
      this.add(projects);
      this.update({ ui: { total } });
    });
  }
}
