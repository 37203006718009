import { ApolloLink } from 'apollo-link';
import isPlainObject from 'lodash/isPlainObject';
import omit from 'lodash/omit';

import { nameFromOperation } from './utils';


function _getDetailsTypeForService(item) {
  const { serviceId, custom } = item;
  if (custom) {
    return 'customDetails';
  }
  return `${serviceId}Details`.replace('3d', '3D').replace('2d', '2D');
}


function _addDetailsType(data) {
  if (Array.isArray(data)) {
    return data.map(_addDetailsType);
  }
  else if (isPlainObject(data)) {
    return Object.keys(data).reduce((memo, key) => {
      let newData = null;
      if (key === 'details' && data.serviceId) {
        newData = omit({ ...memo, [_getDetailsTypeForService(data)]: data.details }, 'details');
      }
      else {
        newData = { ...memo, [key]: _addDetailsType(data[key]) };
      }
      return omit(newData, ['serviceId', 'custom']);
    }, data);
  }
  else {
    return data;
  }
}


export function withDetailsType() {
  return new ApolloLink((operation, forward) => {
    const operationName = nameFromOperation(operation);
    if (operationName === 'updateOrderItems' || operationName === 'addOrderItems') {
      const newVariables = _addDetailsType(operation.variables);
      operation.variables = newVariables;
      return forward(operation);
    }
    return forward(operation);
  });
}
