import React from 'react';

import RedirectWithParams from '~/components/RedirectWithParams';
import Route, { Switch } from '~/components/Route';
import Overview from './Overview';
import Viewer from './Viewer';


class Studio extends React.Component {
  render() {
    const { match } = this.props;
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <Switch handleNotFound>
          <Route exact path={`${match.url}/project-item/:projectId/preview/:previewId`} component={Viewer} />
          <RedirectWithParams
            from={`${match.url}/order_items/:projectId/preview/:previewId`}
            to={`${match.url}/project-item/:projectId/preview/:previewId`} />
          <Route exact path={`${match.url}/project-item/:projectId`} component={Overview} />
          <RedirectWithParams
            from={`${match.url}/order_items/:projectId`}
            to={`${match.url}/project-item/:projectId`} />
        </Switch>
      </div>
    );
  }
}


export default Studio;
