import gql from 'fraql';


const Interior3D = gql`
  fragment _ on Item {
    details {
      ... on Interior3DDetails {
        unit
        room
        otherRoom
        otherRoomAttachments {
          id
          filename
          url
        }
        kitchenFlooring
        kitchenFlooringAttachments {
          id
          filename
          url
        }
        livingRoomFlooring
        livingRoomFlooringAttachments {
          id
          filename
          url
        }
        kitchenMaterials
        kitchenMaterialsAttachments {
          id
          filename
          url
        }
        bedroomFlooring
        bedroomFlooringAttachments {
          id
          filename
          url
        }
        bathroomFlooring
        bathroomFlooringAttachments {
          id
          filename
          url
        }
        wallTiles
        wallTilesAttachments {
          id
          filename
          url
        }
        style
        otherStyle
        otherStyleAttachments {
          id
          filename
          url
        }
        environment
        otherEnvironment
        otherEnvironmentAttachments {
          id
          filename
          url
        }
        otherInfo
        otherInfoAttachments {
          id
          filename
          url
        }
        flooring
        flooringAttachments {
          id
          filename
          url
        }
      }
    }
  }
`;


const Exterior3D = gql`
  fragment _ on Item {
    details {
      ... on Exterior3DDetails {
        shadows
        people
        views {
          id
          details {
            lighting
            lightingDetails
            otherStyle
            otherStyleAttachments {
              id
              filename
              url
            }
            angles
            anglesDescription
          }
        }
      }
    }
  }
`;


const Shoebox = gql`
  fragment _ on Item {
    details {
      ... on ShoeboxDetails {
        unit
        style
        otherStyle
        otherStyleAttachments {
          id
          filename
          url
        }
        doors
        people
        timeOfDay
        additionalRoomFurniture
        otherInfo
        otherInfoAttachments {
          id
          filename
          url
        }
        flooring
        flooringAttachments {
          id
          filename
          url
        }
        section
      }
    }
  }
`;


export const SitePlanDefaultDetails = {
  options: [
    'street_names',
    'building_names',
    'entrances',
    'wind_rose',
  ],
};


const SitePlan = gql`
  fragment _ on Item {
    details {
      ... on SitePlanDetails {
        options
        surroundingsStyle
        otherInfo
        otherInfoAttachments {
          id
          filename
          url
        }
      }
    }
  }
`;


const CommercialFloorplan = gql`
  fragment _ on Item {
    details {
      ... on CommercialFloorplanDetails {
        style
        otherStyle
        otherStyleAttachments {
          id
          filename
          url
        }
        options
        logoOptions
        logoAttachments {
          id
          filename
          url
        }
        extraVisuals
        footnote
        otherInfo
        otherInfoAttachments {
          id
          filename
          url
        }
      }
    }
  }
`;


const Panorama360 = gql`
  fragment _ on Item {
    details {
      ... on Panorama360Details {
        pois {
          id
          label
          latitude
          longitude
        }
        otherInfo
        otherInfoAttachments {
          id
          filename
          url
        }
      }
    }
  }
`;


const PhotoShooting = gql`
  fragment _ on Item {
    details {
      ... on PhotoShootingDetails {
        pois {
          id
          label
          latitude
          longitude
        }
        otherInfo
        otherInfoAttachments {
          id
          filename
          url
        }
      }
    }
  }
`;


const ExteriorRestyling = gql`
  fragment _ on Item {
    details {
      ... on ExteriorRestylingDetails {
        photosToRestyle {
          id
          filename
          url
        }
        adaptDoors
        doorsInfo
        doorsAttachments {
          id
          filename
          url
        }
        adaptWindows
        windowsInfo
        windowsAttachments {
          id
          filename
          url
        }
        adaptFacade
        facadeInfo
        facadeAttachments {
          id
          filename
          url
        }
        adaptRoofing
        roofingInfo
        roofingAttachments {
          id
          filename
          url
        }
        logoAttachments {
          id
          filename
          url
        }
        footnote
        otherInfo
        otherInfoAttachments {
          id
          filename
          url
        }
      }
    }
  }
`;


const PhotoEditing = gql`
  fragment _ on Item {
    details {
      ... on PhotoEditingDetails {
        description
        photosToEdit {
          id
          filename
          url
        }
      }
    }
  }
`;


const Plan2D = gql`
  fragment _ on Item {
    details {
      ... on Plan2DDetails {
        logo
        disclaimer
        options
        format
        otherInfo
        style
        subStyle
        disclaimerText
        logoAttachment {
          id
          filename
          url
        }
        otherInfoAttachments {
          id
          filename
          url
        }
      }
    }
  }
`;


const Model360 = gql`
  fragment _ on Item {
    details {
      ... on Model360Details {
        description
        otherInfo
        otherInfoAttachments {
          id
          filename
          url
        }
      }
    }
  }
`;


const VideoAnimation = gql`
  fragment _ on Item {
    details {
      ... on VideoAnimationDetails {
        description
        mapStyle
        stockFootage
        music
        otherMusic
        otherMusicAttachments {
          id
          filename
          url
        }
        pois {
          id
          label
          latitude
          longitude
        }
        people
        otherInfo
        otherInfoAttachments {
          id
          filename
          url
        }
      }
    }
  }
`;


const BrandID = gql`
  fragment _ on Item {
    details {
      ... on BrandIdDetails {
        logos
        dislikedLogos
        otherLogo
        otherLogoAttachments {
          id
          filename
          url
        }
        fonts
        dislikedFonts
        otherFont
        otherFontAttachments {
          id
          filename
          url
        }
        palettes
        otherPalettes
        otherPalettesAttachments {
          id
          filename
          url
        }
        styles
        dislikedStyles
        otherStyle
        otherStyleAttachments {
          id
          filename
          url
        }
        otherInfo
        otherInfoAttachments {
          id
          filename
          url
        }
      }
    }
  }
`;


const Brochure = gql`
  fragment _ on Item {
    details {
      ... on BrochureDetails {
        format
        measures
        type
        numberOfPages
        otherFormat
        brandId
        brandIdAttachments {
          id
          filename
          url
        }
        styles
        dislikedStyles
        otherStyle
        otherStyleAttachments {
          id
          filename
          url
        }
        sections
        otherSections
        visuals
        otherVisuals        
        visualsAttachments {
          id
          filename
          url
        }
        otherInfo
        otherInfoAttachments {
          id
          filename
          url
        }
        numberOfLanguages
        languages
      }
    }
  }
`;


const Billboard = gql`
  fragment _ on Item {
    details {
      ... on BillboardDetails {
        format
        measures
        otherFormat
        brandId
        brandIdAttachments {
          id
          filename
          url
        }
        visuals
        otherVisuals
        visualsAttachments {
          id
          filename
          url
        }
        otherInfo
        otherInfoAttachments {
          id
          filename
          url
        }
      }
    }
  }
`;


const Website = gql`
  fragment _ on Item {
    details {
      ... on WebsiteDetails {
        brandId
        brandIdAttachments {
          id
          filename
          url
        }
        styles
        dislikedStyles
        otherStyle
        otherStyleAttachments {
          id
          filename
          url
        }
        visuals
        otherVisuals
        visualsAttachments {
          id
          filename
          url
        }
        sections
        structureDescription
        structureAttachments {
          id
          filename
          url
        }
        otherInfo
        otherInfoAttachments {
          id
          filename
          url
        }
        numberOfLanguages
        languages
      }
    }
  }
`;


const Revo = gql`
  fragment _ on Item {
    details {
      ... on RevoDetails {
        languages
        otherInfo
        features
        recipientEmails
        otherInfoAttachments {
          id
          filename
          url
        }
      }
    }
  }
`;


export const RevoDefaultDetails = {
  features: [
    'show_prices',
    'enable_favourites',
    'enable_listing',
    'show_availability',
    'show_unit_details',
    'show_surface',
  ],
};


const Tour3D = gql`
  fragment _ on Item {
    details {
      ... on Tour3DDetails {
        unit
        style
        otherStyle
        otherStyleAttachments {
          id
          filename
          url
        }
        otherInfo
        otherInfoAttachments {
          id
          filename
          url
        }
      }
    }
  }
`;


const LandingPage = gql`
  fragment _ on Item {
    details {
      ... on LandingPageDetails {
        brandId
        brandIdAttachments {
          id
          filename
          url
        }
        visuals
        otherVisuals
        visualsAttachments {
          id
          filename
          url
        }
        otherInfo
        otherInfoAttachments {
          id
          filename
          url
        }
        numberOfLanguages
        languages
      }
    }
  }
`;


const Flyer = gql`
  fragment _ on Item {
    details {
      ... on FlyerDetails {
        brandId
        brandIdAttachments {
          id
          filename
          url
        }
        visuals
        otherVisuals        
        visualsAttachments {
          id
          filename
          url
        }
        otherInfo
        otherInfoAttachments {
          id
          filename
          url
        }
      }
    }
  }
`;


const MediaKit = gql`
  fragment _ on Item {
    details {
      ... on MediaKitDetails {
        brandId
        brandIdAttachments {
          id
          filename
          url
        }
        visuals
        otherVisuals
        visualsAttachments {
          id
          filename
          url
        }
        otherInfo
        otherInfoAttachments {
          id
          filename
          url
        }
      }
    }
  }
`;


const SocialMediaKit = gql`
  fragment _ on Item {
    details {
      ... on SocialMediaKitDetails {
        brandId
        brandIdAttachments {
          id
          filename
          url
        }
        visuals
        otherVisuals        
        visualsAttachments {
          id
          filename
          url
        }
        otherInfo
        otherInfoAttachments {
          id
          filename
          url
        }
      }
    }
  }
`;


const Newsletter = gql`
  fragment _ on Item {
    details {
      ... on NewsletterDetails {
        brandId
        brandIdAttachments {
          id
          filename
          url
        }
        visuals
        otherVisuals
        visualsAttachments {
          id
          filename
          url
        }
        otherInfo
        otherInfoAttachments {
          id
          filename
          url
        }
        numberOfLanguages
        languages
      }
    }
  }
`;


const VRHeadset = gql`
  fragment _ on Item {
    details {
      ... on VrHeadsetDetails {
        brandId
        brandIdAttachments {
          id
          filename
          url
        }
        otherInfo
        otherInfoAttachments {
          id
          filename
          url
        }
      }
    }
  }
`;


const CustomProduct = gql`
  fragment _ on Item {
    details {
      ... on CustomDetails {
        description
        attachments {
          id
          filename
          url
        }
      }
    }
  }
`;


const DroneShooting = gql`
  fragment _ on Item {
    details {
      ... on DroneShootingDetails {
        accentPoints
        otherInfo
        otherInfoAttachments {
          id
          filename
          url
        }
      }
    }
  }
`;


const VideoShooting = gql`
  fragment _ on Item {
    details {
      ... on VideoShootingDetails {
        otherInfo
        otherInfoAttachments {
          id
          filename
          url
        }
      }
    }
  }
`;


export default gql`
  fragment _ on Item {
    ${Interior3D}
    ${Exterior3D}
    ${Shoebox}
    ${SitePlan}
    ${CommercialFloorplan}
    ${VideoAnimation}
    ${BrandID}
    ${Brochure}
    ${Website}
    ${LandingPage}
    ${Flyer}
    ${MediaKit}
    ${SocialMediaKit}
    ${Newsletter}
    ${VRHeadset}
    ${Billboard}
    ${Revo}
    ${Tour3D}
    ${Panorama360}
    ${PhotoShooting}
    ${Model360}
    ${CustomProduct}
    ${DroneShooting}
    ${VideoShooting}
    ${ExteriorRestyling}
    ${Plan2D}
    ${PhotoEditing}
  }
`;
