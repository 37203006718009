import dsv from '@drawbotics/drylus-style-vars';
import { Padding } from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React from 'react';

import { Item } from '~/pods/dashboard/types';

import { ItemSidebarLoader } from './ItemSidebarLoader';
import { StatusPanel } from './StatusPanel';
import { Timeline } from './Timeline';

const styles = {
  itemSidebar: css`
    width: 440px;
    height: 100%;
    background: ${dsv.neutralLight};
  `,
};

interface ItemSidebarProps {
  item?: Item;
  isLoading: boolean;
}

export const ItemSidebar = ({ item, isLoading }: ItemSidebarProps) => {
  return (
    <div className={styles.itemSidebar}>
      {isLoading || item == null ? (
        <ItemSidebarLoader />
      ) : (
        <Padding>
          <StatusPanel item={item} />
          <Timeline item={item} />
        </Padding>
      )}
    </div>
  );
};
