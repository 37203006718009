import morphey, { fromKey, fromValue } from 'morphey';

import { addressFromServer } from './address';


const translationsFromServer = (transaction) => ({
  id: 'access_token',
  direct: 'direct',
  amount: 'amount',
  currency: 'currency_code',
  recipientOrganization: fromKey('organisation_id'),
  recipientType: fromValue('Organisation'),
  type: 'transaction_type',
  createdAt: 'created_at',
  updatedAt: 'updated_at',
  buyer: 'payment_process.client_user_id',
  priceExclTaxes: 'amount',
  priceWithTaxes: 'price_with_taxes',
  status: 'status',
  bonus: 'bonus',
  paid: 'paid',
  vat: 'vat_rate',
  reason: 'reason',
  relatedObjectId: 'related_object_id',
  relatedObjectType: 'related_object_type',
  priceBeforeDiscount: 'price_before_discount',
  billingAddress: fromValue(addressFromServer(transaction.payment_process.address)),
});


export function transactionFromServer(transaction) {
  return morphey(transaction, translationsFromServer);
}


export default {
  fromServer: transactionFromServer,
};
