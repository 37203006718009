import morphey from 'morphey';


const translationsFromServer = {
  id: 'id',
  charged: 'charged',
  createdAt: 'created_at',
  projectItem: 'order_item_id',
  price: 'price',
  updatedAt: 'updated_at',
  used: 'used',
  usedAt: 'used_at',
};


export function correctionRoundFromServer(correctionRound) {
  return morphey(correctionRound, translationsFromServer);
}


export default {
  fromServer: correctionRoundFromServer,
};
