import { Add, Switch, Conditional, ForEach, RelativeAdd } from 'drawbotics-pricer';


const description = {
  Base: [
    Switch('estate.projectType').is({
      'residential': [
        Add({ EUR: 5250, GBP: 4850, USD: 6400 }),
        Conditional('details.copywriting').equals(true).then([
          ForEach('details.numberOfLanguages').do(Add({ EUR: 1250, GBP: 1250, USD: 1500 })),
        ]),
        ForEach('details.numberOfLanguages').threshold(1).do([
          Add({ EUR: 500, GBP: 500, USD: 600 }),
        ]),
      ],
      'commercial': [
        Add({ EUR: 7250, GBP: 6500, USD: 8950 }),
        Conditional('details.copywriting').equals(true).then([
          ForEach('details.numberOfLanguages').do(Add({ EUR: 1500, GBP: 1500, USD: 1750 })),
        ]),
        ForEach('details.numberOfLanguages').threshold(1).do([
          Add({ EUR: 750, GBP: 750, USD: 750 }),
        ]),
      ],
    }),
  ],
  Delivery: [
    Switch('estate.delivery').is({
      'relaxed': RelativeAdd(-0.1).from('Base'),
      'standard': RelativeAdd(0).from('Base'),
      'express': RelativeAdd(0.5).from('Base'),
    })
  ],
};


export default description;
