import sv from '@drawbotics/drylus-style-vars';
import {
  AttachmentBox,
  AttachmentList,
  Button,
  Category,
  Flex,
  FlexAlign,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  FormGroup,
  Icon,
  Label,
  ListTile,
  Margin,
  Modal,
  Size,
  Spinner,
  Text,
  TextArea,
  Tier,
  UploadHelper,
  uploadFiles,
  useAlert,
} from '@drawbotics/react-drylus';
import { SigningResult } from '@drawbotics/react-drylus/lib/utils';
import { css, cx } from 'emotion';
import gql from 'fraql';
import React, { Fragment, useEffect, useState } from 'react';
import { useMutation } from 'urql';

import { EstateProblem, ItemProblem } from '~/pods/dashboard/types';
import { Attachment as AttachmentApi } from '~/pods/dashboard/utils/api';
import { buildUrl } from '~/utils';
import { createTranslate, translate as t } from '~/utils/translation';

const tt = createTranslate('pods.dashboard.routes.production');

const styles = {
  section: css`
    padding: ${sv.paddingExtraSmall};
    border-radius: ${sv.defaultBorderRadius};

    &:hover {
      cursor: pointer;
    }
  `,
  active: css`
    background: ${sv.backgroundColor};
  `,
  bullet: css`
    background: ${sv.neutral};
    height: ${sv.defaultMargin};
    width: ${sv.defaultMargin};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1000px;
  `,
};

interface SolveProblemVariables {
  problemId: string;
  comment?: string;
}

interface SolveProblemResult {
  problem: EstateProblem;
}

const solveProblemMutation = gql`
  mutation solveProblem($problemId: ID!, $comment: String) {
    solveProblem(input: { problemId: $problemId, comments: $comment }) {
      problem {
        id
      }
    }
  }
`;

interface UploadedFile {
  file: File;
  signingResult: SigningResult;
}

interface SolveProblemPane {
  problem: EstateProblem | ItemProblem;
}

const SolveProblemPane = ({ problem }: SolveProblemPane) => {
  const [comment, setComment] = useState<string>('');
  const [uploads, setUploads] = useState<Array<UploadedFile>>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isSolvingProblem, setIsSolvingProblem] = useState(false);
  const { showAlert } = useAlert();

  const [, solveProblem] = useMutation<SolveProblemResult, SolveProblemVariables>(
    solveProblemMutation,
  );

  const handleSolveProblem = async () => {
    setIsSolvingProblem(true);
    await Promise.all(
      uploads.map((upload) =>
        AttachmentApi.create({
          ...upload.signingResult,
          name: upload.file.name,
          signedId: upload.signingResult!.signed_id,
          publicUrl: upload.signingResult!.direct_upload.url,
          fieldType: 'attachments',
          targetId: problem.id,
          targetType: 'problem',
        }),
      ),
    );
    const res = await solveProblem({ problemId: problem.id, comment });
    if (res.error != null) {
      showAlert({
        category: Category.DANGER,
        text: tt('something_went_wrong'),
      });
    } else {
      showAlert({
        category: Category.SUCCESS,
        text: tt('thank_you'),
      });
    }
    setIsSolvingProblem(false);
  };

  const handleUploadFile = async (files: FileList) => {
    if (files.length > 0) {
      setIsUploading(true);
      const res = await uploadFiles(
        files,
        buildUrl(process.env.API_HOST, '/rails/active_storage/direct_uploads'),
        {},
      );
      const validUploads = res.filter(
        (upload) => upload.file != null && upload.signingResult != null,
      ) as Array<UploadedFile>;
      setUploads([...uploads, ...validUploads]);
      setIsUploading(false);
    }
  };

  return (
    <Fragment>
      <Margin size={{ bottom: Size.SMALL }}>
        <Text>
          {t(`pods.dashboard.components.reception_problem.causes.${problem.reason}.text`)}
        </Text>
      </Margin>
      {problem.description != null ? (
        <Margin size={{ bottom: Size.SMALL }}>
          <Text>{problem.description}</Text>
        </Margin>
      ) : null}
      {problem.attachments.length > 0 ? (
        <Margin size={{ bottom: Size.SMALL }}>
          <AttachmentList>
            {problem.attachments.map((attachment) => (
              <AttachmentBox
                key={attachment.id}
                fileName={attachment.filename}
                onClickDownload={() => window.open(attachment.url)}
              />
            ))}
          </AttachmentList>
        </Margin>
      ) : null}
      <Margin size={{ bottom: Size.SMALL, top: Size.LARGE }}>
        <UploadHelper multiple onUploadFiles={handleUploadFile}>
          <Button
            category={Category.BRAND}
            leading={isUploading ? <Spinner size={Size.SMALL} inversed /> : <Icon name="upload" />}>
            {isUploading ? tt('uploading') : tt('upload_files')}
          </Button>
        </UploadHelper>
      </Margin>
      {uploads.length > 0 ? (
        <Margin size={{ bottom: Size.SMALL }}>
          <AttachmentList>
            {uploads.map((upload) => (
              <AttachmentBox
                key={upload.signingResult.id}
                fileName={upload.file.name}
                onClickClose={() =>
                  setUploads(
                    uploads.filter(
                      (_upload) => _upload.signingResult.id !== upload.signingResult.id,
                    ),
                  )
                }
              />
            ))}
          </AttachmentList>
        </Margin>
      ) : null}
      <FormGroup
        label={<Label>{tt('add_comment')}</Label>}
        input={<TextArea value={comment} onChange={(v) => setComment(v as string)} />}
      />
      <div style={{ position: 'absolute', bottom: sv.marginSmall, right: sv.marginSmall }}>
        <Button
          disabled={comment == '' && uploads.length === 0}
          leading={isSolvingProblem ? <Spinner inversed size={Size.SMALL} /> : null}
          category={Category.BRAND}
          onClick={handleSolveProblem}>
          {tt('submit')}
        </Button>
      </div>
    </Fragment>
  );
};

interface SolveProblemModalProps {
  visible: boolean;
  onClickClose: VoidFunction;
  problems: Array<EstateProblem | ItemProblem>;
}

export const SolveProblemModal = ({ visible, onClickClose, problems }: SolveProblemModalProps) => {
  const [activeProblem, setActiveProblem] = useState(problems[0].id);

  useEffect(() => {
    if (problems.length === 0) {
      onClickClose();
    } else {
      setActiveProblem(problems[0].id);
    }
  }, [problems]);

  return (
    <Modal
      size={Size.LARGE}
      visible={visible}
      onClickClose={onClickClose}
      title={tt('problems_to_solve', { count: problems.length })}
      footer={
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <Button onClick={onClickClose} tier={Tier.TERTIARY}>
              {tt('cancel')}
            </Button>
          </FlexItem>
          <FlexSpacer size={Size.SMALL} />
          <FlexItem style={{ opacity: 0, pointerEvents: 'none' }}>
            <Button>{tt('submit')}</Button>
          </FlexItem>
        </Flex>
      }>
      <Flex align={FlexAlign.STRETCH}>
        <FlexItem flex style={{ maxWidth: 400 }}>
          {problems.map((problem, i) => (
            <Fragment key={problem.id}>
              {i !== 0 ? <Margin size={{ top: Size.EXTRA_SMALL }} /> : null}
              <div
                onClick={() => setActiveProblem(problem.id)}
                className={cx(styles.section, {
                  [styles.active]: problem.id === activeProblem,
                })}>
                <ListTile
                  title={t(
                    `pods.dashboard.components.reception_problem.causes.${problem.reason}.title`,
                  )}
                  leading={<div className={styles.bullet}>{i + 1}</div>}
                />
              </div>
            </Fragment>
          ))}
        </FlexItem>
        <FlexSpacer size={Size.DEFAULT} />
        <FlexItem flex style={{ maxWidth: 400 }}>
          {problems.map((problem) => (
            <div
              key={problem.id}
              style={{ display: problem.id === activeProblem ? 'block' : 'none' }}>
              <SolveProblemPane problem={problem} />
            </div>
          ))}
        </FlexItem>
      </Flex>
    </Modal>
  );
};
