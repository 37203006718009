import { price } from 'drawbotics-pricer';
import descriptions from './descriptions';
import { estateWithSqm, itemWithSqm } from './utils';


export function priceItem(rawItem, currency = 'EUR', noDelivery) {
  if (rawItem.product?.custom) {
    return rawItem.price;
  }
  try {
    const description = descriptions[rawItem.service.id];
    const item = itemWithSqm(rawItem);
    return price(item, description, currency, noDelivery ? ['Delivery'] : []);
  }
  catch (err) {
    const error = new Error(`There was a problem calculating the price for an item of service ${rawItem.service.id}`);
    console.error(err);
    error.originalError = err;
    throw error;
  }
}


export function priceEstateModeling(rawEstate, currency = 'EUR', noDelivery) {
  try {
    const description = descriptions.estate;
    const estate = estateWithSqm(rawEstate);
    return price(estate, description, currency, noDelivery ? ['Delivery'] : []);
  }
  catch (err) {
    const error = new Error(`There was a problem calculating the price for the estate`);
    console.error(err);
    error.originalError = err;
    throw error;
  }
}
