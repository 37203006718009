// Returns the coordinates relative to the studio element for layering HTML
// elements on top of SVG (annotations)

var coordinatesInStudio = (shape, displayData, style, offset = {x:0,y:0}, fullScreen = false) => {
  var { containerWidth, containerHeight, imageObject: image } = displayData;
  var { x, y } = shape;
  var { width: annotationWidth, height: annotationHeight } = style;

  var ratioImage = image.width / image.height;
  var crossRatio = containerWidth / containerHeight / ratioImage;
  var imageWidth = crossRatio > 1 ? containerHeight * ratioImage : containerWidth;
  var imageHeight = crossRatio > 1 ? containerHeight : containerWidth / ratioImage;
  var coordinates = {};
  var positionX = imageWidth * x;
  var positionY = imageHeight * y;

  if((positionY < imageHeight / 2) || positionY < imageHeight - annotationHeight) {
    coordinates.y = positionY - offset.y;
    coordinates.arrow = 'top';
  } else {
    coordinates.y = positionY - annotationHeight + offset.y;
    coordinates.arrow = 'bottom';
  }

  if(positionX < imageWidth - annotationWidth){
    coordinates.x = (containerWidth - imageWidth) / 2 + positionX + offset.x;
    coordinates.arrow += 'Left';
  } else {
    coordinates.x = (containerWidth - imageWidth) / 2 + positionX - annotationWidth - offset.x;
    coordinates.arrow += 'Right';
  }

  if(fullScreen){
    if(crossRatio < 1){
      coordinates.y += (containerHeight - imageHeight) / 2 ;
    }
  }

  return {
    top: coordinates.y,
    left: coordinates.x,
    arrow: coordinates.arrow,
  };
};

// Returns date in ISO format for user events
function ISODate() {
  const date = new Date();
  return date.toISOString();
}

function usernameById(id, users) {
  const user =  users.find(user => user.id === id) || {first_name:'Team member' ,last_name:''};
  return `${user.first_name} ${user.last_name}`;
}

// Fetches the relative position of a mouse event in the preview
function fetchPosition (event) {
  var target = event.currentTarget || event.srcElement;
  var { top, left, width, height } = target.getBoundingClientRect();
  var x = (event.clientX || 0) - left;
  var y = (event.clientY || 0) - top;
  var relativeX = x / width;
  var relativeY = y / height;
  return [relativeX, relativeY];
}

// Manages behavior according to the currently open annotation and its content
// If no other anntation is open the wanted behavior(handler) can proceed.
// Otherwise the behavior looks at the content (none, different or identical)
// and proceeds accordingly

let annotationEvent = ({actions, annotations, annotationOpen})  => event => {

  event.stopPropagation();

  const {
    changeComment,
    closeAnnotation,
    deleteAnnotation,
    handler,
    alternative = () => null,
  } = actions;
  const {[annotationOpen]: annotation } = annotations;

  if (annotationOpen !== null) {
    const { comment, attachments, tempState } = annotation;
    if (tempState) {
      if(
        comment.trim() === tempState.comment.trim() &&
        JSON.stringify(attachments) === JSON.stringify(tempState.attachments)
      ) {
        let { comment, updated_at, updated_by } = tempState;
        changeComment(updated_by, comment, updated_at);
        closeAnnotation();
        handler(event);
      } else {
        alternative(event);
      }
    } else {
      if(!annotation.comment && !annotation.attachments.length) {
        deleteAnnotation();
        handler(event);
      } else {
        alternative(event);
      }
    }
  } else {
    handler(event);
  }

};

export {
  coordinatesInStudio,
  fetchPosition,
  ISODate,
  annotationEvent,
  usernameById,
};


export scrollIntoView from './scroll-into-view';
