import React from 'react';
import dayjs from 'dayjs';
import get from 'lodash/get';

import { createTranslate } from 'utils/translation';
import styles from 'pods/studio/styles/components/annotations-list';


const tt = createTranslate('pods.studio.components.annotation_list');


const placeholderStyle = {
  width: '100%',
  border: '2px dashed #ddd',
  color: '#aaa',
  padding: '60px 30px',
  fontSize: 20,
  fontWeight: 'bold',
  textAlign: 'center',
};


const AnnotationItem = ({
  index,
  active,
  annotation,
  onClick,
}) => {
  return (
    <a href={`#${annotation.id}`}>
      <div
        className={active ? styles.isOpen : styles.annotation}
        onClick={() => onClick(annotation.id)}>
        { do {
          if (annotation.hasProblems()) {
            <div className={styles.problemLabel}>
              PROBLEM
            </div>
          }
        }}
        <div className={styles.annotationIndex}>
          {index + 1}
        </div>
        <div className={styles.annotationInfo}>
          <div className={styles.annotationComment}>{annotation.comment}</div>
          <ul className={styles.attachmentsList}>
            {annotation.attachments.map((a, i) => <li key={i}>&#9679;&nbsp;&nbsp; {a.filename}</li>)}
          </ul>
          <div className={styles.annotationAuthorAndDate}>
            <div className={styles.annotationAuthor}>
              {annotation.userType === 'BackUser' ? 'Drawbotics' : annotation.createdBy.firstName}
            </div>
            <div className={styles.annotationDate}>
              {dayjs(annotation.createdAt).fromNow()}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};


const AnnotationList = ({
  user,
  preview,
  activeAnnotation,
  onClickAnnotationItem,
}) => {
  const userCanAdd = get(user, 'permissions.annotations.add', false);
  const annotations = preview.annotations;
  return (
    <div className={styles.annotationsList}>
      {do {
        if (annotations.length > 0) {
          <div>
            {annotations.map((annotation, i) => (
              <AnnotationItem
                key={i}
                index={i}
                annotation={annotation}
                active={activeAnnotation === annotation.id}
                onClick={() => onClickAnnotationItem(annotation.id)} />
            ))}
          </div>
        }
      }}
      {do {
        if (annotations.length <= 0 && preview.draft.isEditable()) {
          <div style={placeholderStyle}>
            {userCanAdd ? tt('annotations_placeholder') : tt('permission_placeholder')}
          </div>
        }
      }}
    </div>
  );
};


export default AnnotationList;
