import gql from 'fraql';

import {
  ItemProblem,
  ItemStatus,
  Milestone,
  MilestoneName,
  PaymentDocument,
  PaymentDocumentStatus,
} from '~/pods/dashboard/types';

const Item = {
  typeName: 'Item',
  fields: {
    status: {
      dependencies: gql`
        fragment _ on Item {
          rawStatus: status
          problems {
            correction
          }
          estate {
            id
            rawStatus: status
            holder {
              reasons
            }
            paymentProcess {
              invoices {
                status
              }
            }
          }
          milestones {
            id
            name
          }
        }
      `,
      resolver: (item: any): ItemStatus => {
        if (
          item.rawStatus === ItemStatus.ON_HOLD &&
          item.estate.paymentProcess?.invoices.find(
            (invoice: PaymentDocument) => invoice.status === PaymentDocumentStatus.LATE,
          )
        ) {
          return ItemStatus.AWAITING_PAYMENT;
        }
        if (item.rawStatus === ItemStatus.ON_HOLD && item.briefingConfirmedAt == null) {
          return ItemStatus.AWAITING_BRIEFING;
        }
        if (
          (item.rawStatus === ItemStatus.ONGOING || item.rawStatus === ItemStatus.ON_HOLD) &&
          item.milestones.find(
            (milestone: Milestone) => milestone.name === MilestoneName.ACCEPTED,
          ) == null
        ) {
          return ItemStatus.REVIEWING_BRIEFING;
        }
        if (
          item.rawStatus === ItemStatus.PROBLEM &&
          item.problems.every((p: ItemProblem) => !p.correction)
        ) {
          return ItemStatus.BRIEFING_ERROR;
        }
        return item.rawStatus;
      },
    },
  },
};

export default Item;
