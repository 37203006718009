import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Main, Header, Footer } from 'react-ittsu/layout';
import { withRouter, Route } from 'react-router-dom';
import browser from 'detect-browser';

import isAuthenticated from '~/utils/is-authenticated';
import { loadSessionUser } from '~/actions';
import { isFooterShown, isHeaderShown, getSessionUser } from '~/selectors';
import handleErrors from 'utils/handle-errors';
import OutdatedBrowserBanner from '~/components/OutdatedBrowserBanner';
import { Switch } from '~/components/Route';
import { Navbar } from '~/components';
import FooterContent from '~/containers/FooterContent';
import LoadingContainer from '~/containers/LoadingContainer';
import { Dashboard } from '~/pods/dashboard/routes/Dashboard';
import Profile from '~/pods/profile/routes/Profile';
import Transactions from '~/pods/transactions/routes/Transactions';
import Studio from '~/pods/studio/routes/Studio';
import { Route as Order } from '../pods/order';
import { SettingsRoute as Settings } from '../pods/profile/routes/SettingsRoute';


class Application extends React.Component {

  intercomBooted = false;

  componentDidMount() {
    const { loadSessionUser, user } = this.props;
    loadSessionUser();
    if (user && ! this.intercomBooted && window.Intercom && process.env.NODE_ENV === 'production') {
      this.intercomBooted = true;
      window.Intercom('boot', {
        app_id: 'txmuxzbr',
        email: user.email,
        user_hash: user.intercomHash,
      });
    }
  }

  componentDidUpdate() {
    const { user } = this.props;
    if (user && ! this.intercomBooted && window.Intercom && process.env.NODE_ENV === 'production') {
      this.intercomBooted = true;
      window.Intercom('boot', {
        app_id: 'txmuxzbr',
        email: user.email,
        user_hash: user.intercomHash,
      });
    }
  }

  render() {
    const { showHeader, showFooter } = this.props;
    return (
      <LoadingContainer
        loadingKeys="sessionUser"
        whenLoaded={() => (
          <div style={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            height: browser && browser.name === 'ie' ? '100vh' : undefined,
          }}>
            <OutdatedBrowserBanner />
            {do {
              if (showHeader) {
                <Header>
                  <Navbar />
                </Header>
              }
            }}
            <Main>
              <Switch handleNotFound>
                <Route path="/studio" component={Studio} />
                <Route path="/addresses" component={Profile} />
                <Route path="/settings" component={Settings} />
                <Route path="/transactions" component={Transactions} />
                <Route path="/order" component={Order} />
                <Route path="/" component={Dashboard} />
              </Switch>
            </Main>
            {do {
              if (showFooter) {
                <Footer >
                  <FooterContent />
                </Footer>
              }
            }}
          </div>
        )} />
    );
  }

}


const mapStateToProps = (state) => ({
  showFooter: isFooterShown(state),
  showHeader: isHeaderShown(state),
  user: getSessionUser(state),
});


const mapDispatchToProps = {
  loadSessionUser,
};


export default compose(
  withRouter,
  isAuthenticated(),
  handleErrors(),
  connect(mapStateToProps, mapDispatchToProps),
)(Application);
