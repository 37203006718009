import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import autobind from 'autobind-decorator';

import PreviewWrapper from 'pods/studio/components/PreviewWrapper';
import Sidebar from 'pods/studio/components/Sidebar';
import { getSessionUser } from 'selectors';
import {
  getPreview,
  checkIfFullScreen,
  checkIfSavingAnnotation,
  checkIfValidatePreviewDisabled,
  checkIfDisabledAnnotationSaveButton,
  getActiveAnnotation,
  getCurrentDraft,
} from 'pods/studio/selectors';
import { loadProject } from 'pods/studio/actions/project';
import { loadDraft } from 'pods/studio/actions/drafts';
import {
  toggleFullScreen,
  openAnnotation,
  closeAnnotation,
} from 'pods/studio/actions/ui';
import {
  createAnnotation,
  updateAnnotation,
  deleteAnnotation,
  saveAnnotation,
} from 'pods/studio/actions/annotations';
import { solveProblem } from 'pods/studio/actions/problems';
import { createAttachment, deleteAttachment } from 'pods/studio/actions/attachments';
import { validatePreview } from 'pods/studio/actions/previews';
import history from '~/utils/history';

import styles from 'pods/studio/styles/containers/studio';


class Viewer extends React.Component {

  componentDidMount() {
    const { project, loadProject, loadDraft, params, currentDraftId, openAnnotation, isFullScreen, toggleFullScreen } = this.props;
    if (isFullScreen) {
      toggleFullScreen();
    }
    get(project, 'drafts', false) ? null : loadProject(params.projectId);
    currentDraftId ? loadDraft(currentDraftId) : null;

    const hash = window.location.hash.substring(1);
    if (hash !== null) {
      openAnnotation(parseInt(hash));
    }
  }

  componentDidUpdate(prevProps) {
    const { currentDraftId, loadDraft, params } = this.props;
    const { currentDraftId: prevDraftId } = prevProps;
    if (currentDraftId !== prevDraftId) {
      loadDraft(currentDraftId, params.projectId);
    }
  }

  render() {
    const {
      sessionUser,
      preview,
      activeAnnotation,
      isFullScreen,
      disabledAnnotationSaveButton,
      isValidatePreviewDisabled,
      savingAnnotation,
      createAnnotation,
      toggleFullScreen,
      openAnnotation,
      closeAnnotation,
      updateAnnotation,
      deleteAnnotation,
      saveAnnotation,
      createAttachment,
      deleteAttachment,
      validatePreview,
      solveProblem,
    } = this.props;
    if ( ! preview) {return null}
    const { isFirst, isLast } = this._getCurrentIndex();
    return (
      <div className={styles.studio}>
        <div style={{width:'100%', height:'100%'}}>
          <div className={isFullScreen ? styles.fullScreen : styles.previewAndSidebar}>
            <div className={styles.viewerWithControls}>
              <div className={isFirst ? styles.disabled : styles.leftControl} onClick={() => this._switchPreview('prev')}>
                <i className="dbi dbi-caret-left-bold" />
              </div>
              <PreviewWrapper
                key={(isFullScreen ? 'fullscreenviewer' : 'nonfullscreenviewer') + preview.id}
                preview={preview}
                user={sessionUser}
                activeAnnotation={activeAnnotation}
                isFullScreen={isFullScreen}
                disabledAnnotationSaveButton={disabledAnnotationSaveButton}
                onClickPreview={this._onClickPreview}
                toggleFullScreen={toggleFullScreen}
                openAnnotation={openAnnotation}
                closeAnnotation={closeAnnotation}
                createAnnotation={createAnnotation}
                updateAnnotation={updateAnnotation}
                deleteAnnotation={deleteAnnotation}
                saveAnnotation={saveAnnotation}
                createAttachment={createAttachment}
                deleteAttachment={deleteAttachment}
                switchPreview={this._switchPreview}
                solveProblem={solveProblem} />
              <div className={isLast ? styles.disabled : styles.rightControl} onClick={() => this._switchPreview('next')}>
                <i className="dbi dbi-caret-right-bold" />
              </div>
            </div>
            { do {
              if ( ! isFullScreen) {
                <Sidebar
                user={sessionUser}
                preview={preview}
                disabledValidatePreview={isValidatePreviewDisabled}
                savingAnnotation={savingAnnotation}
                activeAnnotation={activeAnnotation}
                openAnnotation={openAnnotation}
                validatePreview={validatePreview} />
              }
            }}
          </div>
        </div>
      </div>
    );
  }

  @autobind
  _switchPreview(direction, e) {
    if (e) {
      e.preventDefault();
    }
    const { preview, params, activeAnnotation, closeAnnotation } = this.props;
    const { projectId } = params;
    const { currentIndex, isFirst, isLast } = this._getCurrentIndex();
    const { previews: allPreviews } = preview.draft;

    if (activeAnnotation && ! isNaN(activeAnnotation)) {
      closeAnnotation(activeAnnotation);
    }

    if (direction === 'prev' && ! isFirst) {
      const nextPreviewId = allPreviews[currentIndex - 1].id;
      history.push(`/studio/project-item/${projectId}/preview/${nextPreviewId}`);
    }
    else if (direction === 'next' && ! isLast) {
      const nextPreviewId = allPreviews[currentIndex + 1].id;
      history.push(`/studio/project-item/${projectId}/preview/${nextPreviewId}`);
    }
  }

  @autobind
  _getCurrentIndex() {
    const { preview } = this.props;
    const allPreviews = preview.draft.previews;

    const currentIndex = allPreviews.findIndex((p) => p.id === preview.id);
    const isFirst = currentIndex === 0;
    const isLast = currentIndex === (allPreviews.length - 1);

    return { currentIndex, isFirst, isLast };
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    preview: getPreview(state, ownProps.match.params.previewId),
    isFullScreen: checkIfFullScreen(state),
    sessionUser: getSessionUser(state),
    activeAnnotation: getActiveAnnotation(state),
    isValidatePreviewDisabled: checkIfValidatePreviewDisabled(state),
    disabledAnnotationSaveButton: checkIfDisabledAnnotationSaveButton(state),
    savingAnnotation: checkIfSavingAnnotation(state),
    currentDraftId: getCurrentDraft(state),
    params: ownProps.match.params,
  };
};


const mapDispatchToProps = {
  toggleFullScreen,
  createAnnotation,
  openAnnotation,
  closeAnnotation,
  updateAnnotation,
  deleteAnnotation,
  saveAnnotation,
  createAttachment,
  deleteAttachment,
  validatePreview,
  loadProject,
  loadDraft,
  solveProblem,
};


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Viewer);
