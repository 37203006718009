import { EstateEnums } from '../../utils/estate-enums';


export function shouldAskArea(estate) {
  return false
    || estate.propertyType === EstateEnums.PropertyType.EXISTING_PROPERTY
    || estate.projectType === EstateEnums.ProjectType.RESIDENTIAL && estate.projectSubtype === EstateEnums.ProjectSubtype.HOUSE
    || estate.projectType === EstateEnums.ProjectType.COMMERCIAL;
}

export function shouldAskNumberOfUnits(estate) {
  return false
    || estate.projectType === EstateEnums.ProjectType.RESIDENTIAL && estate.projectSubtype === EstateEnums.ProjectSubtype.BUILDING;
}


export function shouldAskNumberOfStructures(estate) {
  return false
    || estate.projectSubtype === EstateEnums.ProjectSubtype.COMPLEX;
}


export function shouldAskNumberOfApartments(estate) {
  return false
    || estate.projectType === EstateEnums.ProjectType.RESIDENTIAL && estate.projectSubtype === EstateEnums.ProjectSubtype.COMPLEX;
}


export function shouldAskNumberOfHouses(estate) {
  return false
    || estate.projectType === EstateEnums.ProjectType.RESIDENTIAL && estate.projectSubtype === EstateEnums.ProjectSubtype.COMPLEX;
}


export function shouldAskAddress(estate) {
  return false
    || estate.propertyType !== EstateEnums.PropertyType.EXISTING_PROPERTY;
}
