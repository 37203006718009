import get from 'lodash/get';
import last from 'lodash/last';


/** Runs an interval operation.
 *
 * An interval will start at the first step and will
 * go until infinity.
 *
 * Example:
 *
 * {
 *   operation: 'interval',
 *   interval: {
 *     key: 'someKey',
 *     steps: {
 *       0: {
 *         operation: 'add',
 *         add: { value: 10 },
 *       },
 *       100: {
 *         operation: 'add',
 *         add: { value: 20 },
 *       },
 *       200: {
 *         operation: 'add',
 *         add: { value: 30 },
 *       },
 *     },
 *   },
 * }
 */

export function doInterval(object, intervalDescription, doOperation) {
  const { key, steps } = intervalDescription;
  const value = Number(get(object, key));
  const intervals = Object.keys(steps).map(Number);
  const intervalsAndValue = [ ...intervals, value ].sort((i1, i2) => i1 - i2);

  const exactStep = intervals[intervals.indexOf(value)];
  if (exactStep != null) {
    const then = steps[exactStep];
    return doOperation(object, then);
  }

  const previousStep = intervalsAndValue[intervalsAndValue.indexOf(value) - 1];

  if (previousStep == null) {
    // either use the smaller one or the bigger one depending on value
    const step = value >= last(intervals)
      ? last(intervals)
      : intervals[0];
    const then = steps[step];
    return doOperation(object, then);
  }

  const then = steps[previousStep];
  return doOperation(object, then);
}


class IntervalOperation {
  constructor(key) {
    this.key = key;
  }

  steps(next) {
    return {
      operation: 'interval',
      interval: {
        key: this.key,
        steps: next,
      },
    };
  }
}


export function Interval(key) {
  return new IntervalOperation(key);
}
