export const SHOW_FOOTER = 'SHOW_FOOTER';

export function showFooter() {
  return {
    type: SHOW_FOOTER,
  };
}


export const HIDE_FOOTER = 'HIDE_FOOTER';

export function hideFooter() {
  return {
    type: HIDE_FOOTER,
  };
}

export const USE_FULL_WIDTH_NAVBAR = 'USE_FULL_WIDTH_NAVBAR';

export function useFullWidthNavbar() {
  return {
    type: USE_FULL_WIDTH_NAVBAR,
  };
}


export const USE_NAVBAR_WITH_CONTAINER = 'USE_NAVBAR_WITH_CONTAINER';

export function useNavbarWithContainer() {
  return {
    type: USE_NAVBAR_WITH_CONTAINER,
  };
}
