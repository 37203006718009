import gql from 'fraql';

import PaymentAddress from '../PaymentAddress';
import PaymentSynthesis from '../PaymentSynthesis';
import EstateSidebar from '../../components/EstateSidebar';


export const PaymentProcessQuery = gql`
  query PaymentProcess($paymentProcessId: ID!) {
    paymentProcess(paymentProcessId: $paymentProcessId) {
      id
      status
      estate: orderEstate {
        id
        metadata
        propertyType
        jsonMeta @client(type: Estate)
        ${EstateSidebar.fragments.Estate()}
      }
      ${PaymentAddress.fragments.PaymentProcess()}
      ${PaymentSynthesis.fragments.PaymentProcess()}
    }
  }
`;


export const UpdateMetaMutation = gql`
  mutation UpdateMeta(
    $id: ID!
    $metadata: String
  ) {
    payload: updateOrderEstate(
      input: {
        orderEstateId: $id
        metadata: $metadata
      }
    ) {
      updatedEstate: orderEstate {
        id
        metadata
        jsonMeta @client(type: Estate)
      }
    }
  }
`;


export const CheckVatMutation = gql`
  mutation CheckVat(
    $vat: String!
    $addressId: ID
  ) {
    payload: checkVat(
      input: {
        vat: $vat
        addressId: $addressId
      }
    ) {
      validVAT: valid
    }
  }
`;


export const UpdateAddressMutation = gql`
  mutation UpdateAddress(
    $id: ID!
    $street: String!
    $zipCode: String!
    $city: String!
    $countryCode: String!
    $label: String
    $vat: String
  ) {
    payload: updateAddress(
      input: {
        id: $id
        street: $street
        zipCode: $zipCode
        city: $city
        countryCode: $countryCode
        label: $label
        vat: $vat
      }
    ) {
      updatedAddress: address {
        id
        main
        ${PaymentAddress.fragments.Address()}
        ${PaymentSynthesis.fragments.Address()}
      }
    }
  }
`;
