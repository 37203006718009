import { fork, call, takeLatest } from 'redux-saga/effects';

import * as ProblemActions from 'pods/studio/actions/problems';
import { Problem } from 'pods/studio/utils/api';


/**
 * REGULAR SAGAS
 */
function* handleSolveProblem(action) {
  const { annotationId, problemResponse, problemIds } = action.payload;
  try {
    const payload = {
      problem_solvings: {
        problems: [
          {
            client_answer: problemResponse,
            problem_ids: problemIds,
          },
        ],
      },
    };
    yield call(Problem.solve, annotationId, payload);
    // We reload the page to get the new problem state from the server
    location.reload();
  }
  catch (err) {
    console.error(err);
  }
}


/**
 * WATCHERS
 */
function* watchSolveProblem() {
  yield takeLatest(ProblemActions.SOLVE_PROBLEM, handleSolveProblem);
}


/**
 * ROOT
 */
export default function* root() {
  yield fork(watchSolveProblem);
}
