import Rx from 'rxjs-legacy';
import { combineEpics } from 'redux-observable';

import * as InvoicesActions from 'pods/transactions/actions/invoices';
import * as AlertsActions from 'actions/alerts';
import { translate as t } from 'utils/translation';
import { wrapError } from 'utils/errors';


function loadInvoices(action$, store, extra) {
  const { api } = extra;
  return action$
    .filter((action) => action.type === InvoicesActions.LOAD_INVOICES)
    .mergeMap((action) => {
      const { organizationId } = action.payload;
      return Rx.Observable.fromPromise(api.queries.getInvoices(organizationId))
        .map((invoices) => InvoicesActions.loadInvoicesSuccess(invoices))
        .catch((err) => Rx.Observable.concat(
          Rx.Observable.of(AlertsActions.showAlert(InvoicesActions.LOAD_INVOICES_FAIL, 'error', t('containers.alerts.transactions.invoices.errors.load_invoices'))),
          Rx.Observable.of(InvoicesActions.loadInvoicesFail(wrapError(err)))
        ));
    });
}

export default combineEpics(
  loadInvoices,
);
