const k = (key) => `brochure.briefing.${key}`;


const residential = {
  1: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('brochure_format'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('choose_format'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'SingleSelect',
        key: 'format',
        keyLabel: k('keys.format'),
        required: 'all',
        values: [
          {
            image: require('../images/brochure/format/a4-portrait.jpg'),
            value: 'a4_portrait',
            label: k('formats.a4_portrait'),
          }, {
            image: require('../images/brochure/format/a4-landscape.jpg'),
            value: 'a4_landscape',
            label: k('formats.a4_landscape'),
          }, {
            image: require('../images/brochure/format/a5-landscape.jpg'),
            value: 'a5_landscape',
            label: k('formats.a5_landscape'),
          }, {
            image: require('../images/brochure/format/a5-portrait.jpg'),
            value: 'a5_portrait',
            label: k('formats.a5_portrait'),
          }, {
            image: require('../images/brochure/format/large-vertical.jpg'),
            value: 'large_format_vertical',
            label: k('formats.large_format_vertical'),
          }, {
            image: require('../images/brochure/format/square-20.jpg'),
            value: 'square_20',
            label: k('formats.square_20'),
          }, {
            image: require('../images/brochure/format/square-30.jpg'),
            value: 'square_30',
            label: k('formats.square_30'),
          }, {
            image: require('../images/shared/other.jpg'),
            value: 'other',
            label: 'shared.other_option',
            warning: k('additional_costs'),
            meta: {
              type: 'meta',
              noToggle: true,
              components: [
                {
                  type: 'question-component',
                  label: k('describe_format'),
                  component: 'Input',
                  required: 'all',
                  key: 'otherFormat',
                },
              ],
            },
          },
        ],
      },
    ],
  },
  2: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('type'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('depending_on_pages'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'SingleSelect',
        key: 'type',
        keyLabel: k('keys.type'),
        required: 'all',
        values: [
          {
            image: require('../images/brochure/types/leaflet.jpg'),
            zoom: require('../images/brochure/types/leaflet.jpg'),
            value: 'leaflet',
            label: k('leaflet'),
            description: k('leaflet_description'),
            disabled: (answers) => answers.numberOfPages < 4 || answers.numberOfPages >= 8,
            disabledText: k('not_available_more_than'),
          }, {
            image: require('../images/brochure/types/standard.jpg'),
            zoom: require('../images/brochure/types/standard.jpg'),
            value: 'standard',
            label: 'Standard',
            description: k('standard_description'),
            disabled: (answers) => answers.numberOfPages < 8 || answers.numberOfPages >= 32,
            disabledText: k('not_available_between'),
          }, {
            image: require('../images/brochure/types/premium.jpg'),
            zoom: require('../images/brochure/types/premium.jpg'),
            value: 'premium',
            label: 'Premium',
            description: k('premium_description'),
            disabled: (answers) => answers.numberOfPages < 32,
            disabledText: k('not_available_less_than'),
          }, {
            image: require('../images/brochure/types/deluxe.jpg'),
            zoom: require('../images/brochure/types/deluxe.jpg'),
            value: 'deluxe',
            label: 'Deluxe',
            description: k('deluxe_description'),
            disabled: (answers) => answers.numberOfPages < 32,
            disabledText: k('not_available_less_than'),
          },
        ],
      },
    ],
  },
  3: {
    type: 'section',
    filter: {
      type: 'service',
      value: 'brandId',
    },
    components: [
      {
        type: 'component',
        label: k('branding'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('select_branding'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'SingleSelect',
        key: 'brandId',
        keyLabel: k('keys.branding'),
        required: 'all',
        values: [
          {
            image: require('../images/shared/company-branding.jpg'),
            value: 'company',
            label: k('company_branding'),
            meta: {
              type: 'meta',
              noToggle: true,
              components: [
                {
                  type: 'question-component',
                  label: k('upload_company_files'),
                  component: 'FileUploader',
                  key: 'brandIdAttachments',
                  keyLabel: k('keys.company_files'),
                  required: 'all',
                },
              ],
            },
          },
          {
            image: require('../images/shared/project-branding.jpg'),
            value: 'project',
            label: k('project_branding'),
            meta: {
              type: 'meta',
              condition: {
                required: 'one',
                conditions: [{
                  type: 'service',
                  value: 'brandId',
                }],
              },
              components: [
                {
                  type: 'question-component',
                  label: k('upload_project_files'),
                  component: 'FileUploader',
                  key: 'brandIdAttachments',
                  keyLabel: k('keys.project_files'),
                  required: 'all',
                },
              ],
            },
          },
        ],
      },
    ],
  },
  4: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('brochure_styles'),
        component: 'Title',
      }, {
        type: 'component',
        label: k('like_dislike_styles'),
        component: 'Note',
      }, {
        type: 'question-component',
        component: 'LikeDislike',
        key: 'styles',
        keyLabel: k('keys.styles'),
        dislikedKey: 'dislikedStyles',
        required: 'one',
        values: [
          {
            image: require('../images/brochure/styles/classic.jpg'),
            value: 'classic',
            label: 'Classic',
          },
          {
            image: require('../images/brochure/styles/crafted.jpg'),
            value: 'crafted',
            label: 'Crafted',
          },
          {
            image: require('../images/brochure/styles/dynamic.jpg'),
            value: 'dynamic',
            label: 'Dynamic',
          },
          {
            image: require('../images/brochure/styles/minimalist.jpg'),
            value: 'minimalist',
            label: 'Minimalist',
          },
          {
            image: require('../images/brochure/styles/modern.jpg'),
            value: 'modern',
            label: 'Modern',
          },
          {
            image: require('../images/brochure/styles/retro.jpg'),
            value: 'retro',
            label: 'Retro',
          },
        ],
      }, {
        type: 'meta',
        label: 'shared.show_my_own',
        components: [
          {
            type: 'question-component',
            label: 'shared.example_description',
            component: 'Input',
            key: 'otherStyle',
            keyLabel: 'shared.keys.example_comments',
          }, {
            type: 'question-component',
            label: 'shared.upload_example',
            component: 'FileUploader',
            keyLabel: 'shared.keys.submitted_examples',
            key: 'otherStyleAttachments',
          },
        ],
      },
    ],
  },
  5: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('sections'),
        component: 'Title',
      }, {
        type: 'component',
        label: k('select_sections'),
        component: 'Note',
      }, {
        type: 'question-component',
        component: 'MultiSelect',
        key: 'sections',
        keyLabel: k('keys.sections'),
        required: 'all',
        values: [
          {
            image: require('../images/brochure/sections/summary.jpg'),
            value: 'summary',
            label: k('section_types.summary'),
          }, {
            image: require('../images/brochure/sections/environment.jpg'),
            value: 'environment',
            label: k('section_types.environment'),
          }, {
            image: require('../images/brochure/sections/finishes.jpg'),
            value: 'finishes',
            label: k('section_types.finishes'),
          }, {
            image: require('../images/brochure/sections/floorplan.jpg'),
            value: 'floorplan',
            label: k('section_types.floorplan'),
          }, {
            image: require('../images/brochure/sections/intro.jpg'),
            value: 'intro',
            label: k('section_types.intro'),
          }, {
            image: require('../images/brochure/sections/map.jpg'),
            value: 'map',
            label: k('section_types.map'),
          }, {
            image: require('../images/brochure/sections/panorama.jpg'),
            value: 'panorama',
            label: k('section_types.panorama'),
          }, {
            image: require('../images/brochure/sections/project-description.jpg'),
            value: 'project_description',
            label: k('section_types.project_description'),
          }, {
            image: require('../images/brochure/sections/shoebox.jpg'),
            value: 'shoeboxes',
            label: k('section_types.shoeboxes'),
          }, {
            image: require('../images/brochure/sections/units-listing.jpg'),
            value: 'units_listing',
            label: k('section_types.units_listing'),
          }, {
            image: require('../images/shared/other.jpg'),
            value: 'other',
            label: 'shared.other_option',
            meta: {
              type: 'meta',
              noToggle: true,
              components: [
                {
                  type: 'question-component',
                  label: k('describe_sections'),
                  component: 'Input',
                  required: 'all',
                  key: 'otherSections',
                  keyLabel: k('keys.other_section_description'),
                },
              ],
            },
          },
        ],
      }
    ],
  },
  6: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'shared.visuals',
        component: 'Title',
      },
      {
        type: 'component',
        label: 'shared.visuals_note',
        component: 'Note',
      },
      
      {
        type: 'question-component',
        component: 'SingleSelect',
        key: 'visuals',
        keyLabel: 'shared.keys.visuals',
        required: 'all',
        values: [
          {
            value: 'drawbotics',
            label: 'shared.drawbotics',
            image: require('../images/shared/drawbotics.jpg'),
          },
          {
            value: 'upload_my_own',
            image: require('../images/shared/my-own.jpg'),  
            label: 'shared.upload_my_own',
            meta: {
              type: 'meta',
              noToggle: true,
              components: [
                {
                  type: 'question-component',
                  label: 'shared.upload_visuals',
                  component: 'FileUploader',
                  key: 'visualsAttachments',
                  keyLabel: 'shared.keys.visuals_attachments',
                },
              ],
            },
          },
          {
            value: 'other',
            label: 'shared.other',
            image: require('../images/shared/other.jpg'),            
            meta: {
              type: 'meta',
              noToggle: true,
              components: [
                {
                  type: 'question-component',
                  component: 'Input',
                  label: 'shared.other_visuals',
                  key: 'otherVisuals',
                  keyLabel: 'shared.keys.other_visuals',
                },
              ],
            },
          },
        ],
      },
    ],
  },
  7: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'shared.languages',
        component: 'Title',
      }, {
        type: 'component',
        label: 'shared.languages_note',
        component: 'Note',
      }, {
        type: 'question-component',
        component: 'MultiSelect',
        min: (answers) => answers.numberOfLanguages,
        max: (answers) => answers.numberOfLanguages,
        key: 'languages',
        keyLabel: 'shared.keys.languages',
        required: 'all',
        values: [
          {
            value: 'en',
            image: require('../images/shared/EN.jpg'),
            label: 'shared.english',
          },
          {
            value: 'fr',
            image: require('../images/shared/FR.jpg'),
            label: 'shared.french',
          },
          {
            value: 'nl',
            image: require('../images/shared/NL.jpg'),
            label: 'shared.dutch',
          },
        ],
      },
    ],
  },
  8: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'shared.additional_info',
        component: 'Title',
      }, {
        type: 'component',
        label: 'shared.anything_to_add',
        component: 'Note',
      }, {
        type: 'question-component',
        label: 'shared.additional_comments',
        component: 'Input',
        keyLabel: 'shared.keys.other_info',
        key: 'otherInfo',
      }, {
        type: 'question-component',
        label: 'shared.upload_files',
        component: 'FileUploader',
        key: 'otherInfoAttachments',
        keyLabel: 'shared.keys.other_info_attachments',
      },
    ],
  },
};


export default { residential };
