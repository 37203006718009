import React from 'react';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';

import { getComponent } from './utils';


const styles = {
  sections: css`
    margin-top: ${sv.baseMarginSmall};
    margin-bottom: ${sv.baseMargin};

    &:last-child {
      margin-bottom: 0;
    }

    @media ${sv.phone} {
      margin-bottom: ${sv.baseMarginSmall};
      width: 100%;
    }
  `,
  section: css`
    margin-bottom: calc(${sv.baseMargin} * 1.5);
    display: flex;
    flex-direction: column;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }

    @media ${sv.phone} {
      margin-bottom: ${sv.baseMarginSmall};
    }
  `,
}


class Sections extends React.Component {
  render() {
    const { activeStep, sections, answers, modifyAnswer, components, estate, save } = this.props;
    const activeSection = sections[activeStep];
    const generatedComponents = activeSection.components
      .map((componentDescription) => getComponent(components, componentDescription, { answers, modifyAnswer, estate, save }))
      .filter((c) => !! c);
    return (
      <div className={styles.sections}>
        {generatedComponents.map((generatedComponent, i) => (
          <div key={i} className={styles.section}>
            <generatedComponent.type {...generatedComponent.props} />
          </div>
        ))}
      </div>
    );
  }
}


export default Sections;
