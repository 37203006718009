import React from 'react';
import { Icon } from 'react-ittsu/components';
import { css, cx } from 'emotion';
import sv from '@drawbotics/style-vars';


export const styles = {
  orderOption: css`
    position: relative;
    margin-bottom: ${sv.baseMargin};

    @media ${sv.phoneXl} {
      margin-bottom: 0;
    }
  `,
  checkedOption: css`
    & [data-element="imageContainer"] {
      border-color: ${sv.brandGreen};
      box-shadow: 0px 0px 0px 2px ${sv.brandGreen};
    }
    & [data-element="check"] {
      opacity: 1;
      transform: scale(1);
    }
  `,
  imageContainer: css`
    border: 1px solid ${sv.grey300};
    border-radius: ${sv.baseBorderRadius};
    transition: all ${sv.baseTransitionTimeShort} ease-in-out;
    height: 170px;
    overflow: hidden;

    &:hover {
      cursor: pointer;
      border-color: ${sv.grey400};
    }

    @media ${sv.phoneXl} {
      height: 120px;
    }
  `,
  image: css`
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  `,
  title: css`
    text-align: center;
    color: ${sv.textPrimaryDark};
    margin-top: ${sv.baseMarginSmall};
    margin-bottom: ${sv.baseMarginSmall};
    font-size: 1.1rem;

    @media ${sv.ipadLandscape} {
      font-size: 1rem;
    }

    @media ${sv.phoneXl} {
      font-size: 0.9rem;
    }
  `,
  description: css`
    text-align: center;
    font-size: 0.9rem;
    color: ${sv.textSecondaryDark};

    @media ${sv.phoneXl} {
      font-size: 0.8rem;
      margin-bottom: ${sv.baseMarginSmall};
    }
  `,
  check: css`
    position: absolute;
    top: -15px;
    right: -15px;
    z-index: 99;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${sv.white};
    background: ${sv.brandGreen};
    opacity: 0;
    transform: scale(0);
    transition: all ${sv.baseTransitionTimeShort} ease-in-out;

    @media ${sv.ipad} {
      top: 10px;
      right: 10px;
    }

    @media ${sv.phoneXl} {
      height: 20px;
      width: 20px;
      font-size: 0.5rem;
      top: -10px;
      right: -10px;
    }
  `,
};


const OrderOption = ({
  image,
  title,
  children,
  checked,
  onClick,
}) => {
  return (
    <div className={cx(styles.orderOption, { [styles.checkedOption]: checked })} onClick={onClick ? onClick : null}>
      <div className={styles.imageContainer} data-element="imageContainer">
        <div className={styles.image} style={{ backgroundImage: `url(${image})` }} />
      </div>
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.description}>
        {children}
      </div>
      <div className={styles.check} data-element="check">
        <Icon name="check-bold" />
      </div>
    </div>
  );
}


export default OrderOption;
