import isPlainObject from 'lodash/isPlainObject';
import cookies from 'js-cookie';

import { GRAPHQL_URL } from './api/constants';


export function isLocalStorageAvailable() {
  try {
    localStorage.setItem('dummy', '');
    localStorage.getItem('dummy');
    localStorage.removeItem('dummy');
    return true;
  }
  catch (err) {
    return false;
  }
}


export function currencyWithSymbol(code='EUR') {
  let symbol = '€';
  if (code === 'USD') {
    symbol = '$';
  }
  else if (code === 'GBP') {
    symbol = '£';
  }
  return {
    code,
    symbol,
  };
}


export const currencyToSymbol = {
  'EUR': '€',
  'GPB': '£',
  'USD': '$',
};


export function displaySurface(value, unit) {
  if (unit.includes('sqm')) {
    return `${value} m²`;
  }
  else {
    return `${value} ${unit}`;
  }
}


export function flatten(obj, parentPath) {
  return Object.keys(obj || {}).reduce((result, k) => {
    if ( ! obj.hasOwnProperty(k)) {
      return result;
    }
    const currentPath = parentPath ? parentPath + '.' + k : k;
    const currentProp = obj[k];

    if (Array.isArray(currentProp)) {
      const arrayResult = currentProp.map((value, i) => {
        const arrayPath = `${currentPath}[${i}]`;
        if (isPlainObject(value)) {
          return flatten(value, arrayPath);
        }
        return { [arrayPath] : value };
      });
      return Object.assign({}, result, ...arrayResult);
    }
    else if (isPlainObject(currentProp)) {
      return {
        ...result,
        ...flatten(currentProp, currentPath),
      };
    }

    return {
      ...result,
      [currentPath]: currentProp,
    };
  }, {});
}


export function buildUrl(...args) {
  return args.join('/').replace(/\/\//g, '/').replace(':/', '://');
}


export function readReminderViews() {
  const reminderViews = cookies.get('reminder_views') || 0;
  return JSON.parse(reminderViews);
}


export function writeReminderViews() {
  const counter = readReminderViews();
  cookies.set('reminder_views', JSON.stringify(counter + 1));
}


export function readSession() {
  const localSession = localStorage.getItem('session');
  const sessionSession = cookies.get('session') || null;
  const session = localSession ? localSession : sessionSession;
  return JSON.parse(session) ?? undefined;
}


export async function isAuthenticatedUser() {
  const session = readSession();
  const { status } = await fetch(GRAPHQL_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${session?.authToken}`,
    },
    body: JSON.stringify({
      variables: {},
      query: `{ __schema { types { name } } }`,
    }),
  });
  return String(status) !== '401';
}


export shallowEqual from './shallow-compare';
export * from './open-popup';


export function camelize(str) {
  return str
    .replace(/(\d)(_)/g, '$1')
    .replace(/(_)(\S{1})/g, (match, a, b) => b.toUpperCase());
}


export * from './run';
export * from './session';
export * from './help';