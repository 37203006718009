export default (request, entities, translators) => ({
  find(id) {
    return request('GET', `transactions/${id}`).then(translators.Transaction.fromServer);
  },
  create(amount) {
    const data = { amount };
    return request('POST', 'transactions', data).then(translators.Transaction.fromServer);
  },
  update(id, data) {
    return request('PUT', `transactions/${id}`, data).then(translators.Transaction.fromServer);
  },
  confirmPayment({ id, tokenId, paymentIntentId }) {
    return request('POST', `transactions/${id}/confirm_payment`, {
      token_id: tokenId,
      payment_intent_id: paymentIntentId,
    });
  },
});