import React, { ComponentType, ReactNode, createContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { RouterContext as RouterContextType } from '../types';

export const RouterContext = createContext<RouterContextType | undefined>(undefined);

interface RouterProviderProps {
  value: RouterContextType;
  children: ReactNode;
}

export const RouterProvider = ({ children, value }: RouterProviderProps) => {
  return <RouterContext.Provider value={value}>{children}</RouterContext.Provider>;
};

interface RouteViewWrapperProps extends RouteComponentProps {
  children: ReactNode;
}

/**
 * Use this component to wrap every tokamak-esque view or no access to
 * an up to date match will be available in that view.
 */
export const RouteViewWrapper = ({ children, history, location, match }: RouteViewWrapperProps) => {
  return <RouterProvider value={{ history, location, match }}>{children}</RouterProvider>;
};

/**
 * Utility function to quickly use existing react-router Routes with the
 * RouteViewWrapper. E.g.:
 *
 * <Route path="/object/:id" render={createViewWrapper(RouteView)} />
 */
export function createViewWrapper(View: ComponentType): (...args: any) => ReactNode {
  return (props: any) => (
    <RouteViewWrapper {...props}>
      <View />
    </RouteViewWrapper>
  );
}
