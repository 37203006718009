import React from 'react';
import gql from 'fraql';
import { Grid, Cell } from 'react-ittsu/layout';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';

import ServiceCard from '../components/ServiceCard';


const styles = {
  cards: css`
    margin-bottom: ${sv.baseMargin};
  `,
};


const ServicesPicker = ({
  active,
  items,
  services,
  onClickService,
}) => {
  if (services == null || services.length === 0) {
    return null;
  }

  return (
    <div className={styles.cards}>
      <Grid withHGutters withVGutters>
        {services.map((service) => (
          <Cell size="1of4" responsive="xl1of3,m1of2" key={service.id}>
            <ServiceCard
              active={active}
              isChecked={items.some((i) => i.product.service.id == service.id)}
              disabled={service.id === 'restyling' || ! active}
              service={service}
              onClick={onClickService} />
          </Cell>
        ))}
      </Grid>
    </div>
  );
};


ServicesPicker.fragments = {
  Item: gql`
    fragment _ on Item {
      id
      product {
        id @client(type: Product)
        service {
          id @client(type: ProductService)
        }
      }
    }
  `,
  Service: gql`
    fragment _ on ProductService {
      id @client(type: ProductService)
      category {
        slug
      }
      ${ServiceCard.fragments.ServiceCard}
    }
  `,
};


export default ServicesPicker;
