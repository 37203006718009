import React from 'react';
import { Grid, Cell } from 'react-ittsu/layout';
import { Icon } from 'react-ittsu/components';
import autobind from 'autobind-decorator';
import { css, cx } from 'emotion';
import sv, { fade, darken } from '@drawbotics/style-vars';
import get from 'lodash/get';

import AnswerCell from '../shared/AnswerCell';


const styles = {
  likeDislike: css`
  `,
  wrapper: css`
    position: relative;
  `,
  overlay: css`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: all ${sv.baseTransitionTimeShort} ease-in-out;

    &:hover {
      opacity: 1;
    }

    @media ${sv.phone} {
      display: none;
    }
  `,
  vote: css`
    height: 45px;
    width: 45px;
    border-radius: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${sv.white};
    border: 2px solid ${sv.white};
    font-size: 1.6em;
    transition: all ${sv.baseTransitionTimeShort} ease-in-out;
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);

    & > i {
      transition: all ${sv.baseTransitionTimeShort} ease-in-out;
    }

    &:hover {
      cursor: pointer;
      transform: scale3d(1.1, 1.1, 1) translate3d(0, 0, 0) rotate(-20deg);
    }

    &:active {
      transform: scale3d(1, 1, 1) translate3d(0, 0, 0) rotate(-20deg);
    }
  `,
  upVote: css`
    background: ${sv.brandGreen};
    margin-right: ${sv.baseMargin};

    @media ${sv.phone} {
      margin-right: calc(${sv.baseMargin} * 2);
    }
  `,
  downVote: css`
    background: ${sv.brandRed};
  `,
  largeVote: css`
    height: 80px;
    width: 80px;
    font-size: 2.3em;
  `,
  mobileVoteOverlay: css`
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.8);
    opacity: 0;
    pointer-events: none;
    display: none;
    transition: all ${sv.baseTransitionTimeShort} ease-in-out;

    @media ${sv.phone} {
      display: flex;
    }
  `,
  visibleMobileOverlay: css`
    opacity: 1;
    pointer-events: all;
    z-index: 1000;
  `,
  greenBackground: css`
    background: ${fade(darken(sv.brandGreen, 30), 80)};
  `,
  redBackground: css`
    background: ${fade(darken(sv.brandRed, 30), 80)};
  `,
  close: css`
    position: absolute;
    top: ${sv.baseMarginSmall};
    right: ${sv.baseMarginSmall};
    color: ${sv.white};
  `,
};


const Vote = ({ onClickLike, onClickDislike, image, onClick }) => {
  return (
    <div className={styles.wrapper} onClick={onClick}>
      <div className={styles.overlay}>
        <div className={cx(styles.vote, styles.upVote)} onClick={onClickLike}>
          <i className="ion-thumbsup" />
        </div>
        <div className={cx(styles.vote, styles.downVote)} onClick={onClickDislike}>
          <i className="ion-thumbsdown" />
        </div>
      </div>
      <img src={image} />
    </div>
  );
}


class LikeDislike extends React.Component {
  state = {}

  render() {
    const { answer } = this.state;
    const { values, answers, likedKey, dislikedKey } = this.props;
    const likedAnswers = get(answers, likedKey) ?? [];
    const dislikedAnswers = get(answers, dislikedKey) ?? [];
    return (
      <div className={styles.likeDislike}>
        <Grid halfHGutters halfVGutters>
          {values.map((answer, i) => (
            <Cell size="1of3" responsive="m1of2" key={i}>
              <AnswerCell
                answer={answer}
                selected={likedAnswers.includes(answer.value)}
                negSelected={dislikedAnswers.includes(answer.value)}
                asVote={true}
                position={i}>
                <Vote
                  image={answer.image}
                  onClick={() => this.setState({ answer: answer.value })}
                  onClickLike={() => this._onClickVote(answer.value, 'like')}
                  onClickDislike={() => this._onClickVote(answer.value, 'dislike')}/>
              </AnswerCell>
            </Cell>
          ))}
        </Grid>
        <div className={cx(styles.mobileVoteOverlay, {
          [styles.visibleMobileOverlay]: !! answer,
          [styles.greenBackground]: likedAnswers.includes(answer),
          [styles.redBackground]: dislikedAnswers.includes(answer),
        })}>
          <div className={styles.close} onClick={() => this.setState({ answer: null })}>
            <Icon name="close-bold" size="2x" />
          </div>
          <div className={cx(styles.vote, styles.upVote, styles.largeVote)} onClick={() => this._onClickVote(answer, 'like')}>
            <i className="ion-thumbsup" />
          </div>
          <div className={cx(styles.vote, styles.downVote, styles.largeVote)} onClick={() => this._onClickVote(answer, 'dislike')}>
            <i className="ion-thumbsdown" />
          </div>
        </div>
      </div>
    );
  }

  @autobind
  _onClickVote(value, type) {
    const { modifyAnswer, likedKey, answers, dislikedKey } = this.props;
    const key = type === 'like' ? likedKey : dislikedKey;
    const oppositeKey = type === 'like' ? dislikedKey : likedKey;
    const sectionAnswers = get(answers, key) ?? [];
    const oppositeAnswers = get(answers, oppositeKey) ?? [];
    const remove = sectionAnswers.includes(value);
    const removeOpposite = oppositeAnswers.includes(value);
    if (remove) {
      if (sectionAnswers.length === 1) {
        modifyAnswer(key, null, true);
      }
      else {
        modifyAnswer(key, sectionAnswers.filter((a) => a !== value));
      }
    }
    else {
      modifyAnswer(key, [ ...sectionAnswers, value ]);
    }
    if (removeOpposite) {
      if (oppositeAnswers.length === 1) {
        modifyAnswer(oppositeKey, null, true);
      }
      else {
        modifyAnswer(oppositeKey, oppositeAnswers.filter((a) => a !== value));
      }
    }
    this.setState({ answer: null });
  }
}


export default LikeDislike;
