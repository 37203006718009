import get from 'lodash/get';

import { getItemsByProduct, getItemsByService } from './estate-utils';


const modelingHardTriggers = [
  'exterior3d_standard',
  'exteriorTour3d_standard',
  'model360_standard',
  'model360_premium',
  'revo_standard',
  'sitePlan_standard',
  'panorama360_premium',
  'videoAnimation_standard',
  'videoAnimation_premium',
];


const modelingSoftTriggers = [
  {
    service: 'panorama360',
    key: 'details.modeling',
    value: true,
  },
  {
    service: 'tour3d',
    key: 'details.gardenHotspots',
    value: (v) => v > 0,
  },
  {
    service: 'tour3d',
    key: 'details.terraceHotspots',
    value: (v) => v > 0,
  },
];


function evaluateSoftTriggers(estate) {
  const services = getItemsByService(estate);
  return modelingSoftTriggers.some((trigger) => get(services, trigger.service, []).some((item) => {
    if (typeof trigger.value === 'function') {
      return trigger.value(get(item, trigger.key));
    }
    return get(item, trigger.key) === trigger.value;
  }));
}


function evaluateHardTriggers(estate) {
  const products = getItemsByProduct(estate);
  return Object.keys(products).some((productId) => {
    const modelingRequired = products[productId].custom && products[productId].modelingRequired;
    return modelingHardTriggers.includes(productId) || modelingRequired;
  });
}


export function shouldRequireModeling(estate) {
  const softTrigger = evaluateSoftTriggers(estate);
  const hardTrigger = evaluateHardTriggers(estate);
  return softTrigger || hardTrigger;
}
