import Rx from 'rxjs-legacy';
import { combineEpics } from 'redux-observable';
import morphey, { fromValue, fromKey } from 'morphey';

import { getSessionUser } from 'selectors';
import * as AlertsActions from 'actions/alerts';
import * as PaymentsActions from 'pods/transactions/actions/payments';
import * as SessionActions from 'actions/session';
import * as ApolloCacheActions from 'actions/cache-invalidation';
import { Bancontact, CreditCard, Ideal } from 'pods/transactions/utils/payments';
import { stripe } from 'pods/transactions/utils/payments/stripe';
import { translate as t, createTranslate } from 'utils/translation';
import { wrapError } from 'utils/errors';


const tt = createTranslate('pods.transactions');


function getCurrency(currency) {
  if ( ! currency) {
    return currency;
  }
  switch (currency) {
    case '$': {
      return 'usd';
    }
    case '€': {
      return 'eur';
    }
    case '£': {
      return 'gbp';
    }
    default: {
      return currency;
    }
  }
}


function extractDataFromTransaction(type, transaction) {
  return morphey(transaction, {
    type: fromValue(type),
    amount: fromKey('priceWithTaxes').using((v) => Math.round(v * 100)),
    currency: fromKey('currency').using(getCurrency).defaultTo('eur'),
    'owner.name': fromKey('buyer').using((v) => v.getName()),
    redirect: fromValue({
      return_url: window.location.origin + '/return',
    }),
  });
}


function payWithBancontact(action$, store, extra) {
  const { api } = extra;
  return action$
    .filter((action) => action.type === PaymentsActions.PAY_WITH_BANCONTACT)
    .switchMap((action) => {
      const { transaction } = action.payload;
      const data = extractDataFromTransaction('bancontact', transaction);
      return Bancontact.initRx(data)
        .find((result) => result.source.status === 'chargeable' || result.source.status === 'failed')
        .flatMap((result) => {
          if (result.source.status === 'chargeable') {
            const charge = { source: result.source.id, transaction };
            return Rx.Observable.fromPromise(api.mutations.createCharge(charge))
              .map(() => PaymentsActions.paymentSuccess(transaction))
              .catch((err) => Rx.Observable.concat(
                Rx.Observable.of(AlertsActions.showAlert(PaymentsActions.PAYMENT_FAIL, 'error', t('containers.alerts.transactions.payments.errors.failed'))),
                Rx.Observable.of(PaymentsActions.paymentFail(wrapError(err)))
              ));
          }
          else {
            return Rx.Observable.concat(
              Rx.Observable.of(PaymentsActions.paymentCancel()),
              Rx.Observable.of(AlertsActions.showAlert(PaymentsActions.PAYMENT_CANCEL, 'error', t('containers.alerts.transactions.payments.errors.cancelled')))
            );
          }
        })
        .catch((err) => Rx.Observable.of(PaymentsActions.paymentFail(err)));
    });
}


function payWithIdeal(action$, store, extra) {
  const { api } = extra;
  return action$
    .filter((action) => action.type === PaymentsActions.PAY_WITH_IDEAL)
    .switchMap((action) => {
      const { transaction } = action.payload;
      const data = extractDataFromTransaction('ideal', transaction);
      return Ideal.initRx(data)
        .find((result) => result.source.status === 'chargeable' || result.source.status === 'failed')
        .flatMap((result) => {
          if (result.source.status === 'chargeable') {
            const charge = { source: result.source.id, transaction };
            return Rx.Observable.fromPromise(api.mutations.createCharge(charge))
              .map(() => PaymentsActions.paymentSuccess(transaction))
              .catch((err) => Rx.Observable.concat(
                Rx.Observable.of(AlertsActions.showAlert(PaymentsActions.PAYMENT_FAIL, 'error', t('containers.alerts.transactions.payments.errors.failed'))),
                Rx.Observable.of(PaymentsActions.paymentFail(wrapError(err)))
              ));
          }
          else {
            return Rx.Observable.concat(
              Rx.Observable.of(PaymentsActions.paymentCancel()),
              Rx.Observable.of(AlertsActions.showAlert(PaymentsActions.PAYMENT_CANCEL, 'error', t('containers.alerts.transactions.payments.errors.cancelled')))
            );
          }
        })
        .catch((err) => Rx.Observable.of(PaymentsActions.paymentFail(err)));
    });
}


function payWithCreditCard(action$, store, extra) {
  const { api } = extra;
  return action$
    .filter((action) => action.type === PaymentsActions.PAY_WITH_CREDIT_CARD)
    .flatMap((action) => {
      const { transaction, creditCard } = action.payload;
      const data = {
        number: creditCard.cardNumber,
        cvc: creditCard.cardSecurityCode,
        exp_month: creditCard.month,
        exp_year: creditCard.year,
      };
      return CreditCard.initRx(data)
        .flatMap((tokenResponse) => {
          const handleAction = async (response) => {
            const { error, paymentIntent } = await stripe.handleCardAction(response.payment_intent_client_secret);

            if (error != null) {
              throw new Error(error.message);
            }

            const res = await api.mutations.confirmPayment({ id: transaction.id, paymentIntentId: paymentIntent.id });

            if (res.error != null) {
              const err = new Error();
              err.message = tt(res.error.message);
              throw err;
            }
            else if (res.requires_action) {
              return await handleAction(res);
            }

            return res;
          };

          const chargeCard = async () => {
            const res = await api.mutations.confirmPayment({ id: transaction.id, tokenId: tokenResponse.id });

            if (res.error != null) {
              const err = new Error();
              err.message = tt(res.error.message);
              throw err;
            }
            else if (res.requires_action) {
              return await handleAction(res);
            }

            return res;
          };

          return Rx.Observable.fromPromise(chargeCard());
        })
        .map(() => PaymentsActions.paymentSuccess(transaction))
        .catch((err) => {
          if (err.message) {
            return Rx.Observable.concat(
              Rx.Observable.of(PaymentsActions.paymentFail(err, { isCritical: false, ignoreLog: true })),
              Rx.Observable.of(AlertsActions.showAlert(PaymentsActions.PAYMENT_FAIL, 'error', err.message)),
            );
          }
          return Rx.Observable.of(PaymentsActions.paymentFail(err));
        });
    });
}


function showAlertAfterPayment(action$, store, extra) {
  return action$
    .filter((action) => action.type === PaymentsActions.PAYMENT_SUCCESS)
    .flatMap(() => Rx.Observable.concat(
      Rx.Observable.of(AlertsActions.showAlert(PaymentsActions.PAYMENT_SUCCESS, 'success', t('containers.alerts.transactions.payments.successful'))),
      Rx.Observable.of(ApolloCacheActions.invalidateQuery('ProjectsQuery')),
    ));
}


function redirectAfterPayment(action$, store, extra) {
  const { history } = extra;
  return action$
    .filter((action) => action.type === PaymentsActions.PAYMENT_SUCCESS)
    .map((action) => Rx.Observable.of(history.push('/')))
    .ignoreElements();  // Stop flow to not having "Actions must be an object..." error
}


function updateUserCredits(action$, store) {
  return action$
    .filter((action) => action.type === PaymentsActions.PAYMENT_SUCCESS)
    .flatMap((action) => {
      const { transaction } = action.payload;
      if (transaction.reason === 'buying_credits') {
        const { amount, bonus=0 } = transaction;
        const { id, credits } = getSessionUser(store.getState());
        return Rx.Observable.of(SessionActions.updateUserCredits(id, credits + bonus + amount));
      }
      else {
        return Rx.Observable.empty();
      }
    });
}


export default combineEpics(
  payWithBancontact,
  payWithIdeal,
  showAlertAfterPayment,
  payWithCreditCard,
  updateUserCredits,
  redirectAfterPayment,
);
