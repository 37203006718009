import sv from '@drawbotics/style-vars';
import dayjs from 'dayjs';
import { css } from 'emotion';
import gql from 'fraql';
import compose from 'lodash/flowRight';
import React from 'react';
import { Button, Icon } from 'react-ittsu/components';
import { RouteComponentProps } from 'react-router-dom';

import MainPanel from '~/pods/order/components/MainPanel';
import Title from '~/pods/order/components/Title';
import { readSession, run } from '~/utils';
import { createTranslate } from '~/utils/translation';

import { Transaction } from './Transaction';

const t = createTranslate('pods.payments.routes.confirmation');

const styles = {
  transactionConfirmation: css`
    @media ${sv.ipad} {
      margin-top: ${sv.baseMargin};
    }
  `,
  content: css`
    text-align: center;

    table {
      margin: ${sv.baseMargin} auto;
      text-align: left;

      td {
        padding: 5px calc(${sv.basePadding} * 0.5);

        &:last-of-type {
          color: ${sv.brandPrimary};
        }
      }
    }
  `,
  checkmark: css`
    width: 35px;
    height: 35px;
    border-radius: 1000px;
    font-size: 15px;
    background: ${sv.brandGreen};
    margin-right: ${sv.baseMarginSmall};
    color: ${sv.white};
    display: inline-flex;
    align-items: center;
    justify-content: center;
  `,
  gotIt: css`
    min-width: 180px;
    text-align: center;
    margin: ${sv.baseMargin} 0;
  `,
};

interface TransactionConfirmationProps extends RouteComponentProps {
  transaction: Transaction;
  setAsVisited: (step: string) => Promise<void>;
}

const TransactionConfirmation = ({ transaction, setAsVisited }: TransactionConfirmationProps) => {
  const { salesOrder } = transaction;
  const isUserLoggedIn = readSession() != null;

  const _goNext = async () => {
    const { estate } = transaction.paymentProcess ?? {};
    if (estate == null) return;
    if (isUserLoggedIn) {
      await setAsVisited('confirmation');
    }
    // Hard refresh to make sure all queries are reloaded
    window.location.assign(`/order/recap/${estate.id}`);
  };

  return (
    <MainPanel>
      <div className={styles.transactionConfirmation}>
        <Title>{t('title')}</Title>
        <div className={styles.content}>
          <div className={styles.checkmark}>
            <Icon name="check-bold" />
          </div>
          <p>{t('payment_processed')}</p>
          <p>{t('email_confirmation', { email: <strong>{transaction.clientEmail}</strong> })}</p>
          <table>
            <tbody>
              {run(() => {
                if (transaction.paymentProcess?.estate.name != null) {
                  return (
                    <tr>
                      <td>{t('project_title')}</td>
                      <td>{transaction.paymentProcess.estate.name}</td>
                    </tr>
                  );
                } else if (transaction.invoice != null) {
                  return (
                    <tr>
                      <td>{t('project_title')}</td>
                      <td>{transaction.invoice.reference}</td>
                    </tr>
                  );
                }
              })}
              {salesOrder?.clientReference ? (
                <tr>
                  <td>{t('internal_reference')}</td>
                  <td>{salesOrder.clientReference}</td>
                </tr>
              ) : null}
              <tr>
                <td>{t('order_date')}</td>
                <td>{dayjs(transaction.createdAt).format('l')}</td>
              </tr>
              {transaction.invoice?.url ? (
                <tr>
                  <td>{t('invoice')}</td>
                  <td>
                    <a href={transaction.invoice.url} target="_blank" rel="noopener noreferrer">
                      {t('download_pdf')}
                    </a>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
          <p>{t('text1')}</p>
          <p>{t('contact', { email: <strong>info@drawbotics.com</strong> })}</p>
          {isUserLoggedIn ? (
            <div className={styles.gotIt}>
              <Button round category="success" onClick={_goNext}>
                {t('got_it')}
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </MainPanel>
  );
};

TransactionConfirmation.fragments = {
  Transaction: gql`
    fragment _ on Transaction {
      clientEmail
      createdAt
      invoice {
        url
        reference
      }
      salesOrder {
        clientReference
      }
      paymentProcess {
        estate: orderEstate {
          id
          name
        }
      }
    }
  `,
  PublicTransaction: gql`
    fragment _ on Transaction {
      id
      clientEmail
      createdAt
      invoice {
        url
        reference
      }
      salesOrder {
        clientReference
      }
    }
  `,
};

export default compose()(TransactionConfirmation);
