import get from 'lodash/get';
import last from 'lodash/last';


/**
 * Runs a forEach operation.
 *
 * Example:
 * {
 *   operation: 'forEach',
 *   forEach: {
 *     threshold: 2,  // Don't run operations until threshold is reached
 *     // When value is a number, the minimum value to start with (can also be a string to be evaluated)
 *     minimum: 2,
 *     key: 'someKey', // If key value is a number, simply repeat operation n times
 *                     // If key is an array, change context to object inside array
 *     do: [
 *       {
 *         operation: 'add',
 *         add: { value: 10 },
 *       },
 *       {
 *         operation: 'add',
 *         add: { value: 20 },
 *       },
 *     ],
 *     // If foreach is an object, apply that operation to all the members
 *     // If foreach is an array, apply i operation to i member
 *   },
 * }
 */

function _isNumber(value) {
  return ! isNaN(value);
}


function _doNextOp(object, nextOp, doOperation, i) {
  if (Array.isArray(nextOp)) {
    nextOp = nextOp[i] == null ? last(nextOp) : nextOp[i];
    return doOperation(object, nextOp);
  }
  else {
    return doOperation(object, nextOp);
  }
}


function _computeMinimum(object, minimum, previousMinimum) {
  if (minimum == null || Number.isNaN(minimum)) {
    throw new Error(`Couldn't return a number for "${previousMinimum}"`);
  }
  else if (typeof minimum !== 'string') {
    return Math.floor(minimum);
  }
  else {
    const noVars = (s) => ! s.includes('@{');
    if (noVars(minimum)) {
      const calcMinimum = new Function(`"use strict";return ${minimum}`);
      return _computeMinimum(object, calcMinimum(), previousMinimum || minimum);
    }
    else {
      const vars = minimum.match(/@{([\d\w.]*)}/g);
      const finalMinimum = vars.reduce((memo, variable) => {
        const varKey = variable.replace('@{', '').replace('}', '').trim();
        const varValue = get(object, varKey);
        return memo.replace(variable, varValue);
      }, minimum);
      return _computeMinimum(object, finalMinimum, minimum);
    }
  }
}


export function doForEach(object, forEachDescription, doOperation) {
  const {
    key,
    threshold = 0,
    minimum = 0,
    limit = Infinity,
    do: nextOp,
  } = forEachDescription;
  let value = get(object, key);

  if (value == null) {
    return 0;
  }
  else if (Array.isArray(value)) {
    const times = value.length - threshold;
    if (times <= 0) {
      return 0;
    }
    return value.slice(threshold).reduce((memo, object, i) => {
      return memo + _doNextOp(object, nextOp, doOperation, i);
    }, 0);
  }
  else if (_isNumber(value)) {
    const finalMinimum = _computeMinimum(object, minimum);
    const times = value >= finalMinimum ? value : finalMinimum;
    if (times <= 0) {
      return 0;
    }
    return Array(times).fill(0).reduce((memo, _, i) => {
      if (i + 1 > threshold && i + 1 <= limit) {
        return memo + _doNextOp(object, nextOp, doOperation, i);
      }
      return memo;
    }, 0);
  }
  else {
    throw new Error(`Unknown type at key "${key}" in "forEach" operation. Should be an array or a number`);
  }
}


class ForEachOperation {
  constructor(key) {
    this.key = key;
  }

  threshold(threshold) {
    this._threshold = threshold;
    return this;
  }

  minimum(minimum) {
    this._minimum = minimum;
    return this;
  }

  limit(limit) {
    this._limit = limit;
    return this;
  }

  do(next) {
    return {
      operation: 'forEach',
      forEach: {
        key: this.key,
        threshold: this._threshold,
        minimum: this._minimum,
        limit: this._limit,
        do: next,
      },
    };
  }
}


export function ForEach(key) {
  return new ForEachOperation(key);
}
