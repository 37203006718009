import { graphql } from 'react-apollo-redux';
import compose from 'lodash/flowRight';
import { connect } from 'react-redux';

import Component from './Component';
import { RecapQuery, InitiateSalesOrderMutation, PayWithCreditsMutation, InitiateDirectPaymentMutation } from './graphql';
import { getSessionUser } from '~/selectors';


const mapStateToProps = (state, ownProps) => ({
  estateId: ownProps.match.params.estateId,
  sessionUser: getSessionUser(state),
});


export default compose(
  connect(mapStateToProps),
  graphql(RecapQuery, {
    name: 'recapData',
    options: (ownProps) => ({ variables: { estateId: ownProps.estateId }}),
  }),
  graphql(InitiateSalesOrderMutation, {
    name: 'initiateSalesOrder',
  }),
  graphql(PayWithCreditsMutation, {
    name: 'payWithCredits',
  }),
  graphql(InitiateDirectPaymentMutation, {
    name: 'initiateDirectPayment',
  }),
)(Component);
