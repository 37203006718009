import React, { Fragment } from 'react';
import gql from 'fraql';
import { Table, TCell, TRow, TBody } from 'react-ittsu/components';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';

import { createTranslate, translate as t } from '~/utils/translation';


const tt = createTranslate('pods.order.components.cart_item');


const styles = {
  itemsTable: css`
    padding-top: 0;
    padding-bottom: ${sv.basePaddingSmall};
    margin-top: calc(${sv.baseMarginSmall} * -1.5);
    margin-left: calc((${sv.baseMargin} * 3) - ${sv.baseMarginSmall});
    border-bottom: 1px solid ${sv.grey200};

    @media ${sv.phoneXl} {
      padding: ${sv.basePaddingSmall};
      margin-left: 0;
    }
  `,
  table: css`
    width: 100%;
    font-size: 0.9rem;
    table-layout: auto;

    & .Table__cell {
      color: ${sv.textPrimaryDark};
      padding: 5px;
    }
  `,
  tableTitle: css`
    text-transform: uppercase;
    font-family: ${sv.baseFontFamilyAlt};
    font-size: 0.7rem;
    color: ${sv.textSecondaryDark};
    font-weight: 700;
  `,
}


const TableRow = ({ items, withCurrency }) => {
  const totalPrice = items.reduce((price, item) => price + item.basePrice, 0);
  const serviceName = t(`services.${items[0].service.id}`);
  return (
    <TRow>
      <TCell>
        {serviceName}
      </TCell>
      <TCell>
        {items.length}
      </TCell>
      <TCell align="right">
        {withCurrency(totalPrice)}
      </TCell>
    </TRow>
  );
}


const TableHeader = () => {
  return (
    <TRow>
      <TCell>
        <div className={styles.tableTitle}>
          {tt('services')}
        </div>
      </TCell>
      <TCell>
        <div className={styles.tableTitle}>
          {tt('quantity')}
        </div>
      </TCell>
      <TCell align="right">
        <div className={styles.tableTitle}>
          {tt('total')}
        </div>
      </TCell>
    </TRow>
  );
}


const ItemsTable = ({ services, items, withCurrency, extraCosts=[] }) => {
  const sortedServices = Object.values(services).sort((s1, s2) => Number(s1[0].service.abstract) - Number(s2[0].service.abstract));
  return (
    <div className={styles.itemsTable}>
      <Table className={styles.table} variation="borderless">
        <TBody>
          <TableHeader />
          {sortedServices.map((service, i) => (
            <TableRow key={i} withCurrency={withCurrency} items={service.map((item) => items.find((itm) => itm.id === item.id))} />
          ))}
          {do{
            if (extraCosts.length > 0) {
              <Fragment>
                <TRow>
                  <TCell></TCell>
                </TRow>
                {extraCosts.map((cost, i) => (
                  <TRow key={i}>
                    <TCell>
                      {cost.raw ? cost.label : tt('extra_cost', { cost_name: t(`services.${cost.id}`)})}
                    </TCell>
                    <TCell></TCell>
                    <TCell align="right">
                      {cost.raw ? cost.price : withCurrency(cost.price)}
                    </TCell>
                  </TRow>
                ))}
              </Fragment>
            }
          }}
        </TBody>
      </Table>
    </div>
  );
}


ItemsTable.fragments = {
  ItemsTable: gql`
    fragment _ on Item {
      id
      price @client(type: Item)
      basePrice @client(type: Item)
      service {
        id @client(type: ProductService)
        abstract
      }
      product {
        id @client(type: Product)
        slug
      }
    },
  `,
}


export default ItemsTable;
