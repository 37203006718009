import React from 'react';
import { Table, THead, TBody, TRow, TCell, Button } from 'react-ittsu/components';
import PropTypes from 'prop-types';
import gql from 'fraql';
import autobind from 'autobind-decorator';
import { css, cx } from 'emotion';
import sv from '@drawbotics/style-vars';
import compose from 'lodash/flowRight';
import truncate from 'lodash/truncate';

import Title from '../components/Title';
import MainPanel from '../components/MainPanel';
import { getItemsByService } from '../utils';
import ServiceModal from '../components/ServiceModal';
import PrintInstructions from '../components/PrintInstructions';
import { withMedia } from '~/utils/media-provider';
import { withUser } from '~/utils/with-user';
import { createTranslate, translate as t } from '~/utils/translation';
import { shouldAskArea, shouldAskAddress } from './EstateDetails/utils';
import PriceLabel from '../components/PriceLabel';

import serviceImages from '../images/services';


const tt = createTranslate('pods.order.routes.estate_summary');


const styles = {
  header: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${sv.baseMargin};
    font-size: 0.7rem;
    width: 100%;
    padding: ${sv.basePaddingSmall};
    background: ${sv.grey100};
    border-radius: ${sv.baseBorderRadius};

    @media print {
      flex-direction: column;
      align-items: flex-start;
    }

    @media ${sv.ipadLandscape} {
      flex-direction: column;
      align-items: stretch;
      background: ${sv.grey100};
      padding: ${sv.basePaddingSmall};
    }
  `,
  headerItem: css`
    display: flex;
    align-items: baseline;

    @media print {
      margin-bottom: 10px;
    }

    @media ${sv.ipadLandscape} {
      margin-bottom: 10px;
      justify-content: space-between;

      &:last-child {
        margin-bottom: 0;
      }
    }
  `,
  headerItemLabel: css`
    color: ${sv.textTertiaryDark};
    text-transform: uppercase;
    font-family: ${sv.baseFontFamilyAlt};
    margin-right: 10px;
    font-weight: bold;
    font-size: 1.2em;
  `,
  headerItemValue: css`
    font-size: 1.2em;
    color: ${sv.textPrimaryDark};
    font-weight: 600;
    text-align: right;
    line-height: 1.1rem;

    @media ${sv.ipadLandscape} {
      margin-right: 0;
    }
  `,
  table: css`
    margin-bottom: ${sv.baseMargin};
    border-bottom: 1px solid ${sv.grey300};

    & th {
      color: ${sv.textTertiaryDark} !important;
      font-size: 0.8rem;
      font-weight: bold !important;
    }

    & td {
      color: ${sv.textPrimaryDark} !important;
    }

    @media ${sv.ipadLandscape} {
      & thead {
        display: none;
      }

      & [data-element="price"] {
        display: none;
      }
    }
  `,
  footer: css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media ${sv.ipadLandscape} {
      flex-direction: column-reverse;
    }

    @media ${sv.phoneXl} {
      align-items: flex-end;
    }
  `,
  actions: css`
    max-width: 350px;
    display: flex;
    flex: 1;
    align-items: flex-start;

    > button {
      margin-right: ${sv.baseMarginSmall};
      white-space: nowrap;

      &:last-child {
        margin-right: 0;
      }
    }

    @media print {
      opacity: 0;
    }
  `,
  totalContainer: css`
    @media ${sv.ipadLandscape} {
      margin-right: 0;
      margin-left: auto;
    }

    @media ${sv.phoneXl} {
      width: 100%;
      margin: auto;
      margin-bottom: ${sv.baseMarginSmall};
    }
  `,
  total: css`
    font-size: 1.6rem;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: ${sv.baseMarginSmall};
    color: ${sv.textPrimaryDark};

    span:first-child {
      font-size: 1.2rem;
    }

    span:last-child {
      font-weight: 600;
    }

    small {
      opacity: 0.8;
      font-size: 0.6em;
    }
  `,
  summaryRowFirstColumn: css`
    display: flex;
    align-items: center;
  `,
  summaryRowImage: css`
    width: 50px;
    height: 50px;
    margin-right: ${sv.baseMarginSmall};
    border-radius: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `,
  summaryRowUpdate: css`
    color: ${sv.brandBlue};
    font-size: 0.8rem;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    @media print {
      display: none;
    }
  `,
  discount: css`
    font-size: 0.9rem;
    justify-content: flex-end;
    font-weight: bold;
  `,
};


const HeaderItem = React.forwardRef(({
  label,
  value,
}, ref) => {
  return (
    <div className={styles.headerItem} ref={ref}>
      <div className={styles.headerItemLabel}>
        {label}
      </div>
      <div className={styles.headerItemValue} title={value}>
        {value}
      </div>
    </div>
  );
});


const ExtraSummaryRow = ({ title, price, amount }) => {
  return (
    <TRow>
      <TCell>
        <div className={styles.summaryRowFirstColumn} style={{ fontStyle: 'italic' }}>
          <div className={styles.summaryRowImage}>
          </div>
          <div className={styles.summaryRowName}>
            {title}
          </div>
        </div>
      </TCell>
      <TCell data-element="price">
        {price}
      </TCell>
      <TCell data-element="quantity">
      </TCell>
      <TCell align="right">
        {amount}
      </TCell>
    </TRow>
  );
}


const SummaryRow = compose(
  withUser({ provides: ['sessionUser'] }),
)(({
  items,
  isModifiable,
  onClickEdit,
  sessionUser,
}) => {
  const thumbnail = Object.values(serviceImages[items?.[0]?.service?.id] || {})?.[0];
  return (
    <TRow>
      <TCell>
        <div className={styles.summaryRowFirstColumn}>
          <div className={styles.summaryRowImage}>
            {do{
              if (thumbnail) {
                <img src={thumbnail} />
              }
            }}
          </div>
          <div>
            <div className={styles.summaryRowName}>
              {t(`services.${items[0].service.id}`)}
            </div>
            {do{
              if (isModifiable) {
                <div className={styles.summaryRowUpdate} onClick={onClickEdit}>
                  {tt('update_details')}
                </div>
              }
            }}
          </div>
        </div>
      </TCell>
      <TCell data-element="price">
        {sessionUser.addCurrency(items[0].basePrice)}
      </TCell>
      <TCell data-element="quantity">
        {items.length}
      </TCell>
      <TCell align="right">
        {sessionUser.addCurrency(items.reduce((total, item) => item.basePrice + total, 0))}
      </TCell>
    </TRow>
  );
});


class EstateSummary extends React.Component {

  static propTypes = {
    estate: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
      projectType: PropTypes.string.isRequired,
      unitCount: PropTypes.number.isRequired,
      address: PropTypes.object,
      items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        service: PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        }),
        estate: PropTypes.shape({
          delivery: PropTypes.string.isRequired,
        }),
      })),
    }).isRequired,
  };

  static fragments = {
    Item: () => gql`
      fragment _ on Item {
        id
        product {
          id @client(type: Product)
          service {
            id @client(type: ProductService)
            slug
          }
        }
        estate {
          delivery
        }
        price @client(type: Item)
        basePrice @client(type: Item)
      }
    `,
    Summary: () => gql`
      fragment _ on Estate {
        id
        projectType
        unitCount
        surface {
          unit
          value
        }
        address {
          id
          street
        }
        delivery
        modelingPrice @client(type: Estate)
        modelingRequired
        discount {
          rate
          amount
          discountType
        }
        discountedPrice {
          value
        }
        items {
          ${EstateSummary.fragments.Item()}
        }
      }
    `,
  };

  state = {
    activeService: null,
  };

  constructor(props) {
    super(props);
    this.headerUnits = React.createRef();
    this.headerType = React.createRef();
    this.headerLocation = React.createRef();
    this.header = React.createRef();
  }

  componentDidMount() {
    this._truncateAddress();
  }

  componentDidUpdate() {
    this._truncateAddress();
  }

  render() {
    const { activeService } = this.state;
    const { estate, goBack, push, history, mediaSize, sessionUser } = this.props;
    const itemsByService = getItemsByService(estate);
    const concreteServices = Object.keys(itemsByService).filter((serviceKey) => ! itemsByService[serviceKey][0].service.abstract);
    const extraServices = Object.keys(itemsByService).filter((serviceKey) => itemsByService[serviceKey][0].service.abstract);
    const estatePrice = estate.items.reduce((memo, i) => i.price + memo, 0) + estate.modelingPrice;
    return (
      <MainPanel
        hideWhenDisabled
        onClickBack={goBack}
        nextText={tt('confirm')}
        onClickNext={() => history.push(`/order/recap/${estate.id}`)}>
        <Title>{tt('summary', { project_name: estate.name })}</Title>
        <div className={styles.header} ref={this.header}>
          <HeaderItem label={tt('type')} value={t(`pods.order.project_type.${estate.projectType}`)} ref={this.headerType} />
          {do {
            if (shouldAskArea(estate)) {
              <HeaderItem label={tt('surface')} value={`${estate.surface.value} ${tt(estate.surface.unit)}`} ref={this.headerUnits} />
            }
          }}
          {do {
            if (shouldAskAddress(estate)) {
              <HeaderItem label={tt('location')} value={estate.address.label} ref={this.headerLocation} />
            }
          }}
        </div>
        <div className={styles.tableContainer}>
          <Table fullWidth className={styles.table}>
            <colgroup>
              <col style={{ width: mediaSize.isTablet ? 200 : 300 }}></col>
              <col></col>
              <col></col>
              {do{
                if ( ! mediaSize.isTablet) {
                  <col></col>
                }
              }}
            </colgroup>
            <THead>
              <TRow>
                <TCell>
                  {tt('service')}
                </TCell>
                <TCell style={{ whiteSpace: 'nowrap' }}>
                  {tt('unit_price')}
                </TCell>
                <TCell>
                  {tt('quantity')}
                </TCell>
                <TCell style={{ textAlign: 'right' }}>
                  {tt('amount')}
                </TCell>
              </TRow>
            </THead>
            <TBody>
              {concreteServices.map((serviceSlug) => (
                <SummaryRow
                  key={serviceSlug}
                  items={itemsByService[serviceSlug]}
                  isModifiable={true}
                  onClickEdit={() => this.setState({ activeService: itemsByService[serviceSlug][0].service })} />
              ))}
              {extraServices.map((serviceSlug) => (
                <SummaryRow
                  key={serviceSlug}
                  items={itemsByService[serviceSlug]}
                  isModifiable={false}
                  onClickEdit={() => this.setState({ activeService: itemsByService[serviceSlug][0].service })} />
              ))}
              {do{
                if (estate.modelingRequired) {
                  <ExtraSummaryRow
                    title={tt('extra_cost', { cost_name: t('services.modeling') })}
                    price={sessionUser.addCurrency(estate.baseModelingPrice)}
                    amount={sessionUser.addCurrency(estate.baseModelingPrice)} />
                }
              }}
              {do{
                if (estate.delivery === 'relaxed' || estate.delivery === 'express') {
                  <ExtraSummaryRow
                    title={t('pods.order.routes.estate_delivery.delivery', {
                      delivery_type: t(`pods.order.routes.estate_delivery.${estate.delivery}`),
                    })}
                    amount={estate.delivery === 'express' ? '+50%' : '-10%'} />
                }
              }}
            </TBody>
          </Table>
          <div className={styles.footer}>
            <div className={styles.actions}>
              <Button round onClick={() => push('services/visualization')} category="primary" size={mediaSize.isMobile ? 'small' : null}>{tt('add_services')}</Button>
              <Button round onClick={this._handlePrint} size={mediaSize.isMobile ? 'small' : null}>{tt('print_order')}</Button>
            </div>
            <div className={styles.totalContainer}>
              <div className={styles.total}>
                <span>{tt('total')} <small>({tt('taxes_excluded')})</small></span>
                <PriceLabel
                  style={{ marginLeft: 5 }}
                  amount={estatePrice}
                  discountedAmount={estate.discount != null ? estate.discountedPrice.value : null} />
              </div>
              {do{
                if (estate.discountedPrice && estate.discount) {
                  <div className={cx(styles.total, styles.discount)}>
                    {do {
                      if (estate.discount.discountType === 'percentage_discount') {
                        t('pods.order.components.discount_modal.discount', { rate: Math.round(estate.discount.rate * 100) })
                      }
                      else if (estate.discount.discountType === 'amount_discount') {
                        t('pods.order.components.discount_modal.discount_amount', {
                          amount: sessionUser.addCurrency(estate.discount.amount),
                        })
                      }
                    }}
                  </div>
                }
              }}
            </div>
          </div>
        </div>
        <ServiceModal
          isVisible={activeService != null}
          service={activeService}
          estate={estate}
          onModifyService={this._handleModifyService}
          onClickClose={() => this.setState({ activeService: null })} />
        <PrintInstructions />
      </MainPanel>
    );
  }

  @autobind
  async _handleModifyService(changes) {
    const { modifyService } = this.props;
    this.setState({ showError: false, activeService: null });
    modifyService(changes);
  }

  _truncateAddress() {
    const { mediaSize } = this.props;
    if (! mediaSize.isTablet && ! mediaSize.isMobile && this.headerLocation.current !== null) {
      const text = this.headerLocation.current.querySelector('div:nth-child(2)').innerText;
      const { width: headerWidth } = this.header.current.getBoundingClientRect();
      const { width: typeWidth } = this.headerType.current.getBoundingClientRect();
      const unitsWidth = this.headerUnits.current?.getBoundingClientRect()?.width || 0;
      const textWidth = text.length * 10;  // We assume each char is 10px on average
      const maxWidth = headerWidth - typeWidth - unitsWidth;
      if (textWidth >= maxWidth) {
        const maxTextChars = Math.floor(maxWidth / 10);
        this.headerLocation.current.querySelector('div:nth-child(2)').innerText = truncate(text, { length: maxTextChars });
      }
    }
  }

  @autobind
  _handlePrint() {
    const { estate } = this.props;
    const title = `${document.title}`;
    document.title = `Drawbotics - ${estate.name}`;
    print();
    document.title = title;
  }

}


export default compose(
  withMedia(),
  withUser(),
)(EstateSummary);
