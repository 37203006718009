import { useSelector } from 'react-redux';

import { getSession, getSessionUser } from '~/selectors';

interface SessionUser {
  id: string;
  credits: number;
  currency: {
    code: string;
    symbol: string;
  };
  firstName: string;
  lastName: string;
  session: Session;
}

interface Session {
  type: 'public' & string;
}

export function useLoadSession(): {
  sessionUser?: SessionUser;
  session?: Session;
} {
  const sessionUser = useSelector<SessionUser, SessionUser>(getSessionUser);
  const session = useSelector<Session, Session>(getSession);
  return { sessionUser, session };
}
