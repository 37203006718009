import React from 'react';
import { Grid, Cell } from 'react-ittsu/layout';
import autobind from 'autobind-decorator';
import get from 'lodash/get';
import { css } from 'emotion';
import sv, { darken } from '@drawbotics/style-vars';

import AnswerCell from '../shared/AnswerCell';
import { createTranslate } from '~/utils/translation';


const t = createTranslate('pods.order.services');
const tt = createTranslate('pods.order.components.questions_generator');


const styles = {
  grid: css`
    justify-content: center;

    @media ${sv.phone} {
      margin-top: ${sv.baseMarginSmall};
      margin-bottom: ${sv.baseMargin};
    }
  `,
  meta: css`
    margin-top: calc(2 * ${sv.baseMargin});
  `,
  noImage: css`
    height: 150px;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${sv.grey200};
    color: #9DC0FF;
    text-shadow: -1px 0 ${darken(sv.brandBlue, 30)},
      0 1px ${darken(sv.brandBlue, 30)},
      1px 0 ${darken(sv.brandBlue, 30)},
      0 -1px ${darken(sv.brandBlue, 30)};
    font-size: 3rem;
    font-family: ${sv.baseFontFamilyAlt};
    text-transform: uppercase;
    font-weight: 600;

    @media ${sv.phone} {
      height: 100px;
      font-size: 2rem;
      width: 200px;
    }
  `,
  multipleImages: css`
    display: flex;
    justify-content: center;
  `,
  image: css`
    margin-right: 10px;
    height: 300px;
    flex: 1;

    &:last-child {
      margin-right: 0;
    }

    & > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    @media ${sv.ipad} {
      height: 150px;
    }

    @media ${sv.phone} {
      height: 70px;
      margin-right: 5px;
    }
  `,
};


class SingleSelect extends React.Component {
  render() {
    const { values, answers, resultKey, modifyAnswer, metaComponent, estate, options } = this.props;
    const sectionAnswer = get(answers, resultKey);
    const cellsWithDisabled = values.map((answer) => typeof answer.disabled === 'function' ?
      { ...answer, disabled: answer.disabled(answers, estate) } : answer);
    return (
      <div>
        <Grid halfHGutters halfVGutters className={styles.grid}>
          {cellsWithDisabled.map((answer, i) => (
            <Cell
              size={answer.images
                ? `${answer.images.length}of${options?.gridSpan ?? 3}`
                : `1of${options?.gridSpan ?? 3}`
              }
              responsive={answer.images ? null : 'm1of2'}
              key={i}>
              <AnswerCell
                answer={answer}
                selected={sectionAnswer === answer.value}
                onClick={() => this._onClickCell(answer.value)}
                position={i}>
                {do{
                  if (answer.image) {
                    <img src={answer.image} />
                  }
                  else if (answer.images) {
                    <div className={styles.multipleImages}>
                      {answer.images.map((image, i) => (
                        <div key={i} className={styles.image}>
                          <img src={image} />
                        </div>
                      ))}
                    </div>
                  }
                  else if (typeof answer.value === 'boolean' && ! answer.image) {
                    <div className={styles.noImage}>
                      {answer.value ? tt('yes') : tt('no')}
                    </div>
                  }
                  else {
                    <div className={styles.noImage}>
                      {t(answer.label, null, answer.label)}
                    </div>
                  }
                }}
              </AnswerCell>
            </Cell>
          ))}
        </Grid>
        {do{
          if (metaComponent) {
            <div>
              <metaComponent.type {...metaComponent.props} answers={answers} modifyAnswer={modifyAnswer} />
            </div>
          }
        }}
      </div>
    );
  }

  @autobind
  _onClickCell(value) {
    const { values, modifyAnswer, resultKey, answers } = this.props;
    const sectionAnswer = get(answers, resultKey);
    const inactiveMetaKeys = values.reduce((inactiveKeys, value) =>
      sectionAnswer === value.value ? [ ...inactiveKeys, ...(value.meta?.components.map((c) => c.key) || []) ] : inactiveKeys, []);
    modifyAnswer(inactiveMetaKeys, null, true);
    modifyAnswer(resultKey, value);
  }
}


export default SingleSelect;
