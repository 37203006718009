import { css } from 'emotion';
import sv from '@drawbotics/style-vars';


export default {
  cart: css`
    position: relative;
    flex: 1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;

    &::before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    	background-color: rgba(66, 90, 112, 0.9);
      z-index: -1;
    }
  `,
  container: css`
    max-width: 800px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding-top: ${sv.basePadding} !important;
    padding-bottom: ${sv.basePadding} !important;
    overflow-y: auto;
    overflow-x: hidden;

    @media ${sv.phoneXl} {
      padding-top: ${sv.basePadding} !important;
      padding-bottom: calc(${sv.basePadding} * 4) !important;
    }
  `,
  panel: css`
    position: relative;
    padding: calc(${sv.basePadding} * 2);
    background: ${sv.white};
    border-radius: ${sv.baseBorderRadius};
    box-shadow: ${sv.elevation4};

    @media ${sv.ipad} {
      padding: ${sv.basePadding};
    }

    @media ${sv.phoneXl} {
      padding: 0;
      background: none;
      box-shadow: none;
    }
  `,
  title: css`
    color: ${sv.textPrimaryDark};
    text-align: center;
    margin-bottom: ${sv.baseMarginSmall};

    @media ${sv.phoneXl} {
      color: ${sv.white};
      text-align: left;
    }
  `,
  count: css`
    height: 30px;
    width: 30px;
    border-radius: 10000px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${sv.white};
    background: ${sv.brandPrimary};
    margin-left: ${sv.baseMarginSmall};
  `,
  newProject: css`
    position: absolute;
    z-index: 99;
    top: ${sv.baseMargin};
    right: ${sv.baseMargin};

    &:hover {
      & [data-element="help"] {
        opacity: 1;
        transform: translate(-50%, 10px);
      }
    }

    @media ${sv.phoneXl} {
      top: 0;
      right: 0;
    }
  `,
  newProjectButton: css`
    width: 40px;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${sv.white};
  `,
  help: css`
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 8px);
    padding: 5px;
    background: rgba(0, 0, 0, 0.7);
    color: ${sv.white};
    font-size: 0.7rem;
    white-space: nowrap;
    border-radius: ${sv.baseBorderRadius};
    opacity: 0;
    transition: all ${sv.baseTransitionTimeShort} ease-in-out;
  `,
  items: css`
  `,
  item: css`
    border-bottom: 2px solid ${sv.grey200};

    &:last-child {
      border-bottom: none;
    }

    @media ${sv.phoneXl} {
      border-bottom: none;
      margin-bottom: ${sv.baseMargin};
      box-shadow: ${sv.elevation4};
    }
  `,
  emptyState: css`
    background: ${sv.white};
    padding: ${sv.basePadding};
    border-radius: ${sv.baseBorderRadius};
  `,
  note: css`
    text-align: center;
    color: ${sv.textSecondaryDark};
  `,
  emptyImage: css`
    margin: ${sv.baseMargin};
  `,
  info: css`
    color: ${sv.white};
    font-style: italic;
    font-size: 0.8rem;
    text-align: center;
    opacity: 0.7;
    margin-bottom: ${sv.baseMargin};
    display: none;

    @media ${sv.phoneXl} {
      display: block;
      text-align: left;
    }
  `,
  continueShopping: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: ${sv.white};
    margin-top: ${sv.baseMargin};

    & > span {
      margin-left: 5px;
      transition: all ${sv.baseTransitionTimeShort} ease-in-out;
    }

    &:hover {
      cursor: pointer;

      & > span {
        transform: translateX(2px);
      }
    }
  `,
};
