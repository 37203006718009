import Rx from 'rxjs-legacy';
import { combineEpics } from 'redux-observable';

import * as ProjectsActions from 'pods/dashboard/actions/projects';


function getProjectWithStatus(projects) {
  return projects.map((project) => ({
    ...project,
    status: project.items.some((i) => i.status === 'ongoing') ? 'ongoing' :
      project.items.every((i) => i.status === 'finished') ? 'finished' : 'on_hold',
  }));
}


function loadProjects(action$, store, extra) {
  const { api } = extra;
  return action$
    .filter((action) => action.type === ProjectsActions.LOAD_PROJECTS)
    .mergeMap((action) => {
      const { config } = action.payload;
      return Rx.Observable.fromPromise(api.queries.getProjects(config))
        .flatMap(({ projects, meta }) => {
          const normalizedProjects = projects.reduce((prev, next) => ({
            ...prev,
            [next.id]: prev[next.id] ? { ...prev[next.id], items: [ ...prev[next.id].items, ...next.items ] } : next,
          }), {});
          const projectsWithStatus = getProjectWithStatus(Object.values(normalizedProjects));
          return Rx.Observable.concat(
            Rx.Observable.of(ProjectsActions.loadProjectsSuccess(projectsWithStatus)),
            Rx.Observable.of(ProjectsActions.setProjectsLastPage(meta.lastPage)),
          );
        })
        .catch((err) => Rx.Observable.concat(
          Rx.Observable.of(ProjectsActions.loadProjectsFail(err)),
        ));
    });
}


export default combineEpics(
  loadProjects,
);
