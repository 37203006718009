import sv from '@drawbotics/drylus-style-vars';
import {
  Align,
  Button,
  Category,
  Flex,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Label,
  Margin,
  Modal,
  Padding,
  Paragraph,
  Separator,
  Size,
  Spinner,
  Text,
  Tier,
} from '@drawbotics/react-drylus';
import React, { Fragment, useEffect, useState } from 'react';

import reopenProject from '~/images/reopen-project.svg';
import { createTranslate } from '~/utils/translation';

const tt = createTranslate('pods.dashboard.routes.item');

interface ReopenItemModalProps {
  onClickClose: VoidFunction;
  visible: boolean;
  onClickConfirm: () => Promise<void>;
  loading: boolean;
  price: string;
}

export const ReopenItemModal = ({
  onClickClose,
  onClickConfirm,
  visible,
  loading,
  price,
}: ReopenItemModalProps) => {
  const [isConfirmed, setIsConfirmed] = useState(false);

  useEffect(() => {
    if (visible === true) {
      setIsConfirmed(false);
    }
  }, [visible]);

  const handleClickConfirm = async () => {
    await onClickConfirm();
    setIsConfirmed(true);
  };

  return (
    <Modal
      visible={visible}
      onClickClose={onClickClose}
      title={isConfirmed ? undefined : tt('additional_service')}
      footer={
        isConfirmed ? null : (
          <Flex justify={FlexJustify.END}>
            <FlexItem>
              <Button tier={Tier.TERTIARY} onClick={onClickClose}>
                {tt('cancel')}
              </Button>
            </FlexItem>
            <FlexSpacer />
            <FlexItem>
              <Button
                trailing={loading ? <Spinner inversed size={Size.SMALL} /> : null}
                category={Category.BRAND}
                onClick={handleClickConfirm}>
                {tt('order')}
              </Button>
            </FlexItem>
          </Flex>
        )
      }>
      {isConfirmed ? (
        <Flex direction={FlexDirection.VERTICAL}>
          <FlexItem>
            <img src={reopenProject} />
          </FlexItem>
          <FlexSpacer />
          <FlexItem>
            <Paragraph>{tt('successful_reopen')}</Paragraph>
          </FlexItem>
          <FlexItem>
            <Button onClick={() => onClickClose()} category={Category.BRAND}>
              {tt('back_to_item')}
            </Button>
          </FlexItem>
        </Flex>
      ) : (
        <Fragment>
          <div style={{ background: sv.azureLight, borderRadius: sv.defaultBorderRadius }}>
            <Padding size={Size.SMALL}>
              <Flex justify={FlexJustify.SPACE_BETWEEN}>
                <FlexItem>
                  <Label>{tt('service')}</Label>
                </FlexItem>
                <FlexItem>
                  <Label>{tt('price')}</Label>
                </FlexItem>
              </Flex>
              <Margin size={{ vertical: Size.EXTRA_SMALL }}>
                <Separator />
              </Margin>
              <Flex justify={FlexJustify.SPACE_BETWEEN}>
                <FlexItem>
                  <Text>{tt('reopen_project')}</Text>
                </FlexItem>
                <FlexItem>
                  <Text>{price}</Text>
                </FlexItem>
              </Flex>
            </Padding>
          </div>
          <Paragraph align={Align.CENTER}>{tt('extra_cost')}</Paragraph>
        </Fragment>
      )}
    </Modal>
  );
};
