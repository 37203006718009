import React from 'react';
import cn from 'classnames';

import styles from 'styles/components/route-container';


const className = (props) => cn(styles.routeContainer, {
}, props.className);


const RouteContainer = (props) => {
  const {
    children,
  } = props;
  return (
    <div className={className(props)}>
      {children}
    </div>
  );
};


export default RouteContainer;
