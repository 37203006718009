import { createEntities } from 'entman';
import { startLoading, stopLoading } from 'loading-manager';

import schemas from 'schemas';


export const LOAD_PUBLIC_PROJECT = 'LOAD_PUBLIC_PROJECT';

export function loadPublicProject(token) {
  return {
    type: LOAD_PUBLIC_PROJECT,
    payload: { token },
    meta: { loading: startLoading('publicProject') },
  };
}


export const LOAD_PUBLIC_PROJECT_SUCCESS = 'LOAD_PUBLIC_PROJECT_SUCCESS';

export function loadPublicProjectSuccess(item) {
  return createEntities(schemas.ProjectItem, 'payload.item', {
    type: LOAD_PUBLIC_PROJECT_SUCCESS,
    payload: { item },
    meta: { loading: stopLoading('publicProject') },
  });
}


export const LOAD_PUBLIC_PROJECT_FAIL = 'LOAD_PUBLIC_PROJECT_FAIL';

export function loadPublicProjectFail(error) {
  return {
    type: LOAD_PUBLIC_PROJECT_FAIL,
    meta: { error, loading: stopLoading('publicProject') },
  };
}
