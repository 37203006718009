export const attachments = {
  exterior3d: [
    'floorPlans',
    'elevationPlans',
    'sectionPlans',
    'sitePlan',
    'facadeCovering',
    'outdoorFlooring',
    'doorAndWindowFrames',
    'referenceImages',
  ],
  interior3d: [
    'floorPlans',
    'elevationPlans',
    'sectionPlans',
    'sitePlan',
    'doorAndWindowFrames',
    'indoorWindowFrames',
    'referenceImages',
  ],
  shoebox: [
    'floorPlans',
    'elevationPlans',
    'sectionPlans',
    'sitePlan',
    'facadeCovering',
    'outdoorFlooring',
    'doorAndWindowFrames',
    'kitchenFlooring',
    'livingRoomFlooring',
    'kitchenMaterials',
    'bedroomFlooring',
    'bathroomFlooring',
    'wallTiles',
    'indoorWindowFrames',
    'referenceImages',
  ],
  sitePlan: [
    'floorPlans',
    'elevationPlans',
    'sectionPlans',
    'sitePlan',
    'facadeCovering',
    'outdoorFlooring',
    'referenceImages',
  ],
  commercialFloorplan: [
    'floorPlans',
    'elevationPlans',
    'sectionPlans',
    'sitePlan',
    'outdoorFlooring',
    'kitchenFlooring',
    'livingRoomFlooring',
    'bedroomFlooring',
    'bathroomFlooring',
    'referenceImages',
  ],
  model360: [
    'floorPlans',
    'facade',
    'sectionPlans',
    'sitePlan',
    'facadeCovering',
    'outdoorFlooring',
    'doorAndWindowFrames',
    'referenceImages',
  ],
  revo: [
    'floorPlans',
    'elevationPlans',
    'sectionPlans',
    'sitePlan',
    'facadeCovering',
    'outdoorFlooring',
    'doorAndWindowFrames',
    'kitchenFlooring',
    'livingRoomFlooring',
    'kitchenMaterials',
    'bedroomFlooring',
    'bathroomFlooring',
    'wallTiles',
    'indoorWindowFrames',
    'referenceImages',
  ],
  tour3d: [
    'floorPlans',
    'kitchenFlooring',
    'livingRoomFlooring',
    'kitchenMaterials',
    'bedroomFlooring',
    'bathroomFlooring',
    'wallTiles',
    'indoorWindowFrames',
    'referenceImages',
  ],
  exteriorTour3d: [
    'floorPlans',
    'elevationPlans',
    'sectionPlans',
    'sitePlan',
    'facadeCovering',
    'outdoorFlooring',
    'doorAndWindowFrames',
    'referenceImages',
  ],
  photoShooting: [
    'referenceImages',
  ],
  billboard: [
    'referenceImages',
    'companyLogo',
  ],
  brandId: [
    'referenceImages',
    'companyLogo',
  ],
  brochure: [
    'referenceImages',
    'companyLogo',
  ],
  customProduct: [
    'referenceImages',
  ],
  flyer: [
    'referenceImages',
    'companyLogo',
  ],
  landingPage: [
    'referenceImages',
    'companyLogo',
  ],
  mediaKit: [
    'referenceImages',
    'companyLogo',
  ],
  newsletter: [
    'referenceImages',
    'companyLogo',
  ],
  socialMediaKit: [
    'referenceImages',
    'companyLogo',
  ],
  videoAnimation: [
    'referenceImages',
    'companyLogo',
  ],
  vrHeadset: [
    'referenceImages',
  ],
  website: [
    'referenceImages',
    'companyLogo',
  ],

};


export const categories = {
  references: [
    'referenceImages',
    'companyLogo',
  ],
  architecturalDrawings: [
    'floorPlans',
    'elevationPlans',
    'sectionPlans',
    'sitePlan',
  ],
  materialsAndFinishes: [
    'kitchenMaterials',
    'kitchenFlooring',
    'livingRoomFlooring',
    'bedroomFlooring',
    'bathroomFlooring',
    'wallTiles',
    'outdoorFlooring',
    'doorAndWindowFrames',
    'indoorWindowFrames',
    'facadeCovering',
  ],
};
