import React from 'react';
import Modal from '@drawbotics/modal';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';

import Generator from './Generator';


const styles = {
  questionsGenerator: css`
    background: ${sv.grey100};
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
  `,
};


class QuestionsGenerator extends React.Component {
  render() {
    const { visible } = this.props;
    return (
      <Modal isVisible={visible}>
        <div className={styles.questionsGenerator} ref={(generator) => this.generator = generator}>
          <Generator {...this.props} generatorElement={this.generator} />
        </div>
      </Modal>
    );
  }
}


export default QuestionsGenerator;
