export default (request, entities, translators) => ({
  findAll({ page, status }) {
    return request('GET', `estates?for_org=true&page=${page}&status=${status}`)
      .then((estates) => ({
        estates: estates.estates.map((estate) => ({
          ...estate.estate,
          items: estate.items.map((item) => translators.ProjectItem.fromServer(item.item)),
        })),
        lastPage: estates.last_page,
      }))
      .then(({ estates, lastPage }) => ({
        projects: estates.map(translators.Project.fromServer),
        meta: { lastPage },
      }));
  },
  getData(projectId) {
    return request('GET', `/api/data/${projectId}`, undefined, 'analytics')
      .then((data) => data.data);
  },
  getViewsOverTime(projectId) {
    return request('GET', `/api/queries/views/${projectId}`, undefined, 'analytics')
      .then((data) => data.data);
  },
  getSessionLocations(projectId) {
    return request('GET', `/api/queries/locations/${projectId}`, undefined, 'analytics')
      .then((data) => data.data);
  },
  getVisits(projectId) {
    return request('GET', `/api/queries/visits/${projectId}`, undefined, 'analytics')
      .then((data) => data.data);
  },
  getMostFavorited(projectId) {
    return request('GET', `/api/queries/favorites/${projectId}`, undefined, 'analytics')
      .then((data) => data.data);
  },
  getTimeSpent(projectId) {
    return request('GET', `/api/queries/time-spent/${projectId}`, undefined, 'analytics')
      .then((data) => data.data);
  },
});
