import { Module } from '@tokamakjs/react';

import { AuthService, RouterService } from '~/services';

import { ProjectsApi } from './api';
import { ProjectsService } from './services';
import { ProjectsStore } from './stores';

@Module({
  providers: [ProjectsApi, ProjectsStore, ProjectsService, AuthService, RouterService],
})
export class DashboardModule {}
