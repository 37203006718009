import React from 'react';
import { Grid, Cell, Offset } from 'react-ittsu/layout';
import PropTypes from 'prop-types';
import gql from 'fraql';
import autobind from 'autobind-decorator';
import compose from 'lodash/flowRight';

import Title from '../components/Title';
import MainPanel from '../components/MainPanel';
import OrderOption from '../components/OrderOption';
import ErrorMessage from '../components/ErrorMessage';
import { withMedia } from '~/utils/media-provider';
import { createTranslate, translate as t } from '~/utils/translation';
import EstateResetConfirmModal from '../components/EstateResetConfirmModal';
import { EstateEnums } from '../utils/estate-enums';

import residentialIcon from '../images/icons/residential-icon.svg';
import commercialIcon from '../images/icons/commercial-icon.svg';


const tt = createTranslate('pods.order.routes.estate_type');


class EstateType extends React.Component {

  static propTypes = {
    estate: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      projectType: PropTypes.string,
      isNewDevelopment: PropTypes.bool.isRequired,
    }).isRequired,
    updateEstate: PropTypes.func.isRequired,
  };

  static fragments = {
    Type: gql`
      fragment _ on Estate {
        id
        projectType
        propertyType
        isNewDevelopment @client(type: Estate)
        jsonMeta @client(type: Estate)
      }
    `,
  };

  state = {
    showError: false,
  };

  render() {
    const { estate, goBack, mediaSize } = this.props;
    const { showError } = this.state;
    return (
      <MainPanel
        onClickBack={goBack}
        onClickNext={this._goNext}>
        <Title>
          {tt('type_of_property', { property: t(`pods.order.property_type.${estate.propertyType}`) })}
        </Title>
        {do {
          if (showError) {
            <ErrorMessage>
              {tt('errors.select_project')}
            </ErrorMessage>
          }
        }}
        <EstateResetConfirmModal>
          {(confirm) => (
            <Grid withHGutters withVGutters halfVGutters={mediaSize.isMobile}>
            <Offset size="1of6" style={{ display: mediaSize.isTablet ? 'none' : null }} />
              <Cell size="2of6">
                <OrderOption
                  image={residentialIcon}
                  title={t('pods.order.project_type.residential')}
                  checked={estate.projectType === EstateEnums.ProjectType.RESIDENTIAL}
                  onClick={() => this._updateType(EstateEnums.ProjectType.RESIDENTIAL, confirm)}>
                </OrderOption>
              </Cell>
              <Cell size="2of6">
                <OrderOption
                  image={commercialIcon}
                  title={t('pods.order.project_type.commercial')}
                  checked={estate.projectType === EstateEnums.ProjectType.COMMERCIAL}
                  onClick={() => this._updateType(EstateEnums.ProjectType.COMMERCIAL, confirm)}>
                </OrderOption>
              </Cell>
              <Offset size="1of6" style={{ display: mediaSize.isTablet ? 'none' : null }} />
            </Grid>
          )}
        </EstateResetConfirmModal>
      </MainPanel>
    );
  }

  @autobind
  async _updateType(projectType, confirm) {
    const { updateEstate, estate, resetEstate } = this.props;
    if (estate.projectType === projectType) {
      return;
    }

    const shouldReset = estate.projectType != null;
    const shouldAskConfirm = Object.keys(estate.jsonMeta.steps).includes('subtype');
    const askConfirm = shouldAskConfirm ? confirm : () => true;
    const confirmed = ! shouldReset || (askConfirm && await askConfirm());
    if (confirmed) {
      if (shouldReset) {
        await resetEstate({
          projectType: EstateEnums.ProjectType.NONE,
          projectSubtype: EstateEnums.ProjectSubtype.NONE,
        });
      }
      const resetSteps = {
        'property-type': estate.jsonMeta.steps?.['property-type'],
        'type': estate.jsonMeta.steps?.type,
      };
      const updatedEstate = await updateEstate({
        projectType,
        metadata: JSON.stringify({ ...estate.jsonMeta, steps: resetSteps }),
      });
      if (updatedEstate.projectType !== projectType) {
        console.error('Something happened when updating the projectType');
      }
      else {
        this.setState({ showError: false });
      }
    }
  }

  @autobind
  _goNext() {
    const { estate, goNext } = this.props;
    estate.projectType ? goNext() : this.setState({ showError: true });
  }

}


export default compose(
  withMedia(),
)(EstateType);
