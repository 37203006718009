import get from 'lodash/get';

import { NETWORK_ERROR, GRAPHQL_ERROR } from 'utils/errors';
import * as ErrorsActions from 'actions/errors';
import * as AlertsActions from 'actions/alerts';
import reportException from './bootstrap/report-exception';


/**
 * We cannot guarantee that console.reportException is gonna
 * be available because middlewares are executed before reportException
 * is added to the console.
 */
const reporter = (err, extra) => console.reportException ?
  console.reportException(err, extra) : reportException(err, { extra });


function reportError(error, state, action) {
  reporter(error, {
    info: error.info,
    action,
    state,
    from: 'utils/handle-errors-middleware',
  });
}


function handleError(error, store) {
  if (error.isCritical) {
    store.dispatch(ErrorsActions.triggerCritical());
  }
  else if (error.type === NETWORK_ERROR) {
    if (String(error.info.status) === '500') {
      store.dispatch(ErrorsActions.triggerCritical());
    }
    if (String(error.info.status) === '404') {
      store.dispatch(ErrorsActions.triggerNotFound());
    }
  }
  else if (error.type === GRAPHQL_ERROR) {
    if (error.info.message.match(/not found/)) {
      store.dispatch(ErrorsActions.triggerNotFound());
    }
    else if (error.info.message.match(/translatable_error|translated_error/)) {
      const translation = error.info.message.split('#')[1];
      console.warn('Translated error:', translation);
      store.dispatch(AlertsActions.showAlert('GRAPHQL_ERROR/STRIPE_ERROR', 'error', translation));
    }
    else if (error.info.message.match(/stripe_error/)) {
      const translation = error.info.message.split('#')[1];
      console.warn('Translated error:', translation);
      store.dispatch(AlertsActions.showAlert('GRAPHQL_ERROR/STRIPE_ERROR', 'error', translation));
    }
    else {
      store.dispatch(ErrorsActions.triggerCritical());
    }
  }
  else {
    console.warn('Unhandled error:', error);
  }
}


export default function createHandleErrorsMiddleware() {
  return (store) => (next) => (action) => {
    try {
      const error = get(action, 'meta.error');
      const ignoreLog = get(action, 'meta.ignoreLog');
      if (error && ! ignoreLog) {
        reportError(error, store.getState(), action);
        handleError(error, store);
      }
      next(action);
    }
    catch (error) {
      reportError(error, store.getState(), action);
      handleError(error, store);
    }
  };
}
