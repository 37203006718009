import { Add, Switch, RelativeAdd, Conditional, ForEach } from 'drawbotics-pricer';


const description = {
  Base: [
    Add(5),
    Switch('estate.projectSubtype').is({
      'house': [
        Switch('details.style').is({
          'standard': [
            Add({ EUR: 15, GBP: 14, USD: 18 }),
          ],
          'black_and_white': [
            Add({ EUR: 15, GBP: 14, USD: 18 }),
          ],
          'xyz': [
            Add({ EUR: 20, GBP: 18, USD: 24 }),
          ],
          'premium': [
            Add({ EUR: 25, GBP: 23, USD: 30 }),
          ],
        }),
        Conditional('details.dimensions').equals(true).then([
          Add(3),
        ]),
        Conditional('details.surfaceArea').equals(true).then([
          Add(3),
        ]),
        Conditional('details.view3d').equals(true).then([
          Add(4),
        ]),
      ],
      'duplex': [
        Switch('details.style').is({
          'standard': [
            Add({ EUR: 15, GBP: 14, USD: 18 }),
          ],
          'black_and_white': [
            Add({ EUR: 15, GBP: 14, USD: 18 }),
          ],
          'xyz': [
            Add({ EUR: 20, GBP: 18, USD: 24 }),
          ],
          'premium': [
            Add({ EUR: 25, GBP: 23, USD: 30 }),
          ],
        }),
        Conditional('details.dimensions').equals(true).then([
          Add(3),
        ]),
        Conditional('details.surfaceArea').equals(true).then([
          Add(3),
        ]),
        Conditional('details.view3d').equals(true).then([
          Add(4),
        ]),
      ],
      'apartment': [
        Switch('details.style').is({
          'standard': [
            Add({ EUR: 10, GBP: 9, USD: 12 }),
          ],
          'black_and_white': [
            Add({ EUR: 10, GBP: 9, USD: 12 }),
          ],
          'xyz': [
            Add({ EUR: 13, GBP: 12, USD: 16 }),
          ],
          'premium': [
            Add({ EUR: 17, GBP: 16, USD: 21 }),
          ],
        }),
        Conditional('details.dimensions').equals(true).then([
          Add(1),
        ]),
        Conditional('details.surfaceArea').equals(true).then([
          Add(1),
        ]),
        Conditional('details.view3d').equals(true).then([
          Add(2),
        ]),
      ],
      'building': [
        Switch('details.style').is({
          'standard': [
            ForEach('estate.surface.value').minimum(100).do([
              Add(0.1),
            ]),
          ],
          'black_and_white': [
            ForEach('estate.surface.value').minimum(100).do([
              Add(0.1),
            ]),
          ],
          'xyz': [
            ForEach('estate.surface.value').minimum(100).do([
              Add(0.15),
            ]),
          ],
          'premium': [
            ForEach('estate.surface.value').minimum(100).do([
              Add(0.2),
            ]),
          ],
        }),
        Conditional('details.dimensions').equals(true).then([
          ForEach('estate.surface.value').minimum(100).do([
            Add(0.01),
          ]),
        ]),
        Conditional('details.surfaceArea').equals(true).then([
          ForEach('estate.surface.value').minimum(100).do([
            Add(0.01),
          ]),
        ]),
        Conditional('details.view3d').equals(true).then([
          ForEach('estate.surface.value').minimum(100).do([
            Add(0.04),
          ]),
        ]),
      ],
      'commercial': [
        Switch('details.style').is({
          'standard': [
            ForEach('estate.surface.value').minimum(100).do([
              Add(0.1),
            ]),
          ],
          'black_and_white': [
            ForEach('estate.surface.value').minimum(100).do([
              Add(0.1),
            ]),
          ],
          'xyz': [
            ForEach('estate.surface.value').minimum(100).do([
              Add(0.15),
            ]),
          ],
          'premium': [
            ForEach('estate.surface.value').minimum(100).do([
              Add(0.2),
            ]),
          ],
        }),
        Conditional('details.dimensions').equals(true).then([
          ForEach('estate.surface.value').minimum(100).do([
            Add(0.01),
          ]),
        ]),
        Conditional('details.surfaceArea').equals(true).then([
          ForEach('estate.surface.value').minimum(100).do([
            Add(0.01),
          ]),
        ]),
        Conditional('details.view3d').equals(true).then([
          ForEach('estate.surface.value').minimum(100).do([
            Add(0.04),
          ]),
        ]),
      ],
    }),
    Conditional('details.logo').equals(true).then([
      Add(2),
    ]),
    Conditional('details.disclaimer').equals(true).then([
      Add(2)
    ]),
    Conditional('details.garden').equals(true).then([
      Switch('estate.projectSubtype').is({
        'house': [Add(4)],
        'duplex': [Add(4)],
        'apartment': [Add(4)],
        'building': [
          ForEach('estate.surface.value').minimum(400).do([
            Add(0.01),
          ]),
        ],
        'commercial': [
          ForEach('estate.surface.value').minimum(400).do([
            Add(0.01),
          ]),
        ],
      })
    ]),
  ],
  Delivery: [
    Switch('estate.delivery').is({
      'relaxed': RelativeAdd(-0.1).from('Base'),
      'standard': RelativeAdd(0).from('Base'),
      'express': RelativeAdd(0.5).from('Base'),
    })
  ],
};


export default description;
