import v4 from 'uuid/v4';
import dayjs from 'dayjs';


export const SHOW_ALERT = 'SHOW_ALERT';

export function showAlert(type, category, message) {
  return {
    type: SHOW_ALERT,
    payload: { id: v4(), type, category, message, createdAt: dayjs() },
  };
}


export const HIDE_ALERT = 'HIDE_ALERT';

export function hideAlert(id) {
  return {
    type: HIDE_ALERT,
    payload: { id },
  };
}
