import buildUrl from 'url-join';
import { createClient, cacheExchange, dedupExchange, fetchExchange } from 'urql';
import { computedExchange } from 'urql-computed-exchange';

import entities from '~/entities';
import { readSession } from '~/utils';
import { GRAPHQL_HOST, GRAPHQL_BASEPATH } from '../constants';

export default createClient({
  url: buildUrl(GRAPHQL_HOST, GRAPHQL_BASEPATH),
  exchanges: [
    dedupExchange,
    cacheExchange,
    computedExchange({ entities }),
    fetchExchange,
  ],
  fetchOptions: () => {
    const authToken = readSession()?.authToken;
    if ( ! authToken) {
      return {};
    }

    if (authToken.length > 30) {
      return {
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      }
    }

    return {
      headers: {
        authorization: `Token token=${authToken}`,
      },
    };
  },
});
