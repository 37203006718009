import v4 from 'uuid/v4';
import { deleteEntities, createEntities, updateEntityId, updateEntities } from 'entman';


import schemas from 'schemas';


export const LOAD_INVITED_USER = 'LOAD_INVITED_USER';

export function loadInvitedUser(token) {
  return {
    type: LOAD_INVITED_USER,
    payload: {
      token,
    },
  };
}


export const LOAD_INVITED_USER_SUCCESS = 'LOAD_INVITED_USER_SUCCESS';

export function loadInvitedUserSuccess(user) {
  return {
    type: LOAD_INVITED_USER_SUCCESS,
    payload: {
      user,
    },
  };
}


export const LOAD_INVITED_USER_FAIL = 'LOAD_INVITED_USER_FAIL';

export function loadInvitedUserFail(error) {
  return {
    type: LOAD_INVITED_USER_FAIL,
    meta: { error },
  };
}


export const CREATE_INVITATION = 'CREATE_INVITATION';

export function createInvitation(invitation, item, order) {
  return createEntities(schemas.Invitation, 'payload.invitation', {
    type: CREATE_INVITATION,
    payload: {
      invitation: {
        ...invitation,
        user: {
          ...invitation.user,
          id: v4(),
        },
        id: v4(),
      },
      item,
      order,
    },
  });
}


export const CREATE_INVITATION_SUCCESS = 'CREATE_INVITATION_SUCCESS';

export function createInvitationSuccess(oldId, invitation) {
  return updateEntityId(schemas.Invitation, oldId, invitation.id, {
    type: CREATE_INVITATION_SUCCESS,
    payload: {
      invitation,
    },
  });
}


export const UPDATE_INVITATION = 'UPDATE_INVITATION';

export function updateInvitation(invitation, noServer) {
  return updateEntities(schemas.Invitation, invitation.id, 'payload.invitation', {
    type: UPDATE_INVITATION,
    payload: {
      invitation,
      noServer,
    },
  });
}


export const UPDATE_INVITATION_SUCCESS = 'UPDATE_INVITATION_SUCCESS';

export function updateInvitationSuccess(invitation) {
  return {
    type: UPDATE_INVITATION_SUCCESS,
    payload: {
      invitation,
    },
  };
}


export const UPDATE_INVITATION_FAIL = 'UPDATE_INVITATION_FAIL';

export function updateInvitationFail(error) {
  return {
    type: UPDATE_INVITATION_FAIL,
    meta: { error },
  };
}


export const CREATE_INVITATION_FAIL = 'CREATE_INVITATION_FAIL';

export function createInvitationFail(error, invitation) {
  return {
    type: CREATE_INVITATION_FAIL,
    payload: { invitation },
    meta: { error },
  };
}


export const DELETE_INVITATION = 'DELETE_INVITATION';

export function deleteInvitation(invitation, noServer) {
  return deleteEntities(schemas.Invitation, invitation.id, {
    type: DELETE_INVITATION,
    payload: { invitation, noServer },
  });
}
