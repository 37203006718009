import morphey from 'morphey';


const translationsFromServer = {
  id: 'id',
  fieldType: 'field_type',
  name: 'filename',
  signedId: 'signed_id',
};


export function attachmentFromServer(attachment) {
  return morphey(attachment, translationsFromServer);
}


const translateToServer = {
  signed_id: 'signedId',
  'infos.field_type': 'fieldType',
  'infos.target_type': 'targetType',
  'infos.target_id': 'targetId',
};


export function attachmentToServer(attachment) {
  return morphey(attachment, translateToServer);
}
