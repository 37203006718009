import React from 'react';
import sv from '@drawbotics/style-vars';
import { Label } from 'react-ittsu/forms';

import { createTranslate } from '~/utils/translation';


const t = createTranslate('pods.order.services');


const Title = (props) => {
  const { label } = props;
  return (
    <Label style={{ marginTop: sv.baseMargin }}>
      {t(label, null, label)}
    </Label>
  );
};


export default Title;
