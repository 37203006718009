import React from 'react';
import autobind from 'autobind-decorator';
import { Grid, Cell, Offset } from 'react-ittsu/layout';
import gql from 'fraql';

import BaseServiceModal from '../BaseServiceModal';
import Paragraph from '../Paragraph';
import SectionTitle from '../SectionTitle';
import { ProductCard, Stripe, StripeGroup } from '../../forms';
import { priceItem, applyPrice } from '../../../utils/pricing';
import { specsToItems, itemsToSpecs } from '~/pods/order/utils/services/specs-to-items';
import applyChanges from '~/pods/order/utils/services/specs-to-items/apply-changes';
import { getItemsByService, getFeaturesForProduct, openIntercom, merge } from '~/pods/order/utils';
import { createTranslate, translate as t } from '~/utils/translation';
import { EstateEnums } from '~/pods/order/utils/estate-enums';


const tt = createTranslate('pods.order.services.video_animation.order');
const tm = createTranslate('pods.order.components.service_modal');


const videos = [
  'https://player.vimeo.com/external/292918907.hd.mp4?s=b87ccbc35aa5a9fc628fba769ab6e3fcd5890d3f&profile_id=174',
  'https://player.vimeo.com/external/292698433.hd.mp4?s=36cb4f9f33fa574844412286b8bbcd86e8b9dabc&profile_id=174',
  'https://player.vimeo.com/external/268173386.hd.mp4?s=e1203fdfee2696bf93adf335edbfa3949c6dcc6e&profile_id=174',
];


class VideoAnimation extends React.Component {

  static fragments = {
    Item: gql`
      fragment _ on Item {
        id
        details {
          ... on VideoAnimationDetails {
            droneShooting
            duration
          }
        }
        product {
          id @client(type: Product)
          custom
          name
          features {
            locale
            content
          }
        }
      }
    `,
  }

  state = {
    custom: null,
    specs: {
      droneShooting: false,
      standard: false,
      premium: false,
      duration: 45,
    },
    price: undefined,
  };

  componentDidUpdate(prevProps) {
    const { service, estate } = this.props;
    const { service: prevService } = prevProps;
    if (service?.id === 'videoAnimation' && ! prevService) {
      const specs = itemsToSpecs({ estate, service });
      const items = getItemsByService(estate).videoAnimation || [];
      const customItem = items.find((item) => item.product.custom);
      const price = items != null ? items.reduce((memo, i) => memo + priceItem(i, this.props.sessionUser.currency.code, true), 0) : 0;
      this.setState({ specs: merge(this.state.specs, specs), price, custom: customItem ? true : null });
    }
  }

  render() {
    const { onClickClose, isVisible, service, estate, sessionUser } = this.props;
    const { specs: { standard, premium, droneShooting }, custom, price } = this.state;
    const items = getItemsByService(estate).videoAnimation || [];
    const customItem = items.find((item) => item.product.custom);
    const customFeatures = custom !== null ? getFeaturesForProduct(customItem?.product) : null;
    const isResidential = estate.projectType === EstateEnums.ProjectType.RESIDENTIAL;
    return (
      <BaseServiceModal
        isVisible={isVisible && service?.id === 'videoAnimation'}
        title={service?.id ? t(`services.${service.id}`) : ''}
        videos={videos}
        autoplayGallery={false}
        price={sessionUser.addCurrency(price)}
        disclaimer={{
          label: tt('requires_modeling.text'),
          description: tt('requires_modeling.info'),
        }}
        priceDisclaimer={tt('price_excludes_modeling')}
        onClickConfirm={this._handleClickConfirm}
        confirmAsUpdate={items?.length > 0}
        onClickClose={onClickClose}
        addDisabled={price === 0}>
        <div className="childcontent">
          <Paragraph>
            {tt('description')}
          </Paragraph>
          <SectionTitle>
            {tt('select_type')}
          </SectionTitle>
          <Grid withVGutters withHGutters>
            {isResidential ?
              <Cell size="2of4">
                <ProductCard
                  name="standard"
                  label={tm('standard')}
                  note={tm('from_price', { price: applyPrice({ EUR: 2500, USD: 3000, GBP: 2000 }, sessionUser) })}
                  fullHeight
                  formComponent="radio"
                  nonFeatures={[{
                    text: tt('non_features.first'),
                  }, {
                    text: tt('non_features.second'),
                  }, {
                    text: tt('non_features.third.text'),
                    infoText: tt('non_features.third.info'),
                  }]}
                  features={[{
                    text: tt('features.first.text'),
                    infoText: tt('features.first.info'),
                  }, {
                    text: tt('features.second.text'),
                    infoText: tt('features.second.info'),
                  }, {
                    text: tt('features.third.text'),
                    infoText: tt('features.third.info'),
                  }, {
                    text: tt('features.fourth.text'),
                    infoText: tt('features.fourth.info'),
                  }]}
                  value={standard}
                  onChange={this._handleClickOption} />
              </Cell> : null}
            <Cell size={isResidential ? '2of4' : '1of2'}>
              <ProductCard
                name="premium"
                label={tm('premium')}
                note={tm('from_price', { price: applyPrice(isResidential ? { EUR: 9500, USD: 15000, GBP: 9500 } : { EUR: 12500, USD: 17500, GBP: 12500 }, sessionUser) })}
                features={[{
                  text: tt('premium_features.first'),
                }, {
                  text: tt('premium_features.second'),
                }, {
                  text: tt('premium_features.third'),
                }, {
                  text: tt('premium_features.fourth.text'),
                  infoText: tt('premium_features.fourth.info'),
                }, {
                  text: tt('premium_features.fifth.text'),
                  infoText: tt('premium_features.fifth.info'),
                }, {
                  text: tt('premium_features.sixth.text'),
                  infoText: tt('premium_features.sixth.info'),
                }, {
                  text: tt('premium_features.seventh.text'),
                  infoText: tt('premium_features.seventh.info'),
                }]}
                formComponent="radio"
                fullHeight
                onChange={this._handleClickOption}
                value={premium} />
            </Cell>
            {isResidential ? <Offset size="1of4" /> : null}
            <Cell size={isResidential ? '2of4' : '1of2'}>
              <ProductCard
                name="custom"
                label={custom !== null ? `${customItem?.product.name}` : tm('custom')}
                note={custom !== null && customItem ? `${sessionUser.addCurrency(customItem?.price)}` : tm('contact_us')}
                formComponent="radio"
                fullHeight
                features={customFeatures}
                onChange={this._handleClickCustom}
                value={!! custom} />
            </Cell>
            {isResidential ? <Offset size="1of4" /> : null}
          </Grid>
          <SectionTitle>
            {tt('environment')}
          </SectionTitle>
          <StripeGroup>
            <Stripe
              title={tt('drone_shooting')}
              subtitle={tm('from_price', { price: applyPrice({ EUR: 1200, USD: 1550, GBP: 1200 }, sessionUser) })}
              component="switch"
              features={[{
                text: tt('drone_shooting_features.first'),
              }, {
                text: tt('drone_shooting_features.second.text'),
              }]}
              disabled={! premium && ! standard}
              value={droneShooting}
              onChange={() => this.setState({ specs: { ...this.state.specs, droneShooting: ! droneShooting }}, this._syncState)} />
          </StripeGroup>
        </div>
      </BaseServiceModal>
    );
  }

  @autobind
  _syncState() {
    const { estate, service } = this.props;
    const { specs } = this.state;
    const changes = specsToItems({ specs, estate, service });
    const updatedEstate = applyChanges(changes, estate);
    const items = getItemsByService(updatedEstate).videoAnimation;
    const price = items != null
      ? items
          .map((item) => item.estate != null ? item : { ...item, estate: updatedEstate })
          .reduce((memo, i) => memo + priceItem(i, this.props.sessionUser.currency.code, true), 0)
      : 0;
    this.setState({ price });
    return { changes, updatedEstate, price };
  }

  @autobind
  _handleClickCustom() {
    const { custom } = this.state;
    if (custom === null) {
      openIntercom();
      this._resetSpecs(this._syncState);
    }
    else {
      const newValue = ! custom;
      this._resetSpecs(() => this.setState({ custom: newValue }, this._syncState));
    }
  }

  @autobind
  _resetSpecs(onReset) {
    this.setState({ specs: {
      standard: false,
      premium: false,
      droneShooting: false,
      duration: 45,
      } }, onReset);
  }

  @autobind
  _handleClickOption(_, key) {
    const { specs, custom } = this.state;
    const { droneShooting } = specs;
    const newSpecs = { ...specs, standard: false, premium: false, [key]: ! specs[key] };
    const removeOptions = ! newSpecs.premium && ! newSpecs.standard;
    this.setState({
      custom: custom ? ! custom : custom,
      specs: {
        ...newSpecs,
        duration: newSpecs.standard === true ? 45 : 60,
        droneShooting: removeOptions ? false : droneShooting,
      },
    }, this._syncState);
  }

  @autobind
  _handleClickConfirm() {
    const { onModifyService, estate } = this.props;
    const { custom } = this.state;
    const extraRemove = custom === false ? [getItemsByService(estate).videoAnimation.find((item) => item.product.custom)] : [];
    const { changes } = this._syncState();
    onModifyService({ ...changes, remove: [ ...changes.remove, ...extraRemove ] });
  }
}


export default VideoAnimation;
