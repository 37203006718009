import React from 'react';
import { css } from 'emotion';
import { Paragraph as IttsuParagraph } from 'react-ittsu/components';
import sv from '@drawbotics/style-vars';


const styles = {
  paragraph: css`
    color: ${sv.textPrimaryDark};
    margin-top: 5px;
    font-size: 0.85rem;
    text-align: center;
  `,
};


const Paragraph = ({ children }) => {
  return (
    <IttsuParagraph className={styles.paragraph}>
      {children}
    </IttsuParagraph>
  );
};


export default Paragraph;
