import React, { useEffect } from 'react';
import { css } from 'emotion';
import omit from 'lodash/omit';
import setWith from 'lodash/setWith';
import clone from 'lodash/clone';
import sv from '@drawbotics/style-vars';

import {
  filterDescription,
  getComponent,
  getRequiredKeys,
  canAdvance,
} from './utils';


function _set(obj, key, value) {
  return setWith(clone(obj), key, value, clone);
}


const styles = {
  section: css`
    margin-bottom: ${sv.baseMarginSmall};

    ${'' /* Style overrides */}
    .Checkbox__input:checked + .Checkbox__sprite,
    .Radio__input:checked+.Radio__sprite {
      background: ${sv.brandGreen} !important;
      border-color: ${sv.brandGreen};

      i {
        font-size: 0.7rem !important;
        top: -2px;
      }
    }
  `,
};


const SimplifiedGenerator = ({
  description: rawDescription,
  components,
  estate,
  onChange,
  values: answers,
  setCompleted,
  showRequired,
}) => {
  const handleModifyAnswer = (key, value, remove=false) => {
    if (remove) {
      const result = omit(answers, key instanceof Array ? key : [key]);
      onChange(result);
    }
    else {
      const result = _set(answers, key, value);
      onChange(result);
    }
  };

  const description = filterDescription(rawDescription, estate);

  useEffect(() => {
    const allEnabled = Object.values(description).reduce((memo, section) => {
      const requiredKeys = getRequiredKeys(section.components, answers, estate);
      const enabledNext = canAdvance(requiredKeys, answers);

      return memo && enabledNext;
    }, true);

    setCompleted(allEnabled);
  }, [answers]);

  const sections = Object.values(description).map((section) => {
    return section.components
      .map((componentDescription) => {
        return getComponent(components, componentDescription, {
          answers,
          modifyAnswer: handleModifyAnswer,
          estate,
        });
      })
      .filter((c) => !! c);
  });

  return (
    <div>
      {sections.map((generatedComponents) => {
        return generatedComponents.map((generatedComponent, i) => (
          <div key={i} className={styles.section}>
            <generatedComponent.type {...generatedComponent.props} withErrors={showRequired} />
          </div>
        ));
      })}
    </div>
  );
};


export default SimplifiedGenerator;