import {
  EmptyState,
  Padding,
  Panel,
  PanelBody,
  PanelHeader,
  Size,
  Title,
} from '@drawbotics/react-drylus';
import React from 'react';

import { createTranslate } from '~/utils/translation';

import { EstateHeader, EstateLayout } from '../../components';
import { ErrorType } from '../../utils';
import { useFetchEstatePayments } from '../../utils';
import { PaymentsTable } from './components';

const tt = createTranslate('pods.dashboard.routes.billing');

interface BillingProps {
  estateId: string;
  triggerNotFound: VoidFunction;
  triggerCritical: VoidFunction;
}

export const Billing = ({ estateId, triggerNotFound, triggerCritical }: BillingProps) => {
  const { isLoading, paymentProcess, error } = useFetchEstatePayments(estateId);

  if (error === ErrorType.NOT_FOUND || error === ErrorType.NOT_AUTHORIZED) {
    triggerNotFound();
    return null;
  }
  if (error === ErrorType.CRITICAL) {
    triggerCritical();
    return null;
  }

  return (
    <EstateLayout header={<EstateHeader estateId={estateId} />}>
      <Padding size={{ vertical: Size.EXTRA_LARGE, horizontal: Size.LARGE }}>
        <Panel
          header={
            <PanelHeader>
              <Title noMargin size={4}>
                {tt('billing')}
              </Title>
            </PanelHeader>
          }
          body={
            <PanelBody noPadding>
              {!isLoading && error == null && paymentProcess == null ? (
                <EmptyState description={tt('no_actions')} />
              ) : (
                <PaymentsTable isLoading={isLoading} paymentProcess={paymentProcess} />
              )}
            </PanelBody>
          }
        />
      </Padding>
    </EstateLayout>
  );
};
