import { combineReducers } from 'redux';
import { reducer as entities } from 'entman';

import { podsReducers } from 'pods';
import schemas from 'schemas';
import resetState from 'utils/reset-state';

import session from './session';
import ui from './ui';
import invitation from './invitation';
import testErrors from './test-errors';
import lastAction from './last-action';
import cacheInvalidation from './cache-invalidation';


export default resetState(combineReducers({
  ...podsReducers,
  session,
  ui,
  invitation,
  testErrors,
  lastAction,
  cacheInvalidation,
  entities: entities(schemas),
}));
