import { useContext } from 'react';

import { RouterContext as ReactRouterContext } from '../../components';
import { RouterContext } from '../../types';

export function useRouter(): RouterContext {
  const router = useContext(ReactRouterContext);

  if (router == null) {
    throw new Error('No router context found.');
  }

  return router;
}
