import React from 'react';
import { css, cx } from 'emotion';
import sv from '@drawbotics/style-vars';
import PropTypes from 'prop-types';
import { Icon } from 'react-ittsu/components';
import { FileIcon } from '@drawbotics/file-icons';
import '@drawbotics/file-icons/dist/style.css';


const styles = {
  attachmentBox: css`
    display: flex;
    align-items: center;
    padding: 10px ${sv.basePaddingSmall};
    background: ${sv.grey100};
    border-radius: ${sv.baseBorderRadius};
  `,
  disabled: css`
    pointer-events: none;

    & [data-element="icon"], [data-element="innerRow"] {
      opacity: 0.5;
    }
  `,
  inverse: css`
    background: ${sv.white};
  `,
  icon: css`
    margin-right: ${sv.baseMarginSmall};
  `,
  outerRow: css`
    flex: 1;
    min-width: 0;
  `,
  innerRow: css`
    width: 100%;
    display: flex;
    align-items: center;
  `,
  filename: css`
    flex: 1;
    width: 1px;
    margin-right: calc(${sv.baseMargin} * 2);
    white-space: nowrap;
    color: ${sv.textPrimaryDark};
    font-size: 0.9rem;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  download: css`
    font-size: 1.1rem;
    color: ${sv.textSecondaryDark};
    pointer-events: all !important;

    &:hover {
      cursor: pointer;
      color: ${sv.textPrimaryDark};
    }
  `,
  delete: css`
    margin-left: ${sv.baseMarginSmall};
    font-size: 0.9rem;
    color: ${sv.textSecondaryDark};

    &:hover {
      cursor: pointer;
      color: ${sv.textPrimaryDark};
    }
  `,
  progressBar: css`
    position: relative;
    height: 4px;
    background: ${sv.grey300};
    width: 100%;
    border-radius: 3px;
    overflow: hidden;
    margin-top: 5px;
  `,
  progress: css`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 4px;
    background: ${sv.brandGreen};
  `,
  check: css`
    height: 20px;
    width: 20px;
    border-radius: 1000px;
    background: ${sv.brandGreen};
    margin-right: ${sv.baseMarginSmall};
    color: ${sv.white};
    font-size: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
};


const AttachmentBox = ({ file, onClickDelete, progress, inverse }) => {
  return (
    <div className={cx(styles.attachmentBox, { [styles.disabled]: progress, [styles.inverse]: inverse } )}>
      <div className={styles.icon} data-element="icon">
        <FileIcon filename={file.filename} />
      </div>
      <div className={styles.outerRow}>
        <div className={styles.innerRow} data-element="innerRow">
          <div className={styles.filename}>
            {file.filename}
          </div>
          {do{
            if (! progress) {
              <div className={styles.check}>
                <Icon name="check-bold" vOffset={1} />
              </div>
            }
          }}
          <a href={file.url} target="_blank" className={styles.download} download rel="noopener noreferrer">
            <Icon name="download" bold />
          </a>
          {do{
            if (onClickDelete) {
              <div className={styles.delete} onClick={onClickDelete}>
                <Icon name="close" bold />
              </div>
            }
          }}
        </div>
        {do{
          if (progress) {
            <div className={styles.progressBar}>
              <div className={styles.progress} style={{ width: `${progress}%` }} />
            </div>
          }
        }}
      </div>
    </div>
  );
}


AttachmentBox.propTypes = {
  file: PropTypes.shape({
      filename: PropTypes.string.isRequired,
      url: PropTypes.string,
    }).isRequired,
  onClickDelete: PropTypes.func,
  progress: PropTypes.number,
  inverse: PropTypes.bool,
}


export default AttachmentBox;
