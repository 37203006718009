import morphey from 'morphey';


const translationsFromServer = {
  id: 'id',
  preview: 'preview',
  attached: 'attached',
  final: 'final',
  targetId: 'target_id',
  targetType: 'target_type',
  source: 'source',
  initial: 'initial',
  masterId: 'master_id',
  position: 'position',
  taskId: 'task_id',
  approbationId: 'approbation_id',
  fieldType: 'field_type',
  validatedAt: 'validated_at',
  validatedBy: 'validatedBy',
};


export function attachmentFromServer(attachment) {
  return morphey(attachment, translationsFromServer);
}


const translationsToServer = {
};


export function attachmentToServer(attachment) {
  return morphey(attachment, translationsToServer);
}


export default {
  fromServer: attachmentFromServer,
  toServer: attachmentToServer,
};
