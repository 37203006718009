import React from 'react';
import get from 'lodash/get';

import { fetchPosition } from 'pods/studio/utils';
import AnnotationDots from './AnnotationDots';


const FullScreenIcon = ({ isFullScreen }) => {
  if (isFullScreen) {
    return (
      <path
        style={{fill: '#3ebfc6'}}
        d="M18 3h2v4h4v2h-6v-6zm6 12v2h-4v4h-2v-6h6zm-18 6h-2v-4h-4v-2h6v6zm-6-12v-2h4v-4h2v6h-6z" />
    );
  }
  return (
    <path
      style={{fill: '#3ebfc6'}}
      d="M24 9h-2v-4h-4v-2h6v6zm-6 12v-2h4v-4h2v6h-6zm-18-6h2v4h4v2h-6v-6zm6-12v2h-4v4h-2v-6h6z" />
  );
};


const Preview = ({
  user,
  preview,
  activeAnnotation,
  width,
  height,
  imageUrl,
  notFound,
  containerWidth,
  containerHeight,
  isFullScreen,
  onClickPreview,
  onClickFullScreenToggler,
  onClickDot,
}) => {
  if ( ! preview.draft.isEditable()) {
    onClickPreview = () => {};
  }
  const userCanEdit = get(user, 'permissions.annotations.add', false);
  return (
    <svg width="100%" height="100%">
      <image
        x={(containerWidth - width) / 2}
        y={isFullScreen ? (containerHeight - height) / 2 : 0}
        width={width}
        height={height}
        onClick={(e) => userCanEdit && onClickPreview(preview, user, fetchPosition(e))}
        xlinkHref={imageUrl} />
      <AnnotationDots
        width={width}
        height={height}
        containerWidth={containerWidth}
        containerHeight={containerHeight}
        annotations={preview.annotations}
        editDraft={preview.draft.isEditable()}
        isFullScreen={isFullScreen}
        activeAnnotation={activeAnnotation}
        onClickDot={onClickDot} />
      <svg
        y={0}
        x={containerWidth - 35}
        width={35}
        height={30}
        viewBox="0 0 30 30"
        onClick={onClickFullScreenToggler}
        style={{ cursor: 'pointer' }}>
        <rect y={0} x={0} height={30} width={30} style={{ fillOpacity: 0 }} />
        <svg x={0} y={5} height={24} width={24}>
          <FullScreenIcon isFullScreen={isFullScreen} />
        </svg>
      </svg>
      {do {
        if (notFound) {
          <text x="50" y="50" fill="grey" style={{ fontWeight: 'bold' }}>
            Image Not found
          </text>
        }
      }}
    </svg>
  );
};

export default Preview;
