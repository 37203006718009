import { doConditional } from './conditional';
import { doForEach } from './for-each';
import { doSwitch } from './switch';
import { doInterval } from './interval';
import { doRelativeAdd} from './relative-add';
import { doAdd} from './add';
import { doLog } from './log';


export function doOperation(object, description, relativeDescriptions, variation) {
  if (Array.isArray(description)) {
    return description.reduce((memo, description) => {
      return memo + doOperation(object, description, relativeDescriptions, variation);
    }, 0);
  }

  const next = (obj, desc) => doOperation(obj, desc, relativeDescriptions, variation);
  const { operation: operationName } = description;
  const operationDescription = description[operationName];

  switch (operationName) {
    case 'conditional':
      return doConditional(object, operationDescription, next);
    case 'forEach':
      return doForEach(object, operationDescription, next);
    case 'switch':
      return doSwitch(object, operationDescription, next, variation);
    case 'interval':
      return doInterval(object, operationDescription, next);
    case 'add':
      return doAdd(object, operationDescription, next, variation);
    case 'log':
      return doLog(object, operationDescription, next);
    case 'relativeAdd':
      return doRelativeAdd(object, operationDescription, relativeDescriptions);
    default:
      throw new Error(`Unknown operation "${operationName}" found in description`);
  }
}
