import { Add, Switch, ForEach, RelativeAdd, Conditional } from 'drawbotics-pricer';

const USD_MULTIPLIER = 1.25;
const DISCOUNT_1 = 0.7;
const DISCOUNT_2 = 0.6;
const DISCOUNT_3 = 0.5;
const DISCOUNT_4 = 0.4;

const description = {
  UnitBase: [
    Conditional('details.project360Rotation').equals(true).then([
      Add({ EUR: 30, USD: 30 * USD_MULTIPLIER, GBP: 30 }),
    ]),
    Conditional('details.floorplansIn3d').equals(true).then([
      Add({ EUR: 30, USD: 30 * USD_MULTIPLIER, GBP: 30 }),
    ]),
    Conditional('details.furnishedFloors').equals(true).then([
      Add({ EUR: 20, USD: 20 * USD_MULTIPLIER, GBP: 20 }),
    ]),
    Conditional('details.shoebox360Rotation').equals(true).then([
      Add({ EUR: 30, USD: 30 * USD_MULTIPLIER, GBP: 30 }),
    ]),
    Conditional('details.googleMapsIntegration').equals(true).then([
      Add({ EUR: 20, USD: 20 * USD_MULTIPLIER, GBP: 20 }),
    ]),
    Conditional('details.environmentIn3d').equals(true).then([
      Add({ EUR: 30, USD: 30 * USD_MULTIPLIER, GBP: 30 }),
    ]),
    Switch('estate.projectType').is({
      residential: [
        Add({ EUR: 100, USD: 100 * USD_MULTIPLIER, GBP: 100 }),
      ],
      commercial: [
        Add({ EUR: 140, USD: 140 * USD_MULTIPLIER, GBP: 140 }),
      ],
    }),
  ],
  Base: [
    Switch('estate.projectType').is({
      'residential': [
        Add({ EUR: 2000, USD: 2000 * USD_MULTIPLIER, GBP: 2000 }),
        Switch('estate.projectSubtype').is({
          building: [
            ForEach('estate.unitCount').threshold(1).limit(25).do(RelativeAdd(1).from('UnitBase')),
            ForEach('estate.unitCount').threshold(25).limit(50).do(RelativeAdd(DISCOUNT_1).from('UnitBase')),
            ForEach('estate.unitCount').threshold(50).limit(75).do(RelativeAdd(DISCOUNT_2).from('UnitBase')),
            ForEach('estate.unitCount').threshold(75).limit(100).do(RelativeAdd(DISCOUNT_3).from('UnitBase')),
            ForEach('estate.unitCount').threshold(100).do(RelativeAdd(DISCOUNT_4).from('UnitBase')),
          ],
          complex: [
            ForEach('estate.apartmentCount').threshold(1).limit(25).do(RelativeAdd(1).from('UnitBase')),
            ForEach('estate.apartmentCount').threshold(25).limit(50).do(RelativeAdd(DISCOUNT_1).from('UnitBase')),
            ForEach('estate.apartmentCount').threshold(50).limit(75).do(RelativeAdd(DISCOUNT_2).from('UnitBase')),
            ForEach('estate.apartmentCount').threshold(75).limit(100).do(RelativeAdd(DISCOUNT_3).from('UnitBase')),
            ForEach('estate.apartmentCount').threshold(100).do(RelativeAdd(DISCOUNT_4).from('UnitBase')),
            ForEach('estate.houseCount').threshold(1).limit(25).do(RelativeAdd(1).from('UnitBase')),
            ForEach('estate.houseCount').threshold(25).limit(50).do(RelativeAdd(DISCOUNT_1).from('UnitBase')),
            ForEach('estate.houseCount').threshold(50).limit(75).do(RelativeAdd(DISCOUNT_2).from('UnitBase')),
            ForEach('estate.houseCount').threshold(75).limit(100).do(RelativeAdd(DISCOUNT_3).from('UnitBase')),
            ForEach('estate.houseCount').threshold(100).do(RelativeAdd(DISCOUNT_4).from('UnitBase')),
          ],
        }),
      ],
      'commercial': [
        Add({ EUR: 5000, USD: 5000 * USD_MULTIPLIER, GBP: 5000 }),
        ForEach('estate.surface.value').threshold(100).limit(2500).do(RelativeAdd(1 * 0.01).from('UnitBase')),
        ForEach('estate.surface.value').threshold(2500).limit(5000).do(RelativeAdd(DISCOUNT_1 * 0.01).from('UnitBase')),
        ForEach('estate.surface.value').threshold(5000).limit(7500).do(RelativeAdd(DISCOUNT_2 * 0.01).from('UnitBase')),
        ForEach('estate.surface.value').threshold(7500).limit(10000).do(RelativeAdd(DISCOUNT_3 * 0.01).from('UnitBase')),
        ForEach('estate.surface.value').threshold(10000).do(RelativeAdd(DISCOUNT_4 * 0.01).from('UnitBase')),
      ],
    }),
    
  ],
  Delivery: [
    Switch('estate.delivery').is({
      'relaxed': RelativeAdd(-0.1).from('Base'),
      'standard': RelativeAdd(0).from('Base'),
      'express': RelativeAdd(0.5).from('Base'),
    })
  ],
};


export default description;
