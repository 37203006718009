function getAddresses(organization, request, translators) {
  return request('GET', `organisations/${organization.id}/addresses`)
    .then((addresses) => addresses.addresses)
    .then((addresses) => addresses.map((a) => ({ ...a, organization: organization.id })))
    .then((addresses) => addresses.map(translators.Address.fromServer));
}


function getInvitations(organization, request, translators) {
  return request('GET', `organisations/${organization.id}/invitations`)
    .then((invitations) => invitations.invitations)
    .then((invitations) => invitations.map(translators.Invitation.fromServer));
}


function getUsers(organization, request, translators) {
  return request('GET', `organisations/${organization.id}/clients`)
    .then((clients) => clients.clients)
    .then((users) => users.map((u) => translators.Invitation.fromServer({ ...u.client, ...u })));
}


export default (request, entities, translators) => ({
  find(id) {
    return request('GET', `organisations/${id}`)
      .then(translators.Organization.fromServer)
      .then((organization) => Promise.all([
        organization,
        getAddresses(organization, request, translators),
        getInvitations(organization, request, translators),
        getUsers(organization, request, translators),
      ]))
      .then(([ organization, addresses, invitations, users ]) => ({ ...organization, addresses, invitations: [...invitations, ...users]}));
  },
  update(organization) {
    return request('PUT', `organisations/${organization.id}`, organization)
      .then(translators.Organization.fromServer);
  },
});
