import React from 'react';
import Rx from 'rxjs-legacy';
import filterBy from 'filter-by';

import { translate as t } from './translation';


export const NETWORK_ERROR = 'NETWORK_ERROR';

export function createNetworkError(status, body, request) {
  const error = new Error(JSON.stringify({ status, body }));
  error.type = NETWORK_ERROR;
  error.info = {
    status,
    body,
    request: request ? filterBy(request, (v, k) => k !== 'password') : null,
  };
  return error;
}


export const GRAPHQL_ERROR = 'GRAPHQL_ERROR';

export function createGraphQLError(graphQLError) {
  const { message, locations, path } = graphQLError;
  const error = new Error(message);
  error.type = GRAPHQL_ERROR;
  error.info = {
    message,
    locations,
    path,
  };
  return error;
}


export function wrapError(error) {
  const { type, info } = error;
  const newError = new Error(error);
  newError.type = type;
  newError.info = info;
  return newError;
}


export function handleEpicErrors(error, source) {
  error.isCritical = true;
  return Rx.Observable.of({
    type: 'UNCAUGHT_ERROR_IN_ROOT_EPIC',
    meta: { error },
  });
}


export function handleSagaErrors(store) {
  return (error) => {
    error.isCritical = true;
    store.dispatch({
      type: 'UNCAUGHT_ERROR_IN_ROOT_SAGA',
      meta: { error },
    });
  };
}


// TODO: Stop using this. It's passing non serializable data into the actions
export function formatErrorMessage(message, translationRoot) {
  if (Object.keys(message).length === 1) {
    return (
      <div>
        {Object.keys(message).map((key, i) => (
          <span key={i}>{t(`${translationRoot}.${key}.${message[key][0].error}`)}</span>
        ))}
      </div>
    );
  }
  const formatted = (
    <div>
      <span>{t('containers.alerts.errors.title')}</span>
      <ul>
        {Object.keys(message).map((key, i) => (
          <li key={i}>{t(`${translationRoot}.${key}.${message[key][0].error}`)}</li>
        ))}
      </ul>
    </div>
  );
  return formatted;
}
