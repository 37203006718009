import React from 'react';
import { Paragraph, Button, T3 } from 'react-ittsu/components';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';

import InfoModal from './InfoModal';
import { createTranslate } from '~/utils/translation';


const tt = createTranslate('pods.order.components.discount_modal');


const styles = {
  discountModal: css`
  `,
  title: css`
    color: ${sv.brandBlue};
  `,
  content: css`
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 400px;
    align-items: center;
    margin: auto;
  `,
  message: css`
    color: ${sv.textPrimaryDark};
    margin-bottom: ${sv.baseMargin};
  `,
};


const DiscountModal = ({
  visible,
  onClickClose,
  date,
  rate,
  minAmount,
  amount,
  discountType,
}) => {
  return (
    <div className={styles.discountModal} onClick={(e) => e.stopPropagation()}>
      <InfoModal visible={visible}>
        <div className={styles.content}>
          <T3 className={styles.title}>{tt('youre_eligible')}</T3>
          <Paragraph className={styles.message}>
            {do {
              if (discountType === 'percentage_discount') {
                tt('discount_description', { min_amount: minAmount, date, rate })
              }
              else if (discountType === 'amount_discount') {
                tt('discount_amount_description', { min_amount: minAmount, date, amount })
              }
            }}
          </Paragraph>
          <Button category="info" round onClick={onClickClose}>OK</Button>
        </div>
      </InfoModal>
    </div>
  );
}


export default DiscountModal;
