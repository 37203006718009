import {
  Badge,
  ButtonLink,
  Category,
  Color,
  Flex,
  FlexAlign,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Icon,
  ListTile,
  Padding,
  Pagination,
  Panel,
  PanelBody,
  PanelFooter,
  PanelHeader,
  SearchInput,
  SelectFilter,
  Size,
  Table,
  Tag,
  Text,
  Tier,
  Title,
} from '@drawbotics/react-drylus';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import { useController, useDebouncedState } from '~/utils/hooks';
import { createTranslate } from '~/utils/translation';

import { Container } from '../../components';
import { ProjectsController } from './projects.controller';

const t = createTranslate('pods.dashboard.routes.projects');

export const ProjectsView = withRouter(({ history }) => {
  const ctrl = useController(ProjectsController);
  const [search, setSearch] = useDebouncedState(ctrl.searchValue, (v) => ctrl.updateSearch(v), 200);

  const tableData = ctrl.projects.map((p) => ({
    id: p.id,
    name: (
      <Text>
        {p.name} {p.actionsCount > 0 ? <Badge value={p.actionsCount} color={Color.RED} /> : null}
      </Text>
    ),
    items: (
      <ListTile
        title={t('items_count', { count: p.items.length })}
        trailing={
          p.hasProblem ? (
            <ListTile
              title={<Tag color={Color.RED}>{t('problem')}</Tag>}
              trailing={<Icon name="alert-triangle" color={Color.RED} />}
            />
          ) : null
        }
      />
    ),
    link: (
      <Link to={`/projects/${p.id}`}>
        <ButtonLink size={Size.SMALL} tier={Tier.SECONDARY}>
          {t('go_to_project_button')}
        </ButtonLink>
      </Link>
    ),
  }));

  return (
    <Container>
      <Padding size={{ vertical: Size.DEFAULT, horizontal: Size.LARGE }}>
        <Flex
          justify={FlexJustify.SPACE_BETWEEN}
          align={FlexAlign.CENTER}
          style={{ width: '100%' }}>
          <FlexItem>
            <Title size={2}>{t('dashboard_title')}</Title>
          </FlexItem>
          <FlexItem>
            <Link to="/order">
              <ButtonLink leading={<Icon name="plus" />} category={Category.BRAND}>
                {t('new_project')}
              </ButtonLink>
            </Link>
          </FlexItem>
        </Flex>
        <Panel
          header={
            <PanelHeader>
              <Flex justify={FlexJustify.SPACE_BETWEEN} align={FlexAlign.CENTER}>
                <FlexItem flex>
                  <Title noMargin size={4}>
                    {t('projects_title')}
                  </Title>
                </FlexItem>
                <FlexItem>
                  <SearchInput
                    minimal
                    value={search ?? ''}
                    onChange={(v: string) => setSearch(v)}
                    placeholder={t('search_placeholder')}
                  />
                </FlexItem>
                <FlexSpacer size={Size.SMALL} />
                <FlexItem>
                  <SelectFilter
                    label={t('filter_label')}
                    options={[
                      { label: t('active_filter_label'), value: 'ACTIVE' as const },
                      { label: t('completed_filter_label'), value: 'COMPLETED' as const },
                    ]}
                    value={ctrl.filter}
                    onClear={() => ctrl.setFilter(undefined)}
                    onChange={(v) => ctrl.setFilter(v)}
                  />
                </FlexItem>
              </Flex>
            </PanelHeader>
          }
          body={
            <PanelBody noPadding>
              <Table
                clickable
                highlighted
                onClickRow={(row) => history.push(`/projects/${row.id}`)}
                isLoading={ctrl.isLoading}
                data={tableData}
                header={[
                  {
                    label: t('name_header_label'),
                    value: 'name',
                  },
                  {
                    label: t('items_header_label'),
                    value: 'items',
                  },
                  {
                    label: '',
                    value: 'link',
                  },
                ]}
              />
            </PanelBody>
          }
          footer={
            <PanelFooter>
              <Flex justify={FlexJustify.END}>
                <FlexItem>
                  <Pagination
                    pages={ctrl.pages}
                    value={ctrl.currentPage}
                    onChange={(v) => ctrl.goToPage(Math.max(1, v))}
                  />
                </FlexItem>
              </Flex>
            </PanelFooter>
          }
        />
      </Padding>
    </Container>
  );
});
