import {
  Button,
  Category,
  Content,
  Flex,
  FlexAlign,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  FormGroup,
  Icon,
  Input,
  Label,
  Margin,
  Padding,
  Panel,
  PanelBody,
  PanelHeader,
  SearchInput,
  Size,
  Spinner,
  Title,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { z } from 'zod';

import { useController } from '~/utils/hooks';
import { createTranslate } from '~/utils/translation';

import { InviteModal, TeammatesTable } from '../../components';
import { SettingsController } from './settings.controller';

const tt = createTranslate('pods.profile.routes.settings');

function _createUserSchema() {
  // @ts-ignore Type instantiation is excessively deep and possibly infinite.
  return z.object({
    firstName: z.string().min(1, tt('field_is_required')),
    lastName: z.string().min(1, tt('field_is_required')),
  });
}

interface ProfilePanelProps {
  ctrl: SettingsController;
}

const ProfilePanel = ({ ctrl }: ProfilePanelProps) => {
  const { user, isUserLoading } = ctrl;

  const form = useForm(_createUserSchema(), {
    defaultValues: {
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
    },
  });

  useEffect(() => {
    if (user != null) {
      form.set(user.firstName, 'firstName');
      form.set(user.lastName, 'lastName');
    }
  }, [user]);

  const handleClickUpdate = () => {
    const validationResult = form.validateAll();
    if (isEmpty(validationResult.errors)) {
      ctrl.updateUser(form.values);
    }
  };

  return (
    <Panel
      header={
        <PanelHeader>
          <Flex justify={FlexJustify.SPACE_BETWEEN}>
            <FlexItem>
              <Title noMargin size={4}>
                {tt('personal_settings')}
              </Title>
            </FlexItem>
            <FlexItem>
              <Button
                trailing={ctrl.isUserSaving ? <Spinner inversed size={Size.SMALL} /> : null}
                onClick={handleClickUpdate}
                category={Category.BRAND}>
                {tt('update_profile')}
              </Button>
            </FlexItem>
          </Flex>
        </PanelHeader>
      }
      body={
        <PanelBody>
          <Flex align={FlexAlign.START}>
            <FlexItem flex>
              <FormGroup
                label={<Label>{tt('first_name')}</Label>}
                input={
                  <Input
                    name="firstName"
                    isPlaceholder={isUserLoading}
                    error={form.getError}
                    value={form.get}
                    onChange={form.set}
                  />
                }
              />
            </FlexItem>
            <FlexSpacer />
            <FlexItem flex>
              <FormGroup
                label={<Label>{tt('last_name')}</Label>}
                input={
                  <Input
                    name="lastName"
                    isPlaceholder={isUserLoading}
                    value={form.get}
                    error={form.getError}
                    onChange={form.set}
                  />
                }
              />
            </FlexItem>
            <FlexSpacer />
            <FlexItem flex>
              <FormGroup
                label={<Label>{tt('email')}</Label>}
                input={<Input isPlaceholder={isUserLoading} value={user?.email ?? ''} />}
              />
            </FlexItem>
          </Flex>
        </PanelBody>
      }
    />
  );
};

export const SettingsView = () => {
  const ctrl = useController(SettingsController);
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
  const [search, setSearch] = useState('');

  const teammates = ctrl.teammates.filter(
    (t) => t.fullName.includes(search) || t.email.includes(search),
  );

  return (
    <Content fullHeight>
      <Padding size={Size.DEFAULT}>
        <ProfilePanel ctrl={ctrl} />
        <Margin size={{ vertical: Size.DEFAULT }} />
        <Panel
          header={
            <PanelHeader>
              <Flex>
                <FlexItem flex>
                  <Title size={4} noMargin>
                    {tt('company_team')}
                  </Title>
                </FlexItem>
                <FlexItem>
                  <SearchInput
                    minimal
                    value={search}
                    placeholder={tt('company_team')}
                    onChange={setSearch}
                  />
                </FlexItem>
                <FlexSpacer size={Size.SMALL} />
                <FlexItem>
                  <Button
                    onClick={() => setIsInviteModalVisible(true)}
                    category={Category.BRAND}
                    leading={<Icon name="mail" />}>
                    {tt('invite_email')}
                  </Button>
                </FlexItem>
              </Flex>
            </PanelHeader>
          }
          body={
            <PanelBody noPadding>
              <TeammatesTable
                onResendInvitation={(id) => ctrl.resendInvitation(id)}
                onChangeTeammate={(id, t) => ctrl.updateTeammate(id, t)}
                teammateSavingId={ctrl.teammateSavingId}
                teammateSendingInvitation={ctrl.teammateSendingInvitation}
                teammates={teammates}
                isLoading={ctrl.isTeammatesLoading}
                currentUser={ctrl.user}
              />
            </PanelBody>
          }
        />
        <InviteModal
          isSendingInvitation={ctrl.isCreatingInvitation}
          visible={isInviteModalVisible}
          onClickInvite={(i) => ctrl.createInvitation(i)}
          onClickClose={() => setIsInviteModalVisible(false)}
        />
      </Padding>
    </Content>
  );
};
