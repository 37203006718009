import React from 'react';
import ReactDOM from 'react-dom';


export default function scrollIntoView(Component) {
  return class ScrollIntoView extends React.Component {

    componentDidMount() {
      this._scrollIntoView();
    }
    componentDidUpdate() {
      this._scrollIntoView();
    }
    render() {
      return React.createElement(Component, { ...this.props });
    }
    _scrollIntoView() {
      // eslint-disable-next-line
      this.domNode = ReactDOM.findDOMNode(this);
      this.domNode.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }

  };
}
