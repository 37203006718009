import React from 'react';

import styles from 'pods/studio/styles/components/notification';


const Notification = ({ text }) => (
  <div className={styles.notification}>
    {text}
  </div>
);


export default Notification;
