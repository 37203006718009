const k = (key) => `video_animation.briefing.${key}`;

const residential = {
  1: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('video_description'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('video_description_note'),
        component: 'Note',
      },
      {
        type: 'question-component',
        label: k('intro_outro'),
        component: 'Input',
        required: 'all',
        key: 'description',
        keyLabel: k('keys.description'),
      },
    ],
  },
  2: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('map_style'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('map_style_note'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'SingleSelect',
        key: 'mapStyle',
        keyLabel: k('keys.map_style'),
        required: 'all',
        values: [
          {
            image: require('../images/video-animation/map-styles/map-style-1.jpg'),
            label: k('night'),
            value: 'night_style',
          },
          {
            image: require('../images/video-animation/map-styles/map-style-2.jpg'),
            label: k('day'),
            value: 'day_style',
          },
          {
            image: require('../images/video-animation/map-styles/map-style-3.jpg'),
            label: k('dark'),
            value: 'dark_style',
          },
          {
            image: require('../images/video-animation/map-styles/map-style-4.jpg'),
            label: k('highlights'),
            value: 'highlights_style',
          },
          {
            image: require('../images/video-animation/map-styles/map-style-5.jpg'),
            label: k('black_and_white'),
            value: 'black_and_white_style',
          },
          {
            image: require('../images/video-animation/map-styles/map-style-6.jpg'),
            label: k('satellite'),
            value: 'satellite_style',
          },
        ],
      },
      {
        type: 'meta',
        label: 'shared.show_my_own',
        components: [
          {
            type: 'question-component',
            label: 'shared.example_description',
            component: 'Input',
            key: 'otherMapStyle',
            keyLabel: k('keys.other_map_style'),
          }, {
            type: 'question-component',
            label: 'shared.upload_example',
            component: 'FileUploader',
            key: 'otherMapStyleAttachments',
            keyLabel: k('keys.other_map_style_attachments'),
          },
        ],
      },
    ],
  },
  3: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('poi'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('poi_note'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'MapSearch',
        required: 'all',
        key: 'pois',
        keryLabel: k('keys.pois'),
      },
    ],
  },
  4: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('stock_footage'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('stock_footage_note'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'SingleSelect',
        key: 'stockFootage',
        keyLabel: k('keys.stock_footage'),
        required: 'all',
        values: [
          {
            image: require('../images/video-animation/footage/stock.jpg'),
            value: true,
            label: k('use_stock'),
            warning: k('stock_warning'),
          },
          {
            image: require('../images/video-animation/footage/proprietary.jpg'),
            value: false,
            label: k('use_proprietary'),
          },
        ],
      },
      {
        type: 'component',
        label: k('integrate_people'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'SingleSelect',
        key: 'people',
        keyLabel: k('keys.people'),
        required: 'all',
        values: [
          {
            image: require('../images/shared/none.jpg'),
            value: 'none',
            label: k('no_people'),
          },
          {
            image: require('../images/shared/3d-people.jpg'),
            value: 'three_d',
            label: k('3d_people'),
          },
        ],
      },
    ],
  },
  5: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('music'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('music_note'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'SingleSelect',
        key: 'music',
        keyLabel: k('keys.music'),
        required: 'all',
        values: [
          {
            image: require('../images/shared/drawbotics.jpg'),
            value: 'drawbotics_choice',
            label: 'shared.drawbotics_choice',
          },
          {
            image: require('../images/shared/other.jpg'),
            value: 'other',
            label: k('upload_my_own'),
            meta: {
              type: 'meta',
              noToggle: true,
              components: [
                {
                  type: 'question-component',
                  label: k('music_comment'),
                  component: 'Input',
                  key: 'otherMusic',
                  keyLabel: k('keys.other_music'),
                },
                {
                  type: 'question-component',
                  label: k('upload_music'),
                  component: 'FileUploader',
                  required: 'all',
                  accept: 'audio/*',
                  key: 'otherMusicAttachments',
                  keyLabel: k('keys.other_music_attachments'),
                },
              ],
            },
          },
        ],
      },
    ],
  },
  6: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'shared.additional_info',
        component: 'Title',
      }, {
        type: 'component',
        label: 'shared.anything_to_add',
        component: 'Note',
      }, {
        type: 'question-component',
        label: 'shared.additional_comments',
        component: 'Input',
        key: 'otherInfo',
        keyLabel: 'shared.keys.other_info',
      }, {
        type: 'question-component',
        label: 'shared.upload_files',
        component: 'FileUploader',
        key: 'otherInfoAttachments',
        keyLabel: 'shared.keys.other_info_attachments',
      },
    ],
  },
};


export default { residential };
