import Rx from 'rxjs-legacy';
import { combineEpics } from 'redux-observable';

import * as ProjectItemActions from 'pods/dashboard/actions/project-item';
import * as AlertsActions from 'actions/alerts';
import * as SessionActions from 'actions/session';
import * as ApolloCacheActions from 'actions/cache-invalidation';
import { wrapError } from 'utils/errors';
import { createTranslate } from 'utils/translation';


const tt = createTranslate('containers.alerts.project_item');


function loadProjectItem(action$, store, extra) {
  const { api } = extra;
  return action$
    .ofType(ProjectItemActions.LOAD_PROJECT_ITEM)
    .flatMap((action) => {
      const { id } = action.payload;
      return Rx.Observable.fromPromise(api.queries.getProjectItem(id))
        .map((item) =>  ProjectItemActions.loadProjectItemSuccess(item))
        .catch((err) =>  {
          if (err.info.status === '500') {
            return Rx.Observable.concat(
              Rx.Observable.of(ProjectItemActions.loadProjectItemFail(err)),
            );
          }
          return Rx.Observable.concat(
            Rx.Observable.of(ProjectItemActions.loadProjectItemFail(err)),
          );
        });
    });
}


function archiveProjectItem(action$, store, extra) {
  return action$
    .filter((action) => action.type === '@@MUTATION/ARCHIVE_ITEM_SUCCESS')
    .map((action) => {
      return AlertsActions.showAlert('@@MUTATION/ARCHIVE_ITEM_SUCCESS', 'success', tt('item_archived'));
    })
    .catch((err) => {
      console.error(err);
      wrapError(err);
    });
}


function reopenProjectItem(action$, store, extra) {
  const { history } = extra;
  return action$
    .filter((action) => action.type === '@@MUTATION/REOPEN_ITEM_SUCCESS')
    .flatMap((action) => {
      const { args } = action.payload;
      const { variables, extra } = args;
      const newCredits = extra.user.credits - extra.reopeningPrice;
      if (newCredits < 0) {
        return Rx.Observable.of(AlertsActions.showAlert('@@MUTATION/REOPEN_ITEM_FAIL', 'error', tt('errors.item.not_enough_credits')));
      }
      return Rx.Observable.concat(
        Rx.Observable.of(SessionActions.updateUserCredits(extra.user.id, newCredits)),
        Rx.Observable.of(AlertsActions.showAlert('@@MUTATION/REOPEN_ITEM_SUCCESS', 'success', tt('item_reopened'))),
        Rx.Observable.of(history.push(`/studio/project-item/${variables.itemId}`)),
      );
    })
    .catch((err) => {
      console.error(err);
    })
}


function reopenProjectItemFail(action$) {
  return action$
    .filter((action) => action.type === '@@MUTATION/REOPEN_ITEM_FAIL')
    .map((action) => {
      return AlertsActions.showAlert('@@MUTATION/REOPEN_ITEM_FAIL', 'error', tt('errors.item.not_enough_credits'));
    });
}


function generateLink(action$, store, extra) {
  return action$
    .filter((action) => action.type === '@@MUTATION/GENERATE_LINK_SUCCESS')
    .map((action) => {
      return AlertsActions.showAlert('@@MUTATION/GENERATE_LINK_SUCCESS', 'success', tt('link_generated'))
    })
    .catch((err) => {
      console.error(err);
      return AlertsActions.showAlert('@@MUTATION/GENERATE_LINK_FAIL', 'error', tt('errors.link_not_generated'));
    });
}


function revokeLink(action$, store, extra) {
  return action$
    .filter((action) => action.type === '@@MUTATION/REVOKE_LINK_SUCCESS')
    .map((action) => {
      return AlertsActions.showAlert('@@MUTATION/REVOKE_LINK_SUCCESS', 'success', tt('link_revoked'))
    })
    .catch((err) => {
      console.error(err);
      return AlertsActions.showAlert('@@MUTATION/REVOKE_LINK_FAIL', 'error', tt('errors.link_not_revoked'));
    });
}


function resolveProblem(action$, store, extra) {
  return action$
    .filter((action) => action.type === '@@MUTATION/SOLVE_PROBLEM_SUCCESS')
    .flatMap((action) => {
      const { args } = action.payload;
      const { extra } = args;
      if (extra.price) {
        const newCredits = extra.user.credits - extra.price;
        return Rx.Observable.concat(
          Rx.Observable.of(SessionActions.updateUserCredits(extra.user.id, newCredits)),
          Rx.Observable.of(AlertsActions.showAlert('@@MUTATION/SOLVE_PROBLEM_SUCCESS', 'success', tt('problem_settled', { credits: extra.price }))),
        );
      }
      return Rx.Observable.of(AlertsActions.showAlert('@@MUTATION/SOLVE_PROBLEM_SUCCESS', 'success', tt('problem_solved')));
    })
    .catch((err) => {
      console.error(err);
      return AlertsActions.showAlert('@@MUTATION/SOLVE_PROBLEM_FAIL', 'error', tt('errors.problem_not_solved'));
    });
}


function cancelProjectItem(action$, store, extra) {
  const { history } = extra;
  return action$
    .filter((action) => action.type === '@@MUTATION/CANCEL_ITEM_SUCCESS')
    .flatMap((action) => {
      const { args } = action.payload;
      const { extra } = args;
      const newCredits = extra.user.credits + extra.price;
      return Rx.Observable.concat(
        Rx.Observable.of(SessionActions.updateUserCredits(extra.user.id, newCredits)),
        Rx.Observable.of(AlertsActions.showAlert('@@MUTATION/CANCEL_ITEM_SUCCESS', 'success', tt('item_destroyed', { price: extra.price }))),
        Rx.Observable.of(extra.toDashboard ? ApolloCacheActions.invalidateQuery('ProjectsQuery') : ApolloCacheActions.invalidateQuery('ProjectQuery')),
        Rx.Observable.of(extra.toDashboard ? history.push('/') : history.push(`/projects/${extra.estateId}`)),
      );
    })
    .catch((err) => {
      console.error(err);
      return AlertsActions.showAlert('@@MUTATION/CANCEL_ITEM_FAIL', 'error', tt('errors.item_not_destroyed'));
    })
}

export default combineEpics(
  loadProjectItem,
  archiveProjectItem,
  reopenProjectItem,
  generateLink,
  revokeLink,
  resolveProblem,
  cancelProjectItem,
  reopenProjectItemFail,
);
