import React from 'react';
import get from 'lodash/get';
import { css, cx } from 'emotion';
import sv from '@drawbotics/style-vars';
import autobind from 'autobind-decorator';
import { Icon, Button } from 'react-ittsu/components';

import { getRequiredKeys, canAdvance } from 'questions-generator/utils';
import Spinner from '~/components/Spinner';
import { createTranslate } from '~/utils/translation';


const t = createTranslate('pods.order.components.questions_generator');


const styles = {
  views: css`
    width: 1000px;
    background: ${sv.white};
    margin: 0 ${sv.baseMargin};
    overflow: hidden;

    @media ${sv.ipadLandscape} {
      width: calc(100vw - ${sv.baseMargin} * 4);
    }

    @media ${sv.phoneXl} {
      margin-top: ${sv.baseMargin};
      width: calc(100vw - ${sv.baseMargin});
    }
  `,
  viewContainer: css`
    width: 100%;
    padding: ${sv.basePaddingSmall};
    border-bottom: 1px solid ${sv.grey200};
    transition: box-shadow ${sv.baseTransitionTimeShort} ease-in-out;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.15);
    }
  `,
  title: css`
    display: flex;
    justify-content: space-between;
  `,
  name: css`
    color: ${sv.textPrimaryDark};
  `,
  info: css`
    color: ${sv.textTertiaryDark};

    &:hover {
      cursor: pointer;
      color: ${sv.brandBlue};
    }
  `,
  check: css`
    height: 20px;
    width: 20px;
    border-radius: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${sv.brandRed};
    color: ${sv.white};
    font-size: 0.65rem;
  `,
  completed: css`
    background: ${sv.brandGreen};
  `,
  section: css`
    margin-bottom: calc(${sv.baseMargin} * 1.5);
    display: flex;
    flex-direction: column;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }

    @media ${sv.phone} {
      margin-bottom: ${sv.baseMarginSmall};
    }
  `,
  content: css`
    display: none;
    background: ${sv.grey100};
    padding: ${sv.basePaddingSmall};
    margin-top: ${sv.baseMarginSmall};
  `,
  visible: css`
    display: block;
  `,
  save: css`
    display: block;
    margin: auto;
  `,
};


class ViewOptions extends React.Component {
  state = {
    openView: null,
    saving: null,
  }

  render() {
    const { openView, saving } = this.state;
    const { components, resultKey, answers, rawComponents, estate } = this.props;
    const views = get(answers, resultKey, []) || [];
    return (
      <div className={styles.views}>
        {views.map((view, i) => {
          const requiredKeys = getRequiredKeys(rawComponents, answers, estate, i);
          const completed = canAdvance(requiredKeys, answers);
          return (
            <div key={view.id} className={styles.viewContainer}>
              <div className={styles.title}>
                <div className={styles.name}>
                  {`${t('image')} ${i + 1}`}
                </div>
                <Button
                  onClick={() => this._handleClickGroup(view.id)}
                  size="small"
                  category="primary"
                  round >
                  {t('click_to', { action: openView === view.id ? t('close_save') : t('edit') })}
                </Button>
                <div className={cx(styles.check, { [styles.completed]: completed })}>
                  {do{
                    if (saving === view.id) {
                      <Spinner size={12} inversed />
                    }
                    else {
                      <Icon name={completed ? 'check-bold' : 'close-bold'} bold />
                    }
                  }}
                </div>
              </div>
              <div className={cx(styles.content, { [styles.visible]: openView === view.id })}>
                {components[i].map((component, j) => (
                  <div key={j} className={styles.section}>
                    <component.type
                      {...component.props}
                      resultKey={component.props.resultKey?.replace('%{n}', i)} />
                  </div>
                ))}
                <Button
                  onClick={() => this._handleClickGroup(view.id)}
                  size="small"
                  round
                  className={styles.save}
                  category="success">{t('close_save')}</Button>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  @autobind
  async _handleClickGroup(id) {
    const { save } = this.props;
    const { openView } = this.state;
    this.setState({
      openView: openView === id ? null : id,
      saving: openView,
    });
    await save();
    this.setState({ saving: null });
  }
}


export default ViewOptions;
