import {
  Align,
  Flex,
  FlexDirection,
  FlexItem,
  FlexSpacer,
  Padding,
  Paragraph,
  Size,
  Title,
} from '@drawbotics/react-drylus';
import React, { ReactNode } from 'react';

interface ProductionOverlayMessageProps {
  title: string;
  description: string;
  image: string;
  actions?: ReactNode;
}

export const ProductionOverlayMessage = ({
  title,
  description,
  image,
  actions,
}: ProductionOverlayMessageProps) => {
  return (
    <Padding size={Size.EXTRA_LARGE}>
      <Flex direction={FlexDirection.VERTICAL}>
        <FlexItem>
          <img src={image} width="230" />
        </FlexItem>
        <FlexItem>
          <Title size={3} align={Align.CENTER}>
            {title}
          </Title>
        </FlexItem>
        <FlexItem>
          <Paragraph align={Align.CENTER} style={{ maxWidth: 500 }}>
            {description}
          </Paragraph>
        </FlexItem>
        <FlexSpacer size={Size.DEFAULT} />
        <FlexItem>{actions}</FlexItem>
      </Flex>
    </Padding>
  );
};
