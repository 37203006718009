import * as zip from '@zip.js/zip.js';

import { Attachment } from '~/types';

export async function downloadAll(attachments: Array<Attachment>, name?: string): Promise<void> {
  const zipWriter = new zip.ZipWriter(new zip.BlobWriter('application/zip'), {
    useWebWorkers: false,
  });

  for (const attachment of attachments) {
    await zipWriter.add(
      attachment.filename,
      new zip.HttpReader(attachment.url, { preventHeadRequest: true }),
      {
        bufferedWrite: true,
      },
    );
  }
  const blobURL = URL.createObjectURL(await zipWriter.close());
  if (blobURL != null) {
    const anchor = document.createElement('a');
    const clickEvent = new MouseEvent('click');
    anchor.href = blobURL;
    anchor.download = `${name ?? 'visuals'}.zip`;
    anchor.dispatchEvent(clickEvent);
  }
}
