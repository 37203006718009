/**
 * Reducer that remembers the last dispatched action
 * for logging purposes.
 *
 * Use only in production, when immutable middleware is
 * not enabled. Otherwise, they would crash.
 */
export default function lastAction(state={}, action) {
  if (process.env.NODE_ENV === 'production') {
    return action;
  }
  else {
    return {};
  }
}
