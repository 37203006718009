import React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import styles from '../styles/components/new-modal';


const Overlay = ({
  children,
  onClick,
}) => {
  return (
    <div className={styles.overlay} onClick={onClick} data-element="overlay">
      {children}
    </div>
  );
};


const Modal = ({
  children,
}) => {
  return (
    <div className={styles.modal} data-element="modal">
      {children}
    </div>
  );
};


class NewModal extends React.Component {

  componentDidUpdate(prevProps) {
    const { isVisible: isPrevShown } = prevProps;
    const { isVisible } = this.props;
    if (isPrevShown !== isVisible && !!isVisible) {
      document.body.style.overflow = 'hidden';
    }
    else if (isPrevShown !== isVisible && !isVisible) {
      document.body.style.overflow = 'initial';
    }
  }

  render() {
    const { isVisible, children } = this.props;
    return ReactDOM.createPortal(
      <CSSTransition
        in={isVisible}
        timeout={500}
        mountOnEnter
        unmountOnExit
        classNames="modalTransition">
        <Overlay>
          <Modal>
            {children}
          </Modal>
        </Overlay>
      </CSSTransition>,
      document.getElementById('modals-outlet'),
    );
  }

}


export default NewModal;
