import morphey, { fromValue } from 'morphey';

import { userFromServer } from './user';


const translationsFromServer = (follower) => ({
  id: 'id',
  user: fromValue(userFromServer(follower)),
});


export function followerFromServer(follower) {
  return morphey(follower, translationsFromServer);
}


const translationsToServer = (follower) => ({
  id: 'id',
  email: follower.user ? 'user.email' : 'email',
});


export function followerToServer(follower) {
  return morphey(follower, translationsToServer);
}


export default {
  fromServer: followerFromServer,
  toServer: followerToServer,
};
