import { fork, put, call, takeEvery, takeLatest } from 'redux-saga/effects';

import { Attachment } from 'pods/studio/utils/api';
import * as AnnotationsActions from 'pods/studio/actions/annotations';
import * as AttachmentsActions from 'pods/studio/actions/attachments';
import * as UIActions from 'pods/studio/actions/ui';


/**
 * REGULAR SAGAS
 */
function* handleCreateAttachment(action) {
  const { data } = action.payload;
  yield put(AnnotationsActions.updateAnnotation(data.annotation, { isDirty: true }));
}

function* handleDeleteAttachment(action) {
  const { id } = action.payload;
  yield call(Attachment.delete, id);
}

function* handleSaveAttachments(action) {
  // const { attachments, annotationId } = action.payload;
  // yield put(UIActions.disableValidatePreviewButton());
  // for (let attachment of attachments) {
  //   if ( ! attachment.isDangling) {
  //     continue;
  //   }
  //   yield call(Attachment.update, attachment.id, { ...attachment, annotation: annotationId });
  //   yield put(AttachmentsActions.updateAttachment(attachment.id, {
  //     annotation: annotationId,
  //     isDangling: false,
  //   }));
  // }
  yield put(AttachmentsActions.saveAttachmentsDone());
  yield put(UIActions.enableValidatePreviewButton());
}


/**
 * WATCHERS
 */
function* watchCreateAttachment() {
  yield takeLatest(AttachmentsActions.CREATE_ATTACHMENT, handleCreateAttachment);
}

function* watchDeleteAttachment() {
  yield takeLatest(AttachmentsActions.DELETE_ATTACHMENT, handleDeleteAttachment);
}

function* watchSaveAttachments() {
  yield takeEvery(AttachmentsActions.SAVE_ATTACHMENTS, handleSaveAttachments);
}


/**
 * ROOT
 */
export default function* root() {
  yield fork(watchCreateAttachment);
  yield fork(watchDeleteAttachment);
  yield fork(watchSaveAttachments);
}
