import React from 'react';
import {
  Navbar,
  NavbarIcon,
  NavbarControls,
  NavbarLink,
  Button,
  Link,
} from 'react-ittsu/components';
import { Main, Header, Container, Footer } from 'react-ittsu/layout';

import { createTranslate } from 'utils/translation';
import RouteContainer from 'components/RouteContainer';
import FooterContent from 'containers/FooterContent';

import styles from 'styles/routes/critical-error';
import logo from 'images/logo.svg';
import notFound from 'images/500.svg';


const tt = createTranslate('routes.critical_error');


class CriticalError extends React.Component {
  constructor(props) {
    super(props);
    this._openIntercom = this._openIntercom.bind(this);
  }

  render() {
    return (
      <div className={styles.criticalError}>
        <Header>
          <Navbar withContainer>
            <Link href="/">
              <NavbarIcon image={logo} />
            </Link>
            <NavbarControls>
              <NavbarLink><Link underlined="none" href="/">{tt('back_home')}</Link></NavbarLink>
            </NavbarControls>
          </Navbar>
        </Header>
        <Main>
          <RouteContainer>
            <Container style={{ height: '100%' }}>
              <div className={styles.criticalContainer}>
                <img src={notFound} className={styles.backgroundImage} />
                <div className={styles.content}>
                  <div className={styles.title}>{tt('title')}</div>
                  <div className={styles.subtitle}>{tt('subtitle')}</div>
                </div>
                <div className={styles.action}>
                  <Button category="primary" onClick={this._openIntercom}>{tt('contact_us')}</Button>
                </div>
              </div>
            </Container>
          </RouteContainer>
        </Main>
        <Footer>
          <FooterContent />
        </Footer>
      </div>
    );
  }

  _openIntercom() {
    window.Intercom ? window.Intercom('show') : null;
  }
}


export default CriticalError;
