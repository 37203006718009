import gql from 'fraql';
import { useQuery } from 'urql';

import { Estate } from '../../types';
import { ErrorType, buildError } from '../errors';

interface EstateQueryData {
  estate: Estate;
}

interface EstateQueryVariables {
  estateId: string;
}

const EstateQuery = gql`
  query EstateQuery($estateId: ID!) {
    estate(estateId: $estateId) {
      id
      name
      briefingConfirmedAt
      problems {
        id
      }
      items {
        id
        status
        briefingConfirmedAt
      }
      paymentProcess {
        invoices {
          id
          status
        }
      }
    }
  }
`;

export function useFetchBasicEstate(
  estateId: string,
): { isLoading: boolean; estate?: Estate; error?: ErrorType } {
  const [res] = useQuery<EstateQueryData, EstateQueryVariables>({
    query: EstateQuery,
    variables: { estateId },
  });

  return { isLoading: res.fetching, estate: res.data?.estate, error: buildError(res.error) };
}
