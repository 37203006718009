import React from 'react';
import get from 'lodash/get';
import { Button } from 'react-ittsu/components';

import { createTranslate } from 'utils/translation';
import Link from 'components/Link';

import styles from 'pods/studio/styles/components/sidebar';


const tt = createTranslate('pods.studio.components.validate_preview');

const nonValidatedText = (userCanAdd, preview) => {
  if ( ! userCanAdd) {return null}

  return preview.hasAnnotations() ?
    tt('approve_annotations') : tt('validate_visual');
};


const validatedText = (preview) => {
  const someoneValidated = !!preview.validatedBy;
  const validatorName = someoneValidated ? preview.validatedBy.firstName : '';
  if (preview.hasAnnotations()) {
    return someoneValidated ?
      `${tt('correction_approved_by')} ${validatorName}` : tt('correction_approved');
  }
  else {
    return someoneValidated ?
      `${tt('design_approved_by')} ${validatorName}` : tt('design_approved');
  }
};


const isEditableText = (userCanAdd, preview) => {
  return preview.isValidated() ? validatedText(preview) : nonValidatedText(userCanAdd, preview);
};


const isNonEditableText = (preview) => {
  const hasAnnotations = preview.hasAnnotations();
  const someoneValidated = !!preview.validatedBy;
  const validatorName = someoneValidated ? preview.validatedBy.firstName : '';
  if (hasAnnotations) {
    return tt('corrections_sent_to_production');
  }
  return someoneValidated ?
    `${tt('design_approved_by')} ${validatorName}` : tt('design_approved');
};


const getButtonText = (userCanAdd, editable, preview, savingAnnotation) => {
  if (savingAnnotation) {return tt('saving')}
  return editable ? isEditableText(userCanAdd, preview) : isNonEditableText(preview);
};


const ValidateButton = ({
  user,
  preview,
  disabled,
  savingAnnotation,
  onClickValidate,
}) => {
  const userCanAdd = get(user, 'permissions.annotations.add', false);
  const draftIsEditable = preview.draft.isEditable();
  const canBeValidated = draftIsEditable && ! preview.isValidated() && ! disabled;
  const text = getButtonText(userCanAdd, draftIsEditable, preview, savingAnnotation);
  if (! text) return null;
  return (
    <Button
      onClick={() => onClickValidate(preview, user.id)}
      disabled={ ! canBeValidated}
      className={canBeValidated ? styles.validateButton : styles.approvedText}>
      {text}
    </Button>
  );
};


const ValidatePreview = ({
  user,
  preview,
  disabled,
  savingAnnotation,
  onClickValidate,
}) => (
  <div className={styles.validatePreview}>
    <Link href={`/studio/project-item/${preview.draft.projectItem.id}`} className={styles.goBack}>
      <span><i className="fa fa-fw fa-caret-left" />{tt('go_back')}</span>
    </Link>
    <ValidateButton
      user={user}
      preview={preview}
      disabled={disabled}
      savingAnnotation={savingAnnotation}
      onClickValidate={onClickValidate} />
  </div>
);


export default ValidatePreview;
