import { getEntities } from 'entman';
import find from 'lodash/find';

import schemas from 'schemas';


export function getPublicProject(state, token) {
  const entities = getEntities(state, schemas.ProjectItem);
  const project = find(entities, ['publicToken', token]);
  if (! project) {
    return {};
  }
  return project;
}
