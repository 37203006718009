import React from 'react';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';
import gql from 'fraql';
import { Tooltip, showTooltip, hideTooltip } from 'react-ittsu/components';

import { getRequiredAttachmentsForEstate, categories } from '../../utils/briefing/filter-estate-attachments';
import { createTranslate } from '~/utils/translation';
import fileExamples from './file-examples';


const tt = createTranslate('pods.order.routes.briefing_estate');


const styles = {
  checklist: css`
  `,
  description: css`
    color: ${sv.textPrimaryDark};
    margin-bottom: ${sv.baseMargin};
  `,
  section: css`
    margin-bottom: ${sv.baseMargin};
    border-bottom: 1px solid ${sv.grey200};
    padding-bottom: ${sv.basePaddingSmall};

    &:last-child {
      border-bottom: none;
    }

    @media ${sv.phone} {
      margin-bottom: ${sv.baseMarginSmall};
    }
  `,
  title: css`
    color: ${sv.textTertiaryDark};
    font-family: ${sv.baseFontFamilyAlt};
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8rem;
    letter-spacing: 0.02rem;
    margin-bottom: 10px;
  `,
  selection: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 0;

    &:last-child {
      border-bottom: 0;
    }
  `,
  bullet: css`
    height: 4px;
    width: 4px;
    border-radius: 1000px;
    background: ${sv.grey600};
  `,
  label: css`
    margin-left: ${sv.baseMarginSmall};
    color: ${sv.textPrimaryDark};
  `,
  tooltipIcon: css`
    height: 15px;
    width: 15px;
    border-radius: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${sv.grey200};
    color: ${sv.textPrimaryDark};
    font-size: 0.75rem;
    font-weight: 600;
    margin-left: 5px;
    pointer-events: all !important;

    &:hover {
      cursor: pointer;
      background: ${sv.grey300};
    }

    @media ${sv.phone} {
      height: 30px;
      width: 30px;
      font-size: 0.8rem;
      margin-left: 10px;
    }
  `,
  tooltip: css`
    padding: ${sv.basePaddingSmall};
    color: ${sv.textPrimaryDark};

    & > img {
      margin-top: 5px;
    }
  `,
};


class Checklist extends React.Component {
  static fragments = {
    Estate: gql`
      fragment _ on Estate {
        id
        jsonMeta @client(type: Estate)
        items {
          id
        }
      }
    `,
  }

  state = {
    tooltipState: {},
    activeTip: null,
  }

  render() {
    const { estate } = this.props;
    const requiredAttachments = getRequiredAttachmentsForEstate(estate);
    const { tooltipState, activeTip } = this.state;
    const activeCategories = Object.keys(categories).reduce((active, categoryName) =>
      categories[categoryName].some((key) => requiredAttachments.includes(key)) ?
        ({ ...active, [categoryName]: categories[categoryName].filter((key) => requiredAttachments.includes(key)) }) : active, {});
    return (
      <div className={styles.checklist}>
        <div className={styles.description}>{tt('checklist.title')}</div>
        {Object.keys(activeCategories).map((categoryName, i) => (
          <div className={styles.section} key={i}>
            <div className={styles.title}>
              {tt(`checklist.${categoryName}`)}
            </div>
            {activeCategories[categoryName].map((attachmentName, i) => (
              <div key={i} className={styles.selection}>
                <div className={styles.bullet} data-element="check" />
                <div className={styles.label}>
                  {tt(`checklist.${attachmentName}.title`)}
                </div>
                <div
                  className={styles.tooltipIcon}
                  onMouseEnter={(e) => this.setState({ tooltipState: showTooltip(tooltipState, e), activeTip: attachmentName })}
                  onMouseLeave={() => this.setState({ tooltipState: hideTooltip(tooltipState) })}>?</div>
              </div>
            ))}
          </div>
        ))}
        <Tooltip
          label="tooltip"
          position="right"
          visible={tooltipState?.visible && activeTip}
          parentRect={tooltipState?.parentRect}>
          <div className={styles.tooltip}>
            {activeTip && tt(`checklist.${activeTip}.description`)}
            {do{
              if (fileExamples[activeTip]) {
                <img src={fileExamples[activeTip]} />
              }
            }}
          </div>
        </Tooltip>
      </div>
    );
  }
}


export default Checklist;
