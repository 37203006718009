import { Route, Switch } from '@drawbotics/route';
import compose from 'lodash/flowRight';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { triggerCritical, triggerNotFound } from '~/actions';

import { Billing } from '../Billing';
import { Item } from '../Item';
import { Notifications } from '../Notifications';
import { Production } from '../Production';

interface EstateProps extends RouteComponentProps<{ estateId: string }> {
  triggerNotFound: VoidFunction;
}

const _Project = ({ match, triggerNotFound }: EstateProps) => {
  const { estateId } = match.params;
  return (
    <Switch onNoMatch={() => triggerNotFound()}>
      {/* Redirect from old path */}
      <Route
        basePath={match.url}
        path="/production/items/:itemId"
        render={(props) => <Redirect to={`${match.url}/production/${props.match.params.itemId}`} />}
      />
      <Route
        withProps={{ estateId, triggerNotFound, triggerCritical }}
        basePath={match.url}
        path="/production/:itemId"
        component={Item}
      />
      <Route
        basePath={match.url}
        path="/notifications"
        component={Notifications}
        withProps={{ estateId, triggerNotFound, triggerCritical }}
      />
      <Route basePath={match.url} path="/billing" component={Billing} withProps={{ estateId }} />
      <Route
        basePath={match.url}
        path="/production"
        component={Production}
        withProps={{ estateId, triggerNotFound, triggerCritical }}
      />
      <Redirect path={match.url} from="/" to={`${match.url}/production`} />
    </Switch>
  );
};

const mapDispatchToProps = {
  triggerNotFound,
  triggerCritical,
};

export const Project = compose(connect(undefined, mapDispatchToProps))(_Project);
