import { Add, Switch, RelativeAdd } from 'drawbotics-pricer';


const description = {
  Base: [
    Switch('estate.projectType').is({
      'residential': [
        Add({ EUR: 1550, USD: 1950, GBP: 1375 }),
      ],
      'commercial': [
        Add({ EUR: 1950, USD: 2550, GBP: 1750, }),
      ],
    }),
  ],
  Delivery: [
    Switch('estate.delivery').is({
      'relaxed': RelativeAdd(-0.1).from('Base'),
      'standard': RelativeAdd(0).from('Base'),
      'express': RelativeAdd(0.5).from('Base'),
    })
  ],
};


export default description;
