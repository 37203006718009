import gql from 'fraql';

import CartItem from '../../components/CartItem';


export const CartQuery = gql`
  query CartQuery {
    estates: cart {
      id
      orderCompleted @client(type: Estate)
      paymentCompleted @client(type: Estate)
      ${CartItem.fragments.CartItem}
    }
  }
`;


export const DeleteEstateMutation = gql`
  mutation DeleteEstateMutation($estateId: ID!) {
    payload: destroyOrderEstate(
      input: {
        orderEstateId: $estateId
      }
    ) {
      estate: orderEstate {
        id
      }
    }
  }
`;
