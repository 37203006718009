const specs = {
  standard: {
    operation: 'create',
    create: {
      type: 'bool',
      product: 'exteriorTour3d_standard',
    },
  },
  hotspots: {
    operation: 'update',
    update: {
      type: 'mapTo',
      target: '@{product.id} == "exteriorTour3d_standard"',
      mapTo: ['hotspots'],
    },
  },
};


const dependencies = {
  hard: [],
  soft: [],
};


export default { specs, dependencies };
