const specs = {
  standard: {
    operation: 'create',
    create: {
      type: 'bool',
      product: 'photoEditing_standard',
    },
  },
  numberOfImages: {
    operation: 'update',
    update: {
      type: 'mapTo',
      target: 'all',
      mapTo: [ 'numberOfImages' ],
    },
  },
  logoAndWatermark: {
    operation: 'update',
    update: {
      type: 'mapTo',
      target: 'all',
      mapTo: [ 'logoAndWatermark' ],
    },
  },
};


const dependencies = {
  hard: [],
  soft: [],
};


export default { specs, dependencies };
