import React from 'react';
import { compose } from 'redux';
import queryString from 'query-string';

import { createTranslate } from 'utils/translation';
import { showAlert } from 'actions';
import withAPI from 'utils/api/with-api';


const tt = createTranslate('routes.confirm_email');


class ConfirmEmail extends React.Component {

  componentDidMount() {
    const { location, confirmEmail } = this.props;
    const query = queryString.parse(location.search);
    const { confirmation_token: confirmationToken } = query;
    confirmEmail(confirmationToken);
  }

  render() {
    return (
      <div />
    );
  }

}


const apiConfig = (api, setState, { dispatch }, ownProps) => ({
  confirmEmail(token) {
    api.mutations.confirmEmail(token)
      .then((response) => {
        dispatch(showAlert('CONFIRM_EMAIL', 'success', tt('email_address_confirm_success')));
      })
      .catch((error) => {
        dispatch(showAlert('CONFIRM_EMAIL', 'error', tt('email_address_confirm_fail')));
        console.reportException(error);
      })
      .then(() => dispatch(ownProps.history.push('/')));
  },
});


export default compose(
  withAPI(apiConfig, { legacy: true }),
)(ConfirmEmail);
