import React from 'react';
import autobind from 'autobind-decorator';
import { css } from 'emotion';
import omit from 'lodash/omit';
import v4 from 'uuid/v4';

import FFileUploader from '~/components/FileUploader';
import { request } from '~/utils/api/utils';


const styles = {
  uploadOverlay: css`
    position: relative;

    & input {
      display: none;
    }
  `,
}


class FileUploadOverlay extends React.Component {
  state = {
    progresses: {},
  }

  render() {
    const { onComplete, onInit, onError, component, setDropZoneActive, setDropZoneInactive, ...rest } = this.props;
    const uniqueId = v4();
    return (
      <div className={styles.uploadOverlay}>
        <div className={styles.component} onClick={() => document.getElementById(uniqueId).click()}>
        <FFileUploader
          {...rest}
          id={`${uniqueId}`}
          onInit={onInit}
          onComplete={onComplete}
          onError={onError}
          multiple={true}
          onProgress={this._handleOnProgress}
          onFinish={this._handleOnFinish}
          enableDragDrop={true}
          setDropZoneActive={setDropZoneActive}
          setDropZoneInactive={setDropZoneInactive}>
          {component}
        </FFileUploader>
        </div>
      </div>
    );
  }

  @autobind
  _handleOnProgress(file, event) {
    const { progresses } = this.state;
    const { onProgress } = this.props;
    const progress = Math.round((event.loaded / event.total) * 100);
    const newProgresses = { ...progresses, [file.name]: progress };
    this.setState({ progresses: newProgresses });
    onProgress(file.name, progress);
  }

  @autobind
  async _handleOnFinish(file, signingResult) {
    const { onFinish } = this.props;
    const { progresses } = this.state;
    const newProgresses = omit(progresses, [file.name]);
    this.setState({ progresses: newProgresses });

    const attachment = {
      ...signingResult,
      name: file.name,
      signedId: signingResult.signed_id,
      publicUrl: signingResult.direct_upload.url,
      infos: {
        fieldType: null,
        targetType: null,
        targetId: null,
      },
    };
    const response = await request('POST', 'attachments', attachment);
    await onFinish({
      filename: signingResult.filename,
      id: response.message.attachment[0].attachment_id,
    });
  }
}


export default FileUploadOverlay;
