import React from 'react';
import {
  Panel,
  PanelHeader,
  PanelTitle,
  PanelBody,
  Table,
  THead,
  TCell,
  TBody,
  TRow,
} from 'react-ittsu/components';
import {
  Container,
} from 'react-ittsu/layout';
import { compose } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';

import isAuthenticated from 'utils/is-authenticated';
import { getSessionUser } from 'selectors';
import { getSalesOrders } from 'pods/transactions/selectors';
import { loadSalesOrders } from 'pods/transactions/actions';
import Link from 'components/Link';
import RouteContainer from 'components/RouteContainer';
import LoadingContainer from 'containers/LoadingContainer';
import { convertDate } from 'pods/transactions/utils';
import { translate as t, createTranslate } from 'utils/translation';


const tt = createTranslate('pods.transactions.routes.sales_orders');


class SalesOrders extends React.Component {
  state = {
    loaded: false,
  }

  constructor(props) {
    super(props);
    this._loadAllSalesOrders = this._loadAllSalesOrders.bind(this);
  }

  componentDidMount() {
    this._loadAllSalesOrders();
  }

  componentDidUpdate() {
    this._loadAllSalesOrders();
  }
  render() {
    const { salesOrders } = this.props;
    return (
      <LoadingContainer
        loadingKeys="salesOrders"
        whenLoaded={() => (
          <RouteContainer>
            <Container>
              <Panel>
                <PanelHeader>
                  <PanelTitle>
                    {tt('sales_orders')}
                  </PanelTitle>
                </PanelHeader>
                <PanelBody>
                  <Table fullWidth variation="striped">
                    <THead>
                      <TRow>
                        <TCell>
                          {tt('transaction_reference')}
                        </TCell>
                        <TCell>
                          {tt('purchase_date')}
                        </TCell>
                        <TCell>
                          {tt('buyer')}
                        </TCell>
                        <TCell>
                          {tt('internal_reference')}
                        </TCell>
                        <TCell>
                          {tt('total')}
                        </TCell>
                        <TCell>
                          {tt('transaction_status')}
                        </TCell>
                        <TCell></TCell>
                      </TRow>
                    </THead>
                    <TBody>
                      {salesOrders.reverse().filter((i) => i.status !== 'obsolete').map((transaction, i) => (
                        <TRow key={i}>
                          <TCell>{transaction.invoice.salesOrderReference}</TCell>
                          <TCell>{convertDate(transaction.orderedAt)}</TCell>
                          <TCell>{transaction.buyer.firstName ? `${transaction.buyer.firstName} ${transaction.buyer.lastName}` : null}</TCell>
                          <TCell>{transaction.internalReference}</TCell>
                          <TCell>{transaction.amount}{transaction.currency}</TCell>
                          <TCell>{t(`pods.transactions.routes.invoices.status.${transaction.status}`)}</TCell>
                          <TCell style={{ textAlign: 'right' }}>
                            {do {
                              if (transaction.invoice.salesOrderPublicUrl) {
                                <Link underlined="always" href={transaction.invoice.salesOrderPublicUrl} target="_blank" download>{tt('download')}</Link>
                              }
                            }}
                            {do {
                              if (transaction.status === 'incomplete' || transaction.status === 'initiated') {
                                <Link href={`/transactions/sales-orders/${transaction.id}/edit`} underlined="always">{tt('finish_order')}</Link>
                              }
                            }}
                          </TCell>
                        </TRow>
                      ))}
                    </TBody>
                  </Table>
                </PanelBody>
              </Panel>
            </Container>
          </RouteContainer>
        )} />
    );
  }

  _loadAllSalesOrders() {
    const { loadSalesOrders, sessionUser } = this.props;
    const { loaded } = this.state;
    if (sessionUser && ! loaded) {
      loadSalesOrders(get(sessionUser, 'organization.id', null));
      this.setState({
        loaded: true,
      });
    }
  }
}


const mapStateToProps = (state) => ({
  salesOrders: getSalesOrders(state),
  sessionUser: getSessionUser(state),
});


const mapDispatchToProps = {
  loadSalesOrders,
};


export default compose(
  isAuthenticated(),
  connect(mapStateToProps, mapDispatchToProps),
)(SalesOrders);
