import Rx from 'rxjs-legacy';
import { combineEpics } from 'redux-observable';

import * as SalesOrdersActions from 'pods/transactions/actions/sales-orders';
import * as AlertsActions from 'actions/alerts';
import { translate as t } from 'utils/translation';
import { wrapError } from 'utils/errors';


function loadSalesOrders(action$, store, extra) {
  const { api } = extra;
  return action$
    .filter((action) => action.type === SalesOrdersActions.LOAD_SALES_ORDERS)
    .mergeMap((action) => {
      const { organizationId } = action.payload;
      return Rx.Observable.fromPromise(api.queries.getSalesOrders(organizationId))
        .map((salesOrders) => SalesOrdersActions.loadSalesOrdersSuccess(salesOrders))
        .catch((err) => Rx.Observable.concat(
          Rx.Observable.of(AlertsActions.showAlert(SalesOrdersActions.LOAD_SALES_ORDERS_FAIL, 'error', t('containers.alerts.transactions.sales_order.errors.load_sales_orders'))),
          Rx.Observable.of(SalesOrdersActions.loadSalesOrdersFail(wrapError(err)))
        ));
    });
}


function confirmSalesOrder(action$, store, extra) {
  const { api, history } = extra;
  return action$
    .filter((action) => action.type === SalesOrdersActions.CONFIRM_SALES_ORDER)
    .mergeMap((action) => {
      const { address, cart, transaction } = action.payload;
      return Rx.Observable.fromPromise(api.mutations.confirmSalesOrder(address, cart, transaction))
        .flatMap((newTransaction) => Rx.Observable.concat(
          Rx.Observable.of(SalesOrdersActions.confirmSalesOrderSuccess(transaction)),
          Rx.Observable.of(newTransaction.relatedObjectType === 'Order' ? history.push(`/transactions/after-orders/${newTransaction.relatedObjectId}`) : history.push('/')),
          Rx.Observable.of(AlertsActions.showAlert(SalesOrdersActions.CONFIRM_SALES_ORDER_SUCCESS, 'success', t('containers.alerts.transactions.sales_order.created')))
        ))
        .catch((err) => Rx.Observable.concat(
          Rx.Observable.of(AlertsActions.showAlert(SalesOrdersActions.CONFIRM_SALES_ORDER_FAIL, 'error', t('containers.alerts.transactions.sales_order.errors.not_created'))),
          Rx.Observable.of(SalesOrdersActions.confirmSalesOrderFail(wrapError(err)))
        ));
    });
}

export default combineEpics(
  loadSalesOrders,
  confirmSalesOrder,
);
