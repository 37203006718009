import { combineEpics } from 'redux-observable';

import invoiceEpic from './invoices';
import ordersEpic from './orders';
import salesOrdersEpic from './sales-orders';
import transactionsEpic from './transactions';
import paymentsEpic from './payments';


export default combineEpics(
  invoiceEpic,
  ordersEpic,
  salesOrdersEpic,
  transactionsEpic,
  paymentsEpic,
);
