import Rx from 'rxjs-legacy';
import get from 'lodash/get';

import { stripe } from './stripe';


function init(data) {
  return (next, error, complete) => {
    stripe.createSource(data).then(({ source }) => {
      const redirect = get(source, 'redirect.url');
      const win = window.open(redirect, 'bancontact', 'location=false');

      if (! win || win.closed || typeof win.closed === 'undefined') {
        alert('Your browser is blocking pop-ups, please allow them for this page.');
      }

      if (source.hasOwnProperty('error')) {
        error({ source: source });
        return win.close();
      }

      const sourceId = source.id;
      const clientSecret = source.client_secret;

      const pollSource = async () => {
        const { source } = await stripe.retrieveSource({ id: sourceId, client_secret: clientSecret });

        if (source.status === 'chargeable' || source.status === 'failed') {
          complete({ source });
        }
        else if (source.status === 'consumed') {
          return;  // cancel poll
        }
        else {
          next({ source });
          setTimeout(pollSource, 1000);
        }
      };

      pollSource(source);
    });
  };
}


function initRx(data) {
  const polling = init(data);
  return Rx.Observable.create((observer) => {
    polling(observer.next.bind(observer), observer.error.bind(observer), observer.next.bind(observer));
  });
}


function initP(data) {
  return new Promise((resolve, reject) => {
    const polling = init(data);
    const noop = x => x;
    polling(noop, reject, resolve);
  });
}


export default {
  init,
  initRx,
  initP,
};
