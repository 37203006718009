import React from 'react';
import { Grid, Cell, Offset } from 'react-ittsu/layout';
import PropTypes from 'prop-types';
import gql from 'fraql';
import autobind from 'autobind-decorator';
import compose from 'lodash/flowRight';

import Title from '../components/Title';
import MainPanel from '../components/MainPanel';
import OrderOption from '../components/OrderOption';
import ErrorMessage from '../components/ErrorMessage';
import { withMedia } from '~/utils/media-provider';
import { createTranslate } from '~/utils/translation';
import EstateResetConfirmModal from '../components/EstateResetConfirmModal';
import { EstateEnums } from '../utils/estate-enums';
import { openIntercom } from '../utils';

import talkToDrawboticsIcon from '../images/icons/talk-to-drawbotics-icon.svg';
import renovationIcon from '../images/icons/renovation-icon.svg';
import newDevelopmentIcon from '../images/icons/new-development-icon.svg';
import existingPropertyIcon from '../images/icons/existing-property-icon.svg';


const tt = createTranslate('pods.order.routes.estate_property_type');


class EstatePropertyType extends React.Component {

  static propTypes = {
    estate: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      propertyType: PropTypes.string,
    }).isRequired,
    updateEstate: PropTypes.func.isRequired,
  };

  static fragments = {
    ProjectType: gql`
      fragment _ on Estate {
        id
        propertyType
        jsonMeta @client(type: Estate)
      }
    `,
  };

  state = {
    showError: false,
  };

  render() {
    const { estate, mediaSize } = this.props;
    const { showError } = this.state;
    return (
      <MainPanel
        onClickBack={null}
        onClickNext={this._goNext}>
        <Title>
          {tt('what_to_do')}
        </Title>
        {do {
          if (showError) {
            <ErrorMessage>
              {tt('errors.select_property')}
            </ErrorMessage>
          }
        }}
        <EstateResetConfirmModal>
          {(confirm) => (
            <Grid withHGutters withVGutters halfVGutters={mediaSize.isMobile}>
              <Offset size="1of6" style={{ display: mediaSize.isTablet ? 'none' : null }} />
              <Cell size="2of6" responsive="m1of2">
                <OrderOption
                  image={newDevelopmentIcon}
                  title={tt('promote_project')}
                  checked={estate.propertyType === EstateEnums.PropertyType.NEW_DEVELOPMENT}
                  onClick={() => this._updatePropertyType(EstateEnums.PropertyType.NEW_DEVELOPMENT, confirm)} />
              </Cell>
              <Cell size="2of6" responsive="m1of2">
                <OrderOption
                  image={existingPropertyIcon}
                  title={tt('market_existing')}
                  checked={estate.propertyType === EstateEnums.PropertyType.EXISTING_PROPERTY}
                  onClick={() => this._updatePropertyType(EstateEnums.PropertyType.EXISTING_PROPERTY, confirm)} />
              </Cell>
              <Offset size="1of6" style={{ display: mediaSize.isTablet ? 'none' : null }} />
              <Offset size="1of6" style={{ display: mediaSize.isTablet ? 'none' : null }} />
              <Cell size="2of6" responsive="m1of2">
                <OrderOption
                  image={renovationIcon}
                  title={tt('sell_renovation')}
                  checked={estate.propertyType === EstateEnums.PropertyType.RENOVATION}
                  onClick={() => this._updatePropertyType(EstateEnums.PropertyType.RENOVATION, confirm)} />
              </Cell>
              <Cell size="2of6" responsive="m1of2">
                <OrderOption
                  image={talkToDrawboticsIcon}
                  title={tt('talk_to_drawbotics')}
                  checked={false}
                  onClick={openIntercom} />
              </Cell>
              <Offset size="1of6" style={{ display: mediaSize.isTablet ? 'none' : null }} />
            </Grid>
          )}
        </EstateResetConfirmModal>
      </MainPanel>
    );
  }

  @autobind
  async _updatePropertyType(propertyType, confirm) {
    const { updateEstate, estate, resetEstate } = this.props;
    
    if (estate.propertyType === propertyType) {
      return;
    }

    const shouldReset = estate.propertyType != null;
    const shouldAskConfirm = Object.keys(estate.jsonMeta.steps).includes('type');
    const askConfirm = shouldAskConfirm ? confirm : () => true;
    const confirmed = ! shouldReset || (askConfirm && await askConfirm());

    if (confirmed) {
      if (shouldReset) {
        await resetEstate({
          propertyType: EstateEnums.PropertyType.NONE,
          projectType: EstateEnums.PropertyType.NONE,
          projectSubtype: EstateEnums.PropertyType.NONE
        });
      }

      const resetSteps = {
        'property-type': estate.jsonMeta.steps?.['property-type'],
      };

      const updatedEstate = await updateEstate({
        propertyType,
        metadata: JSON.stringify({ ...estate.jsonMeta, steps: resetSteps }),
      });

      if (updatedEstate.propertyType !== propertyType) {
        console.error('Something happened when updating the property type');
      }
      else {
        this.setState({ showError: false });
      }
    }
  }

  @autobind
  _goNext() {
    const { estate, goNext } = this.props;
    estate.propertyType ? goNext() : this.setState({ showError: true });
  }

}


export default compose(
  withMedia(),
)(EstatePropertyType);
