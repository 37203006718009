import { Injectable } from '@tokamakjs/react';

import { readSession } from '~/utils';

@Injectable()
export class AuthService {
  get authToken(): string | undefined {
    return readSession()?.authToken;
  }
}
