import React from 'react';
import pathToRegexp from 'path-to-regexp';
import { Route, Redirect } from 'react-router-dom';


function replaceParams(url, params) {
  const toPath = pathToRegexp.compile(url);
  return toPath(params);
}


const RedirectWithParams = ({
  from,
  to,
}) => {
  return (
    <Route
      path={from}
      render={(props) => <Redirect to={replaceParams(to, props.match.params)} />} />
  );
};


export default RedirectWithParams;
