import React from 'react';
import { Paragraph } from 'react-ittsu/components';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';

import { createTranslate } from '~/utils/translation';


const t = createTranslate('pods.order.services');


const styles = {
  note: css`
    color: ${sv.textSecondaryDark};
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
  `,
  paragraph: css`
    max-width: 800px;
    margin: 0;
  `,
};


const Note = ({ label }) => {
  return (
    <div className={styles.note}>
      <Paragraph className={styles.paragraph}>{t(label, null, label)}</Paragraph>
    </div>
  );
};


export default Note;
