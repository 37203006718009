import React from 'react';
import { connect } from 'react-redux';
import omit from 'lodash/omit';

import api from './index';


function getLegacyWithAPI(config, Component) {
  return class WithAPI extends React.Component {

    constructor(props) {
      super(props);
      this.setState = this.setState.bind(this);
    }

    componentDidMount() {
    }

    render() {
      const { state, dispatch } = this.props;
      return React.createElement(Component, {
        ...omit(this.props, ['state', 'dispatch']),
        ...config(api, this.setState, { state, dispatch }, this.props),
        ...this.state,
      });
    }

  }
}


function resolveQueries(config, api, ownProps, setState, extra) {
  const queries = config.queries(api, ownProps, extra);
  setState({ api: { loading: true } });
  const promises = Object.keys(queries).map((key) => Promise.all([key, queries[key]]));
  Promise.all(promises)
    .then((values) => values.reduce((memo, v) => ({
      ...memo,
      data: {
        ...memo.data,
        [v[0]]: v[1],
      },
    }), { loading: false }))
    .then((api) => setState({ api }));
}


function resolveMutations(config, api, ownProps, setState, extra) {
  return config.mutations(api, ownProps, setState, extra);
}


function getWithAPI(config, Component) {
  return class WithAPI extends React.Component {

    state = {
      api: {
        loading: true,
      },
    };

    constructor(props) {
      super(props);
      this.setState = this.setState.bind(this);
    }

    componentDidMount() {
      const { state, dispatch } = this.props;
      const ownProps = omit(this.props, ['state', 'dispatch']);
      resolveQueries(config, api, ownProps, this.setState, { state, dispatch });
    }

    render() {
      const { state, dispatch } = this.props;
      const ownProps = omit(this.props, ['state', 'dispatch']);
      return React.createElement(Component, {
        ...ownProps,
        ...resolveMutations(config, api, ownProps, this.setState, { state, dispatch }),
        ...this.state,
      });
    }

  }
}


export default function withAPI(queries, mutations={}) {
  const { legacy } = mutations;
  return (Component) => {
    const WithAPI = ! legacy ? getWithAPI({ queries, mutations }, Component) : getLegacyWithAPI(queries, Component);

    const mapStateToProps = (state) => ({ state });
    const mapDispatchToProps = (dispatch) => ({ dispatch });

    return connect(mapStateToProps, mapDispatchToProps)(WithAPI);
  };
}
