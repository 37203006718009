import omit from 'lodash/omit';
import { countries, regions } from 'country-data';

const exceptionalReservations = ['AC', 'CP', 'DG', 'EA', 'EU', 'EZ', 'FX', 'IC', 'SU', 'TA', 'UK', 'UN'];
const mainCountries = ['BE', 'NL', 'FR', 'GB', 'US'];


const filteredCountries = omit(countries, exceptionalReservations);

const finalCountries = {
  ...filteredCountries,
  all: [
    ...filteredCountries.all
      .filter((c) => mainCountries.includes(c.alpha2))
      .slice().sort((a, b) => a.name.localeCompare(b.name)),
    { alpha2: '_separator', name: '──────' },
    ...filteredCountries.all
      .filter((c) => ! mainCountries.includes(c.alpha2) && c.alpha2 !== 'UK')
      .filter((c) => ! exceptionalReservations.includes(c.alpha2))
      .slice().sort((a, b) => a.name.localeCompare(b.name)),
  ],
};


export default finalCountries;


export const europeanCountryCodes = [
  ...regions.easternEurope.countries,
  ...regions.westernEurope.countries,
  ...regions.northernEurope.countries,
  ...regions.southernEurope.countries,
];


export function getVatPlaceholder(countryCode) {
  switch (countryCode) {
    case 'AT': {
      return 'ATU12345678';
    }
    case 'BE': {
      return 'BE1234567890';
    }
    case 'BG': {
      return 'BG1234567890';
    }
    case 'HR': {
      return 'HR12345678901';
    }
    case 'CY': {
      return 'CY12345678X';
    }
    case 'CZ': {
      return 'CZ12345678';
    }
    case 'DK': {
      return 'DK12345678';
    }
    case 'EE': {
      return 'EE123456789';
    }
    case 'FI': {
      return 'FI12345678';
    }
    case 'FR': {
      return 'FRXX123456789';
    }
    case 'DE': {
      return 'DE123456789';
    }
    case 'EL': {
      return 'EL123456789';
    }
    case 'HU': {
      return 'HU12345678';
    }
    case 'IE': {
      return 'IE1234567WA / IE1234567FA';
    }
    case 'IL': {
      return 'IL123456789';
    }
    case 'IT': {
      return 'IT12345678901';
    }
    case 'LV': {
      return 'LV12345678901';
    }
    case 'LT': {
      return 'LT123456789012';
    }
    case 'LU': {
      return 'LU12345678';
    }
    case 'MT': {
      return 'MT12345678';
    }
    case 'NL': {
      return 'NL123456789B01';
    }
    case 'NZ': {
      return 'NZ123-456-789';
    }
    case 'NO': {
      return '123456789MVA';
    }
    case 'PL': {
      return 'PL1234567890';
    }
    case 'PT': {
      return 'PT123456789';
    }
    case 'RO': {
      return 'RO1234567890';
    }
    case 'SK': {
      return 'SK1234567890';
    }
    case 'SI': {
      return 'SI12345678';
    }
    case 'ES': {
      return 'ESX1234567X';
    }
    case 'SE': {
      return 'SE123456789012';
    }
    case 'CHE': {
      return 'CHE123.456.789';
    }
    case 'GB':
    case 'UK': {
      return 'UK123456789';
    }
    default: {
      return '';
    }
  }
}


export function isVatRequired(countryCode) {
  return europeanCountryCodes.includes(countryCode);
}
