import * as LoadingActions from './actions';


const initialState = {
};


export default function loading(state=initialState, action) {
  switch (action.type) {
    case LoadingActions.START_LOADING: {
      const { key } = action.payload;
      return { ...state, [key]: true };
    }
    case LoadingActions.STOP_LOADING: {
      const { key } = action.payload;
      return { ...state, [key]: false };
    }
    default: {
      return state;
    }
  }
}
