import {
  Button,
  ButtonLink,
  Category,
  Color,
  EmptyState,
  EmptyStateVariation,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Icon,
  ListTile,
  Margin,
  Shade,
  Size,
  Table,
  Tag,
  Text,
  Tier,
  Tooltip,
  useAlert,
} from '@drawbotics/react-drylus';
import copyToClipboard from 'copy-to-clipboard';
import dayjs from 'dayjs';
import { last } from 'lodash';
import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import { Item, ItemStatus } from '~/pods/dashboard/types';
import { camelize, serviceToIcon, statusToColor } from '~/pods/dashboard/utils';
import { run } from '~/utils';
import { createTranslate, translate as t } from '~/utils/translation';

const tt = createTranslate('pods.dashboard.routes.production');
const ttItem = createTranslate('pods.dashboard.routes.item');

const STATUS_ORDER: Array<ItemStatus> = [
  ItemStatus.PROBLEM,
  ItemStatus.BRIEFING_ERROR,
  ItemStatus.AWAITING_PAYMENT,
  ItemStatus.AWAITING,
  ItemStatus.ONGOING,
  ...Object.values(ItemStatus),
];

function _sortByStatus(a: Item, b: Item) {
  if (STATUS_ORDER.indexOf(a.status) > STATUS_ORDER.indexOf(b.status)) {
    return 1;
  }
  if (STATUS_ORDER.indexOf(a.status) < STATUS_ORDER.indexOf(b.status)) {
    return -1;
  }
  return a.id.localeCompare(b.id);
}

interface ItemsTableProps extends RouteComponentProps {
  isLoading: boolean;
  items?: Array<Item>;
}

export const ItemsTable = withRouter(({ items, isLoading, history, match }: ItemsTableProps) => {
  const { showAlert } = useAlert();

  const data = [...(items ?? [])].sort(_sortByStatus).map((item) => {
    const itemUrl = item?.url;

    return {
      id: item.id,
      item: (
        <ListTile
          leading={<Icon name={serviceToIcon(item.service.slug)} />}
          title={
            <Text>
              {item.product.custom
                ? item.product.name
                : t(`services.${camelize(item.service.slug)}`)}
              {item.service.slug.includes('premium') ? ' (Premium)' : ''}
            </Text>
          }
        />
      ),
      name: <Text shade={Shade.MEDIUM}>{item.name}</Text>,
      status: (
        <Flex justify={FlexJustify.START}>
          <FlexItem>
            <Tag color={statusToColor(item.status)}>
              {t(`pods.dashboard.status.${item.status}.title`)}
            </Tag>
          </FlexItem>
          <FlexSpacer size={Size.EXTRA_SMALL} />
          {item.milestones.length > 0 ? (
            <FlexItem style={{ display: 'flex' }}>
              <ListTile
                leading={run(() => {
                  if (item.status === ItemStatus.PROBLEM) {
                    return <Icon color={Color.RED} name="alert-triangle" />;
                  }
                  if (item.status === ItemStatus.ON_HOLD) {
                    return (
                      <Tooltip content={t('pods.dashboard.estate_status.on_hold.text')}>
                        <Icon shade={Shade.MEDIUM} name="alert-circle" />
                      </Tooltip>
                    );
                  }
                })}
                title={
                  <Text shade={Shade.MEDIUM} size={Size.SMALL}>
                    {tt('since', { days: dayjs(last(item.milestones)!.date).fromNow(true) })}
                  </Text>
                }
              />
            </FlexItem>
          ) : null}
        </Flex>
      ),
      actions: run(() => {
        if (item.status === ItemStatus.PROBLEM) {
          return (
            <ButtonLink size={Size.SMALL} category={Category.INFO} leading={<Icon name="eye" />}>
              {tt('review_issues')}
            </ButtonLink>
          );
        } else if (item.status === ItemStatus.AWAITING) {
          return (
            <Link onClick={(e) => e.stopPropagation()} to={`/studio/project-item/${item.id}`}>
              <ButtonLink
                size={Size.SMALL}
                category={Category.INFO}
                leading={<Icon name="edit-2" />}>
                {tt('go_to_studio')}
              </ButtonLink>
            </Link>
          );
        } else if (item.status === ItemStatus.FINISHED) {
          return (
            <Flex justify={FlexJustify.END} wrap>
              {itemUrl != null && itemUrl !== '' ? (
                <FlexItem>
                  <Margin size={{ right: Size.EXTRA_SMALL }}>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();

                        copyToClipboard(itemUrl);
                        showAlert({
                          text: ttItem('link_copied'),
                          category: Category.INFO,
                        });
                      }}
                      size={Size.SMALL}
                      tier={Tier.SECONDARY}
                      leading={<Icon name="link" />}>
                      {ttItem('copy_task_link')}
                    </Button>
                  </Margin>
                </FlexItem>
              ) : null}
              <FlexItem>
                <ButtonLink
                  size={Size.SMALL}
                  tier={Tier.SECONDARY}
                  leading={<Icon name="download" />}>
                  {tt('download_files')}
                </ButtonLink>
              </FlexItem>
            </Flex>
          );
        } else if (
          item.status === ItemStatus.AWAITING_PAYMENT ||
          item.status === ItemStatus.REVIEWING_BRIEFING
        ) {
          // Don't allow any actions in this case
          return null;
        } else if (item.status === ItemStatus.AWAITING_BRIEFING) {
          return (
            <Link
              onClick={(e) => e.stopPropagation()}
              to={`/order/briefing/${item.estate.id}/${camelize(item.service.slug)}/${item.id}`}>
              <ButtonLink
                size={Size.SMALL}
                category={Category.INFO}
                leading={<Icon name="clipboard" />}>
                {tt('complete_briefing')}
              </ButtonLink>
            </Link>
          );
        } else {
          return (
            <ButtonLink size={Size.SMALL} tier={Tier.SECONDARY} leading={<Icon name="eye" />}>
              {tt('see_item')}
            </ButtonLink>
          );
        }
      }),
    };
  });

  return (
    <Table
      isLoading={isLoading}
      emptyContent={
        isLoading == false && items == null ? (
          <EmptyState
            variation={EmptyStateVariation.FAILED}
            description={tt('items_could_not_load')}
          />
        ) : null
      }
      clickable
      highlighted
      onClickRow={(row) => history.push(`${match.url}/${row.id}`)}
      data={data}
      header={[
        { value: 'item', label: tt('item') },
        { value: 'name', label: tt('item_name') },
        { value: 'status', label: tt('status') },
        { value: 'actions', label: tt('actions') },
      ]}
    />
  );
});
