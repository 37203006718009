import React from 'react';
import PropTypes from 'prop-types';
import gql from 'fraql';
import { compose, graphql } from 'react-apollo-redux';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import produce from 'immer';
import pullAllBy from 'lodash/pullAllBy';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';
import omit from 'lodash/omit';
import get from 'lodash/get';

import { triggerNotFound } from '~/actions';
import { withUser } from '~/utils/with-user';
import SidebarLayout from '../components/SidebarLayout';
import OrderWizard, { Step } from '../components/OrderWizard';
import EstateSidebar from '../components/EstateSidebar';
import EstatePropertyType from './EstatePropertyType';
import EstateType from './EstateType';
import EstateSubtype from './EstateSubtype';
import EstateDetails from './EstateDetails';
import EstateServices from './EstateServices';
import EstateDelivery from './EstateDelivery';
import EstateSummary from './EstateSummary';
import Spinner from '~/components/Spinner';
import { createTranslate, translate as t } from '~/utils/translation';
import { shouldRequireModeling, processServices } from '../utils';
import { priceItem, priceEstateModeling } from '../utils/pricing';
import { showLoading, hideLoading } from '../actions';
import { EstateEnums } from '~/pods/order/utils/estate-enums';


const tt = createTranslate('pods.order.routes.estate');


export const ServicesQuery = gql`
  query ServicesQuery {
    serviceCategories: categories {
      id: slug
      slug
      services {
        ${EstateServices.fragments.Service()}
      }
    }
  }
`;


export const EstateQuery = gql`
  query EstateQuery($estateId: ID!) {
    estate(estateId: $estateId) {
      id
      metadata
      jsonMeta @client(type: Estate)
      propertyType
      orderCompleted @client(type: Estate)
      modelingRequired
      projectId
      ${EstateType.fragments.Type}
      ${EstateSubtype.fragments.Subtype}
      ${EstateDetails.fragments.Details}
      ${EstateServices.fragments.Items()}
      ${EstateDelivery.fragments.Delivery()}
      ${EstateSummary.fragments.Summary()}
      ${EstateSidebar.fragments.Estate()}
    },
  },
`;


export const CreateEstateMutation = gql`
  mutation CreateEstateMutation(
    $userId: ID!
    $organizationId: ID!
  ) {
    payload: createOrderEstate(
      input: {
        clientUserId: $userId
        organisationId: $organizationId
      }
    ) {
      createdEstate: orderEstate {
        id
      }
    }
  }
`;


export const UpdateEstateMutation = gql`
  mutation UpdateEstateMutation(
    $id: ID!
    $propertyType: PropertyTypeEnum
    $projectType: ProjectTypeEnum
    $projectSubtype: ProjectSubtypeEnum
    $name: String
    $unitCount: Int
    $surface: SurfaceInput
    $address: AddressInput
    $delivery: DeliveryEnum
    $metadata: String
    $structureCount: Int
    $apartmentCount: Int
    $houseCount: Int
    $modelingRequired: Boolean
    $modelingPrice: PriceInput
    $basePrice: PriceInput
  ) {
    payload: updateOrderEstate(
      input: {
        orderEstateId: $id
        name: $name
        metadata: $metadata
        propertyType: $propertyType
        projectType: $projectType
        projectSubtype: $projectSubtype
        address: $address
        surface: $surface
        unitCount: $unitCount
        structureCount: $structureCount
        apartmentCount: $apartmentCount
        houseCount: $houseCount
        delivery: $delivery
        modelingRequired: $modelingRequired
        modelingPrice: $modelingPrice
        basePrice: $basePrice
      }
    ) {
      updatedEstate: orderEstate {
        id
        metadata
        jsonMeta @client(type: Estate)
        orderCompleted @client(type: Estate)
        modelingRequired
        ${EstatePropertyType.fragments.ProjectType}
        ${EstateType.fragments.Type}
        ${EstateSubtype.fragments.Subtype}
        ${EstateDetails.fragments.Details}
        ${EstateServices.fragments.Items()}
        ${EstateDelivery.fragments.Delivery()}
        ${EstateSummary.fragments.Summary()}
        ${EstateSidebar.fragments.Estate()}
      }
    },
  },
`;


export const UpdateEstateMetadataMutation = gql`
  mutation UpdateOrderEstate(
    $id: ID!
    $metadata: String
  ) {
    payload: updateOrderEstate(
      input: {
        orderEstateId: $id
        metadata: $metadata
      }
    ) {
      updatedEstate: orderEstate {
        id
        metadata
        jsonMeta @client(type: Estate)
      }
    }
  }
`;


export const UpdateEstatePriceMutation = gql`
  mutation UpdateOrderEstate(
    $id: ID!
    $modelingPrice: PriceInput
    $basePrice: PriceInput
  ) {
    payload: updateOrderEstate(
      input: {
        orderEstateId: $id
        basePrice: $basePrice
        modelingPrice: $modelingPrice
      }
    ) {
      updatedEstate: orderEstate {
        id
        modelingPrice @client(type: Estate)
        discountedPrice {
          value
          currency
        }
      }
    }
  }
`;


export const AddItemsMutation = gql`
  mutation AddItemsMutation(
    $estateId: ID!
    $items: [OrderItemInput!]!
  ) {
    payload: addOrderItems(
      input: {
        orderEstateId: $estateId
        items: $items
      }
    ) {
      addedItems: items {
        id
        # NOTE: because we use item.briefingConfirmedAt somewhere else, when creating a new item without this field, the Missing field in _ is triggered as we manually write to the store (below)
        # Will need to show Lars to replicate
        # briefingConfirmedAt
        ${EstateServices.fragments.Item()}
        ${EstateSummary.fragments.Item()}
        ${EstateSidebar.fragments.Item()}
        ${EstateDelivery.fragments.Item()}
      }
    }
  }
`;


export const RemoveItemsMutation = gql`
  mutation RemoveItemsMutation(
    $estateId: ID!
    $itemIds: [ID!]!
  ) {
    payload: removeOrderItems(
      input: {
        orderEstateId: $estateId
        itemIds: $itemIds
      }
    ) {
      removedItems: items {
        id
        ${EstateServices.fragments.Item()}
        ${EstateSummary.fragments.Item()}
        ${EstateSidebar.fragments.Item()}
        ${EstateDelivery.fragments.Item()}
      }
    }
  }
`;


export const UpdateItemsMutation = gql`
  mutation UpdateItemsMutation(
    $estateId: ID!
    $items: [OrderItemInput!]!
  ) {
    payload: updateOrderItems(
      input: {
        orderEstateId: $estateId
        items: $items
      }
    ) {
      updatedItems: items {
        id
        ${EstateServices.fragments.Item()}
        ${EstateSummary.fragments.Item()}
        ${EstateSidebar.fragments.Item()}
        ${EstateDelivery.fragments.Item()}
      }
    }
  }
`;


const styles = {
  estate: css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  `,
  timeLine: css`
    flex-shrink: 0;
  `,
  container: css`
    width: 100%;
    max-width: 1200px;
    min-height: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    margin: auto;
    padding: 0 ${sv.basePadding};

    @media ${sv.ipad} {
      padding: 0;
    }

    @media print {
      padding: 0;
    }
  `,
};


class Estate extends React.Component {

  static propTypes = {
    estateId: PropTypes.string,
  };

  state = {
    activeService: null,
  };

  componentDidMount() {
    const { match } = this.props;
    if (! match.params.estateId) {
      this._createEstate();
    }
  }

  componentDidUpdate(prevProps) {
    // If this orderEstate alraedy belongs to a project becaus eit was initially created through Mosaic,
    // skip the first step of the order wizard because the property type will always be new development.
    const updateEstate = this._updateEstate;
    const estate = this._getEstate()

    if (estate !== prevProps.estateData?.estate && estate?.projectId != null) {
      updateEstate({
        propertyType: EstateEnums.PropertyType.NEW_DEVELOPMENT,
      })
    }

    // By default, set delivery to standard for new developments, as the choice is not given
    if (estate?.propertyType === EstateEnums.PropertyType.NEW_DEVELOPMENT && estate?.delivery == null && estate !== prevProps.estateData?.estate) {
      updateEstate({
        delivery: EstateEnums.Delivery.STANDARD,
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { estateData, triggerNotFound } = this.props;
    const { estateData: nextEstateData } = nextProps;
    if (estateData?.loading && ! nextEstateData?.loading && ! nextEstateData?.estate) {
      return triggerNotFound();
    }
  }

  render() {
    const { match, estateData, servicesData, history, location } = this.props;
    
    if (! estateData) {
      return <Spinner size={50} containerStyle={{ margin: 'auto' }} text={tt('creating_project')} />
    }
    
    const { loading: loadingEstate } = estateData;
    const { loading: loadingServices } = servicesData;
    const estate = this._getEstate();
    const updateEstate = this._updateEstate;
    const addItems = this._addItems;
    const removeItems = this._removeItems;
    const modifyService = this._handleModifyService;
    const resetEstate = this._resetEstate;
    
    if (loadingEstate || ! estate || loadingServices) {
      return <Spinner size={50} containerStyle={{ margin: 'auto' }} text={t('pods.order.loading')} />
    }

    const { steps } = estate.jsonMeta;
    const showSummary = estate.items.length > 0;
    const services = this._getServices();
    const processedServices = processServices(services.filter((s) => ! s.abstract));
    const servicesForEstate = estate.isExistingProperty ? processedServices.existingProperty : processedServices.newDevelopment;
    
    return (
      <div className={styles.estate}>
        <SidebarLayout
          sidebar={showSummary ? () => (
            <EstateSidebar
              estate={estate}
              isMobileFinalAction={location.pathname.includes('summary')}
              mobileButtonText={location.pathname.includes('summary') ? tt('checkout') : tt('view_summary')}
              onClickAction={() => history.push(`/order/recap/${estate.id}`)}/>
          ) : null}
          content={() => (
            <div className={styles.container}>
              <OrderWizard history={history} basename={match.url} onGoNext={this._setAsVisited}>
              {do{
                if (estate.projectId == null) {
                <Step
                  id="property-type"
                  title="Property Type"
                  checked={steps?.['property-type']?.visited}
                  component={EstatePropertyType}
                  withProps={{ estate, updateEstate, resetEstate }} />
                }
              }}
                <Step
                  id="type"
                  title="Type"
                  checked={steps?.type?.visited}
                  component={EstateType}
                  withProps={{ estate, updateEstate, resetEstate }} />
                <Step
                  id="subtype"
                  title="Subtype"
                  checked={steps?.subtype?.visited}
                  component={EstateSubtype}
                  withProps={{ estate, updateEstate, resetEstate }} />
                <Step
                  id="details"
                  title="Details"
                  checked={steps?.details?.visited}
                  component={EstateDetails}
                  withProps={{ estate, updateEstate }} />
                {do{
                  if (servicesForEstate.byCategory.visualization?.length > 0) {
                    <Step
                      id="services/visualization"
                      title="Visualise"
                      checked={steps?.['services/visualization']?.visited}
                      component={EstateServices}
                      withProps={{
                        estate,
                        category: 'visualization',
                        services: servicesForEstate.byCategory.visualization,
                        modifyService,
                      }} />
                  }
                }}
                {do{
                  if (servicesForEstate.byCategory.innovation?.length > 0) {
                    <Step
                      id="services/innovation"
                      title="Innovate"
                      checked={steps?.['services/innovation']?.visited}
                      component={EstateServices}
                      withProps={{
                        estate,
                        category: 'innovation',
                        services: servicesForEstate.byCategory.innovation,
                        modifyService,
                      }} />
                  }
                }}
                {do{
                  if (servicesForEstate.byCategory.communication?.length > 0) {
                    <Step
                      id="services/communication"
                      title="Communicate"
                      checked={steps?.['services/communication']?.visited}
                      component={EstateServices}
                      withProps={{
                        estate,
                        category: 'communication',
                        services: servicesForEstate.byCategory.communication,
                        modifyService,
                      }} />
                  }
                }}
                {estate.propertyType !== EstateEnums.PropertyType.NEW_DEVELOPMENT ?
                  <Step
                    id="delivery"
                    title="Delivery"
                    checked={steps?.delivery?.visited}
                    component={EstateDelivery}
                    withProps={{ estate, updateEstate, modifyService }} />
                : null}
                <Step
                  id="summary"
                  title="Summary"
                  checked={steps?.summary?.visited}
                  component={EstateSummary}
                  withProps={{ estate, services, addItems, removeItems, modifyService }} />
              </OrderWizard>
            </div>
          )} />
      </div>
    );
  }

  @autobind
  async _createEstate() {
    const { createEstate, match, history, sessionUser } = this.props;
    const { data: result } = await createEstate({
      variables: { userId: sessionUser.id, organizationId: sessionUser.organization.id },
    });
    history.push(`${match.url}/${result.payload.createdEstate.id}`);
  }

  @autobind
  async _updateEstate(variables) {
    const { estateData, updateEstate, showLoading, hideLoading } = this.props;
    const { estate } = estateData;
    showLoading();
    const { data: result } = await updateEstate({
      variables: {
        ...variables,
        id: estate.id,
      },
    });

    const { payload } = result;
    const updatedEstate = payload?.updatedEstate;
    await this._syncEstatePrices(updatedEstate);
    hideLoading();
    return updatedEstate;
  }

  @autobind
  async _addItems(variables) {
    const { addItems, estateData } = this.props;
    const { data: result } = await addItems({ variables: { ...variables, estateId: estateData.estate.id } });
    const { addedItems } = result;
    return addedItems;
  }

  @autobind
  async _updateItems(variables) {
    const { updateItems, estateData } = this.props;
    const { data: result } = await updateItems({ variables: { ...variables, estateId: estateData.estate.id } });
    const { updatedItems } = result;
    return updatedItems;
  }

  @autobind
  async _removeItems(variables) {
    const { removeItems, estateData } = this.props;
    showLoading();
    const { data: result } = await removeItems({ variables: { ...variables, estateId: estateData.estate.id } });
    const { removedItems } = result;
    const estate = this._getEstate();
    if (estate.items.length === 0) {
      this._setAsUnvisited([
        'delivery',
        'summary',
      ]);
    }
    hideLoading();
    return removedItems;
  }

  @autobind
  async _handleModifyService(changes) {
    showLoading();
    const { sessionUser } = this.props;
    const estate = this._getEstate();
    const { add, remove, update } = changes;
    if (add.length > 0) {
      const itemInputs = add.map((item) => ({
        price: priceItem({ ...item, estate }, sessionUser.currency.code),
        productSlug: this._getProductById(item.product.id).slug,
        details: item.details,
        serviceId: this._getServiceByProductId(item.product.id).id,
      }));
      await this._addItems({ estateId: estate.id, items: itemInputs });
    }
    if (remove.length > 0) {
      const itemInputs = remove.map((item) => item.id);
      await this._removeItems({ itemIds: itemInputs });
    }
    if (update.length > 0) {
      const itemInputs = update.map((item) => {
        const existingItem = estate.items.find((i) => i.id === item.id);
        const mergedDetails = { ...existingItem.details, ...item.details };
        return {
          id: item.id,
          price: priceItem({ ...item, details: mergedDetails, estate }, sessionUser.currency.code),
          productSlug: this._getProductById(item.product.id).slug,
          details: omit(item.details, ['__typename']),
          serviceId: this._getServiceByProductId(item.product.id).id,
        };
      });
      await this._updateItems({ items: itemInputs });
    }
    this._setModelingRequired();
    hideLoading();
  }

  @autobind
  _setModelingRequired() {
    const estate = this._getEstate();
    const modelingRequired = shouldRequireModeling(estate);
    this._updateEstate({ modelingRequired });
  }

  @autobind
  async _setAsVisited(step, nextStep) {
    const { estateData, updateEstateMetadata } = this.props;
    const { estate } = estateData;
    const { id } = nextStep;
    const { id: prevStepId } = step;
    const newMetadata = JSON.stringify(produce(estate.jsonMeta, (metadata) => {
      metadata.steps[id] = metadata.steps[id] || {};
      metadata.steps[id].visited = true;
      metadata.steps[prevStepId] = metadata.steps[prevStepId] || {};
      metadata.steps[prevStepId].visited = true;
    }));
    await updateEstateMetadata({
      variables: {
        id: estate.id,
        metadata: newMetadata,
      },
    });
  }

  @autobind
  async _setAsUnvisited(stepIds) {
    const { updateEstate } = this.props;
    const estate = this._getEstate();
    const newMetadata = JSON.stringify(produce(estate.jsonMeta, (metadata) => {
      stepIds.forEach((stepId) => metadata.steps[stepId] ? metadata.steps[stepId].visited = false : null);
    }));
    updateEstate({
      variables: {
        id: estate.id,
        metadata: newMetadata,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        payload: {
          __typename: 'UpdateOrderEstatePayload',
          updatedEstate: {
            ...estate,
            metadata: newMetadata,
          },
        },
      },
    });
  }

  @autobind
  async _resetEstate(baseData) {
    const estate = this._getEstate();
    await this._updateEstate(baseData);
    await this._removeItems({ itemIds: estate.items.map(({ id }) => id) });
  }

  _getProductById(id) {
    const services = this._getServices();
    const allProducts = services.reduce((memo, s) => [ ...memo, ...s.products ], []);
    return allProducts.find((p) => p.id === id);
  }

  _getServiceByProductId(id) {
    const product = this._getProductById(id);
    return product.service;
  }

  _getServices() {
    const { servicesData } = this.props;
    const { serviceCategories } = servicesData;
    return serviceCategories.reduce((memo, category) => [ ...memo, ...category.services ], []);
  }

  _getEstate() {
    const { estateData } = this.props;
    // const { estate } = estateData;
    const estate = get(estateData, 'estate', undefined)
    return estate;
  }

  _getLatestEstatePrices(estate) {
    const { sessionUser } = this.props;
    const basePrice = estate.items.reduce((price, item) => price + priceItem(item, sessionUser.currency.code), 0);
    const modelingPrice = priceEstateModeling(estate, sessionUser.currency.code);
    return {
      basePrice: {
        value: basePrice,
        currency: sessionUser.currency.code,
      },
      modelingPrice: {
        value: modelingPrice,
        currency: sessionUser.currency.code,
      },
    };
  }

  @autobind
  async _syncEstatePrices(estate) {
    const { updateEstatePrice } = this.props;
    const { basePrice, modelingPrice } = this._getLatestEstatePrices(estate);
    await updateEstatePrice({
      variables: {
        id: estate.id,
        basePrice,
        modelingPrice,
      },
    });
  }

}


const mapStateToProps = (state, ownProps) => ({
  estateId: ownProps.match.params.estateId,
});


const mapDispatchToProps = {
  triggerNotFound,
  showLoading,
  hideLoading,
};


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withUser(),
  graphql(ServicesQuery, {
    name: 'servicesData',
  }),
  graphql(EstateQuery, {
    name: 'estateData',
    skip: (ownProps) => ! ownProps.match.params.estateId,
    options: (ownProps) => ({ variables: { estateId: ownProps.match.params.estateId }}),
  }),
  graphql(CreateEstateMutation, {
    name: 'createEstate',
  }),
  graphql(UpdateEstateMutation, { name: 'updateEstate' }),
  graphql(UpdateEstateMetadataMutation, { name: 'updateEstateMetadata' }),
  graphql(UpdateEstatePriceMutation, { name: 'updateEstatePrice' }),
  graphql(AddItemsMutation, {
    name: 'addItems',
    options: (ownProps) => ({
      update(proxy, { data }) {
        const estateId = String(ownProps.estateId);
        const existingData = proxy.readQuery({ query: EstateQuery, variables: { estateId } });
        existingData.estate.items.push(...data.payload.addedItems);
        proxy.writeQuery({ query: EstateQuery, variables: { estateId }, data: existingData });
      },
    }),
  }),
  graphql(UpdateItemsMutation, {
    name: 'updateItems',
    options: (ownProps) => ({
      update(proxy, { data }) {
        const estateId = String(ownProps.estateId);
        const existingData = proxy.readQuery({ query: EstateQuery, variables: { estateId } });
        const newItems = existingData.estate.items.map((existingItem) => {
          const updatedItem = data.payload.updatedItems.find((updatedItem) => updatedItem.id === existingItem.id);
          return updatedItem != null ? updatedItem : existingItem;
        });
        existingData.estate.items = newItems;
        proxy.writeQuery({ query: EstateQuery, variables: { estateId }, data: existingData });
      },
    }),
  }),
  graphql(RemoveItemsMutation, {
    name: 'removeItems',
    options: (ownProps) => ({
      update(proxy, { data }) {
        const estateId = String(ownProps.estateId);
        const existingData = proxy.readQuery({ query: EstateQuery, variables: { estateId } });
        pullAllBy(existingData.estate.items, data.payload.removedItems, 'id');
        proxy.writeQuery({ query: EstateQuery, variables: { estateId }, data: existingData });
      },
    }),
  }),
)(Estate);
