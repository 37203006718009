import { Add, Switch, ForEach, RelativeAdd } from 'drawbotics-pricer';


const description = {
  Base: [
    Switch('estate.projectType').is({
      'residential': [
        Switch('details.type').is({
          'apartment': [
            Switch('details.rooms').is({
              '0': Add({ EUR: 1200, GBP: 1200, USD: 1500 }),
              '1': Add({ EUR: 1650, GBP: 1650, USD: 2070 }),
              '2': Add({ EUR: 2000, GBP: 2000, USD: 2500 }),
              '3': Add({ EUR: 2350, GBP: 2350, USD: 2940 }),
              '4': Add({ EUR: 2600, GBP: 2600, USD: 3250 }),
              '5': Add({ EUR: 2850, GBP: 2850, USD: 3570 }),
            }),
          ],
          'house': [
            Switch('details.rooms').is({
              '2': Add({ EUR: 2350, GBP: 2350, USD: 2940 }),
              '3': Add({ EUR: 2600, GBP: 2600, USD: 3250 }),
              '4': Add({ EUR: 2850, GBP: 2850, USD: 3570 }),
              '5': Add({ EUR: 3000, GBP: 3000, USD: 3750 }),
            }),
          ],
        }),
        ForEach('details.gardenHotspots').do(Add({ EUR: 750, GBP: 750, USD: 940 })),
        ForEach('details.terraceHotspots').do(Add({ EUR: 650, GBP: 650, USD: 820 })),
      ],
      'commercial': [
        ForEach('details.commercialHotspots').minimum(3).do(Add({ EUR: 750, GBP: 750, USD: 1000 })),
      ],
    })
  ],
  Delivery: [
    Switch('estate.delivery').is({
      'relaxed': RelativeAdd(-0.1).from('Base'),
      'standard': RelativeAdd(0).from('Base'),
      'express': RelativeAdd(0.5).from('Base'),
    })
  ],
};


export default description;
