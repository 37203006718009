import React from 'react';
import { Grid, Cell } from 'react-ittsu/layout';

import { createTranslate } from 'utils/translation';
import Link from 'components/Link';

import styles from 'pods/studio/styles/components/previews';


const tt = createTranslate('pods.studio.components.preview');


const Preview = ({
  preview,
}) => (
  <div className={styles.preview}>
    <div className={styles.imageContainer}>
      <div className={styles.image} style={{ backgroundImage: `url("${preview.url}")` }}>
        { do {
          if (preview.problemStatus === 'unsolved') {
            <div className={styles.problemLayer}>
              <div className={styles.validatedLabel}>
                {tt('problem')}
              </div>
            </div>
          }
          else if (preview.validatedAt) {
            <div className={styles.validatedLayer}>
              <div className={styles.validatedLabel}>
                <i className="dbi dbi-check" />
                {preview.hasAnnotations() ?
                  tt('correction_approved') : tt('design_approved')}
              </div>
            </div>
          }
          else if ( ! preview.validatedAt) {
            <div data-element="hover-image" className={styles.hoverImage}>
              <div className={styles.hoverButton}>
                {tt('see_design')}
              </div>
            </div>
          }
        }}
      </div>
    </div>
    <div className={styles.info}>
      <div className={styles.comments}>
        <div className={styles.commentsCount}>{preview.annotations.length}</div>{tt('comments', preview.annotations.length)}
      </div>
      <div className={styles.filename}>
        {preview.filename}
      </div>
    </div>
  </div>
);


const Previews = ({
  previews,
}) => {
  return (
    <div className={styles.previews}>
      <Grid withHGutters withVGutters>
        {previews.map((preview, i) => (
          <Cell key={i} size="1of3" responsive="l1of2, m1of1, s1of1">
            <Link
              key={preview.id}
              style={{ display: 'block' }}
              href={`/studio/project-item/${preview.draft.projectItem.id}/preview/${preview.id}`}>
              <Preview preview={preview} />
            </Link>
          </Cell>
        ))}
      </Grid>
    </div>
  );
};


export default Previews;
