import React from 'react';
import { connect } from 'react-redux';
import {
  T2,
  T3,
  Panel,
  Icon,
  Table,
  TRow,
  TBody,
  TCell,
  Button,
} from 'react-ittsu/components';
import { Container } from 'react-ittsu/layout';
import get from 'lodash/get';

import RouteContainer from 'components/RouteContainer';
// import Participants from 'pods/dashboard/components/Participants';
import LoadingContainer from 'containers/LoadingContainer';
import { isModalShown, getSessionUser } from 'selectors';
import { loadOrder } from 'pods/transactions/actions';
import { getOrder } from 'pods/transactions/selectors';
import { showModal, hideModal } from 'actions/modals';
import { addFollower, removeFollower } from 'pods/dashboard/actions';
import { createInvitation, updateInvitation } from 'actions/invitation';
import { invalidateQuery } from 'actions/cache-invalidation';
import { translate as t, createTranslate } from 'utils/translation';

import styles from 'pods/transactions/styles/routes/after-order';


const tt = createTranslate('pods.transactions.routes.after_order');

const INVITE_MODAL = 'INVITE_NEW_FOLLOWER_AFTER_ORDER_MODAL';


class AfterOrder extends React.Component {

  constructor(props) {
    super(props);
    this._removeFollower = this._removeFollower.bind(this);
    this._addFollower = this._addFollower.bind(this);
    this._handleInviteNew = this._handleInviteNew.bind(this);
    this._openInviteModal = this._openInviteModal.bind(this);
    this._continueToDashboard = this._continueToDashboard.bind(this);
  }

  componentDidMount() {
    const { loadOrder, params } = this.props;
    loadOrder(params.id);
  }

  render() {
    const { order } = this.props;
    if (! order) {
      return null;
    }
    return (
      <LoadingContainer
        loadingKeys="after-order"
        whenLoaded={() => (
          <RouteContainer>
            <Container>
              <T2 brand>
                {tt('order_summary')}
              </T2>
              <div className={styles.subtitle}>
                {tt('recap')}
              </div>
              <div className={styles.list}>
                {order.projects.map((project, i) => (
                  <div key={i} className={styles.section}>
                    <Panel>
                      <T3 brand>{project.name}</T3>
                      <Table variation="borderless" className={styles.services}>
                        <colgroup>
                          <col style={{width: 50}} />
                          <col />
                        </colgroup>
                        <TBody>
                          {project.items.map((item, i) => (
                            <TRow key={i}>
                              <TCell>
                                <div className={styles.icon}>
                                  <Icon name={item.productSlug} />
                                </div>
                              </TCell>
                              <TCell>
                                <div className={styles.info}>
                                  <div className={styles.name}>
                                    {t(`services.${item.legacyName}`)}
                                  </div>
                                  <div className={styles.reference}>
                                    {item.reference}
                                  </div>
                                </div>
                              </TCell>
                            </TRow>
                          ))}
                        </TBody>
                      </Table>
                      {/* <Participants
                        participants={get(project, 'items[0].followers', [])}
                        invitations={get(project, 'items[0].invitations', [])}
                        organizationUsers={organizationUsers}
                        removeFollower={(f) => this._removeFollower(f, project)}
                        addFollower={(e, r) => this._addFollower(e, r, project)}
                        showInviteModal={this._openInviteModal}
                        hideInviteModal={() => hideModal(INVITE_MODAL)}
                        inviteNewFollower={(i) => this._handleInviteNew(i, project)}
                        isInviteModalShown={isInviteModalShown}
                        nolinkGeneration /> */}
                    </Panel>
                  </div>
                ))}
              </div>
              <div className={styles.actions}>
                <div className={styles.action}>
                  <Button category="primary" onClick={this._continueToDashboard}>{tt('continue')}</Button>
                </div>
              </div>
            </Container>
          </RouteContainer>
        )} />
    );
  }

  _removeFollower(follower, project) {
    const { removeFollower } = this.props;
    const firstItem = get(project, 'items[0]', null);
    removeFollower({ ...follower, item: project.id }, firstItem, project);
  }

  _addFollower(email, project) {
    const { addFollower } = this.props;
    const firstItem = get(project, 'items[0]', null);
    addFollower({ email, item: project.id }, firstItem, project);
  }

  _openInviteModal() {
    const { showModal } = this.props;
    showModal(INVITE_MODAL);
  }

  _handleInviteNew(invitation, project) {
    const { sessionUser, createInvitation, hideModal, order } = this.props;
    const organization = get(sessionUser, 'organization.id');
    const item = get(project, 'items[0]', null);
    hideModal(INVITE_MODAL);
    createInvitation({ ...invitation, items: [item.id], organization }, item, order);
  }

  _continueToDashboard() {
    const { push, invalidateQuery } = this.props;
    invalidateQuery('ProjectsQuery');
    push('/');
  }
}


const mapStateToProps = (state, ownProps) => ({
  isInviteModalShown: isModalShown(state, INVITE_MODAL),
  order: getOrder(state, ownProps.match.params.id),
  params: ownProps.match.params,
  sessionUser: getSessionUser(state),
  push: ownProps.history.push,
});


const mapDispatchToProps = {
  loadOrder,
  addFollower,
  removeFollower,
  showModal,
  hideModal,
  createInvitation,
  updateInvitation,
  invalidateQuery,
};


export default connect(mapStateToProps, mapDispatchToProps)(AfterOrder);
