const k = (key) => `exterior_tour_3d.briefing.${key}`;


const residential = {
  1: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('outdoor_and_landscaping'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('outdoor_and_landscaping_description'),
        component: 'Note',
      },
      {
        type: 'question-component',
        label: k('add_comment'),
        component: 'Input',
        key: 'landscapingPlan',
        keyLabel: k('landscaping_comment'),
      },
      {
        type: 'question-component',
        label: k('upload_landscaping'),
        component: 'FileUploader',
        key: 'landscapingPlanAttachments',
        keyLabel: k('keys.landscaping_attachments'),
        required: 'all',
      },
    ],
  },
  2: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'shared.additional_info',
        component: 'Title',
      }, {
        type: 'component',
        label: 'shared.anything_to_add',
        component: 'Note',
      }, {
        type: 'question-component',
        label: 'shared.additional_comments',
        component: 'Input',
        key: 'otherInfo',
        keyLabel: 'shared.other_info',
      }, {
        type: 'question-component',
        label: 'shared.upload_files',
        component: 'FileUploader',
        key: 'otherInfoAttachments',
        keyLabel: 'shared.other_info_attachments',
      },
    ],
  },
};


export default { residential };
