import React from 'react';
import {
  Handle,
  Slider,
} from 'react-ittsu/forms';
import {
  Icon,
} from 'react-ittsu/components';

import Link from 'components/Link';
import { createTranslate } from 'utils/translation';

import styles from 'pods/transactions/styles/components/credit-slider';


const tt = createTranslate('pods.transactions.components.credit_slider');


const Tooltip = ({ title, subtitle }) => (
  <div>
    <div className={styles.text}>
      {title}
    </div>
    <div className={styles.text}>
      {do {
        if (subtitle) {
          <span>{subtitle}</span>
        }
        else {
          <Link href="#" underlined="always">{tt('contact_us')}</Link>
        }
      }}
    </div>
  </div>
);

const CreditHandle = (props) => {
  const { value, ranges, getCurrentRange } = props;
  const currentRange = getCurrentRange(ranges, value);
  const subtitle = tt('bonus_with_value', { bonus: currentRange.bonus });
  return (
    <Handle {...props}
      value={<Tooltip title={`${value} credits`} subtitle={subtitle} />}
    />
  );
};


const CreditSlider = (props) => {
  const { data, getCurrentRange, onChange, credits } = props;
  return (
    <div className={styles.creditSliderContainer}>
      <Icon name="credits-few" className={styles.icon} />
      <div className={styles.creditSlider}>
        <div className={styles.creditsIndicator}>
          {data.bonusRanges.slice(1).map((range, i) => (
            <div key={i} className={styles.indicator} style={{ left: `${(range.start / data.maxCredit) * 100}%` }}>
              {`${range.value || range.start}`}
            </div>
          ))}
        </div>
        <Slider
          min={data.minCredit}
          max={data.maxCredit}
          step={data.step}
          value={credits}
          handle={({ ref, ...rest }) => <CreditHandle {...rest} ranges={data.bonusRanges} getCurrentRange={getCurrentRange} />}
          onChange={(v) => onChange(v)}
          variation="alt"
        />
        <div className={styles.bonusSteps}>
          {data.bonusRanges.slice(1).map((range, i) => (
            <div key={i} className={styles.step} style={{ left: `${(range.start / data.maxCredit) * 100}%` }}>
              {`+${range.bonus}`}
            </div>
          ))}
        </div>
      </div>
      <Icon name="credits-lots" className={styles.icon} />
    </div>
  );
};


export default CreditSlider;
