import gql from 'fraql';


const Unit = {
  typeName: 'Unit',
  fields: {
    typology: {
      dependencies: gql`
        fragment _ on Unit {
          bedrooms
        }
      `,
      resolver: (unit) => `T${unit.bedrooms + 1}`,
    },
    building: {
      dependencies: gql`
        fragment _ on Unit {
          id
          floor {
            id
            building {
              id
              reference
            }
          }
        }
      `,
      resolver: (unit) => unit.floor.building,
    },
    project: {
      dependencies: gql`
        fragment _ on Unit {
          id
          floor {
            id
            building {
              id
              orderEstate {
                id
                name
              }
            }
          }
        }
      `,
      resolver: (unit) => unit.floor.building.orderEstate,
    },
  },
};


export default Unit;
