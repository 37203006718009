import { Add, Switch, ForEach, RelativeAdd } from 'drawbotics-pricer';


const description = {
  Base: [
    Switch('estate.projectType').is({
      'residential': [
        Switch('details.type').is({
          'apartment': Add({ EUR: 250, GBP: 250, USD: 300 }),
          'duplex': Add({ EUR: 350, GBP: 350, USD: 550 }),
          'house': Add({ EUR: 450, GBP: 450, USD: 600 }),
        }),
      ],
      'commercial': [
        Switch('currency').is({
          'EUR': [
            ForEach('estate.surface.value').minimum(700).do([
              Add(0.5),
            ]),
          ],
          'USD': [
            ForEach('estate.surface.value').minimum(733).do([
              Add(0.75),
            ]),
          ],
          'GBP': [
            ForEach('estate.surface.value').minimum(700).do([
              Add(0.5),
            ]),
          ],
        }),
      ],
    }),
  ],
  Delivery: [
    Switch('estate.delivery').is({
      'relaxed': RelativeAdd(-0.1).from('Base'),
      'standard': RelativeAdd(0).from('Base'),
      'express': RelativeAdd(0.5).from('Base'),
    })
  ],
};


export default description;
