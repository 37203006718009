import { Attachment, Id, User } from '~/types';

export type CurrencyType = 'EUR' | 'USD' | 'GBP' | 'credits';

export interface CorrectionRoundAttachment extends Attachment {
  final: boolean;
  preview: boolean;
  draftId: Id;
  correctionRoundDate?: string;
}

export enum PaymentDocumentStatus {
  SIGNED = 'signed', // sales order agreed to, show document url
  PAID = 'paid',
  NOT_DUE = 'not_due', // not due yet, can be hidden
  LATE = 'late',
  SENT = 'sent', // something to be paid, it’s just not late… yet
}

export interface PaymentDocument {
  id: string;
  reference: string;
  clientReference?: string;
  sentAt: string;
  amount: number;
  priceWithTaxes: number;
  paidTaxes: number;
  paymentDeadline: string;
  vatRate: number;
  downPaymentPercentage: number;
  currency: CurrencyType;
  url: string;
  status: PaymentDocumentStatus;
  debitTransaction: {
    accessToken: string;
    performedAt: string;
  };
}

export interface PaymentProcess {
  id: string;
  salesOrder: PaymentDocument;
  invoices: Array<PaymentDocument>;
  type: 'sales_order' | 'balance';
}

export enum ItemStatus {
  FINISHED = 'finished',
  ARCHIVED = 'archived',
  ON_HOLD = 'on_hold',
  CANCELLED = 'cancelled',
  ONGOING = 'ongoing',
  PROBLEM = 'problem',
  AWAITING = 'awaiting',
  AWAITING_PAYMENT = 'awaiting_payment',
  AWAITING_BRIEFING = 'awaiting_briefing',
  BRIEFING_ERROR = 'briefing_error',
  REVIEWING_BRIEFING = 'reviewing_briefing',
}

export enum MilestoneName {
  ORDERED = 'ordered',
  CONFIRMED = 'confirmed',
  BRIEFING_COMPLETED = 'briefing_completed',
  ACCEPTED = 'accepted',
  PROBLEM_DECLARED = 'problem_declared',
  PROBLEM_SOLVED = 'problem_solved',
  PREVIEW_READY = 'preview_ready',
  PREVIEW_VALIDATED = 'preview_validated',
  PREVIEW_REJECTED = 'preview_rejected',
  FINISHED = 'finished',
  REOPENED = 'reopened',
  HALTED = 'halted',
  UNHALTED = 'unhalted',
  ARCHIVED = 'archived',
  ESTATE_PROBLEM_DECLARED = 'estate_problem_declared',
  ESTATE_PROBLEM_SOLVED = 'estate_problem_solved',
  CANCELLED = 'cancelled',
}

export interface Milestone {
  date: string;
  id: string;
  inducedState?: ItemStatus;
  name: MilestoneName;
}

export interface Item {
  id: string;
  name: string;
  status: ItemStatus;
  reference: string;
  milestones: Array<Milestone>;
  product: Product;
  service: Service;
  estimatedDelivery: string;
  briefingConfirmedAt?: string;
  attachments: Array<CorrectionRoundAttachment>;
  publicIdentifier?: string;
  problems: Array<ItemProblem>;
  estate: Estate;
  reopeningPrice: number;
  url?: string;
}

export enum ItemProblemReason {
  MISSING_FILE_FLOORPLAN = 'missing_file_floorplan',
  MISSING_SECTION_ELEVATION = 'missing_section_elevation',
  UNCLEAR_ATTACHMENT = 'unclear_attachment',
  INCORRECT_SURFACE = 'incorrect_surface',
  MISSING_LOGO = 'missing_logo',
  MISSING_FILE_1ST_FLOOR = 'missing_file_1st_floor',
  MISSING_DIMENSIONS = 'missing_dimensions',
  FILE_QUALITY = 'file_quality',
  MISSING_FILE_PHOTO = 'missing_file_photo',
  MISSING_FILE_0TH_FLOOR = 'missing_file_0th_floor',
  INTERIOR3D_FILE_QUALITY = 'interior3_d_file_quality',
  ELEVATION_FLOORPLAN_MISMATCH = 'elevation_floorplan_mismatch',
  UNREADABLE_FILE = 'unreadable_file',
  MISSING_INFORMATIONS = 'missing_informations',
  MISSING_FILE_2ND_FLOOR = 'missing_file_2nd_floor',
  RESTYLING_FLOORPLAN_MISSING = 'restyling_floorplan_missing',
  MISSING_ELEVATION_DRAWINGS = 'missing_elevation_drawings',
  MISSING_CAMERA_ANGLE = 'missing_camera_angle',
  MISSING_FLOORPLANS = 'missing_floorplans',
  MISSING_SECTION_DRAWING = 'missing_section_drawing',
  MISSING_FILE_BASEMENT = 'missing_file_basement',
  MISSING_SITE_PLAN = 'missing_site_plan',
  MISSING_MATERIAL_INFORMATION = 'missing_material_information',
  MISSING_LANDSCAPE_PLAN = 'missing_landscape_plan',
  INTERIOR3D_NUMBER = 'interior3_d_number',
  MISSING_COLORS_TEXTURES = 'missing_colors_textures',
  OUTDOOR_MISSING = 'outdoor_missing',
  NOMENCLATURE_MISMATCH = 'nomenclature_mismatch',
  PLAN3D_FILE_QUALITY = 'plan3_d_file_quality',
}

export enum EstateHolderReason {
  KICKOFF_NOT_DONE = 'kickoff_not_done',
  NOT_ENOUGH_CREDITS = 'not_enough_credits',
}

export enum EstateProblemReason {
  MISSING_FILE_FLOORPLAN = 'missing_file_floorplan',
  MISSING_FLOORPLANS = 'missing_floorplans',
  MISSING_SECTION_ELEVATION = 'missing_section_elevation',
  MISSING_MATERIAL_INFORMATION = 'missing_material_information',
  MISSING_LANDSCAPE_PLAN = 'missing_landscape_plan',
  MISSING_SITE_PLAN = 'missing_site_plan',
  MISSING_ELEVATION_DRAWINGS = 'missing_elevation_drawings',
  MISSING_SECTION_DRAWING = 'missing_section_drawing',
  NOMENCLATURE_MISMATCH = 'nomenclature_mismatch',
  ELEVATION_FLOORPLAN_MISMATCH = 'elevation_floorplan_mismatch',
}

interface Problem {
  id: string;
  description?: string;
}

export interface EstateProblem extends Problem {
  reason: EstateProblemReason;
  description?: string;
  attachments: Array<{ id: string; filename: string; url: string }>;
}

export interface ItemProblem extends Problem {
  reason: ItemProblemReason;
  description?: string;
  attachments: Array<{ id: string; filename: string; url: string }>;
  correction: boolean;
  annotationTargetId?: Id;
  previewTargetId?: Id;
}

export enum PropertyType {
  NEW_DEVELOPMENT = 'new_development',
  RENOVATION = 'renovation',
  EXISTING_PROPERTY = 'existing_property',
}

export interface Estate {
  id: string;
  name: string;
  items: Array<Item>;
  problems: Array<EstateProblem>;
  holder?: {
    reasons: Array<EstateHolderReason>;
  };
  propertyType: PropertyType;
  briefingConfirmedAt: string;
  paymentProcess?: PaymentProcess;
  followers: Array<User>;
  organisation: {
    id: string;
    members: Array<User>;
  };
}

export enum ProductEnum {
  RESTYLING_STANDARD = 'restyling_standard',
  PHOTO_EDITING_STANDARD = 'photo_editing_standard',
  PLAN_2D_STANDARD = 'plan_2d_standard',
  COMMERCIAL_FLOORPLAN_STANDARD = 'commercial_floorplan_standard',
  EXTERIOR_3D_STANDARD = 'exterior_3d_standard',
  INTERIOR_3D_STANDARD = 'interior_3d_standard',
  MODEL_360_STANDARD = 'model_360_standard',
  PANORAMA_360_STANDARD = 'panorama_360_standard',
  REVO_STANDARD = 'revo_standard',
  SITE_PLAN_STANDARD = 'site_plan_standard',
  SHOEBOX_STANDARD = 'shoebox_standard',
  TOUR_3D_STANDARD = 'tour_3d_standard',
  EXTERIOR_TOUR_3D_STANDARD = 'exterior_tour_3d_standard',
  VIDEO_ANIMATION_STANDARD = 'video_animation_standard',
  DRONE_SHOOTING_STANDARD = 'drone_shooting_standard',
  VIDEO_SHOOTING_STANDARD = 'video_shooting_standard',
  BILLBOARD_STANDARD = 'billboard_standard',
  BRAND_ID_STANDARD = 'brand_id_standard',
  BROCHURE_STANDARD = 'brochure_standard',
  FLYER_STANDARD = 'flyer_standard',
  LANDING_PAGE_STANDARD = 'landing_page_standard',
  MEDIA_KIT_STANDARD = 'media_kit_standard',
  NEWSLETTER_STANDARD = 'newsletter_standard',
  SOCIAL_MEDIA_KIT_STANDARD = 'social_media_kit_standard',
  WEBSITE_STANDARD = 'website_standard',
  EXTERIOR_RESTYLING_STANDARD = 'exterior_restyling_standard',
  COMMERCIAL_FLOORPLAN_PREMIUM = 'commercial_floorplan_premium',
  MODEL_360_PREMIUM = 'model_360_premium',
  PANORAMA_360_PREMIUM = 'panorama_360_premium',
  REVO_PREMIUM = 'revo_premium',
  VIDEO_ANIMATION_PREMIUM = 'video_animation_premium',
  PHOTO_SHOOTING_STANDARD = 'photo_shooting_standard',
  BRAND_ID_PREMIUM = 'brand_id_premium',
  NEWSLETTER_PREMIUM = 'newsletter_premium',
  VR_HEADSET_STANDARD = 'vr_headset_standard',
  WEBSITE_PREMIUM = 'website_premium',
}

export interface Product {
  custom: boolean;
  name: string;
  slug: ProductEnum | string; // If string, it's custom product
}

export enum ServiceEnum {
  EXTERIOR_3D = 'exterior_3d',
  INTERIOR_3D = 'interior_3d',
  SHOEBOX = 'shoebox',
  COMMERCIAL_FLOORPLAN = 'commercial_floorplan',
  SITE_PLAN = 'site_plan',
  PANORAMA_360 = 'panorama_360',
  MODEL_360 = 'model_360',
  VIDEO_ANIMATION = 'video_animation',
  DRONE_SHOOTING = 'drone_shooting',
  VIDEO_SHOOTING = 'video_shooting',
  EXTERIOR_TOUR_3D = 'exterior_tour_3d',
  REVO = 'revo',
  TOUR_3D = 'tour_3d',
  BRAND_ID = 'brand_id',
  BROCHURE = 'brochure',
  BILLBOARD = 'billboard',
  WEBSITE = 'website',
  LANDING_PAGE = 'landing_page',
  VR_HEADSET = 'vr_headset',
  NEWSLETTER = 'newsletter',
  FLYER = 'flyer',
  MEDIA_KIT = 'media_kit',
  SOCIAL_MEDIA_KIT = 'social_media_kit',
  EXTERIOR_RESTYLING = 'exterior_restyling',
  PHOTO_EDITING = 'photo_editing',
  PLAN_2D = 'plan_2d',
  RESTYLING = 'restyling',
  PHOTO_SHOOTING = 'photo_shooting',
  PRESENTATION = 'presentation',
}

export interface Service {
  slug: ServiceEnum;
}

// Only used in Dashboard
export interface Project {
  id: string;
  name: string;
  items: Array<Pick<Item, 'id'>>;
  actionsCount: number;
  hasProblem: boolean;
}

export type ProjectStatus = 'ACTIVE' | 'COMPLETED';
