import { combineEpics } from 'redux-observable';

import projectItemEpic from './project-item';
import projectsEpic from './projects';
import followerEpic from './follower';
import projectEpic from './project';


export default combineEpics(
  projectItemEpic,
  projectsEpic,
  followerEpic,
  projectEpic,
);
