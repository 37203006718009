import sv from '@drawbotics/drylus-style-vars';
import Chance from 'chance';

export function generateColorFromString(name: string): string {
  const colors = [
    sv.red,
    sv.green,
    sv.orange,
    sv.blue,
    sv.pink,
    sv.brand,
    sv.purple,
    sv.yellow,
    sv.greenLight,
    sv.orangeLight,
    sv.yellowLight,
    sv.pinkLight,
    sv.blueLight,
    sv.redLight,
    sv.purpleLight,
  ];
  const generator = new Chance(name);
  const index = generator.integer({ min: 0, max: colors.length - 1 });
  return colors[index];
}
