import { graphql } from 'react-apollo-redux';
import compose from 'lodash/flowRight';
import { connect } from 'react-redux';

import Component from './Component';
import {
  PaymentProcessQuery,
  UpdateMetaMutation,
  CheckVatMutation,
  UpdateAddressMutation,
} from './graphql';


const mapStateToProps = (state, ownProps) => ({
  paymentProcessId: ownProps.match.params.paymentProcessId,
});

export default compose(
  connect(mapStateToProps, undefined),
  graphql(PaymentProcessQuery, {
    name: 'paymentProcessData',
    options: (ownProps) => ({ variables: { paymentProcessId: ownProps.paymentProcessId }}),
  }),
  graphql(UpdateMetaMutation, { name: 'updateMeta' }),
  graphql(CheckVatMutation, { name: 'checkVat' }),
  graphql(UpdateAddressMutation, { name: 'updateAddress' }),
)(Component);
