import React from 'react';
import compose from 'lodash/flowRight';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Route, Switch, Prompt } from '@drawbotics/route';

import {
  hideFooter,
  showFooter,
  useFullWidthNavbar,
  useNavbarWithContainer,
  triggerNotFound,
} from '~/actions';
import Cart from './Cart';
import Estate from './Estate';
import Recap from './Recap';
import Payment from './Payment';
import Transaction from './Transaction';
import Briefing from './Briefing';
import { translate as t } from '~/utils/translation';

import styles from '../styles/routes/order';


class Order extends React.Component {

  componentDidMount() {
    const { hideFooter, useFullWidthNavbar } = this.props;
    hideFooter();
    useFullWidthNavbar();
  }

  componentWillUnmount() {
    const { showFooter, useNavbarWithContainer } = this.props;
    showFooter();
    useNavbarWithContainer();
  }

  render() {
    const { match, triggerNotFound } = this.props;
    return (
      <div className={styles.order}>
        <Switch onNoMatch={() => triggerNotFound()}>
          <Route basePath={match.url} path="/cart" exact component={Cart} />
          <Route basePath={match.url} path="/new" exact component={Estate} />
          <Route basePath={`${match.url}/new/:estateId`} path="/" component={Estate} />
          <Route basePath={`${match.url}/payment/:paymentProcessId`} path="/" component={Payment} />
          <Route basePath={`${match.url}/transaction/:transactionToken`} path="/" component={Transaction} />
          <Route basePath={`${match.url}/briefing/:estateId`} path="/" component={Briefing} />
          <Route basePath={`${match.url}/recap/:estateId`} path="/" component={Recap} />
          <Redirect exact from={match.url} to={`${match.url}/new`} />
        </Switch>
        <Prompt when={(nextLocation) => match.url.includes('order') && nextLocation.pathname.includes('cart')} message={t('pods.order.back_to_cart')} />
      </div>
    );
  }

}


export default compose(
  connect(undefined, {
    hideFooter,
    showFooter,
    useFullWidthNavbar,
    useNavbarWithContainer,
    triggerNotFound,
  }),
)(Order);
