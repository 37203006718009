import reducer from './reducer';
import epic from './epic';
import resolvers from './resolvers';


export default {
  reducer,
  epic,
  resolvers,
};
