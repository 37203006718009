import { createEntities } from 'entman';
import { startLoading, stopLoading } from 'loading-manager';

import schemas from 'schemas';


export const VALIDATE_DRAFT = 'VALIDATE_DRAFT';

export function validateDraft(draft) {
  return {
    type: VALIDATE_DRAFT,
    payload: {
      draft,
    },
  };
}


export const LOAD_DRAFT = 'LOAD_DRAFT';

export function loadDraft(id, projectId) {
  return {
    type: LOAD_DRAFT,
    payload: { id, projectId },
    meta: { loading: startLoading('draft') },
  };
}


export const LOAD_DRAFT_SUCCESS = 'LOAD_DRAFT_SUCCESS';

export function loadDraftSuccess(draft) {
  return createEntities(schemas.Draft, 'payload.draft', {
    type: LOAD_DRAFT_SUCCESS,
    payload: { draft },
    meta: { loading: stopLoading('draft') },
  });
}


export const LOAD_DRAFT_ERROR = 'LOAD_DRAFT_ERROR';

export function loadDraftError(error) {
  return {
    type: LOAD_DRAFT_ERROR,
    payload: { err: error },
    meta: { error, loading: stopLoading('draft') },
  };
}
