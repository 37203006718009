import gql from 'fraql';
import { useQuery } from 'urql';

import { Item } from '../../types';
import { ErrorType, buildError } from '../../utils';

interface ItemQueryData {
  item: Item;
}

interface ItemQueryVariables {
  itemId: string;
}

const ItemQuery = gql`
  query ItemQuery($itemId: ID!) {
    item(itemId: $itemId) {
      id
      reference
      status @computed(type: Item)
      briefingConfirmedAt
      publicIdentifier
      reopeningPrice
      url
      estate {
        id
        propertyType
        paymentProcess {
          type
          salesOrder {
            currency
          }
        }
      }
      service {
        slug
      }
      problems {
        id
        description
        reason
        correction
        annotationTargetId
        previewTargetId
        attachments {
          id
          filename
          url
        }
      }
      milestones {
        id
        date
        name
        inducedState
      }
      attachments {
        id
        filename
        url
        final
        preview
        draftId
        correctionRoundDate
      }
    }
  }
`;

export function useFetchItem(
  itemId: string,
): { isLoading: boolean; item?: Item; error?: ErrorType; refetch: VoidFunction } {
  const [res, reexecuteQuery] = useQuery<ItemQueryData, ItemQueryVariables>({
    query: ItemQuery,
    variables: { itemId },
  });

  return {
    isLoading: res.fetching,
    item: res.data?.item,
    error: buildError(res.error),
    refetch: () => reexecuteQuery({ requestPolicy: 'network-only' }),
  };
}
