import * as UIActions from '../actions/ui';


const initialState = {
  isLoading: false,
};


export default function ui(state=initialState, action) {
  switch(action.type) {
    case UIActions.SHOW_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UIActions.HIDE_LOADING: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
