const specs = {
  styleStandard: {
    operation: 'create',
    create: {
      type: 'bool',
      product: 'plan2d_standard',
      with: {
        style: 'standard',
      },
    },
  },
  styleBW: {
    operation: 'create',
    create: {
      type: 'bool',
      product: 'plan2d_standard',
      with: {
        style: 'black_and_white',
      },
    },
  },
  styleXyz: {
    operation: 'create',
    create: {
      type: 'bool',
      product: 'plan2d_standard',
      with: {
        style: 'xyz',
      },
    },
  },
  stylePremium: {
    operation: 'create',
    create: {
      type: 'bool',
      product: 'plan2d_standard',
      with: {
        style: 'premium',
      },
    },
  },
  dimensions: {
    operation: 'update',
    update: {
      target: 'all',
      type: 'mapTo',
      mapTo: [ 'dimensions' ],
    },
  },
  garden: {
    operation: 'update',
    update: {
      target: 'all',
      type: 'mapTo',
      mapTo: [ 'garden' ],
    },
  },
  surfaceArea: {
    operation: 'update',
    update: {
      target: 'all',
      type: 'mapTo',
      mapTo: [ 'surfaceArea' ],
    },
  },
  view3d: {
    operation: 'update',
    update: {
      target: 'all',
      type: 'mapTo',
      mapTo: [ 'view3d' ],
    },
  },
  logo: {
    operation: 'update',
    update: {
      target: 'all',
      type: 'mapTo',
      mapTo: [ 'logo' ],
    },
  },
  disclaimer: {
    operation: 'update',
    update: {
      target: 'all',
      type: 'mapTo',
      mapTo: [ 'disclaimer' ],
    },
  },
};


const dependencies = {
  hard: [],
  soft: [],
};


export default { specs, dependencies };
