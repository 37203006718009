import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { connect } from 'react-redux';

import { getSessionUser } from '~/selectors/session';


export function withUser() {
  return (WrappedComponent) => {
    class WithUser extends React.Component {
      static displayName = `WithUser(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

      render() {
        return (
          <WrappedComponent {...this.props} />
        );
      }
    }

    const mapStateToProps = (state) => ({
      sessionUser: getSessionUser(state),
    });

    return hoistNonReactStatics(connect(mapStateToProps)(WithUser), WrappedComponent);
  }
}
