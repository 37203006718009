import React from 'react';
import { FormGroup, TextArea, Form, ErrorLabel } from 'react-ittsu/forms';
import autobind from 'autobind-decorator';
import get from 'lodash/get';
import { css, cx } from 'emotion';
import sv from '@drawbotics/style-vars';

import { createTranslate, translate as t } from '~/utils/translation';


const tt = createTranslate('pods.order.services');


const styles = {
  input: css`
    width: 500px;

    @media ${sv.phoneXl} {
      max-width: 500px;
      width: 100%;
    }
  `,
  withError: css`
    .TextArea {
      border-color: ${sv.brandRed};
    }
  `,
};


class Input extends React.Component {
  render() {
    const {
      label,
      resultKey,
      answers,
      withErrors,
      required,
    } = this.props;
    const comment = get(answers, resultKey, '') || '';
    const showError = withErrors && required && comment == '';

    return (
      <div
        className={cx(styles.input, { [styles.withError]: showError })}>
        <Form name="input">
          <FormGroup>
            <TextArea
              name="textarea"
              value={comment}
              placeholder={label && tt(label, null, label)}
              onChange={this._handleOnChange} />
          </FormGroup>
          {do {
            if (showError) {
              <ErrorLabel
                text={t('pods.order.components.briefing_details.field_required')} />
            }
          }}
        </Form>
      </div>
    );
  }

  @autobind
  _handleOnChange(val) {
    const { modifyAnswer, resultKey } = this.props;
    if (val === '') {
      modifyAnswer(resultKey, null, true);
    }
    else {
      modifyAnswer(resultKey, val);
    }
  }
}


export default Input;
