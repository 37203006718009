import React from 'react';
import { Button } from 'react-ittsu/components';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';


const styles = {
  nextButton: css`
    padding-left: calc(${sv.basePadding} * 2);
    padding-right: calc(${sv.basePadding} * 2);

    > i {
      font-size: 1.1em;
      margin-left: 5px;
    }
  `,
}


const NextButton = ({ children, onClick, disabled }) => {
  return (
    <Button className={styles.nextButton} category="success" onClick={onClick} round disabled={disabled}>
      {children}
      <i className="ion-android-arrow-forward" />
    </Button>
  );
}


export default NextButton;
