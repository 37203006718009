import get from 'lodash/get';


export function getErrors(state) {
  return get(state, 'ui.errors', null);
}


export function criticalErrorHappened(state) {
  return get(state, 'ui.errors.criticalError', false);
}
