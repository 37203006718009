import React from 'react';
import { Wizard, Steps, Step as AlbusStep } from 'react-albus';
import { css } from 'emotion';
import { Panel } from 'react-ittsu/components';
import sv from '@drawbotics/style-vars';
import { connect } from 'react-redux';
import { compose } from 'react-apollo-redux';

import TimeLine from './TimeLine';
import Spinner from '~/components/Spinner';


const styles = {
  orderWizard: css`
    width: 100%;
    display: flex;
    flex-direction: column;
  `,
  panel: css`
    min-height: calc(100% - ${sv.baseMargin} * 2);
    height: calc(100% - ${sv.baseMargin} * 2);
    margin-bottom: ${sv.baseMargin};
    margin-top: ${sv.baseMargin};
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.1);
    position: relative;
    background: ${sv.white};
    display: flex;
    flex-direction: column;

    @media ${sv.ipadLandscape} {
      padding: ${sv.basePaddingSmall};
    }

    @media ${sv.ipad} {
      padding: ${sv.basePaddingSmall} 0;
      padding-top: ${sv.basePadding};
      box-shadow: none;
      margin-top: 0;
      min-height: calc(100% - ${sv.baseMargin} * 1.2);
      height: calc(100% - ${sv.baseMargin} * 1.2);
    }

    @media print {
      box-shadow: none;
      padding: 0;
      margin: 0;
    }
  `,
  timelineContainer: css`
    flex-shrink: 0;

    @media print {
      display: none;
    }
  `,
  stepsContainer: css`
    flex: 1;
    height: 0;
  `,
  loader: css`
    position: absolute;
    top: ${sv.baseMargin};
    right: ${sv.baseMargin};
  `,
};


export const Step = ({
  component: Component,
  withProps,
  checked,
  ...rest
}) => {
  return (
    <AlbusStep {...rest} checked={checked} render={({ next, previous, step, steps, push, ...rest }) => {
      const redirectTo = steps.slice().reverse().find((s) => s.checked) || steps[0];
      if (! step.checked && step.id != redirectTo.id) {
        setTimeout(() => push(redirectTo.id));
        return null;
      }
      return (
        <Component
          {...rest}
          push={push}
          step={step}
          steps={steps}
          checked={checked}
          {...withProps || {}}
          goNext={next}
          goBack={previous} />
      );
    }} />
  );
};


function handleGoNext(onGoNext) {
  return async ({ push, step, steps }) => {
    const currentStepIndex = steps.indexOf(step);
    if (currentStepIndex >= 0 && currentStepIndex <= steps.length) {
      onGoNext ? await onGoNext(step, steps[currentStepIndex + 1]) : null;
      push();
    }
  };
}


function differentSteps(steps, prevSteps) {
  if (steps.length !== prevSteps.length) {
    return true;
  }
  return steps.reduce((memo, step, i) => {
    const prevStep = prevSteps[i];
    const sameId = step.id === prevStep.id;
    const sameTitle = step.title === prevStep.title;
    const sameChecked = step.checked === prevStep.checked;
    return memo || ! (sameId && sameTitle && sameChecked);
  }, false);
}


class OrderWizard extends React.Component {

  state = {
    stepsKey: 0,
  };

  componentDidUpdate(prevProps) {
    const { children } = this.props;
    const { children: prevChildren } = prevProps;
    const steps = React.Children.map(children, (c) => c?.props);
    const prevSteps = React.Children.map(prevChildren, (c) => c?.props);
    if (differentSteps(steps, prevSteps)) {
      this.setState({ stepsKey: this.state.stepsKey + 1 });
    }
  }

  render() {
    const { children: rawChildren, onGoNext, showLoading, ...rest } = this.props;
    const { stepsKey } = this.state;
    const children = rawChildren.filter((c) => !! c);
    return (
      <Wizard {...rest}
        onNext={handleGoNext(onGoNext)}
        render={({ step, steps, push }) => {
          return (
            <div className={styles.orderWizard}>
              <Panel className={styles.panel}>
                {do{
                  if (showLoading) {
                    <div className={styles.loader}>
                      <Spinner size={20} />
                    </div>
                  }
                }}
                <div className={styles.timelineContainer}>
                  <TimeLine steps={steps} activeStep={step} onClickStep={(step) => step.checked ? push(step.id) : null} />
                </div>
                <div className={styles.stepsContainer}>
                  <Steps key={stepsKey} isActive={! showLoading}>
                    {children}
                  </Steps>
                </div>
              </Panel>
            </div>
          );
      }} />
    );
  }

}


const mapStateToProps = (state, ownProps) => ({
  showLoading: state.order.ui.isLoading,
});


export default compose(
  connect(mapStateToProps, null),
)(OrderWizard);
