import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@tokamakjs/react';
import immer from 'immer';

import { Invitation, Teammate, TeammateData } from '../api/domain';

export interface TeammateState {
  teammates: Array<TeammateData>;
}

@Injectable()
@StoreConfig({ name: 'teammates', resettable: true, producerFn: immer })
export class TeammatesStore extends Store<TeammateState> {
  constructor() {
    super({ teammates: [] });
  }

  public populate(teammates: Array<Teammate>) {
    this.update({ teammates: teammates.map((t) => t.toData()) });
  }

  public updateTeammate(id: string, newTeammate: Partial<Teammate>) {
    this.update((store) => {
      const teammateIndex = store.teammates.findIndex((t) => t.id === id);
      Object.assign(store.teammates[teammateIndex], newTeammate);
    });
  }

  public addInvitation(invitation: Invitation) {
    this.update((store) => {
      store.teammates.push(invitation.toData());
    });
  }
}
