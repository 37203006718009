import React from 'react';
import { css, cx } from 'emotion';
import sv from '@drawbotics/style-vars';
import { Icon } from 'react-ittsu/components';

import { createTranslate } from '~/utils/translation';


const t = createTranslate('pods.order.services');


const styles = {
  booleanField: css`
    display: flex;
    align-items: center;
    margin: ${sv.baseMarginSmall} 0;
  `,
  title: css`
    font-family: ${sv.baseFontFamilyAlt};
    text-transform: uppercase;
    color: ${sv.textPrimaryDark};
    font-weight: 600;
    font-size: 0.65rem;
    flex: 1;
  `,
  row: css`
    display: flex;
    align-items: center;
    margin-left: ${sv.baseMargin};
    flex: 1;
  `,
  check: css`
    height: 20px;
    width: 20px;
    border-radius: 1000px;
    background: ${sv.brandGreen};
    color: ${sv.white};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${sv.baseMarginSmall};
    font-size: 0.6rem;
  `,
  negative: css`
    background: ${sv.brandRed};
  `,
  label: css`
    font-size: 0.9rem;
    color: ${sv.textPrimaryDark};
  `,
};


const BooleanField = ({ values, title }) => {
  return (
    <div className={styles.booleanField}>
      <div className={styles.title}>
        {t(title)}
      </div>
      {values.map((value, i) => (
        <div className={styles.row} key={i}>
          <div className={cx(styles.check, { [styles.negative]: value.value === false })}>
            <Icon name={value.value === false ? 'close-bold' : 'check-bold'} vOffset={0} />
          </div>
          <div className={styles.label}>
            {t(value.label, null, value.label)}
          </div>
        </div>
      ))}
    </div>
  );
};


export default BooleanField;
