export default {
  dependencies: {
    id: `
      fragment _ on Product {
        slug
        custom
      }
    `,
  },
  resolvers: {
    id: (product) => product.id,
  },
};
