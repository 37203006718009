import React from 'react';
import { css, cx } from 'emotion';
import sv from '@drawbotics/style-vars';
import { Icon } from 'react-ittsu/components';


export const styles = {
  paymentMethodCard: css`
    position: relative;

    &:hover {
      cursor: pointer;

      & [data-element="imageContainer"] {
        border-color: ${sv.grey500};
      }
    }
  `,
  checked: css`
    & [data-element="imageContainer"] {
      border-color: ${sv.brandGreen} !important;
      box-shadow: 0px 0px 0px 2px ${sv.brandGreen};
    }
    & [data-element="checkmark"] {
      opacity: 1;
      transform: scale(1);
    }
  `,
  imageContainer: css`
    height: 120px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: ${sv.baseBorderRadius};
    background: ${sv.grey50};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${sv.basePadding};
    transition: all ${sv.baseTransitionTime} ease-in-out;
  `,
  title: css`
    font-size: 0.93em;
    text-align: center;
    margin-top: ${sv.baseMarginSmall};
    color: ${sv.textSecondaryDark};
    font-weight: bold;
  `,
  checkmark: css`
    width: 20px;
    height: 20px;
    border-radius: 10000px;
    font-size: 0.7rem;
    background: ${sv.brandGreen};
    color: ${sv.white};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -10px;
    right: -10px;
    opacity: 0;
    transform: scale(0);
    transition: all ${sv.baseTransitionTimeShort} ease-in-out;
  `,
};


const PaymentMethodCard = ({
  image,
  title,
  checked,
  onClick,
}) => {
  return (
    <div className={cx(styles.paymentMethodCard, { [styles.checked]: checked })} onClick={onClick}>
      <div className={styles.checkmark} data-element="checkmark">
        <Icon name="check-bold" />
      </div>
      <div className={styles.imageContainer} data-element="imageContainer">
        <img src={image} className={styles.image} />
      </div>
      <div className={styles.title}>
        {title}
      </div>
    </div>
  );
};


export default PaymentMethodCard;
