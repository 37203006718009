import { Query, StateHistoryPlugin } from '@datorama/akita';
import { Injectable, onModuleInit } from '@tokamakjs/react';
import { map } from 'rxjs/operators';

import { SettingsApi } from '../api';
import { Invitation, Member, Teammate, isInvitation } from '../api/domain';
import { TeammateState, TeammatesStore } from '../stores';

@Injectable()
export class TeammatesService extends Query<TeammateState> {
  private readonly _isLoading$ = this.selectLoading();
  private readonly _teammates$ = this.select('teammates');
  private readonly _stateHistory: StateHistoryPlugin;

  get isLoading$() {
    return this._isLoading$;
  }

  get teammates$() {
    return this._teammates$.pipe(
      map((w) => w.map((w) => (isInvitation(w) ? Invitation.fromData(w) : Member.fromData(w)))),
    );
  }

  constructor(private readonly _store: TeammatesStore, private readonly _api: SettingsApi) {
    super(_store);
    this._stateHistory = new StateHistoryPlugin(this);
  }

  @onModuleInit()
  protected onModuleInit(): void {
    this._store.setLoading(true);
  }

  public async loadTeammates(): Promise<void> {
    this._store.setLoading(true);
    const res = await this._api.fetchTeammates();

    if (res.error == null) {
      const teammates = res.data.teammates;
      this._store.populate(teammates);
    }

    this._store.setLoading(false);
  }

  public async updateTeammate(
    id: string,
    teammate: Partial<Teammate>,
  ): Promise<Partial<Teammate> | undefined> {
    const rawTeammate = this._store.getValue().teammates.find((t) => t.id === id);

    this._store.updateTeammate(id, teammate);

    const res = await this._api.updateTeammate(
      id,
      teammate,
      rawTeammate != null && isInvitation(rawTeammate),
    );

    if (res.error != null) {
      this._stateHistory.undo();
    } else {
      return res.data.teammate;
    }
  }

  public async createInvitation(invitation: Partial<Invitation>) {
    const res = await this._api.createInvitation(invitation);

    if (res.error == null) {
      const invitation = res.data.invitation;
      this._store.addInvitation(invitation);
      return invitation;
    }
  }

  public async resendInvitation(invitationId: string) {
    const res = await this._api.resendInvitation(invitationId);

    if (res.error == null) {
      return res.data.invitation;
    }
  }
}
