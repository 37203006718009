import React from 'react';
import { Form, FormGroup, Label, Input, CountBox, ErrorLabel, InputGroup, Select } from 'react-ittsu/forms';
import gql from 'fraql';
import PropTypes from 'prop-types';
import or from 'lodash/defaultTo';
import isEmpty from 'lodash/isEmpty';
import autobind from 'autobind-decorator';

import Title from '../../components/Title';
import AddressInput from '../../components/AddressInput';
import MainPanel from '../../components/MainPanel';
import ErrorMessage from '../../components/ErrorMessage';
import * as utils from './utils';
import { createTranslate } from '~/utils/translation';
import styles from './styles';


const tt = createTranslate('pods.order.routes.estate_details');


function _isBlank(value) {
  return value == null || value === '' || (typeof value === 'object' && isEmpty(value));
}


function _isLte(value, threshold) {
  return value != null && value <= threshold;
}


function _isInvalidNumber(value) {
  return isNaN(value) || String(value).includes('.') || String(value).includes(',');
}


const CustomSelect = (props) => {
  return (
    <Select {...props} />
  )
};

CustomSelect.displayName = 'Select';


class EstateDetails extends React.Component {

  static propTypes = {
    estate: PropTypes.object.isRequired,
    updateEstate: PropTypes.func.isRequired,
  };

  state = {
    errors: {
      fields: {},
      hasErrors: false,
    },
    values: {},
  };

  constructor(props) {
    super(props);
    const { estate } = props;
    this.state.values = {
      name: or(estate.name, ''),
      unitCount: or(estate.unitCount, 1),
      address: estate.address
        ? {
          ...estate.address,
          location: {
            lat: estate.address.lat,
            lng: estate.address.lng,
          },
          address: estate.address.label
        }
        : {},
      surface: or(estate.surface?.value, ''),
      surfaceUnit: or(estate.surface?.unit, 'sqm'),
      structureCount: Math.max(estate.structureCount, 2),
      apartmentCount: or(estate.apartmentCount, 0),
      houseCount: or(estate.houseCount, 0),
    };
  }

  render() {
    const { goBack, estate } = this.props;
    const { values, errors } = this.state;
    const hasErrors = Object.values(errors.fields).some((v) => v != null);
    
    return (
      <MainPanel
        onClickBack={goBack}
        onClickNext={this._updateDetails}>
        <Title>{tt('your_project')}</Title>
        {do {
          if (hasErrors) {
            <ErrorMessage>
              {tt('errors.correct_below')}
            </ErrorMessage>
          }
        }}
        <div className={styles.formContainer}>
          <Form>
            <FormGroup>
              <Label>{tt('name')}</Label>
              <Input
                disabled={estate.projectId != null}
                name="name"
                placeholder={tt('name_placeholder')}
                value={values.name}
                onChange={this._handleOnChangeField} />
              <ErrorLabel text={errors.fields?.name} />
            </FormGroup>
            {do {
              if (utils.shouldAskAddress(estate)) {
                <FormGroup>
                  <Label>{tt('address')}</Label>
                  <AddressInput
                    name="address"
                    placeholder={tt('address_placeholder')}
                    value={values.address.address || ''}
                    onChange={this._handleOnChangeField} />
                  <ErrorLabel text={errors.fields?.address} />
                </FormGroup>
              }
            }}
            {do {
              if (utils.shouldAskArea(estate)) {
                <FormGroup>
                  <Label>{utils.shouldAskArea(estate) ? tt('area') : tt('commercial_area')}</Label>
                  <InputGroup input={
                    <Input
                      name="surface"
                      placeholder={tt('surface_placeholder')}
                      value={values.surface}
                      onChange={this._handleOnChangeField} />
                  }
                  addon={
                    <CustomSelect
                      value={values.surfaceUnit}
                      name="surfaceUnit"
                      onChange={this._handleOnChangeField}
                      values={[{ key: 'sqm', label: tt('sqmt') }, { key: 'sqft', label: tt('sqft') }]} />
                  } />
                  <ErrorLabel text={errors.fields?.surface} />
                </FormGroup>
              }
            }}
            {do {
              if (utils.shouldAskNumberOfUnits(estate)) {
                <FormGroup>
                  <Label>
                    {utils.shouldAskNumberOfUnits(estate) ? tt('number_of_units') : tt('number_of_res_units')}
                  </Label>
                  <CountBox
                    name="unitCount"
                    value={values.unitCount}
                    onChange={this._handleOnChangeField} />
                  <ErrorLabel text={errors.fields?.unitCount} />
                </FormGroup>
              }
            }}
            {do {
              if (utils.shouldAskNumberOfStructures(estate)) {
                <FormGroup>
                  <Label>{tt('number_of_structures')}</Label>
                  <CountBox
                    name="structureCount"
                    min={2}
                    value={values.structureCount}
                    onChange={this._handleOnChangeField} />
                  <ErrorLabel text={errors.fields?.structureCount} />
                </FormGroup>
              }
            }}
            {do {
              if (utils.shouldAskNumberOfApartments(estate)) {
                <FormGroup>
                  <Label>{tt('number_of_apartments')}</Label>
                  <CountBox
                    name="apartmentCount"
                    value={values.apartmentCount}
                    onChange={this._handleOnChangeField} />
                  <ErrorLabel text={errors.fields?.apartmentCount} />
                </FormGroup>
              }
            }}
            {do {
              if (utils.shouldAskNumberOfHouses(estate)) {
                <FormGroup>
                  <Label>{tt('number_of_houses')}</Label>
                  <CountBox
                    name="houseCount"
                    value={values.houseCount}
                    onChange={this._handleOnChangeField} />
                  <ErrorLabel text={errors.fields?.houseCount} />
                </FormGroup>
              }
            }}
          </Form>
        </div>
      </MainPanel>
    );
  }

  @autobind
  _handleOnChangeField(value, name) {
    const { values, errors } = this.state;
    this.setState({
      values: { ...values, [name]: value },
      errors: { ...errors, fields: { ...errors.fields, [name]: null } },
    });
  }

  @autobind
  async _updateDetails() {
    const { updateEstate, goNext } = this.props;
    const { values } = this.state;
    const { address } = values;
    const errors = this._validate();
    if ( ! errors.hasErrors) {
      await updateEstate({
        ...values,
        surface: {
          value: Number(values.surface),
          unit: values.surfaceUnit || 'sqm',
        },
        address: ! isEmpty(address) ? {
          label: address.address,
          latitude: String(address.location?.lat),
          longitude: String(address.location?.lng),
          street: address.street,
          zipCode: address.zipCode,
          city: address.city,
          countryCode: address.countryCode,
        } : null,
      });
      goNext();
    }
    else {
      this.setState({ errors });
    }
  }

  _validate() {
    const { estate } = this.props;
    const { values } = this.state;
    const errors = { fields: {}, hasErrors: false };
    if (_isBlank(values.name)) {
      errors.fields.name = tt('errors.field_required');
      errors.hasErrors = true;
    }
    const shouldAskAddress = utils.shouldAskAddress(estate);
    if (shouldAskAddress && _isBlank(values.address)) {
      errors.fields.address = tt('errors.field_required');
      errors.hasErrors = true;
    }
    const shouldAskArea = utils.shouldAskArea(estate);
    if (shouldAskArea && _isBlank(values.surface)) {
      errors.fields.surface = tt('errors.field_required');
      errors.hasErrors = true;
    }
    else if (shouldAskArea && values.surface >= 1000000) {
      errors.fields.surface = tt('errors.area_too_large');
      errors.hasErrors = true;
    }
    else if (shouldAskArea && _isLte(values.surface, 0)) {
      errors.fields.surface = tt('errors.missing_area');
      errors.hasErrors = true;
    }
    else if (shouldAskArea && _isInvalidNumber(values.surface, 0)) {
      errors.fields.surface = tt('errors.invalid_number');
      errors.hasErrors = true;
    }
    const shouldAskNumberOfUnits = utils.shouldAskNumberOfUnits(estate);
    if (shouldAskNumberOfUnits && _isLte(values.unitCount, 0)) {
      errors.fields.unitCount = tt('errors.not_zero');
      errors.hasErrors = true;
    }
    if (utils.shouldAskNumberOfStructures(estate) && _isLte(values.structureCount, 0)) {
      errors.fields.structureCount = tt('errors.not_zero');
      errors.hasErrors = true;
    }
    if (utils.shouldAskNumberOfApartments(estate) && _isLte(values.apartmentCount, 0)) {
      errors.fields.apartmentCount = tt('errors.not_zero');
      errors.hasErrors = true;
    }
    return errors;
  }

}


EstateDetails.fragments = {
  Details: gql`
    fragment _ on Estate {
      id
      name
      unitCount
      address {
        id
        label
        lat: latitude
        lng: longitude
        countryCode
        city
        street
        zipCode
      }
      propertyType
      projectType
      projectSubtype
      surface {
        value
        unit
      }
      structureCount
      apartmentCount
      houseCount
    }
  `,
};


export default EstateDetails;
