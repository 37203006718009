import { startLoading, stopLoading } from 'loading-manager';
import { createEntities } from 'entman';

import schemas from 'schemas';


export const LOAD_SALES_ORDERS = 'LOAD_SALES_ORDERS';

export function loadSalesOrders(organizationId) {
  return {
    type: LOAD_SALES_ORDERS,
    payload: { organizationId },
    meta: { loading: startLoading('salesOrders') },
  };
}


export const LOAD_SALES_ORDERS_SUCCESS = 'LOAD_SALES_ORDERS_SUCCESS';

export function loadSalesOrdersSuccess(salesOrders) {
  return createEntities(schemas.SalesOrder, 'payload.salesOrders', {
    type: LOAD_SALES_ORDERS_SUCCESS,
    payload: { salesOrders },
    meta: { loading: stopLoading('salesOrders') },
  });
}


export const LOAD_SALES_ORDERS_FAIL = 'LOAD_SALES_ORDERS_FAIL';

export function loadSalesOrdersFail(error) {
  return {
    type: LOAD_SALES_ORDERS_FAIL,
    meta: { error, loading: stopLoading('salesOrders') },
  };
}


export const CONFIRM_SALES_ORDER = 'CONFIRM_SALES_ORDER';

export function confirmSalesOrder(address, cart, transaction) {
  return {
    type: CONFIRM_SALES_ORDER,
    payload: {
      address,
      cart,
      transaction,
    },
    meta: { loading: startLoading('confirmSalesOrder') },
  };
}


export const CONFIRM_SALES_ORDER_SUCCESS = 'CONFIRM_SALES_ORDER_SUCCESS';

export function confirmSalesOrderSuccess(transaction) {
  return {
    type: CONFIRM_SALES_ORDER_SUCCESS,
    payload: {
      transaction,
    },
    meta: { loading: stopLoading('confirmSalesOrder') },
  };
}


export const CONFIRM_SALES_ORDER_FAIL = 'CONFIRM_SALES_ORDER_FAIL';

export function confirmSalesOrderFail(error) {
  return {
    type: CONFIRM_SALES_ORDER_FAIL,
    meta: { error, loading: stopLoading('confirmSalesOrder') },
  };
}
