import { updateEntities } from 'entman';

import schemas from 'schemas';


export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';

export function updateOrganization(organization) {
  return {
    type: UPDATE_ORGANIZATION,
    payload: {
      organization,
    },
  };
}


export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';

export function updateOrganizationSuccess(organization) {
  return updateEntities(schemas.Organization, organization.id, 'payload.organization', {
    type: UPDATE_ORGANIZATION_SUCCESS,
    payload: {
      organization,
    },
  });
}


export const UPDATE_ORGANIZATION_FAIL =  'UPDATE_ORGANIZATION_FAIL';

export function updateOrganizationFail(error) {
  return {
    type: UPDATE_ORGANIZATION_FAIL,
    meta: { error },
  };
}
