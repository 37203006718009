import morphey, { fromKey } from 'morphey';

import { projectItemFromServer } from './project-item';


const estateTranslation = (i) => ({
  id: 'id',
  name: 'name',
  quickService: 'quick_service',
  items: fromKey('items').using(() => i.items.map(projectItemFromServer)),
});


function estateFromServer(estate) {
  return morphey(estate, estateTranslation);
}


const translationsFromServer = (i) => ({
  id: 'id',
  orderedBy: 'ordered_by',
  orderedAt: 'ordered_at',
  reference: 'reference',
  currency: 'currency.code',
  projects: fromKey('estates').using(() => i.estates.map(estateFromServer)),
});


export function orderFromServer(order) {
  return morphey(order, translationsFromServer);
}


export default {
  fromServer: orderFromServer,
};
