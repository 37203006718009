import get from 'lodash/get';


/**
 * Run a conditional operation.
 *
 * Example:
 *
 * {
 *   operation: 'condtional',
 *   conditional: {
 *     type: 'equals',
 *     key: 'someKey',
 *     equals: 'someValue',
 *     then: {
 *       operation: 'add',
 *       add: { value: 10 },
 *     },
 *   },
 * }
 */

export function doConditional(object, conditional, doOperation) {
  const { type, key, then } = conditional;
  const value = conditional[type];
  switch (type) {
    case 'equals':
      return get(object, key) === value ? doOperation(object, then) : 0;
    case 'oneOf':
      return value.includes(get(object, key)) ? doOperation(object, then) : 0;
    case 'lte':
      return get(object, key) <= value ? doOperation(object, then) : 0;
    case 'gt':
      return get(object, key) > value ? doOperation(object, then) : 0;
    case 'notNull':
      return get(object, key) != null ? doOperation(object, then) : 0;
    case 'isNull':
      return get(object, key) == null ? doOperation(object, then) : 0;
    default:
      console.warn(`Unknown type "${type}" found for operation "conditional"`);
      return 0;
  }
}


class ConditionalOperation {
  constructor(key) {
    this.key = key;
  }

  equals(value) {
    this.isEquals = true;
    this.value = value;
    return this;
  }

  oneOf(value) {
    this.isOneOf = true;
    this.value = value;
    return this;
  }

  lte(value) {
    this.isLte = true;
    this.value = value;
    return this;
  }

  gt(value) {
    this.isGt = true;
    this.value = value;
    return this;
  }

  notNull() {
    this.isNotNull = true;
    return this;
  }

  isNull() {
    this.isIsNull = true;
    return this;
  }

  then(next) {
    return {
      operation: 'conditional',
      conditional: {
        type: this._getType(),
        key: this.key,
        [this._getType()]: this.value,
        then: next,
      },
    };
  }

  _getType() {
    if (this.isEquals) {
      return 'equals';
    }
    else if (this.isOneOf) {
      return 'oneOf';
    }
    else if (this.isLte) {
      return 'lte';
    }
    else if (this.isGt) {
      return 'gt';
    }
    else if (this.isNotNull) {
      return 'notNull';
    }
    else if (this.isIsNull) {
      return 'isNull';
    }
  }
}


export function Conditional(key) {
  return new ConditionalOperation(key);
}
