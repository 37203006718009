import gql from 'graphql-tag';

export const ResendInvitation = gql`
  mutation resendInvitation($invitationId: ID!) {
    resendInvitation(input: { invitationId: $invitationId }) {
      invitation {
        id
        email
      }
    }
  }
`;
