import Rx from 'rxjs-legacy';
import { combineEpics } from 'redux-observable';


import * as PublicProjectActions from 'actions/public-project';
import * as AlertsActions from 'actions/alerts';
import { translate as t } from 'utils/translation';
import { wrapError } from 'utils/errors';


function loadPublicProject(action$, store, extra) {
  const { api } = extra;
  return action$
    .filter((action) => action.type === PublicProjectActions.LOAD_PUBLIC_PROJECT)
    .flatMap((action) => {
      const { token } = action.payload;
      return Rx.Observable.fromPromise(api.queries.getPublicItem(token))
        .map((item) => PublicProjectActions.loadPublicProjectSuccess(item))
        .catch((err) => Rx.Observable.concat(
          Rx.Observable.of(AlertsActions.showAlert(PublicProjectActions.LOAD_PUBLIC_PROJECT_FAIL, 'error', t('containers.alerts.studio_guest.errors.load_project'))),
          Rx.Observable.of(PublicProjectActions.loadPublicProjectFail(wrapError(err)))
        ));
    });
}


export default combineEpics(
  loadPublicProject,
);
