import React from 'react';
import browser from 'detect-browser';

import { createTranslate } from 'utils/translation';

import styles from 'styles/components/outdated-browser-banner';


const tt = createTranslate('components.outdated_browser_banner');


function isOldVersion(browser) {
  if ( ! browser) {
    return false;
  }
  const { name, version } = browser;
  switch (name) {
    case 'chrome': {
      const [ major ] = version.split('.');
      return parseInt(major) < 50;
    }
    case 'firefox': {
      const [ major ] = version.split('.');
      return parseInt(major) < 40;
    }
    case 'safari': {
      const [ major ] = version.split('.');
      return parseInt(major) < 10;
    }
    case 'edge': {
      return false;
    }
    default: {
      return false;
    }
  }
}


class OutdatedBrowserBanner extends React.Component {

  render() {
    const display = isOldVersion(browser);
    return (
      <div className={styles.outdatedBrowserBanner} style={{ display: display ? 'block' : 'none' }}>
        <div className={styles.title}>
          {tt('title')}
        </div>
        <div className={styles.text}>
          {tt('outdated_browser_text', { link:
            <a
              href="http://outdatedbrowser.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="Link Link--underlined">
              {tt('outdated_browser_here')}
            </a>,
          })}
        </div>
      </div>
    );
  }

}


export default OutdatedBrowserBanner;
