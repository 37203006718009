import Rx from 'rxjs-legacy';
import { combineEpics } from 'redux-observable';
import get from 'lodash/get';

import LogRocket from 'utils/logrocket';
import Sentry from 'utils/sentry';
import * as SessionActions from 'actions/session';
import * as AlertsActions from 'actions/alerts';
import * as AddressActions from '~/pods/profile/actions/addresses';
import * as ApolloCacheActions from 'actions/cache-invalidation';
import { RESET_STATE } from 'utils/reset-state';
import { getSessionUser } from 'selectors';
import { writeSession, currencyWithSymbol } from 'utils';
import { formatErrorMessage, wrapError } from 'utils/errors';
import { translate as t } from 'utils/translation';
import { removeSession } from '~/utils/bootstrap/setup-session';


function signInSession(session$, rememberMe=false) {
  return session$
    .map((session) => writeSession(session, rememberMe))
    .map((session) => SessionActions.signInSuccess(session))
}


function signIn(action$, store, extra) {
  const { api } = extra;
  return action$
    .filter((action) => action.type === SessionActions.SIGN_IN)
    .flatMap((action) => {
      const { email, password, rememberMe } = action.payload;
      return signInSession(Rx.Observable.fromPromise(api.mutations.signIn(email, password)), rememberMe)
        .catch((err) => {
          if ((err.info) && (String(err.info.status) === '401' || String(err.info.status) === '422')) {
            const message = formatErrorMessage(err.info.body.message, 'containers.alerts.login.errors');
            return Rx.Observable.concat(
              Rx.Observable.of(SessionActions.signInFail(err, { ignoreLog: true })),
              Rx.Observable.of(AlertsActions.showAlert(SessionActions.SIGN_IN_FAIL, 'error', message)),
            );
          }
          return Rx.Observable.of(SessionActions.signInFail(err));
        });
    });
}


function signUp(action$, store, extra) {
  const { api } = extra;
  return action$
    .filter((action) => action.type === SessionActions.SIGN_UP)
    .flatMap((action) => {
      const { user, organization } = action.payload;
      return Rx.Observable.fromPromise(api.mutations.signUp(user, organization))
        .flatMap((session) => signInSession(Rx.Observable.of(session)))
        .catch((err) => {
          if ((err.info) && (String(err.info.status) === '401' || String(err.info.status) === '422')) {
            const message = formatErrorMessage(err.info.body.message, 'containers.alerts.signup.errors');
            return Rx.Observable.concat(
              Rx.Observable.of(SessionActions.signUpFail(wrapError(err), { ignoreLog: true })),
              Rx.Observable.of(AlertsActions.showAlert(SessionActions.SIGN_UP_FAIL, 'error', message)),
            );
          }
          return Rx.Observable.of(SessionActions.signUpFail(wrapError(err)));
        });
    });
}


function signOut(action$) {
  return action$
    .filter((action) => action.type === SessionActions.SIGN_OUT)
    .flatMap((action) => {
      removeSession();
      return Rx.Observable.concat(
        Rx.Observable.of(SessionActions.signOutSuccess()),
        Rx.Observable.of({ type: RESET_STATE }),
        Rx.Observable.of(ApolloCacheActions.invalidateQuery('ProjectsQuery')),
      );
    });
}


function loadSessionUser(action$, store, extra) {
  const { api } = extra;
  return action$
    .filter((action) => action.type === SessionActions.LOAD_SESSION_USER)
    .flatMap((action) => {
      const sessionUser = getSessionUser(store.getState());
      if (sessionUser) {
        return Rx.Observable.of(SessionActions.loadSessionUserSuccess(sessionUser, 'from-cache'));
      }
      return Rx.Observable.fromPromise(api.queries.getSessionUser())
        .map((user) => SessionActions.loadSessionUserSuccess(user))
        .catch((err) => {
          if (err.info.body && ! action.meta.ignoreFailure) {
            const message = formatErrorMessage(err.info.body.message, 'containers.alerts.errors');
            return Rx.Observable.concat(
              Rx.Observable.of(SessionActions.signOut()),
              Rx.Observable.of(AlertsActions.showAlert(SessionActions.LOAD_SESSION_USER_FAIL, 'error', message)),
              Rx.Observable.of(SessionActions.loadSessionUserFail(wrapError(err), { ignoreLog: true })),
            );
          }
          return Rx.Observable.of(SessionActions.loadSessionUserFail(wrapError(err)));
        });
    });
}


function addLocaleToWindow(action$) {
  return Rx.Observable.merge(
    action$.filter((action) => action.type === SessionActions.SIGN_IN_SUCCESS),
    action$.filter((action) => action.type === SessionActions.SIGN_UP_SUCCESS),
    action$.filter((action) => action.type === SessionActions.CHANGE_LANGUAGE),
  )
    .flatMap((action) => {
      const { data, session } = action.payload;
      const locale = get(session, 'locale', get(data, 'locale', 'en'));
      window.i18n.changeLocale(locale);
      return Rx.Observable.empty();
    });
}


function addCurrencyToWindow(action$) {
  return Rx.Observable.merge(
    action$.filter((action) => action.type === SessionActions.LOAD_SESSION_USER_SUCCESS),
    action$.filter((action) => action.type === SessionActions.CHANGE_CURRENCY_SUCCESS),
    action$.filter((action) => action.type === SessionActions.CHANGE_CURRENCY),
  )
    .flatMap((action) => {
      const { data, user, currency } = action.payload;
      window.userCurrency = get(user, 'currency', get(data, 'currency', currency || currencyWithSymbol('EUR')));
      return Rx.Observable.empty();
    });
}


function changeCurrency(action$, store, extra) {
  const { api } = extra;
  return action$
    .filter((action) => action.type === AddressActions.UPDATE_ADDRESS_SUCCESS)
    .flatMap((action) => {
      const { address } = action.payload;
      // console.log('payload', address);
      if (address.default) {
        return Rx.Observable.fromPromise(api.queries.getSessionUser())
          .flatMap((user) => {
            return Rx.Observable.merge(
              Rx.Observable.of(SessionActions.changeCurrencySuccess(user.id, user.currency)),
              Rx.Observable.of(SessionActions.updateSession({ currency: user.currency.code })),
            );
          });
      }
      else {
        return Rx.Observable.empty();
      }
    })
    .catch((err) => Rx.Observable.of(SessionActions.changeCurrencyFail(wrapError(err))));
}


function updateLogRocketInfo(action$) {
  return action$
    .filter((action) => action.type === SessionActions.LOAD_SESSION_USER_SUCCESS)
    .flatMap((action) => {
      const { user } = action.payload;
      LogRocket.identify(user.id, {
        name: user.firstName + ' ' + user.lastName,
        email: user.email,
      });
      return Rx.Observable.empty();
    });
}


function updateSentryInfo(action$) {
  return action$
    .filter((action) => action.type === SessionActions.LOAD_SESSION_USER_SUCCESS)
    .flatMap((action) => {
      const { user } = action.payload;
      Sentry.setUserContext({
        email: user.email,
        id: user.id,
        name: user.firstName + ' ' + user.lastName,
      });
      return Rx.Observable.empty();
    });
}


function updateSessionUser(action$, store, extra) {
  const { api } = extra;
  return action$
    .filter((action) => action.type === SessionActions.UPDATE_SESSION_USER)
    .flatMap((action) => {
      const { user } = action.payload;
      const { emailChanged } = action.meta;
      return Rx.Observable.fromPromise(api.mutations.updateSessionUser(user))
        .flatMap((user) => Rx.Observable.concat(
          Rx.Observable.of(SessionActions.updateSessionUserSuccess(user)),
          Rx.Observable.of(AlertsActions.showAlert(SessionActions.UPDATE_SESSION_USER_SUCCESS, 'success', emailChanged ? t('containers.alerts.profile.email_updated') : t('containers.alerts.profile.update'))),
        ))
        .catch((error) => {
          if (String(error.info.status) === '401' || String(error.info.status) === '422') {
            const message = formatErrorMessage(error.info.body.message, 'containers.alerts.profile.errors');
            return Rx.Observable.concat(
              Rx.Observable.of(AlertsActions.showAlert(SessionActions.UPDATE_SESSION_USER_FAIL, 'error', message)),
              Rx.Observable.of(SessionActions.updateSessionUserFail(error, { ignoreLog: true })),
            );
          }
          return Rx.Observable.of(SessionActions.updateSessionUserFail(error));
        });
    });
}


function changeLanguage(action$, store, extra) {
  const { api } = extra;
  return action$
    .filter((action) => action.type === SessionActions.CHANGE_LANGUAGE)
    .flatMap((action) => {
      const { data, noServer } = action.payload;
      if (noServer) {
        return Rx.Observable.of(SessionActions.changeLanguageSuccess());
      }
      return Rx.Observable.fromPromise(api.mutations.changeLanguage(data.locale))
        .map((user) => SessionActions.changeLanguageSuccess(user))
        .catch((error) => Rx.Observable.concat(
          Rx.Observable.of(SessionActions.changeLanguageFail(error))
        ));
    });
}


function requestNewPassword(action$, store, extra) {
  const { api } = extra;
  return action$
    .filter((action) => action.type === SessionActions.REQUEST_NEW_PASSWORD)
    .flatMap((action) => {
      const { email } = action.payload;
      return Rx.Observable.fromPromise(api.mutations.requestNewPassword(email))
        .map((response) => {
          if (response.message === 'reset_password_link_sent') {
            return AlertsActions.showAlert(SessionActions.REQUEST_NEW_PASSWORD, 'success', response.message);
          }
          else {
            return AlertsActions.showAlert(SessionActions.REQUEST_NEW_PASSWORD, 'error', response.message);
          }
        })
        .catch((err) => Rx.Observable.of(SessionActions.requestNewPasswordFail(err)));
    });
}


function guestSignUp(action$, store, extra) {
  const { api, history } = extra;
  return action$
    .filter((action) => action.type === SessionActions.GUEST_SIGN_UP)
    .flatMap((action) => {
      const { user, item } = action.payload;
      return Rx.Observable.fromPromise(api.mutations.guestSignUp(user, item))
        .flatMap((session) => {
          if (session === 'registered') {
            return Rx.Observable.concat(
              Rx.Observable.of(history.push('/')),
              Rx.Observable.of(AlertsActions.showAlert(SessionActions.GUEST_SIGN_UP_FAIL, 'error', t('containers.alerts.studio_guest.errors.user_already_registered')))
            );
          }
          return Rx.Observable.concat(
            Rx.Observable.of(writeSession(session, false)),
            Rx.Observable.of(SessionActions.guestSignUpSuccess(session))
          );
        })
        .catch((err) => Rx.Observable.of(SessionActions.guestSignUpFail(wrapError(err))));
    });
}


function updatePassword(action$, store, extra) {
  const { api, history } = extra;
  return action$
    .filter((action) => action.type === SessionActions.UPDATE_PASSWORD)
    .flatMap((action) => {
      const { newPassword, newPasswordConfirmation, token } = action.payload;
      return Rx.Observable.fromPromise(api.mutations.updatePassword(newPassword, newPasswordConfirmation, token))
        .flatMap((response) => Rx.Observable.concat(
          Rx.Observable.of(SessionActions.updatePasswordSuccess()),
          signInSession(Rx.Observable.of(response)),
          Rx.Observable.of(history.push('/')),
          Rx.Observable.of(AlertsActions.showAlert(SessionActions.UPDATE_PASSWORD, 'success', t('containers.alerts.profile.password_updated'))),
        ))
        .catch((err) => {
          if (String(err.info.status) === '422' || String(err.info.status) === '401') {
            const message = formatErrorMessage(err.info.body.message, 'containers.alerts.recover_password.errors');
            return Rx.Observable.concat(
              Rx.Observable.of(SessionActions.updatePasswordFail(err)),
              Rx.Observable.of(AlertsActions.showAlert(SessionActions.UPDATE_PASSWORD, 'error', message))
            );
          }
          return Rx.Observable.of(SessionActions.updatePasswordFail(err, { ignoreLog: true }));
        });
    });
}


function guestSignUpFail(action$) {
  return action$
    .filter((action) => action.type === SessionActions.GUEST_SIGN_UP_FAIL)
    .map((action) => {
      const message = action.meta.error.message;
      return AlertsActions.showAlert(SessionActions.GUEST_SIGN_UP_FAIL, 'error', message);
    });
}


export default combineEpics(
  signIn,
  signUp,
  signOut,
  loadSessionUser,
  updateSessionUser,
  changeLanguage,
  guestSignUp,
  guestSignUpFail,
  addLocaleToWindow,
  addCurrencyToWindow,
  changeCurrency,
  updateLogRocketInfo,
  updateSentryInfo,
  requestNewPassword,
  updatePassword,
);
