import gql from 'fraql';
import { useQuery } from 'urql';

import { Estate, PaymentProcess } from '../../types';
import { ErrorType, buildError } from '../../utils';

interface EstateQueryData {
  estate: Estate;
}

interface EstateQueryVariables {
  estateId: string;
}

const EstateQuery = gql`
  query EstateQuery($estateId: ID!) {
    estate(estateId: $estateId) {
      id
      paymentProcess {
        id
        salesOrder {
          id
          amount
          currency
          url
          status
          sentAt
        }
        invoices {
          id
          reference
          clientReference
          sentAt
          amount
          paymentDeadline
          priceWithTaxes
          paidTaxes
          vatRate
          currency
          url
          status
          downPaymentPercentage
          debitTransaction {
            accessToken
            performedAt
          }
        }
      }
    }
  }
`;

export function useFetchEstatePayments(
  estateId: string,
): { isLoading: boolean; paymentProcess?: PaymentProcess; error?: ErrorType } {
  const [res] = useQuery<EstateQueryData, EstateQueryVariables>({
    query: EstateQuery,
    variables: { estateId },
  });

  return {
    isLoading: res.fetching,
    paymentProcess: res.data?.estate.paymentProcess,
    error: buildError(res.error),
  };
}
