import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  Navbar,
  NavbarIcon,
  NavbarControls,
  NavbarLink,
  NavbarText,
  Panel,
  PanelHeader,
  PanelTitle,
  PanelBody,
  Button,
} from 'react-ittsu/components';
import { Main, Header, Container, Footer } from 'react-ittsu/layout';
import { Form, Label, FormGroup, Input } from 'react-ittsu/forms';
import get from 'lodash/get';
import queryString from 'query-string';

import { showAlert, updatePassword } from 'actions';
import { getSession } from 'selectors';
import RouteContainer from 'components/RouteContainer';
import FooterContent from 'containers/FooterContent';
import Link from 'components/Link';
import withAPI from 'utils/api/with-api';
import { translate as t, createTranslate } from 'utils/translation';
import { formatErrorMessage } from 'utils/errors';

import styles from 'styles/routes/recover-password';
import logo from 'images/logo.svg';


const tt = createTranslate('routes.recover_password');
const et = createTranslate('forms.errors');


class RecoverPassword extends React.Component {

  state = {
  };

  constructor(props) {
    super(props);
    this._handleFieldChange = this._handleFieldChange.bind(this);
    this._handleSubmitEmail = this._handleSubmitEmail.bind(this);
    this._handleSubmitNewPassword = this._handleSubmitNewPassword.bind(this);
  }

  componentDidMount() {
    const { push, session } = this.props;
    if (session.authToken) {
      push('/');
    }
  }

  componentDidUpdate() {
    const { push, session } = this.props;
    if (session.authToken) {
      push('/');
    }
  }

  render() {
    const { sent, emailError } = this.props;
    return (
      <div className={styles.recoverPassword}>
        <Header>
          <Navbar withContainer>
            <Link href="/">
              <NavbarIcon image={logo} />
            </Link>
            <NavbarControls>
              <NavbarText>{t('routes.dont_have_account')}</NavbarText>
              <NavbarLink><Link href="/sign-up">{t('routes.register')}</Link></NavbarLink>
            </NavbarControls>
          </Navbar>
        </Header>
        <Main>
          <RouteContainer className={styles.container}>
            <Container style={{ height: '100%' }}>
              <div className={styles.container}>
                {do {
                  if (location.pathname === '/password-recovery') {
                    <Panel className={styles.panel}>
                      <PanelHeader>
                        <PanelTitle>{tt('title')}</PanelTitle>
                      </PanelHeader>
                      <PanelBody>
                        {do {
                          if ( ! sent) {
                            <Form
                              name="recover-password"
                              onSubmit={this._handleSubmitEmail}>
                              <FormGroup error={emailError}>
                                <Label>{tt('email')}</Label>
                                <Input
                                  name="email"
                                  placeholder={tt('email')}
                                  validation={(v) => v.isEmpty(et('empty_field'))} />
                              </FormGroup>
                              <div className={styles.buttonContainer}>
                                <Link href="/login" className={styles.goBackLink}>
                                  {tt('cancel')}
                                </Link>
                                <Button category="primary" type="submit" className={styles.signInButton}>
                                  {tt('recover')}
                                </Button>
                              </div>
                            </Form>
                          }
                          else {
                            <span>{tt('link')}</span>
                          }
                        }}
                      </PanelBody>
                    </Panel>
                  }
                }}
                {do {
                  if (location.pathname.includes('/password/edit')) {
                    <Panel className={styles.panel}>
                      <PanelHeader>
                        <PanelTitle>{tt('title')}</PanelTitle>
                      </PanelHeader>
                      <PanelBody>
                        <Form
                          name="new-password"
                          onSubmit={this._handleSubmitNewPassword} >
                          <FormGroup>
                            <Label>{tt('new_password')}</Label>
                            <Input
                              name="newPassword"
                              placeholder={tt('introduce_password')}
                              type="password"
                              validation={(v) => v.isEmpty(et('empty_field'))
                                .ifCond( ! v.value || v.value.length < 9, tt('password_too_short'))} />
                          </FormGroup>
                          <FormGroup>
                            <Label>{tt('password_confirmation')}</Label>
                            <Input
                              name="newPasswordConfirmation"
                              placeholder={tt('confirm_password')}
                              type="password"
                              validation={(v) => v.isEmpty(et('empty_field'))
                                .ifCond( ! v.value || v.value.length < 9, tt('password_too_short'))}/>
                          </FormGroup>
                          <div className={styles.buttonContainer}>
                            <Link href="/login" className={styles.goBackLink}>
                              {tt('cancel')}
                            </Link>
                            <Button category="primary" type="submit" className={styles.signInButton}>
                              {tt('update')}
                            </Button>
                          </div>
                        </Form>
                      </PanelBody>
                    </Panel>
                  }
                }}
              </div>
            </Container>
          </RouteContainer>
        </Main>
        <Footer>
          <FooterContent />
        </Footer>
      </div>
    );
  }

  _handleFieldChange(value, name) {
    this.setState({ [name]: value });
  }

  _handleSubmitEmail(values) {
    const { email } = values;
    const { requestNewPassword } = this.props;
    requestNewPassword(email);
  }

  _handleSubmitNewPassword(values) {
    const { newPassword, newPasswordConfirmation } = values;
    const { updatePassword, location } = this.props;
    const query = queryString.parse(location.search);
    const { reset_password_token: resetPasswordToken } = query;
    updatePassword(newPassword, newPasswordConfirmation, resetPasswordToken);
  }

}


const mapStateToProps = (state, ownProps) => ({
  session: getSession(state),
  push: ownProps.history.push,
});


const mapDispatchToProps = {
  showAlert,
  updatePassword,
};


const apiConfig = (api, setState, { dispatch }) => ({
  requestNewPassword(email) {
    setState({ sendingEmail: true });
    api.mutations.requestNewPassword(email)
      .then((response) => setState({ sent: true }))
      .catch((error) => {
        const errorMessage = get(error, 'body.message', '');
        if (errorMessage === 'no_client_with_email') {
          setState({ emailError: errorMessage });
        }
        else {
          const message = formatErrorMessage(error.info.body.message, 'routes.recover_password.errors');
          dispatch(showAlert('SENDING_EMAIL', 'error', message));
        }
      })
      .then(() => setState({ sendingEmail: false }));
  },
});


export default compose(
  withAPI(apiConfig, { legacy: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(RecoverPassword);
