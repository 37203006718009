import history from './utils/history';
import { translate as t} from './utils/translation';
import { setupLogRocket } from './utils/logrocket';
import setupErrors from './utils/bootstrap/setup-errors';
import setupLocaleSystem from './utils/bootstrap/setup-locale-system';
import setupGlobalNavigation from './utils/bootstrap/setup-global-navigation';
import { isLocalStorageAvailable, readSession, isAuthenticatedUser } from './utils';
import { fetchFragmentTypes } from './utils/api/graphql/fetch-fragment-types';
import { getGraphqlUrl } from './utils/api/graphql/apollo-client';
import setupLokalise from './utils/bootstrap/setup-lokalise';
import setupDayjs from './utils/bootstrap/setup-dayjs';
import { setupSession } from './utils/bootstrap/setup-session';
import { DiContainer } from '@tokamakjs/injection';
import { AppModule } from './app.module';

import './styles/app';


export default async function bootstrap(next) {
  const setupStore = require('store').default;
  const { default: setupClient } = require('./utils/api/graphql/apollo-client');
  const { default: urqlClient } = require('./utils/api/graphql/urql-client');

  if ( ! isLocalStorageAvailable()) {
    return alert(t('pods.root.enable_cookies'));
  }

  // Register dayjs plugins
  setupDayjs();

  // Setup log rocket
  await setupLogRocket();

  // Setup session
  await setupSession();

  const isPublic = ! (await isAuthenticatedUser());
  const session = readSession();

  const store = setupStore();
  const fragmentTypes = await fetchFragmentTypes(getGraphqlUrl(isPublic), session?.authToken)
  const client = setupClient(store, fragmentTypes);
  const diContainer = await DiContainer.from(AppModule);

  // Setup error system
  setupErrors(store);

  // Add change locale function to window
  setupLocaleSystem(() => next(store, history, client, diContainer));

  // Setup global navigation functions inside window._utils
  setupGlobalNavigation(store);

  // Configure Lokalise
  setupLokalise();

  next(store, history, client, urqlClient, diContainer);
}
