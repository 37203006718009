import { getJwt } from '@drawbotics/auth';
import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';

import { OrganizationRole, User } from '~/types';

interface UserFromToken {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  role: 'admin' | 'member';
}

function _rawUserRoleToOrganizationRole(role: UserFromToken['role']): OrganizationRole {
  if (role === 'admin') {
    return OrganizationRole.ADMIN;
  } else {
    return OrganizationRole.MEMBER;
  }
}

export function useUser(): { user?: User } {
  const authToken = getJwt();
  const [user, setUser] = useState<User>();

  const _decodeUser = (jwt: string): User | undefined => {
    try {
      const rawUser = jwtDecode<UserFromToken>(jwt);
      return {
        id: rawUser.id,
        firstName: rawUser.first_name,
        lastName: rawUser.last_name,
        fullName: `${rawUser.first_name} ${rawUser.last_name}`,
        email: rawUser.email,
        role: _rawUserRoleToOrganizationRole(rawUser.role),
        public: false,
      };
    } catch (e) {
      console.error('Found a token but failed to decode it', e);
      return;
    }
  };

  useEffect(() => {
    if (authToken != null) {
      setUser(_decodeUser(authToken));
    }
  }, [authToken]);

  return { user };
}
