import { checkArgs } from './utils';
import descriptions from './descriptions';
import { runDescription } from './run-description';
import { addDependencies } from './add-dependencies';
import applyChanges from './apply-changes';
import { descriptionToSpecs } from './description-to-specs';
import cloneDeep from 'lodash/cloneDeep';


function _addDescriptionsToItem(item) {
  const { service } = item;
  if (service == null) {
    return item;
  }
  const description = descriptions[service.id];
  if (description == null) {
    return item;
  }
  const { dependencies } = description;
  return { ...item, service: { ...item.service, dependencies } };
}


function _addDescriptionsToEstate(estate) {
  return {
    ...estate,
    items: estate.items.map(_addDescriptionsToItem),
  };
}


function _addDescriptionsToChanges(changes) {
  return {
    add: changes.add.map(_addDescriptionsToItem),
    remove: changes.remove.map(_addDescriptionsToItem),
    update: changes.update.map(_addDescriptionsToItem),
  };
}


function _cleanUp(changes) {
  // make sure we don't update something marked for deletion
  changes.remove.forEach((item) => {
    changes.update = changes.update.filter(({ id }) => id !== item.id);
  });
  return changes;
}


export function specsToItems({ service, specs, estate }) {
  checkArgs({ service, specs, estate });
  const description = descriptions[service.id];
  if (description == null || description.specs == null) {
    return null;
  }
  estate = cloneDeep(estate);
  const changes = runDescription(description.specs, { service, specs, estate });
  return _cleanUp(addDependencies(
    _addDescriptionsToChanges(changes),
    _addDescriptionsToEstate(applyChanges(changes, estate, false)),
  ));
}


export function itemsToSpecs({ service, estate }) {
  const description = descriptions[service.id];
  if (description == null || description.specs == null) {
    return null;
  }
  return descriptionToSpecs(description.specs, estate, service);
}


export { applyChanges };
