import React from 'react';
import PropTypes from 'prop-types';
import { Route as RRRoute, matchPath } from 'react-router-dom';
import { connect } from 'react-redux';

import { buildUrl } from '~/utils';
import { triggerNotFound } from '~/actions';


const Route = (props) => {
  const { basePath, path, component, render, withProps={}, ...rest } = props;
  const finalRender = do {
    if (component) {
      // eslint-disable-next-line
      (props) => React.createElement(component, { ...props, ...withProps, basePath })
    }
    else {
      // eslint-disable-next-line
      (props) => render({ ...props, ...withProps, basePath })
    }
  }
  return (
    <RRRoute {...rest} path={buildUrl(basePath, path)} render={finalRender} />
  );
};


export default Route;


/**
 * Taken from
 * https://github.com/ReactTraining/react-router
 *  /blob/10069457db64f60a68577fd55966375af2ae5f8e/packages/react-router/modules/Switch.js
 */
@connect(undefined, { triggerNotFound })
export class Switch extends React.Component {
  static contextTypes = {
    router: PropTypes.shape({
      route: PropTypes.object.isRequired
    }).isRequired
  };

  static propTypes = {
    children: PropTypes.node,
    location: PropTypes.object
  };

  render() {
    const { route } = this.context.router;
    const { children, onNoMatch, handleNotFound, triggerNotFound } = this.props;
    const location = this.props.location || route.location;

    let match, child;
    React.Children.forEach(children, element => {
      if (match == null && React.isValidElement(element)) {
        const { path: pathProp, basePath, exact, strict, sensitive, from } = element.props;
        const path = buildUrl(basePath, pathProp || from);

        child = element;
        match = path ? matchPath(location.pathname, { path, exact, strict, sensitive }) : route.match;
      }
    });

    if (match) {
      return React.cloneElement(child, { location, computedMatch: match });
    }
    else {
      onNoMatch ? onNoMatch() : null;
      handleNotFound ? triggerNotFound() : null;
      return null;
    }
  }
}


/**
 * The public API for prompting the user before navigating away
 * from a screen with a component.
 *
 * Based on:
 * https://github.com/ReactTraining/react-router
 *  /blob/bd3265a350cff2b4cabda5b52f307f74c9eca60b/packages/react-router/modules/Prompt.js
 */
export class Prompt extends React.Component {
  static propTypes = {
    when: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.bool,
    ]).isRequired,
    message: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
    ]).isRequired,
  };

  static defaultProps = {
    when: true
  };

  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.shape({
        block: PropTypes.func.isRequired
      }).isRequired
    }).isRequired
  }

  componentDidMount() {
    const { history } = this.context.router;
    const { when, message } = this.props;
    this.unblock = history.block((location, action) => {
      const finalWhen = typeof when === 'function' ? when(location, action) : when;
      if (finalWhen) {
        return message;
      }
    });
  }

  componentDidUpdate() {
    const { history } = this.context.router;
    const { when, message } = this.props;
    this.unblock?.();
    this.unblock = history.block((location, action) => {
      const finalWhen = typeof when === 'function' ? when(location, action) : when;
      if (finalWhen) {
        return message;
      }
    });
  }

  componentWillUnmount() {
    this.unblock?.();
  }

  render() {
    return null;
  }
}
