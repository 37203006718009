import { css } from 'emotion';
import sv from '@drawbotics/style-vars';


export default {
  briefingExisting: css`
    width: 100%;
    max-width: 1200px;
    flex: 1;
    margin: auto;
    margin-top: calc(${sv.baseMargin} * 2);
    margin-bottom: ${sv.baseMargin};
    padding: 0 ${sv.basePaddingSmall};

    @media ${sv.ipad} {
      margin: 0;
      padding: ${sv.basePaddingSmall};
      padding-bottom: ${sv.basePadding};
      background: ${sv.white};
    }
  `,
  title: css`
    text-align: left;
    font-size: 1.4rem;
    margin-bottom: ${sv.baseMarginSmall};

    @media ${sv.ipad} {
      font-size: 1.2rem;
    }
  `,
  subtitle: css`
    color: ${sv.textSecondaryDark};
    margin-bottom: calc(${sv.baseMargin} * 2);

    @media ${sv.ipad} {
      margin-bottom: ${sv.baseMargin};
    }
  `,
  container: css`
    display: flex;
    align-items: flex-start;
  `,
  floatingSidebar: css`
    width: 300px;

    @media ${sv.ipad} {
      display: none;
    }
  `,
  sidebar: css`
  `,
  floating: css`
    position: fixed;
    top: ${sv.baseMargin};
  `,
  items: css`
    flex: 1;
  `,
  sidebarItem: css`
    display: flex;
    margin-bottom: ${sv.baseMarginSmall};
    align-items: center;
    justify-content: flex-start;
    color: ${sv.textSecondaryDark};
    font-size: 0.9rem;

    &:hover {
      cursor: pointer;
      color: ${sv.textPrimaryDark};
    }
  `,
  active: css`
    & [data-element="bubble"] {
      background: ${sv.brandPrimary};
    }
    font-weight: 600;
    color: ${sv.textPrimaryDark};
  `,
  completed: css`
    & [data-element="bubble"] {
      background: ${sv.brandGreen};
    }
  `,
  bubble: css`
    width: 25px;
    height: 25px;
    border-radius: 1000px;
    background: ${sv.grey500};
    margin-right: ${sv.baseMarginSmall};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${sv.white};
  `,
  itemPanel: css`
    margin-bottom: ${sv.baseMarginSmall};
    background: ${sv.white};
    padding: ${sv.basePadding};
    border-radius: ${sv.baseBorderRadius};
    box-shadow: -2px 2px 10px rgba(100, 100, 100, 0.15);

    @media ${sv.ipad} {
      box-shadow: none;
      padding: 0;
      position: relative;
      background: none;

      &::after {

      }
    }
  `,
  panelTitle: css`
    color: ${sv.textPrimaryDark};
    font-size: 1.1rem;
    padding-bottom: ${sv.basePadding};
    margin-bottom: ${sv.baseMargin};
    position: relative;

    [data-element="bubble-container"] {
      display: none;
    }

    &::after {
      content: ' ';
      position: absolute;
      left: calc(${sv.basePadding} * -1);
      top: 100%;
      width: calc(100% + ${sv.basePadding} * 2);
      height: 1px;
      background: ${sv.grey300};
    }

    @media ${sv.ipad} {
      padding-bottom: 0;
      margin-bottom: 0;
      display: flex;
      align-items: center;

      [data-element="bubble-container"] {
        display: block;
      }

      &::after {
        content: none;
      }
    }
  `,
  boldTitle: css`
    font-weight: 600;
  `,
  panelContent: css`
    @media ${sv.ipad} {
      padding: ${sv.basePaddingSmall};
      padding-left: ${sv.basePadding};
      position: relative;

      &::after {
        content: ' ';
        position: absolute;
        left: 12px;
        height: calc(100% - 5px);
        width: 1px;
        background: ${sv.grey300};
        top: 5px;
      }
    }
  `,
  note: css`
    color: ${sv.textSecondaryDark};
    font-size: 0.8rem;
    margin-top: ${sv.baseMarginSmall};
  `,
};
