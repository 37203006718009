import morphey, { fromKey } from 'morphey';


function getCurrencyName(code) {
  switch (code) {
    case 'EUR':
      return 'euro';
    case 'GBP':
      return 'pound';
    case 'USD':
      return 'dollar';
  }
}


const translationsFromServer = {
  id: 'id',
  email: 'email',
  firstName: 'first_name',
  lastName: 'last_name',
  intercomHash: 'intercom_hash',
  organization: 'organisation.id',
  credits: 'organisation.credits',
  'cart.count': 'cart.count',
  'cart.id': 'cart.id',
  phone: 'phone',
  unconfirmedEmail: 'unconfirmed_email',
  profilePicture: fromKey('profile_picture').using((p) => p === {} ? undefined : p),
  'currency.code': 'currency.code',
  'currency.name': fromKey('currency.code').using(getCurrencyName),
  'currency.symbol': 'currency.symbol',
  locale: 'locale',
};


export function sessionFromServer(user) {
  const translated = morphey(user, translationsFromServer);
  return translated;
}


const translationsToServer = {
  id: 'id',
  email: 'email',
  first_name: 'firstName',
  last_name: 'lastName',
  intercom_hash: 'intercomHash',
  phone: 'phone',
  organisation_id: 'organization.id',
  unconfirmed_email: 'unconfirmedEmail',
  profile_picture: 'profilePicture',
  locale: 'locale',
  password: fromKey('password').using((p) => p === '' ? undefined : p),
  old_password: fromKey('oldPassword').using((p) => p === '' ? undefined : p),
};


export function sessionToServer(user) {
  return morphey(user, translationsToServer);
}


const guestTranslationsToServer = {
  email: 'email',
  first_name: 'firstName',
  last_name: 'lastName',
};


export function guestToServer(guest) {
  return morphey(guest, guestTranslationsToServer);
}


const guestTranslationsFromServer = {
  id: 'id',
  email: 'email',
  firstName: 'first_name',
  lastName: 'last_name',
  itemId: 'item_id',
  locale: 'locale',
};


export function guestFromServer(guest) {
  return morphey(guest, guestTranslationsFromServer);
}


export default {
  fromServer: sessionFromServer,
  toServer: sessionToServer,
  guestToServer,
  guestFromServer,
};
