import Estate from './Estate';
import Item from './Item';
import Service from './Service';
import Product from './Product';


export default {
  Estate: Estate,
  Item: Item,
  ProductService: Service,
  Product: Product,
};
