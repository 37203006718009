import React from 'react';
import { Checkbox, ErrorLabel } from 'react-ittsu/forms';
import autobind from 'autobind-decorator';
import get from 'lodash/get';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';

import { createTranslate, translate as t } from '~/utils/translation';


const tt = createTranslate('pods.order.services');


const styles = {
  checkbox: css`
    margin-bottom: ${sv.baseMarginSmall};
  `,
};


class MultiSelect extends React.Component {
  render() {
    const {
      values,
      answers,
      resultKey,
      modifyAnswer,
      metaComponent,
      max = false,
      required,
      withErrors,
    } = this.props;
    const sectionAnswers = get(answers, resultKey, []) || [];
    const disabledOthers = max && sectionAnswers.length === (typeof max === 'function' ? max(answers) : max);
    const showError = withErrors && required && sectionAnswers.length === 0;

    return (
      <div>
        {values.map((answer, i) => (
          <div key={i} className={styles.checkbox}>
            <Checkbox
              disabled={! sectionAnswers.includes(answer.value) && disabledOthers}
              onChange={() => this._onClickCell(answer.value)}
              checked={sectionAnswers.includes(answer.value)}>
              {tt(answer.label, null, answer.label)}
            </Checkbox>
          </div>
        ))}
        {do{
          if (metaComponent) {
            <div>
              <metaComponent.type {...metaComponent.props} answers={answers} modifyAnswer={modifyAnswer} />
            </div>
          }
        }}
        {do {
          if (showError) {
            <ErrorLabel
              text={t('pods.order.components.briefing_details.field_required')} />
          }
        }}
      </div>
    );
  }

  @autobind
  _onClickCell(value) {
    const { modifyAnswer, resultKey, answers, values } = this.props;
    const sectionAnswers = get(answers, resultKey, []) || [];
    const remove = sectionAnswers.includes(value);
    if (remove) {
      const inactiveMetaKeys = values.reduce((inactiveKeys, currentValue) =>
        currentValue.value === value ? [ ...inactiveKeys, ...(currentValue.meta?.components.map((c) => c.key) || []) ] : inactiveKeys, []);
      modifyAnswer(inactiveMetaKeys, null, true);
      if (sectionAnswers.length === 1) {
        modifyAnswer(resultKey, null, true);
      }
      else {
        modifyAnswer(resultKey, sectionAnswers.filter((a) => a !== value));
      }
    }
    else {
      modifyAnswer(resultKey, [ ...sectionAnswers, value ]);
    }
  }
}


export default MultiSelect;
