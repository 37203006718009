import {
  Banner,
  ButtonLink,
  Category,
  Color,
  Flex,
  FlexItem,
  FlexSpacer,
  Icon,
  ListTile,
  Margin,
  Padding,
  Panel,
  PanelBody,
  PanelHeader,
  Separator,
  Size,
  Tier,
  Title,
  formatPrice,
} from '@drawbotics/react-drylus';
import { css, cx } from 'emotion';
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { createTranslate } from '~/utils/translation';

import { EstateHeader, EstateLayout, SolveProblemModal } from '../../components';
import { MilestoneName } from '../../types';
import { ErrorType, useFetchEstateProblems, useFetchItems } from '../../utils';
import { HolderRow, ItemsTable, ProductionOverlayMessage } from './components';
import briefingMissing from './images/briefing-missing.svg';

const tt = createTranslate('pods.dashboard.routes.production');

const styles = {
  panelOverlay: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    z-index: 999;
  `,
  highOpacity: css`
    background: rgba(255, 255, 255, 0.95);
  `,
};

interface ProductionProps {
  estateId: string;
  triggerNotFound: VoidFunction;
  triggerCritical: VoidFunction;
}

export const Production = ({ estateId, triggerNotFound, triggerCritical }: ProductionProps) => {
  const [isSolveProblemsModalVisible, setIsSolveProblemsModalVisible] = useState(false);
  const { isLoading, items, error, refetch } = useFetchItems(estateId);
  const { data, isLoading: isProblemsLoading } = useFetchEstateProblems(estateId);

  if (error === ErrorType.NOT_FOUND || error === ErrorType.NOT_AUTHORIZED) {
    triggerNotFound();
    return null;
  }
  if (error === ErrorType.CRITICAL) {
    triggerCritical();
    return null;
  }

  useEffect(() => {
    if (data.problems.length === 0) {
      refetch();
    }
  }, [data.problems.length]);

  const isEstateBriefingUnderReview =
    !items?.some((item) =>
      item.milestones.find((milestone) => milestone.name === MilestoneName.ACCEPTED),
    ) &&
    !data.missingBriefing &&
    !isLoading;

  const showPanelOverlay =
    !isLoading &&
    !isProblemsLoading &&
    (data.holders.length > 0 || data.problems.length > 0 || data.missingBriefing);

  return (
    <EstateLayout header={<EstateHeader estateId={estateId} />}>
      <Padding size={{ vertical: Size.EXTRA_LARGE, horizontal: Size.LARGE }}>
        {data.owedAmount ? (
          <Margin size={{ bottom: Size.DEFAULT }}>
            <Banner
              category={Category.DANGER}
              trailing={
                <Link to="billing">
                  <ButtonLink inversed tier={Tier.SECONDARY} size={Size.SMALL}>
                    {tt('settle_invoices')}
                  </ButtonLink>
                </Link>
              }>
              {tt('overdue_invoices', { amount: formatPrice({ price: data.owedAmount }) })}
            </Banner>
          </Margin>
        ) : null}
        {data.holders.length > 0 || data.problems.length > 0 ? (
          <Margin size={{ bottom: Size.DEFAULT }}>
            <Panel
              header={
                <PanelHeader>
                  <Title noMargin size={4}>
                    {tt('solve_problems')}
                  </Title>
                </PanelHeader>
              }
              body={
                <PanelBody noPadding>
                  {data.problems.length > 0 ? (
                    <HolderRow
                      holder="problem"
                      onClickAction={() => setIsSolveProblemsModalVisible(true)}
                    />
                  ) : null}
                  {data.holders.length > 0 ? <Separator /> : null}
                  {data.holders.map((reason, i) => (
                    <Fragment key={reason}>
                      <HolderRow holder={reason} />
                      {i < data.holders.length - 1 ? <Separator /> : null}
                    </Fragment>
                  ))}
                </PanelBody>
              }
            />
            {data.problems.length > 0 ? (
              <SolveProblemModal
                problems={data.problems}
                visible={isSolveProblemsModalVisible}
                onClickClose={() => setIsSolveProblemsModalVisible(false)}
              />
            ) : null}
          </Margin>
        ) : null}
        <Panel
          header={
            <PanelHeader>
              <Flex>
                <FlexItem flex>
                  <Title noMargin size={4}>
                    {tt('production')}
                  </Title>
                </FlexItem>
                {isEstateBriefingUnderReview ? (
                  <FlexItem>
                    <ListTile
                      leading={<Icon name="clock" color={Color.BRAND} />}
                      title={tt('briefing_in_review')}
                    />
                  </FlexItem>
                ) : null}
                <FlexSpacer size={Size.SMALL} />
                <FlexItem>
                  <Link to={`/order/briefing/${estateId}`}>
                    <ButtonLink tier={Tier.SECONDARY}>{tt('go_to_briefing')}</ButtonLink>
                  </Link>
                </FlexItem>
              </Flex>
            </PanelHeader>
          }
          body={
            <PanelBody
              noPadding
              style={{
                position: 'relative',
                minHeight: !isProblemsLoading && data.missingBriefing ? 550 : undefined,
              }}>
              {showPanelOverlay ? (
                <div
                  className={cx(styles.panelOverlay, {
                    [styles.highOpacity]: data.missingBriefing,
                  })}>
                  {data.missingBriefing ? (
                    <ProductionOverlayMessage
                      actions={
                        <Link to={`/order/briefing/${estateId}`}>
                          <ButtonLink category={Category.BRAND}>
                            {tt('complete_briefing')}
                          </ButtonLink>
                        </Link>
                      }
                      title={tt('first_things_first')}
                      description={tt('ensure_efficiency')}
                      image={briefingMissing}
                    />
                  ) : null}
                </div>
              ) : null}
              <ItemsTable isLoading={isLoading} items={items} />
            </PanelBody>
          }
        />
      </Padding>
    </EstateLayout>
  );
};
