import morphey from 'morphey';


const translationsFromServer = {
  id: 'id',
  items: 'items',
  organization: 'organisation_id',
  followers: 'follower_ids',
  name: 'name',
};


export function projectFromServer(project) {
  return morphey(project, translationsFromServer);
}


export default {
  fromServer: projectFromServer,
};
