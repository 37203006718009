const k = (key) => `exterior_3d.briefing.${key}`;


const residential = {
  1: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('lighting'),  // TODO translation -> changet text
        component: 'Title',
      },
      {
        type: 'component',
        label: k('shadow_strength'),
        component: 'Note',
      },
      {
        type: 'question-component',
        key: 'shadows',
        keyLabel: k('keys.shadows'),
        required: 'all',
        component: 'SingleSelect',
        values: [
          {
            value: 'none',
            label: k('shadows.no_shadows'),
            image: require('../images/exterior-3d/image_details/shadows/no_shadows.jpg'),

          },
          {
            value: 'normal',
            label: k('shadows.normal'),
            image: require('../images/exterior-3d/image_details/shadows/normal.jpg'),

          },
        ],
      },
    ],
  },
  2: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('people'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('people_integration'),
        component: 'Note',
      },
      {
        type: 'question-component',
        key: 'people',
        keyLabel: k('people_integration'),
        required: 'all',
        component: 'SingleSelect',
        values: [
          {
            image: require('../images/shared/none.jpg'),
            value: 'none',
            label: k('integration_options.none'),
          },
          {
            image: require('../images/shared/3d-people.jpg'),
            value: 'three_d',
            label: k('integration_options.three_d'),
          },
        ],
      },
    ],
  },
  3: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('image_settings'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('edit_settings'),
        component: 'Note',
      },
      {
        type: 'component-group',
        component: 'ViewOptions',
        required: 'all',
        key: 'views',
        keyLabel: k('images'),
        components: [
          {
            type: 'component',
            label: k('select_lighting'),
            component: 'Note',
          },
          {
            type: 'question-component',
            key: 'views.%{n}.details.lighting',
            keyLabel: k('keys.lighting'),
            component: 'MultiSelect',
            required: 'all',
            values: [
              {
                value: 'midday_sun',
                label: k('lighting_options.midday_sun'),
                image: require('../images/exterior-3d/image_details/lighting/midday_sun.jpg'),
              },
              {
                value: 'cloudy',
                label: k('lighting_options.cloudy'),
                image: require('../images/exterior-3d/image_details/lighting/cloudy.jpg'),
              },
              {
                value: 'dawn_dusk',
                label: k('lighting_options.dawn_dusk'),
                image: require('../images/exterior-3d/image_details/lighting/dawn_dusk.jpg'),
              },
              {
                value: 'evening',
                label: k('lighting_options.evening'),
                image: require('../images/exterior-3d/image_details/lighting/evening.jpg'),
              },
            ],
          },
          {
            type: 'meta',
            condition: {
              required: 'one',
              conditions: [{
                type: 'threshold',
                value: 2,
                path: 'views.%{n}.details.lighting',
              }],
            },
            components: [
              {
                type: 'component',
                label: k('option_explanation'),
                component: 'Note',
              },
              {
                type: 'question-component',
                label: k('add_explanation'),
                component: 'Input',
                required: 'all',
                key: 'views.%{n}.details.lightingDetails',
                keyLabel: k('keys.choice_description'),
              },
            ],
          },
          {
            type: 'component',
            label: k('select_camera_angles'),
            component: 'Note',
          },
          {
            type: 'question-component',
            component: 'SingleSelect',
            key: 'views.%{n}.details.angles',
            keyLabel: k('keys.angles'),
            required: 'all',
            values: [
              {
                value: 'floorplan',
                label: k('camera_angles_options.floorplan'),
                image: require('../images/exterior-3d/angles/floorplan.jpg'),
              },
              {
                value: 'description',
                label: k('camera_angles_options.my_own'),
                image: require('../images/exterior-3d/angles/my-own.jpg'),
                meta: {
                  type: 'meta',
                  required: 'one',
                  noToggle: true,
                  components: [
                    {
                      type: 'component',
                      component: 'Note',
                      label: k('describe_angles'),
                    },
                    {
                      type: 'question-component',
                      key: 'views.%{n}.details.anglesDescription',
                      keyLabel: k('angles_description'),
                      component: 'Input',
                      label: k('angles_description'),
                      required: 'one',
                    },
                  ],
                },
              },
              {
                value: 'suggestion',
                label: k('camera_angles_options.suggest'),
                image: require('../images/shared/drawbotics.jpg'),
              },
            ],
          },
          {
            type: 'component',
            label: k('extra_style_info'),
            component: 'Note',
          },
          {
            type: 'question-component',
            label: 'shared.additional_comments',
            component: 'Input',
            key: 'views.%{n}.details.otherStyle',
            keyLabel: 'shared.keys.example_comments',
          },
          {
            type: 'question-component',
            label: 'shared.upload_files',
            component: 'FileUploader',
            key: 'views.%{n}.details.otherStyleAttachments',
            keyLabel: 'shared.keys.submitted_examples',
          },
        ],
      },
    ],
  },
};


export default { residential };
