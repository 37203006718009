import get from 'lodash/get';

import { invalidatePreview } from 'pods/studio/actions/previews';


const invalidateWhenEdit = ({ dispatch }) => next => action => {
  if ( ! get(action, 'meta.invalidatePreview', false)) {
    return next(action);
  }

  next(invalidatePreview(action.meta.invalidatePreview));
  next(action);
};


export default invalidateWhenEdit;
