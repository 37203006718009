import React from 'react';
import { Button, Panel } from 'react-ittsu/components';
import { css, cx } from 'emotion';
import sv from '@drawbotics/style-vars';
import { connect } from 'react-redux';
import { compose } from 'react-apollo-redux';
import autobind from 'autobind-decorator';

import Spinner from '~/components/Spinner';
import ScrollIndicator from './ScrollIndicator';
import { createTranslate } from '~/utils/translation';


const tt = createTranslate('pods.order.components.main_panel');


const styles = {
  mainPanel: css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.1);

    @media ${sv.ipad} {
      margin-bottom: 0;
      padding: ${sv.basePaddingSmall};
      padding-top: 0;
      border: none;
      box-shadow: none;
    }

    @media print {
      border: none;
      height: auto;
      box-shadow: none;
    }
  `,
  asContainer: css`
    background: none;
    padding: 0;
    box-shadow: none;
  `,
  content: css`
    height: 100%;
    flex: 1;
    padding: ${sv.basePaddingSmall} ${sv.basePadding};
    padding-bottom: calc(100px + ${sv.baseMargin});
    overflow: auto;

    @media print {
      overflow: visible;
      padding: 0;
    }

    @media ${sv.ipad} {
      padding-left: 5px;
      padding-right: 5px;
      padding-bottom: calc(${sv.basePadding} * 2);
    }
  `,
  bottomBar: css`
    height: 100px;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 ${sv.basePadding};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.95);

    & button {
      min-width: 150px;
    }

    @media ${sv.ipad} {
      height: auto;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      backdrop-filter: blur(2px);
      box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.1);
      padding: ${sv.basePaddingSmall};
      justify-content: flex-end;

      & button {
        min-width: auto;
        width: 65px;
        height: 65px;
        border-radius: 0px !important;
        padding: 0;
        margin: -15px;
        font-size: 1.4em;
      }

      & [data-element="text"] {
        display: none;
      }

      & [data-element="icon"] {
        display: block;
      }
    }

    @media print {
      display: none;
    }
  `,
  spinner: css`
    width: auto;
    display: inline-block;
    margin: 0 ${sv.baseMarginSmall};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    & [data-element="spinner"] {
      border-color: ${sv.white};
      border-top-color: transparent;
    }
  `,
  mobileDisabled: css`
    @media ${sv.ipad} {
      width: 0 !important;
    }
  `,
  scroller: css`
    &:hover {
      cursor: pointer;
    }

    @media ${sv.ipad} {
      display: none;
    }
  `,
};


export class MainPanel extends React.Component {
  state = {}

  componentDidMount() {
    this.content.addEventListener('scroll', this._handleScrollContent);
    window.addEventListener('resize', this._handleScrollContent);
    this.setState({ scrollTop: 0 });
  }

  componentWillUnmount() {
    this.content.removeEventListener('scroll', this._handleScrollContent);
    window.removeEventListener('resize', this._handleScrollContent);
  }

  render() {
    const { scrollTop } = this.state;
    const {
      children,
      hideWhenDisabled=false,
      onClickBack,
      onClickNext,
      displaySuccess,
      nextText,
      disableNext=false,
      loadingNext=false,
      asPanel=false,
      loadingDisableNext,
    } = this.props;
    const backDisabled = ! onClickBack;
    const nextDisabled = ! onClickNext || disableNext;
    const scrollDifference = this.content?.scrollHeight - this.content?.clientHeight;
    return (
      <Panel className={cx(styles.mainPanel, { [styles.asContainer]: ! asPanel })}>
        <div className={styles.content} style={asPanel ? { paddingBottom: 0, overflow: 'visible' } : null} ref={(content) => this.content = content}>
          {children}
        </div>
        {do {
          if ( ! backDisabled || ! nextDisabled) {
            <div className={styles.bottomBar}>
              <Button
                round
                variation="transparent"
                disabled={backDisabled}
                style={{ opacity: hideWhenDisabled && backDisabled ? 0 : undefined, marginRight: sv.baseMarginSmall }}
                onClick={onClickBack}>
                <span data-element="icon" style={{ marginRight: '5px' }}>
                  <i className="ion-android-arrow-back" />
                </span>
                <span data-element="text">
                  Back
                </span>
              </Button>
              {do{
                if (scrollDifference > 0) {
                  <div className={styles.scroller} onClick={() => this._handleClickScroll(scrollDifference)}>
                    <ScrollIndicator reversed={scrollTop === scrollDifference} />
                  </div>
                }
              }}
              <Button
                category={displaySuccess ? 'success' : 'primary'}
                round
                disabled={nextDisabled || loadingNext || loadingDisableNext}
                style={{
                  opacity: (hideWhenDisabled && nextDisabled) ? 0 : undefined,
                  position: 'relative',
                  paddingRight: loadingNext ? 60 : undefined,
                }}
                className={nextDisabled ? styles.mobileDisabled : null}
                onClick={onClickNext}>
                <span data-element="text">
                  {do {
                    if (nextText != null) {
                      nextText
                    }
                    else {
                      displaySuccess ? tt('confirm_and_pay') : tt('next')
                    }
                  }}
                </span>
                <span data-element="icon" style={{ marginLeft: '5px' }}>
                  <i className="ion-android-arrow-forward" />
                </span>
                {do {
                  if (loadingNext) {
                    <div className={styles.spinner}>
                      <Spinner size="20" />
                    </div>
                  }
                }}
              </Button>
            </div>
          }
        }}
      </Panel>
    );
  }

  @autobind
  _handleScrollContent() {
    this.setState({ scrollTop: this.content?.scrollTop });
  }

  @autobind
  _handleClickScroll(diff) {
    if (this.state.scrollTop === diff) {
      this.content.scrollTo(0, 0);
      this.setState({ scrollTo: 0 });
    }
    else {
      this.content.scrollTo(0, diff);
      this.setState({ scrollTo: diff });
    }
  }
}


const mapStateToProps = (state, ownProps) => ({
  loadingDisableNext: state.order.ui.isLoading,
});


export default compose(
  connect(mapStateToProps, null),
)(MainPanel);
