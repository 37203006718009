import React, { Fragment } from 'react';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';

import { createTranslate } from '~/utils/translation';


const tt = createTranslate('pods.order.routes.estate_summary.print');


const styles = {
  printInstructions: css`
    margin-top: 50px;
    display: none;

    @media print {
      display: block;
    }
  `,
  disclaimer: css`
    border: 1px solid ${sv.brandRed};
    color: ${sv.brandRed};
    padding: ${sv.baseMarginSmall} ${sv.baseMargin};
    text-transform: uppercase;
    margin-bottom: ${sv.baseMargin};
    font-size: 0.9rem;
  `,
  stepsMessage: css`
    font-size: 1.4rem;
    color: ${sv.textSecondaryDark};
  `,
  steps: css`
    font-size: 1.1rem;
    color: ${sv.textSecondaryDark};

    > li {
      margin: calc(${sv.baseMarginSmall} / 2) 0;
    }
  `,
  printFooter: css`
    width: 100%;
    padding: ${sv.basePaddingSmall};
    margin-top: ${sv.baseMargin};
    color: ${sv.textTertiaryDark};
    text-transform: uppercase;
    font-size: 0.85rem;
    text-align: center;
    bottom: 0;
    display: none;

    @media print {
      display: block;
    }
  `,
}


const PrintInstructions = () => {
  return (
    <Fragment>
      <div className={styles.printInstructions}>
        <p className={styles.disclaimer}>
          {tt('disclaimer')}
        </p>
        <p className={styles.stepsMessage}>
          {tt('steps_message')}:
        </p>
        <ol className={styles.steps}>
          <li>{tt('step_1')}</li>
          <li>{tt('step_2')}</li>
          <li>{tt('step_3')}</li>
          <li>{tt('step_4')}</li>
          <li>{tt('step_5')}</li>
        </ol>
      </div>
      <div className={styles.printFooter}>
        {tt('address')}<br />
        <strong>{tt('email')}:</strong> info@drawbotics.com -&nbsp;
        <strong>{tt('tel')}:</strong> +32 2 380 69 33
      </div>
    </Fragment>
  );
}


export default PrintInstructions;
