import { fork, call, takeLatest, put } from 'redux-saga/effects';

import { Draft } from 'pods/studio/utils/api';
import * as DraftsActions from 'pods/studio/actions/drafts';
import * as UIActions from 'pods/studio/actions/ui';


/**
 * REGULAR SAGAS
 */
function* handleValidateDraft(action) {
  const { draft } = action.payload;
  yield put(UIActions.disableApproveButton())
  yield call(Draft.validate, draft.id);
  window.location.href = `/projects/${draft.projectItem.estateId}/production/${draft.projectItem.id}`;
}

function* handleLoadDraft(action) {
  const { id, projectId } = action.payload;
  const result = yield call(Draft.get, id, projectId);
  yield put(DraftsActions.loadDraftSuccess(result));
}


/**
 * WATCHERS
 */
function* watchValidateDraft() {
  yield takeLatest(DraftsActions.VALIDATE_DRAFT, handleValidateDraft);
}

function* watchLoadDraft() {
  yield takeLatest(DraftsActions.LOAD_DRAFT, handleLoadDraft);
}


/**
 * ROOT
 */
export default function* root() {
  yield fork(watchValidateDraft);
  yield fork(watchLoadDraft);
}
