import morphey, { fromKey } from 'morphey';

import { attachmentFromServer } from './attachment';


const translationsFromServer = {
  id: 'id',
  reason: 'reason',
  comments: 'comments',
  attachments: fromKey('attachments').using((at) => at ? at.map(attachmentFromServer) : []),
  newSurface: fromKey('amendment.new_surface').defaultTo(''),
  oldSurface: fromKey('amendment.old_surface').defaultTo(''),
  price: fromKey('amendment.price').defaultTo(''),
};


export function problemFromServer(problem) {
  return morphey(problem, translationsFromServer);
}


const translationsToServer = {
  reception_problem_id: 'id',
  comments: 'comments',
  amendment_id: 'id',
  attachment_ids: 'attachments',
};


export function problemToServer(problem) {
  return morphey(problem, translationsToServer);
}


export default {
  fromServer: problemFromServer,
  toServer: problemToServer,
};
