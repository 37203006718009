import { ze } from '@tokamakjs/common';
import { z } from 'zod';

import { ItemStatus, PaymentDocumentStatus } from '../../types';

const ItemDtoSchema = z.object({
  id: z.string(),
  // @ts-ignore Type instantiation is excessively deep and possibly infinite.
  status: z.nativeEnum(ItemStatus),
  briefingConfirmedAt: ze.optional(z.string()),
  problems: ze.defaults(z.array(z.any()), []),
});

class ItemDto extends ze.ClassFrom(ItemDtoSchema) {
  get requiresAction(): boolean {
    return (
      [ItemStatus.PROBLEM, ItemStatus.AWAITING].includes(this.status) ||
      (this.briefingConfirmedAt == null &&
        ![ItemStatus.ONGOING, ItemStatus.ARCHIVED, ItemStatus.FINISHED].includes(this.status))
    );
  }
}

const InvoiceDtoSchema = z.object({
  status: z.nativeEnum(PaymentDocumentStatus),
});

export const ProjectDtoSchema = z.object({
  id: z.string(),
  name: z.string(),
  // @ts-ignore Type instantiation is excessively deep and possibly infinite.
  briefingConfirmedAt: ze.optional(z.string()),
  items: ze.defaults(z.array(ItemDtoSchema), []),
  problems: ze.defaults(z.array(z.any()), []),
  paymentProcess: z.object({
    invoices: ze.defaults(z.array(InvoiceDtoSchema), []),
  }),
});

export class ProjectDto extends ze.ClassFrom(ProjectDtoSchema) {
  get actionsCount(): number {
    // TODO: Improve ze.validate to recursively transform schemas
    // instead of having to do this manually
    const items = this.items.map((i) => ze.validate(i, ItemDto));
    const itemActionsCount = items.filter((i) => i.requiresAction).length;

    const problemsCount = this.problems.length;

    const billingActionsCount = this.paymentProcess.invoices.filter((i) => {
      return [PaymentDocumentStatus.LATE, PaymentDocumentStatus.SENT].includes(i.status);
    }).length;

    const briefingAction = this.briefingConfirmedAt == null ? 1 : 0;

    return problemsCount + itemActionsCount + billingActionsCount + briefingAction;
  }

  get hasProblem(): boolean {
    const projectHasProblem = this.problems.length > 0;
    const itemsHaveProblems = this.items.some((item) => item.problems.length > 0);

    return projectHasProblem || itemsHaveProblems;
  }
}
