import React from 'react';
import { css, cx } from 'emotion';
import sv from '@drawbotics/style-vars';


const switchHandleDimensions = 20;
const switchBorderRadius = switchHandleDimensions;
const switchPadding = 3;
const switchActiveOffset = 6;

const styles = {
  switch: css`
    display: inline-block;
    border-radius: ${switchBorderRadius}px;
    background: ${sv.grey200};
    transition background ${sv.baseTransitionTimeShort} ease-in-out;

    &:hover {
      cursor: pointer;
    }
  `,
  disabled: css`
    cursor: not-allowed !important;
    opacity: 0.5;
  `,
  minimal: css`
    background: ${sv.white} !important;

    & [data-element="handle"] {
      background: ${sv.brandPrimary};
      box-shadow: none;
    }
  `,
  active: css`
    background: ${sv.brandGreen};

    & [data-element="content"] {
      transform: translateX(${switchHandleDimensions * 1.5 - switchActiveOffset}px);

      &:active {
        transform: translateX(${switchHandleDimensions * 1.5 - switchActiveOffset * 1.5}px);
      }

      &:after {
        transform: translateX(${switchHandleDimensions * 1.5 - switchActiveOffset}px);
      }
    }
  `,
  background: css`
    width: ${switchHandleDimensions * 2.5 - 5 + switchPadding * 2}px;
    height: ${switchHandleDimensions + switchPadding * 2}px;
    border-radius: ${switchBorderRadius}px;
    padding: ${switchPadding}px;
    position: relative;
    overflow: hidden;
  `,
  content: css`
    position: absolute;
    height: 100%;
    left: ${switchPadding}px;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all ${sv.baseTransitionTime} ${sv.bouncyTransitionCurve};

    &:active {
      transform: translateX(${switchActiveOffset / 2}px);
    }
  `,
  handle: css`
    width: ${switchHandleDimensions}px;
    height: ${switchHandleDimensions}px;
    border-radius: 100%;
    background: ${sv.white};
    box-shadow: ${sv.elevation1};
  `,
}


const className = (props) => cx(styles.switch, {
  [styles.active]: props.value,
  [styles.minimal]: props.minimal,
  [styles.disabled]: props.disabled,
});


const Switch = ({ value, onChange, minimal, disabled }) => {
  return (
    <div className={className({ value, minimal, disabled })} onClick={disabled ? null : () => onChange(! value)}>
      <div className={styles.background}>
        <div className={styles.content} data-element="content">
          <div className={styles.handle} data-element="handle" />
        </div>
      </div>
    </div>
  );
}


export default Switch;
