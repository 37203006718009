const k = (key) => `photo_shooting.briefing.${key}`;


const residential = {
  1: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('pois'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('search_on_map'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'MapSearch',
        required: 'all',
        key: 'pois',
        keyLabel: k('pois'),
      },
    ],
  },
  2: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'shared.additional_info',
        component: 'Title',
      }, {
        type: 'component',
        label: 'shared.anything_to_add',
        component: 'Note',
      }, {
        type: 'question-component',
        label: 'shared.additional_comments',
        component: 'Input',
        key: 'otherInfo',
        keyLabel: 'shared.keys.other_info',
      }, {
        type: 'question-component',
        label: 'shared.upload_files',
        component: 'FileUploader',
        key: 'otherInfoAttachments',
        keyLabel: 'shared.keys.other_info_attachments',
      },
    ],
  },
};

export default { residential };
