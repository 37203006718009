import { fork, put, call, select, takeLatest } from 'redux-saga/effects';

import { Annotation } from 'pods/studio/utils/api';
import { getActiveAnnotation, getAnnotation } from 'pods/studio/selectors';
import * as AnnotationsActions from 'pods/studio/actions/annotations';
import * as AttachmentsActions from 'pods/studio/actions/attachments';
import * as UIActions from 'pods/studio/actions/ui';


/**
 * REGULAR SAGAS
 */
function* handleCreateAnnotation(action) {
  const { data } = action.payload;
  const activeAnnotationId = yield select(getActiveAnnotation);
  if (activeAnnotationId) {
    yield put(UIActions.closeAnnotation(activeAnnotationId));
  }
  yield put(UIActions.openAnnotation(data.id));
}


function* handleSaveAnnotation(action) {
  const { id } = action.payload.annotation;
  const annotation = yield select(getAnnotation, id);
  yield put(UIActions.disableValidatePreviewButton());
  if (action.payload.closeAnnotation && (! annotation.comment || annotation.comment === '')) {
    const error = new Error('Annotation shouldn\'t have an empty comment');
    console.reportException(error, {
      annotation,
    });
    throw error;
  }
  let result;
  if (annotation.isDangling) {
    result = yield call(Annotation.create, annotation);
    if ( ! action.payload.closeAnnotation) {
      yield put(UIActions.openAnnotation(result.id));      
    }
    yield put(AnnotationsActions.updateAnnotation(annotation.id, { isDangling: false, isDirty: false }));
  }
  else {
    result = yield call(Annotation.update, annotation.id, annotation);
    yield put(AnnotationsActions.updateAnnotation(annotation.id, { isDirty: false }));
  }
  yield put(AnnotationsActions.saveAnnotationSuccess(annotation.id, result.id));
  yield put(UIActions.enableValidatePreviewButton());
  yield put(AttachmentsActions.saveAttachments(annotation.attachments, result.id));
}


function* deleteDanglingAnnotation(action) {
  const { id } = action.payload;
  const annotation = yield select((state) => getAnnotation(state, id));
  if (annotation && annotation.isDangling) {
    yield put(AnnotationsActions.deleteAnnotation(annotation));
  }
}


function* handleDeleteAnnotation(action) {
  const { annotation } = action.payload;
  if ( ! annotation.isDangling) {
    yield call(Annotation.delete, annotation.id);
  }
}


/**
 * WATCHERS
 */
function* watchCloseAnnotation() {
  yield takeLatest(UIActions.CLOSE_ANNOTATION, deleteDanglingAnnotation);
}


function* watchCreateAnnotation() {
  yield takeLatest(AnnotationsActions.CREATE_ANNOTATION, handleCreateAnnotation);
}


function* watchSaveAnnotation() {
  yield takeLatest(AnnotationsActions.SAVE_ANNOTATION, handleSaveAnnotation);
}


function* watchDeleteAnnotation() {
  yield takeLatest(AnnotationsActions.DELETE_ANNOTATION, handleDeleteAnnotation);
}


/**
 * ROOT
 */
export default function* root() {
  yield fork(watchCloseAnnotation);
  yield fork(watchCreateAnnotation);
  yield fork(watchSaveAnnotation);
  yield fork(watchDeleteAnnotation);
}
