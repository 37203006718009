import React from 'react';
import autobind from 'autobind-decorator';
import { Grid, Cell } from 'react-ittsu/layout';
import gql from 'fraql';

import BaseServiceModal from '../BaseServiceModal';
import Paragraph from '../Paragraph';
import SectionTitle from '../SectionTitle';
import { ProductCard, Stripe, StripeGroup } from '../../forms';
import { priceItem, applyPrice } from '../../../utils/pricing';
import { specsToItems, itemsToSpecs } from '~/pods/order/utils/services/specs-to-items';
import applyChanges from '~/pods/order/utils/services/specs-to-items/apply-changes';
import { getItemsByService, merge, openIntercom, getFeaturesForProduct } from '~/pods/order/utils';
import { createTranslate, translate as t } from '~/utils/translation';
import { EstateEnums } from '~/pods/order/utils/estate-enums';

import serviceImages from '~/pods/order/images/services';

const tt = createTranslate('pods.order.services.exterior_tour_3d.order');
const tm = createTranslate('pods.order.components.service_modal');


class ExteriorTour3D extends React.Component {
  static fragments = {
    Item: gql`
      fragment _ on Item {
        id
        details {
          ... on ExteriorTour3DDetails {
            hotspots
          }
        }
        service {
          id @client(type: ProductService)
        }
        product {
          id @client(type: Product)
          custom
          features {
            locale
            content
          }
        }
      }
    `,
  }

  state = {
    price: undefined,
    custom: null,
    specs: {
      hotspots: 1,
      standard: false,
    },
  }

  componentDidUpdate(prevProps) {
    const { service, estate } = this.props;
    const { service: prevService } = prevProps;
    if (service?.id === 'exteriorTour3d' && ! prevService) {
      const specs = itemsToSpecs({ estate, service });
      const items = getItemsByService(estate).exteriorTour3d || [];
      const customItem = items?.find((item) => item.product.custom);
      const price = items != null ? items.reduce((memo, i) => memo + priceItem(i, this.props.sessionUser.currency.code, true), 0) : 0;
      this.setState({
        specs: merge(this.state.specs, specs),
        price,
        custom: customItem ? true : null,
      });
    }
  }

  render() {
    const { onClickClose, isVisible, service, estate, sessionUser } = this.props;
    const { price, specs, custom } = this.state;
    const { exteriorTour3d } = serviceImages;
    const items = getItemsByService(estate).exteriorTour3d || [];
    const customItem = items.find((item) => item.product.custom);
    const customFeatures = custom !== null ? getFeaturesForProduct(customItem?.product) : null;
    const isResidential = estate.projectType === EstateEnums.ProjectType.RESIDENTIAL;
    return (
      <BaseServiceModal
        isVisible={isVisible && service?.id === 'exteriorTour3d'}
        title={service?.id ? t(`services.${service.id}`) : ''}
        images={Object.values(exteriorTour3d)}
        price={sessionUser.addCurrency(price)}
        onClickConfirm={this._handleClickConfirm}
        confirmAsUpdate={items?.length > 0}
        onClickClose={onClickClose}
        addDisabled={price === 0}>
        <Paragraph>
          {tt('description')}
        </Paragraph>
        <SectionTitle>
          {tt('select_type')}
        </SectionTitle>
        <Grid withVGutters withHGutters>
          <Cell size="1of2">
            <ProductCard
              name="standard"
              note={tm('from_price', { price: applyPrice(isResidential ? { EUR: 1200, USD: 1500, GBP: 1200 } : { EUR: 1400, USD: 1700, GBP: 1400 }, sessionUser) })}
              label={tm('standard')}
              fullHeight
              formComponent="radio"
              features={[
                {
                  text: tt('features.first'),
                },
                {
                  text: tt('features.second'),
                },
                {
                  text: tt('features.third'),
                },
                {
                  text: tt('features.fourth'),
                },
                {
                  text: tt('features.fifth'),
                },
                {
                  text: tt('features.sixth'),
                },
              ]}
              value={specs.standard}
              onChange={this._handleClickStandard} />
          </Cell>
          <Cell size="1of2">
            <ProductCard
              name="custom"
              label={custom !== null ? `${customItem?.product.name}` : tm('custom')}
              note={custom !== null ? `${sessionUser.addCurrency(customItem?.price)}` : tm('contact_us')}
              formComponent="radio"
              fullHeight
              features={customFeatures}
              onChange={this._handleClickCustom}
              value={!! custom} />
          </Cell>
        </Grid>
        <SectionTitle>
          {tt('choose_hotspots')}
        </SectionTitle>
        <StripeGroup>
          <Stripe
            name="hotspots"
            title={tt('hotspots')}
            subtitle={tt('minimum')}
            component="counter"
            features={[{
              text: tt('hotspot_features.first'),
            }]}
            disabled={! specs.standard}
            value={specs.hotspots}
            onChange={(v) => this._handleClickOption(v === 0 ? 1 : v, 'hotspots')} />
        </StripeGroup>
      </BaseServiceModal>
    );
  }

  @autobind
  _syncState() {
    const { estate, service } = this.props;
    const { specs } = this.state;
    const changes = specsToItems({ specs, estate, service });
    const updatedEstate = applyChanges(changes, estate);
    const items = getItemsByService(updatedEstate).exteriorTour3d;
    const price = items != null
      ? items
          .map((item) => item.estate != null ? item : { ...item, estate: updatedEstate })
          .reduce((memo, i) => memo + priceItem(i, this.props.sessionUser.currency.code, true), 0)
      : 0;
    this.setState({ price });
    return { changes, updatedEstate, price };
  }

  @autobind
  _handleClickCustom() {
    const { custom } = this.state;
    if (custom === null) {
      openIntercom();
      this._resetSpecs(this._syncState);
    }
    else {
      const newValue = ! custom;
      this._resetSpecs(() => this.setState({ custom: newValue }, this._syncState));
    }
  }

  @autobind
  _handleClickStandard(value) {
    const { specs } = this.state;
    this.setState({
      specs: { ...specs, standard: ! specs.standard },
    }, this._syncState);
  }

  @autobind
  _handleClickOption(value, key) {
    const { specs } = this.state;
    const newSpecs = { ...specs, [key]: value };
    this.setState({ specs: newSpecs }, this._syncState);
  }

  @autobind
  _resetSpecs(onReset) {
    this.setState({ specs: {
      standard: false,
      hotspots: 1,
    } }, onReset);
  }

  @autobind
  _handleClickConfirm() {
    const { onModifyService, estate } = this.props;
    const { custom } = this.state;
    const extraRemove = custom === false ? [getItemsByService(estate).exteriorTour3d.find((item) => item.product.custom)] : [];
    const { changes } = this._syncState();
    onModifyService({ ...changes, remove: [ ...changes.remove, ...extraRemove ] });
  }
}


export default ExteriorTour3D;
