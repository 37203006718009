import isPlainObject from 'lodash/isPlainObject';


export default function filterBy(object, fn) {
  return Object.keys(object).reduce((memo, k) => {
    const value = object[k];
    const useIt = fn(value, k);
    if (useIt) {
      const finalValue = isPlainObject(value) ? filterBy(value, fn) : value;
      return { ...memo, [k]: finalValue };
    }
    else {
      return memo;
    }
  }, {});
}
