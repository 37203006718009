import React from 'react';
import {
  Tooltip,
  showTooltip,
  hideTooltip,
} from 'react-ittsu/components';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';
import autobind from 'autobind-decorator';


const styles = {
  disclaimer: css`
  `,
  label: css`
    color: ${sv.textTertiaryDark};
    font-size: 0.9em;
    font-style: italic;

    > i {
      margin-right: 5px;
      font-size: 1.2em;
    }
  `,
}


class Disclaimer extends React.Component {
  state = {}

  render() {
    const { tooltipState } = this.state;
    const { disclaimer } = this.props;
    const text = disclaimer.label || disclaimer;
    return (
      <div className={styles.disclaimer}>
        <div className={styles.label}>
          {do{
            if (disclaimer.label) {
              <i
                className="ion-ios-information-outline"
                onMouseEnter={this._handleShowTooltip}
                onMouseLeave={this._handleHideTooltip} />
            }
          }}
          {text}
        </div>
        {do{
          if (disclaimer.description) {
            <Tooltip
              label="tooltipState"
              text={true}
              visible={tooltipState?.visible}
              parentRect={tooltipState?.parentRect}>
              {disclaimer.description}
            </Tooltip>
          }
        }}
      </div>
    );
  }

  @autobind
  _handleShowTooltip(e) {
    const { tooltipState } = this.state;
    this.setState({ tooltipState: showTooltip(tooltipState, e) });
  }

  @autobind
  _handleHideTooltip() {
    const { tooltipState } = this.state;
    this.setState({ tooltipState: hideTooltip(tooltipState) });
  }
}


export default Disclaimer;
