import morphey, { fromKey } from 'morphey';
import isEmpty from 'lodash/isEmpty';

import { organizationToServer } from './organization';


const translationsFromServer = {
  id: 'id',
  firstName: 'first_name',
  lastName: 'last_name',
  intercomHash: 'intercom_hash',
  email: 'email',
  phone: 'phone',
  profilePicture: fromKey('profile_picture').using((p) => isEmpty(p) ? undefined : p),
};


export function userFromServer(user) {
  return morphey(user, translationsFromServer);
}


const translationsToServer = (user) => ({
  first_name: 'firstName',
  last_name: 'lastName',
  email: 'email',
  locale: 'locale',
  intercomHash: 'intercom_hash',
  password: 'password',
  invitation_token: 'token',
  organisation: fromKey('organization').using(() => organizationToServer(user.organization)),
});


export function userToServer(user) {
  return morphey(user, translationsToServer);
}


export default {
  fromServer: userFromServer,
  toServer: userToServer,
};
