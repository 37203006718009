import { ApolloClient, HttpLink, InMemoryCache } from 'apollo-client-preset';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { withClientState } from 'apollo-link-computed';
import buildUrl from 'url-join';
import { withSlugToId } from 'apollo-link-slug-to-id';
import { withDetailsType } from 'apollo-link-details-type';

import { readSession } from '~/utils';
import { createNetworkError, createGraphQLError } from '~/utils/errors';
import resolvers from '~/resolvers';
import { GRAPHQL_HOST, GRAPHQL_BASEPATH, GRAPHQL_PUBLIC_BASEPATH } from '../constants';

// This exists because clients have to be able to send a link to the payment page
// to a person who does not have a Drawbotics account, e.g. their accounting department.
// Since that page needs to be publicly available, it uses a separate, public schema
// which we load here if we detect that we are in the transaction phase
function _canBePublic() {
  const isSettle = window.location.pathname.includes('transaction')
    && window.location.pathname.includes('settle');
  const isConfirmation = window.location.pathname.includes('transaction')
    && window.location.pathname.includes('confirmation');

  return isSettle || isConfirmation;
}


function _isMutation(operation) {
  return operation?.query?.definitions?.[0]?.operation === 'mutation';
}


export function getGraphqlUrl(isPublic) {
  return isPublic
    ? buildUrl(GRAPHQL_HOST, GRAPHQL_PUBLIC_BASEPATH)
    : buildUrl(GRAPHQL_HOST, GRAPHQL_BASEPATH);
}


const httpLink = () => new HttpLink({
  uri: getGraphqlUrl(readSession() == null && _canBePublic()),
});


const middlewareLink = () => setContext(() => {
  const authToken = readSession()?.authToken;
  if ( ! authToken) {
    return {};
  }

  if (authToken.length > 30) {
    return {
      headers: {
        'Authorization': `Bearer ${authToken}`,
      },
    }
  }

  return {
    headers: {
      'Authorization': `Token token=${authToken}`,
    },
  };
});


const errorMiddleware = (store) => onError(({ graphQLErrors=[], networkError, operation }) => {

  if (_isMutation(operation)) {
    // Don't handle mutations because they're supposed to be wrapped
    // in a try/catch block when used
    return;
  }

  graphQLErrors.forEach((graphQLError) => {
    const error = createGraphQLError(graphQLError);
    store.dispatch({
      type: 'UNCAUGHT_ERROR_IN_GRAPHQL',
      meta: { error },
    });
  });

  if (networkError) {
    const { response } = networkError;
    if ( ! response) {
      store.dispatch({
        type: 'UNCAUGHT_ERROR_IN_GRAPHQL',
        meta: { error: networkError },
      });
      return;
    }
    const error = createNetworkError(response.status, response.body, operation);
    store.dispatch({
      type: 'UNCAUGHT_ERROR_IN_GRAPHQL',
      meta: { error },
    });
  }
});


const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
}


export default function setupClient(store, fragmentTypes) {
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: fragmentTypes,
  });

  const cache = new InMemoryCache({ fragmentMatcher });

  const stateLink = withClientState({
    cache,
    resolvers,
  });

  const slugToIdLink = withSlugToId();
  const detailsTypeLink = withDetailsType();

  return new ApolloClient({
    defaultOptions,
    cache,
    link: errorMiddleware(store)
      .concat(middlewareLink())
      .concat(stateLink)
      .concat(slugToIdLink)
      .concat(detailsTypeLink)
      .concat(httpLink()),
  });
}
