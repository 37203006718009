import { css } from 'emotion';
import React, { ReactNode } from 'react';

const styles = {
  container: css`
    width: 100%;
    display: flex;
    justify-content: center;
  `,
  content: css`
    width: 100%;
    max-width: 1200px;
  `,
};

interface ContainerProps {
  children: ReactNode;
}

export const Container = ({ children }: ContainerProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
