export function getCurrentDraft(state) {
  return state.studio.ui.currentDraft;
}


export function checkIfFullScreen(state) {
  return state.studio.ui.fullScreen;
}


export function checkIfSavingAnnotation(state) {
  return state.studio.ui.savingAnnotation;
}


export function getActiveAnnotation(state) {
  return state.studio.ui.activeAnnotation;
}


export function checkIfCorrectionChecked(state) {
  return state.studio.ui.purchaseCorrectionRound;
}


export function checkIfValidatePreviewDisabled(state) {
  return state.studio.ui.disabledValidatePreview;
}


export function checkIfDisabledAnnotationSaveButton(state) {
  return state.studio.ui.disableAnnotationSaveButton;
}

export function getEnableApproveButton(state) {
  return state.studio.ui.enableApproveButton;
}