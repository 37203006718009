import React from 'react';
import { css, cx } from 'emotion';
import { Icon } from 'react-ittsu/components';
import sv from '@drawbotics/style-vars';


const styles = {
  check: css`
    height: 25px;
    width: 25px;
    background: ${sv.grey200};
    border-radius: ${sv.baseBorderRadius};
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  round: css`
    height: 27px;
    width: 27px;
    border-radius: 100%;
  `,
  background: css`
    background: ${sv.brandGreen};
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-height: 35px;
    min-width: 35px;
    border-radius: 100%;
    transform: scale(0);
    opacity: 0;
    transition: all ${sv.baseTransitionTimeShort} ease-in-out;
  `,
  selected: css`
    transform: scale(1);
    opacity: 1;

    & [data-element="box"] {
      opacity: 1;
    }
  `,
  box: css`
    color: ${sv.white};
    opacity: 0;
    font-size: 0.95rem;
  `,
}


const Check = ({ value, round }) => {
  return (
    <div className={cx(styles.check, { [styles.round]: round })}>
      <div className={cx(styles.background, { [styles.selected]: value })}>
        <div className={styles.box} data-element="box">
          <Icon name="check-bold" />
        </div>
      </div>
    </div>
  );
}


export default Check;
