import React from 'react';
import { connect } from 'react-redux';
import { Icon } from 'react-ittsu/components';
import cn from 'classnames';

import { hideAlert } from 'actions';
import { getAlerts } from 'selectors';

import styles from 'styles/containers/alert';


const closeTimer = 4000;


class Alert extends React.Component {

  state = {
    status: 'hidden',
  };

  constructor(props) {
    super(props);
    this._handleAnimationStart = this._handleAnimationStart.bind(this);
    this._handleAnimationEnd = this._handleAnimationEnd.bind(this);
    this._closeAlert = this._closeAlert.bind(this);
  }

  componentDidMount() {
    const { category } = this.props;
    this.domNode.addEventListener('animationstart', this._handleAnimationStart);
    this.domNode.addEventListener('animationend', this._handleAnimationEnd);
    this.domNode.addEventListener('transitionend', this._handleAnimationEnd);
    setTimeout(() => this.setState({ status: 'visible' }), 0);  // Think about this
    if (category !== 'error') {
      this.autoCloseTimeout = setTimeout(() => this._closeAlert(), closeTimer);
    }
  }

  componentWillUnmount() {
    if (this.autoCloseTimeout) {
      clearTimeout(this.autoCloseTimeout);
    }
  }

  render() {
    const { category, message } = this.props;
    const { status } = this.state;
    const className = cn({
      [styles.visible]: status === 'visible',
    });
    return (
      <div className={className || styles.alert} ref={(ref) => this.domNode = ref}>
        <div className={styles[category]}>
          <Icon name={category === 'success' ? 'check-circle' : 'exclamation-circle'} size="2x" vOffset={0} />
        </div>
        <div className={styles.content}>
          <div>{message}</div>
        </div>
        <div className={styles.action} onClick={this._closeAlert}>
          <Icon name="close-bold" size="lg" vOffset={-1} />
        </div>
      </div>
    );
  }

  _closeAlert() {
    this.setState({ status: 'hidden' });
  }

  _handleAnimationStart(e) {
  }

  _handleAnimationEnd(e) {
    const { status } = this.state;
    const { onClose, id } = this.props;
    if (e.target === this.domNode && status === 'hidden') {
      onClose(id);
    }
  }
}


const Alerts = ({ alerts, hideAlert }) => (
  <div className={styles.alerts}>
    {alerts.slice().sort((a, b) => b.createdAt - a.createdAt).map((a, i) => (
      <Alert key={a.id} category={a.category} message={a.message} id={a.id} onClose={hideAlert} />
    ))}
  </div>
);


const mapStateToProps = (state) => ({
  alerts: getAlerts(state),
});


const mapDispatchToProps = {
  hideAlert,
};


export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
