export default (request, entities, translators) => ({
  create(follower, type) {
    const { item } = follower;
    const data = translators.Follower.toServer(follower);
    if (type === 'project') {
      return request('POST', `estates/${item}/followers`, data)
        .then(translators.Follower.fromServer);
    }
    return request('POST', `items/${item}/followers`, data)
      .then(translators.Follower.fromServer);
  },
  delete(follower, type) {
    const { item } = follower;
    const data = translators.Follower.toServer(follower);
    if (type === 'project') {
      return request('DELETE', `estates/${item}/followers`, data)
        .then(translators.Follower.fromServer);
    }
    return request('DELETE', `items/${item}/followers/${data.id}`, data);
  },
});
