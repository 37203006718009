/**
 * Each pod should expose it's routes, reducer and epic
 * through a single index.js file at the root of the pod.
 */

import dashboard from './dashboard';
import transactions from './transactions';
import profile from './profile';
import studio from './studio';
import * as order from './order';


const podsMain = {
  dashboard,
  transactions,
  profile,
  studio,
  order,
};


const pods = Object.entries(podsMain).reduce((memo, [ name, pod ]) => ({
  ...memo,
  [name]: {
    routes: pod ? pod.routes : null,
    reducer: pod && pod.reducer ? pod.reducer : () => ({}),
    epic: pod ? pod.epic : null,
    resolvers: pod ? pod.resolvers : null,
  },
}), {});


export const podsReducers = Object.keys(pods).reduce((memo, podName) => ({
  ...memo,
  [podName]: pods[podName].reducer,
}), {});


export const podsEpics = Object.keys(pods)
  .map((podName) => pods[podName].epic)
  .filter((epic) => typeof epic === 'function');


export const podsResolvers = Object.keys(pods)
  .map((name) => pods[name].resolvers)
  .filter(Boolean);
