import React, { Fragment } from 'react';
import { Radio, ErrorLabel } from 'react-ittsu/forms';
import autobind from 'autobind-decorator';
import get from 'lodash/get';
import sv from '@drawbotics/style-vars';
import { css, cx } from 'emotion';

import { createTranslate, translate as t } from '~/utils/translation';
import AnswerCell from '../shared/AnswerCell';


const ts = createTranslate('pods.order.services');
const tt = createTranslate('pods.order.components.questions_generator');


const styles = {
  radio: css`
    margin-bottom: ${sv.baseMarginSmall};
  `,
  gallery: css`
    display: flex;

    @media ${sv.phoneLandscape} {
      flex-wrap: wrap;
    }
  `,
  imageContainer: css`
    flex: 1;
    margin-right: ${sv.baseMarginSmall};

    &:last-of-type {
      margin-right: 0;
    }

    @media ${sv.phoneLandscape} {
      flex: auto;
    }
  `,
};


class SingleSelect extends React.Component {
  render() {
    const {
      values,
      answers,
      resultKey,
      modifyAnswer,
      metaComponent,
      estate,
      withErrors,
      required,
    } = this.props;
    const sectionAnswer = get(answers, resultKey);
    const cellsWithDisabled = values.map((answer) => typeof answer.disabled === 'function' ?
      { ...answer, disabled: answer.disabled(answers, estate) } : answer);
    const asGallery = cellsWithDisabled.some((a) => a.image);
    const showError = withErrors && required && sectionAnswer == null;

    return (
      <Fragment>
        <div className={asGallery ? styles.gallery : null}>
          {cellsWithDisabled.map((answer, i) => (
            <div
              key={i}
              className={cx(styles.radio, {
                [styles.imageContainer]: asGallery,
              })}>
              {do {
                if (answer.image) {
                  <AnswerCell
                    answer={answer}
                    selected={sectionAnswer === answer.value}
                    onClick={() => this._onClickCell(answer.value)}
                    position={i}>
                    <img src={answer.image} />
                  </AnswerCell>
                }
                else {
                  <Radio
                    asCheck
                    checked={sectionAnswer === answer.value}
                    onChange={() => this._onClickCell(answer.value)}>
                    {do {
                      if (typeof answer.value === 'boolean') {
                        answer.value ? tt('yes') : tt('no')
                      }
                      else {
                        ts(answer.label, null, answer.label)
                      }
                    }}
                  </Radio>
                }
              }}
            </div>
          ))}
          {do{
            if (metaComponent) {
              <div>
                <metaComponent.type {...metaComponent.props} answers={answers} modifyAnswer={modifyAnswer} />
              </div>
            }
          }}
        </div>
        {do {
          if (showError) {
            <ErrorLabel
              text={t('pods.order.components.briefing_details.field_required')} />
          }
        }}
      </Fragment>
    );
  }

  @autobind
  _onClickCell(value) {
    const { values, modifyAnswer, resultKey, answers } = this.props;
    const sectionAnswer = get(answers, resultKey);
    const inactiveMetaKeys = values.reduce((inactiveKeys, value) =>
      sectionAnswer === value.value ? [ ...inactiveKeys, ...(value.meta?.components.map((c) => c.key) || []) ] : inactiveKeys, []);
    modifyAnswer(inactiveMetaKeys, null, true);
    modifyAnswer(resultKey, value);
  }
}


export default SingleSelect;
