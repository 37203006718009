import React from 'react';
import { Icon, Paragraph } from 'react-ittsu/components';
import { Container } from 'react-ittsu/layout';
import queryString from 'query-string';
import autobind from 'autobind-decorator';
import omit from 'lodash/omit';
import set from 'lodash/set';
import pickBy from 'lodash/pickBy';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';

import Steps from './Steps';
import Sections from './Sections';
import NextButton from './NextButton';
import { getRequiredKeys, canAdvance, filterDescription } from './utils';

import { createTranslate } from '~/utils/translation';


const tt = createTranslate('pods.order.components.questions_generator');


const styles = {
  generator: css`
  `,
  close: css`
    position: absolute;
    top: ${sv.baseMargin};
    right: ${sv.baseMargin};
    color: ${sv.textTertiaryDark};
    transition: all ${sv.baseTransitionTimeShort} ease-in-out;
    z-index: 99;

    &:hover {
      cursor: pointer;
      color: ${sv.textPrimaryDark};
    }

    @media ${sv.phone} {
      top: ${sv.baseMarginSmall};
      right: ${sv.baseMarginSmall};
    }
  `,
  content: css`
    padding: calc(${sv.basePadding} * 2) ${sv.basePadding};
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${sv.phone} {
      margin-top: ${sv.baseMarginSmall};
      padding: ${sv.basePadding} 0;
    }
  `,
  next: css`
    margin-top: ${sv.baseMargin};
  `,
  message: css`
    font-style: italic;
    color: ${sv.textSecondaryDark};
    font-size: 0.9em;
    text-align: center;
  `,
}


class Generator extends React.Component {

  state = {
    activeStepId: 1,
    answers: {},
  }

  componentDidMount() {
    const { history, values, query } = this.props;
    const { activeStepId } = this.state;
    if (values) {
      this.setState({ answers: omit(values, '__typename') });
    }
    const stepFromUrl = query.step;
    history.push('?' + queryString.stringify({ step: stepFromUrl || activeStepId }));
  }

  componentWillUnmount() {
    const { history } = this.props;
    history.replace({});
  }

  componentDidUpdate(prevProps) {
    const { activeStepId } = this.state;
    const { query, onClickClose } = this.props;
    const { query: prevQuery } = prevProps;
    const step = parseInt(query.step);
    if (query.step !== prevQuery.step && ! step) {
      onClickClose();
    }
    if (step !== activeStepId && step > 0) {
      this.setState({ activeStepId: step });
    }
  }

  render() {
    const { onClickClose, onFinish, description: rawDescription, components, estate, completed } = this.props;
    const { activeStepId, answers } = this.state;
    const description = filterDescription(rawDescription, estate);
    const totalSteps = Object.keys(description).length;
    const requiredKeys = getRequiredKeys(description[activeStepId].components, answers, estate);
    const enabledNext = canAdvance(requiredKeys, answers);
    return (
      <div className={styles.generator}>
        <div className={styles.close} onClick={onClickClose}>
          <Icon name="close-bold" size="2x" />
        </div>
        <Container>
          <div className={styles.content}>
            <Steps
              goTo={enabledNext && completed ? this._goTo : null}
              steps={description}
              activeStep={activeStepId} />
            <Sections
              key={activeStepId}
              components={components}
              activeStep={activeStepId}
              sections={description}
              estate={estate}
              answers={answers}
              save={this._save}
              modifyAnswer={this._handleModifyAnswer} />
            <div className={styles.next}>
              {do{
                if (activeStepId === totalSteps) {
                  <NextButton onClick={() => onFinish(answers)} disabled={! enabledNext}>
                    {tt('finish')}
                  </NextButton>
                }
                else {
                  <NextButton onClick={this._handleClickNext} disabled={! enabledNext}>
                    {tt('next')}
                  </NextButton>
                }
              }}
            </div>
            {do{
              if (! enabledNext) {
                <Paragraph className={styles.message}>{tt('answer_all_questions')}</Paragraph>
              }
            }}
          </div>
        </Container>
      </div>
    );
  }

  @autobind
  _goTo(index) {
    const { history } = this.props;
    history.push('?' + queryString.stringify({ step: index }));
  }

  @autobind
  _handleClickNext() {
    const { history, generatorElement } = this.props;
    const { activeStepId } = this.state;
    generatorElement.scrollTop = 0;
    this._save();
    history.push('?' + queryString.stringify({ step: activeStepId + 1 }));
  }

  @autobind
  async _save() {
    const { onGoNext } = this.props;
    const { answers } = this.state;
    const filteredAnswers = pickBy(answers, (value) => value != null);
    await onGoNext(filteredAnswers);
  }

  @autobind
  _handleModifyAnswer(key, value, remove=false) {
    if (remove) {
      this.setState((prevState) => ({
        answers: omit(prevState.answers, key instanceof Array ? key : [key]),
      }));
    }
    else {
      this.setState((prevState) => ({
        answers: set(prevState.answers, key, value),
      }));
    }
  }

}


export default Generator;
