/* eslint-disable */
import get from 'lodash/get';
import last from 'lodash/last';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';

import { doOperation } from './operations';


function priceGroup(object, groupDescription, relativeDescriptions, variation) {
  return groupDescription.reduce((memo, operation) => {
    return memo + doOperation(object, operation, relativeDescriptions, variation);
  }, 0);
}


function applyRelativeAdd(groupTotal, relativeDescriptions) {
  return relativeDescriptions.reduce((memo, description) => {
    const { coefficient } = description;
    return memo + groupTotal * coefficient;
  }, groupTotal);
}


export function price(object, description, variation, ignoreGroups=[]) {
  if (object == null || description == null) {
    return 0;
  }

  const relativeDescriptions = [];
  if (Array.isArray(description)) {
    return Math.round(priceGroup(object, description, relativeDescriptions, variation));
  }
  else {
    const groups = mapValues(omit(description, ignoreGroups), (groupDescription) => {
      return priceGroup(object, groupDescription, relativeDescriptions, variation);
    });
    const total = Object.keys(groups).reduce((memo, groupName) => {
      const groupTotal = groups[groupName];
      const groupAdds = relativeDescriptions.filter((x) => x.base.includes(groupName));
      return memo + applyRelativeAdd(groupTotal, groupAdds);
    }, 0);
    return Math.round(total);
  }
}
