import morphey, { fromKey } from 'morphey';

import { userFromServer } from './user';


const translationsFromServer = (i) => ({
  id: 'id',
  transactionType: 'transaction_type',
  status: 'status',
  vat: 'vat',
  vatValidated: 'vat_validated',
  amount: 'amount',
  currency: 'currency_code',
  createdAt: 'created_at',
  sentAt: 'sent_at',
  priceWithTaxes: 'price_with_taxes',
  invoiceFileName: 'invoice_file_name',
  invoicePublicUrl: 'url',
  invoiceReference: 'reference',
  internalReference: 'client_reference',
  buyer: fromKey('buyer').using(() => userFromServer(i.buyer)),
});


export function invoiceFromServer(invoice) {
  return morphey(invoice, translationsFromServer);
}


export default {
  fromServer: invoiceFromServer,
};
