import get from 'lodash/get';


export function isFooterShown(state) {
  return get(state, 'ui.parts.showFooter', true);
}


export function isHeaderShown(state) {
  return get(state, 'ui.parts.showHeader', true);
}


export function isFullWidthNavbarInUse(state) {
  return get(state, 'ui.parts.useFullWidthNavbar', false);
}
