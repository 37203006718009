import en from './build/en.yml';
import fr from './build/fr.yml';
import nl from './build/nl.yml';


export default {
  en: en.en,
  fr: fr.fr,
  nl: nl.nl,
};
