import morphey, { fromKey } from 'morphey';


function getName(isCustomService, id) {
  return (name) => {
    if (isCustomService) {
      return `custom-service_${id}`;
    }
    switch (name) {
      case 'visit3_d':
        return 'visit3d';
      case 'commercial_floorplan':
        return 'commercialFloorplan';
      case 'photo-edit':
        return 'photoEditing';
      case 'exterior-restyling':
        return 'exteriorRestyling';
      case 'brand id':
        return 'branding';
      case 'brand_id':
        return 'branding';
      default:
        return name;
    }
  };
}


function getPath(isCustomService, id) {
  return (path) => {
    if (isCustomService) {
      return `custom-service_${id}`;
    }
    switch (path) {
      case 'visit3_d':
        return 'visit3d';
      case 'commercial_floorplan':
        return 'commercial-floorplan';
      case 'photo-edit':
        return 'photo-editing';
      case 'brand id':
        return 'branding';
      case 'brand_id':
        return 'branding';
      default:
        return path;
    }
  };
}


export function translateProductSlug(legacyName) {
  if (legacyName && legacyName.startsWith('custom_service')) {
    return 'custom-service';
  }
  switch (legacyName) {
    case 'plan2_d_standard':
      return 'plan2d';
    case 'interior3_d_standard':
      return 'interior3d';
    case 'exterior3_d_premium':
      return 'exterior3d';
    case 'restyling_standard':
      return 'restyling';
    case 'visit3_d':
      return 'visit3d';
    case 'brand_id':
      return 'branding';
    case 'commercial_floorplan':
      return 'commercialFloorplan';
    case 'photo_standard':
      return 'photo';
    case 'plan3_d_premium':
      return 'shoebox';
    case 'exterior_restyling':
      return 'exteriorRestyling';
    default:
      return legacyName;
  }
}


function camelize(str) {
  return str
    .replace(/(\d)(_)/g, '$1')
    .replace(/(_)(\S{1})/g, (match, a, b) => b.toUpperCase());
}


export function translateServiceSlug(slug) {
  return camelize(slug);
}


export function translateLegacyName(legacyName) {
  if (legacyName && legacyName.startsWith('custom_service')) {
    return legacyName.replace('custom_service', 'custom-service');
  }
  switch (legacyName) {
    case 'plan2_d_standard':
      return 'plan2_d';
    case 'interior3_d_standard':
      return 'interior3_d';
    case 'exterior3_d_premium':
      return 'exterior3_d';
    case 'restyling_standard':
      return 'restyling';
    case 'photo_standard':
      return 'photo';
    case 'plan3_d_premium':
      return 'plan3_d';
    default:
      return legacyName;
  }
}


const translationsFromServer = (s) => ({
  available: 'available',
  category: 'category',
  durations: fromKey('durations').using((v) => Object.keys(v).reduce((memo, k) => ({
    ...memo,
    [k]: {
      preview: v[k].preview,
      correction: v[k].preview + v[k].correction,
      final: v[k].preview + v[k].correction + v[k].final,
    },
  }), {})),
  legacyName: 'id_name',
  isQuickService: 'is_quick_service',
  isCustomService: 'is_custom_product',
  link: 'link',
  price: 'price',
  pricing: 'pricing',
  id: 'product_id',
  title: 'path',
  'notes.description': 'descriptions',
  'notes.attachments': 'attachments',
  name: { fromKey: 'path', transform: getName(s.is_custom_product, s.product_id) },
  path: { fromKey: 'path', transform: getPath(s.is_custom_product, s.product_id) },
});


export function serviceFromServer(service) {
  return morphey(service, translationsFromServer);
}


export function servicesFromServer(services) {
  return services.map(s => serviceFromServer);
}


export function getServiceNameFromId(serviceId, products=[]) {
  const service = products.find(product => String(product.product_id) === String(serviceId));
  return service && getName(service.is_custom_product, service.product_id)(service.path);
}
