import React from 'react';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';


const styles = {
  errorMessage: css`
    text-align: center;
    margin: ${sv.baseMargin} 0;
    color: ${sv.white};
    background: ${sv.brandRed};
    font-weight: 600;
    padding: ${sv.basePaddingSmall};
    border-radius: ${sv.baseBorderRadius};
    border: 1px solid rgba(0, 0, 0, 0.2);

    @media ${sv.phoneXl} {
      font-size: 0.8rem;
    }
  `,
};


const ErrorMessage = ({ children }) => {
  return (
    <div className={styles.errorMessage}>
      {children}
    </div>
  );
};


export default ErrorMessage;
