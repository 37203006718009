export const START_LOADING = '@@LOADING-MANAGER/START_LOADING';

export function startLoading(key) {
  return {
    type: START_LOADING,
    payload: { key },
  };
}


export const STOP_LOADING = '@@LOADING-MANAGER/STOP_LOADING';

export function stopLoading(key) {
  return {
    type: STOP_LOADING,
    payload: { key },
  };
}
