import React from 'react';
import { Container } from 'react-ittsu/layout';
import { Link } from 'react-ittsu/components';

import { translate as t } from 'utils/translation';

import styles from 'styles/containers/footer';


export function getTranslatedLink(link) {
  const locale = window.i18n.locale;
  switch (link) {
    case 'terms-and-conditions': {
      if (locale === 'fr') {
        return 'https://www.drawbotics.com/fr/termes-et-conditions';
      }
      else if (locale === 'nl') {
        return 'https://www.drawbotics.com/nl/algeme-voorwaarden';
      }
      else {
        return 'https://www.drawbotics.com/en/terms-and-conditions';
      }
    }
    case 'privacy-policy': {
      if (locale === 'fr') {
        return 'https://www.drawbotics.com/fr/politique-de-confidentialite';
      }
      else if (locale === 'nl') {
        return 'https://www.drawbotics.com/nl/privacy-policy';
      }
      else {
        return 'https://www.drawbotics.com/en/privacy-policy';
      }
    }
    case 'services': {
      if (locale === 'fr') {
        return 'https://www.drawbotics.com/fr/services';
      }
      else if (locale === 'nl') {
        return 'https://www.drawbotics.com/nl/diensten';
      }
      else {
        return 'https://www.drawbotics.com/en/services';
      }
    }
  }
}


const FooterContent = () => {
  return (
    <div className={styles.footer}>
      <Container useBottomPadding useTopPadding className={styles.container}>
        <div className={styles.info}>
          <div className={styles.copyright}>
            {`© ${new Date().getFullYear()} Drawbotics, Inc.`}
          </div>
          <div className={styles.links}>
            <span className={styles.link}>
              <Link target="_blank" href={getTranslatedLink('terms-and-conditions')}>{t('containers.footer_content.legal')}</Link>
            </span>
            <span className={styles.link}>
              <Link target="_blank" href={getTranslatedLink('privacy-policy')}>{t('containers.footer_content.privacy')}</Link>
            </span>
            <span className={styles.link}>
              <Link target="_blank" href={getTranslatedLink('services')}>{t('containers.footer_content.products')}</Link>
            </span>
          </div>
        </div>
      </Container>
    </div>
  );
}


export default FooterContent;
