import sv from '@drawbotics/style-vars';
import cn from 'classnames';
import { css } from 'emotion';
import gql from 'fraql';
import compose from 'lodash/flowRight';
import React, { useEffect, useState } from 'react';
import { graphql } from 'react-apollo';
import { Icon, Link as IttsuLink } from 'react-ittsu/components';
import { Checkbox } from 'react-ittsu/forms';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { changeCurrency } from '~/actions/session';
import { getTranslatedLink } from '~/containers/FooterContent';
import Address from '~/pods/order/components/Address';
import MainPanel from '~/pods/order/components/MainPanel';
import Title from '~/pods/order/components/Title';
import { getSessionUser } from '~/selectors';
import { currencyWithSymbol, run } from '~/utils';
import { createTranslate } from '~/utils/translation';

import { UnverifiedUserModal } from '../components/UnverifiedUserModal';
import { ApolloMutation, ID } from '../types';
import { PaymentProcess } from './Payment/Component';

const tt = createTranslate('pods.payments.routes.synthesis');

export const PayWithSalesOrderMutation = gql`
  mutation PayWithSalesOrder($estateId: ID!, $transactionId: ID!) {
    payload: payWithSalesOrder(input: { estateId: $estateId, transactionId: $transactionId }) {
      salesOrder {
        id
      }
    }
  }
`;

const styles = {
  reviewYourOrder: css`
    color: ${sv.textPrimaryDark};
    margin-bottom: ${sv.baseMargin};
  `,
  section: css`
    display: flex;
    align-items: flex-start;
    margin-bottom: ${sv.baseMargin};
    color: ${sv.textPrimaryDark};

    @media ${sv.phoneXl} {
      flex-direction: column;
    }
  `,
  label: css`
    width: 40%;
    font-size: 1rem;

    @media ${sv.phoneXl} {
      width: auto;
      margin-bottom: ${sv.baseMarginSmall};
    }
  `,
  checkmark: css`
    width: 20px;
    height: 20px;
    border-radius: 1000px;
    font-size: 0.7rem;
    background: ${sv.brandGreen};
    margin-right: 5px;
    color: ${sv.white};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
  `,
  value: css`
    width: 60%;

    @media ${sv.phoneXl} {
      width: 100%;
    }
  `,
  paymentTerms: css`
    font-size: 0.9rem;
    line-height: 1.4em;

    & > span {
      display: block;
      margin-bottom: 15px;
    }
  `,
  termsAndConditions: css`
    color: ${sv.textPrimaryDark};
    margin-top: calc(${sv.baseMargin} * 2);
    margin-bottom: ${sv.baseMarginSmall};
    font-size: 0.9em;

    @media ${sv.phoneXl} {
      margin-top: 0;
    }
  `,
};

const Checkmark = () => {
  return (
    <div className={styles.checkmark}>
      <Icon name="check-bold" />
    </div>
  );
};

interface PaymentSynthesisProps extends RouteComponentProps {
  goBack: VoidFunction;
  paymentProcess: PaymentProcess;
  changeCurrency: (...args: Parameters<typeof changeCurrency>) => void;
  refetch: () => Promise<void>;
  payWithSalesOrder: ApolloMutation<{ estateId: ID; transactionId: ID }, {}>;
}

const PaymentSynthesis = ({
  goBack,
  paymentProcess,
  refetch,
  changeCurrency,
  payWithSalesOrder,
  history,
}: PaymentSynthesisProps) => {
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [confirmingPayment, setConfirmingPayment] = useState(false);
  const [unverifiedUserModalVisible, setUnverifiedUserModalVisible] = useState(false);

  const _goNext = (transactionToken: string) => {
    history.push(`/order/transaction/${transactionToken}/settle`);
  };

  useEffect(() => {
    if (paymentProcess.status !== 'INITIATED') {
      _goNext(paymentProcess.downPaymentTransaction.transactionToken);
    }
  });

  useEffect(() => {
    const { currency } = paymentProcess.downPaymentTransaction;
    if (currency != window.userCurrency.code) {
      changeCurrency(currencyWithSymbol(currency));
      refetch();
    }
  }, []);

  const _confirmPayment = async () => {
    setConfirmingPayment(true);

    const { id: transactionId, transactionToken } = paymentProcess.downPaymentTransaction;

    if (paymentProcess.salesOrder) {
      const estateId = paymentProcess.estate.id;
      const { errors } = await payWithSalesOrder({ variables: { estateId, transactionId } });
      if (errors != null) {
        if (errors[0].message === '#user_not_verified') {
          setUnverifiedUserModalVisible(true);
        }
        return;
      }
    }

    _goNext(transactionToken);
  };

  return (
    <MainPanel
      displaySuccess
      disableNext={!acceptTerms}
      loadingNext={confirmingPayment}
      onClickBack={confirmingPayment ? undefined : goBack}
      onClickNext={_confirmPayment}>
      <Title>{tt('title')}</Title>
      <div className={styles.section}>
        <div className={styles.label}>
          <Checkmark /> {tt('invoicing_address')}
        </div>
        <div className={styles.value}>
          <Address noRadioButton address={paymentProcess.address} />
        </div>
      </div>
      {paymentProcess.salesOrder?.clientReference != null ? (
        <div className={styles.section}>
          <div className={styles.label}>
            <Checkmark /> {tt('internal_reference')}
          </div>
          <div className={styles.value}>{paymentProcess.salesOrder.clientReference}</div>
        </div>
      ) : null}
      {run(() => {
        if (paymentProcess.salesOrder != null) {
          const { daysUntilPayment, downPaymentPercentage } = paymentProcess.salesOrder;
          return (
            <div className={styles.section}>
              <div className={styles.label}>
                <Checkmark /> {tt('payment_terms.title')}
              </div>
              <div className={cn(styles.value, styles.paymentTerms)}>
                <span>
                  - {tt('payment_terms.terms.no_vat')} <br />
                </span>
                <span>
                  - {tt('payment_terms.terms.additional_costs')} <br />
                </span>
                {run(() => {
                  if (daysUntilPayment != null) {
                    return (
                      <span>
                        -{' '}
                        {tt('payment_terms.terms.invoices_must_be_paid_within', {
                          days: daysUntilPayment,
                        })}{' '}
                        <br />
                      </span>
                    );
                  } else {
                    return (
                      <span>
                        - {tt('payment_terms.terms.invoices_must_be_paid_inmediately')} <br />
                      </span>
                    );
                  }
                })}
                <span>
                  - {tt('payment_terms.terms.down_payment', { percentage: downPaymentPercentage })}{' '}
                  <br />
                </span>
                {100 - downPaymentPercentage > 0 ? (
                  <span>
                    -{' '}
                    {tt('payment_terms.terms.final_payment', {
                      percentage: 100 - downPaymentPercentage,
                    })}{' '}
                    <br />
                  </span>
                ) : null}
              </div>
            </div>
          );
        }
      })}
      <div className={styles.termsAndConditions}>
        <Checkbox value={acceptTerms} onChange={(acceptTerms: any) => setAcceptTerms(acceptTerms)}>
          {`${tt('tos_pre')} `}
          <IttsuLink
            underlined="always"
            target="_blank"
            href={getTranslatedLink('terms-and-conditions')}>
            {tt('tos')}
          </IttsuLink>
          {` ${tt('tos_post')}`}
        </Checkbox>
      </div>
      <UnverifiedUserModal
        navigateToDashboard={() => history.push('/')}
        onClickClose={() => {
          setUnverifiedUserModalVisible(false);
          setConfirmingPayment(false);
        }}
        visible={unverifiedUserModalVisible}
      />
    </MainPanel>
  );
};

PaymentSynthesis.fragments = {
  Address: () => gql`
      fragment _ on Address {
        id
        main
        ${Address.fragments.Address}
      }
    `,
  PaymentProcess: () => gql`
      fragment _ on PaymentProcess {
        id
        address {
          ${PaymentSynthesis.fragments.Address()}
        }
        estate: orderEstate {
          id
        }
        downPaymentTransaction {
          id
          transactionToken: accessToken
        }
        salesOrder {
          id
          clientReference
          daysUntilPayment
          downPaymentPercentage
        }
      }
    `,
};

const mapStateToProps = (state: any) => ({
  sessionUser: getSessionUser(state),
});

const mapDispatchToProps = {
  changeCurrency,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql(PayWithSalesOrderMutation, { name: 'payWithSalesOrder' }),
)(PaymentSynthesis);
