export function doAdd(object, add, next, variation) {
  const { value } = add;
  if (typeof value === 'object' && variation) {
    return value[variation];
  }
  return value;
}


export function Add(value) {
  return {
    operation: 'add',
    add: { value },
  };
}
