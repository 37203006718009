import React from 'react';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';

import { createTranslate } from '~/utils/translation';


const t = createTranslate('pods.order.services');


const styles = {
  emails: css`
    margin: ${sv.baseMarginSmall} 0;
  `,
  title: css`
    font-family: ${sv.baseFontFamilyAlt};
    text-transform: uppercase;
    color: ${sv.textPrimaryDark};
    font-weight: 600;
    font-size: 0.65rem;
  `,
  emailList: css`
    margin-top: ${sv.baseMarginSmall};
  `,
  item: css`
    padding: 5px 0;
    color: ${sv.brandBlue};
    font-size: 0.9em;
    white-space: pre;
    border-bottom: ${sv.grey100};

    &:last-child {
      border-bottom: none;
    }
  `,
};


const Emails = ({ emails, title }) => {
  return (
    <div className={styles.emails}>
      <div className={styles.title}>
        {t(title)}
      </div>
      <div className={styles.emailList}>
        {emails.map((email) => (
          <div key={email} className={styles.item}>
            {email}
          </div>
        ))}
      </div>
    </div>
  );
}


export default Emails;
