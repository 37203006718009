import sv, { fade } from '@drawbotics/drylus-style-vars';
import {
  Dot,
  Flex,
  FlexAlign,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Icon,
  IconType,
  Icons,
  ListTile,
  Margin,
  Padding,
  Shade,
  Size,
  Text,
} from '@drawbotics/react-drylus';
import dayjs, { Dayjs } from 'dayjs';
import { css, cx } from 'emotion';
import { flow, last } from 'lodash';
import React, { ReactNode, useState } from 'react';

import { Item, ItemStatus, Milestone, MilestoneName } from '~/pods/dashboard/types';
import { statusToColor } from '~/pods/dashboard/utils';
import { createTranslate, translate as t } from '~/utils/translation';

const tt = createTranslate('pods.dashboard.routes.item');

const styles = {
  timeline: css``,
  timelineItem: css`
    position: relative;
    margin-bottom: ${sv.marginLarge};

    &:last-child {
      margin-bottom: 0;
    }

    &:not(:last-child) {
      &::after {
        content: '';
        position: absolute;
        top: ${sv.defaultMargin};
        left: ${sv.marginExtraSmall};
        width: 2px;
        height: 100%;
        background: ${sv.neutral};
      }
    }
  `,
  content: css`
    padding: ${sv.paddingExtraSmall};
    margin: -${sv.marginExtraSmall};
    margin-left: 0;
    border-radius: ${sv.defaultBorderRadius};
  `,
  togglable: css`
    &:hover {
      cursor: pointer;
      background: ${fade(sv.neutral, 50)};
    }
  `,
  disabled: css`
    opacity: 0.3;
    pointer-events: none;
  `,
};

const BRIEFING_GROUP_BOUNDARIES = {
  lower: [MilestoneName.BRIEFING_COMPLETED],
  upper: [MilestoneName.ACCEPTED],
};

const PREVIEW_GROUP_BOUNDARIES = {
  lower: [MilestoneName.PREVIEW_READY],
  upper: [
    MilestoneName.PROBLEM_SOLVED,
    MilestoneName.PREVIEW_REJECTED,
    MilestoneName.PREVIEW_VALIDATED,
  ],
};

interface TimelineMilestone {
  id: string;
  rawMilestone?: Milestone;
  icon?: IconType;
  color?: string;
  title: string;
  date?: Dayjs;
  asStatus?: boolean;
}

interface MilestoneGroup extends TimelineMilestone {
  children?: Array<TimelineMilestone>;
}

function _getIconFromName(name: string): IconType | undefined {
  if (name.includes('briefing')) {
    return Icons.briefing;
  }
  if (name === MilestoneName.PROBLEM_DECLARED) {
    return Icons.xCircle;
  }
  if (name.includes('preview')) {
    return Icons.conversation;
  }
  if (name === MilestoneName.HALTED) {
    return Icons.pauseCircle;
  }
  if (name === MilestoneName.UNHALTED) {
    return Icons.playCircle;
  }
  if (name === MilestoneName.ARCHIVED) {
    return Icons.folder;
  }
  if (name === MilestoneName.CANCELLED) {
    return Icons.slash;
  }
  if (name === MilestoneName.REOPENED) {
    return Icons.refreshCcw;
  }
  if (
    name === MilestoneName.ACCEPTED ||
    name === MilestoneName.CONFIRMED ||
    name === MilestoneName.PROBLEM_SOLVED
  ) {
    return Icons.checkCircle;
  }
  if (name === MilestoneName.ORDERED) {
    return Icons.order;
  }
  if (name === MilestoneName.FINISHED) {
    return Icons.box;
  }
}

function _milestoneBelongsInGroup(milestone: TimelineMilestone): boolean {
  if (milestone.rawMilestone?.name == null) {
    return false;
  }
  return [
    ...BRIEFING_GROUP_BOUNDARIES.upper,
    ...BRIEFING_GROUP_BOUNDARIES.lower,
    ...PREVIEW_GROUP_BOUNDARIES.upper,
    ...PREVIEW_GROUP_BOUNDARIES.lower,
  ].includes(milestone.rawMilestone?.name);
}

function _filterMilestones(milestones: Array<Milestone>): Array<Milestone> {
  return milestones.filter((m) => !m.name.includes('estate') && m.name !== MilestoneName.CONFIRMED);
}

function _sortMilestones(milestones: Array<Milestone>): Array<Milestone> {
  return [...milestones].sort((a, b) =>
    a.date == null ? 1 : b.date == null ? -1 : dayjs(a.date).diff(dayjs(b.date)),
  );
}

function _addMilestones(
  milestones: Array<TimelineMilestone>,
  item: Item,
): Array<TimelineMilestone> {
  let extraMilestones: Array<TimelineMilestone> = [];
  if (
    milestones.find((m) => m.rawMilestone?.name === MilestoneName.FINISHED) == null &&
    milestones.find((m) => m.rawMilestone?.name === MilestoneName.REOPENED) == null
  ) {
    extraMilestones.push({
      id: `${Math.random()}`,
      title: tt('delivered'),
      icon: 'box',
    });
  }
  if (
    milestones.find((m) => m.rawMilestone?.name === MilestoneName.PREVIEW_VALIDATED) == null &&
    milestones.find((m) => m.rawMilestone?.name === MilestoneName.FINISHED) == null &&
    milestones.find((m) => m.rawMilestone?.name === MilestoneName.REOPENED) == null
  ) {
    extraMilestones.push({
      id: `${Math.random()}`,
      title: tt('validated'),
      icon: 'check-circle',
    });
  }
  if (
    item.status !== ItemStatus.FINISHED &&
    item.status !== ItemStatus.ARCHIVED &&
    item.status !== ItemStatus.CANCELLED
  ) {
    extraMilestones.push({
      id: `${Math.random()}`,
      date: dayjs(),
      title: t(`pods.dashboard.status.${item.status}.title`),
      color: statusToColor(item.status),
      asStatus: true,
    });
  }
  extraMilestones.push(...milestones);
  return extraMilestones;
}

function _groupMilestones(milestones: Array<TimelineMilestone>): Array<MilestoneGroup> {
  let groups: Array<MilestoneGroup> = [];
  let correctionRoundCount = 1;

  for (const milestone of milestones) {
    const lastGroup = last(groups);
    const lastGroupedMilestoneName = last(lastGroup?.children)?.rawMilestone?.name;
    const firstGroupedMilestoneName = lastGroup?.children?.[0].rawMilestone?.name;
    const currentMilestoneName = milestone.rawMilestone?.name;

    const isBriefingGroupClosed =
      lastGroupedMilestoneName != null &&
      firstGroupedMilestoneName != null &&
      BRIEFING_GROUP_BOUNDARIES.upper.includes(lastGroupedMilestoneName) &&
      BRIEFING_GROUP_BOUNDARIES.lower.includes(firstGroupedMilestoneName);

    const isPreviewGroupClosed =
      lastGroupedMilestoneName != null &&
      firstGroupedMilestoneName != null &&
      PREVIEW_GROUP_BOUNDARIES.upper.includes(lastGroupedMilestoneName) &&
      PREVIEW_GROUP_BOUNDARIES.lower.includes(firstGroupedMilestoneName);

    // milestone comes after a closed group
    if (
      !_milestoneBelongsInGroup(milestone) &&
      (lastGroupedMilestoneName == null || isBriefingGroupClosed || isPreviewGroupClosed)
    ) {
      groups.push(milestone);
      continue;
    }
    // milestone is the first of the briefing group
    if (
      currentMilestoneName != null &&
      BRIEFING_GROUP_BOUNDARIES.lower.includes(currentMilestoneName)
    ) {
      const milestoneGroup: MilestoneGroup = {
        id: `${Math.random()}`,
        date: milestone.date,
        title: t('pods.dashboard.milestones.briefing_validated.title'),
        icon: Icons.briefing,
        children: [milestone],
      };
      groups.push(milestoneGroup);
      continue;
    }
    // milestone is the first of the preview group
    if (
      currentMilestoneName != null &&
      PREVIEW_GROUP_BOUNDARIES.lower.includes(currentMilestoneName)
    ) {
      const milestoneGroup: MilestoneGroup = {
        id: `${Math.random()}`,
        date: milestone.date,
        title: tt('correction_round', { count: correctionRoundCount }),
        icon: Icons.conversation,
        children: [milestone],
      };
      correctionRoundCount += 1;
      groups.push(milestoneGroup);
      continue;
    }

    // milestone is the last of briefing group
    if (
      currentMilestoneName != null &&
      lastGroup?.children != null &&
      firstGroupedMilestoneName != null &&
      BRIEFING_GROUP_BOUNDARIES.upper.includes(currentMilestoneName) &&
      BRIEFING_GROUP_BOUNDARIES.lower.includes(firstGroupedMilestoneName)
    ) {
      lastGroup.children.push(milestone);
      lastGroup.date = milestone.date;
      continue;
    }

    // milestone is the last of preview group
    if (
      currentMilestoneName != null &&
      lastGroup?.children != null &&
      firstGroupedMilestoneName != null &&
      PREVIEW_GROUP_BOUNDARIES.upper.includes(currentMilestoneName) &&
      PREVIEW_GROUP_BOUNDARIES.lower.includes(firstGroupedMilestoneName)
    ) {
      lastGroup.children.push(milestone);
      lastGroup.date = milestone.date;
      continue;
    }

    // if milestone is the status, and the previous group is not closed, it needs to replace the previous group root
    if (
      milestone.asStatus &&
      lastGroupedMilestoneName != null &&
      ![...PREVIEW_GROUP_BOUNDARIES.upper, ...BRIEFING_GROUP_BOUNDARIES.upper].includes(
        lastGroupedMilestoneName,
      )
    ) {
      const _date = lastGroup?.date;
      const _children = lastGroup?.children;
      Object.assign(lastGroup, milestone);
      if (lastGroup != null) {
        lastGroup.icon = undefined;
        lastGroup.date = _date;
        lastGroup.children = _children;
      }
      continue;
    }

    // milestone is not linked to any existing milestone
    if (milestone.rawMilestone == null) {
      groups.push(milestone);
      continue;
    }

    // milestone is within a group boundary
    if (lastGroup?.children != null) {
      lastGroup.children.push(milestone);
      continue;
    }

    // if nothing else matches, just add it to the outer timeline
    groups.push(milestone);
  }
  return groups;
}

function _formatMilestones(milestones: Array<Milestone>): Array<TimelineMilestone> {
  return milestones.map((m) => ({
    id: m.id,
    title: t(`pods.dashboard.milestones.${m.name}.title`),
    date: dayjs(m.date),
    icon: _getIconFromName(m.name),
    color: m.inducedState != null ? statusToColor(m.inducedState) : undefined,
    rawMilestone: m,
  }));
}

function _generateMilestones(milestones: Array<Milestone>, item: Item): Array<MilestoneGroup> {
  return flow(
    _filterMilestones,
    _formatMilestones,
    (m) => _addMilestones(m, item),
    _sortMilestones,
    _groupMilestones,
  )(milestones);
}

interface TimelineItemProps {
  leading: ReactNode;
  title: string;
  subtitle?: string;
  disabled?: boolean;
  children?: ReactNode;
}

const TimelineItem = ({ leading, title, subtitle, disabled, children }: TimelineItemProps) => {
  const [isChildrenVisible, setIsChildrenVisible] = useState(false);
  return (
    <div
      className={cx(styles.timelineItem, {
        [styles.disabled]: disabled,
      })}>
      <Flex align={FlexAlign.START} justify={FlexJustify.START}>
        <FlexItem>
          {subtitle != null ? <Margin size={{ bottom: Size.SMALL }}>{leading}</Margin> : leading}
        </FlexItem>
        <FlexSpacer size={Size.EXTRA_SMALL} />
        <FlexItem flex>
          <div
            onClick={() => setIsChildrenVisible(!isChildrenVisible)}
            className={cx(styles.content, { [styles.togglable]: children != null })}>
            <Flex justify={FlexJustify.SPACE_BETWEEN} align={FlexAlign.START}>
              <FlexItem>
                <Text bold>{title}</Text>
                {subtitle != null ? (
                  <Margin size={{ top: Size.EXTRA_SMALL }}>
                    <Text
                      dateOptions={{ format: 'D MMM YYYY, H:mm' }}
                      size={Size.SMALL}
                      shade={Shade.MEDIUM}>
                      {subtitle}
                    </Text>
                  </Margin>
                ) : null}
              </FlexItem>
              {children != null ? (
                <FlexItem>
                  <Icon
                    shade={Shade.MEDIUM}
                    name={isChildrenVisible ? 'chevron-up' : 'chevron-down'}
                  />
                </FlexItem>
              ) : null}
            </Flex>
            {isChildrenVisible ? children : null}
          </div>
        </FlexItem>
      </Flex>
    </div>
  );
};

interface TimelineProps {
  item: Item;
}

export const Timeline = ({ item }: TimelineProps) => {
  const milestones = _generateMilestones(item.milestones, item);
  return (
    <div className={styles.timeline}>
      <Margin size={{ vertical: Size.DEFAULT, horizontal: Size.SMALL }}>
        {[...milestones].reverse().map((m, i) => {
          return (
            <TimelineItem
              key={m.id}
              disabled={m.date == null || m.date.isAfter(dayjs())}
              leading={
                m.icon != null ? (
                  <Icon name={m.icon} shade={Shade.LIGHT} />
                ) : (
                  <Dot color={m.color} size={Size.EXTRA_LARGE} />
                )
              }
              title={m.title}
              subtitle={
                m.asStatus
                  ? tt('since', {
                      count: Math.abs(
                        dayjs((m.children != null ? last(m.children) : milestones[i])?.date).diff(
                          new Date(),
                          'day',
                        ),
                      ),
                    })
                  : m.date?.toDate()
              }>
              {m.children ? (
                <Padding size={{ top: Size.DEFAULT }}>
                  {m.children.reverse().map((subMilestone) => (
                    <div key={subMilestone.id} className={styles.timelineItem}>
                      <Margin size={{ bottom: Size.SMALL }}>
                        <ListTile
                          leading={
                            <Margin style={{ marginLeft: 5 }} size={{ bottom: Size.SMALL }}>
                              <Dot color={subMilestone.color} />
                            </Margin>
                          }
                          title={<Text bold>{subMilestone.title}</Text>}
                          subtitle={
                            subMilestone.date != null ? (
                              <Text
                                dateOptions={{ format: 'D MMM YYYY, H:mm' }}
                                size={Size.SMALL}
                                shade={Shade.MEDIUM}>
                                {subMilestone.date.toDate()}
                              </Text>
                            ) : null
                          }
                        />
                      </Margin>
                    </div>
                  ))}
                </Padding>
              ) : null}
            </TimelineItem>
          );
        })}
      </Margin>
    </div>
  );
};
