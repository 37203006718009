import React from 'react';
import { Grid, Cell } from 'react-ittsu/layout';
import { Icon, Button } from 'react-ittsu/components';
import PropTypes from 'prop-types';
import gql from 'fraql';
import { css, keyframes } from 'emotion';
import sv from '@drawbotics/style-vars';
import autobind from 'autobind-decorator';
import compose from 'lodash/flowRight';
import { connect } from 'react-redux';

import BriefingCard from '../components/BriefingCard';
import CopyFromPreviousModal from '../components/CopyFromPreviousModal';
import { getItemsByService, deepOmit } from '../utils';
import Title from '../components/Title';
import { translate as t, createTranslate } from '~/utils/translation';
import { showAlert } from '~/actions';

import serviceImages from '~/images/services-illustrations';
import ItemDetails from '../utils/briefing/item-details';


const tt = createTranslate('pods.order.routes.briefing_service');


const slideUp = keyframes`
  0% {
    transform: translateY(10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const styles = {
  container: css`
    position: relative;
    width: 100%;
    max-width: 1200px;
    flex: 1;
    display: flex;
    margin: auto;
    margin-top: calc(${sv.baseMargin} * 2);
    padding: 0 ${sv.basePaddingSmall};

    @media ${sv.phone} {
      margin-top: ${sv.baseMargin};
    }
  `,
  briefingService: css`
    position: relative;
    width: 100%;
    margin-bottom: ${sv.baseMargin};
  `,
  panel: css`
    text-align: center;
    height: 100%;
  `,
  grid: css`
    margin-top: ${sv.baseMargin};
    justify-content: center;
  `,
  back: css`
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ${sv.baseTransitionTimeShort} ease-in-out;
    color: ${sv.textPrimaryDark};

    &:hover {
      cursor: pointer;
      color: ${sv.grey800};
    }
  `,
  backLabel: css`
    margin-left: 5px;

    @media ${sv.phone} {
      display: none;
    }
  `,
  card: css`
    animation: ${slideUp} ${sv.baseTransitionTime} forwards;
    opacity: 0;
    transform: translateY(10%);
  `,
  title: css`
    margin-bottom: ${sv.baseMarginSmall};

    @media ${sv.phone} {
      margin-bottom: 5px;
    }
  `,
  subtitle: css`
    text-align: center;
    color: ${sv.textSecondaryDark};
  `,
  backButton: css`
    text-align: center;
    margin-top: ${sv.baseMargin};
`,
};


const servicesWithCopyFeature = ['shoebox'];


class BriefingService extends React.Component {

  static propTypes = {
    estate: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        service: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
      })),
    }),
  };

  static fragments = {
    Estate: () => gql`
      fragment _ on Estate {
        id
        items {
          id
          briefingConfirmedAt
          reference
          jsonMeta @client(type: Item)
          ${ItemDetails}
          service {
            id @client(type: ProductService)
            slug
          }
          milestones {
            id
            name
          }
          product {
            id @client(type: Product)
            slug
            custom
            name
          }
        }
      }
    `,
  };

  state = {
    copyFromPreviousModalOpen: false,
    clickedItem: null,
  }

  UNSAFE_componentWillMount() {
    const { estate, goNext, match, history } = this.props;
    const itemsByService = getItemsByService(estate);
    const items = itemsByService[match.params.serviceType];
    if (items.length === 1) {
      goNext(items[0], { replace: true, style: history.location.state.style });
    }
  }

  render() {
    const { copyFromPreviousModalOpen } = this.state;
    const { goBack, goNext, estate, match } = this.props;
    const itemsByService = getItemsByService(estate);
    const items = itemsByService[match.params.serviceType];
    const sortedItems = items.sort((a, b) => Number(a.product.custom) - Number(b.product.custom));
    const service = items[0].service;
    const itemsLeft = items.length - items.filter((item) => !! item.briefingConfirmedAt).length;

    const completedItems = items.filter((item) => item.jsonMeta.briefingCompleted || item.briefingConfirmedAt != null);
    const sourceItem = completedItems[0];
    const showCopyFromPreviousModal = servicesWithCopyFeature.includes(service.id)
      && completedItems.length === 1;

    return (
      <div className={styles.container}>
        <div className={styles.briefingService}>
          <div className={styles.back} onClick={goBack}>
            <Icon name="caret-left-bold" size="lg" />
            <div className={styles.backLabel}>
              {tt('back_to_overview')}
            </div>
          </div>
          <div>
            <Title className={styles.title}>{t(`services.${service.id}`)}</Title>
            <div className={styles.subtitle}>
              { do {
                if (itemsLeft > 1) {
                  tt('items_left', { items_left: String(itemsLeft) });
                }
                else if (itemsLeft === 1) {
                  tt('one_item_left');
                }
                else {
                  tt('all_items_completed');
                }
              }}
            </div>
            <Grid halfVGutters halfHGutters className={styles.grid}>
              {sortedItems.map((item, i) => (
                <Cell
                  key={item.id}
                  className={styles.card}
                  style={{ animationDelay: `${i / 10}s` }}
                  size="1of4"
                  responsive="l1of2, m1of1">
                  <BriefingCard
                    asRow
                    onClick={(style) => {
                      if (showCopyFromPreviousModal && item.id !== sourceItem?.id) {
                        this._handleClickCard(item);
                      }
                      else {
                        goNext(item, { style });
                      }
                    }}
                    confirmed={item.milestones.find((milestone) => milestone.name === 'accepted')}
                    completed={item.jsonMeta.briefingCompleted || item.jsonMeta.briefingConfirmedAt || item.briefingConfirmedAt}
                    image={serviceImages[service.id]}
                    title={item.product.custom ? item.product.name : `${tt('item')} ${i + 1}`} />
                </Cell>
              ))}
            </Grid>
            <div className={styles.backButton}>
              { do {
                if (itemsLeft === 0) {
                  <Button category="primary" onClick={goBack}>{tt('back_to_overview')}</Button>
                }
              }}
            </div>
          </div>
        </div>
        {do {
          if (showCopyFromPreviousModal) {
            <CopyFromPreviousModal
              items={sortedItems.filter((item) => sourceItem?.id !== item.id)}
              sourceItem={sourceItem}
              visible={copyFromPreviousModalOpen}
              onClickSave={(units) => this._handleCopyFromPrevious(sourceItem, units)}
              onClickCancel={() => goNext(this.state.clickedItem, {})}
              onClickClose={() => this.setState({
                copyFromPreviousModalOpen: false,
                clickedItem: null,
              })} />
          }
        }}
      </div>
    );
  }

  @autobind
  _handleClickCard(item) {
    this.setState({ copyFromPreviousModalOpen: true, clickedItem: item });
  }

  @autobind
  async _handleCopyFromPrevious(sourceItem, unitValues) {
    const { estate, match, updateItems, showAlert } = this.props;
    const itemsByService = getItemsByService(estate);
    const items = itemsByService[match.params.serviceType];
    const itemsById = items.reduce((memo, item) => ({ ...memo, [item.id]: item }), {});

    const updatedItems = Object.keys(unitValues).map((id) => ({
      id,
      details: deepOmit({
        ...sourceItem.details,
        unit: unitValues[id],
      }, ['__typename', 'filename', 'url']),
      metadata: JSON.stringify({ briefingCompleted: true, briefingOngoing: false }),
      serviceId: itemsById[id].service.id,
      custom: itemsById[id].product.custom,
      productSlug: itemsById[id].product.slug,
    }));
    await updateItems(updatedItems);
    document.body.style.overflow = 'initial'; // Prevent preserving style from modal
    showAlert('DETAILS_COPIED', 'success', `Fantastic, all details have been copied. Don't forget to confirm each item!`);
    this.setState({ copyFromPreviousModalOpen: false });
  }
}


const mapDispatchToProps = {
  showAlert,
};


export default compose(
  connect(null, mapDispatchToProps),
)(BriefingService);
