export const CHANGE_CURRENT_DRAFT = 'CHANGE_CURRENT_DRAFT';

export function changeCurrentDraft(id) {
  return {
    type: CHANGE_CURRENT_DRAFT,
    payload: {
      id,
    },
  };
}


export const TOGGLE_FULL_SCREEN = 'TOGGLE_FULL_SCREEN';

export function toggleFullScreen() {
  return {
    type: TOGGLE_FULL_SCREEN,
  };
}


export const OPEN_ANNOTATION = 'OPEN_ANNOTATION';

export function openAnnotation(id) {
  return {
    type: OPEN_ANNOTATION,
    payload: {
      id,
    },
  };
}


export const CLOSE_ANNOTATION = 'CLOSE_ANNOTATION';

export function closeAnnotation(id) {
  return {
    type: CLOSE_ANNOTATION,
    payload: {
      id,
    },
  };
}


export const TOGGLE_CORRECTION_ROUND = 'TOGGLE_CORRECTION_ROUND';

export function toggleCorrectionRound() {
  return {
    type: TOGGLE_CORRECTION_ROUND,
  };
}


export const DISABLE_VALIDATE_PREVIEW_BUTTON = 'DISABLE_VALIDATE_PREVIEW_BUTTON';

export function disableValidatePreviewButton() {
  return {
    type: DISABLE_VALIDATE_PREVIEW_BUTTON,
  };
}


export const ENABLE_VALIDATE_PREVIEW_BUTTON = 'ENABLE_VALIDATE_PREVIEW_BUTTON';

export function enableValidatePreviewButton() {
  return {
    type: ENABLE_VALIDATE_PREVIEW_BUTTON,
  };
}


export const DISABLE_ANNOTATION_SAVE_BUTTON = 'DISABLE_ANNOTATION_SAVE_BUTTON';

export function disableAnnotationSaveButton() {
  return {
    type: DISABLE_ANNOTATION_SAVE_BUTTON,
  };
}


export const ENABLE_ANNOTATION_SAVE_BUTTON = 'ENABLE_ANNOTATION_SAVE_BUTTON';

export function enableAnnotationSaveButton() {
  return {
    type: ENABLE_ANNOTATION_SAVE_BUTTON,
  };
}


export const ENABLE_APPROVE_BUTTON = 'ENABLE_APPROVE_BUTTON';

export function enableApproveButton() {
  return {
    type: ENABLE_APPROVE_BUTTON,
  };
}


export const DISABLE_APPROVE_BUTTON = 'DISABLE_APPROVE_BUTTON';

export function disableApproveButton() {
  return {
    type: DISABLE_APPROVE_BUTTON,
  };
}