import { HookService, effect, onDidMount, ref, state } from '@tokamakjs/react';
import { Subscription } from 'rxjs';

import { RouterService } from '~/services';

import { ProjectsService } from '../../services';
import { Project, ProjectStatus } from '../../types';

/**
 * Ideally, this should be a tokamak controller, however, since we cannot
 * use the tokamak router, we have to "mock" our own controller implementation.
 *
 * This is not too big of a problem since at the end of the day, tokamakjs controllers
 * are just classes linked to a specific route.
 *
 * For this, we use a HookService since it's basically a controller without view.
 */
@HookService()
export class ProjectsController {
  public static readonly ITEMS_PER_PAGE = 10;

  @state private _isLoading = true;
  @state private _projects: Array<Project> = [];
  @state private _totalProjects = 0;

  @ref private _subs = new Subscription();
  @ref private _filtersUsed = false;
  @ref private _loaded = false;

  get isLoading() {
    return this._isLoading;
  }

  get projects() {
    return this._projects;
  }

  get pages(): number {
    return Math.ceil(this._totalProjects / ProjectsController.ITEMS_PER_PAGE);
  }

  get currentPage(): number {
    return Number(this._router.getQuery('page') ?? 1);
  }

  get searchValue(): string | undefined {
    return this._router.getQuery('search');
  }

  get filter(): ProjectStatus | undefined {
    const filter = this._router.getQuery('filter');
    return this._isValidFilter(filter) ? filter : undefined;
  }

  constructor(private readonly _service: ProjectsService, private readonly _router: RouterService) {
    this._filtersUsed = JSON.parse(sessionStorage.getItem('PROJECTS::FILTERS_USED') ?? 'false');
  }

  @onDidMount()
  protected initSubscriptions(): VoidFunction {
    // Make sure to update projects first so it's not empty when isLoading gets updated to false
    this._subs.add(this._service.projects$.subscribe((v) => (this._projects = v)));
    this._subs.add(this._service.totalProjects$.subscribe((v) => (this._totalProjects = v)));
    this._subs.add(this._service.isLoading$.subscribe((v) => (this._isLoading = v)));

    return () => {
      this._subs.unsubscribe();
    };
  }

  @onDidMount()
  protected setDefaultFilter(): void {
    if (!this._filtersUsed && this.filter == null) {
      // Use setTimeout to make sure it's the last action after mount
      setTimeout(() => this.setFilter('ACTIVE'));
    }
  }

  @effect((self: ProjectsController) => [
    self.currentPage ?? 'empty', // use 'empty' to match undefined as equal
    self.searchValue ?? 'empty',
    self.filter ?? 'empty',
  ])
  protected loadPage(): void {
    if (!this._filtersUsed && this.filter == null) {
      return; // don't bother loading since we're gonna re-load with the default filter
    }

    const start = (this.currentPage - 1) * ProjectsController.ITEMS_PER_PAGE;

    this._service
      .resetAndLoadProjects(start, ProjectsController.ITEMS_PER_PAGE, this.searchValue, this.filter)
      .then(() => (this._loaded = true)); // use .then() until tokamak supports returning a promise
  }

  // reset the current page because it probably changed with the new search results
  @effect((self: ProjectsController) => [self.searchValue, self.filter])
  protected resetPage(): void {
    if (this._loaded) {
      this._router.setQuery('page', undefined);
    }
  }

  public goToPage(page: number): void {
    this._router.setQuery('page', page.toString());
  }

  public updateSearch(search: string | undefined): void {
    this._router.setQuery('search', search === '' ? undefined : search);
  }

  public setFilter(filter: ProjectStatus | undefined): void {
    this._filtersUsed = true;
    sessionStorage.setItem('PROJECTS::FILTERS_USED', 'true');
    this._router.setQuery('filter', filter);
  }

  private _isValidFilter(filter?: string): filter is ProjectStatus | undefined {
    return filter == null || (filter != null && filter !== 'active' && filter !== 'completed');
  }
}
