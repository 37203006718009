import { css, keyframes } from 'emotion';
import sv, { fade } from '@drawbotics/style-vars';


const fadeInContent = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export default {
  container: css`
    width: 100%;
    max-width: 1200px;
    margin: auto;
  `,
  briefingItem: css`
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
  `,
  cover: css`
    position: absolute;
    height: 300px;
    width: 100%;
    top: 0;
    left: 0;
    background-position: 100% 10%;
    background-repeat: no-repeat;
    background-size: 40%;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.44, 0.11, 0.07, 1);
    transition-duration: 0;

    &:before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.25);
      z-index: 1;
    }
  `,
  coverWithTransition: css`
    transition-duration: ${sv.baseTransitionTime};
  `,
  review: css`
    &::before {
      background: ${fade('#3DB5D0', 90)};
    }
  `,
  confirmed: css`
    &::before {
      background: ${fade('#52bf7f', 90)};
    }
  `,
  back: css`
    position: absolute;
    top: ${sv.baseMargin};
    left: ${sv.baseMargin};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ${sv.baseTransitionTimeShort} ease-in-out;
    color: ${sv.white};

    &:hover {
      cursor: pointer;
      color: ${sv.grey100};
    }
  `,
  coverContent: css`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: ${sv.basePadding};
    z-index: 2;
    margin: auto;
    align-items: center;
  `,
  backLabel: css`
    margin-left: 5px;

    @media ${sv.phone} {
      display: none;
    }
  `,
  coverTitle: css`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: ${sv.white};
    font-size: 1.6rem;
    font-weight: 600;
    opacity: 0;
    pointer-events: none;
    animation: ${fadeInContent} ${sv.baseTransitionTime} ${sv.baseTransitionTime} forwards;
    max-width: 500px;
    text-align: center;
    margin-top: calc(${sv.baseMargin} * -2);

    @media ${sv.phone} {
      font-size: 1.2rem;
    }
  `,
  contentWrapper: css`
    height: 100%;
    margin-top: 300px;
  `,
  content: css`
    animation: ${fadeInContent} ${sv.baseTransitionTimeShort} ${sv.baseTransitionTime} forwards;
    opacity: 0;
    transform: translateY(0);
    position: relative;
    top: -100px;
    z-index: 999;
    max-width: 1200px;
    width: 100%;
    margin: auto;
    background: ${sv.white};
    padding: calc(${sv.basePadding} * 2);
    border-radius: ${sv.baseBorderRadius};

    @media ${sv.phone} {
      padding: ${sv.basePaddingSmall};
      padding-top: calc(${sv.basePadding} * 1.5);
    }
  `,
  subtitle: css`
    text-align: center;
    color: ${sv.textPrimaryDark};
  `,
  actions: css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: ${sv.baseMargin};
    border-top: 2px solid ${sv.grey100};
  `,
  backButton: css`
  margin-top: ${sv.baseMargin};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -100px;
`,
  intro: css`
    text-align: center;
    margin-top: calc(${sv.baseMargin} * 2);
  `,
  checksContainer: css`
    position: absolute;
    top: ${sv.baseMargin};
    right: ${sv.baseMargin};
    display: flex;
    align-items: center;
    z-index: 99;
  `,
  checkcircle: css`
    height: 30px;
    width: 30px;
    border-radius: 1000px;
    background: ${sv.grey200};
    display: flex;
    align-items: center;
    justify-content: center;
    color: transparent;
    font-size: 0.8rem;
  `,
  checked: css`
    background: ${sv.brandGreen};
    color: ${sv.white};
  `,
};
