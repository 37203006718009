export default {
  dependencies: {
    briefingCompleted: `
      fragment _ on Estate {
        briefingConfirmedAt
        items {
          id
          briefingConfirmedAt
          service {
            abstract
          }
        }
      }
    `,
  },
  resolvers: {
    briefingCompleted: (estate) => {
      const allItemsCompleted = estate.items.every((item) => item.briefingConfirmedAt);
      return estate.briefingConfirmedAt && allItemsCompleted;
    },
  },
};
