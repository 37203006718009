import { Add, Switch, RelativeAdd, Conditional, ForEach } from 'drawbotics-pricer';


const description = {
  Base: [
    Add(2),
    Conditional('details.logoAndWatermark').equals(true).then([
      Add(1),
    ]),
    ForEach('details.numberOfImages').threshold(1).do([
      Add(2),
    ]),
  ],
  Delivery: [
    Switch('estate.delivery').is({
      'relaxed': RelativeAdd(-0.1).from('Base'),
      'standard': RelativeAdd(0).from('Base'),
      'express': RelativeAdd(0.5).from('Base'),
    })
  ],
};


export default description;
