import React from 'react';
import { ConfirmModal } from '@drawbotics/modal';
import { Text, Modal, FlexItem, FlexJustify, Button, Tier, FlexSpacer, Category, Flex, FlexDirection, Title, Paragraph, Shade } from '@drawbotics/react-drylus';

import { createTranslate } from '~/utils/translation';

import changeServices from '~/pods/order/images/change-services.svg';

const tt = createTranslate('pods.order.components.estate_reset_confirm_modal');

const EstateResetWarningModal = ({
  children,
}) => {
  return (
    <ConfirmModal enhance={children}>
      {(onClickConfirm, onClickCancel) => (
        <Modal visible title={tt('change_services')} onClickCancel={onClickCancel} footer={
          <Flex justify={FlexJustify.END}>
            <FlexItem>
              <Button tier={Tier.TERTIARY} onClick={onClickCancel}>{tt('cancel')}</Button>
            </FlexItem>
            <FlexSpacer />
            <FlexItem>
              <Button category={Category.DANGER} onClick={onClickConfirm}>{tt('continue')}</Button>
            </FlexItem>
          </Flex>}>
          <Flex direction={FlexDirection.VERTICAL}>
            <FlexItem>
              <img src={changeServices} />
            </FlexItem>
            <FlexItem>
               <Title size={4}>{tt('message')}</Title>
            </FlexItem>
            <FlexItem>
              <Paragraph>
                <Text shade={Shade.MEDIUM}>{tt('message_2')}</Text>
              </Paragraph>
            </FlexItem>
          </Flex>
        </Modal>
      )}
    </ConfirmModal>
  );
};


export default EstateResetWarningModal;
