import dayjs from 'dayjs';
import { combineReducers } from 'redux';

import * as UIActions from 'pods/studio/actions/ui';
import * as ProjectActions from 'pods/studio/actions/project';
import * as AnnotationsActions from 'pods/studio/actions/annotations';
import * as AttachmentsActions from 'pods/studio/actions/attachments';


function currentDraft(state=0, action) {
  switch (action.type) {
    case ProjectActions.LOAD_PROJECT_SUCCESS: {
      const { project } = action.payload;
      const sortedDrafts = project.drafts
        .sort((d1, d2) => dayjs(d2.createdAt).diff(dayjs(d1.createdAt)));
      return sortedDrafts[0].id;
    }
    case UIActions.CHANGE_CURRENT_DRAFT: {
      const { id } = action.payload;
      return id;
    }
    default:
      return state;
  }
}


function fullScreen(state=false, action) {
  switch (action.type) {
    case UIActions.TOGGLE_FULL_SCREEN: {
      return ! state;
    }
    default:
      return state;
  }
}


function savingAnnotation(state=false, action) {
  switch (action.type) {
    case AnnotationsActions.SAVE_ANNOTATION: {
      return true;
    }
    case AttachmentsActions.SAVE_ATTACHMENTS_DONE: {
      return false;
    }
    default:
      return state;
  }
}


function activeAnnotation(state=null, action) {
  switch (action.type) {
    case UIActions.OPEN_ANNOTATION: {
      const { id } = action.payload;
      return id;
    }
    case UIActions.CLOSE_ANNOTATION: {
      const { id } = action.payload;
      if (id !== state) {
        return state;
      }
      return null;
    }
    case AnnotationsActions.DELETE_ANNOTATION: {
      const { annotation } = action.payload;
      if (state !== annotation.id) {
        return state;
      }
      return null;
    }
    case AnnotationsActions.SAVE_ANNOTATION: {
      const { annotation, closeAnnotation } = action.payload;
      if (state !== annotation.id || ! closeAnnotation) {
        return state;
      }
      return null;
    }
    default:
      return state;
  }
}


function purchaseCorrectionRound(state=false, action) {
  switch (action.type) {
    case UIActions.TOGGLE_CORRECTION_ROUND: {
      return ! state;
    }
    default:
      return state;
  }
}


function disabledValidatePreview(state=false, action) {
  switch (action.type) {
    case UIActions.DISABLE_VALIDATE_PREVIEW_BUTTON: {
      return true;
    }
    case UIActions.ENABLE_VALIDATE_PREVIEW_BUTTON: {
      return false;
    }
    default:
      return state;
  }
}


function enableApproveButton(state=true, action) {
  switch (action.type) {
    case UIActions.ENABLE_APPROVE_BUTTON: {
      return true;
    }
    case UIActions.DISABLE_APPROVE_BUTTON: {
      return false;
    }
    default:
      return state;
  }
}


export default combineReducers({
  currentDraft,
  fullScreen,
  savingAnnotation,
  activeAnnotation,
  purchaseCorrectionRound,
  disabledValidatePreview,
  enableApproveButton,
});
