export default (request, entities, translators) => ({
  create(invitation) {
    const data = translators.Invitation.toServer(invitation);
    const organization = invitation.organization.id ? invitation.organization.id : invitation.organization;
    return request('POST', `organisations/${organization}/invitations`, { invitation: data })
      // .then((response) => response.resource)
      .then(translators.Invitation.fromServer);
  },
  find(id) {
    return request('GET', `invitations/${id}`)
      .then(translators.Invitation.fromServer);
  },
  update(invitation) {
    const organization = invitation.organization;
    const data = translators.Invitation.toServer(invitation);
    return request('PUT', `organisations/${organization}/clients/${invitation.id}`, data);
  },
});
