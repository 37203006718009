import { TargetDomain } from '@drawbotics/auth';
import { gqlFetch } from '@tokamakjs/common';
import { Injectable } from '@tokamakjs/react';
import { omit } from 'lodash';
import urljoin from 'url-join';

import { AuthService } from '~/services';

import {
  Invitation,
  Member,
  PartialInvitation,
  PartialMember,
  PartialUser,
  Teammate,
  User,
} from './domain';
import { CreateInvitation, ResendInvitation, UpdateTeammate, UpdateUser } from './mutations';
import { TeammatesQuery, UserQuery } from './queries';

enum Error {
  GENERIC = 'GENERIC',
  CRITICAL = 'CRITICAL',
}

@Injectable()
export class SettingsApi {
  constructor(private readonly _authService: AuthService) {}

  public async updateUser(newUser: {
    firstName?: string;
    lastName?: string;
  }): Promise<{ data: { user: PartialUser }; error?: undefined } | { error: Error }> {
    const { authToken } = this._authService;

    const res = await gqlFetch({
      url: urljoin(process.env.CORE_HOST, '/api/v1/graphql'),
      headers: { authorization: `Bearer ${authToken}` },
      query: UpdateUser,
      variables: { user: newUser },
    });

    const body = await res.json();

    if (body.errors) {
      return { error: Error.GENERIC };
    }

    return { data: { user: PartialUser.fromData(body.data.updateProfile.user) } };
  }

  public async fetchUser(): Promise<
    { data: { user: User }; error?: undefined } | { error: Error }
  > {
    const { authToken } = this._authService;

    const res = await gqlFetch({
      url: urljoin(process.env.CORE_HOST, '/api/v1/graphql'),
      headers: { Authorization: `Bearer ${authToken}` },
      query: UserQuery,
    });

    const body = await res.json();

    if (body.errors) {
      return { error: Error.GENERIC };
    }

    return { data: { user: User.fromData(body.data.user) } };
  }

  public async updateTeammate(
    userId: string,
    teammate: Partial<Teammate>,
    isInvitation?: boolean,
  ): Promise<{ data: { teammate: Partial<Teammate> }; error?: undefined } | { error: Error }> {
    const { authToken } = this._authService;

    const res = await gqlFetch({
      url: urljoin(process.env.CORE_HOST, '/api/v1/graphql'),
      headers: { authorization: `Bearer ${authToken}` },
      query: UpdateTeammate,
      variables: { user: { userId, role: teammate.role } }, // Note: we can only modify the role atm
    });

    const body = await res.json();

    if (body.errors) {
      return { error: Error.GENERIC };
    }

    return {
      data: {
        teammate: isInvitation
          ? PartialInvitation.fromData(body.data.updateUser.user)
          : PartialMember.fromData(body.data.updateUser.user),
      },
    };
  }

  public async fetchTeammates(): Promise<
    { data: { teammates: Array<Teammate> }; error?: undefined } | { error: Error }
  > {
    const { authToken } = this._authService;

    const res = await gqlFetch({
      url: urljoin(process.env.CORE_HOST, '/api/v1/graphql'),
      headers: { Authorization: `Bearer ${authToken}` },
      query: TeammatesQuery,
    });

    const body = await res.json();

    if (body.errors) {
      return { error: Error.GENERIC };
    }

    const {
      organisation,
    }: {
      organisation?: { users: Array<Member>; invitations: Array<Invitation> };
    } = body.data;

    return {
      data: {
        teammates: [
          ...(organisation?.users.map(Member.fromData) ?? []),
          ...(organisation?.invitations.map(Invitation.fromData) ?? []),
        ],
      },
    };
  }

  public async createInvitation(
    invitation: Partial<Invitation>,
  ): Promise<{ data: { invitation: Invitation }; error?: undefined } | { error: Error }> {
    const { authToken } = this._authService;

    const res = await gqlFetch({
      url: urljoin(process.env.CORE_HOST, '/api/v1/graphql'),
      headers: { authorization: `Bearer ${authToken}` },
      query: CreateInvitation,
      variables: {
        invitation: {
          ...omit(invitation, 'role'),
          organisationRole: invitation.role,
          targetDomain: TargetDomain.DRAWBOTICS.toUpperCase(),
        },
      },
    });

    const body = await res.json();

    if (body.errors) {
      return { error: Error.GENERIC };
    }

    return { data: { invitation: Invitation.fromData(body.data.createInvitation.invitation) } };
  }

  public async resendInvitation(
    id: string,
  ): Promise<{ data: { invitation: Partial<Invitation> }; error?: undefined } | { error: Error }> {
    const { authToken } = this._authService;

    const res = await gqlFetch({
      url: urljoin(process.env.CORE_HOST, '/api/v1/graphql'),
      headers: { authorization: `Bearer ${authToken}` },
      query: ResendInvitation,
      variables: {
        invitationId: id,
      },
    });

    const body = await res.json();

    if (body.errors) {
      return { error: Error.GENERIC };
    }

    return {
      data: { invitation: PartialInvitation.fromData(body.data.resendInvitation.invitation) },
    };
  }
}
