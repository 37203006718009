const specs = {
  standard: {
    operation: 'create',
    create: {
      type: 'bool',
      product: 'landingPage_standard',
    },
  },
  copywriting: {
    operation: 'update',
    update: {
      type: 'mapTo',
      target: '@{product.id} == "landingPage_standard"',
      mapTo: [ 'copywriting' ],
    },
  },
  numberOfLanguages: {
    operation: 'update',
    update: {
      type: 'mapTo',
      target: '@{product.id} == "landingPage_standard"',
      mapTo: [ 'numberOfLanguages' ],
    },
  },
};


const dependencies = {
  hard: [],
  soft: [],
};


export default { specs, dependencies };
