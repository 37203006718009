var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

import { Subject } from 'rxjs-legacy/Subject';
import { map } from 'rxjs-legacy/operator/map';
import { switchMap } from 'rxjs-legacy/operator/switchMap';
import { ActionsObservable } from './ActionsObservable';
import { EPIC_END } from './EPIC_END';

var defaultAdapter = {
  input: function input(action$) {
    return action$;
  },
  output: function output(action$) {
    return action$;
  }
};

var defaultOptions = {
  adapter: defaultAdapter
};

export function createEpicMiddleware(epic) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : defaultOptions;

  if (typeof epic !== 'function') {
    throw new TypeError('You must provide a root Epic to createEpicMiddleware');
  }

  // even though we used default param, we need to merge the defaults
  // inside the options object as well in case they declare only some
  options = _extends({}, defaultOptions, options);
  var input$ = new Subject();
  var action$ = options.adapter.input(new ActionsObservable(input$));
  var epic$ = new Subject();
  var store = void 0;

  var epicMiddleware = function epicMiddleware(_store) {
    store = _store;

    return function (next) {
      var _context;

      (_context = map.call(epic$, function (epic) {
        var output$ = 'dependencies' in options ? epic(action$, store, options.dependencies) : epic(action$, store);

        if (!output$) {
          throw new TypeError('Your root Epic "' + (epic.name || '<anonymous>') + '" does not return a stream. Double check you\'re not missing a return statement!');
        }

        return output$;
      }), switchMap).call(_context, function (output$) {
        return options.adapter.output(output$);
      }).subscribe(store.dispatch);

      // Setup initial root epic
      epic$.next(epic);

      return function (action) {
        var result = next(action);
        input$.next(action);
        return result;
      };
    };
  };

  epicMiddleware.replaceEpic = function (epic) {
    // gives the previous root Epic a last chance
    // to do some clean up
    store.dispatch({ type: EPIC_END });
    // switches to the new root Epic, synchronously terminating
    // the previous one
    epic$.next(epic);
  };

  return epicMiddleware;
}
