import { compose, createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { createLoadingMiddleware } from 'loading-manager';
import createSagaMiddleware from 'redux-saga';
import createReduxImmutableStateMiddleware from 'redux-immutable-state-invariant';
import { middleware as createEntmanMiddleware } from 'entman';

import reducer from './reducer';
import epic from './epic';
import saga from './saga';
import api from './utils/api';
import history from './utils/history';
import { logRocketMiddleware } from './utils/logrocket';
import createHandleErrorsMiddleware from './utils/handle-errors-middleware';
import invalidateWhenEdit from './pods/studio/middlewares/invalidate-when-edit';
import { handleSagaErrors } from './utils/errors';


const isDevEnv = process.env.NODE_ENV === 'development';
const isDevApp = process.env.APP_ENV !== 'production';
const existingStore = window.hasOwnProperty('reduxStore');


const sagaMiddleware = createSagaMiddleware();
const immutableStateMiddleware = isDevEnv ? createReduxImmutableStateMiddleware() : null;


const middlewares = ({ api, history }) => [
  immutableStateMiddleware,
  createHandleErrorsMiddleware(),
  invalidateWhenEdit,
  createEpicMiddleware(epic, { dependencies: { api, history } }),
  sagaMiddleware,
  createLoadingMiddleware(),
  createEntmanMiddleware({ enableBatching: true }),
  logRocketMiddleware,
];


export default function setupStore(initialState) {
  // We return the same store so it doesn't break Redux when using HMR
  if (isDevEnv && existingStore) {
    const nextReducer = require('reducer').default;
    window.reduxStore.replaceReducer(nextReducer);
    return window.reduxStore;
  }

  const devTools = (window.__REDUX_DEVTOOLS_EXTENSION__&& isDevApp) ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f;
  const withMiddlewares = applyMiddleware(...middlewares({ api, history }).filter(Boolean));

  const store = createStore(
    reducer,
    initialState,
    compose(withMiddlewares, devTools),
  );

  sagaMiddleware.run(saga).done.catch(handleSagaErrors(store));

  if (isDevEnv) {
    window.reduxStore = store;
  }

  return store;
}
