import React from 'react';
import { Grid, Cell } from 'react-ittsu/layout';
import { css, cx } from 'emotion';
import sv, { darken } from '@drawbotics/style-vars';

import { createTranslate } from '~/utils/translation';


const t = createTranslate('pods.order.services');


const styles = {
  images: css`
    margin: ${sv.baseMarginSmall} 0;
  `,
  title: css`
    font-family: ${sv.baseFontFamilyAlt};
    text-transform: uppercase;
    color: ${sv.textPrimaryDark};
    font-weight: 600;
    font-size: 0.65rem;
    margin-bottom: ${sv.baseMarginSmall};
  `,
  imageWrapper: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `,
  image: css`
    width: 150px;
    border: 3px solid ${sv.grey400};
    position: relative;
    background: ${sv.grey200};
  `,
  liked: css`
    border-color: ${sv.brandGreen};

    &::after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      height: 30px;
      width: 30px;
      margin: auto;
      border-radius: 100%;
      background: ${sv.brandGreen};
    }
  `,
  disliked: css`
    border-color: ${sv.brandRed};
  `,
  label: css`
    margin-top: calc(${sv.baseMarginSmall} / 2);
    font-size: 0.7rem;
    text-align: center;
    color: ${sv.textSecondaryDark};
  `,
  noImage: css`
    height: 80px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${sv.grey100};
    color: #9DC0FF;
    text-shadow: -1px 0 ${darken(sv.brandBlue, 30)},
      0 1px ${darken(sv.brandBlue, 30)},
      1px 0 ${darken(sv.brandBlue, 30)},
      0 -1px ${darken(sv.brandBlue, 30)};
    font-size: 1.5rem;
    font-family: ${sv.baseFontFamilyAlt};
    text-transform: uppercase;
    font-weight: 600;
  `,
  multipleImages: css`
    display: flex;
    justify-content: center;
    width: auto;
    background: ${sv.grey400};
  `,
  oneOfManyImages: css`
    margin-right: 2px;
    height: 130px;
    flex: 1;

    &:last-child {
      margin-right: 0;
    }

    & > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  `,
};


const Images = ({ values, dislikedValues, title }) => {
  return (
    <div className={styles.images}>
      <div className={styles.title}>
        {t(title)}
      </div>
      <Grid halfVGutters halfHGutters>
        {values.map((value, i) => (
          <Cell key={i} responsive={value.images ? null : 'm1of2'}>
            <div className={styles.imageWrapper}>
              {do{
                if (value.image) {
                  <img src={value.image} className={cx(styles.image, { [styles.liked]: dislikedValues.length > 0 })} />
                }
                else if (value.images) {
                  <div className={cx(styles.image, styles.multipleImages)}>
                    {value.images.map((image, i) => (
                      <div key={i} className={styles.oneOfManyImages}>
                        <img src={image} />
                      </div>
                    ))}
                  </div>
                }
                else {
                  <div className={cx(styles.image, styles.noImage)}>
                    {t(value.label, null, value.label)}
                  </div>
                }
              }}
              {do{
                if (value.label) {
                  <div className={styles.label}>{t(value.label, null, value.label)}</div>
                }
              }}
            </div>
          </Cell>
        ))}
        {dislikedValues && dislikedValues.map((value, i) => (
          <Cell key={i + values.length} responsive="m1of2">
            <div className={styles.imageWrapper}>
              <img src={value.image} className={cx(styles.image, { [styles.disliked]: dislikedValues.length > 0 })} />
              {do{
                if (value.label) {
                  <div className={styles.label}>{t(value.label, null, value.label)}</div>
                }
              }}
            </div>
          </Cell>
        ))}
      </Grid>
    </div>
  );
}


export default Images;
