import React from 'react';

import AnnotationList from './AnnotationList';
import ValidatePreview from './ValidatePreview';

import styles from 'pods/studio/styles/components/sidebar';


const Sidebar = ({
  user,
  preview,
  disabledValidatePreview,
  savingAnnotation,
  activeAnnotation,
  openAnnotation,
  validatePreview,
}) => {
  return (
    <div className={styles.sidebar}>
      <div className={styles.scrollable}>
        <AnnotationList
          user={user}
          preview={preview}
          activeAnnotation={activeAnnotation}
          onClickAnnotationItem={openAnnotation} />
      </div>
      <ValidatePreview
        user={user}
        preview={preview}
        savingAnnotation={savingAnnotation}
        disabled={disabledValidatePreview}
        onClickValidate={validatePreview} />
    </div>
  );
};

export default Sidebar;
