import Stripe from 'stripe';


let stripe = null;


if (process.env.APP_ENV === 'production') {
  stripe = Stripe('pk_live_4X44TrIsigOxHQyAEZoOTZTu');
}
else {
  stripe = Stripe('pk_test_CinUcOV5l4rxcVqXWbZGtwMp');
}


export { stripe };
