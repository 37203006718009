import { HookService } from '@tokamakjs/react';

import { useRouter } from '../utils/hooks/use-router';

@HookService()
export class RouterService {
  private readonly _router = useRouter();

  public setQuery(key: string, value: string | undefined): void {
    const { search } = this._router.location;

    const newSearch = new URLSearchParams(search);

    if (value == null) {
      newSearch.delete(key);
    } else {
      newSearch.set(key, value);
    }

    this._router.history.push({ search: newSearch.toString() });
  }

  public getQuery(key?: string): string | undefined {
    if (key == null) {
      return this._router.location.search.slice(1); // remove leading ?
    }

    const search = new URLSearchParams(this._router.location.search);
    return search.get(key) ?? undefined;
  }

  public push(href: string): void {
    this._router.history.push(href);
  }

  public getParam(key: string): string | undefined {
    return (this._router.match.params as any)[key];
  }
}
