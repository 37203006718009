import gql from 'fraql';

import EstateSidebar from '../../components/EstateSidebar';


export const RecapQuery = gql`
  query RecapQuery($estateId: ID!) {
    estate(estateId: $estateId) {
      id
      name
      propertyType
      ${EstateSidebar.fragments.Estate()}
      lastVisitedStep @client(type: Estate)
      orderCompleted @client(type: Estate)
      orderProgress @client(type: Estate)
      paymentCompleted @client(type: Estate)
      paymentProgress @client(type: Estate)
      briefingProgress @client(type: Estate)
      briefingCompleted @client(type: Estate)
      paymentProcess {
        id
      }
    }
  }
`;


export const InitiateSalesOrderMutation = gql`
  mutation InitiateSalesOrder(
    $estateId: ID!
  ) {
    payload: initiateSalesOrder (
      input: {
        estateId: $estateId
      }
    ) {
      paymentProcess {
        id
      }
    }
  }
`;


export const InitiateDirectPaymentMutation = gql`
  mutation InitiateDirectPayment($estateId: ID!) {
    payload: initiateDirectPayment(input: { estateId: $estateId }) {
      paymentProcess {
        id
      }
    }
  }
`;


export const PayWithCreditsMutation = gql`
  mutation PayWithCredits(
    $estateId: ID!
  ) {
    payload: payWithCreditBalance(
      input: {
        estateId: $estateId
      }
    ) {
      paymentProcess {
        id
        downPaymentTransaction {
          id
          transactionToken: accessToken
        }
      }
    }
  }
`;
