const specs = {
  houseTwoBedroom: {
    operation: 'create',
    create: {
      type: 'number',
      product: 'tour3d_standard',
      with: {
        type: 'house',
        rooms: 2
      },
    },
  },
  houseThreeBedroom: {
    operation: 'create',
    create: {
      type: 'number',
      product: 'tour3d_standard',
      with: {
        type: 'house',
        rooms: 3
      },
    },
  },
  houseFourBedroom: {
    operation: 'create',
    create: {
      type: 'number',
      product: 'tour3d_standard',
      with: {
        type: 'house',
        rooms: 4
      },
    },
  },
  houseFiveBedroom: {
    operation: 'create',
    create: {
      type: 'number',
      product: 'tour3d_standard',
      with: {
        type: 'house',
        rooms: 5
      },
    },
  },
  apartmentStudio: {
    operation: 'create',
    create: {
      type: 'number',
      product: 'tour3d_standard',
      with: {
        type: 'apartment',
        rooms: 0
      },
    },
  },
  apartmentOneBedroom: {
    operation: 'create',
    create: {
      type: 'number',
      product: 'tour3d_standard',
      with: {
        type: 'apartment',
        rooms: 1
      },
    },
  },
  apartmentTwoBedroom: {
    operation: 'create',
    create: {
      type: 'number',
      product: 'tour3d_standard',
      with: {
        type: 'apartment',
        rooms: 2
      },
    },
  },
  apartmentThreeBedroom: {
    operation: 'create',
    create: {
      type: 'number',
      product: 'tour3d_standard',
      with: {
        type: 'apartment',
        rooms: 3
      },
    },
  },
  apartmentFourBedroom: {
    operation: 'create',
    create: {
      type: 'number',
      product: 'tour3d_standard',
      with: {
        type: 'apartment',
        rooms: 4
      },
    },
  },
  apartmentFiveBedroom: {
    operation: 'create',
    create: {
      type: 'number',
      product: 'tour3d_standard',
      with: {
        type: 'apartment',
        rooms: 5
      },
    },
  },
  gardenHotspots: {
    operation: 'update',
    update: {
      type: 'mapTo',
      target: '@{details.type} == "apartment" || @{details.type} == "house"',
      mapTo: [ 'gardenHotspots' ],
    },
  },
  terraceHotspots: {
    operation: 'update',
    update: {
      type: 'mapTo',
      target: '@{details.type} == "apartment" || @{details.type} == "house"',
      mapTo: [ 'terraceHotspots' ],
    },
  },
  commercialStandard: {
    operation: 'create',
    create: {
      type: 'bool',
      product: 'tour3d_standard',
      with: {
        type: 'commercial',
      },
    },
  },
  commercialHotspots: {
    operation: 'update',
    update: {
      type: 'mapTo',
      target: '@{details.type} == "commercial"',
      mapTo: [ 'commercialHotspots' ],
    },
  },
};


const dependencies = {
  hard: [],
  soft: [
    {
      if: {
        key: 'terraceHotspots',
        value: (v) => v > 0,
      },
      then: {
        operation: 'createOrUpdate',
        createOrUpdate: {
          product: 'droneShooting_standard',
          service: 'droneShooting',
          with: { tour3d: true },
        },
      },
    }
  ],
};


export default { specs, dependencies };
