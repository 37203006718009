import { Add, Switch, RelativeAdd } from 'drawbotics-pricer';


const description = {
  Base: [
    Switch('estate.projectType').is({
      'residential': [
        Switch('product.id').is({
          'videoAnimation_standard': [
            Add({ EUR: 2500, USD: 3000, GBP: 2000, }),
          ],
          'videoAnimation_premium': [
            Add({ EUR: 9500, USD: 15000, GBP: 9500, }),
          ],
        }),
      ],
      'commercial': [
        Add({ EUR: 12500, USD: 17500, GBP: 12500, }),
      ],
    }),
  ],
  Delivery: [
    Switch('estate.delivery').is({
      'relaxed': RelativeAdd(-0.1).from('Base'),
      'standard': RelativeAdd(0).from('Base'),
      'express': RelativeAdd(0.5).from('Base'),
    })
  ],
};


export default description;
