import dayjs from 'dayjs';


export function convertDate(date) {
  return dayjs(date).locale('en').format('DD/MM/YY');
}


export function convertDateAndHour(date) {
  return dayjs(date).locale('en').format('DD/MM/YY - HH:mm');
}


export function codeToSymbol(code) {
  switch (code) {
    case 'EUR': {
      return '€';
    }
    case 'USD': {
      return '$';
    }
    case 'GBP': {
      return '£';
    }
    default: {
      return code;
    }
  }
}
