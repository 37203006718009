import Raven from 'raven-js';

import LogRocket from './logrocket';


// Configure Raven client
if (process.env.NODE_ENV === 'production' && ! Raven.isSetup()) {
  const ravenInstance = Raven.config('https://36078163e35942a790d8fea24c81debb@sentry.io/178638', {
    autoBreadcrumbs: false,  // Prevent raven from wrapping every console log
    environment: process.env.APP_ENV,
  });

  Raven.setDataCallback((data) => {
    data.extra.sessionURL = LogRocket.sessionURL;
    return data;
  });

  ravenInstance.install();

  fetch('/version.json')
    .then((response) => response.status !== 500 ? response.json() : {})
    .then((body) => Raven.setRelease(body.version));
}


export default {
  captureException: process.env.NODE_ENV === 'production'
    ? Raven.captureException.bind(Raven)
    : x => x,
  setUserContext: process.env.NODE_ENV === 'production'
    ? Raven.setUserContext.bind(Raven)
    : x => x,
};
