import React from 'react';
import { Button } from 'react-ittsu/components';
import { ErrorLabel } from 'react-ittsu/forms';
import autobind from 'autobind-decorator';
import omit from 'lodash/omit';
import { css, cx } from 'emotion';
import sv from '@drawbotics/style-vars';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import FileUploadOverlay from '~/pods/order/components/FileUploadOverlay';
import AttachmentBox from '~/pods/order/components/AttachmentBox';
import { createTranslate, translate as t } from '~/utils/translation';


const tt = createTranslate('pods.order.services');


const styles = {
  fileUploader: css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 500px;

    @media ${sv.ipad} {
      max-width: 500px;
      width: 100%;
      margin-top: ${sv.baseMarginSmall};
    }
  `,
  uploadZone: css`
    position: relative;
  `,
  disabled: css`
    pointer-events: none;
    opacity: 0.5;
  `,
  file: css`
    margin-top: ${sv.baseMarginSmall};
    width: 100%;
  `,
}


class FileUploader extends React.Component {
  state = {
    tempFiles: {},
    toUpload: [],
  };

  render() {
    const {
      label,
      resultKey,
      answers,
      accept,
      withErrors,
      required,
    } = this.props;
    const { tempFiles } = this.state;
    const attachments = get(answers, resultKey) ?? [];
    const showError = withErrors && required && attachments.length === 0;

    return (
      <div className={styles.fileUploader}>
        <div className={cx(styles.uploadZone, { [styles.disabled]: ! isEmpty(tempFiles) })}>
          <FileUploadOverlay
            accept={accept}
            component={
              <Button
                round
                category={showError ? "danger" : "primary"}>
                {tt(label, null, label)}
              </Button>
            }
            onInit={(files) => this.setState({ uploading: files.length })}
            onProgress={this._handleFileUploading}
            onFinish={this._handleFinishUpload} />
          {do {
            if (showError) {
              <ErrorLabel
                text={t('pods.order.components.briefing_details.field_required')} />
            }
          }}
        </div>
        {Object.keys(tempFiles).map((filename, i) => (
          <div key={i} className={styles.file}>
            <AttachmentBox file={{ filename }} progress={tempFiles[filename]} />
          </div>
        ))}
        {attachments.map((attachment) => (
          <div key={attachment.id} className={styles.file}>
            <AttachmentBox file={attachment} onClickDelete={() => this._handleDeleteAttachment(attachment.id)} />
          </div>
        ))}
      </div>
    );
  }

  @autobind
  _handleFileUploading(filename, progress) {
    const { tempFiles } = this.state;
    this.setState({ tempFiles: { ...tempFiles, [filename]: progress } });
  }

  @autobind
  _handleFinishUpload(file) {
    const uploading = this.state.uploading - 1;
    this.setState({ uploading, toUpload: [ ...this.state.toUpload, file] }, () => {
      if (uploading === 0) {
        this._handleUploadAll();
      }
    });
  }

  @autobind
  _handleUploadAll() {
    const { toUpload } = this.state;
    const { modifyAnswer, resultKey, answers } = this.props;
    const sectionAnswers = get(answers, resultKey) ?? [];
    modifyAnswer(resultKey, [ ...sectionAnswers.map((file) => omit(file, '__typename')), ...toUpload ]);
    this.setState({ toUpload: [], tempFiles: {} });
  }

  @autobind
  _handleDeleteAttachment(id) {
    const { modifyAnswer, resultKey, answers } = this.props;
    const sectionAnswers = get(answers, resultKey) ?? [];
    modifyAnswer(resultKey, sectionAnswers.filter((a) => a.id !== id));
  }
}


export default FileUploader;
