import { ze } from '@tokamakjs/common';
import { z } from 'zod';

import { OrganizationRole } from '~/types';

const UserSchema = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  // @ts-ignore Type instantiation is excessively deep and possibly infinite.
  role: z.nativeEnum(OrganizationRole),
});

const PartialUserSchema = UserSchema.partial();

export type UserData = z.infer<typeof UserSchema>;

export class User extends ze.ClassFrom(UserSchema) {
  public static fromData(data: UserData): User {
    return ze.validate(data, User);
  }
}

export type PartialUserData = z.infer<typeof PartialUserSchema>;

export class PartialUser extends ze.ClassFrom(PartialUserSchema) {
  public static fromData(data: Partial<UserData>): PartialUser {
    return ze.validate(data, PartialUser);
  }
}
