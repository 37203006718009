import morphey from 'morphey';


const translateToServer = {
  signed_id: 'signedId',
  'infos.field_type': 'fieldType',
  'infos.target_type': 'targetType',
  'infos.target_id': 'targetId',
};


export function attachmentToServer(attachment) {
  return morphey(attachment, translateToServer);
}
