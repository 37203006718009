import React from 'react';
import {
  Panel,
  PanelHeader,
  PanelTitle,
  PanelBody,
  Table,
  TCell,
  TBody,
  TRow,
  T2,
} from 'react-ittsu/components';
import {
  Container,
} from 'react-ittsu/layout';
import get from 'lodash/get';
import { compose } from 'redux';
import { connect } from 'react-redux';
import dayjs from 'dayjs';

import isAuthenticated from 'utils/is-authenticated';
import { loadOrders } from 'pods/transactions/actions';
import { getOrders } from 'pods/transactions/selectors';
import { getSessionUser } from 'selectors';
import { convertDateAndHour, codeToSymbol } from 'pods/transactions/utils';
import RouteContainer from 'components/RouteContainer';
import LoadingContainer from 'containers/LoadingContainer';
import { translate as t, createTranslate } from 'utils/translation';

import styles from 'pods/transactions/styles/routes/orders';


const tt = createTranslate('pods.transactions.routes.orders');


function mapEstateNameToProducts(estate) {
  const mappedProducts = estate.items.map((item) => ({ ...item, itemName: item.serviceSlug }));
  return {
    ...estate,
    products: mappedProducts,
  };
}


function calculateItemPrice(products) {
  return products.reduce((prev, curr) => prev + curr.fullPrice, 0);
}


const ItemsTable = ({ project, currency }) => {
  const { products } = project;
  const total = calculateItemPrice(products);
  return (
    <div>
      <div className={styles.name}>
        {project.name}
      </div>
      <Table fullWidth style={{ marginTop: '20px' }}>
        <TBody>
          {products.map((product, i) => (
            <TRow key={i}>
              <TCell colSpan={2}>{t(`services.${product.itemName}`)}</TCell>
              <TCell>{t(`services.${product.legacyName}`)}</TCell>
              <TCell>{t(`pods.dashboard.status.${product.status}.title`)}</TCell>
              <TCell style={{ textAlign: 'right' }}>
                {do{
                  if (currency === 'credits') {
                    `${product.fullPrice} ${tt('credits')}`
                  }
                  else {
                    tt('price_with_symbol', { currency: codeToSymbol(currency), price: product.fullPrice })
                  }
                }}
              </TCell>
            </TRow>
          ))}
          <TRow>
            <TCell colSpan={5} style={{ textAlign: 'right' }}>
              <span>{tt('subtotal')} : </span>
              <span>
                {do{
                  if (currency === 'credits') {
                    `${total} ${tt('credits')}`
                  }
                  else {
                    tt('price_with_symbol', { currency: codeToSymbol(currency), price: total })
                  }
                }}
              </span>
            </TCell>
          </TRow>
        </TBody>
      </Table>
    </div>
  );
};


const Order = ({ order }) => {
  const projects = order.projects;
  const normalizedProjects = projects.map(mapEstateNameToProducts);
  const total = normalizedProjects.reduce((prev, curr) => prev + calculateItemPrice(curr.products), 0);
  return (
    <div className={styles.section}>
      <Panel>
        <PanelHeader>
          <PanelTitle>{`${tt('order')} ${order.reference}`}</PanelTitle>
        </PanelHeader>
        <PanelBody>
          <div className={styles.basicInfo}>
            {`${tt('ordered_by')} ${order.orderedBy} - ${convertDateAndHour(order.orderedAt)}`}
          </div>
          <div className={styles.orderItem}>
            {normalizedProjects.map((estate, i) => (
              <ItemsTable key={i} project={estate} currency={order.currency} />
            ))}
          </div>
          <div className={styles.total}>
            <span>{tt('total')} : </span>
            <span>
              {do{
                if (order.currency === 'credits') {
                  `${total} ${tt('credits')}`
                }
                else {
                  tt('price_with_symbol', { currency: codeToSymbol(order.currency), price: total })
                }
              }}
            </span>
          </div>
        </PanelBody>
      </Panel>
    </div>
  );
};


class Orders extends React.Component {

  state = {
    loaded: false,
  }

  constructor(props) {
    super(props);
    this._loadAllOrders = this._loadAllOrders.bind(this);
  }

  componentDidMount() {
    this._loadAllOrders();
  }

  componentDidUpdate() {
    this._loadAllOrders();
  }

  render() {
    const { orders } = this.props;
    if (! orders) {
      return null;
    }
    const sorted = orders.sort((a, b) => dayjs(b.orderedAt).diff(dayjs(a.orderedAt)));
    return (
      <LoadingContainer
        loadingKeys="orders"
        whenLoaded={() => (
          <RouteContainer>
            <Container>
              <T2 brand>
                {tt('order_index')}
              </T2>
              {sorted.map( (order, i) => (
                <Order key={i} order={order} />
              ))}
            </Container>
          </RouteContainer>
        )} />
    );
  }

  _loadAllOrders() {
    const { loadOrders, sessionUser } = this.props;
    const { loaded } = this.state;
    if (sessionUser && ! loaded) {
      loadOrders(get(sessionUser, 'organization.id', null));
      this.setState({ loaded: true });
    }
  }
}


const mapStateToProps = (state) => ({
  orders: getOrders(state),
  sessionUser: getSessionUser(state),
});


const mapDispatchToProps = {
  loadOrders,
};


export default compose(
  isAuthenticated(),
  connect(mapStateToProps, mapDispatchToProps),
)(Orders);
