import uniq from 'lodash/uniq';

import filterDescription from './filter-description';
import { attachments } from './attachments';


export function getRequiredAttachmentsForEstate(estate) {
  const requiredKeys = Object.keys(estate.itemsByService).reduce((keys, serviceId) => attachments[serviceId] ? [ ...keys, ...attachments[serviceId] ] : keys, []);
  return uniq(requiredKeys);
}


function appendKeyword(keys) {
  return keys.map((key) => `${key}Attachments`);
}


export default function filterEstateAttachments(estate, description) {
  const requiredAttachmentKeys = appendKeyword(getRequiredAttachmentsForEstate(estate));
  return filterDescription(description, requiredAttachmentKeys);
}


export * from './attachments';
