const k = (key) => `website.briefing.${key}`;

const residential = {
  1: {
    type: 'section',
    filter: {
      type: 'service',
      value: 'brandId',
    },
    components: [
      {
        type: 'component',
        label: k('branding'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('branding_selection'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'SingleSelect',
        key: 'brandId',
        keyLabel: k('keys.brand_id'),
        required: 'all',
        values: [
          {
            image: require('../images/shared/company-branding.jpg'),
            value: 'company',
            label: k('company_branding'),
            meta: {
              type: 'meta',
              noToggle: true,
              components: [
                {
                  type: 'question-component',
                  label: k('upload_company_files'),
                  component: 'FileUploader',
                  key: 'brandIdAttachments',
                  keyLabel: k('keys.brand_id_attachments'),
                  required: 'all',
                },
              ],
            },
          },
          {
            image: require('../images/shared/project-branding.jpg'),
            value: 'project',
            label: k('project_branding'),
            meta: {
              type: 'meta',
              condition: {
                required: 'one',
                conditions: [{
                  type: 'service',
                  value: 'brandId',
                }],
              },
              components: [
                {
                  type: 'question-component',
                  label: k('upload_project_files'),
                  component: 'FileUploader',
                  key: 'brandIdAttachments',
                  keyLabel: k('keys.brand_id_attachments'),
                  required: 'all',
                },
              ],
            },
          },
        ],
      },
    ]
  },
  2: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('website_style'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('like_dislike_styles'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'LikeDislike',
        key: 'styles',
        keyLabel: k('keys.styles'),
        dislikedKey: 'dislikedStyles',
        required: 'one',
        values: [
          {
            image: require('../images/website/thumbnails/bailli.jpg'),
            zoom: require('../images/website/bailli.jpg'),
            value: 'website_style_1',
          },
          {
            image: require('../images/website/thumbnails/brooklyn.jpg'),
            zoom: require('../images/website/brooklyn.jpg'),
            value: 'website_style_2',
          },
          {
            image: require('../images/website/thumbnails/harmonie.jpg'),
            zoom: require('../images/website/harmonie.jpg'),
            value: 'website_style_3',
          },
          {
            image: require('../images/website/thumbnails/iret.jpg'),
            zoom: require('../images/website/iret.jpg'),
            value: 'website_style_4',
          },
          {
            image: require('../images/website/thumbnails/klinkerhof.jpg'),
            zoom: require('../images/website/klinkerhof.jpg'),
            value: 'website_style_5',
          },
          {
            image: require('../images/website/thumbnails/nest.jpg'),
            zoom: require('../images/website/nest.jpg'),
            value: 'website_style_6',
          },
        ]
      },
      {
        type: 'meta',
        label: k('provide_own_styles'),
        components: [
          {
            type: 'question-component',
            label: 'shared.example_description.',
            component: 'Input',
            key: 'otherStyle',
            keyLabel: k('keys.other_style'),
          }, {
            type: 'question-component',
            label:'shared.upload_example',
            component: 'FileUploader',
            key: 'otherStyleAttachments',
            keyLabel: k('keys.other_style_attachments'),
          },
        ],
      }
    ]
  },
  3: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('website_sections'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('website_sections_notes'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'MultiSelect',
        key: 'sections',
        keyLabel: k('keys.sections'),
        required: 'one',
        values: [
          {
            image: require('../images/website/sections/project.jpg'),
            value: 'project_description',
            label: k('project'),
          },
          {
            image: require('../images/website/sections/environment.jpg'),
            value: 'environment',
            label: k('environment'),
          },
          {
            image: require('../images/website/sections/gallery.jpg'),
            value: 'gallery',
            label: k('gallery'),
          },
          {
            image: require('../images/website/sections/map.jpg'),
            value: 'map',
            label: k('map'),
          },
          {
            image: require('../images/website/sections/contact.jpg'),
            value: 'contact',
            label: k('contact'),
          },
          {
            image: require('../images/website/sections/3Dtour.jpg'),
            value: 'tour_3d',
            label: k('tour_3d'),
          },
          {
            image: require('../images/website/sections/listing.jpg'),
            value: 'listing',
            label: k('listing'),
          },
          {
            image: require('../images/website/sections/panorama.jpg'),
            value: 'panorama',
            label: k('panorama'),
          },
          {
            image: require('../images/website/sections/revo.jpg'),
            value: 'revo',
            label: k('revo'),
          },
          {
            image: require('../images/website/sections/video.jpg'),
            value: 'video',
            label: k('video'),
          },
          {
            image: require('../images/website/sections/finishes.jpg'),
            value: 'finishes',
            label: k('finishes'),
          },
        ],
      },
      {
        type: 'meta',
        label: k('structure_extra_details'),
        components: [
          {
            type: 'question-component',
            label: k('structure_description'),
            component: 'Input',
            key: 'structureDescription',
            keyLabel: k('keys.structure_description'),
          }, {
            type: 'question-component',
            label: k('structure_attachments'),
            component: 'FileUploader',
            key: 'structureAttachments',
            keyLabel: k('keys.structure_attachments'),
          },
        ],
      }
    ],
  },
  4: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'shared.visuals',
        component: 'Title',
      },
      {
        type: 'component',
        label: 'shared.visuals_note',
        component: 'Note',
      },
      
      {
        type: 'question-component',
        component: 'SingleSelect',
        key: 'visuals',
        keyLabel: 'shared.keys.visuals',
        required: 'all',
        values: [
          {
            value: 'drawbotics',
            label: 'shared.drawbotics',
            image: require('../images/shared/drawbotics.jpg'),
          },
          {
            value: 'upload_my_own',
            image: require('../images/shared/my-own.jpg'),  
            label: 'shared.upload_my_own',
            meta: {
              type: 'meta',
              noToggle: true,
              components: [
                {
                  type: 'question-component',
                  label: 'shared.upload_visuals',
                  component: 'FileUploader',
                  key: 'visualsAttachments',
                  keyLabel: 'shared.keys.visuals_attachments',
                },
              ],
            },
          },
          {
            value: 'other',
            label: 'shared.other',
            image: require('../images/shared/other.jpg'),            
            meta: {
              type: 'meta',
              noToggle: true,
              components: [
                {
                  type: 'question-component',
                  component: 'Input',
                  label: 'shared.other_visuals',
                  key: 'otherVisuals',
                  keyLabel: 'shared.keys.other_visuals',
                },
              ],
            },
          },
        ],
      },
    ],
  },
  5: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'shared.languages',
        component: 'Title',
      }, {
        type: 'component',
        label: 'shared.languages_note',
        component: 'Note',
      }, {
        type: 'question-component',
        component: 'MultiSelect',
        min: (answers) => answers.numberOfLanguages,
        max: (answers) => answers.numberOfLanguages,
        key: 'languages',
        keyLabel: 'shared.keys.languages',
        required: 'all',
        values: [
          {
            value: 'en',
            image: require('../images/shared/EN.jpg'),
            label: 'shared.english',
          },
          {
            value: 'fr',
            image: require('../images/shared/FR.jpg'),
            label: 'shared.french',
          },
          {
            value: 'nl',
            image: require('../images/shared/NL.jpg'),
            label: 'shared.dutch',
          },
        ],
      },
    ],
  },
  6: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'shared.additional_info',
        component: 'Title',
      }, {
        type: 'component',
        label: 'shared.anything_to_add',
        component: 'Note',
      }, {
        type: 'question-component',
        label: 'shared.additional_comments',
        component: 'Input',
        key: 'otherInfo',
        keyLabel: 'shared.keys.other_info',
      }, {
        type: 'question-component',
        label: 'shared.upload_files',
        component: 'FileUploader',
        key: 'otherInfoAttachments',
        keyLabel: 'shared.keys.other_info_attachments',
      },
    ],
  },
};

export default { residential };
