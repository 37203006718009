import gql from 'fraql';

import BriefingOverview from '../BriefingOverview';
import BriefingService from '../BriefingService';
import BriefingItem from '../BriefingItem';
import BriefingEstate from '../BriefingEstate';


export const BriefingQuery = gql`
  query BriefingQuery($estateId: ID!) {
    estate(estateId: $estateId) {
      id
      propertyType
      ${BriefingOverview.fragments.Estate()}
      ${BriefingService.fragments.Estate()}
      ${BriefingItem.fragments.Estate()}
      ${BriefingEstate.fragments.Estate()}
    }
  }
`;


export const ServicesQuery = gql`
  query ServicesQuery {
    serviceCategories: categories {
      id: slug
      slug
      services {
        slug
        id @client(type: ProductService)
      }
    }
  }
`;


export const UpdateEstateMutation = gql`
  mutation UpdateEstateMutation(
    $id: ID!,
    $details: OrderEstateDetailsInput,
    $attachments: [AttachmentInput!],
  ) {
    payload: updateOrderEstate(
      input: {
        orderEstateId: $id,
        details: $details,
        attachments: $attachments
      }
    ) {
      updatedEstate: orderEstate {
        id
        ${BriefingEstate.fragments.Estate()}
      }
    }
  }
`;


export const UpdateItemsMutation = gql`
  mutation UpdateOrderItemsMutation(
    $id: ID!,
    $items: [OrderItemInput!]!
  ) {
    payload: updateOrderItems(
      input: {
        orderEstateId: $id,
        items: $items,
      }
    ) {
      updatedItems: items {
        id
        ${BriefingItem.fragments.Item()}
      }
    }
  }
`;


export const ConfirmItemBriefing = gql`
  mutation ConfirmOrderItemBriefing(
    $itemId: ID!
  ) {
    payload: confirmOrderItemBriefing(
      input: {
        orderItemId: $itemId,
      }
    ) {
      confirmedItem: orderItem {
        id
        ${BriefingItem.fragments.Item()}
      }
    }
  }
`;


export const ConfirmEstateBriefing = gql`
  mutation ConfirmOrderEstateBriefing(
    $estateId: ID!
  ) {
    payload: confirmOrderEstateBriefing(
      input: {
        orderEstateId: $estateId
      }
    ) {
      confirmedEstate: orderEstate {
        id
        ${BriefingEstate.fragments.Estate()}
      }
    }
  }
`;
