export default (entities) => ({
  addFollower(follower) {
    return entities.Follower.create(follower, 'item');
  },
  removeFollower(follower) {
    return entities.Follower.delete(follower, 'item');
  },
  addProjectFollower(follower) {
    return entities.Follower.create(follower, 'project');
  },
  removeProjectFollower(follower) {
    return entities.Follower.delete(follower, 'project');
  },
});
