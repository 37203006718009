import { Switch, Add, RelativeAdd, ForEach } from 'drawbotics-pricer';


const description = {
  Base: [
    Switch('estate.projectType').is({
      'residential': [
        ForEach('details.viewCount').do([
          Add({ EUR: 850, GBP: 850, USD: 1050 }),
        ]),
      ],
      'commercial': [
        ForEach('details.viewCount').do([
          Add({ EUR: 950, GBP: 950, USD: 1200 }),
        ]),
      ],
    }),
  ],
  Delivery: [
    Switch('estate.delivery').is({
      'relaxed': RelativeAdd(-0.1).from('Base'),
      'standard': RelativeAdd(0).from('Base'),
      'express': RelativeAdd(0.5).from('Base'),
    })
  ],
};


export default description;
