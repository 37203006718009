import Rx from 'rxjs-legacy';
import { combineEpics } from 'redux-observable';

import * as OrdersActions from 'pods/transactions/actions/orders';
import * as AlertsActions from 'actions/alerts';
import { translate as t } from 'utils/translation';
import { wrapError } from 'utils/errors';


function loadOrders(action$, store, extra) {
  const { api } = extra;
  return action$
    .filter((action) => action.type === OrdersActions.LOAD_ORDERS)
    .mergeMap((action) => {
      const { organizationId } = action.payload;
      return Rx.Observable.fromPromise(api.queries.getOrders(organizationId))
        .map((orders) => OrdersActions.loadOrdersSuccess(orders))
        .catch((err) => Rx.Observable.concat(
          Rx.Observable.of(AlertsActions.showAlert(OrdersActions.LOAD_ORDERS_FAIL, 'error', t('containers.alerts.transactions.orders.errors.load_orders'))),
          Rx.Observable.of(OrdersActions.loadOrdersFail(wrapError(err)))
        ));
    });
}


function loadOrder(action$, store, extra) {
  const { api } = extra;
  return action$
    .filter((action) => action.type === OrdersActions.LOAD_ORDER)
    .mergeMap((action) => {
      const { id } = action.payload;
      return Rx.Observable.fromPromise(api.queries.getOrder(id))
        .map((order) => OrdersActions.loadOrderSuccess(order))
        .catch((err) => Rx.Observable.concat(
          Rx.Observable.of(AlertsActions.showAlert(OrdersActions.LOAD_ORDER_FAIL, 'error', t('containers.alerts.transactions.orders.errors.load_order'))),
          Rx.Observable.of(OrdersActions.loadOrderFail(wrapError(err)))
        ));
    });
}


export default combineEpics(
  loadOrders,
  loadOrder,
);
