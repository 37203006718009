import flatten from 'lodash/flatten';


export default (request, entities, translators) => ({
  findAll(organisationId) {
    return request('GET', `organisations/${organisationId}/payments`)
      .then((payments) => flatten(payments.payments.map((payment) =>
        payment.invoices.map((invoice) => ({ ...invoice, buyer: payment.buyer }))
      )))
      .then((invoices) => invoices.map(translators.Invoice.fromServer))
      .then((invoices) => invoices.filter((invoice) => invoice.sentAt));
  },
});
