import omit from 'lodash/omit';
import get from 'lodash/get';
import { getJwt } from '@drawbotics/auth';

const updateLocaleMutation = `
  mutation updateLocale($user: UpdateUserInput!) {
    updateUser(input: $user) {
      user {
        id
        locale
      }
    }
  }
`;

export default (request, entities, translators) => ({
  signIn(email, password) {
    const data = { client: { email, password } };
    return request('POST', 'login', data)
      .then((response) => response.client)
      .then((session) => ({
        authToken: session.authentication_token,
        user: session.id,
        type: session.type,
        locale: session.locale,
        currency: session.currency.code,
      }));
  },
  signUp(user, organization) {
    const transformedOrg = {
      name: organization ? organization.name : '',
      isCompany: user.hasOrganization,
      address: user.hasOrganization ? organization.address : {},
    };
    const data = translators.User.toServer({ ...user, organization: transformedOrg });
    const transformed = user.token ? omit(data, ['organisation']) : omit(data, ['invitation_token']);
    return request('POST', 'register', { client: transformed })
      .then((session) => ({
        authToken: session.client.authentication_token,
        user: session.client.id,
        locale: session.client.locale,
        currency: session.client.currency.code,
      }));
  },
  getSessionUser() {
    return request('GET', 'client')
      .then((session) => ({ ...session.client, ...session }))
      .then(translators.Session.fromServer);
  },
  updateSessionUser(data) {
    return request('PUT', 'client', translators.Session.toServer(data))
      .then((response) => response.client)
      .then((session) => ({ ...session.client, ...session }))
      .then(translators.Session.fromServer);
  },
  changeLanguage(locale, session) {
    return fetch(`${process.env.CORE_HOST}/graphql`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getJwt()}`,
      },
      body: JSON.stringify({
        variables:  { user: { locale: locale.toUpperCase() } },
        query: updateLocaleMutation,
      }),
    });
  },
  requestNewPassword(email) {
    return request('POST', 'forgotten-password', { email });
  },
  updatePassword(password, passwordConfirmation, token) {
    const data = { password, password_confirmation: passwordConfirmation, reset_password_token: token };
    return request('PUT', 'forgotten-password', data).then((session) => ({
      authToken: session.client.authentication_token,
      user: session.client.id,
    }));
  },
  guestSignUp(user, item) {
    const data = { guest: { ...translators.Session.guestToServer(user), public_identifier: item.publicToken } };
    return request('POST', 'public/public-users', data)
      .then((session) => (get(session, 'message.item[0].error') === 'user_already_registered' ?
        'registered' : {
          authToken: session.authentication_token,
          user: session.user_id,
          extra: { item: session.item_id },
          type: session.type ? session.type : 'public',
        }));
  },
  confirmEmail(token) {
    return request('POST', 'confirm-email', { confirmation_token: token });
  },
});