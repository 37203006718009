import Rx from 'rxjs-legacy';
import { combineEpics } from 'redux-observable';

import * as AddressesActions from '~/pods/profile/actions/addresses';
import * as AlertsActions from '~/actions/alerts';

import { formatErrorMessage, wrapError } from 'utils/errors';
import { translate as t } from 'utils/translation';


function createAddress(action$, store, extra) {
  const { api } = extra;
  return action$
    .filter((action) => action.type === AddressesActions.CREATE_ADDRESS)
    .flatMap((action) => {
      const { address } = action.payload;
      return Rx.Observable.fromPromise(api.mutations.createAddress(address))
        .flatMap((newAddress) => Rx.Observable.concat(
          Rx.Observable.of(AddressesActions.createAddressSuccess(address.id, newAddress)),
          Rx.Observable.of(AddressesActions.updateAddress(newAddress, 'no-server')),
          Rx.Observable.of(AlertsActions.showAlert(AddressesActions.CREATE_ADDRESS_SUCCESS, 'success', t('containers.alerts.profile.address_created')))
        ))
        .catch((err) => {
          const message = formatErrorMessage(err.info.body.message, 'containers.alerts.profile.errors');
          return Rx.Observable.concat(
            Rx.Observable.of(AlertsActions.showAlert(AddressesActions.CREATE_ADDRESS_FAIL, 'error', message)),
            Rx.Observable.of(AddressesActions.createAddressFail(err, address))
          );
        });
    });
}


function createAddressFail(action$) {
  return action$
    .filter((action) => action.type === AddressesActions.CREATE_ADDRESS_FAIL)
    .map((action) => action.payload.address)
    .map((address) => AddressesActions.deleteAddress(address, 'no-server'));
}


function deleteAddress(action$, store, extra) {
  const { api } = extra;
  return action$
    .filter((action) => action.type === AddressesActions.DELETE_ADDRESS)
    .flatMap((action) => {
      const { address, noServer } = action.payload;
      if (noServer) {
        return Rx.Observable.of(AddressesActions.deleteAddressSuccess());
      }
      return Rx.Observable.fromPromise(api.mutations.deleteAddress(address))
        .flatMap((response) => Rx.Observable.concat(
          Rx.Observable.of(AddressesActions.deleteAddressSuccess()),
          Rx.Observable.of(AlertsActions.showAlert(AddressesActions.DELETE_ADDRESS_SUCCESS, 'success', t('containers.alerts.profile.address_removed'))),
        ))
        .catch((err) => {
          const message = formatErrorMessage(err.info.body.message, 'containers.alerts.profile.errors');
          return Rx.Observable.concat(
            Rx.Observable.of(AlertsActions.showAlert(AddressesActions.DELETE_ADDRESS_FAIL, 'error', message)),
            Rx.Observable.of(AddressesActions.deleteAddressFail(wrapError(err)))
          );
        });
    });
}


function updateAddress(action$, store, extra) {
  const { api } = extra;
  return action$
    .filter((action) => action.type === AddressesActions.UPDATE_ADDRESS)
    .flatMap((action) => {
      const { address, noServer } = action.payload;
      if (noServer) {
        return Rx.Observable.of(AddressesActions.updateAddressSuccess(address));
      }
      return Rx.Observable.fromPromise(api.mutations.updateAddress(address))
        .flatMap((updatedAddress) => Rx.Observable.concat(
          Rx.Observable.of(AddressesActions.updateAddressSuccess(updatedAddress)),
          Rx.Observable.of(AlertsActions.showAlert(AddressesActions.UPDATE_ADDRESS_SUCCESS, 'success', t('containers.alerts.profile.address_updated'))),
        ))
        .catch((err) => {
          const message = formatErrorMessage(err.info.body.message, 'containers.alerts.profile.errors');
          return Rx.Observable.concat(
            Rx.Observable.of(AlertsActions.showAlert(AddressesActions.UPDATE_ADDRESS_FAIL, 'error', message)),
            Rx.Observable.of(AddressesActions.updateAddressFail(wrapError(err))),
          );
        });
    });
}


export default combineEpics(
  createAddress,
  createAddressFail,
  deleteAddress,
  updateAddress,
);
