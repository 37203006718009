import { createEntities } from 'entman';
import { startLoading, stopLoading } from 'loading-manager';

import schemas from 'schemas';


export const LOAD_PROJECT = '@STUDIO/LOAD_PROJECT';

export function loadProject(id) {
  return {
    type: LOAD_PROJECT,
    payload: { id },
    meta: { loading: startLoading('project') },
  };
}


export const LOAD_PROJECT_SUCCESS = '@STUDIO/LOAD_PROJECT_SUCCESS';

export function loadProjectSuccess(project) {
  return createEntities(schemas.ProjectItem, 'payload.project', {
    type: LOAD_PROJECT_SUCCESS,
    payload: { project },
    meta: { loading: stopLoading('project') },
  });
}


export const LOAD_PROJECT_FAIL = '@STUDIO/LOAD_PROJECT_FAIL';

export function loadProjectFail(error) {
  return {
    type: LOAD_PROJECT_FAIL,
    payload: { err: error },
    meta: { error, loading: stopLoading('project') },
  };
}
