import React, { Fragment } from 'react';
import get from 'lodash/get';

import { createTranslate } from 'utils/translation';
import Option from 'pods/studio/components/Option';
import { scrollIntoView } from 'pods/studio/utils';

import styles from 'pods/studio/styles/components/summary';


const tt = createTranslate('pods.studio.components.summary');


const CorrectionRound = ({
  price,
  availableCorrections,
  used,
  onChange,
  extraStyle,
}) => {
  return (
    <div className={styles.correctionRound} style={extraStyle}>
      <h2 className={styles.title}>{tt('correction_round')}</h2>
      {do {
        if (availableCorrections > 0) {
          <p>
            {tt('corrections_left', { correctionRoundAvailable: availableCorrections })}
          </p>
        }
        else {
          <Fragment>
            <Option
              name="Extra Correction"
              price={price}
              picked={used}
              onChange={onChange} />
            <p>
              {tt('no_more_correction_rounds')}
            </p>
          </Fragment>
        }
      }}
    </div>
  );
};


const ValidateDraft = scrollIntoView(({
  draft,
  disabled,
  onValidateDraft,
}) => (
  <button
    disabled={disabled}
    className={styles.approveButton}
    onClick={() => onValidateDraft(draft)}>
    {draft.hasAnnotations() ?
      tt('send_back_to_production') : tt('validate_project')}
  </button>
));


const Approve = ({
  user,
  draft,
  onApprove,
  enableApproveButton,
  correctionChecked,
}) => {
  const userCanApprove = get(user, 'permissions.draft.validate', false);
  const allPreviewsValidated = draft.previews.every(p => p.validatedAt);
  const needsAnotherRound = draft.hasAnnotations();
  const enoughCorrections = draft.remainingCorrectionRounds() > 0 || correctionChecked;
  const enableButton = enableApproveButton
    && allPreviewsValidated
    && userCanApprove
    && (enoughCorrections || ! needsAnotherRound);
  const hint = allPreviewsValidated ? tt('hint_purchase_correction') : tt('hint_validate_preview');
  return (
    <div className={styles.approve}>
      {do {
        if (draft.isEditable()) {
          <Fragment>
            <ValidateDraft
              disabled={ ! enableButton}
              draft={draft}
              onValidateDraft={onApprove} />
            {do {
              if ( ! enableButton && user.type !== 'public' && userCanApprove && enableApproveButton) {
                <div style={{color: '#db4c60', marginTop: '7px', fontSize: 18}}>
                  {hint}
                </div>
              }
            }}
          </Fragment>
        }
      }}
    </div>
  );
};


const Summary = ({
  user,
  draft,
  correctionChecked,
  onApprove,
  enableApproveButton,
  toggleCorrectionRound,
}) => {
  const extraStyle = user.type === 'public' ? {visibility: 'hidden'} : {};
  if ( ! draft.correctionRounds || draft.correctionRounds.length === 0) {
    return null;
  }
  return(
    <div className={styles.summary}>
      <div className={styles.optionsContainer}>
        {draft.isEditable() &&
          <CorrectionRound
            price={draft.correctionRoundPrice}
            availableCorrections={draft.remainingCorrectionRounds()}
            used={correctionChecked}
            extraStyle={extraStyle}
            onChange={toggleCorrectionRound} />}
        <Approve
          user={user}
          draft={draft}
          correctionChecked={correctionChecked}
          onApprove={onApprove}
          enableApproveButton={enableApproveButton} />
      </div>
    </div>
  );
};


export default Summary;
