import * as SessionActions from 'actions/session';


const initialState = {
};


export default function session(state=initialState, action) {
  switch (action.type) {
    case SessionActions.SIGN_UP: {
      return { ...state, confirmDisabled: true };
    }
    case SessionActions.SIGN_UP_FAIL: {
      return { ...state, confirmDisabled: false };
    }
    case SessionActions.SIGN_UP_SUCCESS: {
      return { ...state, confirmDisabled: false };
    }
    default: {
      return state;
    }
  }
}
