export const INVALIDATE_QUERY = 'INVALIDATE_QUERY';

export function invalidateQuery(query) {
  return {
    type: INVALIDATE_QUERY,
    payload: {
      query,
    },
  };
}


export const CLEAR_QUERY_INVALIDATION = 'CLEAR_QUERY_INVALIDATION';

export function clearInvalidQuery(query) {
  return {
    type: CLEAR_QUERY_INVALIDATION,
    payload: {
      query,
    },
  };
}
