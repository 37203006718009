function _newKeysAdded(mutationsList) {
  for (let mutation of mutationsList) {
    if (mutation.addedNodes.length > 0) {
      return [...mutation.addedNodes]
        .some((n) => n.innerText != null && n.innerText.match(/{\.[.\w]+\.}/gm));
    }
  }
  return false;
}


function _onDomChange(next) {
  const observer = new MutationObserver((mutationsList) => {
    _newKeysAdded(mutationsList) ? next() : null;
  });
  observer.observe(document, { childList: true, subtree: true });
  return observer;
}


function _onChangeLocale(next) {
  window.i18n.onChangeLocale(next);
}


export default function setupLokalise() {
  document.dispatchEvent(new CustomEvent('lokalise-update-locale', { detail: window.i18n.locale }));

  _onDomChange(() => document.dispatchEvent(new Event('lokalise-update-elements')));
  _onChangeLocale((locale) => {
    document.dispatchEvent(new CustomEvent('lokalise-update-locale', { detail: locale }));
  });
}
