import React from 'react';
import { css, cx } from 'emotion';
import sv from '@drawbotics/style-vars';
import PropTypes from 'prop-types';

import Feature from '../Feature';
import Check from './Check';
import Counter from 'components/Counter';

import customServiceIcon from '../../images/icons/custom-service-icon.svg';
import customChecked from '../../images/icons/custom-checked.svg';


const styles = {
  productCard: css`
    background: ${sv.grey50};
    border: 1px solid ${sv.grey300};
    border-radius: ${sv.baseBorderRadius};
    padding: ${sv.basePaddingSmall} ${sv.basePadding};
    transition: all ${sv.baseTransitionTimeShort} ease-in-out;

    &:hover {
      cursor: pointer;
      background: ${sv.grey100};
      border-color: ${sv.grey400};
    }
  `,
  fullHeight: css`
    height: 100%;
  `,
  horizontal: css`
    display: flex;
    align-items: center;
    padding: ${sv.basePaddingSmall};

    & [data-element="footer"] {
      margin-top: 0;
      border-top: none;
      padding: 0;
      margin-left: auto;
    }

    & [data-element="component"] {
      margin-bottom: 0;
    }

    & [data-element="label"] {
      margin: 0 ${sv.baseMarginSmall};
    }
  `,
  selected: css`
    box-shadow: inset 0px 0px 0px 2px ${sv.brandGreen};
    border-color: ${sv.brandGreen};

    &:hover {
      border-color: ${sv.brandGreen};
    }
  `,
  label: css`
    text-align: center;
    color: ${sv.brandPrimary};
    font-weight: 600;
    font-size: 1.1rem;
    margin-bottom: calc(${sv.baseMarginSmall} / 2);
  `,
  note: css`
    text-align: center;
    color: ${sv.textPrimaryDark};
    font-size: 0.85rem;
  `,
  features: css`
    margin-top: ${sv.baseMargin};
    margin-bottom: ${sv.baseMarginSmall};
  `,
  feature: css`
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }

    @media ${sv.phoneXl} {
      display: none;
    }
  `,
  footer: css`
    margin-top: ${sv.baseMargin};
    border-top: 1px solid ${sv.grey300};
    padding: ${sv.basePaddingSmall} 0;
    display: flex;
    justify-content: center;
  `,
  formComponent: css`
    display: flex;
    justify-content: center;
    margin-bottom: ${sv.baseMarginSmall};
  `,
  icon: css`
    max-width: 200px;
    margin: auto;
    margin-top: ${sv.baseMarginSmall};
    padding: ${sv.basePaddingSmall};
  `,
  labelAndNote: css`
    display: flex;
    align-items: center;
    flex-direction: column;
  `,
  horizontalLandA: css`
    flex-direction: row;

    @media ${sv.phoneXl} {
      flex-direction: column;
      align-items: flex-start;
      margin-left: ${sv.baseMarginSmall};

      & [data-element="label"] {
        margin-left: 0;
        margin-bottom: 5px;
      }
    }
  `,
}


const ProductCard = ({
  name,
  formComponent,
  label,
  note,
  features,
  nonFeatures,
  onChange,
  withCounter,
  fullHeight,
  value,
  horizontal,
  image,
}) => {
  const allFeatures = [
    ...(features?.filter(Boolean) || []).map(f =>  ({ ...f, isCheck: true })),
    ...(nonFeatures || []).map(f =>  ({ ...f, isCheck: false }))
  ];
  return (
    <div
      className={cx(styles.productCard, {
        [styles.fullHeight]: fullHeight,
        [styles.selected]: value > 0,
        [styles.horizontal]: horizontal,
      })}
      id="productCard"
      onClick={() => onChange(null, name)}>
      {do{
        if (formComponent === "checkbox" || formComponent === 'radio') {
          <div className={styles.formComponent} data-element="component">
            <Check value={value > 0} round={formComponent === 'radio'} />
          </div>
        }
      }}
      <div className={cx(styles.labelAndNote, { [styles.horizontalLandA]: horizontal })}>
        <div className={styles.label} data-element="label">
          {label}
        </div>
        <div className={styles.note}>
          {note}
        </div>
      </div>
      {do{
        if (image) {
          <div className={styles.icon}>
            <img src={image} />
          </div>
        }
        else if ((! allFeatures || allFeatures.length === 0) && ! horizontal) {
          <div className={styles.icon}>
            <img src={allFeatures?.length === 0 ? customChecked : customServiceIcon} />
          </div>
        }
        else if (allFeatures) {
          <div className={styles.features}>
            {allFeatures.map((feature, i) => (
              <div key={i} className={styles.feature}>
                <Feature {...feature} />
              </div>
            ))}
          </div>
        }
      }}
      {do{
        if (withCounter) {
          <div className={styles.footer} data-element="footer">
            <Counter value={value} onChange={(v, k, e) => { e.stopPropagation(); onChange(v, name)}} />
          </div>
        }
      }}
    </div>
  );
};


ProductCard.propTypes = {
  formComponent: PropTypes.oneOf(['checkbox', 'radio']),
  label: PropTypes.string.isRequired,
  note: PropTypes.string,
  features: PropTypes.array,
  nonFeatures: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  withCounter: PropTypes.bool,
  fullHeight: PropTypes.bool,
  value: PropTypes.oneOfType([ PropTypes.number, PropTypes.bool ]).isRequired,
  horizontal: PropTypes.bool,
  name: PropTypes.string,
  image: PropTypes.string,
};


export default ProductCard;
