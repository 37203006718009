import v4 from 'uuid/v4';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';


function _updateDetails(oldDetails, newDetails) {
  const oldKeys = Object.keys(oldDetails);
  const newKeys = Object.keys(newDetails);
  const sameKeys = oldKeys.every((key) => key in newKeys);
  if (sameKeys) {
    return newDetails;
  }
  else {  // don't delete erased keys, set them to null instead
    const extraKeys = difference(oldKeys, newKeys);  // keys that were present
    return extraKeys.reduce((memo, key) => {
      return { ...memo, [key]: null };
    }, newDetails);
  }
}


export default function applyChanges(changes, estate, dontClone) {
  const { add, remove, update } = changes;
  const newEstate = dontClone ? estate : cloneDeep(estate);
  add.forEach((item) => newEstate.items.push({ ...item, id: v4() }));
  update.forEach((item) => {
    const itemToUpdate = newEstate.items.find((i) => i.id == item.id);
    if (itemToUpdate != null) {
      newEstate.items.splice(
        newEstate.items.findIndex((i) => i.id == item.id),
        1,
        Object.assign({}, itemToUpdate, {
          ...item,
          details: _updateDetails(itemToUpdate.details, item.details),
          id: itemToUpdate.id,
        }),
      );
    }
  });
  remove.forEach((item) => {
    newEstate.items = newEstate.items.filter((i) => i.id != item.id);
  });
  return newEstate;
}
