import React from 'react';
import { css, cx } from 'emotion';
import sv from '@drawbotics/style-vars';

import { createTranslate } from '~/utils/translation';


const t = createTranslate('pods.order.services');


const styles = {
  meta: css`
  `,
  hidden: css`
    margin-top: 0;
  `,
  trigger: css`
  `,
  label: css`
    color: ${sv.textSecondaryDark};
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  `,
  components: css`
    display: flex;
    flex-direction: column;
  `,
  section: css`
    flex: 1;

    &:last-child {
      margin-bottom: 0;
    }
  `,
};


class Meta extends React.Component {
  state = {
    componentsVisible: false,
  }

  render() {
    const {
      generatedComponents,
      label,
      noToggle = false,
      condition,
      withErrors,
    } = this.props;
    const { componentsVisible } = this.state;

    return (
      <div className={cx(styles.meta, { [styles.hidden]: condition !== null && ! noToggle })}>
        {do{
          if (condition === false || ! noToggle) {
            <div className={styles.trigger}>
              <span className={styles.label} onClick={() => this.setState({ componentsVisible: true })}>
                {label != null ?? t(label, null, label)}
              </span>
            </div>
          }
        }}
        {do{
          if (condition === false || noToggle || componentsVisible) {
            <div className={styles.components}>
              {generatedComponents.map((component, i) => (
                <div key={i} className={styles.section}>
                  <component.type {...component.props} withErrors={withErrors} />
                </div>
              ))}
            </div>
          }
        }}
      </div>
    );
  }
}


export default Meta;
