import React from 'react';
import autobind from 'autobind-decorator';
import { Grid, Cell } from 'react-ittsu/layout';
import gql from 'fraql';

import BaseServiceModal from '../BaseServiceModal';
import Paragraph from '../Paragraph';
import SectionTitle from '../SectionTitle';
import { ProductCard, Stripe, StripeGroup } from '../../forms';
import { priceItem } from '../../../utils/pricing';
import { specsToItems, itemsToSpecs } from '~/pods/order/utils/services/specs-to-items';
import applyChanges from '~/pods/order/utils/services/specs-to-items/apply-changes';
import { getItemsByService, merge } from '~/pods/order/utils';
import { createTranslate, translate as t } from '~/utils/translation';

import serviceImages from '~/pods/order/images/services';
import bAndWExample from '~/pods/order/images/services/plan-2d/style-examples/bAndW.jpg';
import standardExample from '~/pods/order/images/services/plan-2d/style-examples/standard.jpg';
import xyzExample from '~/pods/order/images/services/plan-2d/style-examples/xyz.jpg';
import premiumExample from '~/pods/order/images/services/plan-2d/style-examples/premium.jpg';


const tt = createTranslate('pods.order.services.plan_2d.order');


class Plan2D extends React.Component {

  static fragments = {
    Item: gql`
      fragment _ on Item {
        id
        details {
          ... on Plan2DDetails {
            style
            dimensions
            garden
            surfaceArea
            logo
            disclaimer
            view3d
          }
        }
      }
    `,
  }

  state = {
    specs: {
      stylePremium: false,
      styleStandard: false,
      styleBW: false,
      styleXyz: false,
      dimensions: false,
      garden: false,
      surfaceArea: false,
      view3d: false,
      logo: false,
      disclaimer: false,
    },
    price: undefined,
  };

  componentDidUpdate(prevProps) {
    const { specs } = this.state;
    const { service, estate } = this.props;
    const { service: prevService } = prevProps;
    if (service?.id === 'plan2d' && ! prevService) {
      const newSpecs = itemsToSpecs({ estate, service });
      const items = getItemsByService(estate).plan2d;
      const price = items != null ? items.reduce((memo, i) => memo + priceItem(i, this.props.sessionUser.currency.code, true), 0) : 0;
      this.setState({ specs: merge(specs, newSpecs), price });
    }
  }

  render() {
    const { onClickClose, isVisible, service, estate, sessionUser } = this.props;
    const { specs, price } = this.state;
    const { dimensions, garden, surfaceArea, view3d, disclaimer, logo } = specs;
    const { plan2d } = serviceImages;
    const standard = this._isStandardSelected();
    const items = getItemsByService(estate).plan2d;

    return (
      <BaseServiceModal
        isVisible={isVisible && service?.id === 'plan2d'}
        title={service?.id ? t(`services.${service.id}`) : ''}
        images={Object.values(plan2d)}
        price={sessionUser.addCurrency(price)}
        onClickConfirm={this._handleClickConfirm}
        confirmAsUpdate={items?.length > 0}
        onClickClose={onClickClose}
        addDisabled={price === 0}>
        <div className="childcontent">
          <Paragraph>
            {tt('description')}
          </Paragraph>
          <SectionTitle>
            {tt('select_style')}
          </SectionTitle>
          <Grid withVGutters withHGutters>
            <Cell size="1of2">
              <ProductCard
                name="styleStandard"
                label=""
                image={standardExample}
                note={tt('standard')}
                formComponent="radio"
                value={specs.styleStandard}
                onChange={this._handleClickOption} />
            </Cell>
            <Cell size="1of2">
              <ProductCard
                name="styleBW"
                label=""
                note={tt('black_and_white')}
                image={bAndWExample}
                formComponent="radio"
                value={specs.styleBW}
                onChange={this._handleClickOption} />
            </Cell>
            <Cell size="1of2">
              <ProductCard
                name="styleXyz"
                label=""
                note={tt('xyz')}
                image={xyzExample}
                formComponent="radio"
                value={specs.styleXyz}
                onChange={this._handleClickOption} />
            </Cell>
            <Cell size="1of2">
              <ProductCard
                name="stylePremium"
                label=""
                note={tt('premium')}
                image={premiumExample}
                formComponent="radio"
                value={specs.stylePremium}
                onChange={this._handleClickOption} />
            </Cell>
          </Grid>
          <SectionTitle>
            {tt('customize')}
          </SectionTitle>
          <Paragraph>
            {tt('price_computed')}
          </Paragraph>
          <div ref={(ref) => this.stripes = ref}>
            <StripeGroup>
              <Stripe
                title={tt('dimensions')}
                component="switch"
                disabled={! standard}
                value={dimensions}
                features={[{
                  text: tt('dimensions_features.first'),
                }]}
                onChange={(v) => this._handleChangeSpec('dimensions', v)} />
              <Stripe
                title={tt('garden')}
                component="switch"
                disabled={! standard}
                value={garden}
                features={[{ text: tt('garden_features.first') }]}
                onChange={(v) => this._handleChangeSpec('garden', v)} />
              <Stripe
                title={tt('surface_area')}
                component="switch"
                disabled={! standard}
                value={surfaceArea}
                features={[{ text: tt('surface_area_features.first') }]}
                onChange={(v) => this._handleChangeSpec('surfaceArea', v)} />
              <Stripe
                title={tt('view_3d')}
                component="switch"
                disabled={! standard}
                value={view3d}
                features={[{ text: tt('view_3d_features.first') }]}
                onChange={(v) => this._handleChangeSpec('view3d', v)} />
              <Stripe
                title={tt('logo')}
                component="switch"
                disabled={! standard}
                value={logo}
                features={[{ text: tt('logo_features.first') }]}
                onChange={(v) => this._handleChangeSpec('logo', v)} />
              <Stripe
                title={tt('disclaimer')}
                component="switch"
                disabled={! standard}
                value={disclaimer}
                features={[{ text: tt('disclaimer_features.first') }]}
                onChange={(v) => this._handleChangeSpec('disclaimer', v)} />
            </StripeGroup>
          </div>
        </div>
      </BaseServiceModal>
    );
  }

  @autobind
  _syncState() {
    const { estate, service } = this.props;
    const { specs } = this.state;
    const changes = specsToItems({ specs, estate, service });
    const updatedEstate = applyChanges(changes, estate);
    const items = getItemsByService(updatedEstate).plan2d;
    const price = items != null
      ? items
          .map((item) => item.estate != null ? item : { ...item, estate: updatedEstate })
          .reduce((memo, i) => memo + priceItem(i, this.props.sessionUser.currency.code, true), 0)
      : 0;
    this.setState({ price });
    return { changes, updatedEstate, price };
  }

  @autobind
  _handleClickOption(_, key) {
    const { specs } = this.state;
    const { dimensions, garden, surfaceArea, view3d, logo, disclaimer } = specs;
    const newSpecs = {
      ...specs,
      styleStandard: false,
      stylePremium: false,
      styleXyz: false,
      styleBW: false,
      [key]: ! specs[key],
    };
    const resetOptions = ! this._isStandardSelected(newSpecs);
    this.setState({
      specs: {
        ...newSpecs,
        dimensions: resetOptions ? false : dimensions,
        garden: resetOptions ? false : garden,
        surfaceArea: resetOptions ? false : surfaceArea,
        view3d: resetOptions ? false : view3d,
        logo: resetOptions ? false : logo,
        disclaimer: resetOptions ? false : disclaimer,
      }
    }, this._syncState);

    const modalContent = document.querySelector('[data-element="modal-content"]');
    modalContent.scroll({
      top: this.stripes?.offsetTop,
      behavior: 'smooth',
    });
  }

  @autobind
  _handleChangeSpec(specKey, specValue) {
    this.setState({ specs: {
      ...this.state.specs,
      [specKey]: specValue,
    }}, this._syncState);
  }

  @autobind
  _handleClickConfirm() {
    const { onModifyService, service } = this.props;
    const { changes } = this._syncState();
    onModifyService(changes, service);
  }

  _isStandardSelected(newSpecs) {
    const { specs: oldSpecs } = this.state;
    const specs = newSpecs || oldSpecs;
    return specs.styleStandard || specs.styleBW || specs.styleXyz || specs.stylePremium;
  }
}


export default Plan2D;
