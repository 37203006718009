const specs = {
  standard: {
    operation: 'create',
    create: {
      type: 'bool',
      product: 'presentation_standard',
    },
  },
};

const dependencies = {
  hard: [],
  soft: [],
};

export default { specs, dependencies };
