export default {
  Title: require('./Title').default,
  Note: require('./Note').default,
  MultiSelect: require('./MultiSelect').default,
  Meta: require('./Meta').default,
  FileUploader: require('./FileUploader').default,
  Input: require('./Input').default,
  LikeDislike: require('./LikeDislike').default,
  SingleSelect: require('./SingleSelect').default,
  MapSearch: require('./MapSearch').default,
  ViewOptions: require('./ViewOptions').default,
  EmailSelect: require('./EmailSelect').default,
}
