import gql from 'fraql';
import { useQuery } from 'urql';

import { User } from '~/types';

import { Estate } from '../../types';
import { ErrorType, buildError } from '../../utils';

interface EstateQueryData {
  estate: Estate;
}

interface EstateQueryVariables {
  estateId: string;
}

const EstateQuery = gql`
  query EstateQuery($estateId: ID!) {
    estate(estateId: $estateId) {
      id
      followers {
        id
        fullName @computed(type: ClientUser)
        email
        public
      }
    }
  }
`;

export function useFetchEstateFollowers(
  estateId: string,
): { isLoading: boolean; followers?: Array<User>; error?: ErrorType; refetch: VoidFunction } {
  const [res, reexecuteQuery] = useQuery<EstateQueryData, EstateQueryVariables>({
    query: EstateQuery,
    variables: { estateId },
  });

  const nonPublicUsers = res.data?.estate.followers.filter((user) => user.public !== true);

  return {
    isLoading: res.fetching,
    followers: nonPublicUsers,
    error: buildError(res.error),
    refetch: () => reexecuteQuery({ requestPolicy: 'network-only' }),
  };
}
