import React from 'react';
import get from 'lodash/get';

import { getItemsByService } from '~/pods/order/utils';


function componentNotFound(name) {
  console.error(`Component with name "${name}" does not exist.`);
  return null;
}


function computeCondition(condition, estate, answers, index) {
  const { required, conditions } = condition;
  const checks = conditions.map((condition) => {
    if (condition.type === 'service') {
      return !! estate.itemsByService[condition.value];
    }
    else if (condition.type === 'threshold') {
      const selectedAnswers = get(answers, condition.path.replace('%{n}', index), []) || [];
      return selectedAnswers.length < condition.value;
    }
    else if (condition.type === 'orderDetail') {
      const itemsByService = getItemsByService(estate);
      const selectedDetail = get(itemsByService, condition.path, null);
      return selectedDetail !== condition.value;
    }
    else {
      return null;
    }
  });
  return required === 'one' ? checks.some((c) => !! c) : checks.every((c) => !! c);
}


export function getComponent(questionComponents, componentDescription, props, index) {
  const { type } = componentDescription;
  if (type === 'component') {
    const { component: name, label } = componentDescription;
    const Component = questionComponents[name];
    if (! Component) return componentNotFound(name);
    return <Component label={label} />;
  }
  else if (type === 'question-component') {
    const {
      component: name,
      values,
      key: rawKey,
      label,
      max,
      min,
      accept,
      required,
      options,
    } = componentDescription;
    const key = rawKey.replace('%{n}', index);
    const QuestionComponent = questionComponents[name];
    if (! QuestionComponent) return componentNotFound(name);
    if (name === 'LikeDislike') {
      return (
        <QuestionComponent
          {...props}
          options={options}
          required={required}
          label={label}
          values={values}
          likedKey={componentDescription.key}
          dislikedKey={componentDescription.dislikedKey} />
      );
    }
    const sectionAnswer = get(props.answers, key, []);
    const arrayType = Array.isArray(sectionAnswer);
    const activeMeta = values?.find((value) =>
      (sectionAnswer === value.value  || typeof sectionAnswer !== 'boolean' &&
        (arrayType ? sectionAnswer?.includes(value.value) : sectionAnswer === value.value)) && value.meta)?.meta;
    if (activeMeta) {
      const metaComponent = activeMeta && getComponent(questionComponents, activeMeta, props, index);
      return (
        <QuestionComponent
          {...props}
          options={options}
          accept={accept}
          max={max}
          label={label}
          values={values}
          resultKey={key}
          required={required}
          metaComponent={metaComponent} />
      );
    }
    return (
      <QuestionComponent
        {...props}
        options={options}
        accept={accept}
        max={max}
        min={min}
        label={label}
        values={values}
        required={required}
        resultKey={key} />
    );
  }
  else if (type === 'component-group') {
    const { component: name, key, components: groupComponents, options } = componentDescription;
    const QuestionComponent = questionComponents[name];
    const groupAnswers = get(props.answers, key, []) || [];
    const generatedComponents = groupAnswers.map((_, i) =>
      groupComponents.map((componentDescription) =>
        getComponent(questionComponents, componentDescription, props, i))
    );
    return (
      <QuestionComponent
        {...props}
        options={options}
        rawComponents={groupComponents}
        components={generatedComponents}
        resultKey={key} />
      );
  }
  else if (type === 'meta') {
    const { components: metaComponentDescription, label, noToggle, condition } = componentDescription;
    const { Meta } = questionComponents;

    const satisfiesCondition = condition
      ? computeCondition(condition, props.estate, props.answers, index)
      : null;
    const generatedComponents = metaComponentDescription.map((componentDescription) =>
      getComponent(questionComponents, componentDescription, props, index));
    return (
      <Meta
        {...props}
        generatedComponents={generatedComponents}
        label={label}
        noToggle={noToggle}
        condition={satisfiesCondition} />
    );
  }
  else {
    console.warn(`Component type ${type} not supported`);
    return null;
  }
}


export function getRequiredKeys(components=[], answers, estate, index) {
  return components.reduce((keys, component) => {
    if (component.type === 'meta') {
      const meta = component;
      return [
        ...keys,
        ...getRequiredKeys(meta?.condition
          ? (computeCondition(meta.condition, estate, answers, index) ? [] : meta?.components)
          : meta?.components, answers, estate, index
        ),
      ];
    }
    if (component.required === 'all') {
      const { key: rawKey } = component;
      const key = rawKey.replace('%{n}', index);
      const sectionAnswer = get(answers, key, []);
      const arrayType = Array.isArray(sectionAnswer);
      const meta = component.values?.find((option) =>
        (sectionAnswer === option.value  || typeof sectionAnswer !== 'boolean' &&
          (arrayType ? sectionAnswer?.includes(option.value) : sectionAnswer === option.value)) && option.meta)?.meta;
      if (component.type === 'component-group') {
        const groupAnswers = get(answers, key, []) || [];
        return [ ...keys, ...groupAnswers.reduce((groupKeys, _, i) => [ ...groupKeys, ...getRequiredKeys(component.components, answers, estate, i) ], []) ];
      }
      if (meta) {
        if (! meta.required) {
          return [
            ...keys,
            ...getRequiredKeys(meta?.condition ? (computeCondition(meta.condition, estate, answers, index) ? [] : meta?.components) : meta?.components, answers, estate, index),
            key,
          ];
        }
        else if (meta.required === 'all') {
          return [ ...keys, key, ...meta.components.map((component) => component.key?.replace('%{n}', index))  ];
        }
        else if (meta.required === 'one') {
          return [ ...keys, key, meta.components.map((component) => component.key?.replace('%{n}', index))  ];
        }
      }
      return key ? [ ...keys, component.min ? { key, min: component.min(answers)} : key ] : [ ...keys, key, component.dislikedKey ];
    }
    else if (component.required === 'one') {
      const { key: rawKey } = component;
      const key = rawKey.replace('%{n}', index);
      return key ? [ ...keys, [ key ] ] : [ ...keys, [ key, component.dislikedKey ] ];
    }
    else {
      return keys;
    }
  }, []);
}


export function canAdvance(requiredKeys, answers) {
  return requiredKeys.reduce((total, requiredKey) => {
    if (requiredKey instanceof Array) {
      return requiredKey.some((key) =>
        get(answers, key) !== null
          && get(answers, key) !== undefined
          && (! Array.isArray(get(answers, key)) || get(answers, key).length > 0)) ? total + 1 : total;
    }
    else if (requiredKey instanceof Object) {
      const { key, min } = requiredKey;
      const answer = get(answers, key) || [];

      return answer !== null
        && answer !== undefined
        && (! Array.isArray(answer) || answer.length >= min) ? total + 1 : total;
    }
    else {
      return get(answers, requiredKey) !== null
        && get(answers, requiredKey) !== undefined
        && (! Array.isArray(get(answers, requiredKey)) || get(answers, requiredKey).length > 0) ? total + 1 : total;
    }
  }, 0) === requiredKeys.length;
}


export function filterDescription(description, estate) {
  let index = 0;
  return Object.values(description).reduce((memo, section) => {
    if (section.filter) {
      const { type, value } = section.filter;
      if (type === 'service' && estate.itemsByService[value]) {
        return memo;
      }
    }
    index = index + 1;
    return {
      ...memo,
      [index]: section,
    };
  }, {});
}
