import omit from 'lodash/omit';

import { readSession } from 'utils';
import { request } from 'utils/api/utils';
import { attachmentToServer } from './translators/attachments';
import { annotationToServer } from 'utils/api/translators/annotation';
import { projectItemFromServer } from 'utils/api/translators/project-item';
import { correctionRoundFromServer } from 'utils/api/translators/correction-round';
import { draftFromServer } from 'utils/api/translators/draft';


export const Project = {
  get(id) {
    return request('GET', `items/${id}`)
      .then((item) => ({
        ...projectItemFromServer(item),
        drafts: item.drafts.map((d) => ({ ...d, projectItem: id })).map(draftFromServer),
      }));
  },
};


export const Draft = {
  validate(id) {
    return request('POST', 'studio/validate_drafts', { draft_id: id });
  },
  get(id, itemId) {
    return request('GET', `drafts/${id}`)
      .then((result) => ({
        ...draftFromServer(result.draft),
        sessionUser: {
          id: readSession().user,
          permissions: result.permissions,
        },
        correctionRounds: result.correction_rounds.map(correctionRoundFromServer),
      }));
  },
};


export const Preview = {
  validate(id) {
    return request('POST', 'studio/validate_previews', { preview_id: id });
  },
  invalidate(id) {
    return request('POST', 'studio/invalidate_preview', { preview_id: id });
  },
};


export const Annotation = {
  create(data) {
    const annotation = omit(annotationToServer(data), 'id');
    return request('POST', 'studio/annotations', { annotation });
  },
  update(id, data) {
    const entity = data.toEntity ? data.toEntity() : data;
    const annotation = {
      ...annotationToServer(entity),
      id,
    };
    return request('PUT', `studio/annotations/${id}`, { annotation });
  },
  delete(id) {
    return request('DELETE', `studio/annotations/${id}`);
  },
};

export const Problem = {
  solve(annotationId, payload) {
    return request('PUT', `studio/annotations/${annotationId}/problems`, payload);
  },
}


export const Attachment = {
  async create(data) {
    const entity = data.toEntity ? data.toEntity() : data;
    const attachment = attachmentToServer(entity);
    const response = await request('POST', `attachments`, attachment);
    return response.message.attachment[0];
  },
  update(id, data) {
    const entity = data.toEntity ? data.toEntity() : data;
    const attachment = attachmentToServer(entity);
    return request('PUT', `attachments/${id}`, { ...attachment });
  },
  delete(id) {
    return request('DELETE', `attachments/${id}`);
  },
};


export default {
  Project,
  Draft,
  Preview,
  Annotation,
  Attachment,
};
