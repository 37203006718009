import { fork, put, call, takeLatest } from 'redux-saga/effects';

import * as ProjectActions from 'pods/studio/actions/project';
import { Project } from 'pods/studio/utils/api';


/**
 * REGULAR SAGAS
 */
function* loadProject(action) {
  const { id } = action.payload;
  try {
    const project = yield call(Project.get, id);
    yield put(ProjectActions.loadProjectSuccess(project));
  }
  catch (err) {
    console.error(err);
    yield put(ProjectActions.loadProjectFail(err));
  }
}


/**
 * WATCHERS
 */
function* watchLoadProject() {
  yield takeLatest(ProjectActions.LOAD_PROJECT, loadProject);
}


/**
 * ROOT
 */
export default function* root() {
  yield fork(watchLoadProject);
}
