import { Add, Conditional, ForEach, RelativeAdd, Switch } from 'drawbotics-pricer';


const description = {
  Base: [
    Switch('estate.projectType').is({
      'residential': [
        Add({ EUR: 1850, GBP: 1750, USD: 2220 }),
        Conditional('details.copywriting').equals(true).then([
          ForEach('details.numberOfLanguages').do(Add({ EUR: 350, GBP: 350, USD: 450 })),
        ]),
        ForEach('details.numberOfLanguages').threshold(1).do([
          Add({ EUR: 400, GBP: 400, USD: 500 }),
        ]),
      ],
      'commercial': [
        Add({ EUR: 2350, GBP: 2075, USD: 2820 }),
        Conditional('details.copywriting').equals(true).then([
          ForEach('details.numberOfLanguages').do(Add({ EUR: 350, GBP: 350, USD: 450 })),
        ]),
        ForEach('details.numberOfLanguages').threshold(1).do([
          Add({ EUR: 400, GBP: 400, USD: 500 }),
        ]),
      ],
    }),
  ],
  Delivery: [
    Switch('estate.delivery').is({
      'relaxed': RelativeAdd(-0.1).from('Base'),
      'standard': RelativeAdd(0).from('Base'),
      'express': RelativeAdd(0.5).from('Base'),
    })
  ],
};


export default description;
