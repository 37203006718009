import { Add, Switch, RelativeAdd, ForEach } from 'drawbotics-pricer';


const description = {
  Base: [
    ForEach('details.numberOfImages').do([
      Add({ EUR: 100, GBP: 90, USD: 120 }),
    ]),
  ],
  Delivery: [
    Switch('estate.delivery').is({
      'relaxed': RelativeAdd(-0.1).from('Base'),
      'standard': RelativeAdd(0).from('Base'),
      'express': RelativeAdd(0.5).from('Base'),
    })
  ],
};


export default description;
