import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import gql from 'fraql';
import { cx } from 'emotion';
import { Button, Paragraph } from 'react-ittsu/components';
import { SimplifiedGenerator } from 'questions-generator';
import omit from 'lodash/omit';
import compose from 'lodash/flowRight';
import { connect } from 'react-redux';

import { showAlert } from '~/actions';
import { translate as t, createTranslate } from '~/utils/translation';
import Title from '../../components/Title';
import ItemDetails from '../../utils/briefing/item-details';
import ConfirmModal from '../../components/ConfirmModal';
import { descriptions } from '../../utils/briefing/item-to-questions';
import { minimal as questionsComponents } from '../../utils/briefing/item-to-questions/components';
import { deepOmit } from '../../utils';

import styles from './styles';


const tt = createTranslate('pods.order.routes.briefing_existing');


const ItemQuestions = ({
  item,
  estate,
  details,
  onChangeDetails,
  setCompleted,
  hasErrors,
}) => {
  const descriptionForService = descriptions[item.product.custom
    ? 'customProduct'
    : item.service.id
  ];
  const description = descriptionForService[estate.projectType.toLowerCase()]
    || descriptionForService.residential;

  const handleOnCompleted = (isCompleted) => {
    setCompleted(item.id, isCompleted);
  }

  return (
    <SimplifiedGenerator
      showRequired={hasErrors}
      components={questionsComponents}
      estate={estate}
      description={description}
      values={details}
      setCompleted={handleOnCompleted}
      onChange={onChangeDetails} />
  );
};


const BriefingExisting = ({
  estate,
  confirmEstateBriefing,
  confirmItemBriefing,
  showAlert,
  updateItems,
  updateEstate,
  goToEstate,
}) => {
  const { items } = estate;
  const [ activeItem, setActiveItem ] = useState(items[0].id);
  const [ isConfirmModalOpen, setIsConfirmModalOpen ] = useState(false);
  const [ hasErrors, setHasErrors ] = useState(false);
  const [ floating, setFloating ] = useState(false);
  const sidebarRef = useRef();
  const itemsRef = useRef();
  const panelRefs = items.reduce((memo, item) => ({ ...memo, [item.id]: useRef() }), {});

  const [ details, setDetails ] = useState(items.reduce((memo, item) => ({
    ...memo, [item.id]: omit(item.details, '__typename') ?? {},
  }), {}));
  const [ completedItems, setCompletedItems ] = useState(items.reduce((memo, item) => 
    item.jsonMeta?.briefingCompleted ? [ ...memo, item.id ] : memo, []));

  const handleSaveBriefing = async (options) => {
    const completedItems = items.map((item) => ({
      id: item.id,
      details: deepOmit(details[item.id], ['__typename', 'filename', 'url']),
      metadata: JSON.stringify({ briefingCompleted: true, briefingOngoing: false }),
      serviceId: item.service.id,
      custom: item.product.custom,
      productSlug: item.product.slug,
    }));
    await updateItems(completedItems);
    setIsConfirmModalOpen(false);

    if (! options?.skipMessage) {
      showAlert('DETAILS_SAVED', 'success', tt('information_saved'));
    }
  };

  const handleConfirmBriefing = async () => {
    await handleSaveBriefing({ skipMessage: true });
    await confirmEstateBriefing(estate);
    for (const item of items) {
      await confirmItemBriefing(item);
    }
    window.scrollTo(0, 0);
    setIsConfirmModalOpen(false);
    showAlert('BRIEFING_CONFIRMED', 'success', tt('briefing_confirmed'));
    goToEstate();
  };

  const handleSetCompleted = (id, add) => {
    if (add && ! completedItems.includes(id)) {
      setCompletedItems([ ...completedItems, id ]);
    }
    else if (! add) {
      setCompletedItems(completedItems.filter((itemId) => itemId !== id));
    }
  };

  const handleScroll = () => {
    if (sidebarRef.current == null || itemsRef.current == null) {
      return;
    }
    const { top, height } = sidebarRef.current.getBoundingClientRect();
    const { bottom: itemsBottom } = itemsRef.current.getBoundingClientRect();
    if (top < 30 && height < itemsBottom) {
      setFloating(true);
    }
    else {
      setFloating(false);
    }

    const currentItem = Object.keys(panelRefs).find((itemId) => {
      const { y } = panelRefs[itemId].current.getBoundingClientRect();
      return (window.scrollY + window.innerHeight / 3) > (y + window.scrollY);
    });

    setActiveItem(currentItem);
  };

  const handleClickSidebarItem = (itemId) => {
    const { y } = panelRefs[itemId].current.getBoundingClientRect();
    window.scrollTo({ left: 0, top: window.scrollY + y - 30, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const briefingsCompleted = completedItems.length === items.length;

  return (
    <div className={styles.briefingExisting}>
      <Title className={styles.title}>{tt('briefing')}</Title>
      <Paragraph className={styles.subtitle}>
        {tt('fill_all_info')}
      </Paragraph>
      <div className={styles.container}>
        <div
          className={styles.floatingSidebar}
          ref={sidebarRef}>
          <div className={cx(styles.sidebar, { [styles.floating]: floating })}>
            {items.map((item, i) => (
              <div
                key={item.id}
                onClick={() => handleClickSidebarItem(item.id)}
                className={cx(styles.sidebarItem, {
                  [styles.active]: activeItem === item.id,
                  [styles.completed]: completedItems.includes(item.id),
                })}>
                <div data-element="bubble" className={styles.bubble}>
                  {completedItems.includes(item.id) ? <i className="ion-android-done" /> : i + 1}
                </div>
                <div className={styles.name}>
                  {item.product.custom ? item.product.name : t(`services.${item.service.id}`)}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.items} ref={itemsRef}>
          {items.map((item, i) => (
            <div key={item.id} className={styles.itemPanel} ref={panelRefs[item.id]}>
              <div className={styles.panelTitle}>
                <div
                  data-element="bubble-container"
                  className={cx({
                    [styles.active]: activeItem === item.id,
                    [styles.completed]: completedItems.includes(item.id),
                  })}>
                  <div data-element="bubble" className={styles.bubble}>
                    {completedItems.includes(item.id) ? <i className="ion-android-done" /> : i + 1}
                  </div>
                </div>
                <span className={activeItem === item.id ? styles.boldTitle : null}>
                  {item.product.custom ? item.product.name : t(`services.${item.service.id}`)}
                </span>
              </div>
              <div className={styles.panelContent}>
                <ItemQuestions
                  hasErrors={hasErrors}
                  item={item}
                  onChangeDetails={(d) => setDetails({ ...details, [item.id]: d })}
                  setCompleted={handleSetCompleted}
                  details={details[item.id]}
                  estate={estate} />
              </div>
            </div>
          ))}
          <Button
            category="success"
            onClick={() => {
              if (briefingsCompleted) {
                setIsConfirmModalOpen(true);
              }
              else {
                setHasErrors(true);
                showAlert('INCOMPLETE_DETAILS', 'error', tt('incomplete_details'));
                window.scrollTo(0, 0);
              }
            }}>
            {t('pods.order.routes.briefing_item.confirm_briefing')}
          </Button>
        </div>
      </div>
      <ConfirmModal
        onClickSave={handleSaveBriefing}
        onClickConfirm={handleConfirmBriefing}
        onClickClose={() => setIsConfirmModalOpen(false)}
        visible={isConfirmModalOpen} />
    </div>
  );
};


BriefingExisting.fragments = {
  Estate: () => gql`
    fragment _ on Estate {
      id
      items {
        briefingConfirmedAt
        briefingConfirmator {
          firstName
          lastName
        }
        reference
        product {
          id @client(type: Product)
          slug
          custom
          name
          requiredAttachments {
            content
            locale
          }
        }
        service {
          id @client(type: ProductService)
          slug
        }
        jsonMeta @client(type: Item)
        ${ItemDetails}
      }
    }
  `,
};


BriefingExisting.propTypes = {
  estate: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        service: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
    })),
  }),
  updateItems: PropTypes.func.isRequired,
  updateEstate: PropTypes.func.isRequired,
  confirmEstateBriefing: PropTypes.func.isRequired,
  confirmItemBriefing: PropTypes.func.isRequired,
};


const mapDispatchToProps = {
  showAlert,
};


export default compose(
  connect(null, mapDispatchToProps),
)(BriefingExisting);