import gql from 'fraql';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import React, { useEffect, useState } from 'react';
import { Cell, Grid } from 'react-ittsu/layout';

import ProductCard from '~/pods/order/components/forms/ProductCard';
import BaseServiceModal from '~/pods/order/components/ServiceModal/BaseServiceModal';
import Paragraph from '~/pods/order/components/ServiceModal/Paragraph';
import SectionTitle from '~/pods/order/components/ServiceModal/SectionTitle';
import serviceImages from '~/pods/order/images/services';
import { getFeaturesForProduct, getItemsByService, openIntercom } from '~/pods/order/utils';
import {
  applyChanges,
  itemsToSpecs,
  specsToItems,
} from '~/pods/order/utils/services/specs-to-items';
import { createTranslate, translate as t } from '~/utils/translation';
import { usePrevious } from '~/utils/use-previous';

// @ts-ignore
import { priceItem } from '../../../utils/pricing';

const tt = createTranslate('pods.order.services.presentation.order');
const tm = createTranslate('pods.order.components.service_modal');

interface Product {
  id: string;
  service: { id: string };
  slug: string;
  custom: boolean;
  name: string;
}

interface Service {
  abstract: boolean;
  availableFor: Array<string>;
  category: { slug: string };
  id: string;
  products: Array<Product>;
  slug: string;
}

interface Item {
  id: string;
  price: number;
  basePrice: number;
  estate: unknown;
  details: Record<string, any>;
  product: Product;
  service: Service;
}

interface PresentationProps {
  service?: Service;
  isVisible: boolean;
  sessionUser: {
    addCurrency: (price: string | number) => string;
    currency: {
      code: string;
    };
  };
  onClickClose: VoidFunction;
  estate: unknown;
  onModifyService: any;
}

export const Presentation = ({
  isVisible,
  service,
  sessionUser,
  onClickClose,
  estate,
  onModifyService,
}: PresentationProps) => {
  const [hasCustom, setHasCustom] = useState(false);
  const [specs, setSpecs] = useState({ standard: false });
  const [price, setPrice] = useState(0);
  const prevService = usePrevious(service);

  const items = (getItemsByService(estate).presentation ?? []) as Array<Item>;
  const customItem = items.find((i) => i.product.custom);
  const customFeatures = customItem != null ? getFeaturesForProduct(customItem.product) : undefined;

  useEffect(() => {
    if (service?.id !== 'presentation' || prevService != null) return;

    const newSpecs = merge(specs, itemsToSpecs({ estate, service }));
    const price = items.reduce((memo, i) => memo + priceItem(i, sessionUser.currency.code), 0);

    setPrice(price);
    setHasCustom(customItem != null);

    if (!isEqual(specs, newSpecs)) setSpecs(specs);
  });

  const _syncState = () => {
    if (service?.id !== 'presentation') return;

    const changes = specsToItems({ specs, estate, service });
    const updatedEstate = applyChanges(changes, estate);
    const items = (getItemsByService(updatedEstate).presentation ?? []) as Array<Item>;

    const price = items
      .map((item) => (item.estate != null ? item : { ...item, estate: updatedEstate }))
      .reduce((memo, i) => memo + priceItem(i, sessionUser.currency.code, true), 0);

    setPrice(price);
    return { changes, updatedEstate, price };
  };

  useEffect(() => {
    _syncState();
  });

  const _resetSpecs = () => setSpecs({ standard: false });

  const _handleClickConfirm = () => {
    const extraRemove = hasCustom
      ? []
      : [
          ((getItemsByService(estate).presentation ?? []) as Array<Item>).find(
            (item) => item.product.custom,
          ),
        ];
    const { changes } = _syncState()!;
    onModifyService({ ...(changes as any), remove: [...(changes as any).remove, ...extraRemove] });
  };

  const _handleClickCustom = () => {
    if (!hasCustom) {
      openIntercom();
      _resetSpecs();
    } else {
      _resetSpecs();
      setHasCustom(true);
    }
  };

  return (
    <BaseServiceModal
      isVisible={isVisible && service?.id === 'presentation'}
      title={service?.id ? t(`services.${service.id}`) : ''}
      images={Object.values(serviceImages.presentation)}
      price={sessionUser.addCurrency(price)}
      onClickConfirm={_handleClickConfirm}
      confirmAsUpdate={items?.length > 0}
      onClickClose={onClickClose}
      addDisabled={price === 0}>
      <div className="childContent">
        <Paragraph>{tt('description')}</Paragraph>
        <SectionTitle>{tt('select_type')}</SectionTitle>
        <Grid withVGutters withHGutters>
          <Cell size="1of2">
            <ProductCard
              name="custom"
              label={hasCustom ? `${customItem?.product.name}` : tm('custom')}
              note={
                hasCustom && customItem
                  ? `${sessionUser.addCurrency(customItem?.price)}`
                  : tm('contact_us')
              }
              formComponent="radio"
              fullHeight
              features={customFeatures}
              onChange={_handleClickCustom}
              value={hasCustom}
            />
          </Cell>
        </Grid>
      </div>
    </BaseServiceModal>
  );
};

Presentation.fragments = {
  Item: gql`
    fragment _ on Item {
      id
      # details {
      # }
      product {
        id @client(type: Product)
        custom
        name
        features {
          locale
          content
        }
      }
    }
  `,
};
