import { v4 } from 'uuid';
import dayjs from 'dayjs';
import {
  createEntities,
  updateEntities,
  updateEntityId,
  deleteEntities,
} from 'entman';

import schemas from 'schemas';


export const CREATE_ANNOTATION = 'CREATE_ANNOTATION';

export function createAnnotation(preview, user, position) {
  const [x, y] = position;
  return createEntities(schemas.Annotation, 'payload.data', {
    type: CREATE_ANNOTATION,
    payload: {
      data: {
        comment: '',
        attachments: [],
        id: v4(),
        date: dayjs(),
        preview: preview.id,
        createdBy: user.id,
        shape: { x, y },
        isDangling: true,
        isDirty: true,
      },
    },
    meta: {
      invalidatePreview: preview.id,
    },
  });
}


export const UPDATE_ANNOTATION = 'UPDATE_ANNOTATION';

export function updateAnnotation(id, data) {
  return updateEntities(schemas.Annotation, id, 'payload.data', {
    type: UPDATE_ANNOTATION,
    payload: {
      data: {
        isDirty: true,
        ...data,
      },
    },
  });
}


export const DELETE_ANNOTATION = 'DELETE_ANNOTATION';

export function deleteAnnotation(annotation) {
  return deleteEntities(schemas.Annotation, annotation.id, {
    type: DELETE_ANNOTATION,
    payload: {
      annotation,
    },
    meta: {
      invalidatePreview: annotation.preview.id,
    },
  });
}


export const SAVE_ANNOTATION = 'SAVE_ANNOTATION';

export function saveAnnotation(annotation, closeAnnotation=true) {
  return {
    type: SAVE_ANNOTATION,
    payload: {
      annotation,
      closeAnnotation,
    },
    meta: {
      invalidatePreview: annotation.preview.id,
    },
  };
}


export const SAVE_ANNOTATION_SUCCESS = 'SAVE_ANNOTATION_SUCCESS';

export function saveAnnotationSuccess(oldId, newId) {
  return updateEntityId(schemas.Annotation, oldId, newId, {
    type: SAVE_ANNOTATION_SUCCESS,
  });
}
