import { Category, useAlert } from '@drawbotics/react-drylus';
import { HookService, onDidMount, ref, state } from '@tokamakjs/react';
import { Subscription } from 'rxjs';

import { createTranslate } from '~/utils/translation';

import { Invitation, Teammate, User } from '../../api/domain';
import { TeammatesService, UserService } from '../../services';

const tt = createTranslate('pods.profile.routes.settings');

@HookService()
export class SettingsController {
  @state isTeammatesLoading = true;
  @state teammates: Array<Teammate> = [];
  @state isUserLoading = true;
  @state isUserSaving = false;
  @state teammateSavingId: string | undefined;
  @state user: User | undefined;
  @state isCreatingInvitation = false;
  @state teammateSendingInvitation: string | undefined;
  private _showAlert = useAlert().showAlert;

  @ref private _subs = new Subscription();

  constructor(
    private readonly _teammatesService: TeammatesService,
    private readonly _userService: UserService,
  ) {}

  public async updateUser(newUser: { firstName?: string; lastName?: string }) {
    this.isUserSaving = true;
    const updatedUser = await this._userService.updateUser(newUser);
    if (updatedUser == null) {
      this._showAlert({
        category: Category.DANGER,
        text: tt('something_went_wrong_profile'),
      });
    } else {
      this._showAlert({ category: Category.SUCCESS, text: tt('profile_updated') });
    }
    this.isUserSaving = false;
  }

  public async updateTeammate(id: string, teammate: Partial<Teammate>) {
    this.teammateSavingId = id;
    const updatedTeammate = await this._teammatesService.updateTeammate(id, teammate);
    if (updatedTeammate == null) {
      this._showAlert({
        category: Category.DANGER,
        text: tt('something_went_wrong_role'),
      });
    } else {
      this._showAlert({ category: Category.SUCCESS, text: tt('teammate_role_updated') });
    }
    this.teammateSavingId = undefined;
  }

  public async createInvitation(invitation: Partial<Invitation>) {
    this.isCreatingInvitation = true;
    const newInvitation = await this._teammatesService.createInvitation(invitation);
    if (newInvitation == null) {
      this._showAlert({
        category: Category.DANGER,
        text: tt('something_went_wrong_invitation'),
      });
    } else {
      this._showAlert({ category: Category.SUCCESS, text: tt('invitation_sent') });
    }
    this.isCreatingInvitation = false;
  }

  public async resendInvitation(id: string) {
    this.teammateSendingInvitation = id;
    const invitation = await this._teammatesService.resendInvitation(id);
    if (invitation == null) {
      this._showAlert({
        category: Category.DANGER,
        text: tt('something_went_wrong_invitation'),
      });
    } else {
      this._showAlert({ category: Category.SUCCESS, text: tt('invitation_sent') });
    }
    this.teammateSendingInvitation = undefined;
  }

  @onDidMount()
  protected initSubscriptions(): VoidFunction {
    this._subs.add(this._teammatesService.teammates$.subscribe((v) => (this.teammates = v)));
    this._subs.add(
      this._teammatesService.isLoading$.subscribe((v) => (this.isTeammatesLoading = v)),
    );

    return () => {
      this._subs.unsubscribe();
    };
  }

  @onDidMount()
  protected loadTeammates() {
    this._teammatesService.loadTeammates();
  }

  @onDidMount()
  protected loadUser() {
    this._userService.loadUser().then((user) => {
      if (user == null) {
        this._showAlert({
          category: Category.DANGER,
          text: tt('something_went_wrong_loading'),
        });
      } else {
        this.user = user;
        this.isUserLoading = false;
      }
    });
  }
}
