import React from 'react';


const radius = 13;


const Circle = ({
  cx,
  cy,
  index,
  active,
  onClick,
}) => {
  const styleText = {
    fill: '#FFFFFF',
    fontSize: '15px',
    textAnchor: 'middle',
    fontFamily: 'brandon-grotesque',
    cursor: 'pointer',
    userSelect: 'none',
  };
  const styleCircle = {
    fill: '#db4c60',
    cursor: 'pointer',
  };

  const style = active ? { zIndex: 10 } : {};

  return (
    <g onClick={onClick} style={style}>
      <circle cx={cx} cy={cy} r={radius} style={styleCircle} />
      <text x={cx} y={cy + 6} style={styleText}>{index + 1}</text>
    </g>
  );
};


const AnnotationDots = ({
  width,
  height,
  containerWidth,
  containerHeight,
  annotations,
  draftIsEditable,
  isFullScreen,
  activeAnnotation,
  onClickDot,
}) => {
  return(
    <svg
      x={(containerWidth - width) / 2}
      y={isFullScreen ? (containerHeight - height) / 2 : 0}
      width={width}
      height={height}>
      {annotations.map((annotation, i) => {
        // For compatibility with rectangular shapes of old correction studio
        const x = annotation.shape.x;
        const y = annotation.shape.y;
        return (
          <Circle
            key={i}
            index={i}
            cx={x * width}
            cy={y * height}
            active={activeAnnotation === annotation.id}
            onClick={() => {
              onClickDot(annotation.id);
              window.dispatchEvent(new Event('resize'));
            }} />
        );
      })}
    </svg>
  );
};


export default AnnotationDots;
