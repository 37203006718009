export default (entities) => ({
  signIn(email, password) {
    return entities.Session.signIn(email, password);
  },
  signUp(user, organization) {
    return entities.Session.signUp(user, organization);
  },
  updateSessionUser(data) {
    return entities.Session.updateSessionUser(data);
  },
  changeLanguage(data) {
    return entities.Session.changeLanguage(data);
  },
  requestNewPassword(email) {
    return entities.Session.requestNewPassword(email);
  },
  updatePassword(newPassword, newPasswordConfirmation, token) {
    return entities.Session.updatePassword(newPassword, newPasswordConfirmation, token);
  },
  guestSignUp(user, item) {
    return entities.Session.guestSignUp(user, item);
  },
  confirmEmail(token) {
    return entities.Session.confirmEmail(token);
  },
});
