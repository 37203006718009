import omit from 'lodash/omit';
import mergeWith from 'lodash/mergeWith';

import { translate as t } from '~/utils/translation';


export function getPriceLocale(price, currency) {
  return t('price_with_currency', {
    price: price || ' — ',
    currency: do {
      if (currency === 'EUR') {
        '€';
      }
      else if (currency === 'USD') {
        '$';
      }
      else if (currency === 'GBP') {
        '£';
      }
      else {
        currency;
      }
    },
  });
}


export function cleanFileName(fileName) {
  const cleaned = fileName.replace('./', '').replace('.js', '');
  return cleaned.replace(' ', '');  // Return the cleaned string capitalized
}


export function formatSecondsToMinutes(seconds, separator=':') {
  const minutes = Math.floor(seconds / 60);
  const secondsRemaining = `${seconds - minutes * 60}`.padEnd(2, '0');
  return `${minutes}${separator}${secondsRemaining}`;
}


export function deepOmit(object, fields) {
  if (object === null) return object;
  if (Array.isArray(object)) {
    return object.map((value) => deepOmit(value, fields));
  }
  else if (typeof object === 'object') {
    const result = omit(object, fields);
    return Object.keys(result).reduce((memo, key) => ({ ...memo, [key]: deepOmit(object[key], fields) }), {});
  }
  else {
    return object;
  }
}


export function openIntercom() {
  window.Intercom ? window.Intercom('show') : null;
}


export function merge(a, b) {
  return mergeWith({}, a, b, (target, src) => {
    return src == null ? target : src;
  });
}


export * from './estate-utils';
export * from './services';
export * from './mapbox-utils';
