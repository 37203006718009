import React from 'react';
import { Button, Icon } from 'react-ittsu/components';
import autobind from 'autobind-decorator';
import { Modal, Button as DrylusButton, Title, Callout, Category, Flex, FlexItem, FlexSpacer, Tier, FlexJustify, FlexDirection } from '@drawbotics/react-drylus';

import Spinner from '~/components/Spinner';

import SidebarLayout from '../../components/SidebarLayout';
import EstateSidebar from '../../components/EstateSidebar';
import { EstateEnums } from '../../utils/estate-enums';
import { createTranslate, translate as t } from '~/utils/translation';

import confirmPayment from '~/pods/order/images/confirm-payment.svg';

import styles from './styles';


const tt = createTranslate('pods.order.routes.recap');


const PercentageBar = ({
  percentage,
}) => {
  return (
    <div className={styles.outerPercentage}>
      <div className={styles.innerPercentage} style={{ width: `${percentage * 100}%` }} />
    </div>
  );
};


const CompletedCheck = () => {
  return (
    <div className={styles.completedCheck}>
      <Icon name="check-bold" />
    </div>
  );
};


const QuickPayModal = ({
  isShown,
  credits,
  onClickPay,
  onClickClose,
}) => {
  return (
    <Modal style={{ maxWidth: 400 }} title={tt('quick_checkout.title')} visible={isShown} onClickClose={onClickClose} footer={
      <Flex justify={FlexJustify.END}>
        <FlexItem>
          <DrylusButton tier={Tier.TERTIARY} onClick={onClickClose}>{tt('quick_checkout.cancel')}</DrylusButton>
        </FlexItem>
        <FlexSpacer />
        <FlexItem>
          <DrylusButton category={Category.BRAND} onClick={onClickPay}>{tt('quick_checkout.button')}</DrylusButton>
        </FlexItem>
      </Flex>
    }>
      <Flex direction={FlexDirection.VERTICAL}>
        <FlexItem>
          <img src={confirmPayment} />
        </FlexItem>
        <FlexItem>
          <Title size={4}>
            {tt('quick_checkout.note', { credits })}  
          </Title>
        </FlexItem>
        <FlexItem>
          <Callout category={Category.WARNING}>
            {tt('quick_checkout.text')}
          </Callout>
        </FlexItem>
      </Flex>
    </Modal>
  );
};


class Recap extends React.Component {

  state = {
    showQuickPayModal: false,
  };

  render() {
    const { recapData, history, estateId, sessionUser } = this.props;
    const { showQuickPayModal } = this.state;
    const { loading, estate } = recapData;
    if (loading) {
      return <Spinner size={50} containerStyle={{ margin: 'auto' }} text={t('pods.order.loading')} />
    }
    if (estate == null) {
      window.location.reload();
      return null;  // Shame on Apollo
    }
    return (
      <SidebarLayout
        sidebar={() => (
          <EstateSidebar estate={estate} mobileWithBackground mobileButtonText={tt('view_summary')} />
        )}
        content={() => (
          <div className={styles.recap}>
            <h1 className={styles.recapTitle}>{tt('recap', { project_name: estate.name })}</h1>
            <div className={styles.steps}>
              <div className={styles.step}>
                <h2 className={styles.stepTitle}>
                  <strong>{tt('step', { number: 1 })}</strong> {tt('order')} {estate.orderCompleted ? <CompletedCheck /> : null}
                </h2>
                <div className={styles.stepDescription}>
                  {tt('order_description')}
                </div>
                {do {
                  if ( ! estate.orderCompleted) {
                    <div className={styles.stepPercentage}>
                      <PercentageBar percentage={estate.orderProgress} />
                    </div>
                  }
                }}
                <div className={styles.stepActions}>
                  {do {
                    if (estate.orderCompleted  && ! estate.paymentCompleted) {
                      <Button
                        round
                        variation="inversed"
                        category="primary"
                        onClick={() => history.push(`/order/new/${estateId}/property-type`)}>
                        {tt('modify')}
                      </Button>
                    }
                    else if (! estate.paymentCompleted) {
                      <Button
                        round
                        category="primary"
                        onClick={() => history.push(`/order/new/${estateId}/${estate.lastVisitedStep}`)}>
                        {tt('continue')}
                      </Button>
                    }
                  }}
                </div>
              </div>
              <div className={styles.step}>
                <h2 className={styles.stepTitle}>
                  <strong>{tt('step', { number: 2 })}</strong> {tt('payment')} {estate.paymentCompleted ? <CompletedCheck /> : null}
                </h2>
                <div className={styles.stepDescription}>
                  {tt('payment_description')}
                </div>
                {do {
                  if (estate.orderCompleted && ! estate.paymentCompleted) {
                    <div className={styles.stepPercentage}>
                      <PercentageBar percentage={estate.paymentProgress} />
                    </div>
                  }
                }}
                <div className={styles.stepActions}>
                {do {
                  if (estate.orderCompleted && estate.paymentProgress === 0) {
                    <Button round category="primary" onClick={this._handleStartPayment}>
                      {tt('continue')}
                    </Button>
                  }
                  else if (estate.orderCompleted && ! estate.paymentCompleted && estate.paymentProcess !== 1) {
                    <Button
                      round
                      variation="inversed"
                      category="primary"
                      onClick={this._handleStartPayment}>
                      {tt('modify')}
                    </Button>
                  }
                }}
                {do {
                  if (estate.propertyType === EstateEnums.PropertyType.EXISTING_PROPERTY && ! estate.paymentCompleted) {
                    <span style={{ marginLeft: 15 }}>
                      <Button round category="primary" onClick={() => this.setState({ showQuickPayModal: true })}>
                        {tt('pay_with_credits')}
                      </Button>
                    </span>
                  }
                }}
                </div>
              </div>
              <div className={styles.step}>
                <h2 className={styles.stepTitle}>
                  <strong>{tt('step', { number: 3 })}</strong> {tt('briefing')} {estate.briefingCompleted ? <CompletedCheck /> : null}
                </h2>
                <div className={styles.stepDescription}>
                  {tt('briefing_description')}
                </div>
                {do {
                  if (estate.orderCompleted && estate.paymentCompleted && ! estate.briefingCompleted) {
                    <div className={styles.stepPercentage}>
                      <PercentageBar percentage={estate.briefingProgress} />
                    </div>
                  }
                }}
                <div className={styles.stepActions}>
                  {do {
                    if (estate.briefingProgress !== 0 && ! estate.briefingCompleted) {
                      <Button
                        round
                        variation="inversed"
                        category="primary"
                        onClick={() => history.push(`/order/briefing/${estateId}`)}>
                        {tt('modify')}
                      </Button>
                    }
                    else if (estate.orderCompleted && estate.paymentCompleted && ! estate.briefingCompleted) {
                      <Button
                        round
                        category="primary"
                        onClick={() => history.push(`/order/briefing/${estateId}`)}>
                        {tt('continue')}
                      </Button>
                    }
                  }}
                </div>
              </div>
            </div>
            <QuickPayModal
              isShown={showQuickPayModal}
              credits={sessionUser.credits}
              onClickPay={this._handleQuickPay}
              onClickClose={() => this.setState({ showQuickPayModal: false })} />
          </div>
        )} />
    );
  }

  @autobind
  async _handleStartPayment() {
    const { initiateSalesOrder, initiateDirectPayment, estateId, recapData } = this.props;
    const { estate } = recapData;
    let paymentProcess = null;
    if (estate.paymentProcess) {
      paymentProcess = estate.paymentProcess;
    }
    else if (estate.propertyType === EstateEnums.PropertyType.EXISTING_PROPERTY) {
      const { data: result } = await initiateDirectPayment({ variables: { estateId }});
      paymentProcess = result.payload.paymentProcess;
    }
    else {
      const { data: result } = await initiateSalesOrder({ variables: { estateId } });
      paymentProcess = result.payload.paymentProcess;
    }
    window.location = `/order/payment/${paymentProcess.id}/address`;
  }

  @autobind
  async _handleQuickPay() {
    const { payWithCredits, estateId } = this.props;
    const { data: result } = await payWithCredits({ variables: { estateId } });
    window.location = `/order/transaction/${result.payload.paymentProcess.downPaymentTransaction.transactionToken}/confirmation`;
  }

}


export default Recap;
