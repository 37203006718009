import { createEntities, updateEntities } from 'entman';
import { startLoading, stopLoading } from 'loading-manager';

import schemas from 'schemas';


export const SIGN_IN = 'SIGN_IN';

export function signIn(email, password, rememberMe) {
  return {
    type: SIGN_IN,
    payload: { email, password, rememberMe },
    meta: { ignoreLogrocket: true },
  };
}


export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';

export function signInSuccess(session) {
  return {
    type: SIGN_IN_SUCCESS,
    payload: { session },
  };
}


export const SIGN_IN_FAIL = 'SIGN_IN_FAIL';

export function signInFail(error, extra) {
  return {
    type: SIGN_IN_FAIL,
    meta: { ...extra, error },
  };
}


export const SIGN_OUT = 'SIGN_OUT';

export function signOut() {
  return { type: SIGN_OUT };
}


export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';

export function signOutSuccess() {
  return { type: SIGN_OUT_SUCCESS };
}


export const SIGN_UP = 'SIGN_UP';

export function signUp(user, organization) {
  return {
    type: SIGN_UP,
    payload: {
      user,
      organization,
    },
  };
}


export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';

export function signUpSuccess(session) {
  return {
    type: SIGN_UP_SUCCESS,
    payload: { session },
  };
}


export const SIGN_UP_FAIL = 'SIGN_UP_FAIL';

export function signUpFail(error, extra) {
  return {
    type: SIGN_UP_FAIL,
    meta: { ...extra, error },
  };
}


export const UPDATE_SESSION = 'UPDATE_SESSION';

export function updateSession(session) {
  return {
    type: UPDATE_SESSION,
    payload: {
      session,
    },
  };
}


export const UPDATE_SESSION_SUCCESS = 'UPDATE_SESSION_SUCCESS';

export function updateSessionSuccess() {
  return {
    type: UPDATE_SESSION_SUCCESS,
  };
}


export const UPDATE_SESSION_FAIL = 'UPDATE_SESSION_FAIL';

export function updateSessionFail(error) {
  return {
    type: UPDATE_SESSION_FAIL,
    meta: { error },
  };
}


export const LOAD_SESSION_USER = 'LOAD_SESSION_USER';

export function loadSessionUser(meta) {
  return {
    type: LOAD_SESSION_USER,
    meta: { ...meta, loading: startLoading('sessionUser') },
  };
}


export const LOAD_SESSION_USER_SUCCESS = 'LOAD_SESSION_USER_SUCCESS';

export function loadSessionUserSuccess(user, fromCache) {
  if (fromCache) {
    return {
      type: LOAD_SESSION_USER_SUCCESS,
      payload: { user },
      meta: { loading: stopLoading('sessionUser') },
    };
  }
  return createEntities(schemas.User, 'payload.user', {
    type: LOAD_SESSION_USER_SUCCESS,
    payload: { user },
    meta: { loading: stopLoading('sessionUser') },
  });
}


export const LOAD_SESSION_USER_FAIL = 'LOAD_SESSION_USER_FAIL';

export function loadSessionUserFail(error, extra) {
  return {
    type: LOAD_SESSION_USER_FAIL,
    meta: { ...extra, error, loading: stopLoading('sessionUser') },
  };
}


export const UPDATE_SESSION_USER = 'UPDATE_SESSION_USER';

export function updateSessionUser(user, emailChanged) {
  return {
    type: UPDATE_SESSION_USER,
    payload: { user },
    meta: { ignoreLogrocket: true, emailChanged },
  };
}


export const UPDATE_SESSION_USER_SUCCESS = 'UPDATE_SESSION_USER_SUCCESS';

export function updateSessionUserSuccess(user) {
  return updateEntities(schemas.User, user.id, 'payload.user', {
    type: UPDATE_SESSION_USER_SUCCESS,
    payload: { user },
  });
}


export const UPDATE_SESSION_USER_FAIL = 'UPDATE_SESSION_USER_FAIL';

export function updateSessionUserFail(error, config={}) {
  const { ignoreLog } = config;
  return {
    type: UPDATE_SESSION_USER_FAIL,
    meta: { error, ignoreLog },
  };
}


export const UPDATE_USER_CREDITS = 'UPDATE_USER_CREDITS';

export function updateUserCredits(id, credits) {
  return updateEntities(schemas.User, id, 'payload.user', {
    type: UPDATE_USER_CREDITS,
    payload: {
      user: {
        credits,
      },
    },
  });
}


export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

export function changeLanguage(userId, locale, userType) {
  if (! userId || userType === 'public') {
    return {
      type: CHANGE_LANGUAGE,
      payload: {
        data: { locale },
        noServer: 'no-server',
      },
    };
  }
  return updateEntities(schemas.User, userId, 'payload.data', {
    type: CHANGE_LANGUAGE,
    payload: {
      data: { locale },
    },
  });
}


export const CHANGE_LANGUAGE_SUCCESS = 'CHANGE_LANGUAGE_SUCCESS';

export function changeLanguageSuccess(user) {
  return {
    type: CHANGE_LANGUAGE_SUCCESS,
    payload: { user },
  };
}


export const CHANGE_LANGUAGE_FAIL = 'CHANGE_LANGUAGE_FAIL';

export function changeLanguageFail(error) {
  return {
    type: CHANGE_LANGUAGE_FAIL,
    meta: { error },
  };
}


export const REFRESH_CURRENCY = 'REFRESH_CURRENCY';

export function refreshCurrency() {
  return {
    type: REFRESH_CURRENCY,
  };
}


export const CHANGE_CURRENCY = 'CHANGE_CURRENCY';

export function changeCurrency(currency, user) {
  return {
    type: CHANGE_CURRENCY,
    payload: {
      user,
      currency,
    },
  };
}


export const CHANGE_CURRENCY_SUCCESS = 'CHANGE_CURRENCY_SUCCESS';

export function changeCurrencySuccess(userId, currency) {
  return updateEntities(schemas.User, userId, 'payload.data', {
    type: CHANGE_CURRENCY_SUCCESS,
    payload: {
      data: {
        currency,
      },
    },
  });
}


export const CHANGE_CURRENCY_FAIL = 'CHANGE_CURRENCY_FAIL';

export function changeCurrencyFail(error) {
  return {
    type: CHANGE_CURRENCY_FAIL,
    meta: { error },
  };
}


export const REQUEST_NEW_PASSWORD = 'REQUEST_NEW_PASSWORD';

export function requestNewPassword(email) {
  return {
    type: REQUEST_NEW_PASSWORD,
    payload: { email },
  };
}


export const REQUEST_NEW_PASSWORD_FAIL = 'REQUEST_NEW_PASSWORD_FAIL';

export function requestNewPasswordFail(error) {
  return {
    type: REQUEST_NEW_PASSWORD_FAIL,
    meta: { error },
  };
}


export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';

export function updatePassword(newPassword, newPasswordConfirmation, token) {
  return {
    type: UPDATE_PASSWORD,
    payload: { newPassword, newPasswordConfirmation, token },
  };
}


export const GUEST_SIGN_UP = 'GUEST_SIGN_UP';

export function guestSignUp(user, item) {
  return {
    type: GUEST_SIGN_UP,
    payload: {
      user,
      item,
    },
  };
}


export const GUEST_SIGN_UP_SUCCESS = 'GUEST_SIGN_UP_SUCCESS';

export function guestSignUpSuccess(session) {
  return {
    type: GUEST_SIGN_UP_SUCCESS,
    payload: { session },
  };
}


export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';

export function updatePasswordSuccess() {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
  };
}


export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL';

export function updatePasswordFail(error, config={}) {
  const { ignoreLog } = config;
  return {
    type: UPDATE_PASSWORD_FAIL,
    meta: { error, ignoreLog },
  };
}


export const GUEST_SIGN_UP_FAIL = 'GUEST_SIGN_UP_FAIL';

export function guestSignUpFail(error) {
  return {
    type: GUEST_SIGN_UP_FAIL,
    meta: { error },
  };
}
