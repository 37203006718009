import { Dropdown, DropdownOption, Text } from '@drawbotics/react-drylus';
import React from 'react';
import { useDispatch } from 'react-redux';

import { changeLanguage } from '~/actions';
import { translate as t } from '~/utils/translation';

import { LocaleCode } from '../types';
import { useLoadSession } from '../utils/hooks';

export const LanguageSwitcher = () => {
  const dispatch = useDispatch();
  const { sessionUser } = useLoadSession();

  const handleChangeLocale = (locale: LocaleCode) => {
    const changeLocale = window.i18n.changeLocale;
    changeLocale(locale);

    const sessionType = sessionUser?.session.type || 'public';
    const userId = sessionUser?.id;
    dispatch(changeLanguage(userId, locale, sessionType));
    window.location.reload();
  };

  return (
    <Dropdown trigger={<Text>{window.i18n.locale.toUpperCase()}</Text>}>
      <DropdownOption text={t('en')} onClick={() => handleChangeLocale('en')} />
      <DropdownOption text={t('fr')} onClick={() => handleChangeLocale('fr')} />
      <DropdownOption text={t('nl')} onClick={() => handleChangeLocale('nl')} />
    </Dropdown>
  );
};
