import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from '@drawbotics/route';
import autobind from 'autobind-decorator';

import BriefingOverview from '../BriefingOverview';
import BriefingService from '../BriefingService';
import BriefingItem from '../BriefingItem';
import BriefingEstate from '../BriefingEstate';
import BriefingExisting from '../BriefingExisting';
import Spinner from '~/components/Spinner';
import { translate as t } from '~/utils/translation';
import { EstateEnums } from '../../utils/estate-enums';

import styles from './styles';


class Briefing extends React.Component {

  static propTypes = {
    estateId: PropTypes.string.isRequired,
  };

  render() {
    const { match, estateData, servicesData } = this.props;
    const { loading: loadingEstate, estate } = estateData;
    const { loading: loadingServices, serviceCategories } = servicesData;
    if (loadingEstate || loadingServices) {
      return <Spinner size={50} containerStyle={{ margin: 'auto' }} text={t('pods.order.loading')} />
    }
    return (
      <div className={styles.briefing}>
        {do {
          if (estate.propertyType === EstateEnums.PropertyType.EXISTING_PROPERTY) {
            <Switch handleNotFound>
              <Route
                basePath={match.url}
                path="/"
                component={BriefingExisting}
                exact={true}
                withProps={{
                  estate,
                  updateItems: this._updateItems,
                  updateEstate: this._updateEstate,
                  confirmEstateBriefing: this._confirmEstateBriefing,
                  confirmItemBriefing: this._confirmItemBriefing,
                  goToEstate: this._backToEstateOverview,
                }} />
            </Switch>
          }
          else {
            <Switch handleNotFound>
              <Route
                basePath={match.url}
                path="/estate"
                component={BriefingEstate}
                exact={true}
                withProps={{
                  estate,
                  goBack: this._goBackToOverview,
                  updateEstate: this._updateEstate,
                  confirmBriefing: this._confirmEstateBriefing,
                  serviceCategories,
                }} />
              <Route
                basePath={match.url}
                path="/:serviceType/:itemId"
                component={BriefingItem}
                withProps={{
                  estate,
                  goBack: this._goBackToService,
                  updateItem: this._updateItem,
                  confirmBriefing: this._confirmItemBriefing,
                }} />
              <Route
                basePath={match.url}
                path="/:serviceType"
                component={BriefingService}
                withProps={{
                  estate,
                  goNext: this._goItem,
                  goBack: this._goBackToOverview,
                  updateItems: this._updateItems,
                }} />
              <Route
                basePath={match.url}
                path="/"
                component={BriefingOverview}
                withProps={{
                  estate,
                  goNext: this._goService,
                  goEstate: this._goEstate,
                }} />
            </Switch>
          }
        }}
      </div>
    );
  }

  @autobind
  _goService(serviceType, extra) {
    const { history, match } = this.props;
    const { rect } = extra;
    const finalUrl = `${match.url}/${serviceType}`;
    history.push(finalUrl, { rect });
  }

  @autobind
  _goItem(item, extra) {
    const { history, match } = this.props;
    const { replace=false, rect } = extra;
    const serviceType = item.service.id;
    const finalUrl = `${match.url}/${serviceType}/${item.id}`;
    replace ? history.replace(finalUrl, { rect }) : history.push(finalUrl, { rect });
  }

  @autobind
  _goEstate(extra) {
    const { history, match } = this.props;
    const { replace=false, rect } = extra;
    const finalUrl = `${match.url}/estate`;
    replace ? history.replace(finalUrl, { rect }) : history.push(finalUrl, { rect });
  }

  @autobind
  _goBackToOverview(extra={}) {
    const { history, match } = this.props;
    const { replace=false } = extra;
    replace ? history.replace(match.url) : history.push(match.url);
  }

  @autobind
  _goBackToService(type, extra) {
    const { skipServices=false } = extra;
    skipServices ? this._goBackToOverview({ replace: true }) : this._goService(type, {});
  }

  @autobind
  _backToEstateOverview() {
    const { history, match } = this.props;
    const { params } = match;
    history.push(`/projects/${params.estateId}`);
  }

  @autobind
  async _updateEstate(variables) {
    const { updateEstate, estateData } = this.props;
    const attachments = variables.attachments || estateData.estate.attachments;
    const { data: result } = await updateEstate({ variables: {
      id: estateData.estate.id,
      ...variables,
      attachments: attachments.map((a) => ({ id: a.id })),
    } });
    const { updatedEstate } = result.payload;
    return updatedEstate;
  }

  @autobind
  async _updateItem(item) {
    const { updateItems, estateData } = this.props;
    const itemInput = item;
    const { data: result } = await updateItems({ variables: { id: estateData.estate.id, items: [ itemInput ] } });
    const { updatedItems } = result.payload;
    return updatedItems[0];
  }

  @autobind
  async _updateItems(items) {
    const { updateItems, estateData } = this.props;
    const itemInputs = items;
    const { data: result } = await updateItems({ variables: { id: estateData.estate.id, items: itemInputs } });
    const { updatedItems } = result.payload;
    return updatedItems;
  }

  @autobind
  async _confirmItemBriefing(item) {
    const { confirmItemBriefing } = this.props;
    const { data: result } = await confirmItemBriefing({ variables: { itemId: item.id } });
    const { confirmedItem } = result.payload;
    return confirmedItem;
  }

  @autobind
  async _confirmEstateBriefing(estate) {
    const { confirmEstateBriefing } = this.props;
    const { data: result } = await confirmEstateBriefing({ variables: { estateId: estate.id } });
    const { confirmedEstate } = result.payload;
    return confirmedEstate;
  }

}


export default Briefing;
