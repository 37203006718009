import morphey, { fromKey } from 'morphey';

import { addressToServer } from './address';


const translationsFromServer = {
  id: 'id',
  name: 'name',
  profilePicture: 'profile_picture',
};


export function organizationFromServer(organization) {
  return morphey(organization, translationsFromServer);
}


const translationsToServer = (org) => ({
  is_company: 'isCompany',
  name: 'name',
  address: org.isCompany ? fromKey('address').using(() => addressToServer(org.address)) : 'address',
});


export function organizationToServer(organization) {
  return morphey(organization, translationsToServer);
}


export default {
  fromServer: organizationFromServer,
};
