import React from 'react';
import { Input } from 'react-ittsu/forms';
import sv from '@drawbotics/style-vars';
import { css } from 'emotion';
import autobind from 'autobind-decorator';
import { mapbox } from 'mapbox.js';
import compose from 'lodash/flowRight';

import { mapboxFeatureToAddress, mapboxFeatureToAddressParts } from '../utils';
import { withUser } from '~/utils/with-user';
import { createTranslate } from '~/utils/translation';


const tt = createTranslate('pods.order.components.address_input');


const styles = {
  locationSelector: css`
    position: relative;
  `,
  suggestions: css`
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
    background: ${sv.white};
    z-index: 99;
    box-shadow: ${sv.elevation2};
  `,
  suggestion: css`
    padding: calc(${sv.basePaddingSmall} / 1.5);
    color: ${sv.textSecondaryDark};
    font-size: 0.9em;

    &:hover {
      cursor: pointer;
      background: ${sv.grey100};
      color: ${sv.textPrimaryDark};
    }
  `,
};


export class AddressInput extends React.Component {
  state = {
    visible: false,
    text: null,
    locations: [],
    noResults: false,
  }

  constructor(props) {
    super(props);
    this.geocoder = mapbox.geocoder('mapbox.places', { accessToken: process.env.MAPBOX_ACCESS_TOKEN });
  }

  componentDidMount() {
    document.addEventListener('click', this._handleOnClickWindow);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this._handleOnClickWindow);
  }

  render() {
    const { visible, locations, text, noResults } = this.state;
    const { value, name } = this.props;
    const location = text === null ? value : text || '';
    return (
      <div className={styles.locationSelector}>
        <div ref={(ref) => this.select = ref}>
          <Input
            autoComplete="off"
            name={name}
            value={location}
            placeholder="Street, postal code, city"
            onFocus={() => this.setState({ visible: true })}
            onChange={this._handleTypeLocation} />
        </div>
        {do{
          if (visible || noResults) {
            <div className={styles.suggestions}>
              {locations.map((location) => (
                <div key={location.id} className={css(styles.suggestion)} onClick={() => this._handleSetLocation(location)}>
                  {location.label}
                </div>
              ))}
              {do{
                if (noResults) {
                  <div className={styles.suggestion}>
                    {tt('no_match')}
                  </div>
                }
              }}
            </div>
          }
        }}
      </div>
    );
  }

  @autobind
  _handleTypeLocation(text) {
    const { mapboxOptions={}, sessionUser } = this.props;
    clearTimeout(this.textTimeout);
    this.setState({ text });
    this.textTimeout = setTimeout(async () => {
      if (text === '') {
        this.setState({ locations: [], noResults: false });
      }
      else {
        this.geocoder.query({ query: text, language: sessionUser.locale, types: ['address'], ...mapboxOptions }, (err, res) => {
          if (err) {
            console.error(err);
            return;
          }
          const locations = this._transformFeatures(res.results.features);
          this.setState({ locations, noResults: text !== '' && locations.length === 0 });
        });
      }
    }, 500);
  }

  @autobind
  _transformFeatures(features) {
    return features.reduce((locations, feature) => {
      const { street, zipCode, city, countryCode } = mapboxFeatureToAddressParts(feature);
      return [ ...locations, {
        id: feature.id,
        label: mapboxFeatureToAddress(feature),
        location: {
          lng: feature.center[0],
          lat: feature.center[1],
        },
        street,
        zipCode,
        city,
        countryCode,
      }];
    }, []);
  }

  @autobind
  _handleSetLocation(location) {
    const { onChange, name } = this.props;
    this.setState({ text: null, visible: false, locations: [] });
    const { street, zipCode, city, countryCode } = location;
    const result = {
      address: location.label,
      location: {
        lat: location.location.lat,
        lng: location.location.lng,
      },
      street,
      zipCode,
      city,
      countryCode,
    };
    if (! result.location.lat || ! result.location.lng) {
      console.error(`Location value is undefined: ${result}`);
      return;
    }
    onChange(result, name);
  }

  @autobind
  _handleOnClickWindow(e) {
    const { visible } = this.state;
    if (visible && e.target !== this.select && ! this.select.contains(e.target)) {
      this.setState({ visible: false });
    }
  }
}


export default compose(
  withUser(),
)(AddressInput);
