import {
  Button,
  ButtonLink,
  Category,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  ListTile,
  Padding,
  Shade,
  Size,
  Text,
} from '@drawbotics/react-drylus';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { EstateHolderReason } from '~/pods/dashboard/types';
import { createTranslate, translate as t } from '~/utils/translation';

import briefingHolder from '../images/briefing-holder.svg';
import creditsHolder from '../images/credits-holder.svg';
import kickOffHolder from '../images/kick-off-holder.svg';

const tt = createTranslate('pods.dashboard.routes.production');

function _getImageForReason(reason: EstateHolderReason | 'problem'): string | undefined {
  if (reason === 'problem') {
    return briefingHolder;
  }
  if (reason === EstateHolderReason.KICKOFF_NOT_DONE) {
    return kickOffHolder;
  }
  if (reason === EstateHolderReason.NOT_ENOUGH_CREDITS) {
    return creditsHolder;
  }
}

function _getTranslationForReason(
  reason: EstateHolderReason | 'problem',
): { title: string; description: string } | undefined {
  if (reason === 'problem') {
    return {
      title: t('pods.dashboard.status.briefing_error.title'),
      description: tt('solve_briefing'),
    };
  }
  if (reason === EstateHolderReason.KICKOFF_NOT_DONE) {
    return {
      title: tt('awaiting_kickoff'),
      description: tt('production_kickoff'),
    };
  }
  if (reason === EstateHolderReason.NOT_ENOUGH_CREDITS) {
    return {
      title: tt('not_enough_credits'),
      description: tt('buy_credits'),
    };
  }
}

function _getActionForReason(
  reason: EstateHolderReason | 'problem',
  onClick?: VoidFunction,
): ReactNode {
  if (reason === 'problem') {
    return (
      <Button onClick={onClick} category={Category.INFO}>
        {tt('fix_briefing_issues')}
      </Button>
    );
  }
  if (reason === EstateHolderReason.NOT_ENOUGH_CREDITS) {
    return (
      <Link to="/transactions/new">
        <ButtonLink category={Category.INFO}>{tt('buy_more_credits')}</ButtonLink>
      </Link>
    );
  }
}

interface HolderRowProps {
  holder: EstateHolderReason | 'problem';
  onClickAction?: VoidFunction;
}

export const HolderRow = ({ holder, onClickAction }: HolderRowProps) => {
  const reason = _getTranslationForReason(holder);
  return (
    <Padding size={{ vertical: Size.SMALL, horizontal: Size.DEFAULT }}>
      <Flex justify={FlexJustify.START}>
        <FlexItem>
          <img src={_getImageForReason(holder)} width={85} />
        </FlexItem>
        <FlexSpacer size={Size.DEFAULT} />
        <FlexItem flex>
          <ListTile
            title={<Text bold>{reason?.title}:</Text>}
            trailing={
              <Text shade={Shade.LIGHT} size={Size.SMALL}>
                {reason?.description}
              </Text>
            }
          />
        </FlexItem>
        <FlexSpacer size={Size.DEFAULT} />
        <FlexItem>{_getActionForReason(holder, onClickAction)}</FlexItem>
      </Flex>
    </Padding>
  );
};
