import compact from 'lodash/compact';
import merge from 'lodash/merge';
import get from 'lodash/get';

import * as utils from '../utils';


function _getTargetsFilter(target) {
  const hasVars = (target) => target.includes('@{');
  if (target === 'all' || target == null) {
    return (items) => true;
  }
  else if (target === 'last') {
    return (items, i) => i === items.length - 1;
  }
  else if (target === 'first') {
    return (_, i) => i === 0;
  }
  else if (hasVars(target)) {
    const comparisonString = target.replace(/@{([\d\w.]*)}/g, 'get(item, "$1")').replace('==', '===');
    const filterFn = new Function('get', 'item', `"use strict";return ${comparisonString};`);
    return filterFn.bind(null, get);
  }
  else {
    throw new Error(`Unknown target "${target}" in "update" operation`);
  }
}


function _mergeWithOldValues(items, newItems) {
  return newItems.map((item) => {
    const oldItem = items.find(({ id }) => id === item.id);
    if (oldItem != null) {
      return merge(oldItem, { ...item, id: oldItem.id });
    }
    return item;
  });
}


function _applyUpdate(memo, items, target, doUpdate) {
  const targetsFilter = _getTargetsFilter(target);
  const targets = items.filter(targetsFilter);
  const updateChanges = utils.update(memo, _mergeWithOldValues(targets, compact(targets.map(doUpdate))));
  const newAdd = updateChanges.add.map((item, i) => {
    if (targetsFilter(item, i)) {
      const updatedItem = doUpdate(item);
      if (updatedItem == null) {
        return item;
      }
      return merge(item, updatedItem);
    }
    else {
      return item;
    }
  });
  return { ...updateChanges, add: newAdd };
}


function _handleMapTo({ memo, update, estate, service, specValue }) {
  const { target, mapTo } = update;
  const serviceItems = estate.items.filter((item) => item.service?.id === service.id && ! item.product.custom);
  const doUpdate = (item) => {
    const newDetails = mapTo.reduce((memo, key) => {
      return item.details[key] === specValue ? memo : { ...memo, [key]: specValue };
    }, {});
    if (Object.keys(newDetails).length === 0) {
      return null;
    }
    return {
      ...item,
      details: {
        ...item.details,
        ...newDetails,
      },
    };
  };
  return _applyUpdate(memo, serviceItems, target, doUpdate);
}


export default function runUpdate(memo, descriptionValue, specValue, { service, estate }) {
  const { update } = descriptionValue;
  const { type } = update;
  if (type === 'mapTo') {
    return _handleMapTo({ memo, update, estate, service, specValue });
  }
  else {
    throw new Error(`Unknown type "${type}" for "update" operation`);
  }
}
