import { fork } from 'redux-saga/effects';

import projectSaga from './project';
import annotationsSaga from './annotations';
import attachmentsSaga from './attachments';
import previewsSaga from './previews';
import draftsSaga from './drafts';
import problemsSaga from './problems';


export default function* root() {
  yield fork(projectSaga);
  yield fork(annotationsSaga);
  yield fork(attachmentsSaga);
  yield fork(previewsSaga);
  yield fork(draftsSaga);
  yield fork(problemsSaga);
}
