import applyChanges from './apply-changes';
import { isRequired, matchesSoftCondition } from './utils';


function _setToNull(object, keys) {
  return keys.reduce((memo, key) => {
    return { ...memo, [key]: null };
  }, object);
}


function _handleCreateIfNone(mode, operation, estate, extraChanges) {
  const { product, with: withDetails, service } = operation;
  const details = withDetails != null ? withDetails : {};
  if (mode === 'add') {
    if (estate.items.every((item) => item.product.id !== product)) {
      if (extraChanges.add.every((item) => item.product.id !== product)) {
        extraChanges.add.push({ product: { id: product }, service: { id: service }, details });
      }
    }
  }
  else {
    if ( ! isRequired(product, estate)) {
      const item = estate.items.find((item) => item.product.id === product);
      if (extraChanges.remove.every((item) => item.product.id !== product)) {
        extraChanges.remove.push(item);
      }
    }
  }
}


function _handleCreateOrUpdate({ mode, operation, estate, extraChanges, parentProduct, soft }) {
  const { product, with: withDetails, service } = operation;
  const item = estate.items.find((item) => item.product.id === product);
  const details = withDetails != null ? withDetails : {};
  if (mode === 'add') {
    if (estate.items.some((item) => item.product.id === product)) {  // update
      if (extraChanges.update.every(({ id }) => id !== item.id)) {
        extraChanges.update.push({ ...item, details: { ...item.details, ...details } });
      }
    }
    else {  // create
      if (extraChanges.add.every((item) => item.product.id !== product)) {
        extraChanges.add.push({ product: { id: product }, service: { id: service }, details });
      }
    }
  }
  else {
    if (item == null) {
      return;
    }
    if ( ! isRequired(product, estate) && ! extraChanges.remove.includes(item)) {
      extraChanges.remove.push(item);
    }
    else {  // delete details related to the item being deleted
      const parentItems = estate.items.filter((item) => item.product.id === parentProduct.id);
      if (extraChanges.update.every(({ id }) => id !== item.id) && (soft || parentItems.length === 0)) {
        extraChanges.update.push({
          ...item,
          details: _setToNull(item.details, Object.keys(details)),
        });
      }
    }
  }
}


function _handleAddItem(item, estate, changes, extraChanges) {
  const { service, product } = item;
  if (service.dependencies == null) {
    return null;
  }
  const { hard, soft } = service.dependencies;
  hard.forEach((dependency) => {
    const { operation } = dependency;
    if (operation === 'createIfNone') {
      _handleCreateIfNone('add', dependency.createIfNone, estate, extraChanges);
    }
    if (operation === 'createOrUpdate') {
      _handleCreateOrUpdate({
        mode: 'add',
        operation: dependency.createOrUpdate,
        estate,
        extraChanges,
        parentProduct: product,
        soft: false,
      });
    }
  });
  soft.forEach((dependency) => {
    const { operation } = dependency.then;
    if (matchesSoftCondition(item, dependency)) {
      if (operation === 'createOrUpdate') {
        _handleCreateOrUpdate({
          mode: 'add',
          operation: dependency.then.createOrUpdate,
          estate,
          extraChanges,
          parentProduct: product,
          soft: true,
        });
      }
    }
  });
}


function _handleRemoveItem(item, estate, changes, extraChanges) {
  const { service, product } = item;
  if (service.dependencies == null) {
    return null;
  }
  const { hard, soft } = service.dependencies;
  hard.forEach((dependency) => {
    const { operation } = dependency;
    if (operation === 'createIfNone') {
      _handleCreateIfNone('remove', dependency.createIfNone, estate, extraChanges);
    }
    if (operation === 'createOrUpdate') {
      _handleCreateOrUpdate({
        mode: 'remove',
        operation: dependency.createOrUpdate,
        estate,
        extraChanges,
        parentProduct: product,
        soft: false,
      });
    }
  });
  soft.forEach((dependency) => {
    const { operation } = dependency;
    if (matchesSoftCondition(item, dependency)) {
      if (operation === 'createOrUpdate') {
        _handleCreateOrUpdate({
          mode: 'remove',
          operation: dependency.then.createOrUpdate,
          estate,
          extraChanges,
          parentProduct: product,
          soft: true,
        });
      }
    }
  });
}


function _handleUpdateItem(item, estate, changes, extraChanges) {
  const { service, product } = item;
  if (service.dependencies == null) {
    return null;
  }
  const { soft } = service.dependencies;
  // We don't care about hard dependencies because they only depend on the item
  // being present or not, and since this is an update operation, the item is
  // always gonna be present
  soft.forEach((dependency) => {
    const { operation } = dependency.then;
    if (matchesSoftCondition(item, dependency)) {
      if (operation === 'createOrUpdate') {
        _handleCreateOrUpdate({
          mode: 'add',
          operation: dependency.then.createOrUpdate,
          estate,
          extraChanges,
          parentProduct: product,
          soft: true,
        });
      }
    }
    else {
      if (operation === 'createOrUpdate') {
        _handleCreateOrUpdate({
          mode: 'remove',
          operation: dependency.then.createOrUpdate,
          estate,
          extraChanges,
          parentProduct: product,
          soft: true,
        });
      }
    }
  });
}


export function addDependencies(changes, estate) {
  const { add, remove, update } = changes;
  const newEstate = applyChanges(changes, estate);
  const extraChanges = { add: [], remove: [], update: [] };

  add.forEach((item) => _handleAddItem(item, newEstate, changes, extraChanges));
  remove.forEach((item) => _handleRemoveItem(item, newEstate, changes, extraChanges));
  update.forEach((item) => _handleUpdateItem(item, newEstate, changes, extraChanges));

  return {
    add: [...changes.add, ...extraChanges.add],
    remove: [...changes.remove, ...extraChanges.remove],
    update: [...changes.update, ...extraChanges.update],
  };
}
