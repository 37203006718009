import React from 'react';
import {
  Navbar,
  NavbarIcon,
  NavbarControls,
  NavbarLink,
  Button,
  Link,
} from 'react-ittsu/components';
import { Main, Header, Container, Footer } from 'react-ittsu/layout';

import { createTranslate } from 'utils/translation';
import RouteContainer from 'components/RouteContainer';
import FooterContent from 'containers/FooterContent';

import styles from 'styles/routes/not-found';
import logo from 'images/logo.svg';
import notFound from 'images/404.svg';


const tt = createTranslate('routes.not_found');


class NotFound extends React.Component {
  render() {
    return (
      <div className={styles.notFound}>
        <Header>
          <Navbar withContainer>
            <Link href="/">
              <NavbarIcon image={logo} />
            </Link>
            <NavbarControls>
              <NavbarLink><Link underlined="none" href="/">{tt('back_home')}</Link></NavbarLink>
            </NavbarControls>
          </Navbar>
        </Header>
        <Main>
          <RouteContainer>
            <Container style={{ height: '100%' }}>
              <div className={styles.notFountContainer}>
                <img src={notFound} className={styles.backgroundImage} />
                <div className={styles.content}>
                  <div className={styles.title}>{tt('title')}</div>
                  <div className={styles.subtitle}>{tt('subtitle')}</div>
                </div>
                <Button href="/" category="primary">{tt('back_home')}</Button>
              </div>
            </Container>
          </RouteContainer>
        </Main>
        <Footer>
          <FooterContent />
        </Footer>
      </div>
    );
  }
}


export default NotFound;
