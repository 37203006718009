import morphey, { fromKey } from 'morphey';

import { translateProductSlug, translateLegacyName, translateServiceSlug } from './services';
import { followerFromServer } from './follower';
import { attachmentFromServer } from './attachment';
import { invitationFromServer } from './invitation';
import { problemFromServer } from './problem';


const translationsFromServer = {
  id: 'id',
  name: 'name',
  reference: 'reference',
  estateId: 'estate_id',
  organizationId: 'organisation_id',
  productSlug: fromKey('product_slug').using(translateProductSlug),
  serviceSlug: fromKey('service_slug').using(translateServiceSlug),
  productTitle: 'product_title',
  legacyName: fromKey('product_slug').using(translateLegacyName),
  price: 'price',
  attachments: fromKey('attachments').using((at) => at ? at.map(attachmentFromServer) : []),
  progressPercentage: 'progress_percentage',
  status: 'status',
  details: 'details',
  holders: 'holders',
  milestones: 'milestones',
  followers: fromKey('followers').using((fs) => fs ? fs.map(followerFromServer) : []).defaultTo([]),
  invitations: fromKey('invitations').using((inv) => inv ? inv.map(invitationFromServer) : []).defaultTo([]),
  nextAction: 'next_action',
  publicToken: 'public_identifier',
  floorplannerLink: 'floorplanner_link',
  reopeningPrice: 'reopening_price',
  fullPrice: 'full_price',
  managedContent: 'managed_content',
  problems: fromKey('reception_problems').using((rp) => rp ? rp.map(problemFromServer) : []).defaultTo([]),
};


export function projectItemFromServer(item) {
  return morphey(item, translationsFromServer);
}


export default {
  fromServer: projectItemFromServer,
};
