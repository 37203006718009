import { createEntities, updateEntities } from 'entman';
import { startLoading, stopLoading } from 'loading-manager';

import schemas from 'schemas';


export const LOAD_PROJECTS = 'LOAD_PROJECTS';

export function loadProjects(config) {
  return {
    type: LOAD_PROJECTS,
    payload: { config },
    meta: { loading: startLoading('projects') },
  };
}


export const LOAD_PROJECTS_SUCCESS = 'LOAD_PROJECTS_SUCCESS';

export function loadProjectsSuccess(projects) {
  return createEntities(schemas.Project, 'payload.projects', {
    type: LOAD_PROJECTS_SUCCESS,
    payload: { projects },
    meta: { loading: stopLoading('projects') },
  });
}


export const LOAD_PROJECTS_FAIL = 'LOAD_PROJECTS_FAIL';

export function loadProjectsFail(error) {
  return {
    type: LOAD_PROJECTS_FAIL,
    meta: { error, loading: stopLoading('projects') },
  };
}


export const UPDATE_PROJECT = 'UPDATE_PROJECT';

export function updateProject(id, project) {
  return updateEntities(schemas.Project, id, 'payload.project', {
    type: UPDATE_PROJECT,
    payload: {
      project,
    },
  });
}


export const SET_PROJECTS_LAST_PAGE = 'SET_PROJECTS_LAST_PAGE';

export function setProjectsLastPage(lastPage) {
  return {
    type: SET_PROJECTS_LAST_PAGE,
    payload: {
      lastPage,
    },
  };
}
