import { Add, ForEach, Switch, RelativeAdd } from 'drawbotics-pricer';


const description = {
  Base: [
    Add({ EUR: 250, GBP: 250, USD: 300 }),
    Switch('estate.projectSubtype').is({
      building: [
        ForEach('estate.unitCount').do(Add({ EUR: 25, GBP: 25, USD: 30 })),
      ],
      complex: [
        ForEach('estate.apartmentCount').do(Add({ EUR: 25, GBP: 25, USD: 30 })),
        ForEach('estate.houseCount').do(Add({ EUR: 25, GBP: 25, USD: 30 })),
      ],
    }),
  ],
  Delivery: [
    Switch('estate.delivery').is({
      'relaxed': RelativeAdd(-0.1).from('Base'),
      'standard': RelativeAdd(0).from('Base'),
      'express': RelativeAdd(0.5).from('Base'),
    })
  ],
};


export default description;
