export default (entities) => ({
  getSessionUser() {
    return entities.Session.getSessionUser()
      .then((user) => {
        return entities.Organization.find(user.organization)
          .then((organization) => [user, organization])
          // .catch((err) => console.error(err))
          .catch(() => [user, null]);
      })
      .then(([user, organization]) => ({ ...user, organization }));
  },
});
