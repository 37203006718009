import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  Panel,
  PanelHeader,
  PanelTitle,
  PanelBody,
  Table,
  THead,
  TCell,
  TBody,
  TRow,
  Link,
} from 'react-ittsu/components';
import { Container } from 'react-ittsu/layout';
import get from 'lodash/get';

import isAuthenticated from 'utils/is-authenticated';
import { loadInvoices } from 'pods/transactions/actions';
import { getSessionUser } from 'selectors';
import { getInvoices } from 'pods/transactions/selectors';
import { convertDate } from 'pods/transactions/utils';
import RouteContainer from 'components/RouteContainer';
import LoadingContainer from 'containers/LoadingContainer';
import { createTranslate } from 'utils/translation';


const tt = createTranslate('pods.transactions.routes.invoices');


class Invoices extends React.Component {

  state = {
    loaded: false,
  }

  constructor(props) {
    super(props);
    this._loadAllInvoices = this._loadAllInvoices.bind(this);
  }

  componentDidMount() {
    const { invoices } = this.props;
    if (invoices.length === 0) {
      this._loadAllInvoices();
    }
  }

  render() {
    const { invoices } = this.props;
    if (! invoices) {
      return null;
    }
    const sortedInvoices = invoices.sort((a, b) => b.invoiceReference.split('/')[1] - a.invoiceReference.split('/')[1]);
    return (
      <LoadingContainer
        loadingKeys="invoices"
        whenLoaded={() => (
          <RouteContainer>
            <Container>
              <Panel>
                <PanelHeader>
                  <PanelTitle>
                    {tt('invoice')}
                  </PanelTitle>
                </PanelHeader>
                <PanelBody>
                  <Table fullWidth variation="striped">
                    <THead>
                      <TRow>
                        <TCell>
                          {tt('transaction_reference')}
                        </TCell>
                        <TCell>
                          {tt('purchase_date')}
                        </TCell>
                        <TCell>
                          {tt('buyer')}
                        </TCell>
                        <TCell>
                          {tt('internal_reference')}
                        </TCell>
                        <TCell>
                          {tt('total')}
                        </TCell>
                        <TCell></TCell>
                      </TRow>
                    </THead>
                    <TBody>
                      {sortedInvoices.map((transaction, i) => (
                        <TRow key={i}>
                          <TCell>{transaction.invoiceReference}</TCell>
                          <TCell>{convertDate(transaction.sentAt)}</TCell>
                          <TCell>{transaction.buyer.firstName ? `${transaction.buyer.firstName} ${transaction.buyer.lastName}` : null}</TCell>
                          <TCell>{transaction.internalReference}</TCell>
                          <TCell>{`${transaction.priceWithTaxes} (${transaction.currency})`}</TCell>
                          <TCell>
                            {do {
                              if (transaction.invoicePublicUrl) {
                                <Link underlined="always" href={transaction.invoicePublicUrl} target="_blank" download>{tt('download')}</Link>
                              }
                            }}
                          </TCell>
                        </TRow>
                      ))}
                    </TBody>
                  </Table>
                </PanelBody>
              </Panel>
            </Container>
          </RouteContainer>
        )} />
    );
  }

  _loadAllInvoices() {
    const { loadInvoices, sessionUser } = this.props;
    const { loaded } = this.state;
    if (sessionUser && ! loaded) {
      loadInvoices(get(sessionUser, 'organization.id', null));
      this.setState({
        loaded: true,
      });
    }
  }
}


const mapStateToProps = (state) => ({
  invoices: getInvoices(state),
  sessionUser: getSessionUser(state),
});


const mapDispatchToProps = {
  loadInvoices,
};


export default compose(
  isAuthenticated(),
  connect(mapStateToProps, mapDispatchToProps),
)(Invoices);
