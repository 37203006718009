import React from 'react';
import { Paragraph, Button } from 'react-ittsu/components';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';

import WarningModal from '~/pods/order/components/WarningModal';


const styles = {
  warningMessage: css`
  `,
  content: css`
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 400px;
    align-items: center;
    margin: auto;
  `,
  message: css`
    color: ${sv.textPrimaryDark};
    margin-bottom: ${sv.baseMargin};
  `,
};


const WarningMessage = ({ visible, onClickClose, message }) => {
  return (
    <div className={styles.warningMessage} onClick={(e) => e.stopPropagation()}>
      <WarningModal visible={visible}>
        <div className={styles.content}>
          <Paragraph className={styles.message}>
            {message}
          </Paragraph>
          <Button category="primary" round onClick={onClickClose}>OK</Button>
        </div>
      </WarningModal>
    </div>
  );
}


export default WarningMessage;
