import { getEntities, getEntity } from 'entman';

import schemas from 'schemas';


export function getOrders(state) {
  return getEntities(state, schemas.Order);
}


export function getOrder(state, id) {
  return getEntity(state, schemas.Order, id);
}
