import omit from 'lodash/omit';

import * as ApolloCacheActions from 'actions/cache-invalidation';


export default function cacheInvalidation(state={}, action) {
  switch (action.type) {
    case ApolloCacheActions.INVALIDATE_QUERY: {
      const { query } = action.payload;
      return {
        ...state,
        [query]: query,
      };
    }
    case ApolloCacheActions.CLEAR_QUERY_INVALIDATION: {
      const { query } = action.payload;
      return omit(state, [query]);
    }
    default: {
      return state;
    }
  }
}
