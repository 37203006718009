import React from 'react';
import { css, cx } from 'emotion';
import sv from '@drawbotics/style-vars';
import gql from 'fraql';
import { Icon, Button, Tag } from 'react-ittsu/components';
import Swipeout from 'rc-swipeout';
import 'rc-swipeout/assets/index.css';
import compose from 'lodash/flowRight';
import autobind from 'autobind-decorator';

import ItemsTable from './ItemsTable';
import { withUser } from '~/utils/with-user';
import { withMedia } from '~/utils/media-provider';
import { EstateEnums } from '../../utils/estate-enums';
import { createTranslate, translate as t } from '~/utils/translation';
import Spinner from '~/components/Spinner';

import existingIcon from '../../images/icons/existing-property-icon.svg';
import newDevelopmentIcon from '../../images/icons/new-development-icon.svg';
import renovationIcon from '../../images/icons/renovation-icon.svg';


const tt = createTranslate('pods.order.components.cart_item');


const styles = {
  cartItem: css`
    position: relative;
  `,
  wrapper: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${sv.basePaddingSmall} 0;

    @media ${sv.phoneXl} {
      padding: ${sv.basePaddingSmall};
    }

    &:hover {
      cursor: pointer;
    }
  `,
  toggle: css`
    position: relative;
    margin-left: ${sv.baseMarginSmall};
    color: ${sv.textSecondaryDark};
    font-size: 1.1em;
    -webkit-tap-highlight-color: rgba(0,0,0,0);

    &::before {
      content: ' ';
      position: absolute;
      height: 35px;
      width: 35px;
      background: ${sv.grey100};
      border-radius: 10000px;
      padding: 0;
      transform: translate(-25%, -25%);
      opacity: 0;
      transition: opacity ${sv.baseTransitionTimeShort} ease-in-out;
    }

    &:hover {
      cursor: pointer;
      color: ${sv.textPrimaryDark};
    }

    &:active {
      &::before {
        opacity: 1;
      }
    }
  `,
  typeIcon: css`
    height: 70px;
    width: 70px;
    min-width: 70px;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${sv.baseMarginSmall};
    background: ${sv.grey100};
    padding: 0;

    > img {
      height: 100%;
      object-fit: cover;
    }
  `,
  meta: css`
    flex: 1;
    min-width: 0;
  `,
  name: css`
    color: ${sv.textPrimaryDark};
    max-width: 160px;
    line-height: 1.3rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media ${sv.phoneXl} {
      max-width: none;
    }
  `,
  info: css`
    width: 60%;
    display: flex;
    align-items: center;
    margin-right: ${sv.baseMarginSmall};

    @media ${sv.phoneXl} {
      width: auto;
      min-width: 0;
      margin-right: 0;
    }
  `,
  orderAction: css`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media ${sv.phoneXl} {
      display: none;
    }
  `,
  status: css`
    margin-left: 10px;
    font-size: 0.85em;
    font-weight: 700;

    & > .Tag {
      letter-spacing: 0.02rem;
    }

    @media ${sv.phoneXl} {
      position: absolute;
      top: 0;
      right: ${sv.baseMarginSmall};
      transform: translateY(-50%);
    }
  `,
  order: css`
    margin-left: ${sv.baseMargin};
  `,
  price: css`
    flex: 1;
    color: ${sv.textPrimaryDark};
    font-size: 1.1rem;
    white-space: nowrap;
    margin-right: ${sv.baseMarginSmall};

    @media ${sv.phoneXl} {
      display: none;
    }
  `,
  actionIcon: css`
    height: 30px;
    width: 30px;
    border-radius: 10000px;
    background: ${sv.grey200};
    color: ${sv.textSecondaryDark};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    transition: all ${sv.baseTransitionTimeShort} ease-in-out;

    &:hover {
      cursor: pointer;
      background: ${sv.grey300};
      color: ${sv.textPrimaryDark};
    }
  `,
  mobileFooter: css`
    display: none;
    padding: ${sv.basePaddingSmall};
    padding-top: 0;

    @media ${sv.phoneXl} {
      display: block;
    }
  `,
  smallPrice: css`
    color: ${sv.textSecondaryDark};
    margin-right: 10px;
    margin-top: 5px;
    display: none;

    @media ${sv.phoneXl} {
      display: block;
    }
  `,
  swipeButton: css`
    color: ${sv.white};
    width: 70px;
  `,
  actions: css`
    margin-top: ${sv.baseMarginSmall};
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

    @media ${sv.phoneXl} {
      margin-right: ${sv.baseMarginSmall};
    }
  `,
  table: css`
    margin-right: ${sv.baseMargin};
    margin-bottom: ${sv.baseMarginSmall};

    @media ${sv.phoneXl} {
      margin-right: 0;
      padding-bottom: ${sv.basePaddingSmall};
    }
  `,
  bigPrice: css`
    font-size: 1.2rem;
    color: ${sv.textPrimaryDark};
    margin-left: ${sv.baseMarginSmall};
    margin-top: 5px;
  `,
  danger: css`
    background: ${sv.brandRed} !important;
    color: ${sv.white} !important;
  `,
  disabled: css`
    pointer-events: none;
  `,
  discountWrapper: css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  `,
  note: css`
    color: ${sv.textPrimaryDark};
    font-size: 0.9rem;
    margin-top: 10px;
  `,
}


function getIconForType(type) {
  if (type === EstateEnums.PropertyType.NEW_DEVELOPMENT) {
    return newDevelopmentIcon;
  }
  else if (type === EstateEnums.PropertyType.EXISTING_PROPERTY) {
    return existingIcon;
  }
  else if (type === EstateEnums.PropertyType.RENOVATION) {
    return renovationIcon;
  }
  else if (type != null) {
    throw new Error('Property type not recognized');
  }
  else {
    return '';
  }
}


class CartItem extends React.Component {

  state = {
    tableOpen: false,
    deleteClicked: false,
    deleteDisabled: false,
  }

  componentDidMount() {
    document.addEventListener('click', this._handleOnClickWindow);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this._handleOnClickWindow);
  }

  render() {
    const { tableOpen, deleteClicked, deleteDisabled } = this.state;
    const { estate, onClickEdit, onClickOrder, sessionUser, mediaSize } = this.props;
    const estatePrice = estate.items.reduce((memo, i) => i.price + memo, 0) + estate.modelingPrice;
    let extraCosts = [];
    if (estate.modelingRequired) {
      extraCosts.push({ id: 'modeling', price: estate.baseModelingPrice });
    }
    if (!! estate.delivery && estate.delivery !== 'standard') {
      extraCosts.push({
        label: t('pods.order.routes.estate_delivery.delivery', {
          delivery_type: t(`pods.order.routes.estate_delivery.${estate.delivery}`),
        }),
        price: estate.delivery === 'express' ? '+50%' : '-10%',
        raw: true,
      });
    }
    return (
      <div className={styles.cartItem} onClick={mediaSize.isMobile ? this._handleClickCard : null}>
        <div className={styles.wrapper} onClick={mediaSize.isMobile ? null : () => this.setState({ tableOpen: ! tableOpen })}>
          <div className={styles.info}>
            <div className={styles.typeIcon}>
              <img src={getIconForType(estate.propertyType)} />
            </div>
            <div className={styles.meta}>
              <div className={styles.name}>
                {estate.name ? estate.name : tt('untitled_project')}
              </div>
              {do{
                if (estate.items.length > 0) {
                  <div className={styles.smallPrice}>
                    {sessionUser.addCurrency(estatePrice)}
                  </div>
                }
              }}
            </div>
            <div className={cx(styles.status, estate.orderCompleted ? styles.ready : styles.draft)}>
              <Tag color={estate.orderCompleted ? 'green' : 'yellow'}>{estate.orderCompleted ? tt('ready_to_order') : tt('incomplete')}</Tag>
            </div>
          </div>
          {do{
            if (estate.discountedPrice && estate.discount) {
              <div className={styles.price}>
                {sessionUser.addCurrency(estate.discountedPrice.value)}
              </div>
            }
            else {
              <div className={styles.price}>
                {sessionUser.addCurrency(estatePrice)}
              </div>
            }
          }}
          <div className={styles.orderAction}>
            {do{
              if (estate.orderCompleted) {
                <Button category="primary" round className={styles.order} onClick={onClickOrder}>{tt('checkout')}</Button>
              }
            }}
          </div>
          <div className={styles.toggle} onClick={() => this.setState({ tableOpen: ! tableOpen })}>
            <Icon name={`caret-${tableOpen ? 'up' : 'down'}-bold`} bold />
          </div>
        </div>
        <div className={styles.table} style={{ display: tableOpen ? undefined : 'none' }}>
          <ItemsTable
            withCurrency={(amount) => sessionUser.addCurrency(amount)}
            services={estate.itemsByService}
            items={estate.items}
            extraCosts={extraCosts} />
          <div className={styles.actions}>
            <div className={styles.actionIcon} onClick={onClickEdit}>
              <Icon name="edit" bold />
            </div>
            <div
              className={cx(styles.actionIcon, { [styles.danger]: deleteClicked, [styles.disabled]: deleteDisabled })}
              onClick={(e) => deleteClicked ? this._handleClickDelete(e) : this.setState({ deleteClicked: true })}
              ref={(ref) => this.button = ref}>
              {do{
                if (deleteDisabled) {
                  <Spinner size={14} />
                }
                else {
                  <Icon name="trash" bold />
                }
              }}
            </div>
            {do{
              if (estate.discountedPrice && estate.discount) {
                <div className={styles.discountWrapper}>
                  <div className={styles.bigPrice}>
                    <span style={{ textDecoration: 'line-through', opacity: '0.7' }}>{sessionUser.addCurrency(estatePrice)}</span>
                    <span style={{ fontWeight: 'bold' }}>&nbsp;&nbsp;{sessionUser.addCurrency(estate.discountedPrice.value)}</span>
                  </div>
                  <div className={styles.note}>
                    {do {
                      if (estate.discount.type === 'percentage_discount') {
                        t('pods.order.components.discount_modal.discount', { rate: Math.round(estate.discount.rate * 100) })
                      }
                      else if (estate.discount.type === 'amount_discount') {
                        t('pods.order.components.discount_modal.discount_amount', {
                          amount: sessionUser.addCurrency(estate.discount.amount),
                        })
                      }
                    }}
                  </div>
                </div>
              }
              else {
                <div className={styles.bigPrice}>
                  {sessionUser.addCurrency(estatePrice)}
                </div>
              }
            }}
          </div>
        </div>
        {do{
          if (estate.orderCompleted) {
            <div className={styles.mobileFooter}>
              <Button
                category="primary"
                onClick={onClickOrder}
                style={{ width: '100%', boxShadow: 'none' }}>{tt('checkout')}</Button>
            </div>
          }
        }}
      </div>
    );
  }

  @autobind
  _handleClickCard(e) {
    e.stopPropagation();
    this.setState({ tableOpen: ! this.state.tableOpen })
  }

  @autobind
  _handleOnClickWindow(e) {
    const { deleteClicked } = this.state;
    if (deleteClicked && e.target !== this.button && ! this.button.contains(e.target)) {
      this.setState({ deleteClicked: false });
    }
  }

  @autobind
  async _handleClickDelete(e) {
    const { onClickDelete } = this.props;
    this.setState({ deleteDisabled: true });
    await onClickDelete(e);
  }
}


const CartItemWrapper = (props) => {
  const { onClickDelete, onClickEdit, mediaSize } = props;
  if (mediaSize.isMobile) {
    return (
      <Swipeout
        style={{ overflow: 'visible' }}
        right={[
          {
            text: tt('edit'),
            className: styles.swipeButton,
            style: { backgroundColor: sv.grey300 },
            onPress: onClickEdit,
          },
          {
            text: tt('delete'),
            className: styles.swipeButton,
            style: { backgroundColor: sv.brandRed },
            onPress: onClickDelete,
          },
        ]}>
        <CartItem {...props} />
      </Swipeout>
    );
  }
  else {
    return (
      <CartItem {...props} />
    );
  }
}


CartItemWrapper.fragments = {
  CartItem: gql`
    fragment _ on Estate {
      id
      propertyType
      orderCompleted @client(type: Estate)
      name
      itemsByService @client(type: Estate)
      delivery
      modelingPrice @client(type: Estate)
      baseModelingPrice @client(type: Estate)
      modelingRequired
      discount {
        rate
        amount
        discountType
      }
      discountedPrice {
        value
      }
      items {
        id
        ${ItemsTable.fragments.ItemsTable}
      }
    },
  `,
};


export default compose(
  withUser(),
  withMedia(),
)(CartItemWrapper);
