import {
  updateEntities,
} from 'entman';

import schemas from 'schemas';


export const VALIDATE_PREVIEW = 'VALIDATE_PREVIEW';

export function validatePreview(preview, user) {
  return {
    type: VALIDATE_PREVIEW,
    payload: {
      id: preview.id,
      user,
      preview,
    },
  };
}


export const INVALIDATE_PREVIEW = 'INVALIDATE_PREVIEW';

export function invalidatePreview(id) {
  return {
    type: INVALIDATE_PREVIEW,
    payload: {
      id,
    },
  };
}


export const UPDATE_PREVIEW = 'UPDATE_PREVIEW';

export function updatePreview(id, data) {
  return updateEntities(schemas.Preview, id, 'payload.data', {
    type: UPDATE_PREVIEW,
    payload: {
      data,
    },
  });
}
