import produce from 'immer';
import get from 'lodash/get';


export function isCheckedStep(stepName, estate) {
  switch (stepName) {
    case 'type': {
      return estate.projectType != null;
    }
    case 'subtype': {
      return estate.projectSubtype != null;
    }
    case 'details': {
      return estate.name != null && estate.address != null;
    }
    case 'visuals': {
      return estate.jsonMeta.steps?.order?.visuals?.visited;
    }
    case 'communication': {
      return estate.jsonMeta.steps?.order?.communication?.visited;
    }
    case 'delivery': {
      return estate.delivery != null && estate.jsonMeta.steps?.order?.delivery?.visited;
    }
    case 'summary': {
      return estate.jsonMeta.steps?.order?.summary?.visited;
    }
    case 'address': {
      return estate.jsonMeta.steps?.payment?.address?.visited;
    }
    case 'synthesis': {
      return estate.jsonMeta.steps?.payment?.synthesis?.visited;
    }
    default: {
      return false;
    }
  }
}


export function getCheckedSteps(steps, estate) {
  return produce(steps, (steps) => {
    Object.keys(steps).forEach((sectionName) => {
      const section = steps[sectionName];
      Object.keys(section).forEach((stepName) => {
        const step = section[stepName];
        step.checked = isCheckedStep(stepName, estate);
      });
    });
  });
}


export function mergeStepsMeta(steps, stepsMeta, estate) {
  return produce(steps, (steps) => {
    Object.keys(steps).forEach((sectionName) => {
      const section = steps[sectionName];
      Object.keys(section).forEach((stepName) => {
        const step = section[stepName];
        const stepMeta = get(stepsMeta, step.name, {});
        Object.assign(step.data, stepMeta);
      });
    });
  });
}


export function getItemsByService(estate) {
  const { items } = estate;
  return items.reduce((memo, item) => {
    const { service } = item;
    (memo[service?.id] || (memo[service?.id] = [])).push(item);
    return memo;
  }, {});
}


export function getItemsByProduct(estate) {
  const { items } = estate;
  return items.reduce((memo, item) => {
    const { product } = item;
    (memo[product?.id] || (memo[product?.id] = [])).push(item);
    return memo;
  }, {});
}


export function getFeaturesForProduct(product) {
  if (! product) {
    return [];
  }
  const locale = get(window, 'i18n.locale', 'en');
  const { features } = product;
  const featuresArray = features.filter((feature) => feature.locale.toLowerCase() === locale.toLowerCase());
  return featuresArray.map((feature) => ({ text: feature.content }));
}


export function getRequiredAttachmentsForProduct(product) {
  if (! product) {
    return [];
  }
  const locale = get(window, 'i18n.locale', 'en');
  const { requiredAttachments } = product;
  const requirementsArray = requiredAttachments.filter((requirement) => requirement.locale === locale.toUpperCase());
  return requirementsArray.map((requirement) => requirement.content).join(', ');
}


export function addNoteToCustomDescription(content, description) {
  const { components } = description['1'];
  const note = {
    type: 'component',
    component: 'Note',
    label: content,
  };
  const newComponents = components.reduce((memo, component) =>
    component.component === 'Note' ? [ ...memo, component, note ] : [ ...memo, component ], []);
  const newSection = {
    ...description['1'],
    components: newComponents,
  };
  return { ...description, 1: newSection };
}


export * from './estate-modeling';
