const k = (key) => `revo.briefing.${key}`;


const residential = {
  1: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('display_languages'),
        component: 'Title',
      },
      {
        type: 'question-component',
        component: 'MultiSelect',
        key: 'languages',
        keyLabel: k('keys.languages'),
        required: 'all',
        values: [
          {
            image: require('../images/revo/languages/EN.jpg'),
            value: 'en',
            label: 'EN',
          },
          {
            image: require('../images/revo/languages/FR.jpg'),
            value: 'fr',
            label: 'FR',
          },
          {
            image: require('../images/revo/languages/NL.jpg'),
            value: 'nl',
            label: 'NL',
          },
        ],
      },
    ],
  },
  2: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('enabled_features'),
        component: 'Title',
      },
      {
        type: 'question-component',
        component: 'MultiSelect',
        key: 'features',
        keyLabel: k('keys.features'),
        required: 'all',
        values: [
          {
            image: require('../images/revo/features/enable-prices.jpg'),
            value: 'show_prices',
            label: k('features.show_prices'),
          },
          {
            image: require('../images/revo/features/enable-favorites.jpg'),
            value: 'enable_favourites',
            label: k('features.enable_favorites'),
          },
          {
            image: require('../images/revo/features/enable-listing.jpg'),
            value: 'enable_listing',
            label: k('features.enable_listing'),
          },
          {
            image: require('../images/revo/features/enable-availabilities.jpg'),
            value: 'show_availability',
            label: k('features.show_availabilities'),
          },
          {
            image: require('../images/revo/features/enable-unit-details.jpg'),
            value: 'show_unit_details',
            label: k('features.show_unit_details'),
          },
          {
            image: require('../images/revo/features/enable-surfaces.jpg'),
            value: 'show_surface',
            label: k('features.enable_surfaces'),
          },
          {
            image: require('../images/revo/features/keep-access.jpg'),
            value: 'enable_sold_access',
            label: k('features.enable_sold_access'),
          },
        ],
      },
    ],
  },
  3: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('lead_receivers'),
        component: 'Title',
      },
      {
        type: 'question-component',
        component: 'EmailSelect',
        key: 'recipientEmails',
        keyLabel: k('keys.recipient_emails'),
        required: 'all',
      },
    ],
  },
  4: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'shared.additional_info',
        component: 'Title',
      }, {
        type: 'component',
        label: 'shared.anything_to_add',
        component: 'Note',
      }, {
        type: 'question-component',
        label: 'shared.additional_comments',
        component: 'Input',
        key: 'otherInfo',
        keyLabel: 'shared.keys.other_info',
      }, {
        type: 'question-component',
        label: 'shared.upload_files',
        component: 'FileUploader',
        key: 'otherInfoAttachments',
        keyLabel: 'shared.keys.other_info_attachments',
      },
    ],
  },
};

export default { residential };
