import { combineEpics } from 'redux-observable';

import addressesEpic from './addresses';
import organizationEpic from './organization';


export default combineEpics(
  addressesEpic,
  organizationEpic,
);
