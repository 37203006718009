import React from 'react';
import { Button } from 'react-ittsu/components';
import { Grid, Cell } from 'react-ittsu/layout';
import { Label, ErrorLabel, FormGroup, Input, Form, Select } from 'react-ittsu/forms';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';

import { createTranslate } from '~/utils/translation';
import Modal from 'components/NewModal';
import countries, { isVatRequired, getVatPlaceholder } from '~/utils/countries';


const t = createTranslate('pods.payments.components.address_modal');


const styles = {
  addressModal: css`
    max-width: 600px;
    width: calc(100vw - ${sv.baseMargin});
    background: ${sv.white};
    padding: ${sv.basePadding};
    border-radius: ${sv.baseBorderRadius};
  `,
  addressModalTitle: css`
    font-size: 1.4rem;
    margin-bottom: ${sv.baseMargin};
    color: ${sv.brandPrimary};
  `,
  addressModalButtons: css`
    display: flex;
    justify-content: space-between;
    margin-top: ${sv.baseMarginSmall};
    margin-bottom: 0;
  `,
};


class AddressModal extends React.Component {

  state = {
    selectedCountryCode: null,
  };

  render() {
    const {
      isVisible,
      modalExtra,
      isLoading,
      invalidVAT,
      onClickCancel,
      onClickAccept,
    } = this.props;
    const { selectedCountryCode: stateCountryCode } = this.state;
    const { address } = modalExtra;
    const selectedCountryCode = stateCountryCode ? stateCountryCode : address?.countryCode;
    return (
      <Modal isVisible={isVisible} onClickClose={onClickCancel}>
        <div className={styles.addressModal}>
          <div className={styles.addressModalTitle}>
            {address?.id ? t('title.update') : t('title.create')}
          </div>
          <Form
            name="address"
            defaultValues={address}
            onSubmit={(values, ...args) => {
              onClickAccept({
                ...values,
                vat: isVatRequired(values.countryCode) ? values.vat : '' ,
              }, ...args);
            }}>
            <FormGroup>
              <Label>{t('fields.label')}</Label>
              <Input name="label" validation={(v) => v.isEmpty(t('errors.not_empty'))} />
            </FormGroup>
            <FormGroup>
              <Label required>{t('fields.street')}</Label>
              <Input name="street" validation={(v) => v.isEmpty(t('errors.not_empty'))} />
            </FormGroup>
            <Grid withHGutters>
              <Cell size="1of2">
                <FormGroup>
                  <Label required>{t('fields.zip_code')}</Label>
                  <Input name="zipCode" validation={(v) => v.isEmpty(t('errors.not_empty'))} />
                </FormGroup>
              </Cell>
              <Cell size="1of2">
                <FormGroup>
                  <Label required>{t('fields.city')}</Label>
                  <Input name="city" validation={(v) => v.isEmpty(t('errors.not_empty'))} />
                </FormGroup>
              </Cell>
            </Grid>
            <Grid withHGutters>
              <Cell size="1of2">
                <FormGroup>
                  <Label required>{t('fields.country')}</Label>
                  <Select
                    name="countryCode"
                    values={countries.all.map(a => ({ value: a.alpha2, label: a.name }))}
                    onChange={(v, n, onChange) => {
                      this.setState({ selectedCountryCode: v });
                      onChange(v, n);
                    }}/>
                </FormGroup>
              </Cell>
              {do {
                if (isVatRequired(selectedCountryCode)) {
                  <Cell size="1of2">
                    <FormGroup>
                      <Label>{t('fields.vat')}</Label>
                      <Input name="vat" placeholder={getVatPlaceholder(selectedCountryCode)} />
                      <ErrorLabel text={invalidVAT ? t('errors.invalid_vat') : ''} />
                    </FormGroup>
                  </Cell>
                }
              }}
            </Grid>
            <FormGroup className={styles.addressModalButtons}>
              <Button variation="borderless" round onClick={onClickCancel} type="button">
                {t('buttons.cancel')}
              </Button>
              <Button category="primary" disabled={isLoading} round>
                {do {
                  if (isLoading) {
                    t('buttons.loading');
                  }
                  else {
                    address?.id ? t('buttons.update') : t('buttons.create');
                  }
                }}
              </Button>
            </FormGroup>
          </Form>
        </div>
      </Modal>
    );
  }

}


export default AddressModal;
