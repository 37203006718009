import React from 'react';
import autobind from 'autobind-decorator';
import { Icon } from 'react-ittsu/components';
import Modal from '@drawbotics/modal';
import { css, cx } from 'emotion';
import sv from '@drawbotics/style-vars';


const KEY_CODE_ESCAPE = 27;


const styles = {
  imageViewer: css`
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  topAligned: css`
    align-items: flex-start;
  `,
  content: css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${sv.basePadding};
    margin-top: ${sv.baseMargin};
    position: relative;

    @media ${sv.phone} {
      padding: ${sv.basePaddingSmall};
      margin-top: calc(${sv.baseMargin} * 1.5);
    }
  `,
  imageWrapper: css`
    position: relative;
    border: 10px solid ${sv.white};
    border-radius: 5px;
    max-width: 800px;
    height: 100%;
    background: ${sv.white};
  `,
  close: css`
    position: absolute;
    top: calc(-1.5 * ${sv.baseMargin});
    right: calc(-1.5 * ${sv.baseMargin});
    color: ${sv.white};
    transition: all ${sv.baseTransitionTimeShort} ease-in-out;

    &:hover {
      cursor: pointer;
      color: ${sv.grey300};
    }

    @media ${sv.phone} {
      position: fixed;
      top: ${sv.baseMarginSmall};
      right: ${sv.baseMarginSmall};
    }
  `,
};


class ImageViewer extends React.Component {
  state = {
    topAligned: true,
  }

  componentDidUpdate(prevProps) {
    const { visible } = this.props;
    const { visible: prevVisible } = prevProps;
    if (! prevVisible && visible) {
      const boundingBox = this.wrapper.getBoundingClientRect();
      this.setState({ topAligned: boundingBox?.height < window.innerHeight });
      window.addEventListener('keydown', this._handleKeyDown);
    }
    else if (prevVisible && ! visible) {
      window.removeEventListener('keydown', this._handleKeyDown);
    }
  }

  render() {
    const { visible, src, onClickClose } = this.props;
    const { topAligned } = this.state;
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Modal isVisible={visible}>
          <div className={cx(styles.imageViewer, { [styles.topAligned]: topAligned })} onClick={onClickClose}>
            <div className={styles.content} onClick={(e) => e.stopPropagation()}>
              <div className={styles.imageWrapper} ref={(wrapper) => this.wrapper = wrapper}>
                <div className={styles.close} onClick={onClickClose}>
                  <Icon name="close-bold" size="2x" />
                </div>
                <img src={src} />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  @autobind
  _handleKeyDown(e) {
    const { onClickClose } = this.props;
    if (e.keyCode === KEY_CODE_ESCAPE) {
      e.preventDefault();
      onClickClose(e);
    }
  }
}


export default ImageViewer;
