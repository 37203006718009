import { startLoading, stopLoading } from 'loading-manager';
import { createEntities } from 'entman';

import schemas from 'schemas';


export const LOAD_ORDERS = 'LOAD_ORDERS';

export function loadOrders(organizationId) {
  return {
    type: LOAD_ORDERS,
    payload: { organizationId },
    meta: { loading: startLoading('orders') },
  };
}


export const LOAD_ORDERS_SUCCESS = 'LOAD_ORDERS_SUCCESS';

export function loadOrdersSuccess(orders) {
  return createEntities(schemas.Order, 'payload.orders', {
    type: LOAD_ORDERS_SUCCESS,
    payload: { orders },
    meta: { loading: stopLoading('orders') },
  });
}


export const LOAD_ORDERS_FAIL = 'LOAD_ORDERS_FAIL';

export function loadOrdersFail(error) {
  return {
    type: LOAD_ORDERS_FAIL,
    meta: { error, loading: stopLoading('orders') },
  };
}


export const LOAD_ORDER = 'LOAD_ORDER';

export function loadOrder(id) {
  return {
    type: LOAD_ORDER,
    payload: { id },
    meta: { loading: startLoading('after-order') },
  };
}


export const LOAD_ORDER_SUCCESS = 'LOAD_ORDER_SUCCESS';

export function loadOrderSuccess(order) {
  return createEntities(schemas.Order, 'payload.order', {
    type: LOAD_ORDER_SUCCESS,
    payload: { order },
    meta: { loading: stopLoading('after-order') },
  });
}


export const LOAD_ORDER_FAIL = 'LOAD_ORDER_FAIL';

export function loadOrderFail(error) {
  return {
    type: LOAD_ORDER_FAIL,
    meta: { error, loading: stopLoading('after-order') },
  };
}
