export const SET_PROJECTS_FILTER = 'SET_PROJECTS_FILTER';

export function setProjectsFilter(filter) {
  return {
    type: SET_PROJECTS_FILTER,
    payload: {
      filter,
    },
  };
}


export const SET_PROJECTS_SORT_BY = 'SET_PROJECTS_SORT_BY';

export function setProjectsSortBy(sort) {
  return {
    type: SET_PROJECTS_SORT_BY,
    payload: {
      sort,
    },
  };
}


export const SET_PROJECTS_SEARCH = 'SET_PROJECTS_SEARCH';

export function setProjectsSearch(search) {
  return {
    type: SET_PROJECTS_SEARCH,
    payload: {
      search,
    },
  };
}


export const SET_PROJECTS_PAGE = 'SET_PROJECTS_PAGE';

export function setProjectsPage(page) {
  return {
    type: SET_PROJECTS_PAGE,
    payload: {
      page,
    },
  };
}