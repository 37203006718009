import sv from '@drawbotics/style-vars';
import dayjs from 'dayjs';
import { css } from 'emotion';
import gql from 'fraql';
import compose from 'lodash/flowRight';
import React, { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { graphql } from 'react-apollo';
import { Button, Icon } from 'react-ittsu/components';
import { Form, FormGroup, Input, Label, Select } from 'react-ittsu/forms';
import { Cell, Grid } from 'react-ittsu/layout';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { showAlert } from '~/actions';
import Address from '~/pods/order/components/Address';
import MainPanel from '~/pods/order/components/MainPanel';
import PaymentMethodCard from '~/pods/order/components/PaymentMethodCard';
import Title from '~/pods/order/components/Title';
import { getPriceLocale } from '~/pods/order/utils';
import { EstateEnums } from '~/pods/order/utils/estate-enums';
import { Bancontact, CreditCard, Ideal } from '~/pods/transactions/utils/payments';
import { stripe } from '~/pods/transactions/utils/payments/stripe';
import { getSessionUser } from '~/selectors';
import { readSession, run } from '~/utils';
import countries from '~/utils/countries';
import { createTranslate } from '~/utils/translation';

import bancontactImage from '../images/bancontact.svg';
import creditCardImage from '../images/credit-card.png';
import emailImage from '../images/email.png';
import idealImage from '../images/ideal.svg';
import wireTransferImage from '../images/wire-transfer.png';
import { ApolloMutation, ID } from '../types';
import { Transaction } from './Transaction';

const t = createTranslate('pods.payments.routes.settle');
const tt = createTranslate('pods.transactions');

export const ChargeTransactionMutation = gql`
  mutation ChargeTransaction($transactionId: ID!, $stripeHash: String!) {
    payload: chargeTransaction(input: { transactionId: $transactionId, stripeHash: $stripeHash }) {
      transaction {
        id
      }
    }
  }
`;

export const SendSettleTransactionEmailMutation = gql`
  mutation SendSettleTransactionEmail($transactionToken: String!, $email: String!) {
    payload: sendSettleTransaction(
      input: { transactionAccessToken: $transactionToken, recipientEmail: $email }
    ) {
      transaction {
        id
      }
    }
  }
`;

export const ConfirmPaymentMutation = gql`
  mutation ConfirmPayment($transactionId: ID!, $cardToken: String, $paymentIntentId: String) {
    payload: confirmPayment(
      input: {
        transactionId: $transactionId
        cardToken: $cardToken
        paymentIntentId: $paymentIntentId
      }
    ) {
      paymentIntentClientSecret
      requiresAction
      transaction {
        id
      }
    }
  }
`;

const styles = {
  container: css`
    width: 100%;
    max-width: 800px;
  `,
  infoBlock: css`
    margin-bottom: ${sv.baseMarginSmall};
  `,
  subtitle: css`
    color: ${sv.textTertiaryDark};
    font-family: ${sv.baseFontFamilyAlt};
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 0.9rem;

    @media ${sv.phoneXl} {
      font-size: 0.8rem;
    }
  `,
  blockContent: css`
    border-radius: ${sv.baseBorderRadius};
    background: ${sv.grey50};
    padding: ${sv.basePadding};
    color: ${sv.textPrimaryDark};
    font-size: 0.9rem;
    line-height: 1.5em;

    @media ${sv.phoneXl} {
      padding: ${sv.basePaddingSmall};
    }
  `,
  row: css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;

    &:last-of-type {
      padding-top: 10px;
      margin-bottom: ${sv.baseMarginSmall};
      border-top: 2px solid ${sv.grey300};
    }
  `,
  note: css`
    font-size: 0.7rem;
    color: ${sv.textSecondaryDark};
  `,
  submitButton: css`
    margin: ${sv.baseMargin} auto;
    text-align: center;

    button {
      min-width: 180px;
    }

    @media ${sv.phoneXl} {
      margin-bottom: calc(${sv.baseMargin} * 4);

      button {
        min-width: 100%;
      }
    }
  `,
  paymentMethod: css`
    margin-bottom: calc(${sv.baseMargin} * 2);

    @media ${sv.phoneXl} {
      overflow: scroll;
      margin-bottom: ${sv.baseMarginSmall};
      width: calc(100vw + ${sv.baseMarginSmall});
      margin-left: -40px;
      margin-right: -40px;
      padding: ${sv.basePaddingSmall} ${sv.basePadding};
    }
  `,
  paymentsGrid: css`
    @media ${sv.phoneXl} {
      width: calc(100% + 200px);
      padding-right: ${sv.basePaddingSmall};
    }
  `,
  wireTransferInfo: css`
    background: ${sv.grey50};
    padding: ${sv.basePaddingSmall};
    padding-bottom: ${sv.basePadding};
    margin-bottom: ${sv.baseMargin};
    color: ${sv.textPrimaryDark};
    line-height: 1.3em;
  `,
  wireTitle: css`
    color: ${sv.textSecondaryDark};
    font-size: 0.8rem;
    font-family: ${sv.baseFontFamilyAlt};
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 5px;
  `,
  transactionWrapper: css`
    @media ${sv.ipad} {
      padding-top: ${sv.baseMargin};
    }

    @media ${sv.phoneXl} {
      margin-bottom: calc(${sv.baseMargin} * -4);
    }
  `,
};

interface InfoBlockProps {
  children: ReactNode;
  title: string;
}

const InfoBlock = ({ children, title }: InfoBlockProps) => {
  return (
    <div className={styles.infoBlock}>
      <div className={styles.subtitle}>{title}</div>
      <div className={styles.blockContent}>{children}</div>
    </div>
  );
};

function _usePrepareLayout(
  paymentMethodElement?: HTMLDivElement | null,
  paymentsGridElement?: HTMLDivElement | null,
): void {
  useEffect(() => {
    if (paymentMethodElement == null || paymentsGridElement == null) return;
    const { width: gridWidth } = paymentsGridElement?.getBoundingClientRect();
    const { width: paymentMethodWidth } = paymentMethodElement?.getBoundingClientRect();
    paymentMethodElement.scrollLeft = (gridWidth - paymentMethodWidth) / 2 + 15;
  }, []);
}

interface TransactionSettleProps extends RouteComponentProps {
  transaction: Transaction;
  sessionUser: {
    getName: () => string;
  };
  chargeTransaction: ApolloMutation<
    { transactionId: ID; stripeHash: string },
    { payload: { transaction: { id: ID } } }
  >;
  showAlert: (id: string, type: string, message: string) => void;
  setAsVisited: (step: string) => Promise<void>;
  confirmPayment: ApolloMutation<
    { transactionId: ID; paymentIntentId?: ID; cardToken?: ID },
    { payload: { requiresAction: boolean } }
  >;
  sendSettleTransactionEmail: ApolloMutation<
    { transactionToken: string; email: string },
    { payload: { transaction: { id: ID } } }
  >;
}

const TransactionSettle = ({
  transaction,
  sessionUser,
  chargeTransaction,
  showAlert,
  history,
  setAsVisited,
  confirmPayment,
  sendSettleTransactionEmail,
}: TransactionSettleProps) => {
  const [paymentMethod, setPaymentMethod] = useState('creditCard');
  const [paying, setPaying] = useState(false);
  const paymentMethodRef = useRef<HTMLDivElement>(null);
  const paymentsGridRef = useRef<HTMLDivElement>(null);

  _usePrepareLayout(paymentMethodRef.current, paymentsGridRef.current);

  const userIsLoggedIn = readSession() != null;

  const priceLocale = getPriceLocale(transaction.amount, transaction.currency);
  const priceLocaleTaxes = getPriceLocale(
    transaction.priceWithTaxes.toFixed(2),
    transaction.currency,
  );
  const currentYear = dayjs().year();
  const taxes = getPriceLocale(
    (transaction.priceWithTaxes - transaction.amount).toFixed(2),
    transaction.currency,
  );

  const { estate } = transaction?.paymentProcess ?? {};

  let estatePrice;
  if (estate != null) {
    const { basePrice, modelingPrice } = estate;
    estatePrice = getPriceLocale(basePrice.value + modelingPrice, transaction.currency);
  }

  const salesOrder = transaction.paymentProcess?.salesOrder ?? transaction.salesOrder;
  const address = transaction.paymentProcess?.address ?? transaction.address!;

  const wireTransferAvailable = estate?.propertyType !== EstateEnums.PropertyType.EXISTING_PROPERTY;
  const emailAvailable =
    userIsLoggedIn && estate?.propertyType !== EstateEnums.PropertyType.EXISTING_PROPERTY;
  const bancontactAvailable =
    userIsLoggedIn &&
    estate?.propertyType === EstateEnums.PropertyType.EXISTING_PROPERTY &&
    transaction.currency === 'EUR';
  const idealAvailable = bancontactAvailable;

  const _goNext = async () => {
    const { transactionToken } = transaction;
    if (readSession() != null) {
      await setAsVisited('settle');
    }
    history.push(`/order/transaction/${transactionToken}/confirmation`);
  };

  const handlePayWithBancontactOrIdeal = async (type: string) => {
    const data = {
      type,
      amount: (transaction.priceWithTaxes * 100).toFixed(0),
      currency: transaction.currency,
      owner: {
        name: sessionUser ? sessionUser.getName() : transaction.organizationName,
      },
      redirect: {
        return_url: window.location.origin + '/return',
      },
    };
    try {
      const { id: transactionId } = transaction;
      const stripeResponse =
        type === 'bancontact'
          ? ((await Bancontact.initP(data)) as any)
          : ((await Ideal.initP(data)) as any);
      const { id: stripeHash, status } = stripeResponse.source;

      if (status !== 'chargeable') {
        throw new Error();
      }

      const { data: result } = await chargeTransaction({
        variables: { transactionId, stripeHash },
      });
      if (result.payload.transaction.id) {
        _goNext();
      }
    } catch (err) {
      const { message } = err as Error;
      setPaymentMethod('creditCard');

      if (message) {
        showAlert('PAYMENT_ERROR', 'error', message);
      } else {
        showAlert('PAYMENT_ERROR', 'error', t('errors.payment_failed'));
      }
    }
  };

  const _handlePayWithCreditCard = async (creditCard: {
    cardNumber: number;
    cardSecurityCode: number;
    month: number;
    year: number;
  }) => {
    const data = {
      number: creditCard.cardNumber,
      cvc: creditCard.cardSecurityCode,
      exp_month: creditCard.month,
      exp_year: creditCard.year,
    };
    try {
      setPaying(true);
      const tokenResponse = await CreditCard.initP(data);

      const handleAction = async (response: any): Promise<any> => {
        const { error, paymentIntent } = await stripe.handleCardAction(
          response.paymentIntentClientSecret,
        );

        if (error != null) {
          throw error;
        }

        const { data, errors = [] } = await confirmPayment({
          variables: { transactionId: transaction.id, paymentIntentId: paymentIntent.id },
        });
        const { payload: res } = data;

        if (errors[0] != null) {
          const err = new Error();
          err.message = tt(errors[0]?.message.replace('#', ''));
          throw err;
        } else if (res.requiresAction) {
          return await handleAction(res);
        }

        return res;
      };

      const chargeCard = async () => {
        const { data, errors = [] } = await confirmPayment({
          variables: { transactionId: transaction.id, cardToken: tokenResponse.id },
        });
        const { payload: res } = data;

        if (errors[0] != null) {
          const err = new Error();
          err.message = tt(errors[0]?.message.replace('#', ''));
          throw err;
        } else if (res.requiresAction) {
          return await handleAction(res);
        }

        return res;
      };

      const payload = await chargeCard();

      if (payload.transaction.id != null) {
        _goNext();
      }
    } catch (err) {
      const { message } = err as Error;
      showAlert('PAYMENT_ERROR', 'error', message ? message : t('errors.payment_failed'));
    } finally {
      setPaying(false);
    }
  };

  const _handleWireTransfer = () => _goNext();

  const _handleSendEmail = async (email: string) => {
    const transactionToken = transaction.transactionToken;
    const { data: result } = await sendSettleTransactionEmail({
      variables: { transactionToken, email },
    });
    if (result.payload.transaction.id) {
      _goNext();
    }
  };

  return (
    <MainPanel hideWhenDisabled asPanel>
      <div className={styles.transactionWrapper}>
        <Title>
          {transaction.invoice?.reference != null
            ? t('title', { invoice: transaction.invoice.reference })
            : t('title_no_ref')}
        </Title>
        <Grid halfVGutters halfHGutters style={{ marginBottom: sv.baseMargin }}>
          <Cell size="1of2" responsive="s1of1">
            <InfoBlock title={t('to_be_paid')}>
              <div className={styles.row}>
                <div>
                  {t('fields.to_be_paid_no_taxes')}
                  {salesOrder != null ? (
                    <div className={styles.note}>
                      {t('fields.down_payment', { percentage: salesOrder.downPaymentPercentage })}
                    </div>
                  ) : null}
                </div>
                <div>{priceLocale}</div>
              </div>
              {userIsLoggedIn && estate?.discountedPrice != null && estate?.discount != null ? (
                <div className={styles.row}>
                  <div>
                    {t('fields.discount')}
                    <div className={styles.note}>
                      {t('fields.price_without_discount', { price: estatePrice })}
                    </div>
                  </div>
                  <div>
                    {run(() => {
                      if (estate.discount == null) return null;
                      if (estate.discount.discountType === 'percentage_discount') {
                        return `${Math.round(estate.discount.rate * 100)}%`;
                      } else if (estate.discount.discountType === 'amount_discount') {
                        return estate.discount.amount;
                      }
                    })}
                  </div>
                </div>
              ) : null}
              <div className={styles.row}>
                <div>{t('fields.taxes')}</div>
                <div>{taxes}</div>
              </div>
              <div className={styles.row}>
                <div>{t('fields.to_be_paid')}</div>
                <div style={{ fontSize: '1.2rem' }}>{priceLocaleTaxes}</div>
              </div>
              {transaction.invoice?.url != null ? (
                <a href={transaction.invoice.url} target="_blank" rel="noopener noreferrer">
                  <Button category="info" size="small" round style={{ width: '100%' }}>
                    {t('fields.download_invoice')}
                  </Button>
                </a>
              ) : null}
            </InfoBlock>
          </Cell>
          <Cell size="1of2" responsive="s1of1">
            <InfoBlock title={t('fields.invoicing_address')}>
              {address.label} <br />
              {address.street} <br />
              {address.zipCode} {address.city} - {countries[address.countryCode].name} <br />
              {address.vat}
            </InfoBlock>
            <InfoBlock title={t('fields.order_date')}>
              <span style={{ marginRight: '5px' }}>
                <Icon name="clock" bold />
              </span>
              {dayjs(transaction.createdAt).format('dddd, MMMM DD YYYY')}
            </InfoBlock>
            {salesOrder && salesOrder.clientReference ? (
              <InfoBlock title={t('fields.internal_reference')}>
                {salesOrder.clientReference}
              </InfoBlock>
            ) : null}
          </Cell>
        </Grid>
        <div className={styles.subtitle}>{t('payment_methods.title')}</div>
        <div className={styles.paymentMethod} ref={paymentMethodRef}>
          <div className={styles.paymentsGrid} ref={paymentsGridRef}>
            <Grid withVGutters withHGutters>
              {run(() => {
                if (wireTransferAvailable) {
                  return (
                    <Cell size={userIsLoggedIn ? '1of3' : '1of2'}>
                      <PaymentMethodCard
                        title={t('payment_methods.wire_transfer')}
                        image={wireTransferImage}
                        checked={paymentMethod === 'wireTransfer'}
                        onClick={() => setPaymentMethod('wireTransfer')}
                      />
                    </Cell>
                  );
                } else if (bancontactAvailable) {
                  return (
                    <Cell size={userIsLoggedIn ? '1of3' : '1of2'}>
                      <PaymentMethodCard
                        title={t('payment_methods.bancontact')}
                        image={bancontactImage}
                        checked={paymentMethod === 'bancontact'}
                        onClick={() => {
                          setPaymentMethod('bancontact');
                          handlePayWithBancontactOrIdeal('bancontact');
                        }}
                      />
                    </Cell>
                  );
                }
              })}
              <Cell size={userIsLoggedIn ? '1of3' : '1of2'}>
                <PaymentMethodCard
                  title={t('payment_methods.credit_card')}
                  image={creditCardImage}
                  checked={paymentMethod === 'creditCard'}
                  onClick={() => setPaymentMethod('creditCard')}
                />
              </Cell>
              {run(() => {
                if (emailAvailable) {
                  return (
                    <Cell size="1of3">
                      <PaymentMethodCard
                        title={t('payment_methods.email')}
                        image={emailImage}
                        checked={paymentMethod === 'email'}
                        onClick={() => setPaymentMethod('email')}
                      />
                    </Cell>
                  );
                } else if (idealAvailable) {
                  return (
                    <Cell size={userIsLoggedIn ? '1of3' : '1of2'}>
                      <PaymentMethodCard
                        title={t('payment_methods.ideal')}
                        image={idealImage}
                        checked={paymentMethod === 'ideal'}
                        onClick={() => {
                          setPaymentMethod('ideal');
                          handlePayWithBancontactOrIdeal('ideal');
                        }}
                      />
                    </Cell>
                  );
                }
              })}
            </Grid>
          </div>
        </div>
        {run(() => {
          if (paymentMethod === 'creditCard') {
            return (
              <Form name="creditCard" onSubmit={_handlePayWithCreditCard}>
                <Grid halfVGutters halfHGutters>
                  <Cell size="2of3" responsive="s1of1">
                    <FormGroup>
                      <Label required>{t('credit_card.number.label')}</Label>
                      <Input
                        className="_lr-hide"
                        name="cardNumber"
                        placeholder={t('credit_card.number.placeholder')}
                        restriction="number"
                        validation={(v: any) => v.isEmpty(t('errors.not_empty'))}
                      />
                    </FormGroup>
                  </Cell>
                  <Cell size="1of3" responsive="s1of2">
                    <Grid halfHGutters>
                      <Cell size="1of2">
                        <FormGroup>
                          <Label required>{t('credit_card.month.label')}</Label>
                          <Select
                            name="month"
                            validation={(v: any) => v.isEmpty(t('errors.not_empty'))}
                            values={Array.from(new Array(12), (_, i) => ({
                              value: i + 1,
                              label: i + 1,
                            }))}
                          />
                        </FormGroup>
                      </Cell>
                      <Cell size="1of2" responsive="s1of2">
                        <FormGroup>
                          <Label required>{t('credit_card.year.label')}</Label>
                          <Select
                            name="year"
                            validation={(v: any, vs: any) =>
                              v.isEmpty(t('errors.not_empty')).use((v: any) => {
                                const currentYear = new Date().getFullYear();
                                const currentMonth = new Date().getMonth();
                                if (String(currentYear) === String(v)) {
                                  return currentMonth > parseInt(vs.month)
                                    ? t('errors.invalid_date')
                                    : undefined;
                                }
                                return currentYear > parseInt(v)
                                  ? t('errors.invalid_date')
                                  : undefined;
                              })
                            }
                            values={Array.from(new Array(10), (_, i) => ({
                              value: i + currentYear,
                              label: i + currentYear,
                            }))}
                          />
                        </FormGroup>
                      </Cell>
                    </Grid>
                  </Cell>
                  <Cell size="2of3">
                    <FormGroup>
                      <Label required>{t('credit_card.name.label')}</Label>
                      <Input placeholder={t('credit_card.name.placeholder')} />
                    </FormGroup>
                  </Cell>
                  <Cell size="1of3">
                    <FormGroup>
                      <Label required>{t('credit_card.cvc.label')}</Label>
                      <Input
                        className="_lr-hide"
                        name="cardSecurityCode"
                        placeholder={t('credit_card.cvc.placeholder')}
                        validation={(v: any) => v.isEmpty(t('errors.not_empty'))}
                        restriction="number"
                      />
                    </FormGroup>
                  </Cell>
                </Grid>
                <div className={styles.submitButton}>
                  <Button round disabled={paying} id="settle-submit-button" category="success">
                    {t('buttons.submit')}
                  </Button>
                </div>
              </Form>
            );
          } else if (paymentMethod === 'wireTransfer') {
            return (
              <Fragment>
                <Grid halfHGutters halfVGutters className={styles.wireTransferInfo}>
                  <Cell size="1of4" responsive="s1of1">
                    <div className={styles.wireTitle}>{t('wire_transfer.iban')}</div>
                    <div>BE32 0018 0004 8602</div>
                  </Cell>
                  <Cell size="1of4" responsive="s1of1">
                    <div className={styles.wireTitle}>{t('wire_transfer.bic')}</div>
                    <div>GEBABEBB</div>
                  </Cell>
                  <Cell size="1of4" responsive="s1of1">
                    <div className={styles.wireTitle}>{t('wire_transfer.address')}</div>
                    <div>
                      Place Communale d'Auderghem, 8 <br />
                      1160 Brussels Belgium
                    </div>
                  </Cell>
                  <Cell size="1of4" responsive="s1of1">
                    <div className={styles.wireTitle}>{t('wire_transfer.communication')}</div>
                    <div>
                      {t('wire_transfer.invoice_reference', {
                        invoice_reference: transaction?.invoice?.reference,
                      })}
                    </div>
                  </Cell>
                </Grid>
                <div className={styles.submitButton}>
                  <Button
                    round
                    id="settle-submit-button"
                    category="success"
                    onClick={_handleWireTransfer}>
                    {t('buttons.submit')}
                  </Button>
                </div>
              </Fragment>
            );
          } else if (paymentMethod === 'email') {
            return (
              <Form name="email" onSubmit={(values: any) => _handleSendEmail(values.email)}>
                <FormGroup>
                  <Label required>{t('email.label')}</Label>
                  <Input name="email" placeholder={t('email.placeholder')} />
                </FormGroup>
                <div className={styles.submitButton}>
                  <Button round id="settle-submit-button" category="success">
                    {t('buttons.submit')}
                  </Button>
                </div>
              </Form>
            );
          }
        })}
      </div>
    </MainPanel>
  );
};

TransactionSettle.fragments = {
  Transaction: gql`
    fragment _ on Transaction {
      id
      transactionToken: accessToken
      amount
      priceWithTaxes
      currency
      createdAt
      organizationName: organisationName
      invoice {
        reference
        url
      }
      paymentProcess {
        estate: orderEstate {
          name
          propertyType
          jsonMeta @client(type: Estate)
          modelingPrice @client(type: Estate)
          basePrice {
            value
          }
          discountedPrice {
            value
          }
          discount {
            rate
            amount
            discountType
          }
        }
        salesOrder {
          clientReference
          downPaymentPercentage
        }
        address {
          ${Address.fragments.Address}
        }
      }
    }
  `,
  PublicTransaction: gql`
    fragment _ on Transaction {
      id
      transactionToken: accessToken
      amount
      priceWithTaxes
      currency
      organizationName: organisationName
      invoice {
        reference
        url
      }
      salesOrder {
        downPaymentPercentage
        clientReference
      }
      address {
        ${Address.fragments.Address}
      }
    }
  `,
};

const mapStateToProps = (state: any) => ({
  sessionUser: getSessionUser(state),
});

const mapDispatchToProps = {
  showAlert,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql(ChargeTransactionMutation, {
    name: 'chargeTransaction',
  }),
  graphql(SendSettleTransactionEmailMutation, {
    name: 'sendSettleTransactionEmail',
    skip: !readSession(),
  }),
  graphql(ConfirmPaymentMutation, {
    name: 'confirmPayment',
  }),
)(TransactionSettle);
