import React from 'react';
import { Grid, Cell, Offset } from 'react-ittsu/layout';
import gql from 'fraql';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';

import Title from '../components/Title';
import MainPanel from '../components/MainPanel';
import OrderOption from '../components/OrderOption';
import ErrorMessage from '../components/ErrorMessage';
import EstateResetConfirmModal from '../components/EstateResetConfirmModal';
import { EstateEnums } from '../utils/estate-enums';
import { createTranslate, translate as t } from '~/utils/translation';

import houseIcon from '../images/icons/single-house-icon.svg';
import buildingIcon from '../images/icons/building-icon.svg';
import complexIcon from '../images/icons/complex-icon.svg';
import apartmentIcon from '../images/icons/apartment-icon.svg';
import buildingIconAlt from '../images/icons/building-icon-alt.svg';
import duplexIcon from '../images/icons/duplex-icon.svg';
import commercialIcon from '../images/icons/commercial-icon.svg';


const tt = createTranslate('pods.order.routes.estate_subtype');


const newDevelopmentCards = {
  [EstateEnums.ProjectType.RESIDENTIAL]: [
    { href: '/details', label: () => t('pods.order.project_subtype.house'), value: EstateEnums.ProjectSubtype.HOUSE, image: houseIcon },
    { href: '/details', label: () => t('pods.order.project_subtype.building'), value: EstateEnums.ProjectSubtype.BUILDING, image: buildingIcon },
    { href: '/details', label: () => t('pods.order.project_subtype.complex'), value: EstateEnums.ProjectSubtype.COMPLEX, image: complexIcon },
  ],
  [EstateEnums.ProjectType.COMMERCIAL]: [
    { href: '/details', label: () => t('pods.order.project_subtype.building'), value: EstateEnums.ProjectSubtype.BUILDING, image: buildingIcon },
    { href: '/details', label: () => t('pods.order.project_subtype.complex'), value: EstateEnums.ProjectSubtype.COMPLEX, image: complexIcon },
  ]
};


const existingPropertyCards = [
  { href: '/details', label: () => t('pods.order.project_subtype.house'), value: EstateEnums.ProjectSubtype.HOUSE, image: houseIcon },
  { href: '/details', label: () => t('pods.order.project_subtype.apartment'), value: EstateEnums.ProjectSubtype.APARTMENT, image: apartmentIcon },
  { href: '/details', label: () => t('pods.order.project_subtype.duplex'), value: EstateEnums.ProjectSubtype.DUPLEX, image: duplexIcon },
  { href: '/details', label: () => t('pods.order.project_subtype.building'), value: EstateEnums.ProjectSubtype.BUILDING, image: buildingIconAlt },
  { href: '/details', label: () => t('pods.order.project_subtype.commercial'), value: EstateEnums.ProjectSubtype.COMMERCIAL, image: commercialIcon },
];


class EstateSubtype extends React.Component {

  static propTypes = {
    estate: PropTypes.object.isRequired,
    updateEstate: PropTypes.func.isRequired,
  };

  static fragments = {
    Subtype: gql`
      fragment _ on Estate {
        id
        projectType
        projectSubtype
        propertyType
        isExistingProperty @client(type: Estate)
        jsonMeta @client(type: Estate)
      }
    `,
  };

  state = {
    showError: false,
  };

  render() {
    const { estate, goBack } = this.props;
    const { showError } = this.state;
    const cards = estate.isExistingProperty ? existingPropertyCards : newDevelopmentCards[estate.projectType];
    const spacedCards = estate.projectType === EstateEnums.ProjectType.COMMERCIAL;
    return (
      <MainPanel
        onClickBack={goBack}
        onClickNext={this._goNext}>
        <Title>
          {tt('type_of_project', { project_type: t(`pods.order.project_type.${estate.projectType}`) })}
        </Title>
        {do {
          if (showError) {
            <ErrorMessage>
              {tt('errors.select_subtype')}
            </ErrorMessage>
          }
        }}
        <EstateResetConfirmModal>
          {(confirm) => (
            <Grid withHGutters withVGutters>
              {spacedCards ? <Offset size="1of6" /> : null}
              {cards.map((c) => (
                <Cell size={spacedCards ? '2of6' : '1of3'} key={c.label}>
                  <OrderOption
                    image={c.image}
                    title={c.label()}
                    checked={estate.projectSubtype === c.value}
                    onClick={() => this._updateSubtype(c.value, confirm)}>
                    {tt(`${c.value}_description`)}
                  </OrderOption>
                </Cell>
              ))}
              {spacedCards ? <Offset size="1of6" /> : null}
            </Grid>
          )}
        </EstateResetConfirmModal>
      </MainPanel>
    );
  }

  @autobind
  async _updateSubtype(projectSubtype, confirm) {
    const { updateEstate, estate, resetEstate } = this.props;
    if (estate.projectSubtype === projectSubtype) {
      return;
    }

    const shouldReset = estate.projectSubtype != null;
    const shouldAskConfirm = Object.keys(estate.jsonMeta.steps).includes('details');
    const askConfirm = shouldAskConfirm ? confirm : () => true;
    const confirmed = ! shouldReset || (askConfirm && await askConfirm());
    if (confirmed) {
      if (shouldReset) {
        await resetEstate({ projectSubtype: EstateEnums.ProjectSubtype.NONE });
      }
      const resetSteps = {
        'property-type': estate.jsonMeta.steps?.['property-type'],
        'type': estate.jsonMeta.steps?.type,
        'subtype': estate.jsonMeta.steps?.subtype,
      };
      const updatedEstate = await updateEstate({
        projectSubtype,
        metadata: JSON.stringify({ ...estate.jsonMeta, steps: resetSteps }),
      });
      if (updatedEstate.projectSubtype !== projectSubtype) {
        console.error('Something happened when updating projectSubtype');
      }
      else {
        this.setState({ showError: false });
      }
    }
  }

  @autobind
  _goNext() {
    const { estate, goNext } = this.props;
    estate.projectSubtype ? goNext() : this.setState({ showError: true });
  }

}


export default EstateSubtype;
