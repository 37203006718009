import omit from 'lodash/omit';


function cleanValues(values) {
  return values.filter((v) => v !== null);
}


export function pruneSection(section, keys) {
  const { components } = section;
  const filteredComponents = components.filter((component) => ! keys.includes(component.key));
  if (filteredComponents.length === 0) {
    return null;
  }
  else {
    // eslint-disable-next-line no-use-before-define
    const prunedComponents = filteredComponents.map((component) => pruneComponent(component, keys));
    return { ...section, components: cleanValues(prunedComponents) };
  }
}


export function pruneComponent(component, keys) {
  const { type, values } = component;
  if (type === 'meta') {
    return pruneSection(component, keys);
  }
  else if (values) {
    const newValues = values.map((value) => value.meta ? ({ ...value, meta: pruneSection(value.meta, keys) }) : value);
    return {
      ...component,
      values: newValues.map((v) => v.meta === null ? omit(v, 'meta') : v),
    };
  }
  else {
    return component;
  }
}


export default function filterDescription(description, keys) {
  return Object.keys(description).reduce((sections, index) => {
    const section = pruneSection(description[index], keys);
    return section ? { ...sections, [index]: section } : sections;
  } , {});
}
