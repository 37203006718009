import { EstateEnums } from '../../utils/estate-enums';


export function processServices(services) {
  // eslint-disable-next-line
  services = services.map((s) => ({ ...s, availableFor: eval(s.availableFor) }));
  return {
    all: services,
    newDevelopment: {
      all: services.filter((i) => i.availableFor.includes(EstateEnums.PropertyType.NEW_DEVELOPMENT)),
      byCategory: services.filter((i) => i.availableFor.includes(EstateEnums.PropertyType.NEW_DEVELOPMENT)).reduce((memo, s) => {
        const services = memo[s.category.slug];
        return {
          ...memo,
          [s.category.slug]: services ? [...services, s] : [s],
        };
      }, {}),
    },
    existingProperty: {
      all: services.filter((i) => i.availableFor.includes(EstateEnums.PropertyType.EXISTING_PROPERTY)),
      byCategory: services.filter((i) => i.availableFor.includes(EstateEnums.PropertyType.EXISTING_PROPERTY)).reduce((memo, s) => {
        const services = memo[s.category.slug];
        return {
          ...memo,
          [s.category.slug]: services ? [...services, s] : [s],
        };
      }, {}),
    },
  };
}
