import React from 'react';
import PropTypes from 'prop-types';
import { css, cx } from 'emotion';
import { T3 } from 'react-ittsu/components';
import sv from '@drawbotics/style-vars';


const styles = {
  title: css`
    text-align: center;
    color: ${sv.grey600};
    margin-bottom ${sv.baseMargin};
    font-size: 1rem;

    @media ${sv.ipadLandscape} {
      font-size: 0.8rem;
    }

    @media ${sv.ipad} {
      font-size: 1rem;
    }
  `,
};


const Title = ({
  className,
  children,
}) => {
  return (
    <T3 className={cx(styles.title, className)}>
      {children}
    </T3>
  );
}

Title.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
}

export default Title;
