import { EmptyStateVariation } from '@drawbotics/react-drylus';
import { CombinedError } from 'urql';

export enum ErrorType {
  CRITICAL = 'CRITICAL',
  NOT_FOUND = 'NOT_FOUND',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
}

enum GraphqlErrorMessage {
  NOT_FOUND = 'not_found',
  NOT_AUTHORIZED = 'unauthorized',
}

type RestError = { status: number | null; ok: boolean };
type GraphQLSimpleError = { errors: Array<{ message: string }> };
type Error = CombinedError | RestError | GraphQLSimpleError | undefined;

function _isCombinedError(error: Error): error is CombinedError {
  const combinedError = error as CombinedError;
  return combinedError?.response != null && combinedError.name === 'CombinedError';
}

function _isRestError(error: Error): error is RestError {
  return (error as RestError)?.status != null;
}

function _isGraphQLSimpleError(error: Error): error is GraphQLSimpleError {
  return (error as GraphQLSimpleError)?.errors != null;
}

function _getErrorType(error: Error): ErrorType {
  if (_isCombinedError(error)) {
    const { response, graphQLErrors } = error;
    if (response.status === 404) return ErrorType.NOT_FOUND;
    if (response.status === 401 || response.status === 403) return ErrorType.NOT_AUTHORIZED;
    if (graphQLErrors.some((error) => error.message === GraphqlErrorMessage.NOT_FOUND)) {
      return ErrorType.NOT_FOUND;
    }
    if (graphQLErrors.some((error) => error.message === GraphqlErrorMessage.NOT_AUTHORIZED)) {
      return ErrorType.NOT_AUTHORIZED;
    }
    return ErrorType.CRITICAL;
  }
  if (_isGraphQLSimpleError(error)) {
    if (error.errors.some((error) => error.message === GraphqlErrorMessage.NOT_FOUND)) {
      return ErrorType.NOT_FOUND;
    }
    if (error.errors.some((error) => error.message === GraphqlErrorMessage.NOT_AUTHORIZED)) {
      return ErrorType.NOT_AUTHORIZED;
    }
    return ErrorType.CRITICAL;
  }
  if (_isRestError(error)) {
    const { status } = error;
    if (status === 404) return ErrorType.NOT_FOUND;
    if (status === 401 || status === 403) return ErrorType.NOT_AUTHORIZED;
    return ErrorType.CRITICAL;
  }
  return ErrorType.CRITICAL;
}

export function buildError(error: Error | Array<Error>): ErrorType | undefined {
  if (Array.isArray(error) && error.length > 0) {
    const filtered = error.filter((err) => err != null) as Array<Error>;
    return filtered
      .map((err: Error) => _getErrorType(err))
      .sort((a) => (a === ErrorType.CRITICAL ? 1 : -1))[0]; // push critical to end of list
  }
  if (error == null) return error;
  return _getErrorType(error as Error);
}

export function extractGQLErrorMessages(error: Error): { message?: string; all?: Array<string> } {
  if (_isCombinedError(error)) {
    return {
      message: error.graphQLErrors[0].message,
      all: error.graphQLErrors.map((error) => error.message),
    };
  }
  return {};
}

export function getEmptyStateVariationFromErrorType(error?: ErrorType): EmptyStateVariation {
  if (error === ErrorType.CRITICAL) {
    return EmptyStateVariation.FAILED;
  }
  if (error === ErrorType.NOT_AUTHORIZED) {
    return EmptyStateVariation.NOT_ALLOWED;
  }
  if (error === ErrorType.NOT_FOUND) {
    return EmptyStateVariation.NOT_FOUND;
  }
  return EmptyStateVariation.DEFAULT;
}
