export function startLoading(key) {
  return {
    isLoadingAction: true,
    startLoading: true,
    key,
  };
}


export function stopLoading(key) {
  return {
    isLoadingAction: true,
    stopLoading: true,
    key,
  };
}
