import React from 'react';
import { connect } from 'react-redux';

import { getErrors } from 'selectors';
import CriticalError from 'routes/CriticalError';
import NotFound from 'routes/NotFound';


export default function handleErrors() {

  const mapStateToProps = (state) => ({
    errors: getErrors(state),
  });

  const mapDispatchToProps = {
  };

  return (Component) => {
    class ErrorWrappedRoute extends React.Component {

      render() {
        const { errors } = this.props;
        if (errors.criticalError) {
          return React.createElement(CriticalError);
        }
        if (errors.notFoundError) {
          return React.createElement(NotFound);
        }
        return React.createElement(Component, { ...this.props });
      }

    }

    return connect(mapStateToProps, mapDispatchToProps)(ErrorWrappedRoute);
  };
}
