import sv from '@drawbotics/style-vars';
import { css, cx, keyframes } from 'emotion';
import React, { CSSProperties } from 'react';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const styles = {
  container: css`
    position: relative;
  `,
  spinner: css`
    border-radius: 50%;
    width: 24px;
    height: 24px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-top-color: rgba(0, 0, 0, 0.4);
    animation: ${spin} calc(${sv.baseTransitionTime} * 2) infinite linear;
  `,
  inversed: css`
    border-color: rgba(255, 255, 255, 0.2);
    border-top-color: ${sv.white};
  `,
  text: css`
    position: absolute;
    bottom: calc(${sv.baseMargin} * -1);
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: ${sv.textSecondaryDark};
    font-size: 0.9rem;
    white-space: nowrap;
  `,
};

interface SpinnerProps {
  size?: number | string;
  inversed?: boolean;
  containerStyle?: CSSProperties;
  text?: string;
}

const Spinner = ({ size, inversed, containerStyle, text }: SpinnerProps) => {
  return (
    <div className={styles.container} style={containerStyle}>
      <div
        data-element="spinner"
        className={cx(styles.spinner, {
          [styles.inversed]: inversed,
        })}
        style={{ width: `${size}px`, height: `${size}px` }}></div>
      {text != null ? <div className={styles.text}>{text}</div> : null}
    </div>
  );
};

export default Spinner;
