/**
 * This reducer is not used in the application.
 * It's only purpose it's for testing the report of
 * errors to sentry.
 */
export default function errors(state={}, action) {
  switch (action.type) {
    case 'TRIGGER_ERROR_IN_REDUCER': {
      throw new Error('TESTING ERRORS IN REDUCER');
    }
    default: {
      return state;
    }
  }
}
