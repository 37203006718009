import React, { Fragment } from 'react';
import gql from 'fraql';

import { withUser } from '~/utils/with-user';
import {
  Exterior3D,
  Interior3D,
  Shoebox,
  SitePlan,
  CommercialFloorplan,
  Panorama360,
  Model360,
  VideoAnimation,
  Tour3D,
  BrandID,
  Brochure,
  Billboard,
  Website,
  LandingPage,
  VRHeadset,
  Newsletter,
  Flyer,
  MediaKit,
  SocialMediaKit,
  PhotoShooting,
  Revo,
  PhotoEditing,
  ExteriorRestyling,
  Plan2D,
  ExteriorTour3D,
  Presentation,
} from './modals';


export const ServiceModal = ({
  isVisible,
  service,
  ...rest
}) => {
  return (
    <Fragment>
      <Exterior3D service={service} isVisible={isVisible} {...rest} />
      <Interior3D service={service} isVisible={isVisible} {...rest} />
      <Shoebox service={service} isVisible={isVisible} {...rest} />
      <SitePlan service={service} isVisible={isVisible} {...rest} />
      <CommercialFloorplan service={service} isVisible={isVisible} {...rest} />
      <Panorama360 service={service} isVisible={isVisible} {...rest} />
      <Model360 service={service} isVisible={isVisible} {...rest} />
      <VideoAnimation service={service} isVisible={isVisible} {...rest} />
      <Tour3D service={service} isVisible={isVisible} {...rest} />
      <BrandID service={service} isVisible={isVisible} {...rest} />
      <Brochure service={service} isVisible={isVisible} {...rest} />
      <Billboard service={service} isVisible={isVisible} {...rest} />
      <Website service={service} isVisible={isVisible} {...rest} />
      <LandingPage service={service} isVisible={isVisible} {...rest} />
      <VRHeadset service={service} isVisible={isVisible} {...rest} />
      <Newsletter service={service} isVisible={isVisible} {...rest} />
      <Flyer service={service} isVisible={isVisible} {...rest} />
      <MediaKit service={service} isVisible={isVisible} {...rest} />
      <SocialMediaKit service={service} isVisible={isVisible} {...rest} />
      <PhotoShooting service={service} isVisible={isVisible} {...rest} />
      <PhotoEditing service={service} isVisible={isVisible} {...rest} />
      <ExteriorRestyling service={service} isVisible={isVisible} {...rest} />
      <Plan2D service={service} isVisible={isVisible} {...rest} />
      <Revo service={service} isVisible={isVisible} {...rest} />
      <ExteriorTour3D service={service} isVisible={isVisible} {...rest} />
      <Presentation service={service} isVisible={isVisible} {...rest} />
    </Fragment>
  );
};


ServiceModal.fragments = {
  Item: gql`
    fragment _ on Item {
      id
      ${Exterior3D.fragments.Item}
      ${Interior3D.fragments.Item}
      ${Shoebox.fragments.Item}
      ${SitePlan.fragments.Item}
      ${CommercialFloorplan.fragments.Item}
      ${Panorama360.fragments.Item}
      ${Model360.fragments.Item}
      ${VideoAnimation.fragments.Item}
      ${BrandID.fragments.Item}
      ${Brochure.fragments.Item}
      ${Billboard.fragments.Item}
      ${LandingPage.fragments.Item}
      ${VRHeadset.fragments.Item}
      ${Newsletter.fragments.Item}
      ${Flyer.fragments.Item}
      ${MediaKit.fragments.Item}
      ${SocialMediaKit.fragments.Item}
      ${PhotoShooting.fragments.Item}
      ${PhotoEditing.fragments.Item}
      ${Website.fragments.Item}
      ${Revo.fragments.Item}
      ${Tour3D.fragments.Item}
      ${ExteriorRestyling.fragments.Item}
      ${Plan2D.fragments.Item}
      ${ExteriorTour3D.fragments.Item}
      details {
        ... on DroneShootingDetails {
          exterior3d
          interior3d
          sitePlan
          videoAnimation
          revo
          panorama360
          model360
        }
      }
    }
  `,
};


export default withUser()(ServiceModal);
