import * as ErrorsActions from 'actions/errors';


const initialState = {
};


export default function errors(state=initialState, action) {
  switch (action.type) {
    case ErrorsActions.TRIGGER_CRITICAL: {
      return { ...state, criticalError: true };
    }
    case ErrorsActions.TRIGGER_NOT_FOUND: {
      return { ...state, notFoundError: true };
    }
    default: {
      return state;
    }
  }
}
