import React from 'react';
import styles from 'pods/studio/styles/components/annotation';


class Text extends React.Component {

  componentDidMount() {
    this._focusAnnotation();
  }

  render() {
    const {
      isEditable,
      text,
      onChange,
    } = this.props;
    return (
      <textarea
        rows="3"
        ref={annot => this.annot = annot}
        className={styles.textarea}
        value={text}
        onChange={onChange}
        disabled={ ! isEditable} />
    );
  }

  _focusAnnotation() {
    var position = this.props.text.length + 1;
    if(this.annot) {
      this.annot.focus();
      this.annot.setSelectionRange(position, position);
    }
  }

}

export default Text;
