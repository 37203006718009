import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  T2,
  Panel,
  PanelHeader,
  PanelTitle,
  PanelBody,
  Table,
  TCell,
  TRow,
  TBody,
  Button,
  Paragraph,
} from 'react-ittsu/components';
import { Grid, Cell, Container } from 'react-ittsu/layout';
import { FormGroup, Label, Select, Input, Form } from 'react-ittsu/forms';

import {
  payWithBancontact,
  payWithCreditCard,
  payWithIdeal,
  loadTransaction,
  updateTransactionAddress,
  redirectTransactionAlreadyPaid,
} from 'pods/transactions/actions';
import { getTransaction } from 'pods/transactions/selectors';
import Link from 'components/Link';
import RouteContainer from 'components/RouteContainer';
import LoadingContainer from 'containers/LoadingContainer';
import { translate as t, createTranslate } from 'utils/translation';
import { codeToSymbol } from '../utils';

import styles from 'pods/transactions/styles/routes/transaction';


function isValidAddress(address) {
  if (! address) {
    return false;
  }
  return address.street && address.city && address.label && address.zipCode && address.countryCode;
}


const et = createTranslate('forms.errors');
const tt = createTranslate('pods.transactions.routes.transaction');


class Transaction extends React.Component {

  state = {
    currentAddress: null,
  }

  constructor(props) {
    super(props);
    this._handlePayWithCreditCard = this._handlePayWithCreditCard.bind(this);
    this._updateTransactionAddress = this._updateTransactionAddress.bind(this);
  }

  componentDidMount() {
    const { loadTransaction, params } = this.props;
    loadTransaction(params.id);
  }

  render() {
    const { payWithBancontact, transaction, payWithIdeal, push, redirectTransactionAlreadyPaid } = this.props;
    const { currentAddress } = this.state;
    const isAddressValid = isValidAddress(get(transaction, 'billingAddress', null));
    if (transaction && transaction.paid) {
      redirectTransactionAlreadyPaid();
      return null;
    }
    return (
      <LoadingContainer
        loadingKeys="transaction sessionUser startPayment"
        defaultTo={false}
        required={[
          transaction,
          get(transaction, 'buyer'),
        ]}
        whenLoaded={() => (
          <RouteContainer>
            <Container>
              <div className={styles.transaction}>
                <T2 brand className={styles.title}>
                  {tt('transaction_payment')}
                </T2>
                <Grid withHGutters withVGutters>
                  <Cell size="3of7" responsive="m1of1">
                    <Panel>
                      <PanelHeader>
                        <PanelTitle>
                          {tt('order_synthesis')}
                        </PanelTitle>
                      </PanelHeader>
                      <PanelBody>
                        <div className={styles.section}>
                          <Table fullWidth>
                            <TBody>
                              <TRow>
                                <TCell>
                                  <span className={styles.label}>{tt('amount')}</span>
                                </TCell>
                                <TCell colSpan={2}>
                                  {transaction.amount}
                                </TCell>
                              </TRow>
                              <TRow>
                                <TCell>
                                  <span className={styles.label}>{tt('bonus')}</span>
                                </TCell>
                                <TCell colSpan={2}>
                                  {transaction.bonus}
                                </TCell>
                              </TRow>
                              <TRow>
                                <TCell>
                                  <span className={styles.label}>{tt('organisation')}</span>
                                </TCell>
                                <TCell colSpan={2}>
                                  {transaction.recipientType === 'Organisation' ?
                                    transaction.recipientOrganization.name : transaction.recipientUser.getName()}
                                </TCell>
                              </TRow>
                              <TRow>
                                <TCell>
                                  <span className={styles.label}>{tt('buyer')}</span>
                                </TCell>
                                <TCell colSpan={2}>
                                  {transaction.buyer.getName()}
                                </TCell>
                              </TRow>
                            </TBody>
                          </Table>
                        </div>
                        {do {
                          if (isAddressValid) {
                            <Fragment>
                              <div className={styles.section}>
                                <Form>
                                  <FormGroup>
                                    <Label>{tt('billing_address')}</Label>
                                    <Select
                                      name="address"
                                      onChange={this._updateTransactionAddress}
                                      value={get(transaction, 'buyer.organization.addresses', []).find((a) => a.id === currentAddress)}
                                      values={
                                        get(transaction, 'buyer.organization.addresses', [])
                                          .map((a) => ({ label: a.label , value: a.id }))
                                      } />
                                  </FormGroup>
                                </Form>
                              </div>
                              <div className={styles.section}>
                                <Table fullWidth>
                                  <TBody>
                                    <TRow>
                                      <TCell>{transaction.billingAddress.street}</TCell>
                                    </TRow>
                                    <TRow>
                                      <TCell>
                                        {transaction.billingAddress.city}&nbsp;
                                        {transaction.billingAddress.zipCode}&nbsp;
                                        {transaction.billingAddress.country}&nbsp;
                                      </TCell>
                                    </TRow>
                                    <TRow>
                                      <TCell>
                                        <Link href="/addresses" underlined="always" category="classic">{tt('add_address')}</Link>
                                      </TCell>
                                    </TRow>
                                  </TBody>
                                </Table>
                              </div>
                            </Fragment>
                          }
                          else {
                            <div className={styles.section}>
                              <Paragraph className={styles.noAddress}>{tt('no_address')}</Paragraph>
                              <Button variation="inversed" category="primary" className={styles.button} onClick={() => push('/addresses')}>{tt('add_address')}</Button>
                            </div>
                          }
                        }}
                        <div className={styles.section}>
                          <Table fullWidth variation="borderless">
                            <TBody>
                              <TRow>
                                <TCell>
                                  <span className={styles.label}>{tt('price_excl_taxes')}</span>
                                </TCell>
                                <TCell style={{ textAlign: 'right' }}>
                                  <span className={styles.value}>{t('pods.transactions.routes.orders.price_with_symbol', { price: transaction.priceExclTaxes, currency: codeToSymbol(transaction.currency) })}</span>
                                </TCell>
                              </TRow>
                              <TRow>
                                <TCell>
                                  <span className={styles.label}>{tt('vat_rate')}</span>
                                </TCell>
                                <TCell style={{ textAlign: 'right' }}>
                                  <span className={styles.value}>{transaction.vat}%</span>
                                </TCell>
                              </TRow>
                              <TRow>
                                <TCell>
                                  <span className={styles.label}>{tt('price')}</span>
                                  <span className={styles.subtitle}>{tt('price_including_tax')}</span>
                                </TCell>
                                <TCell style={{ textAlign: 'right' }}>
                                  <span className={styles.value}>{t('pods.transactions.routes.orders.price_with_symbol', { price: transaction.priceWithTaxes, currency: codeToSymbol(transaction.currency) })}</span>
                                </TCell>
                              </TRow>
                            </TBody>
                          </Table>
                        </div>
                      </PanelBody>
                    </Panel>
                  </Cell>
                  <Cell size="4of7" responsive="m1of1">
                    <Panel>
                      <PanelHeader>
                        <PanelTitle>{tt('pay_with_credit_cards')}</PanelTitle>
                      </PanelHeader>
                      <Form name="creditCard" onSubmit={this._handlePayWithCreditCard}>
                        <PanelBody className={styles.panel}>
                          <FormGroup>
                            <Label required>{tt('card.card_number')}</Label>
                            <Input
                              className="_lr-hide"
                              name="cardNumber"
                              placeholder="4242 4242 4242 4242"
                              restriction="number"
                              validation={(v) => v.isEmpty(et('empty_field'))}
                              disabled={! isAddressValid} />
                          </FormGroup>
                          <Grid withHGutters>
                            <Cell size="2of3">
                              <FormGroup>
                                <Label>{tt('card.expiration_date')}</Label>
                                <Grid halfHGutters>
                                  <Cell size="1of2">
                                    <Select
                                      name="month"
                                      validation={(v) => v.isEmpty(et('empty_field'))}
                                      values={Array.from(new Array(12), (v, i) => ({ value: i+1, label: i+1 }))}
                                      disabled={! isAddressValid} />
                                  </Cell>
                                  <Cell size="1of2">
                                    <Select
                                      name="year"
                                      validation={(v, vs) => v.isEmpty(et('empty_field')).use((v) => {
                                        const currentYear = parseInt(new Date().getFullYear());
                                        const currentMonth = parseInt(new Date().getMonth());
                                        if (String(currentYear) === String(v)) {
                                          return currentMonth > parseInt(vs.month) ? 'Invalid date' : undefined;
                                        }
                                        return currentYear > parseInt(v) ? 'Invalid date' : undefined;
                                      })}
                                      values={Array.from(new Array(10), (v, i) => ({ value: i+2017, label: i+2017 }))}
                                      disabled={! isAddressValid} />
                                  </Cell>
                                </Grid>
                              </FormGroup>
                            </Cell>
                            <Cell size="1of3">
                              <FormGroup>
                                <Label required>{tt('card.cvc')}</Label>
                                <Input
                                  className="_lr-hide"
                                  name="cardSecurityCode"
                                  placeholder="242"
                                  validation={(v) => v.isEmpty(et('empty_field'))}
                                  restriction="number"
                                  disabled={! isAddressValid} />
                              </FormGroup>
                            </Cell>
                          </Grid>
                          <Button
                            category="primary"
                            className={styles.button}
                            size="medium"
                            disabled={! isAddressValid}>
                            {tt('process_to_payment')}
                          </Button>
                        </PanelBody>
                      </Form>
                    </Panel>
                    {do{
                      if (transaction.currency.toLowerCase() === 'eur') {
                        <Panel className={styles.otherOptions}>
                          <PanelHeader>
                            <PanelTitle>{tt('pay_with_ogone')}</PanelTitle>
                          </PanelHeader>
                          <PanelBody>
                            <div className={styles.others}>
                              {tt('pay_with_a_wide_range')}
                            </div>
                            <div className={styles.othersButtons}>
                              <Button
                                variation="inversed"
                                onClick={() => payWithBancontact(transaction)}
                                disabled={! isAddressValid}>
                                {tt('pay_with_bancontact')}
                              </Button>
                              <Button
                                variation="inversed"
                                onClick={() => payWithIdeal(transaction)}
                                disabled={! isAddressValid}>
                                {tt('pay_with_ideal')}
                              </Button>
                            </div>
                          </PanelBody>
                        </Panel>
                      }
                    }}
                  </Cell>
                </Grid>
              </div>
            </Container>
          </RouteContainer>
        )} />
    );
  }

  _handlePayWithCreditCard(creditCard) {
    const { transaction, payWithCreditCard } = this.props;
    payWithCreditCard(transaction, creditCard);
  }

  _updateTransactionAddress(addressId) {
    const { updateTransactionAddress, transaction } = this.props;
    this.setState({
      currentAddress: addressId,
    });
    updateTransactionAddress(transaction, addressId);
  }

}


const mapStateToProps = (state, ownProps) => {
  if (Object.keys(ownProps.match.params).length <= 0) {
    return {};
  }
  return {
    transaction: getTransaction(state, ownProps.match.params.id),
    params: ownProps.match.params,
    push: ownProps.history.push,
  };
};


const mapDispatchToProps = {
  payWithBancontact,
  payWithCreditCard,
  payWithIdeal,
  loadTransaction,
  updateTransactionAddress,
  redirectTransactionAlreadyPaid,
};


export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
