import morphey, { fromKey } from 'morphey';

import { userFromServer } from './user';


const invoiceTranslations = {
  id: 'id',
  salesOrderFileName: 'sales_order_file_name',
  salesOrderPublicUrl: 'sales_order_public_url',
  salesOrderReference: 'sales_order_reference',
};


function invoiceFromServer(invoice) {
  return morphey(invoice, invoiceTranslations);
}


const translationsFromServer = (i) => ({
  id: 'id',
  transactionType: 'transaction_type',
  status: 'status',
  vat: 'vat',
  internalReference: 'internal_reference',
  amount: 'amount',
  currency: 'currency.symbol',
  createdAt: 'created_at',
  orderedAt: 'ordered_at',
  priceWithTaxes: 'price_with_taxes',
  invoice: fromKey('invoice').using(() => invoiceFromServer(i.invoice)),
  buyer: fromKey('buyer').using(() => userFromServer(i.buyer)),
});


export function salesOrderFromServer(salesOrder) {
  return morphey(salesOrder, translationsFromServer);
}


const translationsToServer = (i) => ({
  recipient_label: 'label',
  internal_reference: 'internalReference',
  street: 'street',
  city: 'city',
  zip_code: 'zipCode',
  country_code: 'countryCode',
  vat: 'vat',
});


export function salesOrderToServer(salesOrder) {
  return morphey(salesOrder, translationsToServer);
}


export default {
  fromServer: salesOrderFromServer,
  toServer: salesOrderToServer,
};
