import { startLoading, stopLoading } from 'loading-manager';


export const PAY_WITH_BANCONTACT = 'PAY_WITH_BANCONTACT';

export function payWithBancontact(transaction) {
  return {
    type: PAY_WITH_BANCONTACT,
    payload: { transaction },
    meta: { loading: startLoading('startPayment') },
  };
}


export const PAY_WITH_IDEAL = 'PAY_WITH_IDEAL';

export function payWithIdeal(transaction) {
  return {
    type: PAY_WITH_IDEAL,
    payload: { transaction },
    meta: { loading: startLoading('startPayment') },
  };
}


export const PAY_WITH_CREDIT_CARD = 'PAY_WITH_CREDIT_CARD';

export function payWithCreditCard(transaction, creditCard) {
  return {
    type: PAY_WITH_CREDIT_CARD,
    payload: { transaction, creditCard },
    meta: { loading: startLoading('startPayment'), ignoreLogrocket: true },
  };
}


export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';

export function paymentSuccess(transaction) {
  return {
    type: PAYMENT_SUCCESS,
    payload: { transaction },
    meta: { loading: stopLoading('startPayment') },
  };
}


export const PAYMENT_FAIL = 'PAYMENT_FAIL';

export function paymentFail(error, extra={}) {
  const { isCritical, ignoreLog } = extra;
  error.isCritical = isCritical !== undefined ? isCritical : true;
  return {
    type: PAYMENT_FAIL,
    meta: { error, ignoreLog, loading: stopLoading('startPayment') },
  };
}


export const PAYMENT_CANCEL = 'PAYMENT_CANCEL';

export function paymentCancel() {
  return {
    type: PAYMENT_CANCEL,
    meta: { loading: stopLoading('startPayment') },
  };
}


export const PAY_WITH_CREDITS = 'PAY_WITH_CREDITS';

export function payWithCredits(cart, estatesIds, remainingCredits) {
  return {
    type: PAY_WITH_CREDITS,
    payload: {
      cart,
      estatesIds,
      remainingCredits,
    },
  };
}


export const PAY_WITH_CREDITS_SUCCESS = 'PAY_WITH_CREDITS_SUCCESS';

export function payWithCreditsSuccess(transaction) {
  return {
    type: PAY_WITH_CREDITS_SUCCESS,
    payload: {
      transaction,
    },
  };
}


export const PAY_WITH_CREDITS_FAIL = 'PAY_WITH_CREDITS_FAIL';

export function payWithCreditsFail(error) {
  return {
    type: PAY_WITH_CREDITS_FAIL,
    meta: { error },
  };
}


export const PAY_WITH_WIRE = 'PAY_WITH_WIRE';

export function payWithWire(cart) {
  return {
    type: PAY_WITH_WIRE,
    payload: {
      cart,
    },
  };
}


export const PAY_WITH_WIRE_SUCCESS = 'PAY_WITH_WIRE_SUCCESS';

export function payWithWireSuccess(transaction) {
  return {
    type: PAY_WITH_WIRE_SUCCESS,
    payload: {
      transaction,
    },
  };
}


export const PAY_WITH_WIRE_FAIL = 'PAY_WITH_WIRE_FAIL';

export function payWithWireFail(error) {
  return {
    type: PAY_WITH_WIRE_FAIL,
    meta: { error },
  };
}


export const PAY_ONLINE = 'PAY_ONLINE';

export function payOnline(cart) {
  return {
    type: PAY_ONLINE,
    payload: {
      cart,
    },
  };
}


export const PAY_ONLINE_SUCCESS = 'PAY_ONLINE_SUCCESS';

export function payOnlineSuccess(transaction) {
  return {
    type: PAY_ONLINE_SUCCESS,
    payload: {
      transaction,
    },
  };
}


export const PAY_ONLINE_FAIL = 'PAY_ONLINE_FAIL';

export function payOnlineFail(error) {
  return {
    type: PAY_ONLINE_FAIL,
    meta: { error },
  };
}
