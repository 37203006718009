import get from 'lodash/get';


export function doLog(object, logOperation) {
  const { key } = logOperation;
  // eslint-disable-next-line
  console.log(get(object, key));
  return 0;
}


export function Log(key) {
  return {
    operation: 'log',
    log: { key },
  };
}
