import gql from 'fraql';
import { useQuery } from 'urql';

import { User } from '~/types';

import { Estate } from '../../types';
import { ErrorType, buildError } from '../../utils';

interface EstateQueryData {
  estate: Estate;
}

interface EstateQueryVariables {
  estateId: string;
}

const EstateQuery = gql`
  query EstateQuery($estateId: ID!) {
    estate(estateId: $estateId) {
      id
      organisation {
        id
        members {
          id
          fullName @computed(type: ClientUser)
        }
      }
    }
  }
`;

export function useFetchOrganizationUsers(
  estateId: string,
): {
  isLoading: boolean;
  users?: Array<User>;
  error?: ErrorType;
} {
  const [res] = useQuery<EstateQueryData, EstateQueryVariables>({
    query: EstateQuery,
    variables: { estateId },
  });

  return {
    isLoading: res.fetching,
    users: res.data?.estate.organisation.members,
    error: buildError(res.error),
  };
}
