import gql from 'fraql';

import { camelize } from '~/utils';


function _getServiceId(slug) {
  return camelize(slug);
}


const ProductService = {
  typeName: 'ProductService',
  fields: {
    id: {
      dependencies: gql`
        fragment _ on ProductService {
          slug
        }
      `,
      resolver: (service) => _getServiceId(service.slug),
    },
  },
};


export default ProductService;