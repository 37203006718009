import React from 'react';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';
import {createTranslate } from '~/utils/translation';

import AttachmentBox from '~/pods/order/components/AttachmentBox';


const t = createTranslate('pods.order.services');


const styles = {
  attachments: css`
    margin: ${sv.baseMarginSmall} 0;
    font-size: 0.9em;
  `,
  title: css`
    font-family: ${sv.baseFontFamilyAlt};
    text-transform: uppercase;
    color: ${sv.textPrimaryDark};
    font-weight: 600;
    font-size: 0.65rem;
  `,
  attachmentsColumn: css`
    margin-top: ${sv.baseMarginSmall};
  `,
  attachment: css`
    margin-bottom: ${sv.baseMarginSmall};
  `,
};


const Attachments = ({ attachments, title }) => {
  return (
    <div className={styles.attachments}>
      <div className={styles.title}>
        {t(title)}
      </div>
      <div className={styles.attachmentsColumn}>
        {attachments.map((attachment, i) => (
          <div className={styles.attachment} key={i}>
            <AttachmentBox file={attachment} />
          </div>
        ))}
      </div>
    </div>
  );
}


export default Attachments;
