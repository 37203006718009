import gql from 'graphql-tag';

export const TeammatesQuery = gql`
  query OrganizationQuery {
    organisation {
      id
      users {
        id
        firstName
        lastName
        email
        role
        createdAt
      }
      invitations {
        id
        firstName
        lastName
        email
        role
        status
      }
    }
  }
`;
