import React from 'react';
import { Grid, Cell, Offset } from 'react-ittsu/layout';
import autobind from 'autobind-decorator';
import gql from 'fraql';

import BaseServiceModal from '../BaseServiceModal';
import Paragraph from '../Paragraph';
import SectionTitle from '../SectionTitle';
import { ProductCard, Stripe, StripeGroup } from '../../forms';
import { priceItem, applyPrice } from '../../../utils/pricing';
import { specsToItems, itemsToSpecs } from '~/pods/order/utils/services/specs-to-items';
import applyChanges from '~/pods/order/utils/services/specs-to-items/apply-changes';
import { getItemsByService, merge } from '~/pods/order/utils';
import { EstateEnums } from '../../../utils/estate-enums';
import { createTranslate, translate as t } from '~/utils/translation';

import serviceImages from '~/pods/order/images/services';


const tt = createTranslate('pods.order.services.shoebox.order');
const tm = createTranslate('pods.order.components.service_modal');


class Shoebox extends React.Component {

  static fragments = {
    Item: gql`
      fragment _ on Item {
        id
        details {
          ... on ShoeboxDetails {
            type
          }
        }
      }
    `,
  }

  state = {
    specs: {
      apartment: 0,
      duplex: 0,
      house: 0,
      commercial: 0,
    },
    price: undefined,
  }

  componentDidUpdate(prevProps) {
    const { specs } = this.state;
    const { service, estate } = this.props;
    const { service: prevService } = prevProps;
    if (service?.id === 'shoebox' && ! prevService) {
      const newSpecs = itemsToSpecs({ estate, service });
      const items = getItemsByService(estate).shoebox;
      const price = items != null ? items.reduce((memo, i) => memo + priceItem(i, this.props.sessionUser.currency.code, true), 0) : 0;
      this.setState({ specs: merge(specs, newSpecs), price });
    }
  }

  render() {
    const { onClickClose, isVisible, service, estate, sessionUser } = this.props;
    const { specs: { apartment, duplex, house, commercial }, price } = this.state;
    const { shoebox } = serviceImages;
    const items = getItemsByService(estate).shoebox;
    return (
      <BaseServiceModal
        title={service?.id ? t(`services.${service.id}`) : ''}
        images={Object.values(shoebox)}
        price={sessionUser.addCurrency(price)}
        onClickConfirm={this._handleClickConfirm}
        isVisible={isVisible && service?.id === 'shoebox'}
        confirmAsUpdate={items?.length > 0}
        onClickClose={onClickClose}
        addDisabled={price === 0}>
        <Paragraph>
          {tt('description')}
        </Paragraph>
        <SectionTitle>
          {tt('select_number')}
        </SectionTitle>
        {do{
          if (estate.projectType === EstateEnums.ProjectType.RESIDENTIAL) {
            <Grid halfVGutters>
              <Cell size="1of1">
                <ProductCard
                  name="apartment"
                  horizontal
                  label={tt('apartment')}
                  note={tm('per_unit', { price: applyPrice({ EUR: 250, GBP: 250, USD: 300 }, sessionUser) })}
                  formComponent="checkbox"
                  value={apartment}
                  onChange={this._handleClickOption}
                  withCounter />
              </Cell>
              <Cell size="1of1">
                <ProductCard
                  name="duplex"
                  horizontal
                  label={tt('duplex')}
                  note={tm('per_unit', { price: applyPrice({ EUR: 350, GBP: 350, USD: 550 }, sessionUser) })}
                  formComponent="checkbox"
                  value={duplex}
                  onChange={this._handleClickOption}
                  withCounter />
              </Cell>
              <Cell size="1of1">
                <ProductCard
                  name="house"
                  horizontal
                  label={tt('house')}
                  note={tm('per_unit', { price: applyPrice({ EUR: 450, GBP: 450, USD: 600 }, sessionUser) })}
                  formComponent="checkbox"
                  value={house}
                  onChange={this._handleClickOption}
                  withCounter />
              </Cell>
            </Grid>
          }
          else {
            <Grid halfVGutters>
              <Offset size="1of4" />
              <Cell size="2of4">
                <ProductCard
                  name="commercial"
                  label={tt('commercial')}
                  note={`${applyPrice({ EUR: 0.5, GBP: 0.5, USD: 0.75 }, sessionUser)}/m2 (min. ${applyPrice({ EUR: 350, GBP: 350, USD: 550 }, sessionUser)})`}
                  formComponent="checkbox"
                  value={commercial}
                  onChange={this._handleClickOption}
                  withCounter />
              </Cell>
              <Offset size="1of4" />
            </Grid>
          }
        }}
        <SectionTitle>
          {tt('included_in_service')}
        </SectionTitle>
        <StripeGroup>
          <Stripe
            features={[
              {
                text: tt('features.first'),
              },
              {
                text: tt('features.third.text'),
                infoText: tt('features.third.tooltip'),
              },
            ]} />
        </StripeGroup>
      </BaseServiceModal>
    );
  }

  @autobind
  _syncState() {
    const { estate, service } = this.props;
    const { specs } = this.state;
    const changes = specsToItems({ specs, estate, service });
    const updatedEstate = applyChanges(changes, estate);
    const items = getItemsByService(updatedEstate).shoebox;
    const price = items != null
      ? items
          .map((item) => item.estate != null ? item : { ...item, estate: updatedEstate })
          .reduce((memo, i) => memo + priceItem(i, this.props.sessionUser.currency.code, true), 0)
      : 0;
    this.setState({ price });
    return { changes, updatedEstate, price };
  }

  @autobind
  _handleClickOption(value, key) {
    const { specs } = this.state;
    this.setState({
      specs: {
        ...specs,
        [key]: value ? value : (specs[key] !== 0 ? 0 : 1),
      },
    }, this._syncState);
  }

  @autobind
  _handleClickConfirm() {
    const { onModifyService, service } = this.props;
    const { changes } = this._syncState();
    onModifyService(changes, service);
  }
}


export default Shoebox;
