import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Card, CardContent, CardFooter, Button } from 'react-ittsu/components';
import gql from 'fraql';
import { css, cx } from 'emotion';
import sv, { fade } from '@drawbotics/style-vars';
import get from 'lodash/get';

import Tooltip from '~/components/Tooltip';
import { createTranslate, translate as t } from '~/utils/translation';

import serviceImages from '../images/services';


const tt = createTranslate('pods.order.components.service_card');


const styles = {
  cardWrapper: css`
    position: relative;

    &:hover {
      [data-element="tooltip"] {
        opacity: 1;
        transform: translate(-50%, 10px);
      }
    }
  `,
  serviceCard: css`
    display: flex;
    flex-direction: column;
    height: 100%;
    box-shadow: none;
    background: ${sv.grey50};
    transition: all ${sv.baseTransitionTimeShort} ease-in-out;
  `,
  disabled: css`
    opacity: 0.4;

    [data-element="add"] {
      pointer-events: none;
    }
  `,
  enabled: css`
    &:hover {
      background: ${sv.white};
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
      transform: translateY(-1px);

      [data-element="add"] {
        opacity: 1;
        transform: translate(-50%, -50%);
      }
    }
  `,
  add: css`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -30%);
    opacity: 0;
    transition: all ${sv.baseTransitionTimeShort} ease-in-out;
    box-shadow: ${sv.elevation2} !important;
    white-space: nowrap;
  `,
  content: css`
    flex: 1;
  `,
  image: css`
    position: relative;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 150px;

    @media ${sv.phoneXl} {
      height: 100px;
    }
  `,
  footer: css`
    padding-top: ${sv.basePaddingSmall};
    text-align: center;
    white-space: nowrap;
    color: ${sv.textSecondaryDark};
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  checkedBackground: css`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: ${fade(sv.brandGreen, 40)};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  check: css`
    height: 40px;
    width: 40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${sv.white};
    background: ${sv.brandGreen};
    box-shadow: ${sv.elevation2};
  `,
};


const ServiceCard = ({
  service,
  isChecked,
  onClick,
  disabled,
}) => {
  const coverImage = get(serviceImages, `thumbs[${service.id}]`);
  return (
    <div className={styles.cardWrapper}>
      <Card
        clickable={! disabled}
        className={cx(styles.serviceCard, {
          [styles.disabled]: disabled,
          [styles.enabled]: ! disabled,
        })}
        onClick={disabled ? null: () => onClick(service)}>
        <CardContent className={styles.content}>
          <div className={styles.image} data-element="image" style={{ backgroundImage: `url(${coverImage})` }}>
            {do {
              if ( ! isChecked) {
                <Button className={styles.add} data-element="add" round category="primary" size="small">
                  {tt('add_service')}
                </Button>
              }
              else {
                <div className={styles.checkedBackground}>
                  <div className={styles.check} data-element="check">
                    <Icon name="check-bold" />
                  </div>
                </div>
              }
            }}
          </div>
        </CardContent>
        <CardFooter>
          <div className={styles.footer} data-element="footer">
            {t(`services.${service.id}`)}
          </div>
        </CardFooter>
      </Card>
      {do{
        if (disabled) {
          <Tooltip>{tt('service_unavailable')}</Tooltip>
        }
      }}
    </div>
  );
};

ServiceCard.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

ServiceCard.fragments = {
  ServiceCard: gql`
    fragment _ on ProductService {
      id @client(type: ProductService)
    }
  `,
};


export default ServiceCard;
