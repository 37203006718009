import isPlainObject from 'lodash/isPlainObject';

import LogRocket from '../logrocket';
import Sentry from '../sentry';


function prepare(err) {
  if (err instanceof Error) {
    return err;
  }
  if (isPlainObject(err)) {
    try {
      // Try to stringify, and in case it fails (for instance, due to circular dependencies)
      return new Error(JSON.stringify(err));
    }
    catch (err) {
      // try to get all the properties at the root at least
      const swallowProperties = Object.keys(err).reduce((memo, key) => ({
        ...memo,
        [key]: err[key],
      }), {});
      return new Error(JSON.stringify(swallowProperties));
    }
  }
  return new Error(err);
}


export default function reportException(err, extra) {
  LogRocket.captureException(prepare(err), extra);
  Sentry.captureException(prepare(err), extra);
  return console.error(prepare(err), extra);
}
