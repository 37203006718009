const k = (key) => `exterior_restyling.briefing.${key}`;

const residential = {
  1: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('photos_to_restyle'),
        component: 'Title',
      },
      {
        type: 'question-component',
        label: k('upload_photos'),
        component: 'FileUploader',
        required: 'all',
        key: 'photosToRestyle',
        keyLabel: k('keys.photos_to_restyle'),
      },
    ],
  },
  2: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('adaptations'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('doors_note'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'SingleSelect',
        required: 'one',
        key: 'adaptDoors',
        keyLabel: k('keys.adapt_doors'),
        values: [
          {
            value: true,
            label: k('with_doors'),
            meta: {
              type: 'meta',
              required: 'all',
              noToggle: true,
              components: [
                {
                  type: 'component',
                  label: k('doors_info'),
                  component: 'Note',
                }, {
                  type: 'question-component',
                  label: 'shared.example_description',
                  component: 'Input',
                  key: 'doorsInfo',
                  keyLabel: k('keys.doors_info'),
                }, {
                  type: 'question-component',
                  label: 'shared.upload_example',
                  component: 'FileUploader',
                  key: 'doorsAttachments',
                  keyLabel: k('keys.doors_attachments'),
                },
              ],
            },
          },
          {
            value: false,
            label: k('without_doors'),
          },
        ],
      },
      {
        type: 'component',
        label: k('windows_note'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'SingleSelect',
        required: 'one',
        key: 'adaptWindows',
        keyLabel: k('keys.adapt_windows'),
        values: [
          {
            value: true,
            label: k('with_windows'),
            meta: {
              type: 'meta',
              required: 'all',
              noToggle: true,
              components: [
                {
                  type: 'component',
                  label: k('windows_info'),
                  component: 'Note',
                }, {
                  type: 'question-component',
                  label: 'shared.example_description',
                  component: 'Input',
                  key: 'windowsInfo',
                  keyLabel: k('keys.windows_info'),
                }, {
                  type: 'question-component',
                  label: 'shared.upload_example',
                  component: 'FileUploader',
                  key: 'windowsAttachments',
                  keyLabel: k('keys.windows_attachments'),
                },
              ],
            },
          },
          {
            value: false,
            label: k('without_windows'),
          },
        ],
      },
      {
        type: 'component',
        label: k('facade_note'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'SingleSelect',
        required: 'all',
        key: 'adaptFacade',
        keyLabel: k('keys.adapt_facade'),
        values: [
          {
            value: true,
            label: k('with_facade'),
            meta: {
              type: 'meta',
              required: 'one',
              noToggle: true,
              components: [
                {
                  type: 'component',
                  label: k('facade_info'),
                  component: 'Note',
                }, {
                  type: 'question-component',
                  label: 'shared.example_description',
                  component: 'Input',
                  key: 'facadeInfo',
                  keyLabel: k('keys.facade_info'),
                }, {
                  type: 'question-component',
                  label: 'shared.upload_example',
                  component: 'FileUploader',
                  key: 'facadeAttachments',
                  keyLabel: k('keys.facade_attachments'),
                },
              ],
            },
          },
          {
            value: false,
            label: k('without_facade'),
          },
        ],
      },
      {
        type: 'component',
        label: k('roofing_note'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'SingleSelect',
        required: 'all',
        key: 'adaptRoofing',
        keyLabel: k('keys.adapt_roofing'),
        values: [
          {
            value: true,
            label: k('with_roofing'),
            meta: {
              type: 'meta',
              required: 'one',
              noToggle: true,
              components: [
                {
                  type: 'component',
                  label: k('roofing_info'),
                  component: 'Note',
                }, {
                  type: 'question-component',
                  label: 'shared.example_description',
                  component: 'Input',
                  key: 'roofingInfo',
                  keyLabel: k('keys.roofing_info'),
                }, {
                  type: 'question-component',
                  label: 'shared.upload_example',
                  component: 'FileUploader',
                  key: 'roofingAttachments',
                  keyLabel: k('keys.roofing_attachments'),
                },
              ],
            },
          },
          {
            value: false,
            label: k('without_roofing'),
          },
        ],
      },
    ],
  },
  3: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('attribution'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('logo'),
        component: 'Note',
      },
      {
        type: 'question-component',
        label: k('upload_logo'),
        component: 'FileUploader',
        key: 'logoAttachments',
        keyLabel: k('keys.logo_attachments'),
      },
      {
        type: 'component',
        label: k('footnote'),
        component: 'Title',
      },
      {
        type: 'question-component',
        label: k('footnote_input'),
        component: 'Input',
        key: 'footnote',
        keyLabel: k('keys.footnote'),
      },
    ],
  },
  4: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'Additional information',
        component: 'Title',
      }, {
        type: 'question-component',
        label: k('additional_info'),
        component: 'Input',
        key: 'otherInfo',
        keyLabel: 'shared.keys.other_info',
      }, {
        type: 'question-component',
        label: 'shared.upload_files',
        component: 'FileUploader',
        key: 'otherInfoAttachments',
        keyLabel: 'shared.keys.other_info_attachments',
      },
    ],
  },
};

export default { residential };
