import React from 'react';
import { connect } from 'react-redux';
import { Panel, T2, PanelBody, Button } from 'react-ittsu/components';
import { Checkbox, CountBox } from 'react-ittsu/forms';
import { Container } from 'react-ittsu/layout';
import get from 'lodash/get';

import Link from 'components/Link';
import { createTranslate } from 'utils/translation';
import { buyCredits } from 'pods/transactions/actions';
import CreditSlider from 'pods/transactions/components/CreditSlider';
import { getSessionUser } from 'selectors';

import styles from 'pods/transactions/styles/routes/buy-credits';


const tt = createTranslate('pods.transactions.routes.buy_credits');

const sliderData = {
  creditValue: 1,
  maxCredit: 4000,
  minCredit: 25,
  step: 25,
  bonusRanges: [{
    start: 0,
    end: 475,
    bonus: 0,
  }, {
    start: 500,
    end: 1475,
    bonus: 25,
  }, {
    start: 1500,
    end: 2975,
    bonus: 150,
  }, {
    start: 3000,
    end: 4000,
    bonus: 500,
  }],
};


function getTranslatedLink(link, locale) {
  switch (link) {
    case 'terms-and-conditions': {
      if (locale === 'en') {
        return 'https://www.drawbotics.com/en/terms-and-conditions';
      }
      else if (locale === 'fr') {
        return 'https://www.drawbotics.com/fr/termes-et-conditions';
      }
      else if (locale === 'nl') {
        return 'https://www.drawbotics.com/nl/algeme-voorwaarden';
      }
    }
  }
}


function getCurrentRange(ranges, value) {
  return ranges.find(range => range.start <= value && range.end >= value);
}


function calculateBonus(ranges, value) {
  return get(getCurrentRange(ranges, value), 'bonus', 0);
}


class BuyCredits extends React.Component {

  state = {
    credits: sliderData.minCredit,
    min: sliderData.minCredit,
    max: sliderData.maxCredit,
    step: sliderData.step,
    termsAccepted: false,
  };

  constructor(props) {
    super(props);
    this._updateCredits = this._updateCredits.bind(this);
    this._toggleAcceptConditions = this._toggleAcceptConditions.bind(this);
    this._goToCheckout = this._goToCheckout.bind(this);
  }

  render() {
    const { credits, min, max, step, termsAccepted } = this.state;
    const { sessionUser } = this.props;
    const locale = get(sessionUser, 'locale', 'en');
    const currency = get(sessionUser, 'currency.symbol');
    const noAddress = sessionUser.organization.addresses.length <= 0;
    return (
      <Container useBottomPadding>
        <div className={styles.buyCredits}>
          <T2 brand className={styles.title}>
            {tt('pricing_offer_that_matches_every_budget')}
          </T2>
          <div className={styles.sliderContainer}>
            <CreditSlider
              data={sliderData}
              credits={credits}
              onChange={this._updateCredits}
              getCurrentRange={getCurrentRange} />
          </div>
          <div className={styles.creditContainer}>
            <Panel className={styles.selectCredits}>
              <PanelBody>
                <div className={styles.countContainer}>
                  <div className={styles.label}>{tt('selected_credits')}</div>
                  <CountBox value={credits} min={min} max={max} step={step} onChange={this._updateCredits} />
                  <div className={styles.bonus}>
                    {`+${calculateBonus(sliderData.bonusRanges, credits)} ${tt('bonus')}`}
                  </div>
                </div>
              </PanelBody>
            </Panel>
            <div className={styles.totalCredits}>
              <Panel>
                <PanelBody>
                  <div className={styles.borderedRow}>
                    <span className={styles.total}>{tt('total_credits')}</span>
                    <span className={styles.subtitle}>{tt('with_bonus')}</span>
                    <div className={styles.credits}>
                      {calculateBonus(sliderData.bonusRanges, credits) + credits}
                    </div>
                  </div>
                  <div className={styles.row}>
                    <span className={styles.total}>{tt('total_to_pay')}</span>
                    <span className={styles.subtitle}>{tt('excl_vat')}</span>
                    <div className={styles.credits}>{`${currency}${credits}`}</div>
                  </div>
                  <div className={styles.row}>
                    <Checkbox labelPosition="right" onChange={this._toggleAcceptConditions} checked={termsAccepted}>
                      <a
                        href={getTranslatedLink('terms-and-conditions', locale)}
                        style={{ textDecoration: 'underline'}}
                        rel="noopener noreferrer"
                        target="_blank">
                        {tt('accept_terms')}
                      </a>
                    </Checkbox>
                  </div>
                </PanelBody>
              </Panel>
              <Button
                category="primary"
                disabled={ ! this.state.termsAccepted || noAddress}
                className={styles.button}
                size="medium"
                onClick={this._goToCheckout}>
                {tt('goto_payment')}
              </Button>
              {do {
                if (noAddress) {
                  <div className={styles.noAddress}>
                    <Link underlined="always" href="/addresses">
                      {tt('add_address')}
                    </Link>
                  </div>
                }
              }}
            </div>
          </div>
        </div>
      </Container>
    );
  }

  _updateCredits(value) {
    this.setState({ credits: value });
  }

  _toggleAcceptConditions() {
    this.setState({ termsAccepted: ! this.state.termsAccepted });
  }

  _goToCheckout() {
    const { buyCredits } = this.props;
    const { credits } = this.state;
    const bonus = calculateBonus(sliderData.bonusRanges, credits);
    buyCredits(credits, bonus);
  }

}


const mapStateToProps = (state, ownProps) => ({
  sessionUser: getSessionUser(state),
});


const mapDispatchToProps = {
  buyCredits,
};


export default connect(mapStateToProps, mapDispatchToProps)(BuyCredits);
