import React from 'react';
import { Icon } from 'react-ittsu/components';

import FileUploader from 'components/FileUploader';

import styles from 'pods/studio/styles/components/annotation';

const ActionIcons = ({
  annotation,
  onUploadProgress,
  onUploadFinish,
}) => (
  <div className={styles.actionIcons}>
    <nav>
      <div style={{ 'position': 'absolute' }}>
        <Icon name="paperclip" size="lg" bold />
      </div>
      <FileUploader
        onProgress={onUploadProgress}
        onFinish={onUploadFinish} />
    </nav>
  </div>
);

export default ActionIcons;
