import { createEntities, updateEntities, deleteEntities } from 'entman';
import { startLoading, stopLoading } from 'loading-manager';

import schemas from 'schemas';


export const LOAD_PROJECT_ITEM = 'LOAD_PROJECT_ITEM';

export function loadProjectItem(id, update) {
  return {
    type: LOAD_PROJECT_ITEM,
    payload: { id },
    meta: { loading: update ? null : startLoading('project-item') },
  };
}


export const LOAD_PROJECT_ITEM_SUCCESS = 'LOAD_PROJECT_ITEM_SUCCESS';

export function loadProjectItemSuccess(item) {
  return createEntities(schemas.ProjectItem, 'payload.item', {
    type: LOAD_PROJECT_ITEM_SUCCESS,
    payload: { item },
    meta: { loading: stopLoading('project-item') },
  });
}


export const LOAD_PROJECT_ITEM_FAIL = 'LOAD_PROJECT_ITEM_FAIL';

export function loadProjectItemFail(error) {
  return {
    type: LOAD_PROJECT_ITEM_FAIL,
    meta: { error, loading: stopLoading('project-item') },
  };
}


export const UPDATE_PROJECT_ITEM = 'UPDATE_PROJECT_ITEM';

export function updateProjectItem(id, item) {
  return updateEntities(schemas.ProjectItem, id, 'payload.item', {
    type: UPDATE_PROJECT_ITEM,
    payload: {
      item,
    },
  });
}


export const ARCHIVE_PROJECT_ITEM = 'ARCHIVE_PROJECT_ITEM';

export function archiveProjectItem(id) {
  return updateEntities(schemas.ProjectItem, id, 'payload.item', {
    type: ARCHIVE_PROJECT_ITEM,
    payload: {
      item: {
        id,
        status: 'archived',
      },
    },
  });
}


export const REOPEN_PROJECT_ITEM = 'REOPEN_PROJECT_ITEM';

export function reopenProjectItem(item, user) {
  return {
    type: REOPEN_PROJECT_ITEM,
    payload: {
      item,
      user,
    },
    meta: { loading: startLoading('reopenProjectItem') },
  };
}


export const REOPEN_PROJECT_ITEM_SUCCESS = 'REOPEN_PROJECT_ITEM_SUCCESS';

export function reopenProjectItemSuccess(item) {
  return updateEntities(schemas.ProjectItem, item.id, 'payload.item', {
    type: REOPEN_PROJECT_ITEM_SUCCESS,
    payload: {
      item,
    },
    meta: { loading: stopLoading('reopenProjectItem') },
  });
}


export const REOPEN_PROJECT_ITEM_FAIL = 'REOPEN_PROJECT_ITEM_FAIL';

export function reopenProjectItemFail(error) {
  return {
    type: REOPEN_PROJECT_ITEM_FAIL,
    meta: { error, loading: stopLoading('reopenProjectItem') },
  };
}


export const GENERATE_LINK = 'GENERATE_LINK';

export function generateLink(id) {
  return {
    type: GENERATE_LINK,
    payload: {
      id,
    },
    meta: { loading: startLoading('generateLink') },
  };
}


export const GENERATE_LINK_SUCCESS = 'GENERATE_LINK_SUCCESS';

export function generateLinkSuccess(id, publicToken) {
  return updateEntities(schemas.ProjectItem, id, 'payload.item', {
    type: GENERATE_LINK_SUCCESS,
    payload: {
      item: {
        id,
        publicToken,
      },
    },
    meta: { loading: stopLoading('generateLink') },
  });
}


export const GENERATE_LINK_FAIL = 'GENERATE_LINK_FAIL';

export function generateLinkFail(error) {
  return {
    type: GENERATE_LINK_FAIL,
    meta: { error, loading: stopLoading('generateLink') },
  };
}


export const REVOKE_LINK = 'REVOKE_LINK';

export function revokeLink(item) {
  return updateEntities(schemas.ProjectItem, item.id, 'payload.item', {
    type: REVOKE_LINK,
    payload: {
      item: {
        id: item.id,
        publicToken: null,
      },
      followers: item.followers,
    },
  });
}


export const REVOKE_LINK_SUCCESS = 'REVOKE_LINK_SUCCESS';

export function revokeLinkSuccess() {
  return {
    type: REVOKE_LINK_SUCCESS,
  };
}


export const REVOKE_LINK_FAIL = 'REVOKE_LINK_FAIL';

export function revokeLinkFail(error) {
  return {
    type: REVOKE_LINK_FAIL,
    meta: { error },
  };
}


export const RESOLVE_PROBLEM = 'RESOLVE_PROBLEM';

export function resolveProblem(id, problem, user) {
  return {
    type: RESOLVE_PROBLEM,
    payload: {
      id,
      problem,
      user,
    },
  };
}


export const RESOLVE_PROBLEM_SUCCESS = 'RESOLVE_PROBLEM_SUCCESS';

export function resolveProblemSuccess(id) {
  return deleteEntities(schemas.Problem, id, {
    type: RESOLVE_PROBLEM_SUCCESS,
    payload: {
      id,
    },
  });
}


export const RESOLVE_PROBLEM_FAIL = 'RESOLVE_PROBLEM_FAIL';

export function resolveProblemFail(error) {
  return {
    type: RESOLVE_PROBLEM_FAIL,
    meta: { error },
  };
}


export const CANCEL_PROJECT_ITEM = 'CANCEL_PROJECT_ITEM';

export function cancelProjectItem(item, user) {
  return {
    type: CANCEL_PROJECT_ITEM,
    payload: {
      item,
      user,
    },
  };
}


export const CANCEL_PROJECT_ITEM_SUCCESS = 'CANCEL_PROJECT_ITEM_SUCCESS';

export function cancelProjectItemSuccess(id) {
  return deleteEntities(schemas.ProjectItem, id, {
    type: CANCEL_PROJECT_ITEM_SUCCESS,
    payload: {
      id,
    },
  });
}


export const CANCEL_PROJECT_ITEM_FAIL = 'CANCEL_PROJECT_ITEM_FAIL';

export function cancelProjectItemFail(error) {
  return {
    type: CANCEL_PROJECT_ITEM_FAIL,
    meta: { error },
  };
}
