import { Route, Switch } from '@drawbotics/route';
import sv from '@drawbotics/style-vars';
import { css } from 'emotion';
import gql from 'fraql';
import produce from 'immer';
import compose from 'lodash/flowRight';
import React from 'react';
import { graphql } from 'react-apollo';
import { Header } from 'react-ittsu/layout';
import { connect } from 'react-redux';

import { Navbar } from '~/components/Navbar';
import Spinner from '~/components/Spinner';
import SidebarLayout from '~/pods/order/components/SidebarLayout';
import { readSession } from '~/utils';
import { translate as t } from '~/utils/translation';

import { ApolloMutation, ID, JsonMeta } from '../types';
// @ts-ignore
import TransactionConfirmation from './TransactionConfirmation';
import TransactionSettle from './TransactionSettle';

export const TransactionQuery = gql`
  query Transaction($transactionToken: String!) {
    transaction(transactionAccessToken: $transactionToken) {
      id
      paymentProcess {
        estate: orderEstate {
          id
          jsonMeta @client(type: Estate)
        }
      }
      ${TransactionSettle.fragments.Transaction}
      ${TransactionConfirmation.fragments.Transaction}
    }
  }
`;

export const PublicTransactionQuery = gql`
  query PublicTransaction($transactionToken: String!) {
    transaction(transactionAccessToken: $transactionToken) {
      ${TransactionSettle.fragments.PublicTransaction}
      ${TransactionConfirmation.fragments.PublicTransaction}
    }
  }
`;

export const UpdateMetaMutation = gql`
  mutation UpdateMeta($id: ID!, $metadata: String) {
    payload: updateOrderEstate(input: { orderEstateId: $id, metadata: $metadata }) {
      updatedEstate: orderEstate {
        id
        metadata
        jsonMeta @client(type: Estate)
      }
    }
  }
`;

const styles = {
  transaction: css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  `,
  container: css`
    max-width: 800px;
    margin: auto;
    padding: ${sv.basePadding} 0;
    margin-bottom: ${sv.baseMargin};

    @media ${sv.ipad} {
      padding-top: 0;
    }
  `,
  wrapper: css`
    width: 100%;
    height: 100%;
    overflow: scroll;
  `,
};

export interface Transaction {
  id: ID;
  clientEmail: string;
  paymentProcess?: {
    estate: {
      id: ID;
      jsonMeta: JsonMeta;
      name: string;
      propertyType: string;
      modelingPrice: number;
      basePrice: {
        value: number;
      };
      discountedPrice?: {
        value: number;
      };
      discount?: {
        rate: number;
        amount: number;
        discountType: unknown;
      };
    };
    salesOrder?: {
      clientReference?: string;
      downPaymentPercentage?: number;
    };
    address?: {
      city: string;
      countryCode: string;
      id: ID;
      label: string;
      street: string;
      vat: string;
      zipCode: string;
    };
  };
  transactionToken: string;
  amount: number;
  priceWithTaxes: number;
  currency: string; // EUR | USD | GBP
  createdAt?: string;
  invoice?: {
    reference: string;
    url: string;
  };
  salesOrder?: {
    clientReference?: string;
    downPaymentPercentage?: number;
  };
  address?: {
    city: string;
    countryCode: string;
    id: ID;
    label: string;
    street: string;
    vat: string;
    zipCode: string;
  };
  organizationName: string;
}

interface TransactionData {
  transaction: Transaction;
  loading: boolean;
}

interface TransactionProps {
  basePath: string;
  updateMeta: ApolloMutation<
    {
      id: ID;
      metadata: string;
    },
    {
      payload: {
        updatedEstate: {
          id: ID;
          metadata: string;
          jsonMeta: JsonMeta;
        };
      };
    }
  >;
  transactionData: TransactionData;
}

const Transaction = ({ updateMeta, transactionData }: TransactionProps) => {
  const { transaction, loading: loadingTransaction } = transactionData;

  if (loadingTransaction) {
    return <Spinner size={50} containerStyle={{ margin: 'auto' }} text={t('pods.order.loading')} />;
  }

  const { estate } = transaction.paymentProcess ?? {};

  const _setAsVisited = async (stepName: string) => {
    if (estate == null) return;

    const newMetadata = produce(estate.jsonMeta, (metadata: any) => {
      metadata.steps[stepName] = metadata.steps[stepName] || {};
      metadata.steps[stepName].visited = true;
    });

    await updateMeta({
      variables: {
        id: estate.id,
        metadata: JSON.stringify(newMetadata),
      },
      optimisticResponse: {
        __typename: 'Mutation',
        payload: {
          __typename: 'UpdateOrderEstatePayload',
          updatedEstate: {
            id: estate.id,
            metadata: JSON.stringify(newMetadata),
            jsonMeta: newMetadata,
            __typename: 'Estate',
          },
        },
      },
    });
  };

  const isUserLoggedIn = readSession() != null;
  const setAsVisited = isUserLoggedIn ? _setAsVisited : () => {};
  return (
    <div className={css(styles.transaction)}>
      <Header>
        <Navbar />
      </Header>
      <SidebarLayout
        content={() => (
          <div className={styles.wrapper}>
            <div className={css(styles.container)}>
              <Switch>
                <Route
                  path="/order/transaction/:transactionToken/settle"
                  component={TransactionSettle}
                  withProps={{ transaction, setAsVisited }}
                />
                <Route
                  path="/order/transaction/:transactionToken/confirmation"
                  component={TransactionConfirmation}
                  withProps={{ transaction, setAsVisited }}
                />
              </Switch>
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default compose(
  connect(),
  graphql(TransactionQuery, {
    name: 'transactionData',
    options: (ownProps: any) => ({
      variables: { transactionToken: ownProps.match.params.transactionToken },
    }),
    skip: readSession() == null,
  }),
  graphql(PublicTransactionQuery, {
    name: 'transactionData',
    options: (ownProps: any) => ({
      variables: { transactionToken: ownProps.match.params.transactionToken },
    }),
    skip: readSession() != null,
  }),
  graphql(UpdateMetaMutation, {
    name: 'updateMeta',
    skip: readSession() == null,
  }),
)(Transaction);
