import Cookies from 'js-cookie';
const HELP_SEEN_COOKIE = 'drawbotics-help-seen';

export function getRootDomain(): string {
  if (location.hostname.includes('localhost')) {
    const [, domain] = location.hostname.match(/(localhost:\d+)/) ?? [];
    return domain as string;
  } else if (location.hostname.includes('netlify')) {
    return location.hostname;
  } else {
    return `.${location.hostname
      .split('.')
      .slice(-2)
      .join('.')}`;
  }
}

export function setHelpSeen() {
  Cookies.set(HELP_SEEN_COOKIE, 'true', { domain: getRootDomain(), expires: 10000 });
}

export function getHelpSeen() {
  return Cookies.get(HELP_SEEN_COOKIE) != null;
}
