import React from 'react';
import { Grid, Cell } from 'react-ittsu/layout';
import autobind from 'autobind-decorator';
import get from 'lodash/get';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';

import AnswerCell from '../shared/AnswerCell';


const styles = {
  grid: css`
    justify-content: center;

    @media ${sv.phone} {
      margin-top: ${sv.baseMarginSmall};
      margin-bottom: ${sv.baseMargin};
    }
  `,
  disabledCell: css`
    pointer-events: none;
    opacity: 0.4;
  `,
};


class MultiSelect extends React.Component {
  render() {
    const { values, answers, resultKey, modifyAnswer, metaComponent, max=false } = this.props;
    const sectionAnswers = get(answers, resultKey, []) || [];
    const disabledOthers = max && sectionAnswers.length === (typeof max === 'function' ? max(answers) : max);
    return (
      <div>
        <Grid halfHGutters halfVGutters className={styles.grid}>
          {values.map((answer, i) => (
            <Cell
              size="1of3"
              responsive="m1of2"
              key={i}
              className={! sectionAnswers.includes(answer.value) && disabledOthers ? styles.disabledCell : null}>
              <AnswerCell
                answer={answer}
                selected={sectionAnswers.includes(answer.value)}
                onClick={() => this._onClickCell(answer.value)}
                position={i}>
                <img src={answer.image} />
              </AnswerCell>
            </Cell>
          ))}
        </Grid>
        {do{
          if (metaComponent) {
            <div>
              <metaComponent.type {...metaComponent.props} answers={answers} modifyAnswer={modifyAnswer} />
            </div>
          }
        }}
      </div>
    );
  }

  @autobind
  _onClickCell(value) {
    const { modifyAnswer, resultKey, answers, values } = this.props;
    const sectionAnswers = get(answers, resultKey, []) || [];
    const remove = sectionAnswers.includes(value);
    if (remove) {
      const inactiveMetaKeys = values.reduce((inactiveKeys, currentValue) =>
        currentValue.value === value ? [ ...inactiveKeys, ...(currentValue.meta?.components.map((c) => c.key) || []) ] : inactiveKeys, []);
      modifyAnswer(inactiveMetaKeys, null, true);
      if (sectionAnswers.length === 1) {
        modifyAnswer(resultKey, null, true);
      }
      else {
        modifyAnswer(resultKey, sectionAnswers.filter((a) => a !== value));
      }
    }
    else {
      modifyAnswer(resultKey, [ ...sectionAnswers, value ]);
    }
  }
}


export default MultiSelect;
