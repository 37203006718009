import { residential as residentialGuide, commercial as commercialGuide } from '../utils/interior-guide';

const k = (key) => `tour_3d.briefing.${key}`;

const residential = {
  1: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('unit_number'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('unit_number_note'),
        component: 'Note',
      },
      {
        type: 'question-component',
        label: k('unit_number'),
        component: 'Input',
        required: 'all',
        key: 'unit',
        keyLabel: k('keys.unit'),
      },
    ],
  },
  2: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('3d_tour_style'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('3d_tour_style_note'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'SingleSelect',
        key: 'style',
        keyLabel: k('keys.style'),
        required: 'all',
        values: residentialGuide,
      },
      {
        type: 'meta',
        label: 'shared.show_my_own',
        components: [
          {
            type: 'question-component',
            label: 'shared.example_description',
            component: 'Input',
            key: 'otherStyle',
            keyLabel: k('keys.style_attachments'),
          }, {
            type: 'question-component',
            label: 'shared.upload_example',
            component: 'FileUploader',
            key: 'otherStyleAttachments',
            keyLabel: k('keys.style'),
          },
        ],
      },
    ],
  },
  3: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'shared.additional_info',
        component: 'Title',
      }, {
        type: 'component',
        label: 'shared.anything_to_add',
        component: 'Note',
      }, {
        type: 'question-component',
        label: 'shared.additional_comments',
        component: 'Input',
        key: 'otherInfo',
        keyLabel: 'shared.keys.other_info',
      }, {
        type: 'question-component',
        label: 'shared.upload_files',
        component: 'FileUploader',
        key: 'otherInfoAttachments',
        keyLabel: 'shared.keys.other_info_attachments',
      },
    ],
  },
}


const commercial = {
  ...residential,
  2: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('3d_tour_style'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('3d_tour_style_note'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'SingleSelect',
        key: 'style',
        keyLabel: k('keys.style'),
        required: 'all',
        values: commercialGuide,
      },
      {
        type: 'meta',
        label: 'shared.show_my_own',
        components: [
          {
            type: 'question-component',
            label: 'shared.example_description',
            component: 'Input',
            key: 'otherStyle',
            keyLabel: 'shared.other_info',
          }, {
            type: 'question-component',
            label: 'shared.upload_example',
            component: 'FileUploader',
            key: 'otherStyleAttachments',
            keyLabel: 'shared.other_info_attachments',
          },
        ],
      },
    ],
  },
};


export default { residential, commercial };
