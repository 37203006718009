export default (entities) => ({
  createAddress(address) {
    return entities.Address.create(address);
  },
  deleteAddress(address) {
    return entities.Address.delete(address);
  },
  updateAddress(address) {
    return entities.Address.update(address);
  },
});
