import sv from '@drawbotics/style-vars';
import { css } from 'emotion';


export default {
  recap: css`
    max-width: 500px;
    margin: calc(${sv.baseMargin} * 2) auto;
    margin-top: ${sv.baseMargin};
    padding: ${sv.basePadding};
    padding-right: calc(${sv.basePadding} * 2);

    @media ${sv.ipad} {
      max-width: none;
    }

    @media ${sv.phoneXl} {
      margin-top: 0;
      padding: ${sv.basePaddingSmall};
      background: ${sv.grey100};
    }
  `,
  recapTitle: css`
    font-size: 1.5rem;
    font-weight: bold;
    color: ${sv.brandPrimary};
    margin-bottom: ${sv.baseMarginSmall};

    @media ${sv.phoneXl} {
      font-size: 1.2rem;
    }
  `,
  steps: css`
    color: ${sv.textPrimaryDark};
  `,
  step: css`
    padding: ${sv.basePadding} 0;
    border-bottom: 1px solid ${sv.grey300};

    &:last-of-type {
      border-bottom: 0;
    }
  `,
  stepTitle: css`
    font-size: 1.2rem;
    font-weight: normal;
    margin-top: 0;

    @media ${sv.phoneXl} {
      font-size: 1.rem;
    }
  `,
  stepDescription: css`
    color: ${sv.textSecondaryDark};
    margin-bottom: ${sv.baseMarginSmall};
  `,
  stepPercentage: css`
    width: 80%;
    margin-bottom: ${sv.baseMarginSmall};
  `,
  outerPercentage: css`
    width: 100%;
    height: 10px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
  `,
  innerPercentage: css`
    width: 0;
    height: 100%;
    background: ${sv.brandPrimary};
    border-radius: 10px;
  `,
  completedCheck: css`
    width: 25px;
    height: 25px;
    border-radius: 100%;
    padding: 0;
    background: ${sv.brandGreen};
    color: ${sv.white};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 0.7rem;
    margin-left: 7px;
  `,
  quickPayModal: css`
    max-width: 600px;
    padding: ${sv.basePadding};
    text-align: center;
  `,
  modalNote: css`
    font-size: 0.8em;
    color: ${sv.textTertiaryDark};
  `,
  modalButtons: css`
    margin-top: ${sv.baseMargin};
    text-align: center;
  `,
};
