export default (entities) => ({
  getProjectData(projectId) {
    return entities.Project.getData(projectId);
  },
  getViewsOverTime(projectId) {
    return entities.Project.getViewsOverTime(projectId);
  },
  getSessionLocations(projectId) {
    return entities.Project.getSessionLocations(projectId);
  },
  getVisits(projectId) {
    return entities.Project.getVisits(projectId);
  },
  getMostFavorited(projectId) {
    return entities.Project.getMostFavorited(projectId);
  },
  getTimeSpent(projectId) {
    return entities.Project.getTimeSpent(projectId);
  },
});
