import get from 'lodash/get';
import set from 'lodash/set';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/nl';


function isValidLocale(locale) {
  return locale === 'en' || locale === 'fr' || locale === 'nl';
}


function getDefaultLocale() {
  const fromBrowser = navigator.languages
    ? navigator.languages[0]
    : (navigator.language || navigator.userLanguage);
  const fromLocalStorage = localStorage.getItem('locale');
  const finalLanguage = fromLocalStorage ? fromLocalStorage : fromBrowser;
  const defaultLocale = finalLanguage ? finalLanguage.replace(/-.*/, '') : 'en';
  return isValidLocale(defaultLocale) ? defaultLocale : 'en';
}


function changeWindowLocale(next) {
  // We can add a listener using `next`
  return (locale) => {
    const currentLocale = get(window, 'i18n.locale');
    if (currentLocale === locale) {
      return locale;
    }
    set(window, 'i18n.locale', locale);
    dayjs.locale(locale);
    localStorage.setItem('locale', locale);

    const listeners = get(window, 'i18n._listeners', []);
    for (let listener of listeners) {
      listener(locale);
    }

    return next ? next(locale) : locale;
  };
}


function onChangeLocale(next) {
  const listeners = get(window, 'i18n._listeners', []);
  set(window, 'i18n._listeners', [ ...listeners, next ]);
}


export default function setupLocaleSystem(next) {
  const defaultLocale = getDefaultLocale();
  dayjs.locale(defaultLocale);
  // Add change locale function to window
  set(window, 'i18n.changeLocale', changeWindowLocale(next));
  set(window, 'i18n.locale', defaultLocale);
  set(window, 'i18n.onChangeLocale', onChangeLocale);
}
