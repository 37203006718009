import React from 'react';
import { css, cx } from 'emotion';
import sv from '@drawbotics/style-vars';


const styles = {
  counter: css`
    display: flex;
    align-items: center;
  `,
  disabled: css`
    cursor: not-allowed;
    opacity: 0.5;
  `,
  button: css`
    height: 40px;
    width: 40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${sv.grey200};
    color: ${sv.textPrimaryDark};
    outline: none;
    border: none;
    font-size: 1.6rem;
    padding: 0;

    &:hover {
      cursor: pointer;
      background: ${sv.grey300};
    }

    &:disabled {
      background: ${sv.grey100};
      color: ${sv.textSecondaryDark};
      cursor: not-allowed;
    }
  `,
  value: css`
    margin: 0 10px;
    font-size: 1.3rem;
    color: ${sv.textPrimaryDark};
  `,
}



const Counter = ({ value, onChange, name, disabled }) => {
  const _onChange = disabled ? null : onChange;
  return (
    <div className={cx(styles.counter, { [styles.disabled]: disabled })}>
      <button
        disabled={value <= 0 || disabled}
        className={styles.button}
        onClick={(e) => _onChange(value - 1, name, e)}>
        -
      </button>
      <div className={styles.value}>
        {value}
      </div>
      <button
        disabled={disabled}
        className={styles.button}
        onClick={(e) => _onChange(value + 1, name, e)}>
        +
      </button>
    </div>
  );
}


export default Counter;
