import get from 'lodash/get';


/**
 * Run a switch  operation.
 *
 * Example:
 *
 * {
 *   operation: 'switch',
 *   switch: {
 *     key: 'someKey',
 *     is: {
 *       oneValue: {
 *         operation: 'add',
 *         add: { value: 10 },
 *       },
 *       anotherValue: {
 *         operation: 'add',
 *         add: { value: 20 },
 *       },
 *     },
 *   },
 * }
 *
 */

export function doSwitch(object, switchDescription, doOperation, variation) {
  const { key, is } = switchDescription;
  const value = get(object, key) ?? variation;
  const then = is[value];
  return then != null ? doOperation(object, then) : 0;
}


class SwitchOperation {
  constructor(key) {
    this.key = key;
  }

  is(next) {
    return {
      operation: 'switch',
      switch: {
        key: this.key,
        is: next,
      },
    };
  }
}


export function Switch(key) {
  return new SwitchOperation(key);
}
