export default {
  dependencies: {
    id: `
      fragment _ on ProductService {
        slug
      }
    `,
  },
  resolvers: {
    id: (service) => service.id,
  },
};
