import get from 'lodash/get';
import locales from 'locales';

import { currencyWithSymbol } from './index';

function _replaceData(string, data={}) {
  if (typeof string === 'object') {
    // It's because we have a multiple string { one: '', other: '' }
    // Get count from data
    const count = get(data, 'count');
    if (count === 0) {
      string = get(string, 'zero', '');
    }
    else if (count === 1) {
      string = get(string, 'one', '');
    }
    else {
      string = get(string, 'other', '')
    }
  }
  if (typeof string === 'number') {
    string = string.toString();
  }
  if ( ! string) {
    return null;
  }
  const result = string.split(/(%\{[^}]*\})/)
    .map((fragment, i, array) => {
      const match = fragment.match(/%\{(.*)\}/);
      if ( ! match) {
        return fragment;
      }
      const key = match[1];
      return data[key];
    })
    .map((replacement, i) => {
      if ( ! replacement) {
        return '';
      }
      return replacement.hasOwnProperty('key') && replacement.key == null ? { ...replacement, key: i } : replacement;
    });
  return result.every((res) => typeof res === 'string') ? result.join('') : result;
}


function _translate(key, data, defaultValue) {
  if (process.env.APP_ENV === 'review') {
    return `{.${key}.}`;
  }
  const activeLocale = get(window, 'i18n.locale', 'en');
  const value = _replaceData(get(locales[activeLocale], key, defaultValue), data);
  if (value == null) {
    const placeholderValue = _replaceData(get(locales['en'], key, defaultValue), data);
    console.error(`Translation key '${key}' not found`);
    return placeholderValue ?? '';
  }
  return value;
}


/**
 * Returns a translated string according to
 * the active locale and the specified key.
 *
 * @function
 * @name translate
 * @memberof Utils
 *
 * @param {string} base - The base path of the key
 * @param {string} [key] - The specific part of the key
 * @param {object} [data] - An object with key/values
 * equal to the variables that the translated string accepts.
 * @param {string} [defaultValue] - A default value to
 * return if the translation is not found.
 *
 * @returns {string} - Translated key.
 */
export function translate(base, key, data, defaultValue) {
  let translationKey = '';
  if (arguments.length === 1) {
    translationKey = base;
  }
  if (arguments.length === 2) {
    translationKey = base;
    defaultValue = key && (typeof key === 'string') ? key : undefined;
    data = key && (typeof key === 'object') ? key : {};
  }
  if (arguments.length === 3) {
    translationKey = base.replace(/\.$/, '') + '.' + key;
    defaultValue = data && (typeof data === 'string') ? data : undefined;
    data = data && (typeof data === 'object') ? data : {};
  }
  if (arguments.length >= 3) {
    translationKey = base.replace(/\.$/, '') + '.' + key;
  }
  return _translate(translationKey, data, defaultValue);
}


export function createTranslate(base) {
  return (key, data, defaultValue) => {
    if ( ! data && defaultValue) {
      return translate(base, key, defaultValue);
    }
    if ( ! defaultValue && data) {
      return translate(base, key, data);
    }
    return translate(base, key, data, defaultValue);
  };
}


export function addCurrency(amount, currency) {
  return translate('price_with_currency', { price: amount, currency: currency.symbol });
}


export function translatePrice(amount, code) {
  return addCurrency(amount.toLocaleString(), currencyWithSymbol(code));
}


export default translate;
