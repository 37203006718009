import { getItemsByService } from '../utils';
import { EstateEnums } from '../utils/estate-enums';
import { priceEstateModeling } from '../utils/pricing';


export default {
  dependencies: {
    isNewDevelopment: `
      fragment _ on Estate {
        propertyType
      }
    `,
    isExistingProperty: `
      fragment _ on Estate {
        propertyType
      }
    `,
    jsonMeta: `
      fragment _ on Estate {
        metadata
      }
    `,
    orderCompleted: `
      fragment _ on Estate {
        projectType
        projectSubtype
        name
        unitCount
        metadata
        surface {
          value
        }
        address {
          id
        }
        items {
          id
        }
        delivery
      }
    `,
    orderProgress: `
      fragment _ on Estate {
        projectType
        projectSubtype
        name
        unitCount
        metadata
        surface {
          value
        }
        address {
          id
        }
        items {
          id
        }
        surface {
          value
        }
        delivery
      }
    `,
    paymentCompleted: `
      fragment _ on Estate {
        metadata
        orderedAt
      }
    `,
    paymentProgress: `
      fragment _ on Estate {
        metadata
      }
    `,
    briefingProgress: `
      fragment _ on Estate {
        briefingConfirmedAt
        items {
          id
          briefingConfirmedAt
        }
      }
    `,
    canConfirmBriefing: `
      fragment _ on Estate {
        id
        attachments {
          id
        }
        details {
          keyPoints
          description
          targetAudience
          projectTimeline
          location
        }
      }
    `,
    // BUG: this is not working, we need the ID now, working in CartItem because we request ids there
    itemsByService: `
      fragment _ on Estate {
        items {
          id
          product {
            slug
          }
          service {
            slug
          }
        }
      }
    `,
    lastVisitedStep: `
      fragment _ on Estate {
        metadata
      }
    `,
    modelingPrice: `
      fragment _ on Estate {
        modelingRequired
        rawModelingPrice: modelingPrice {
          value
          currency
        }
        projectType
        projectSubtype
        unitCount
        houseCount
        structureCount
        surface {
          value
        }
        delivery
      }
    `,
    baseModelingPrice: `
      fragment _ on Estate {
        modelingRequired
        rawModelingPrice: modelingPrice {
          value
          currency
        }
        projectType
        projectSubtype
        unitCount
        houseCount
        structureCount
        surface {
          value
        }
        delivery
      }
    `,
  },
  resolvers: {
    lastVisitedStep: (estate) => {
      const visitedSteps = Object.keys((JSON.parse(estate.metadata) || {}).steps);
      const reverseSteps = [
        'services/visualization',
        'services/innovation',
        'services/communication',
        'delivery',
      ].reverse();

      for (let i = 0; i < reverseSteps.length; i++) {
        if (visitedSteps.includes(reverseSteps[i])) {
          return reverseSteps[i];
        }
      }
      return 'property-type';
    },
    isNewDevelopment: (estate) => {
      return estate.propertyType === EstateEnums.PropertyType.NEW_DEVELOPMENT;
    },
    isExistingProperty: (estate) => {
      return estate.propertyType === EstateEnums.PropertyType.EXISTING_PROPERTY;
    },
    jsonMeta: (estate) => {
      const metadata = JSON.parse(estate.metadata);
      return metadata != null ? metadata : { steps: {} };
    },
    orderCompleted: (estate) => {
      let isCompleted = true;
      const { steps } = JSON.parse(estate.metadata) || {};
      isCompleted = isCompleted && estate.projectType != null;
      isCompleted = isCompleted && estate.projectSubtype != null;
      isCompleted = isCompleted && estate.name != null;
      isCompleted = isCompleted && (estate.unitCount > 0 || estate.surface?.value > 0);
      isCompleted = isCompleted
        && (estate.address != null || estate.propertyType === EstateEnums.PropertyType.EXISTING_PROPERTY);
      isCompleted = isCompleted && estate.items != null && estate.items.length > 0;
      isCompleted = isCompleted && estate.delivery != null;
      isCompleted = isCompleted && Boolean(steps?.summary?.visited);
      return isCompleted;
    },
    orderProgress: (estate) => {
      const totalSteps = 7;
      const { steps } = JSON.parse(estate.metadata) || {};
      let completedSteps = 0;
      estate.projectType != null ? completedSteps++ : null;
      estate.projectSubtype != null ? completedSteps++ : null;
      if (estate.unitCount > 0
        && estate.name != null
        && (estate.address != null || estate.propertyType === EstateEnums.PropertyType.EXISTING_PROPERTY)) {
        completedSteps++;
      }
      estate.items != null && estate.items.length > 0 ? completedSteps++ : null;
      estate.delivery != null ? completedSteps++ : null;
      steps.summary?.visited ? completedSteps++ : null;
      return completedSteps / totalSteps;
    },
    paymentCompleted: (estate) => {
      if (estate.orderedAt != null) {
        return true;
      }
      const { steps } = JSON.parse(estate.metadata) || {};
      if (! steps) return 0;
      let completedSteps = 0;
      steps.address?.visited ? completedSteps++ : null;
      steps.synthesis?.visited ? completedSteps++ : null;
      steps.settle?.visited ? completedSteps++ : null;
      steps.confirmation?.visited ? completedSteps++ : null;
      return completedSteps === 4;
    },
    paymentProgress: (estate) => {
      const totalSteps = 4;
      const { steps } = JSON.parse(estate.metadata) || {};
      let completedSteps = 0;
      steps.address?.visited ? completedSteps++ : null;
      steps.synthesis?.visited ? completedSteps++ : null;
      steps.settle?.visited ? completedSteps++ : null;
      steps.confirmation?.visited ? completedSteps++ : null;
      return completedSteps / totalSteps;
    },
    briefingProgress: (estate) => {
      const total = estate.items.filter((item) => item.service.abstract === false).length + 1;
      const itemBriefingsCompleted = estate.items.reduce((completedBriefings, item) => item.briefingConfirmedAt ? completedBriefings + 1 : completedBriefings, 0);
      const completedBriefings = estate.briefingConfirmedAt ? itemBriefingsCompleted + 1 : itemBriefingsCompleted;
      return completedBriefings / total;
    },
    canConfirmBriefing: (estate) => {
      let detailsCompleted = true;
      detailsCompleted = detailsCompleted && !! estate.details?.keyPoints;
      detailsCompleted = detailsCompleted && !! estate.details?.description;
      detailsCompleted = detailsCompleted && !! estate.details?.targetAudience;
      detailsCompleted = detailsCompleted && !! estate.details?.projectTimeline;
      detailsCompleted = detailsCompleted && !! estate.attachments.length > 0;
      return detailsCompleted;
    },
    itemsByService: (estate) => {
      return getItemsByService(estate);
    },
    modelingPrice: (estate) => {
      return estate.modelingRequired ? priceEstateModeling(estate, window.userCurrency?.code) : 0;
    },
    baseModelingPrice: (estate) => {
      return estate.modelingRequired ? priceEstateModeling(estate, window.userCurrency?.code, true) : 0;
    },
  },
};
