import gql from 'graphql-tag';

export const UpdateUser = gql`
  mutation UpdateProfile($user: UpdateProfileInput!) {
    updateProfile(input: $user) {
      user {
        id
        firstName
        lastName
      }
    }
  }
`;
