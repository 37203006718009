const k = (key) => `plan_2d.briefing.${key}`;

const residential = {
  1: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('floorplans'),
        component: 'Title',
      },
      {
        type: 'question-component',
        label: k('upload_floorplans'),
        component: 'FileUploader',
        key: 'otherInfoAttachments',
        required: 'all',
        keyLabel: k('keys.uploaded_floorplans'),
      },
    ],
  },
  2: {
    type: 'section',
    components: [
      {
        type: 'meta',
        condition: {
          required: 'one',
          conditions: [{
            type: 'orderDetail',
            value: 'premium',
            path: 'plan2d[0].details.style',
          }],
        },
        components: [
          {
            type: 'component',
            label: k('choose_finish'),
            component: 'Title',
          },
          {
            type: 'question-component',
            component: 'SingleSelect',
            key: 'subStyle',
            keyLabel: k('substyle'),
            required: 'all',
            values: [
              {
                image: require('../images/plan-2d/substyles/thumbs/premium1.jpg'),
                zoom: require('../images/plan-2d/substyles/premium1.jpg'),
                value: 'premium_1',
              },
              {
                image: require('../images/plan-2d/substyles/thumbs/premium2.jpg'),
                zoom: require('../images/plan-2d/substyles/premium2.jpg'),
                value: 'premium_2',
              },
              {
                image: require('../images/plan-2d/substyles/thumbs/premium3.jpg'),
                zoom: require('../images/plan-2d/substyles/premium3.jpg'),
                value: 'premium_3',
              },
              {
                image: require('../images/plan-2d/substyles/thumbs/premium4.jpg'),
                zoom: require('../images/plan-2d/substyles/premium4.jpg'),
                value: 'premium_4',
              },
            ],
          },
        ],
      },
      {
        type: 'meta',
        condition: {
          required: 'one',
          conditions: [{
            type: 'orderDetail',
            value: 'xyz',
            path: 'plan2d[0].details.style',
          }],
        },
        components: [
          {
            type: 'component',
            label: k('choose_finish'),
            component: 'Title',
          },
          {
            type: 'question-component',
            component: 'SingleSelect',
            key: 'subStyle',
            keyLabel: k('substyle'),
            required: 'all',
            values: [
              {
                image: require('../images/plan-2d/substyles/thumbs/xyz1.jpg'),
                zoom: require('../images/plan-2d/substyles/xyz1.jpg'),
                value: 'xyz_1',
              },
              {
                image: require('../images/plan-2d/substyles/thumbs/xyz2.jpg'),
                zoom: require('../images/plan-2d/substyles/xyz2.jpg'),
                value: 'xyz_2',
              },
            ],
          },
        ],
      },
    ],
  },
  3: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('options'),
        component: 'Title',
      },
      {
        type: 'question-component',
        component: 'MultiSelect',
        key: 'options',
        keyLabel: k('keys.options'),
        values: [
          {
            value: 'furniture',
            label: k('furniture'),
          },
          {
            value: 'compass_rose',
            label: k('compass_rose'),
          },
        ],
      },
    ],
  },
  4: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('format'),
        component: 'Title',
      },
      {
        type: 'question-component',
        component: 'MultiSelect',
        key: 'format',
        keyLabel: k('keys.format'),
        required: 'all',
        values: [
          {
            value: 'jpeg',
            label: k('jpeg'),
          },
          {
            value: 'pdf',
            label: k('pdf'),
          },
          {
            value: 'fml',
            label: k('fml'),
          },
        ],
      },
    ],
  },
  5: {
    type: 'section',
    components: [
      {
        type: 'meta',
        condition: {
          required: 'one',
          conditions: [{
            type: 'orderDetail',
            value: true,
            path: 'plan2d[0].details.logo',
          }],
        },
        components: [
          {
            type: 'component',
            label: k('logo_to_display'),
            component: 'Title',
          },
          {
            type: 'question-component',
            label: k('upload_logo'),
            component: 'FileUploader',
            required: 'all',
            key: 'logoAttachment',
            keyLabel: k('keys.uploaded_logo'),
          },
        ],
      },
      {
        type: 'meta',
        condition: {
          required: 'one',
          conditions: [{
            type: 'orderDetail',
            value: true,
            path: 'plan2d[0].details.disclaimer',
          }],
        },
        components: [
          {
            type: 'component',
            label: k('disclaimer'),
            component: 'Title',
          },
          {
            type: 'question-component',
            component: 'Input',
            required: 'all',
            key: 'disclaimerText',
            label: k('type_disclaimer'),
            keyLabel: k('keys.disclaimer_text'),
          },
        ],
      },
    ],
  },
  6: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'shared.additional_info',
        component: 'Title',
      }, {
        type: 'question-component',
        label: 'shared.additional_comments',
        component: 'Input',
        key: 'otherInfo',
        keyLabel: 'shared.keys.other_info',
      },
    ],
  },
};

export default { residential };
