const specs = {
  standard: {
    operation: 'create',
    create: {
      type: 'bool',
      product: 'videoAnimation_standard',
    },
  },
  premium: {
    operation: 'create',
    create: {
      type: 'bool',
      product: 'videoAnimation_premium',
    },
  },
  duration: {
    operation: 'update',
    update: {
      type: 'mapTo',
      target: '@{product.id} == "videoAnimation_standard" || @{product.id} == "videoAnimation_premium"',
      mapTo: [ 'duration' ],
    },
  },
  droneShooting: {
    operation: 'update',
    update: {
      type: 'mapTo',
      target: '@{product.id} == "videoAnimation_standard" || @{product.id} == "videoAnimation_premium"',
      mapTo: [ 'droneShooting' ],
    },
  },
};


const dependencies = {
  hard: [],
  soft: [
    {
      if: {
        key: 'droneShooting',
        value: true,
      },
      then: {
        operation: 'createOrUpdate',
        createOrUpdate: {
          product: 'droneShooting_standard',
          service: 'droneShooting',
          with: { videoAnimation: true },
        },
      },
    },
  ],
};


export default { specs, dependencies };
