import React, { Fragment } from 'react';
import autobind from 'autobind-decorator';
import { Grid, Cell } from 'react-ittsu/layout';
import gql from 'fraql';

import BaseServiceModal from '../BaseServiceModal';
import Paragraph from '../Paragraph';
import SectionTitle from '../SectionTitle';
import { ProductCard, Stripe, StripeGroup } from '../../forms';
import { priceItem, applyPrice } from '../../../utils/pricing';
import { specsToItems, itemsToSpecs } from '~/pods/order/utils/services/specs-to-items';
import applyChanges from '~/pods/order/utils/services/specs-to-items/apply-changes';
import { getItemsByService, merge, openIntercom, getFeaturesForProduct } from '~/pods/order/utils';
import { EstateEnums } from '~/pods/order/utils/estate-enums';
import { estateWithSqm } from '~/pods/order/utils/pricing/utils';
import { createTranslate, translate as t } from '~/utils/translation';

import serviceImages from '~/pods/order/images/services';


const tt = createTranslate('pods.order.services.tour_3d.order');
const tm = createTranslate('pods.order.components.service_modal');


class Tour3D extends React.Component {

  static fragments = {
    Item: gql`
      fragment _ on Item {
        id
        details {
          ... on Tour3DDetails {
            type
            rooms
            terraceHotspots
            gardenHotspots
            commercialHotspots
          }
        }
        service {
          id @client(type: ProductService)
        }
        product {
          id @client(type: Product)
          custom
          features {
            locale
            content
          }
        }
        estate {
          projectType
          projectSubtype
          surface {
            value
          }
        }
      }
    `,
  }

  state = {
    price: undefined,
    custom: null,
    standard: false,
    specs: {
      houseTwoBedroom: 0,
      houseThreeBedroom: 0,
      houseFourBedroom: 0,
      houseFiveBedroom: 0,
      apartmentStudio: 0,
      apartmentOneBedroom: 0,
      apartmentTwoBedroom: 0,
      apartmentThreeBedroom: 0,
      apartmentFourBedroom: 0,
      apartmentFiveBedroom: 0,
      commercialStandard: false,
      commercialHotspots: 0,
      terraceHotspots: 0,
      gardenHotspots: 0,
    },
  };

  componentDidUpdate(prevProps) {
    const { service, estate } = this.props;
    const { service: prevService } = prevProps;
    if (service?.id === 'tour3d' && ! prevService) {
      const specs = itemsToSpecs({ estate, service });
      const items = getItemsByService(estate).tour3d || [];
      const customItem = items?.find((item) => item.product.custom);
      const price = items != null ? items.reduce((memo, i) => memo + priceItem(i, this.props.sessionUser.currency.code, true), 0) : 0;
      this.setState({
        specs: merge(this.state.specs, specs),
        price,
        custom: customItem ? true : null,
        standard: customItem ? false : this.state.standard,
      });
    }
  }

  render() {
    const { onClickClose, isVisible, service, estate, sessionUser } = this.props;
    const { price, specs, custom, standard } = this.state;
    const { tour3d } = serviceImages;
    const items = getItemsByService(estate).tour3d || [];
    const customItem = items.find((item) => item.product.custom);
    const customFeatures = custom !== null ? getFeaturesForProduct(customItem?.product) : null;
    return (
      <BaseServiceModal
        isVisible={isVisible && service?.id === 'tour3d'}
        title={service?.id ? t(`services.${service.id}`) : ''}
        images={Object.values(tour3d)}
        price={sessionUser.addCurrency(price)}
        onClickConfirm={this._handleClickConfirm}
        confirmAsUpdate={items?.length > 0}
        priceDisclaimer={{
          label: tt('price_disclaimer.text'),
          description: tt('price_disclaimer.info'),
        }}
        addDisabled={price === 0}
        onClickClose={onClickClose}>
        <Paragraph>
          {tt('description')}
        </Paragraph>
        {do {
          if (estate.projectType === EstateEnums.ProjectType.RESIDENTIAL) {
            <Fragment>
              <Grid withVGutters withHGutters>
                <Cell size="1of2">
                  <ProductCard
                    name="standard"
                    label={tm('standard')}
                    fullHeight
                    formComponent="radio"
                    features={[
                      {
                        text: tt('features.first'),
                      },
                      {
                        text: tt('features.second'),
                      },
                      {
                        text: tt('features.third'),
                      },
                      {
                        text: tt('features.fourth'),
                      },
                      {
                        text: tt('features.fifth'),
                      },
                      {
                        text: tt('features.sixth'),
                      },
                    ]}
                    value={standard}
                    onChange={this._handleClickStandard} />
                </Cell>
                <Cell size="1of2">
                  <ProductCard
                    name="custom"
                    label={custom !== null ? `${customItem?.product.name}` : tm('custom')}
                    note={custom !== null ? `${sessionUser.addCurrency(customItem?.price)}` : tm('contact_us')}
                    formComponent="radio"
                    fullHeight
                    features={customFeatures}
                    onChange={this._handleClickCustom}
                    value={!! custom} />
                </Cell>
              </Grid>
              {do{
                if (standard) {
                  <Fragment>
                    {do {
                      if (estate.projectSubtype === EstateEnums.ProjectSubtype.HOUSE) {
                        <Fragment>
                          <SectionTitle>
                            {tt('select_rooms')}
                          </SectionTitle>
                          <Grid halfVGutters>
                            <Cell size="1of1">
                              <ProductCard
                                name="house"
                                label={tt('bedrooms')}
                                note={tm('starts_at', { price: applyPrice({ EUR: 2350, GBP: 2350, USD: 2940 }, sessionUser) })}
                                horizontal
                                formComponent="checkbox"
                                value={this._getHouseBedrooms()}
                                onChange={this._handleSingleHouse}
                                withCounter />
                            </Cell>
                          </Grid>
                        </Fragment>
                      }
                    }}
                    {do {
                      if (estate.projectSubtype === EstateEnums.ProjectSubtype.BUILDING || estate.projectSubtype === EstateEnums.ProjectSubtype.COMPLEX) {
                        <Fragment>
                          <SectionTitle>
                            {tt('select_apartments')}
                          </SectionTitle>
                          <Grid halfVGutters>
                            <Cell size="1of1">
                              <ProductCard
                                name="apartmentStudio"
                                label={tt('studio')}
                                note={tm('per_unit', { price: applyPrice({ EUR: 1200, GBP: 1200, USD: 1500 }, sessionUser) })}
                                horizontal
                                formComponent="checkbox"
                                value={specs.apartmentStudio}
                                onChange={this._handleClickCounter}
                                withCounter />
                            </Cell>
                            <Cell size="1of1">
                              <ProductCard
                                name="apartmentOneBedroom"
                                label={tt('one_bedroom')}
                                note={tm('per_unit', { price: applyPrice({ EUR: 1650, GBP: 1650, USD: 2070 }, sessionUser) })}
                                horizontal
                                formComponent="checkbox"
                                value={specs.apartmentOneBedroom}
                                onChange={this._handleClickCounter}
                                withCounter />
                            </Cell>
                            <Cell size="1of1">
                              <ProductCard
                                name="apartmentTwoBedroom"
                                label={tt('two_bedrooms')}
                                note={tm('per_unit', { price: applyPrice({ EUR: 2000, GBP: 2000, USD: 2500 }, sessionUser) })}
                                horizontal
                                formComponent="checkbox"
                                value={specs.apartmentTwoBedroom}
                                onChange={this._handleClickCounter}
                                withCounter />
                            </Cell>
                            <Cell size="1of1">
                              <ProductCard
                                name="apartmentThreeBedroom"
                                label={tt('three_bedrooms')}
                                note={tm('per_unit', { price: applyPrice({ EUR: 2350, GBP: 2350, USD: 2940 }, sessionUser) })}
                                horizontal
                                formComponent="checkbox"
                                value={specs.apartmentThreeBedroom}
                                onChange={this._handleClickCounter}
                                withCounter />
                            </Cell>
                            <Cell size="1of1">
                              <ProductCard
                                name="apartmentFourBedroom"
                                label={tt('four_bedrooms')}
                                note={tm('per_unit', { price: applyPrice({ EUR: 2600, GBP: 2600, USD: 3250 }, sessionUser) })}
                                horizontal
                                formComponent="checkbox"
                                value={specs.apartmentFourBedroom}
                                onChange={this._handleClickCounter}
                                withCounter />
                            </Cell>
                            <Cell size="1of1">
                              <ProductCard
                                horizontal
                                name="apartmentFiveBedroom"
                                label={tt('five_bedrooms')}
                                note={tm('per_unit', { price: applyPrice({ EUR: 2850, GBP: 2850, USD: 3570 }, sessionUser) })}
                                formComponent="checkbox"
                                value={specs.apartmentFiveBedroom}
                                onChange={this._handleClickCounter}
                                withCounter />
                            </Cell>
                          </Grid>
                        </Fragment>
                      }
                    }}
                    {do {
                      if (estate.projectSubtype === EstateEnums.ProjectSubtype.COMPLEX) {
                        <Fragment>
                          <SectionTitle>
                            {tt('select_houses')}
                          </SectionTitle>
                          <Grid halfVGutters>
                            <Cell size="1of1">
                              <ProductCard
                                name="houseTwoBedroom"
                                label={tt('two_bedrooms')}
                                note={tt('per_room', { price: applyPrice({ EUR: 2350, GBP: 2350, USD: 2940 }, sessionUser) })}
                                horizontal
                                formComponent="checkbox"
                                value={specs.houseTwoBedroom}
                                onChange={this._handleClickCounter}
                                withCounter />
                            </Cell>
                            <Cell size="1of1">
                              <ProductCard
                                name="houseThreeBedroom"
                                label={tt('three_bedrooms')}
                                note={tt('per_room', { price: applyPrice({ EUR: 2600, GBP: 2600, USD: 3250 }, sessionUser) })}
                                horizontal
                                formComponent="checkbox"
                                value={specs.houseThreeBedroom}
                                onChange={this._handleClickCounter}
                                withCounter />
                            </Cell>
                            <Cell size="1of1">
                              <ProductCard
                                name="houseFourBedroom"
                                label={tt('four_bedrooms')}
                                note={tt('per_room', { price: applyPrice({ EUR: 2850, GBP: 2850, USD: 3570 }, sessionUser) })}
                                horizontal
                                formComponent="checkbox"
                                value={specs.houseFourBedroom}
                                onChange={this._handleClickCounter}
                                withCounter />
                            </Cell>
                            <Cell size="1of1">
                              <ProductCard
                                name="houseFiveBedroom"
                                label={tt('five_bedrooms')}
                                note={tt('per_room', { price: applyPrice({ EUR: 3000, GBP: 3000, USD: 3750 }, sessionUser) })}
                                horizontal
                                formComponent="checkbox"
                                value={specs.houseFiveBedroom}
                                onChange={this._handleClickCounter}
                                withCounter />
                            </Cell>
                          </Grid>
                        </Fragment>
                      }
                    }}
                    <SectionTitle>
                      {tt('extra_options')}
                    </SectionTitle>
                    <StripeGroup>
                      <Stripe
                        name="gardenHotspots"
                        title={tt('garden_hotspots')}
                        component="counter"
                        disabled={ ! this._shouldEnableOptions(specs)}
                        value={specs.gardenHotspots}
                        onChange={this._handleClickCounter} />
                      <Stripe
                        name="terraceHotspots"
                        title={tt('terrace_hotspots')}
                        component="counter"
                        disabled={ ! this._shouldEnableOptions(specs)}
                        value={specs.terraceHotspots}
                        onChange={this._handleClickCounter} />
                    </StripeGroup>
                  </Fragment>
                }
              }}
            </Fragment>
          }
          else {
            <Fragment>
              <Grid withVGutters withHGutters>
                <Cell size="1of2">
                  <ProductCard
                    name="commercialStandard"
                    label={tm('standard')}
                    note={tm('variable_price')}
                    fullHeight
                    formComponent="radio"
                    features={[{
                      text: tt('commercial_features.first.text'),
                      infoText: tt('commercial_features.first.info'),
                    }]}
                    value={specs.commercialStandard}
                    onChange={this._handleClickRadio} />
                </Cell>
                <Cell size="1of2">
                  <ProductCard
                    name="custom"
                    label={custom !== null ? `${customItem?.product.name}` : tm('custom')}
                    note={custom !== null ? `${sessionUser.addCurrency(customItem?.price)}` : tm('contact_us')}
                    formComponent="radio"
                    fullHeight
                    features={customFeatures}
                    onChange={this._handleClickCustom}
                    value={!! custom} />
                </Cell>
              </Grid>
              <SectionTitle>
                {tt('options')}
              </SectionTitle>
              <StripeGroup>
                <Stripe
                  name="commercialHotspots"
                  title={tt('commercial_hotspots')}
                  component="counter"
                  features={[{
                    text: tt('hotspots_features.first.text'),
                    infoText: tt('hotspots_features.first.info'),
                  }]}
                  disabled={ ! this._shouldEnableOptions(specs)}
                  value={specs.commercialHotspots}
                  onChange={this._handleClickCounter} />
              </StripeGroup>
            </Fragment>
          }
        }}
        <SectionTitle>
          {tt('included_in_service')}
        </SectionTitle>
        <StripeGroup>
          <Stripe
            features={[{
              text: tt('features.first'),
            }]} />
        </StripeGroup>
      </BaseServiceModal>
    );
  }

  @autobind
  _syncState() {
    const { estate, service } = this.props;
    const { specs } = this.state;
    const changes = specsToItems({ specs, estate, service });
    const updatedEstate = applyChanges(changes, estate);
    const items = getItemsByService(updatedEstate).tour3d;
    const price = items != null
      ? items
          .map((item) => item.estate != null ? item : { ...item, estate: updatedEstate })
          .reduce((memo, i) => memo + priceItem(i, this.props.sessionUser.currency.code, true), 0)
      : 0;
    this.setState({ price });
    return { changes, updatedEstate, price };
  }

  @autobind
  _handleClickCounter(value, name) {
    const { estate } = this.props;
    const { specs } = this.state;
    const minHotspots = Math.floor(estateWithSqm(estate).surface.value / 100);
    const newSpecs = {
      ...specs,
      [name]: name === 'commercialHotspots' && value < minHotspots ? specs.commercialHotspots : (value == null ? 0 : value),
    };
    if ( ! this._shouldEnableOptions(newSpecs)) {
      newSpecs.gardenHotspots = 0;
      newSpecs.terraceHotspots = 0;
      newSpecs.commercialHotspots = 0;
    }
    this.setState({ specs: newSpecs }, this._syncState);
  }

  @autobind
  _handleClickRadio(value, name) {
    const { specs } = this.state;
    const newSpecs = {
      ...specs,
      commercialStandard: false,
      [name]: specs[name] ? false : true,
    };
    if ( ! this._shouldEnableOptions(newSpecs)) {
      newSpecs.commercialHotspots = 0;
    }
    else {
      const minHotspots = 3;
      newSpecs.commercialHotspots = newSpecs.commercialHotspots > minHotspots
        ? newSpecs.commercialHotspots
        : minHotspots;
    }
    this.setState({ specs: newSpecs }, this._syncState);
  }

  @autobind
  _handleSingleHouse(rooms) {
    if (rooms <= 5 && rooms >= 2) {
      const { specs } = this.state;
      const newSpecs = {
        ...specs,
        houseTwoBedroom: rooms === null && specs.houseTwoBedroom === 1 ? 0 : (rooms === 2 || rooms === null ? 1 : 0),
        houseThreeBedroom: rooms === 3 ? 1 : 0,
        houseFourBedroom: rooms === 4 ? 1 : 0,
        houseFiveBedroom: rooms === 5 ? 1 : 0,
      };
      if ( ! this._shouldEnableOptions(newSpecs)) {
        newSpecs.gardenHotspots = 0;
        newSpecs.terraceHotspots = 0;
      }
      this.setState({ specs: newSpecs }, this._syncState);
    }
  }

  @autobind
  _handleClickStandard() {
    const { custom, standard } = this.state;
    const { estate } = this.props;
    const newStandard = ! standard;
    this.setState({
      custom: custom === null ? null : ! custom, standard: newStandard,
      specs: {
        ...this.state.specs,
        houseTwoBedroom: estate.projectSubtype === EstateEnums.ProjectSubtype.HOUSE && newStandard ? 1 : 0,
      },
    }, this._syncState);
    if (! newStandard) {
      this._resetSpecs(this._syncState);
    }
  }

  @autobind
  _handleClickCustom(value) {
    const { custom } = this.state;
    if (custom === null) {
      openIntercom();
      this.setState({ standard: false });
      this._resetSpecs(this._syncState);
    }
    else {
      const newValue = ! custom;
      this._resetSpecs(() => this.setState({ custom: newValue, standard: false }, this._syncState));
    }
  }

  @autobind
  _handleClickConfirm() {
    const { onModifyService, estate } = this.props;
    const { custom } = this.state;
    const extraRemove = custom === false ? [getItemsByService(estate).tour3d.find((item) => item.product.custom)] : [];
    const { changes } = this._syncState();
    onModifyService({ ...changes, remove: [ ...changes.remove, ...extraRemove ] });
  }

  _getHouseBedrooms() {
    const { estate } = this.props;
    const { specs } = this.state;
    if (estate.projectType === EstateEnums.ProjectType.RESIDENTIAL && estate.projectSubtype === EstateEnums.ProjectSubtype.HOUSE) {
      if (specs.houseTwoBedroom > 0) return 2;
      else if (specs.houseThreeBedroom > 0) return 3;
      else if (specs.houseFourBedroom > 0) return 4;
      else if (specs.houseFiveBedroom > 0) return 5;
      else return 0;
    }
    return 0;
  }

  _shouldEnableOptions(specs) {
    const { estate } = this.props;
    if (estate.projectType === EstateEnums.ProjectType.RESIDENTIAL) {
      return specs.houseTwoBedroom > 0
        || specs.houseThreeBedroom > 0
        || specs.houseThreeBedroom > 0
        || specs.houseFourBedroom > 0
        || specs.houseFiveBedroom > 0
        || specs.apartmentStudio > 0
        || specs.apartmentOneBedroom > 0
        || specs.apartmentTwoBedroom > 0
        || specs.apartmentThreeBedroom > 0
        || specs.apartmentFourBedroom > 0
        || specs.apartmentFiveBedroom > 0;
    }
    else {
      return specs.commercialStandard;
    }
  }

  @autobind
  _resetSpecs(onReset) {
    this.setState({ specs: {
      houseTwoBedroom: 0,
      houseThreeBedroom: 0,
      houseFourBedroom: 0,
      houseFiveBedroom: 0,
      apartmentStudio: 0,
      apartmentOneBedroom: 0,
      apartmentTwoBedroom: 0,
      apartmentThreeBedroom: 0,
      apartmentFourBedroom: 0,
      apartmentFiveBedroom: 0,
      commercialStandard: false,
      commercialHotspots: 0,
      terraceHotspots: 0,
      gardenHotspots: 0,
      } }, onReset);
  }
}


export default Tour3D;
