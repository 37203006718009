import morphey, { fromKey, fromValue } from 'morphey';

import { annotationFromServer } from 'utils/api/translators/annotation';


function getDefaultImage() {
  return 'https://plateformfiles.s3.amazonaws.com/uploads/attachment/attached/112547/1RS_2017-03-15_10343_Drawbotics_BW_Preview_2.1.jpg';
}


const translationsFromServer = {
  id: 'id',
  filename: 'filename',
  url: process.env.NODE_ENV === 'development' ? fromValue(getDefaultImage()) : 'url',
  validatedAt: 'validated_at',
  validatedBy: 'validated_by',
  draft: 'draft',
  problemStatus: fromKey('problem_status'),
  annotations: fromKey('annotations').defaultTo([]).using((a) => a.map(annotationFromServer)),
};


export function previewFromServer(preview) {
  return morphey(preview, translationsFromServer);
}


export default {
  fromServer: previewFromServer,
};
