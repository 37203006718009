import React from 'react';
import { Label, FormGroup, TextArea, Form } from 'react-ittsu/forms';
import autobind from 'autobind-decorator';
import get from 'lodash/get';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';

import { createTranslate } from '~/utils/translation';


const t = createTranslate('pods.order.services');


const styles = {
  input: css`
    width: 500px;

    @media ${sv.phoneXl} {
      max-width: 500px;
      width: 100%;
    }
  `,
};


class Input extends React.Component {
  render() {
    const { label, resultKey, answers } = this.props;
    const comment = get(answers, resultKey, '') || '';
    return (
      <div className={styles.input}>
        <Form name="input">
          <FormGroup>
            <Label>{label && t(label, null, label)}</Label>
            <TextArea name="textarea" value={comment} onChange={this._handleOnChange} />
          </FormGroup>
        </Form>
      </div>
    );
  }

  @autobind
  _handleOnChange(val) {
    const { modifyAnswer, resultKey } = this.props;
    if (val === '') {
      modifyAnswer(resultKey, null, true);
    }
    else {
      modifyAnswer(resultKey, val);
    }
  }
}


export default Input;
