import { priceItem } from '../utils/pricing';
import itemPriceDetails from '../utils/pricing/item-details';


export default {
  dependencies: {
    price: `
      fragment _ on Item {
        id
        product {
          slug
          custom
        }
        rawPrice: price
        estate {
          projectType
          projectSubtype
          unitCount
          houseCount
          structureCount
          apartmentCount
          surface {
            value
          }
          delivery
        }
        ${itemPriceDetails}
      }
    `,
    basePrice: `
      fragment _ on Item {
        id
        product {
          slug
          custom
        }
        rawPrice: price
        estate {
          projectType
          projectSubtype
          unitCount
          houseCount
          structureCount
          surface {
            value
          }
          delivery
        }
        ${itemPriceDetails}
      }
    `,
    jsonMeta: `
      fragment _ on Item {
        metadata
      }
    `,
  },
  resolvers: {
    price: (item) => {
      if (item.product.id == null) {
        throw new Error(`Error when pricing an item. It seems you forgot to request "product.id" for ${item.product.slug}`);
      }
      return item.product.custom ? item.rawPrice : priceItem(item, window.userCurrency?.code);
    },
    basePrice: (item) => {
      if (item.product.id == null) {
        throw new Error(`Error when pricing an item. It seems you forgot to request "product.id" for ${item.product.slug}`);
      }
      return item.product.custom ? item.rawPrice : priceItem(item, window.userCurrency?.code, true);
    },
    jsonMeta: (item) => {
      return JSON.parse(item.metadata) || {};
    },
  },
};
