import get from 'lodash/get';
import merge from 'lodash/merge';


export function checkArgs({ service, specs, estate }) {
  if (service == null) {
    throw new Error('Service cannot be null');
  }
  if (service.id == null) {
    throw new Error('Service is not a valid service object');
  }
  if (specs == null) {
    throw new Error('Specs cannot be null');
  }
  if (estate == null) {
    throw new Error('Estate cannot be null');
  }
  if (estate.items == null) {
    throw new Error('Estate is not a valid estate object');
  }
}


export function isIsolated(service) {
  const { related } = service;
  return (related.hard == null || related.hard.length === 0)
    && (related.soft == null
      || ((related.soft.if == null || related.soft.if.length === 0)
        && (related.soft.unless == null || related.soft.unless.length === 0)));
}


function _concatUniqBy(array, array2, conditionKey) {
  return array2.reduce((memo, element) => {
    const alreadyPresent = memo.map((i) => i[conditionKey]).includes(element[conditionKey]);
    if (alreadyPresent) {
      const presentItem = memo.find((i) => i[conditionKey] === element[conditionKey]);
      return [ ...memo.filter((i) => i[conditionKey] !== element[conditionKey]), merge(presentItem, element) ];
    }
    return [ ...memo, element ];
  }, array.slice());
}


export function add(changes, items) {
  return { ...changes, add: [ ...changes.add, ...items ] };
}


export function remove(changes, items) {
  return { ...changes, remove: _concatUniqBy(changes.remove, items, 'id') };
}


export function update(changes, items) {
  return { ...changes, update: _concatUniqBy(changes.update, items, 'id') };
}


export function matchesSoftCondition(item, dependency) {
  const { if: cond } = dependency;
  if (typeof cond.value === 'function') {
    return cond.value(get(item.details, cond.key));
  }
  return get(item.details, cond.key) === cond.value;
}


function _isRequiredByItem(product, item) {
  const { service } = item;
  if (service == null || service.dependencies == null) {
    return false;
  }
  const { hard, soft } = service.dependencies;
  for (let dependency of hard) {
    const { operation } = dependency;
    const { product: dependencyProduct } = dependency[operation];
    if (product === dependencyProduct) {
      return true;
    }
  }
  for (let dependency of soft) {
    const { then: thenCond } = dependency;
    if (matchesSoftCondition(item, dependency)) {
      const { operation } = thenCond;
      const { product: dependencyProduct } = thenCond[operation];
      if (product === dependencyProduct) {
        return true;
      }
    }
  }

  return false;
}


export function isRequired(product, estate) {
  const { items: estateItems } = estate;
  const items = [...estateItems];
  for (let item of items) {
    if (_isRequiredByItem(product, item)) {
      return true;
    }
  }
  return false;
}
