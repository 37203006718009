export const SHOW_MODAL = 'SHOW_MODAL';

export function showModal(id, extra) {
  return {
    type: SHOW_MODAL,
    payload: {
      id,
      extra,
    },
  };
}


export const HIDE_MODAL = 'HIDE_MODAL';

export function hideModal(id) {
  return {
    type: HIDE_MODAL,
    payload: {
      id,
    },
  };
}
