import React from 'react';
import autobind from 'autobind-decorator';
import { css, cx } from 'emotion';
import { FormGroup, Input, Label } from 'react-ittsu/forms';
import { Button, Icon, Paragraph } from 'react-ittsu/components';
import sv, { fade } from '@drawbotics/style-vars';
import Map, { Marker as MapboxMarker } from 'react-mapbox-wrapper';
import { LngLatBounds } from 'mapbox-gl';
import get from 'lodash/get';
import omit from 'lodash/omit';

import AddressInput from '~/pods/order/components/AddressInput';
import { createTranslate, translate as t } from '~/utils/translation';

import pin from '~/pods/order/images/icons/project-pin.png';


const tt = createTranslate('pods.order.components.questions_generator');


const styles = {
  mapSearch: css`
    width: 700px;

    @media ${sv.phone} {
      margin-top: ${sv.baseMargin};
      width: 100%;
    }
  `,
  inputs: css`
    display: flex;
    align-items: center;
    justify-content: space-around;

    & > div {
      flex: 1;
      margin-right: ${sv.baseMarginSmall};
    }

    @media ${sv.phone} {
      flex-direction: column;

      & > div {
        margin-right: 0;
        width: 100%;
      }
    }
  `,
  mapWrapper: css`
    height: 400px;
    position: relative;
    margin-top: ${sv.baseMargin};
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);

    @media ${sv.phone} {
      height: 300px;
      width: calc(100% + ${sv.baseMargin});
      margin-left: calc(${sv.baseMarginSmall} * -1);
    }
  `,
  map: css`
    height: 100%;

    & .mapboxgl-control-container {
      display: none;
    }
  `,
  marker: css`
    position: relative;
    transition: all ${sv.baseTransitionTimeShort} ease-in-out;

    > img {
      width: 30px;
      margin-top: 10px;
    }

    &:hover {
      cursor: pointer;
      transform: translateY(-2px);
    }
  `,
  label: css`
    position: absolute;
    top: calc(${sv.baseMargin} * -1);
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    color: ${sv.white};
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
    background: ${sv.brandPrimary};
    padding: 10px ${sv.basePaddingSmall};
    border-radius: 40px;
    filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.15));
    min-width: 100px;
    z-index: 10;

    &::after {
      content: ' ';
      position: absolute;
      bottom: 0;
      left: calc(50% - 7px);
      top: 100%;
      width: 0px;
      height: 0px;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 7px solid ${sv.brandPrimary};
    }
  `,
  delete: css`
    background: ${sv.brandRed};
    color: ${sv.white};

    &::after {
      border-top-color: ${sv.brandRed};
    }
  `,
  inversed: css`
    color: ${sv.textPrimaryDark};
    background: ${sv.white};

    &::after {
      border-top-color: ${sv.white};
    }
  `,
  fixed: css`
    pointer-events: none;
  `,
  poisCount: css`
    position: absolute;
    top: ${sv.baseMarginSmall};
    left: ${sv.baseMarginSmall};
    background: ${sv.white};
    padding: 5px;
    font-size: 0.8rem;
    font-weight: 600;
    color: ${sv.textPrimaryDark};
    z-index: 5;
  `,
  closeIcon: css`
    margin-left: ${sv.basePaddingSmall};
  `,
  radar: css`
    position: relative;
    height: 40px;
    width: 40px;
    border-radius: 1000px;
    background: ${fade(sv.brandPrimary, 30)};
    border: 1px solid ${sv.brandPrimary};
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: ' ';
      height: 7px;
      width: 7px;
      border-radius: 1000px;
      background: ${sv.brandPrimary};
    }
  `,
  paragraph: css`
    color: ${sv.textPrimaryDark};
  `,
  existing: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
  `,
  poisLabel: css`
    margin-right: ${sv.baseMarginSmall};
    color: ${sv.brandBlue};
    font-size: 1.1rem;

    @media ${sv.phoneXl} {
      font-size: 0.9rem;
    }
  `,
  itemLabel: css`
    margin-right: ${sv.baseMargin};
    color: ${sv.textSecondaryDark};

    @media ${sv.phoneXl} {
      font-size: 0.8rem;
    }
  `,
};


const Marker = ({ label, fixed, onClick, popupOpen, onClickDelete }) => {
  return (
    <div className={cx(styles.marker, { [styles.fixed]: fixed })} onClick={onClick}>
      <div className={cx(styles.label, { [styles.inversed]: ! fixed, [styles.delete]: popupOpen })} onClick={popupOpen ? onClickDelete : null}>
        {popupOpen ? tt('delete') : label}
        {do{
          if ( ! fixed && ! popupOpen) {
            <Icon className={styles.closeIcon} name="close-bold" />
          }
        }}
      </div>
      {do{
        if (fixed) {
          <img src={pin} />
        }
        else {
          <div className={styles.radar} />
        }
      }}
    </div>
  )
};


class MapSearch extends React.Component {
  state = {
    pois: [],
    location: {},
    popupOpen: null,
    reload: false,
  }

  componentDidMount() {
    this._updatePois();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.answers.pois !== this.props.answers.pois) {
      this._updatePois();
    }
  }

  render() {
    const { location, pois, popupOpen } = this.state;
    const { estate } = this.props;
    const projectCoordinates = { lat: parseFloat(estate.address.latitude), lng: parseFloat(estate.address.longitude) };
    const existingPois = this._getExistingPois();
    return (
      <div className={styles.mapSearch}>
        <div className={styles.inputs}>
          <FormGroup>
            <Label>Address</Label>
            <AddressInput
              mapboxOptions={{ proximity: projectCoordinates, types: [] }}
              name="address"
              value={location.address || ''}
              placeholder={tt('poi_placeholder')}
              onChange={this._handleOnChangeField} />
          </FormGroup>
          <FormGroup>
            <Label>Label</Label>
            <Input
              name="label"
              placeholder={tt('label_placeholder')}
              value={location.label || ''}
              onChange={this._handleOnChangeField} />
          </FormGroup>
          <Button category="primary" onClick={this._handleClickAdd} disabled={! location.label || ! location.address}>Add</Button>
        </div>
        {do {
          if (existingPois.length > 0 && pois.length === 0) {
            <div className={styles.fromExisting}>
              <Paragraph className={styles.paragraph}>{tt('choose_from_existing')}</Paragraph>
              {existingPois.map((item) => (
                <div className={styles.existing} key={item.reference}>
                  <div className={styles.poisLabel}>
                    {item.pois.map((poi) => poi.label).join(', ')}
                  </div>
                  <div className={styles.itemLabel}>
                    {tt('from_item', { reference: item.reference, service: t(`services.${item.service}`) })}
                  </div>
                  <Button size="small" round category="info" onClick={() => this._handlePickExistingPois(item.pois)}>
                    {tt('pick')}
                  </Button>
                </div>
              ))}
            </div>
          }
        }}
        <div className={styles.mapWrapper} onClick={() => this.setState({ popupOpen: null })}>
          <div className={styles.poisCount}>
            {tt('pois', { count: pois.length })}
          </div>
          <Map
            onLoad={this._handleMapLoad}
            className={styles.map}
            accessToken={process.env.MAPBOX_ACCESS_TOKEN}
            interactive={false}
            // eslint-disable-next-line react/style-prop-object
            style="mapbox://styles/mapbox/light-v9"
            coordinates={projectCoordinates}>
            {do{
              if (this.map) {
                <MapboxMarker coordinates={projectCoordinates} map={this.map}>
                  <Marker label={tt('your_project')} fixed />
                </MapboxMarker>
              }
            }}
            {do{
              if (this.map) {
                pois.map((poi, i) => (
                  <MapboxMarker key={i} coordinates={poi.coordinates} map={this.map}>
                    <Marker
                      label={poi.label}
                      onClickDelete={(e) => { e.stopPropagation(); this._handleDeletePoi(i)}}
                      popupOpen={popupOpen === i}
                      onClick={(e) => { e.stopPropagation(); this.setState({ popupOpen: i }); }} />
                  </MapboxMarker>
                ));
              }
            }}
          </Map>
        </div>
      </div>
    );
  }

  @autobind
  _updatePois() {
    const { answers, resultKey } = this.props;
    const sectionAnswer = get(answers, resultKey);
    if (sectionAnswer && sectionAnswer.length > 0) {
      this.setState({
        pois: sectionAnswer.map((address) => ({
          id: address.id,
          label: address.label,
          coordinates: { lng: parseFloat(address.longitude), lat: parseFloat(address.latitude) },
        })),
      });
    }
  }

  @autobind
  _handleOnChangeField(value, name) {
    if (name === 'address') {
      this.setState({ location: { ...this.state.location, coordinates: value.location, ...value } });
    }
    else {
      this.setState({ location: { ...this.state.location, [name]: value } });
    }
  }

  @autobind
  _handleClickAdd() {
    const { location, pois } = this.state;
    const newPois = [ ...pois, location ];
    this.setState({ pois: newPois, location: {} }, () => this._handleResizeMap(newPois));
  }

  @autobind
  _handleMapLoad(map) {
    const { pois } = this.state;
    this.map = map;
    this.forceUpdate();
    this._handleResizeMap(pois);
  }

  @autobind
  _handleResizeMap(pois) {
    const { estate } = this.props;
    const projectCoordinates = [ parseFloat(estate.address.longitude), parseFloat(estate.address.latitude) ];
    const bounds = new LngLatBounds(projectCoordinates, projectCoordinates);
    if (pois.length === 0) {
      this.map.flyTo({ center: projectCoordinates });
    }
    else {
      const coordinatesToFit = pois.reduce((coords, poi) => coords.extend([ poi.coordinates.lng, poi.coordinates.lat ]), bounds);
      this.forceUpdate();
      this.map.fitBounds(coordinatesToFit, { padding: { top: 80, bottom: 80, left: 80, right: 80 } });
    }

    this._syncState();
  }

  @autobind
  _handleDeletePoi(index) {
    const { pois } = this.state;
    const newPois = pois.filter((p, i) => i !== index);
    this.setState({ pois: newPois, popupOpen: null }, () => this._handleResizeMap(newPois));
  }

  @autobind
  _syncState() {
    const { pois } = this.state;
    const { modifyAnswer, resultKey } = this.props;
    const addresses = pois.map((poi) => {
      const { lng, lat } = poi.coordinates;
      return omit({ ...poi, longitude: lng.toString(), latitude: lat.toString() }, ['address', 'coordinates', 'location']);
    });
    modifyAnswer(resultKey, addresses);
  }

  @autobind
  _getExistingPois() {
    const { estate } = this.props;
    return estate.items.reduce((items, item) => item.details?.pois?.length > 0 ?
      [ ...items, { reference: item.reference, service: item.service.id, pois: item.details.pois } ] : items, []);
  }

  @autobind
  _handlePickExistingPois(pois) {
    this.setState({ pois: pois.map((poi) => ({
      id: poi.id,
      label: poi.label,
      coordinates: { lng: parseFloat(poi.longitude), lat: parseFloat(poi.latitude) },
    })), location: {} }, () => this._handleResizeMap(this.state.pois));
  }
}


export default MapSearch;
