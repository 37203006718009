import React, { Fragment } from 'react';
import {
  Tooltip,
  showTooltip,
} from 'react-ittsu/components';
import { Slider as IttsuSlider } from 'react-ittsu/forms';
import { css, cx } from 'emotion';
import sv from '@drawbotics/style-vars';
import autobind from 'autobind-decorator';


const styles = {
  sliderWrapper: css`
    display: flex;
    align-items: center;
  `,
  slider: css`
    margin: 0 ${sv.baseMarginSmall};
    margin-bottom: ${sv.baseMargin};
    margin-top: ${sv.baseMarginSmall};
    flex: 1;
    position: relative;

    & .rc-slider-handle {
      background: ${sv.white};
      box-shadow: ${sv.elevation2};
      height: 25px;
      width: 25px;
      transform: translateY(-50%) translateX(-50%) !important;
    }
  `,
  disabled: css`
    opacity: 0.5;
    cursor: not-allowed;

    & .rc-slider-disabled {
      background-color: initial;
    }
  `,
  value: css`
    color: ${sv.brandPrimary};
    font-size: 1.3rem;
  `,
  values: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  `,
  label: css`
    color: ${sv.textTertiaryDark};
    font-size: 0.9em;
  `,
  poi: css`
    position: absolute;
    font-size: 0.85em;
    color: ${sv.textSecondaryDark};
    transform: translateX(calc(-50% - 5px));
    display: inline-flex;
    align-items: center;
    margin-bottom: 3px;
    white-space: nowrap;
    bottom: calc((${sv.baseMargin} + 5px) * -1);

    &::after {
      content: ' ';
      position: absolute;
      top: -25px;
      width: 1px;
      height: 23px;
      background: ${sv.grey400};
      right: calc(50% + 3px);
    }

    > [data-element="tooltip"] {
      margin-left: 5px;
    }
  `,
  withOffset: css`
    bottom: calc((${sv.baseMargin} + 20px) * -1);

    &::after {
      height: 33px;
      top: -36px;
    }
  `,
}


class Slider extends React.Component {
  state = {
    tooltipStates: {},
  }

  render() {
    const { tooltipStates } = this.state;
    const { value, min, max, formatDisplayValue=x=>x, pois=[], disabled } = this.props;
    return (
      <div className={styles.sliderWrapper}>
        <div className={cx(styles.slider, { [styles.disabled]: disabled })}>
          {/* {do{
            if (recommended) {
              <div className={styles.recommended} style={{ marginLeft: `${(recommended / max) * 100}%` }}>
                {`${formatDisplayValue(recommended)} - Recommended`}
              </div>
            }
          }} */}
          {pois.map((poi, i) => (
            <div key={i} className={cx(styles.poi, { [styles.withOffset]: poi.offset })} style={{ marginLeft: `${((poi.value - min) / (max - min)) * 100}%` }}>
              {poi.label}
              {do{
                if (poi.tooltip) {
                  <Fragment>
                    <div className={styles.tooltip} data-element="tooltip">
                      <i
                        className="ion-help-circled"
                        onMouseEnter={(e) => this._handleShowTooltip(e, i)}
                        onMouseLeave={this._handleHideTooltip} />
                    </div>
                    <Tooltip
                      label="tooltipState"
                      text={true}
                      visible={tooltipStates[i]?.visible}
                      parentRect={tooltipStates[i]?.parentRect}>
                      {poi.tooltip}
                    </Tooltip>
                  </Fragment>
                }
              }}
            </div>
          ))}
          <div className={styles.values}>
            <div className={styles.label}>
              {formatDisplayValue(min)}
            </div>
            <div className={styles.label}>
              {formatDisplayValue(max)}
            </div>
          </div>
          <IttsuSlider {...this.props} disabled={disabled} />
        </div>
        <div className={styles.value}>
          {formatDisplayValue(value)}
        </div>
      </div>
    );
  }

  @autobind
  _handleShowTooltip(e, i) {
    const { tooltipStates } = this.state;
    this.setState({ tooltipStates: { [i]: showTooltip(tooltipStates[i], e) } });
  }

  @autobind
  _handleHideTooltip() {
    // const { tooltipStates } = this.state;
    this.setState({ tooltipStates: {} });
  }
}


export default Slider;
