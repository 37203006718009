import React from 'react';
import Dimensions from 'react-dimensions';

import Preview from './Preview';
import Annotation from './Annotation';

import styles from 'pods/studio/styles/components/preview-wrapper';
import notFound from 'images/404.svg';


const calcPreviewSize = (image, containerHeight, containerWidth) => {
  if ( ! image) {return { width: 0, height: 0 }}
  const previewRatio = image.width / image.height;
  const studioRatio = containerWidth / containerHeight;
  const crossRatio = studioRatio / previewRatio;  // > 1: studio is wider, < 1: preview is wider
  const width = crossRatio > 1 ? containerHeight * previewRatio : containerWidth;
  const height = crossRatio > 1 ? containerHeight : containerWidth / previewRatio;
  return { width, height };
};


class PreviewWrapper extends React.Component {
  state = {
    image: null,
    isLoading: true,
    notFound: false,
  };

  componentDidMount() {
    const { preview } = this.props;
    const image = new Image();
    image.src = preview.url;
    image.onload = () => {
      this.setState({ image, isLoading: false });
    };
    image.onerror = () => {
      image.src = notFound;
      this.setState({ notFound: true });
    };
  }

  render() {
    const {
      user,
      preview,
      isFullScreen,
      containerWidth,
      containerHeight,
      activeAnnotation,
      disabledAnnotationSaveButton,
      createAnnotation,
      toggleFullScreen,
      openAnnotation,
      closeAnnotation,
      updateAnnotation,
      deleteAnnotation,
      saveAnnotation,
      createAttachment,
      deleteAttachment,
      solveProblem,
    } = this.props;
    const { isLoading, image, notFound } = this.state;
    if (isLoading) {return null}
    const { width, height } = calcPreviewSize(image, containerHeight, containerWidth);
    const displayData = {
      width,
      height,
      containerWidth,
      containerHeight,
      imageObject: image,
    };
    const annotation = preview.annotations.find(a => a.id === activeAnnotation);
    return (
      <div
        className={styles.previewWrapper}
        style={{ position: 'relative' }}>
        <a className={styles.downloadButton} target="_blank" href={preview.url} rel="noopener noreferrer">
          <i className="dbi dbi-download" />
        </a>
        <Preview
          user={user}
          preview={preview}
          isFullScreen={isFullScreen}
          containerWidth={containerWidth}
          containerHeight={containerHeight}
          width={width}
          height={height}
          notFound={notFound}
          imageUrl={image.src}
          activeAnnotation={activeAnnotation}
          onClickPreview={createAnnotation}
          onClickFullScreenToggler={toggleFullScreen}
          onClickDot={openAnnotation} />
        {do {
          if (annotation) {
            <Annotation
              user={user}
              displayData={displayData}
              annotation={annotation}
              disabledAnnotationSaveButton={disabledAnnotationSaveButton}
              isFullScreen={isFullScreen}
              onClickClose={closeAnnotation}
              onChangeAnnotation={updateAnnotation}
              onClickDelete={deleteAnnotation}
              onClickSave={saveAnnotation}
              onCreateAttachment={createAttachment}
              onDeleteAttachment={deleteAttachment}
              saveAnnotation={saveAnnotation}
              updateAnnotation={updateAnnotation}
              solveProblem={solveProblem} />
          }
        }}
      </div>
    );
  }
}


export default Dimensions()(PreviewWrapper);
