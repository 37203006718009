import gql from 'graphql-tag';

export const UpdateTeammate = gql`
  mutation UpdateTeammate($user: UpdateUserInput!) {
    updateUser(input: $user) {
      user {
        id
        role
      }
    }
  }
`;
