import React from 'react';
import gql from 'fraql';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';

import Title from '../components/Title';
import Subtitle from '../components/Subtitle';
import ServiceModal from '../components/ServiceModal';
import ServicesPicker from '../components/ServicesPicker';
import MainPanel from '../components/MainPanel';
import ErrorMessage from '../components/ErrorMessage';
import { createTranslate } from '~/utils/translation';


const tt = createTranslate('pods.order.routes.estate_services');


export class EstateServices extends React.Component {

  static propTypes = {
    estate: PropTypes.object.isRequired,
    services: PropTypes.array.isRequired,
  };

  static fragments = {
    Service: () => gql`
      fragment _ on ProductService {
        id @client(type: ProductService)
        slug
        category {
          slug
        }
        abstract
        availableFor
        products {
          slug
          id @client(type: Product)
          service {
            id @client(type: ProductService)
          }
        }
        ${ServicesPicker.fragments.Service}
      }
    `,
    Item: () => gql`
      fragment _ on Item {
        id
        price @client(type: Item)
        product {
          id @client(type: Product)
          slug
          service {
            ${EstateServices.fragments.Service()}
          }
        }
        service {
          ${EstateServices.fragments.Service()}
        }
        estate {
          id
          propertyType
        }
        ${ServicesPicker.fragments.Item}
        ${ServiceModal.fragments.Item}
      }
    `,
    Items: () => gql`
      fragment _ on Estate {
        id
        isExistingProperty @client(type: Estate)
        items {
          ${EstateServices.fragments.Item()}
        }
      }
    `,
  };

  state = {
    showError: false,
    activeService: null,
  };

  render() {
    const { estate, goBack, services, isActive } = this.props;
    const { showError, activeService } = this.state;

    // Commercial floorplan should be the last service
    const sortedServices = services.sort((a, b) => a.id.localeCompare(b.id));
    const floorplan = sortedServices.filter((service) => service.id === 'commercialFloorplan');
    const servicesWithoutFloorplan = sortedServices.filter((service) => service.id !== 'commercialFloorplan');
    const orderedServices = [...servicesWithoutFloorplan, ...floorplan];

    const { title, subtitle } = this.getTitleAndSubtitle();

    return (
      <MainPanel
        onClickBack={goBack}
        onClickNext={isActive ? this._goNext : null}>
        <Title>
          {title}
        </Title>
        <Subtitle>
          {subtitle}
        </Subtitle>
        {do{
          if (showError) {
            <ErrorMessage>
              {tt('errors.select_service')}
            </ErrorMessage>
          }
        }}
        <ServicesPicker
          active={isActive}
          items={estate.items}
          services={orderedServices}
          onClickService={(activeService) => this.setState({ activeService })} />
        <ServiceModal
          isVisible={activeService != null}
          service={activeService}
          estate={estate}
          onModifyService={this._handleModifyService}
          onClickClose={() => this.setState({ activeService: null })} />
      </MainPanel>
    );
  }

  @autobind
  async _handleModifyService(changes) {
    const { modifyService } = this.props;
    this.setState({ showError: false, activeService: null });
    modifyService(changes);
  }

  @autobind
  _goNext() {
    const { goNext, estate, category } = this.props;
    const lastCategory = estate.isExistingProperty ? 'visualization' : 'communication';

    if (category === lastCategory && estate.items.length === 0) {
      return this.setState({ showError: true });
    }

    return goNext();
  }

  getTitleAndSubtitle() {
    const { category } = this.props;

    return {
      title: tt(category),
      subtitle: tt(`${category}_subtitle`)
    }
  }

}


const mapStateToProps = (state, ownProps) => ({
  isActive: ! state.order.ui.isLoading,
});


export default compose(
  connect(mapStateToProps, null),
)(EstateServices);
