import React, { Fragment } from 'react';
import { Icon, Link } from 'react-ittsu/components';
import autobind from 'autobind-decorator';
import { css, cx, keyframes } from 'emotion';
import sv from '@drawbotics/style-vars';

import ImageViewer from './ImageViewer';
import WarningMessage from './WarningMessage';
import Tooltip from '~/components/Tooltip';
import { createTranslate } from '~/utils/translation';


const t = createTranslate('pods.order.services');


const cellPadding = '11px';
const iconSize = '23px';

const slideUp = keyframes`
  0% {
    transform: translateY(10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;


const slideUpDisabled = keyframes`
  0% {
    transform: translateY(10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 0.4;
  }
`;


const styles = {
  answerCellWrapper: css`
    position: relative;

    &:hover {
      [data-element="tooltip"] {
        opacity: 1;
        transform: translate(-50%, 10px);
      }
    }
  `,
  answerCell: css`
    position: relative;
    box-shadow: ${sv.elevation1};
    background: ${sv.white};
    padding: ${cellPadding};
    transition: all ${sv.baseTransitionTimeShort} ease-in-out;
    border-radius: ${sv.baseBorderRadius};
    animation: ${slideUp} ${sv.baseTransitionTime} forwards;
    opacity: 0;
    transform: translateY(10%);
    height: 100%;

    &:hover {
      cursor: pointer;
      box-shadow: ${sv.elevation2};
    }
  `,
  unclickable: css`
    &:hover {
      cursor: pointer;
    }
  `,
  content: css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  selection: css`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    border: 3px solid ${sv.brandGreen};
    border-radius: ${sv.baseBorderRadius};
    opacity: 1;
    transition: all ${sv.baseTransitionTimeShort} ease-in-out;
  `,
  hiddenSelection: css`
    border-width: 0;
    opacity: 0;
  `,
  negativeSelection: css`
    border-color: ${sv.brandRed};

    & [data-element="icon"] {
      background: ${sv.brandRed};
    }
  `,
  icon: css`
    position: absolute;
    top: calc(${iconSize} / -1.6);
    right: calc(${iconSize} / -1.6);
    height: ${iconSize};
    width: ${iconSize};
    border-radius: 100%;
    background: ${sv.brandGreen};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${sv.white};
    font-size: 0.8em;
    transform: scale(0) translate3d(0,0,0);
    transition: all ${sv.baseTransitionTimeShort} ${sv.bouncyAnimationCurve};
  `,
  visibleIcon: css`
    transform: scale(1) translate3d(0,0,0);
  `,
  label: css`
    padding: ${sv.basePaddingSmall};
    padding-bottom: calc(${sv.basePaddingSmall} / 2);
    color: ${sv.textPrimaryDark};
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    font-size: 0.85em;
    font-family: ${sv.baseFontFamilyAlt};
  `,
  description: css`
    text-align: center;
    color: ${sv.textPrimaryDark};
    font-size: 0.8rem;
    margin: auto;
    line-height: 1.2rem;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 70%;

    @media ${sv.phoneXl} {
      display: none;
    }
  `,
  extraAction: css`
    position: absolute;
    bottom: 0;
    right: 0;
    width: calc(1.5 * ${sv.baseMargin});
    height: calc(1.5 * ${sv.baseMargin});
    background: ${sv.white};
    color: ${sv.textPrimaryDark};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.7em;
  `,
  disabledCell: css`
    animation-name: ${slideUpDisabled};
    cursor: not-allowed !important;

    &:hover {
      box-shadow: ${sv.elevation1};
    }

    > * {
      opacity: 0.4;
      cursor: not-allowed;
      pointer-events: none;
    }
  `,
};


const selectionClassName = (selected, negSelected) => cx(styles.selection, {
  [styles.hiddenSelection]: ! selected && ! negSelected,
  [styles.negativeSelection]: negSelected,
});


const BorderSelection = ({ selected, negSelected, asVote }) => {
  const icon = negSelected ? 'ion-thumbsdown' : 'ion-thumbsup';
  return (
    <div className={selectionClassName(selected, negSelected)}>
      <div className={cx(styles.icon, { [styles.visibleIcon]: selected || negSelected })} data-element="icon">
        {do{
          if (asVote) {
            <i className={icon} />
          }
          else {
            <Icon name="check-bold" />
          }
        }}
      </div>
    </div>
  );
}


class AnswerCell extends React.Component {
  state = {
  }

  render() {
    const {
      children,
      asVote,
      selected,
      negSelected,
      position,
      answer,
    } = this.props;
    const { viewerVisible, warningVisible } = this.state;
    const { label, zoom, link, warning, description, disabled=false, disabledText } = answer;
    return (
      <div className={styles.answerCellWrapper}>
        <div
          id="answerCell"
          className={cx(styles.answerCell, {
            [styles.unclickable]: asVote,
            [styles.disabledCell]: disabled,
          })}
          onClick={disabled ? null : this._handleClickCell}
          style={{ animationDelay: `${(position / 10) + 0.3}s` }}>
          <BorderSelection selected={selected} negSelected={negSelected} asVote={asVote} />
          <div className={styles.content}>
            {children}
            {do{
              if (zoom) {
                <Fragment>
                  <div className={styles.extraAction} onClick={this._handleClickZoom}>
                    <i className="ion-ios-search-strong" />
                  </div>
                  <ImageViewer visible={viewerVisible} src={zoom} onClickClose={this._handleCloseViewer} />
                </Fragment>
              }
              else if (link) {
                <div className={styles.extraAction} onClick={(e) => e.stopPropagation()}>
                  <Link href={link} underlined="never" target="_blank">
                    <i className="ion-link" />
                  </Link>
                </div>
              }
            }}
          </div>
          {do{
            if (label) {
              <div className={styles.label} id="label">
                {t(label, null, label)}
              </div>
            }
          }}
          {do{
            if (description) {
              <div className={styles.description} id="description">
                {t(description, null, description)}
              </div>
            }
          }}
          {do{
            if (warning) {
              <WarningMessage visible={warningVisible} onClickClose={this._handleCloseWarning} message={t(warning, null, warning)} />
            }
          }}
        </div>
        {do{
          if (disabled) {
            <Tooltip>{t(disabledText, null, disabledText)}</Tooltip>
          }
        }}
      </div>
    );
  }

  @autobind
  _handleClickZoom(e) {
    e.stopPropagation();
    this.setState({ viewerVisible: true });
  }

  @autobind
  _handleCloseViewer(e) {
    e.stopPropagation();
    this.setState({ viewerVisible: false });
  }

  @autobind
  _handleClickCell() {
    const { asVote, onClick, answer, selected } = this.props;
    if (! asVote) {
      if (answer.warning && ! selected) {
        this.setState({ warningVisible: true });
      }
      onClick();
    }
  }

  @autobind
  _handleCloseWarning(e) {
    e.stopPropagation();
    this.setState({ warningVisible: false });
  }
}


export default AnswerCell;
