import Rx from 'rxjs-legacy';
import Stripe from 'stripe';


function init(data) {
  return (next, error, complete) => {
    Stripe.card.createToken(data, (status, response) => {
      if (response.error) {
        error(response.error);
      }

      complete(response);
    });
  };
}


function initRx(data) {
  const normalInit = init(data);
  return Rx.Observable.create((observer) => normalInit(
    observer.next.bind(observer),
    observer.error.bind(observer),
    observer.next.bind(observer),
  ));
}


function initP(data) {
  return new Promise((resolve, reject) => {
    init(data)(null, reject, resolve);
  });
}


export default {
  init,
  initRx,
  initP,
};
