import morphey from 'morphey';


const translationsFromServer = {
  status: 'status',
  message: 'message',
};


export function chargeFromServer(charge) {
  return morphey(charge, translationsFromServer);
}


const translationsToServer = {
  stripe_hash: 'source',
};


export function charteToServer(charge) {
  return morphey(charge, translationsToServer);
}


export default {
  fromServer: chargeFromServer,
  toServer: charteToServer,
};
