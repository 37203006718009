import React from 'react';
import cn from 'classnames';
import styles from 'pods/studio/styles/components/annotation';


const Attachment = ({
  filename,
  onClickDelete,
}) => (
  <div className={styles.attachment}>
    <div className={styles.attachmentData}>
      <span className={styles.attachmentName}>{filename}</span>
      <i
        className={cn('fa fa-fw fa-times', styles.attachmentButton)}
        onClick={onClickDelete} />
    </div>
  </div>
);


const Upload = ({
  filename,
  progress,
}) => (
  <div className={styles.attachment} >
    <div className={styles.attachmentData} >
      <span className={styles.attachmentName}>{filename}</span>
      <span className={styles.attachmentButton}>{progress + '%'}</span>
      <div className={styles.progressBar} style={{ width: progress + '%' }}/>
    </div>
  </div>
);


const Attachments = ({
  attachments,
  uploadStatus,
  onClickDelete,
}) => (
  <div>
    {uploadStatus.filename &&
      <Upload
        filename={uploadStatus.filename}
        progress={uploadStatus.progress} />
    }
    {attachments.sort((a1, a2) => a1.id - a2.id).filter(a => ! a.toDelete).map((a, i) => (
      <Attachment
        key={i}
        filename={a.filename}
        onClickDelete={() => onClickDelete(a.id)} />
    ))}
  </div>
);


export default Attachments;
