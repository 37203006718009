import * as InvitationActions from 'actions/invitation';
import { SIGN_UP_SUCCESS } from 'actions/session';


export default function invitation(state={}, action) {
  switch (action.type) {
    case InvitationActions.LOAD_INVITED_USER_SUCCESS: {
      const { user } = action.payload;
      return user;
    }
    case SIGN_UP_SUCCESS: {
      return {};
    }
    default: {
      return state;
    }
  }
}
