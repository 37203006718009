import runCreate from './run-create';
import runUpdate from './run-update';

/**
 * Create operation:
 *
 * {
 *   operation: 'create',
 *   type: 'bool' || 'number',
 *   create: {
 *     product: 'product_name',
 *     [with]: {
 *       detailKey: 'detailValue',
 *     },
 *   },
 * }
 *
 *
 * Update operation:
 *
 * {
 *   operation: 'update',
 *   type: 'mapTo' || 'values' || 'match',
 *   update: {
 *     target: 'all' || 'first',
 *     [mapTo]: [ 'detailKey' ],
 *     [values]: { detailKey: 'detailValue' },
 *     [match]: { specValue: { detailKey: 'detailValue' } },
 *   },
 * }
 *
 *
 * The different ways of using the update operation:
 *  - With "mapTo", where the details values are mapped directly to the value of the spec
 *  - With a constant "values", where if the spec value is truthy, the values will be applied
 *    directly and if it's falsy, the keys will be removed. E.g:
 *
 *    values: {
 *      someConstantValue: 'Hello',
 *    }
 *
 *  - With a match operation where the serialized value of the spec will decide which values
 *    would be applied. E.g:
 *
 *    match: {
 *      mySpecTrue: {
 *        someValue: 'Hello',
 *      },
 *      mySpecFalse: {
 *        someValue: 'False',
 *      },
 *    }
 *
 *  - NOT RECOMMENDED: With a "values" function where the first argument would be the spec
 *    values and the return would be the values. E.g:
 *
 *    values: (input) => ({ someValue: input.slice(2, 5) })
*/

function _verifyAllSpecs(description, specs) {
  Object.keys(description).forEach((key) => {
    if (specs[key] == null) {
      throw new Error(`The spec keys do not match the description keys. Key not found: ${key}`);
    }
  });
}

export function runDescription(description, { specs, service, estate }) {
  const initialValues = {
    add: [],
    remove: [],
    update: [],
  };

  _verifyAllSpecs(description, specs);

  const operations = Object.keys(specs).map((specKey) => {
    const descriptionValue = description[specKey];
    if (descriptionValue == null) {
      throw new Error(`Unknown spec key "${specKey}" for "${service.id}"`);
    }
    return { operation: descriptionValue.operation, specKey };
  });

  return operations
    .slice()
    .sort((a, b) => a.operation === 'create' ? -1 : 1)
    .map((operation) => operation.specKey)
    .reduce((memo, specKey) => {
      const descriptionValue = description[specKey];
      const specValue = specs[specKey];
      switch (descriptionValue.operation) {
        case 'create':
          return runCreate(memo, descriptionValue, specValue, { specs, service, estate });
        case 'update':
          return runUpdate(memo, descriptionValue, specValue, { specs, service, estate });
        default:
          throw new Error(`Uknown operation ${descriptionValue.operation}`);
      }
    }, initialValues);
}
