import React from 'react';
import { Icon } from 'react-ittsu/components';
import { css, cx, keyframes } from 'emotion';
import sv from '@drawbotics/style-vars';


const animation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;


const styles = {
  scrollIndicator: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    i {
      transition: transform ${sv.baseTransitionTimeShort} ease-in-out;
    }
  `,
  reversed: css`
    i {
      transform: rotate(180deg);
    }
  `,
  arrow: css`
    margin-bottom: calc(${sv.baseMarginSmall} * -1);
    font-size: 1.3rem;
    color: ${sv.brandPrimary};
    animation: 0.7s alternate infinite ${animation};

    &:last-child {
      animation-delay: 0.2s;
    }
  `,
};


const ScrollIndicator = ({ reversed }) => {
  return (
    <div className={cx(styles.scrollIndicator, { [styles.reversed]: reversed })}>
      <div className={styles.arrow}>
        <Icon name="caret-down-bold" bold />
      </div>
      <div className={styles.arrow}>
        <Icon name="caret-down-bold" bold />
      </div>
    </div>
  );
};


export default ScrollIndicator;
