import {
  ButtonLink,
  Category,
  Color,
  EmptyState,
  EmptyStateVariation,
  Icon,
  ListTile,
  ShowDateTime,
  Size,
  Table,
  Tag,
  Text,
  Tier,
} from '@drawbotics/react-drylus';
import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';

import { PaymentDocumentStatus, PaymentProcess } from '~/pods/dashboard/types';
import { run } from '~/utils';
import { createTranslate, translate as t } from '~/utils/translation';

import { PaymentDocumentInfo } from './PaymentDocumentInfo';

const tt = createTranslate('pods.dashboard.routes.billing');

interface PaymentsTableProps {
  isLoading: boolean;
  paymentProcess?: PaymentProcess;
}

export const PaymentsTable = ({ isLoading, paymentProcess }: PaymentsTableProps) => {
  const invoices =
    paymentProcess?.invoices
      .filter((invoice) => invoice.status !== PaymentDocumentStatus.NOT_DUE)
      .sort((a, b) => (dayjs(a.sentAt).isBefore(dayjs(b.sentAt)) ? 1 : -1)) ?? [];
  const invoicesAndSalesOrder =
    paymentProcess?.salesOrder != null ? [paymentProcess.salesOrder, ...invoices] : invoices;

  const data = invoicesAndSalesOrder.map((paymentDocument, i) => ({
    id: paymentDocument.id,
    type: i === 0 && paymentProcess?.salesOrder != null ? tt('sales_order') : tt('invoice'),
    date: (
      <Text dateOptions={{ showTime: ShowDateTime.NEVER }}>{new Date(paymentDocument.sentAt)}</Text>
    ),
    status: (
      <ListTile
        title={
          <Tag
            color={run(() => {
              if (
                paymentDocument.status === PaymentDocumentStatus.SIGNED ||
                paymentDocument.status === PaymentDocumentStatus.PAID
              ) {
                return Color.GREEN;
              } else if (paymentDocument.status === PaymentDocumentStatus.LATE) {
                return Color.RED;
              }
            })}>
            {t(`pods.dashboard.components.estate_payments.status.${paymentDocument.status}`)}
          </Tag>
        }
      />
    ),
    total: <Text>{{ value: paymentDocument.amount, currency: paymentDocument.currency }}</Text>,
    actions:
      paymentDocument.status === PaymentDocumentStatus.PAID || i === 0 ? (
        <a href={paymentDocument.url} download target="_blank" rel="noopener noreferrer">
          <ButtonLink size={Size.SMALL} tier={Tier.SECONDARY} leading={<Icon name="download" />}>
            {t(`pods.dashboard.components.estate_payments.download_invoice`)}
          </ButtonLink>
        </a>
      ) : (
        <Link to={`/order/transaction/${paymentDocument.debitTransaction.accessToken}/settle`}>
          <ButtonLink size={Size.SMALL} category={Category.DANGER} leading={<Icon name="check" />}>
            {t(`pods.dashboard.components.estate_payments.settle_invoice`)}
          </ButtonLink>
        </Link>
      ),
    data:
      i > 0
        ? [
            {
              type: <PaymentDocumentInfo paymentDocument={paymentDocument} />,
            },
          ]
        : undefined,
  }));

  return (
    <Table
      data={data}
      isLoading={isLoading}
      header={[
        {
          value: 'type',
          label: tt('type'),
        },
        {
          value: 'date',
          label: tt('date'),
        },
        {
          value: 'status',
          label: tt('status'),
        },
        {
          value: 'total',
          label: tt('total'),
        },
        {
          value: 'actions',
          label: tt('actions'),
        },
      ]}
      emptyContent={run(() => {
        if (!isLoading && paymentProcess == null) {
          return (
            <EmptyState description={tt('could_not_load')} variation={EmptyStateVariation.FAILED} />
          );
        } else if (!isLoading && invoicesAndSalesOrder.length === 0) {
          return <EmptyState description={tt('no_actions')} />;
        }
      })}
    />
  );
};
