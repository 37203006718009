/*
 * 500 / Critical Error
 */

export const TRIGGER_CRITICAL = 'TRIGGER_CRITICAL';


export function triggerCritical() {
  return { type: TRIGGER_CRITICAL };
}


/*
 * 404 / Not Found
 */

export const TRIGGER_NOT_FOUND = 'TRIGGER_NOT_FOUND';


export function triggerNotFound() {
  return { type: TRIGGER_NOT_FOUND };
}
