import {
  Button,
  Category,
  Flex,
  FlexItem,
  FlexJustify,
  Icon,
  Margin,
  Padding,
  Panel,
  PanelBody,
  PanelHeader,
  Size,
  Title,
} from '@drawbotics/react-drylus';
import React, { useState } from 'react';

import { OrganizationRole } from '~/types/user';
import { createTranslate } from '~/utils/translation';
import { useUser } from '~/utils/use-user';

import { EstateHeader, EstateLayout } from '../../components';
import { useFetchBasicEstate, useFetchEstateFollowers } from '../../utils';
import { AddUsersModal, InfoBanner, UsersTable } from './components';

const tt = createTranslate('pods.dashboard.routes.notifications');

interface NotificationsProps {
  estateId: string;
  triggerNotFound: VoidFunction;
  triggerCritical: VoidFunction;
}

export const Notifications = ({ estateId }: NotificationsProps) => {
  const [isUsersModalOpen, setIsUsersModalOpen] = useState(false);
  const { estate } = useFetchBasicEstate(estateId);
  const {
    isLoading: isEstateFollowersLoading,
    followers = [],
    refetch,
    error: isEstateFollowersError,
  } = useFetchEstateFollowers(estateId);
  const { user } = useUser();

  const isUserMember = user?.role === OrganizationRole.MEMBER;

  return (
    <EstateLayout header={<EstateHeader estateId={estateId} />}>
      <Padding size={{ vertical: Size.EXTRA_LARGE, horizontal: Size.LARGE }}>
        <Margin size={{ bottom: Size.DEFAULT }}>
          <InfoBanner />
        </Margin>
        <Panel
          header={
            <PanelHeader>
              <Flex justify={FlexJustify.SPACE_BETWEEN}>
                <FlexItem>
                  <Title size={4} noMargin>
                    {tt('notified_people')}
                  </Title>
                </FlexItem>
                <FlexItem>
                  <Button
                    category={Category.BRAND}
                    disabled={isUserMember}
                    leading={<Icon name="plus" />}
                    onClick={() => setIsUsersModalOpen(true)}>
                    {tt('add_people')}
                  </Button>
                </FlexItem>
              </Flex>
            </PanelHeader>
          }
          body={
            <PanelBody noPadding>
              <UsersTable
                itemIds={estate?.items.map((item) => item.id)}
                refetchUsers={refetch}
                isLoading={isEstateFollowersLoading}
                error={isEstateFollowersError}
                users={followers}
                readOnly={isUserMember}
              />
            </PanelBody>
          }
        />
      </Padding>
      <AddUsersModal
        existingUserIds={followers.map((user) => user.id)}
        estateId={estateId}
        itemIds={estate?.items.map((item) => item.id)}
        visible={isUsersModalOpen}
        refetchUsers={refetch}
        onClickClose={() => setIsUsersModalOpen(false)}
      />
    </EstateLayout>
  );
};
