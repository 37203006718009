import React from 'react';
import { css, cx } from 'emotion';
import sv from '@drawbotics/style-vars';
import autobind from 'autobind-decorator';
import { Icon } from 'react-ittsu/components';
import gql from 'fraql';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';


import FileUploadOverlay from '../../components/FileUploadOverlay';
import AttachmentBox from '../../components/AttachmentBox';
import { createTranslate } from '~/utils/translation';
import { showAlert } from '~/actions';

import uploadFile from '~/images/upload-file.svg';


const tt = createTranslate('pods.order.routes.briefing_estate');


const styles = {
  attachments: css`
  `,
  dropZone: css`
    position: relative;
    margin-bottom: ${sv.baseMarginSmall};
  `,
  uploadBox: css`
    padding: ${sv.basePaddingSmall};
    padding-bottom: ${sv.basePadding};
    box-shadow: 0 0 0 1px ${sv.grey300} inset;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: ${sv.baseBorderRadius};
    transition: all ${sv.baseTransitionTimeShort} ease-in-out;

    &:hover {
      cursor: pointer;
      border-color: ${sv.grey400};
      background: ${sv.grey50};
    }
  `,
  uploadBoxActive: css`
    border-color: ${sv.grey400};
    background: ${sv.grey50};
  `,
  disabled: css`
    pointer-events: none;
    opacity: 0.5;
  `,
  uploadIcon: css`
    width: 70px;
    margin-bottom: 10px;
  `,
  uploadLabel: css`
    color: ${sv.textSecondaryDark};
  `,
  file: css`
    margin-top: ${sv.baseMarginSmall};
  `,
  label: css`
    color: ${sv.textPrimaryDark};

    & > i {
      font-size: 1.1rem;
    }

    & > span {
      margin-left: 5px;
      font-size: 0.8rem;
    }
  `,
};


class Attachments extends React.Component {
  state = {
    tempFiles: {},
    uploading: 0,
    toUpload: [],
    uploadBoxActive: false,
  }

  static fragments = {
    Estate: gql`
      fragment _ on Estate {
        id
        attachments {
          id
          filename
          url
        }
      }
    `,
  }

  render() {
    const { attachments, uploadHidden } = this.props;
    const { tempFiles, uploadBoxActive } = this.state;
    
    return (
      <div className={styles.attachments}>
        {do{
          if (! uploadHidden) {
            <div className={cx(styles.dropZone, { [styles.disabled]: ! isEmpty(tempFiles) } )}>
              <FileUploadOverlay
                component={
                  <div className={cx(styles.uploadBox, {[styles.uploadBoxActive]: uploadBoxActive})}>
                    <img src={uploadFile} className={styles.uploadIcon} />
                    <div className={styles.uploadLabel}>{tt('attachments.upload_files')}</div>
                  </div>
                }
                onInit={this._handleOnInit}
                onProgress={this._handleFileUploading}
                onFinish={this._handleFinishUpload}
                onError={this._handleError}
                setDropZoneActive={() => {this.setState({ uploadBoxActive: true })}}
                setDropZoneInactive={() => {this.setState({ uploadBoxActive: false })}} />
            </div>
          }
        }}
        <div className={styles.uploads}>
          <div className={styles.label}>
            <Icon name="paperclip" />
            <span>{`${tt('attachments.title')} (${attachments.length})`}</span>
          </div>
          {Object.keys(tempFiles).map((filename, i) => (
            <div key={i} className={styles.file}>
              <AttachmentBox file={{ filename }} progress={tempFiles[filename]} />
            </div>
          ))}
          {attachments.map((attachment) => (
            <div key={attachment.id} className={styles.file}>
              <AttachmentBox file={attachment} onClickDelete={() => this._handleDeleteAttachment(attachment.id)} />
            </div>
          ))}
        </div>
      </div>
    );
  }

  @autobind
  async _handleDeleteAttachment(id) {
    const { attachments, onChange } = this.props;
    await onChange({ attachments: attachments.filter((a) => a.id !== id).map((file) => omit(file, '__typename')) });
  }

  @autobind
  _handleOnInit(files) {
    const { setIsUploading } = this.props;
    this.setState({ uploading: files.length });
    setIsUploading(true);
  }

  @autobind
  _handleFileUploading(filename, progress) {
    const { tempFiles } = this.state;
    this.setState({ tempFiles: { ...tempFiles, [filename]: progress } });
  }

  @autobind
  async _handleFinishUpload(file) {
    const uploading = this.state.uploading - 1;
    this.setState({ uploading, toUpload: [ ...this.state.toUpload, file] }, () => {
      if (uploading === 0) {
        this._handleUploadAll();
      }
    });
  }

  @autobind
  _handleError(file) {
    const { showAlert } = this.props;
    const { tempFiles } = this.state;

    this.setState({ tempFiles: omit(tempFiles, file.name) });
    showAlert(null, 'error', `${tt('upload_error')} ${file.filename}`);
  }

  @autobind
  async _handleUploadAll() {
    const { toUpload } = this.state;
    const { attachments, onChange, setIsUploading } = this.props;
    await onChange({
      attachments: [
        ...attachments.map((file) => omit(file, ['__typename', 'filename'])),
        ...toUpload.map((file) => omit(file, 'filename')),
      ],
    });
    this.setState({ toUpload: [], tempFiles: {} });
    setIsUploading(false);
  }
}


const mapDispatchToProps = {
  showAlert,
};


export default connect(null, mapDispatchToProps)(Attachments);
