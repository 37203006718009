import React from 'react';
import { Button } from 'react-ittsu/components';
import { Grid, Cell } from 'react-ittsu/layout';
import PropTypes from 'prop-types';
import gql from 'fraql';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';

import BriefingCard from '../components/BriefingCard';
import { getItemsByService } from '../utils';
import Title from '../components/Title';
import Link from '~/components/Link';
import { createTranslate, translate as t } from '~/utils/translation';

import serviceImages from '~/images/services-illustrations';
import estateCover from '~/images/estate.svg';


const tt = createTranslate('pods.order.routes.briefing_overview');


const styles = {
  container: css`
    width: 100%;
    max-width: 1200px;
    flex: 1;
    display: flex;
    margin: auto;
    margin-top: calc(${sv.baseMargin} * 2);
    margin-bottom: ${sv.baseMargin};
    padding: 0 ${sv.basePaddingSmall};

    @media ${sv.ipad} {
      margin-top: ${sv.baseMargin};
    }
  `,
  briefingOverview: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  title: css`
    margin-bottom: ${sv.baseMarginSmall};

    @media ${sv.phone} {
      margin-bottom: 5px;
    }
  `,
  subtitle: css`
    text-align: center;
    color: ${sv.textSecondaryDark};
  `,
  grid: css`
    margin-top: ${sv.baseMargin};
  `,
  backButton: css`
    margin-top: ${sv.baseMargin};
    display: flex;
    align-items: center;
    justify-content: center;
`,
};


class BriefingOverview extends React.Component {

  static propTypes = {
    estate: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      briefingConfirmedAt: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        briefingConfirmedAt: PropTypes.string,
        service: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
      })),
    }),
  };

  static fragments = {
    Estate: () => gql`
      fragment _ on Estate {
        id
        items {
          id
          briefingConfirmedAt
          service {
            id @client(type: ProductService)
            slug
            abstract
          }
          milestones {
            id
            name
          }
          product {
            id @client(type: Product)
            slug
            custom
          }
        }
      }
    `,
  };

  render() {
    const { estate, goNext, goEstate } = this.props;
    const itemsByService = getItemsByService(estate);
    const confirmedItemsCount = estate.items.filter((item) => !! item.briefingConfirmedAt).length;
    const itemsLeftToFill = estate.items.length - confirmedItemsCount;
    const itemsLeftWithEstate = estate.briefingConfirmedAt ? itemsLeftToFill : itemsLeftToFill + 1;
    const briefingCompleted = itemsLeftWithEstate === 0;
    const underReview = !estate.items.some((item) => item.milestones.find((milestone) => milestone.name === 'accepted')) && estate.briefingConfirmedAt != null;
    return (
      <div className={styles.container}>
        <div className={styles.briefingOverview}>
          <Title className={styles.title}>Briefing</Title>
          <div className={styles.subtitle}>
            {!briefingCompleted ? <span>{tt('briefing_instructions')}<br /><br /></span> : null }
            {briefingCompleted ? tt('completed_briefing') : tt('items_to_fill', { count: itemsLeftWithEstate })}
          </div>
          <div className={styles.grid}>
            <Grid halfVGutters halfHGutters>
              <Cell size="2of4" responsive="l2of3,m1of1">
                <BriefingCard
                  confirmed={estate.briefingConfirmedAt != null && !underReview}
                  completed={estate.briefingConfirmedAt != null && underReview}
                  image={estateCover}
                  onClick={(rect) => goEstate({ rect })}
                  horizontal={true}
                  description={tt('general_briefing_description')}
                  title={tt('general_briefing')} />
              </Cell>
              {Object.keys(itemsByService).sort((a, b) => a.localeCompare(b)).map((serviceName) => {
                const items = itemsByService[serviceName];
                const service = items[0].service;
                const underReview = items.some((item) => !item.milestones.find((milestone) => milestone.name === 'accepted') && item.briefingConfirmedAt != null);
                const confirmed = items.every((item) => item.milestones.find((milestone) => milestone.name === 'accepted'));
                return (
                  <Cell key={serviceName} size="1of4" responsive="l1of3,m1of2">
                    <BriefingCard
                      disabled={! estate.briefingConfirmedAt}
                      image={serviceImages[service.id]}
                      title={t(`services.${service.id}`)}
                      confirmed={confirmed}
                      completed={underReview}
                      onClick={(rect) => goNext(serviceName, { rect })} />
                  </Cell>
                );
              })}
            </Grid>
          </div>
          {do{
            if (briefingCompleted) {
              <div className={styles.backButton}>
                <Link href={`/projects/${estate.id}/overview`}>
                  <Button category="primary">
                    {tt('back_to_dashboard')}
                  </Button>
                </Link>
              </div>
            }
          }}
        </div>
      </div>
    );
  }

}


export default BriefingOverview;
