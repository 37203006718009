import compose from 'lodash/flowRight';
import { graphql } from 'react-apollo-redux';

import { withUser } from '~/utils/with-user';
import Component from './Component';
import { CartQuery, DeleteEstateMutation } from './graphql';


export default compose(
  withUser(),
  graphql(CartQuery, {
    name: 'cartData',
  }),
  graphql(DeleteEstateMutation, {
    name: 'deleteEstate',
    options: {
      refetchQueries: ['CartQuery'],
    },
  }),
)(Component);
