import { mergeResolvers } from 'apollo-link-computed';

import { podsResolvers } from 'pods';

import Product from './Product';
import ProductService from './Service';
import Estate from './Estate';


const resolvers = [ ...podsResolvers, { Product, ProductService, Estate }];


export default mergeResolvers(...resolvers);
