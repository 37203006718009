import React from 'react';
import { Button } from 'react-ittsu/components';

import { createTranslate } from 'utils/translation';

import styles from 'pods/studio/styles/components/annotation';


const tt = createTranslate('pods.studio.components.action_bar');


const ActionBar = ({
  change,
  enableSaveButton,
  enableCloseButton,
  onClickClose,
  onClickSave,
}) => {
  return(
    <div className={styles.actionBar}>
      <Button
        size="small"
        category="info"
        disabled={ ! enableSaveButton}
        onClick={onClickSave}>
        {tt('save')}
      </Button>
      <Button
        variation="borderless"
        size="small"
        disabled={ ! enableCloseButton}
        onClick={onClickClose}>
        {change ? tt('cancel') : tt('close')}
      </Button>
    </div>
  );
};


export default ActionBar;
