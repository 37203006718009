const k = (key) => `custom.briefing.${key}`;


const residential = {
  1: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('required_info'),
        component: 'Title',
      }, {
        type: 'component',
        label: k('upload_required'),
        component: 'Note',
      }, {
        type: 'question-component',
        label: k('comments'),
        component: 'Input',
        key: 'description',
        keyLabel: k('keys.description'),
        required: 'all',
      }, {
        type: 'question-component',
        label: k('upload_files'),
        component: 'FileUploader',
        key: 'attachments',
        keyLabel: k('keys.attachments'),
      },
    ],
  },
};


export default { residential };
