import React, { useState } from 'react';
import Modal, { BaseModal } from '@drawbotics/modal';
import { css } from 'emotion';
import {
  Icon,
  T3,
  Button,
  Paragraph,
} from 'react-ittsu/components';
import sv from '@drawbotics/style-vars';

import { createTranslate } from '~/utils/translation';
import Spinner from '~/components/Spinner';


const tt = createTranslate('pods.order.components.confirm_modal');


const styles = {
  confirmModal: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100vw - ${sv.baseMargin});
    max-width: 600px;
    min-width: auto;
    text-align: center;
    color: ${sv.textPrimaryDark};
    margin: ${sv.baseMarginSmall};

    @media ${sv.phone} {
      padding: ${sv.basePadding} ${sv.basePaddingSmall};
    }
  `,
  warningIcon: css`
    font-size: 2.5rem;
    color: ${sv.brandRed};
  `,
  actions: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: ${sv.baseMarginSmall};
  `,
  buttonContent: css`
    display: flex;
    align-items: center;
  `,
};


const ConfirmModal = ({ 
  visible,
  onClickClose,
  onClickConfirm,
  onClickSave,
}) => {
  const [ isSaving, setIsSaving ] = useState(false);
  const [ isConfirming, setIsConfirming ] = useState(false);

  const handleClickSave = async () => {
    setIsSaving(true);
    await onClickSave();
    setIsSaving(false);
  };

  const handleClickConfirm = async () => {
    setIsConfirming(true);
    await onClickConfirm();
    setIsConfirming(false);
  };

  return (
    <Modal isVisible={visible} onClickOverlay={onClickClose}>
      <BaseModal
        className={styles.confirmModal}
        onClickClose={onClickClose}
        closeIcon={<Icon name="close-bold" />}>
        <div className={styles.warningIcon}>
          <i className="ion-alert-circled" />
        </div>
        <T3>{tt('are_you_sure')}</T3>
        <Paragraph>
          {tt('paragraph1')}
        </Paragraph>
        <Paragraph>
          {tt('paragraph2', { version: <b>{tt('final_version')}</b> })}
        </Paragraph>
        <div className={styles.actions}>
          <Button variation="borderless" onClick={handleClickSave}>
            <div className={styles.buttonContent}>
              <span>{tt('save_for_later')}</span>
              {do {
                if (isSaving) {
                  <Spinner size={15} containerStyle={{ marginLeft: 5 }} />
                }
              }}
            </div>
          </Button>
          <Button category="success" round onClick={handleClickConfirm}>
            <div className={styles.buttonContent}>
              <span>{tt('im_sure')}</span>
              {do {
                if (isConfirming) {
                  <Spinner size={15} containerStyle={{ marginLeft: 5 }} inversed />
                }
              }}
            </div>
          </Button>
        </div>
      </BaseModal>
    </Modal>
  );
};


export default ConfirmModal;
