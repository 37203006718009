import React from 'react';
import DrawboticsFileUploader from '@drawbotics/file-uploader';

import { buildUrl } from 'utils'
import { API_HOST } from 'utils/api/utils';


class FileUploader extends React.Component {

  render() {
    const {
      onProgress,
      onFinish,
      onComplete,
      onInit,
      onError,
      multiple=false,
      setDropZoneActive,
      setDropZoneInactive,
      ...rest
    } = this.props;
    const finalUrl = buildUrl(API_HOST, '/rails/active_storage/direct_uploads');
    return (
      <DrawboticsFileUploader
        {...rest}
        multiple={multiple}
        signingUrl={finalUrl}
        onProgress={onProgress}
        onComplete={onComplete}
        onInit={onInit}
        onFinish={onFinish}
        onDragEnter={setDropZoneActive}
        onError={onError}
        onDragLeave={setDropZoneInactive} />
    );
  }

}


export default FileUploader;
