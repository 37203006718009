import gql from 'fraql';

import { camelize } from '~/utils';


function _getServiceIdFromProduct(product) {
  if (product.service?.slug != null) {
    return product.service.slug;
  }
  else {  // try to guess it
    const [ serviceId ] = product.slug.split(/_(?=(standard|premium))/);
    return serviceId;
  }
}


function _getProductId(product) {
  if (product.custom) {
    return product.slug;
  }

  const serviceId = _getServiceIdFromProduct(product);
  const productVariation = product.slug.replace(serviceId, '');
  return (productVariation != null && productVariation !== '')
    ? `${camelize(serviceId)}_${camelize(productVariation.slice(1))}`
    : camelize(serviceId);
}


const Product = {
  typeName: 'Product',
  fields: {
    id: {
      dependencies: gql`
        fragment _ on Product {
          slug
          custom
          service {
            slug
          }
        }
      `,
      resolver: (product) => _getProductId(product),
    },
  },
};


export default Product;