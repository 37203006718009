import React from 'react';
import PropTypes from 'prop-types';
import { css, cx } from 'emotion';
import sv, { darken } from '@drawbotics/style-vars';


export const styles = {
  summaryButton: css`
    display: flex;
    align-items: center;
    white-space: nowrap;
    width: 100%;
    background: ${sv.grey200};
    border-radius: ${sv.baseBorderRadius};
    color: ${sv.textPrimaryDark};
    padding: 7px 10px;
    justify-content: center;
    max-width: calc(100vw - (35px * 2 + ${sv.baseMargin} * 3));

    &::active {
      background: ${sv.grey300};
    }
  `,
  primary: css`
    background: ${sv.brandPrimary};
    color: ${sv.white};

    & [data-element="item-count"] {
      background: ${darken(sv.brandPrimary)};
    }
  `,
  itemsCount: css`
    height: 25px;
    width: 25px;
    min-width: 25px;
    font-size: 0.7rem;
    border-radius: 100000px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${sv.grey300};
    margin-right: 10px;
  `,
  label: css`
    font-size: 0.9rem;
    font-weight: 600;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  amount: css`
    font-size: 0.9rem;
  `,
};


const SummaryButton = ({
  onClick,
  count,
  label,
  amount,
  isPrimary=false,
}) => {
  return (
    <div className={cx(styles.summaryButton, { [styles.primary]: isPrimary })} onClick={onClick}>
      <div className={styles.itemsCount} data-element="item-count">
        {count}
      </div>
      <div className={styles.label}>
        {label}
      </div>
      <div className={styles.amount}>
        {amount}
      </div>
    </div>
  );
};

SummaryButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  isPrimary: PropTypes.bool,
}


export default SummaryButton;
