import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Navbar,
  NavbarIcon,
  NavbarControls,
  NavbarLink,
  NavbarText,
  Panel,
  PanelHeader,
  PanelTitle,
  PanelBody,
  Button,
  Paragraph,
} from 'react-ittsu/components';
import { Main, Header, Container, Footer } from 'react-ittsu/layout';
import { Form, Label, FormGroup, Input } from 'react-ittsu/forms';
import get from 'lodash/get';

import { getSession, getPublicProject } from 'selectors';
import { loadPublicProject, guestSignUp, loadSessionUser } from 'actions';
import Link from 'components/Link';
import RouteContainer from 'components/RouteContainer';
import FooterContent from 'containers/FooterContent';
import LoadingContainer from 'containers/LoadingContainer';
import { translate as t, createTranslate } from 'utils/translation';

import styles from 'styles/routes/guest';
import logo from 'images/logo.svg';
import background from 'images/correction-studio-guest-background.jpg';


const tt = createTranslate('routes.studio_guest');


class StudioGuest extends React.Component {

  state = {
    user: {},
  };

  constructor(props) {
    super(props);
    this._handleSubmit = this._handleSubmit.bind(this);
  }

  componentDidMount() {
    const { token, loadPublicProject, user, publicProject, loadSessionUser } = this.props;
    if (token) {
      loadPublicProject(token);
    }
    if (user?.type === 'registered') {
      loadSessionUser();
    }
    if (user?.authToken && publicProject.id && publicProject.status !== 'ordered' && publicProject.status !== 'ongoing') {
      window.location.replace(`/studio/project-item/${publicProject.id}`);
    }
  }

  componentDidUpdate() {
    const { user, publicProject } = this.props;
    if (user?.authToken && publicProject.id && publicProject.status !== 'ordered' && publicProject.status !== 'ongoing') {
      if (user?.extra?.item === publicProject.id) {
        window.location.replace(`/studio/project-item/${publicProject.id}`);
      }
    }
  }

  render() {
    const { publicProject } = this.props;
    const followers = get(publicProject, 'followers', []);
    return (
      <div className={styles.guest}>
        <Header>
          <Navbar withContainer>
            <Link href="/">
              <NavbarIcon image={logo} />
            </Link>
            <NavbarControls>
              <NavbarText>{tt('dont_have_account')}</NavbarText>
              <NavbarLink><Link href="/sign-up">{tt('register')}</Link></NavbarLink>
            </NavbarControls>
          </Navbar>
        </Header>
        <Main>
          <LoadingContainer
            loadingKeys="publicProject"
            whenLoaded={() => (
              <RouteContainer>
                <Container>
                  <div className={styles.guestContainer}>
                    <div className={styles.guestPanel}>
                      <div className={styles.form}>
                        <Panel>
                          <PanelHeader>
                            <PanelTitle>
                              {tt('welcome')}
                            </PanelTitle>
                          </PanelHeader>
                          <PanelBody>
                            {do {
                              if (publicProject.status === 'ordered' || publicProject.status === 'ongoing') {
                                <Fragment>
                                  <Paragraph className={styles.notAvailable}>
                                    {tt('project_unavailable')}
                                  </Paragraph>
                                  <div className={styles.formPlaceholder}></div>
                                </Fragment>
                              }
                            }}
                            {do {
                              if (publicProject.status !== 'ordered' && publicProject.status !== 'ongoing') {
                                <Form
                                  name="studio-guest"
                                  onSubmit={this._handleSubmit}>
                                  <Paragraph className={styles.intro}>
                                    {tt('guest')}
                                  </Paragraph>
                                  <FormGroup>
                                    <Label>{tt('first_name')}</Label>
                                    <Input name="firstName" validation={(v) => v.isEmpty(tt('empty_field'))} />
                                  </FormGroup>
                                  <FormGroup>
                                    <Label>{tt('last_name')}</Label>
                                    <Input name="lastName" validation={(v) => v.isEmpty(tt('empty_field'))} />
                                  </FormGroup>
                                  <FormGroup>
                                    <Label>{tt('email')}</Label>
                                    <Input name="email" validation={(v) => v.isEmpty(tt('empty_field'))} />
                                  </FormGroup>
                                  <div className={styles.buttonContainer}>
                                    <Button category="primary" type="submit" className={styles.signInButton}>{t('routes.sign_in')}</Button>
                                  </div>
                                </Form>
                              }
                            }}
                          </PanelBody>
                        </Panel>
                      </div>
                      <div className={styles.info}>
                        <div className={styles.background} style={{ backgroundImage: `url(${background})` }}></div>
                        <div className={styles.overlay}></div>
                        <div className={styles.content}>
                          <div className={styles.projectInfo}>
                            <div className={styles.reference}>
                              <span>{t(`services.${publicProject.legacyName}`) + ': '}</span>
                              <span className={styles.white}>{publicProject.reference}</span>
                            </div>
                            <div className={styles.title}>
                              {publicProject.name}
                            </div>
                          </div>
                          <div className={styles.stats}>
                            <div className={styles.statsTitle}>
                              {tt('participants', { count: followers.length })}
                            </div>
                            <div className={styles.statsContent}>
                              {followers.slice(0, 3).map((f, i) => (
                                <div key={i} className={styles.user}>
                                  {f.getName()}
                                </div>
                              ))}
                              {do {
                                if (followers.length > 3) {
                                  <div className={styles.user}>
                                    {tt('more', { count: followers.length - 3 })}
                                  </div>
                                }
                              }}
                            </div>
                            <div className={styles.statsTitle}>
                              {`${tt('status')}:`}
                            </div>
                            <div className={styles.statsContent}>
                              {t(`pods.dashboard.status.${publicProject.status}.title`)}
                            </div>
                          </div>
                          <div className={styles.about}>
                            <div className={styles.reference}>
                              <span className={styles.white}>{tt('about.title')}</span>
                            </div>
                            <div className={styles.aboutContent}>
                              {tt('about.text')}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Container>
              </RouteContainer>
            )} />
        </Main>
        <Footer>
          <FooterContent />
        </Footer>
      </div>
    );
  }

  _getDefaultUserValues(props) {
    const { user } = props;
    return {
      firstName: get(user, 'user.firstName', ''),
      lastName: get(user, 'user.lastName', ''),
      email: get(user, 'user.email', ''),
    };
  }

  _handleSubmit(values) {
    const { guestSignUp, publicProject } = this.props;
    guestSignUp(values, publicProject);
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    user: getSession(state),
    publicProject: getPublicProject(state, ownProps.match.params.token),
    ...ownProps.match.params,
  };
};


const mapDispatchToProps = {
  loadSessionUser,
  loadPublicProject,
  guestSignUp,
};


export default connect(mapStateToProps, mapDispatchToProps)(StudioGuest);
