import Rx from 'rxjs-legacy';
import { combineEpics } from 'redux-observable';

import * as InvitationsActions from 'actions/invitation';
import * as AlertsActions from 'actions/alerts';
import * as ProjectItemActions from 'pods/dashboard/actions/project-item';
import * as OrderActions from 'pods/transactions/actions/orders';
import { formatErrorMessage, wrapError } from 'utils/errors';
import { translate as t } from 'utils/translation';


function loadInvitation(action$, store, extra) {
  const { api } = extra;
  return action$
    .filter((action) => action.type === InvitationsActions.LOAD_INVITED_USER)
    .flatMap((action) => {
      const { token } = action.payload;
      return Rx.Observable.fromPromise(api.queries.getInvitedUser(token))
        .map((user) => InvitationsActions.loadInvitedUserSuccess(user))
        .catch((err) => Rx.Observable.concat(
          Rx.Observable.of(AlertsActions.showAlert(InvitationsActions.LOAD_INVITED_USER_FAIL, 'error', t('containers.alerts.invitation.errors.load_invitation'))),
          Rx.Observable.of(InvitationsActions.loadInvitedUserFail(wrapError(err)))
        ));
    });
}


function createInvitation(action$, store, extra) {
  const { api } = extra;
  return action$
    .filter((action) => action.type === InvitationsActions.CREATE_INVITATION)
    .flatMap((action) => {
      const { invitation, item, order } = action.payload;
      return Rx.Observable.fromPromise(api.mutations.createInvitation(invitation))
        .flatMap((newInvitation) => Rx.Observable.concat(
          Rx.Observable.of(InvitationsActions.createInvitationSuccess(invitation.id, newInvitation)),
          Rx.Observable.of(InvitationsActions.updateInvitation(newInvitation, 'no-server')),
          item ? Rx.Observable.of(ProjectItemActions.loadProjectItem(item.id, 'update'))
            : (order ? Rx.Observable.of(OrderActions.loadOrder(order.id)) : Rx.Observable.empty()),
          Rx.Observable.of(AlertsActions.showAlert(InvitationsActions.CREATE_INVITATION_SUCCESS, 'success', t('containers.alerts.invitation.invitation_sent', { email: invitation.user.email }))),
        ))
        .catch((err) => {
          const message = formatErrorMessage(err.info.body.message, 'containers.alerts.invitation.errors');
          return Rx.Observable.concat(
            Rx.Observable.of(AlertsActions.showAlert(InvitationsActions.CREATE_INVITATION_FAIL, 'error', message)),
            Rx.Observable.of(InvitationsActions.createInvitationFail(err, invitation)),
          );
        });
    });
}


function createInvitationFail(action$) {
  return action$
    .filter((action) => action.type === InvitationsActions.CREATE_INVITATION_FAIL)
    .map((action) => action.payload.invitation)
    .map((invitation) => InvitationsActions.deleteInvitation(invitation, 'no-server'));
}


// not in use
function updateInvitation(action$, store, extra) {
  const { api } = extra;
  return action$
    .filter((action) => action.type === InvitationsActions.UPDATE_INVITATION)
    .flatMap((action) => {
      const { invitation, noServer } = action.payload;
      if (noServer) {
        return Rx.Observable.of(InvitationsActions.updateInvitationSuccess(invitation));
      }
      return Rx.Observable.fromPromise(api.mutations.updateInvitation(invitation))
        .flatMap((response) => Rx.Observable.concat(
          Rx.Observable.of(AlertsActions.showAlert(InvitationsActions.UPDATE_INVITATION_SUCCESS, 'success', response.message))
        ))
        .catch((err) => Rx.Observable.of(InvitationsActions.updateInvitationFail(err)));
    });
}


export default combineEpics(
  loadInvitation,
  createInvitation,
  createInvitationFail,
  updateInvitation,
);
