import sv from '@drawbotics/drylus-style-vars';
import {
  Content,
  Flex,
  FlexItem,
  FlexJustify,
  Layout,
  Padding,
  Position,
  Size,
  ThemeProvider,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React, { ReactNode } from 'react';

import authCover from '~/images/auth-cover.jpg';
import logo from '~/images/logo.svg';

import { LanguageSwitcher } from './LanguageSwitcher';

const styles = {
  layout: css`
    > [data-element='layout-bar'] {
      z-index: 99;
    }
  `,
  navbar: css`
    width: 100%;
    height: 60px;
    background: ${sv.white};
    box-shadow: ${sv.elevation2};
  `,
  wrapper: css`
    position: relative;
    overflow: hidden;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: left;
    }
  `,
  sidebar: css`
    width: 600px;
    height: 100%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  container: css`
    width: 75%;
  `,
};

export const BasicNavbar = () => {
  return (
    <ThemeProvider injectGlobal={false} style={{ width: '100%' }}>
      <div className={styles.navbar}>
        <Flex style={{ height: '100%' }}>
          <FlexItem flex>
            <Padding size={{ horizontal: Size.DEFAULT }}>
              <Flex justify={FlexJustify.SPACE_BETWEEN}>
                <FlexItem>
                  <div style={{ display: 'inline-block' }}>
                    <a href="/">
                      <img src={logo} height="30" />
                    </a>
                  </div>
                </FlexItem>
                <FlexItem>
                  <LanguageSwitcher />
                </FlexItem>
              </Flex>
            </Padding>
          </FlexItem>
        </Flex>
      </div>
    </ThemeProvider>
  );
};

interface NavbarLayoutProps {
  children: ReactNode;
  navbar: ReactNode;
}

export const NavbarLayout = ({ children, navbar }: NavbarLayoutProps) => {
  return (
    <Layout
      className={styles.layout}
      bar={navbar}
      position={Position.TOP}
      barScrollable={false}
      fixed>
      {children}
    </Layout>
  );
};

interface SidebarProps {
  children: ReactNode;
}

export const Sidebar = ({ children }: SidebarProps) => {
  return (
    <div className={styles.sidebar}>
      <div className={styles.container}>{children}</div>
    </div>
  );
};

interface MainProps {
  image: string;
}

const Main = ({ image }: MainProps) => {
  return (
    <Content fullWidth fullHeight>
      <div className={styles.wrapper}>
        <img src={image} />
      </div>
    </Content>
  );
};

export const AuthRoutesWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <NavbarLayout navbar={<BasicNavbar />}>
      <Layout bar={<Sidebar>{children}</Sidebar>} position={Position.LEFT}>
        <Main image={authCover} />
      </Layout>
    </NavbarLayout>
  );
};
