const k = (key) => `commercial_floorplan.briefing.${key}`;


const residential = {
  1: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('floorplan_style'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('choose_style'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'SingleSelect',
        key: 'style',
        keyLabel: k('keys.style'),
        required: 'all',
        values: [
          {
            image: require('../images/commercial-floorplan/styles/style-1.jpg'),
            zoom: require('../images/commercial-floorplan/styles/style-1.jpg'),
            value: 'cfp_style_1',
          }, {
            image: require('../images/commercial-floorplan/styles/style-2.jpg'),
            zoom: require('../images/commercial-floorplan/styles/style-2.jpg'),
            value: 'cfp_style_2',
          }, {
            image: require('../images/commercial-floorplan/styles/style-3.jpg'),
            zoom: require('../images/commercial-floorplan/styles/style-3.jpg'),
            value: 'cfp_style_3',
          }, {
            image: require('../images/commercial-floorplan/styles/style-4.jpg'),
            zoom: require('../images/commercial-floorplan/styles/style-4.jpg'),
            value: 'cfp_style_4',
          }, {
            image: require('../images/commercial-floorplan/styles/style-5.jpg'),
            zoom: require('../images/commercial-floorplan/styles/style-5.jpg'),
            value: 'cfp_style_5',
          }, {
            image: require('../images/commercial-floorplan/styles/style-6.jpg'),
            zoom: require('../images/commercial-floorplan/styles/style-6.jpg'),
            value: 'cfp_style_6',
          }, {
            image: require('../images/commercial-floorplan/styles/style-7.jpg'),
            zoom: require('../images/commercial-floorplan/styles/style-7.jpg'),
            value: 'cfp_style_7',
          }, {
            image: require('../images/commercial-floorplan/styles/style-8.jpg'),
            zoom: require('../images/commercial-floorplan/styles/style-8.jpg'),
            value: 'cfp_style_8',
          }, {
            image: require('../images/commercial-floorplan/styles/style-9.jpg'),
            zoom: require('../images/commercial-floorplan/styles/style-9.jpg'),
            value: 'cfp_style_9',
          },
        ],
      },
      {
        type: 'meta',
        label: 'shared.show_my_own',
        components: [
          {
            type: 'question-component',
            label: 'shared.example_description',
            component: 'Input',
            key: 'otherStyle',
            keyLabel: 'shared.keys.example_comments',
          }, {
            type: 'question-component',
            label: 'shared.upload_example',
            component: 'FileUploader',
            key: 'otherStyleAttachments',
            keyLabel: 'shared.keys.submitted_examples'
          },
        ],
      },
    ],
  },
  2: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('options'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('choose_options'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'MultiSelect',
        key: 'options',
        keyLabel: k('keys.options'),
        required: 'all',
        values: [
          {
            image: require('../images/commercial-floorplan/options/compass.jpg'),
            value: 'compass_rose',
            label: k('options_types.compass_rose'),
          },
          {
            image: require('../images/commercial-floorplan/options/surface-area.jpg'),
            value: 'surface_area',
            label: k('options_types.surface_area'),
          },
          {
            image: require('../images/commercial-floorplan/options/dimensions.jpg'),
            value: 'dimensions',
            label: k('options_types.dimensions'),
          },
          {
            image: require('../images/commercial-floorplan/options/scale.jpg'),
            value: 'scale',
            label: k('options_types.scale'),
          },
        ],
      },
    ],
  },
  3: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('logos'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('which_logos'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'MultiSelect',
        key: 'logoOptions',
        keyLabel: k('keys.logo_options'),
        required: 'all',
        values: [
          {
            image: require('../images/commercial-floorplan/logos/project-logo.jpg'),
            value: 'project_logo',
            label: k('project_logo'),
          },
          {
            image: require('../images/commercial-floorplan/logos/architect-logo.jpg'),
            value: 'architect_logo',
            label: k('architect_logo'),
          },
          {
            image: require('../images/commercial-floorplan/logos/developer-logo.jpg'),
            value: 'developer_logo',
            label: k('developer_logo'),
          },
        ],
      },
      {
        type: 'component',
        label: k('upload_selected_logos'),
        component: 'Note',
      },
      {
        type: 'question-component',
        label: k('upload_logo_files'),
        component: 'FileUploader',
        key: 'logoAttachments',
        keyLabel: k('keys.submitted_logos'),
      },
    ],
  },
  4: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('additional_visuals'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('up_to_two'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'MultiSelect',
        key: 'extraVisuals',
        keyLabel: k('keys.extra_visuals'),
        required: 'all',
        max: 2,
        values: [
          {
            image: require('../images/commercial-floorplan/extra-visuals/floor-plan.jpg'),
            value: 'floor_plan',
            label: k('visuals_options.floor_plan'),
          }, {
            image: require('../images/commercial-floorplan/extra-visuals/base-plan.jpg'),
            value: 'base_plan',
            label: k('visuals_options.base_plan'),
          }, {
            image: require('../images/commercial-floorplan/extra-visuals/facade-plan-1.jpg'),
            value: 'facade_plan_1',
            label: k('visuals_options.facade_plan_1'),
          }, {
            image: require('../images/commercial-floorplan/extra-visuals/facade-plan-2.jpg'),
            value: 'facade_plan_2',
            label: k('visuals_options.facade_plan_2'),
          },
        ],
      },
    ],
  },
  5: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('footnote'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('enter_footnote'),
        component: 'Note',
      },
      {
        type: 'question-component',
        label: k('footnote_text'),
        component: 'Input',
        key: 'footnote',
        keyLabel: k('keys.footnote_text'),
      },
    ],
  },
  6: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'shared.additional_info',
        component: 'Title',
      }, {
        type: 'component',
        label: 'shared.anything_to_add',
        component: 'Note',
      }, {
        type: 'question-component',
        label: 'shared.additional_comments',
        component: 'Input',
        key: 'otherInfo',
        keyLabel: 'shared.keys.other_info',
      }, {
        type: 'question-component',
        label: 'shared.upload_files',
        component: 'FileUploader',
        key: 'otherInfoAttachments',
        keyLabel: 'shared.keys.other_info_attachments',
      },
    ],
  },
};


export default { residential };
