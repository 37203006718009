import { Add } from 'drawbotics-pricer';


const description = {
  Base: [
    Add({ EUR: 250, GBP: 250, USD: 250 }),
  ],
  Delivery: [
  ],
};


export default description;
