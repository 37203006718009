import { createEntities, updateEntities } from 'entman';
import { startLoading, stopLoading } from 'loading-manager';

import schemas from 'schemas';


export const BUY_CREDITS = 'BUY_CREDITS';

export function buyCredits(amount, bonus) {
  return {
    type: BUY_CREDITS,
    payload: { amount, bonus },
  };
}


export const CREATE_CREDITS_TRANSACTION = 'CREATE_CREDITS_TRANSACTION';

export function createCreditsTransaction(transaction) {
  return createEntities(schemas.Transaction, 'payload.transaction', {
    type: CREATE_CREDITS_TRANSACTION,
    payload: { transaction },
  });
}


export const BUY_CREDITS_SUCCESS = 'BUY_CREDITS_SUCCESS';

export function buyCreditsSuccess(credits) {
  return {
    type: BUY_CREDITS_SUCCESS,
    payload: { credits },
  };
}


export const BUY_CREDITS_FAIL = 'BUY_CREDITS_FAIL';

export function buyCreditsFail(error) {
  return {
    type: BUY_CREDITS_FAIL,
    meta: { error },
  };
}


export const LOAD_TRANSACTION = 'LOAD_TRANSACTION';

export function loadTransaction(id) {
  return {
    type: LOAD_TRANSACTION,
    payload: { id },
    meta: {
      loading: startLoading('transaction'),
    },
  };
}


export const LOAD_TRANSACTION_SUCCESS = 'LOAD_TRANSACTION_SUCCESS';

export function loadTransactionSuccess(transaction) {
  return createEntities(schemas.Transaction, 'payload.transaction', {
    type: LOAD_TRANSACTION_SUCCESS,
    payload: { transaction },
    meta: {
      loading: stopLoading('transaction'),
    },
  });
}


export const LOAD_TRANSACTION_FAIL = 'LOAD_TRANSACTION_FAIL';

export function loadTransactionFail(error) {
  return {
    type: LOAD_TRANSACTION_FAIL,
    meta: {
      error,
      loading: stopLoading('transaction'),
    },
  };
}


export const UPDATE_TRANSACTION_ADDRESS = 'UPDATE_TRANSACTION_ADDRESS';

export function updateTransactionAddress(transaction, addressId) {
  return {
    type: UPDATE_TRANSACTION_ADDRESS,
    payload: { transaction, addressId },
  };
}


export const UPDATE_TRANSACTION_ADDRESS_SUCCESS = 'UPDATE_TRANSACTION_ADDRESS_SUCCESS';

export function updateTransactionAddressSuccess(transaction) {
  return updateEntities(schemas.Transaction, transaction.id, 'payload.transaction', {
    type: UPDATE_TRANSACTION_ADDRESS_SUCCESS,
    payload: { transaction },
  });
}


export const UPDATE_TRANSACTION_ADDRESS_FAIL = 'UPDATE_TRANSACTION_ADDRESS_FAIL';

export function updateTransactionAddressFail(error) {
  return {
    type: UPDATE_TRANSACTION_ADDRESS_FAIL,
    meta: { error },
  };
}


export const REDIRECT_TRANSACTION_ALREADY_PAID = 'REDIRECT_TRANSACTION_ALREADY_PAID';

export function redirectTransactionAlreadyPaid() {
  return {
    type: REDIRECT_TRANSACTION_ALREADY_PAID,
  };
}
