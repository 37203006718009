/**
 * Run relativeAdd operation
 *
 * Relative Add always return 0 because their purpose is to collect
 * all the relativeAdds to be applied at the end of the calculation.
 *
 * Example:
 *
 * {
 *   operation: 'relativeAdd',
 *   relativeAdd: {
 *     base: ['GroupName'],
 *     coefficient: 0.5,
 *   },
 * }
 */

export function doRelativeAdd(object, relativeDescription, relativeDescriptions) {
  relativeDescriptions.push(relativeDescription);
  return 0;
}


class RelativeAddOperation {
  constructor(coefficient) {
    this.coefficient = coefficient;
  }

  from(base) {
    base = Array.isArray(base) ? base : [base];
    return {
      operation: 'relativeAdd',
      relativeAdd: {
        base,
        coefficient: this.coefficient,
      },
    };
  }
}


export function RelativeAdd(coefficient) {
  return new RelativeAddOperation(coefficient);
}
