import { DrylusProvider } from '@drawbotics/react-drylus';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { RouterProvider } from '~/components';
import Route, { Switch } from '~/components/Route';

import { Project } from '../Project';
import { ProjectsRoute } from '../projects';

interface DashboardProps extends RouteComponentProps {}

export const Dashboard = ({ history, location, match }: DashboardProps) => {
  return (
    <DrylusProvider
      injectGlobal={false}
      style={{
        width: '100%',
        height: '100%',
      }}>
      <RouterProvider value={{ history, location, match }}>
        <Switch handleNotFound>
          <Route basePath="/projects" path="/:estateId" component={Project} />
          <Route path="/" exact component={ProjectsRoute} />
        </Switch>
      </RouterProvider>
    </DrylusProvider>
  );
};
