import history from './history';


export const RESET_STATE = 'RESET_STATE';

export default function resetState(reducer) {
  return (state, action) => {
    if (action.type === RESET_STATE) {
      state = {};
      history.replace('/');
    }
    return reducer(state, action);
  };
}
