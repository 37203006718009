import { residential as residentialGuide, commercial as commercialGuide } from '../utils/interior-guide';

const k = (key) => `shoebox.briefing.${key}`;


const residential = {
  1: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('unit'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('specify_unit'),
        component: 'Note',
      },
      {
        type: 'question-component',
        label: k('unit'),
        component: 'Input',
        required: 'all',
        key: 'unit',
        keyLabel: k('unit'),
      },
    ],
  },
  2: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('shoebox_style'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('choose_style'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'SingleSelect',
        key: 'style',
        keyLabel: k('keys.style'),
        required: 'all',
        values: residentialGuide,
      },
      {
        type: 'meta',
        label: 'shared.show_my_own',
        components: [
          {
            type: 'question-component',
            label: 'shared.example_description',
            component: 'Input',
            key: 'otherStyle',
            keyLabel: 'shared.keys.example_comments',
          }, {
            type: 'question-component',
            label: 'shared.upload_example',
            component: 'FileUploader',
            key: 'otherStyleAttachments',
            keyLabel: 'shared.keys.submitted_examples',
          },
        ],
      },
    ],
  },
  3: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('time_of_day'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('choose_time'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'SingleSelect',
        key: 'timeOfDay',
        keyLabel: k('time_of_day'),
        required: 'all',
        values: [
          {
            image: require('../images/shoebox/time-of-day/day.jpg'),
            label: k('daytime'),
            value: 'day',
          },
          {
            image: require('../images/shoebox/time-of-day/dusk.jpg'),
            label: k('dusk'),
            value: 'dusk',
          },
        ],
      },
    ],
  },
  4: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('details'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('specify_doors'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'SingleSelect',
        key: 'doors',
        keyLabel: k('keys.doors'),
        required: 'all',
        values: [
          {
            image: require('../images/shoebox/doors/all.jpg'),
            value: 'all',
            label: k('all_open'),
          },
          {
            image: require('../images/shoebox/doors/main.jpg'),
            value: 'main',
            label: k('only_main'),
          },
          {
            image: require('../images/shoebox/doors/none.jpg'),
            value: 'none',
            label: k('all_closed'),
          },
        ],
      },
      {
        type: 'component',
        label: k('people_integration'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'SingleSelect',
        key: 'people',
        keyLabel: k('keys.people_integration'),
        required: 'all',
        values: [
          {
            image: require('../images/shoebox/people/no-people.png'),
            value: 'none',
            label: k('no_people'),
          },
          {
            image: require('../images/shoebox/people/people.png'),
            value: 'three_d',
            label: k('yes_3d'),
          },
        ],
      },
      {
        type: 'question-component',
        component: 'Input',
        key: 'additionalRoomFurniture',
        label: k('specify_extra_furniture'),
        keyLabel: k('keys.additional_furniture'),
      },
    ],
  },
  5: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'shared.additional_info',
        component: 'Title',
      }, {
        type: 'component',
        label: 'shared.anything_to_add',
        component: 'Note',
      }, {
        type: 'question-component',
        label: 'shared.additional_comments',
        component: 'Input',
        key: 'otherInfo',
        keyLabel: 'shared.keys.other_info',
      }, {
        type: 'question-component',
        label: 'shared.upload_files',
        component: 'FileUploader',
        key: 'otherInfoAttachments',
        keyLabel: 'shared.keys.other_info_attachments',
      },
    ],
  },
};


const commercial = {
  1: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('section'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('which_floor'),
        component: 'Note',
      },
      {
        type: 'question-component',
        label: k('shoebox_floor'),
        component: 'Input',
        key: 'section',
        keyLabel: k('keys.shoebox_floor'),
        required: 'all',
      },
    ],
  },
  2: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('shoebox_style'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('choose_style'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'SingleSelect',
        key: 'style',
        keyLabel: k('keys.style'),
        required: 'all',
        values: commercialGuide,
      },
      {
        type: 'meta',
        label: 'shared.show_my_own',
        components: [
          {
            type: 'question-component',
            label: 'shared.example_description',
            component: 'Input',
            key: 'otherStyle',
            keyLabel: 'shared.keys.example_comments',
          }, {
            type: 'question-component',
            label: 'shared.upload_example',
            component: 'FileUploader',
            key: 'otherStyleAttachments',
            keyLabel: 'shared.keys.submitted_examples',
          },
        ],
      },
    ],
  },
  3: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('flooring_details'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('specify_flooring'),
        component: 'Note',
      },
      {
        type: 'question-component',
        label: k('flooring_comments'),
        component: 'Input',
        key: 'flooring',
        keyLabel: k('flooring_comments'),
        required: 'all',
      },
      {
        type: 'question-component',
        label: k('upload_file'),
        component: 'FileUploader',
        key: 'flooringAttachments',
        keyLabel: k('keys.flooring_attachments'),
      },
    ],
  },
  4: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'shared.additional_info',
        component: 'Title',
      }, {
        type: 'component',
        label: 'shared.anything_to_add',
        component: 'Note',
      }, {
        type: 'question-component',
        label: 'shared.additional_comments',
        component: 'Input',
        key: 'otherInfo',
        keyLabel: 'shared.keys.other_info',
      }, {
        type: 'question-component',
        label: 'shared.upload_files',
        component: 'FileUploader',
        key: 'otherInfoAttachments',
        keyLabel: 'shared.keys.other_info_attachments',
      },
    ],
  },
};


export default { residential, commercial };
