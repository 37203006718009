import { LoadingPlaceholder, Margin, Padding, Size } from '@drawbotics/react-drylus';
import React from 'react';

export const ItemSidebarLoader = () => {
  return (
    <Padding>
      <LoadingPlaceholder height={40} width="50%" />
      <Margin size={{ top: Size.SMALL }} />
      <LoadingPlaceholder height={40} width="100%" />
      <Margin size={{ top: Size.EXTRA_LARGE }} />
      <LoadingPlaceholder width={150} />
      <Margin size={{ top: Size.DEFAULT }} />
      <LoadingPlaceholder width={100} />
      <Margin size={{ top: Size.DEFAULT }} />
      <LoadingPlaceholder width={100} />
      <Margin size={{ top: Size.DEFAULT }} />
      <LoadingPlaceholder width={100} />
      <Margin size={{ top: Size.DEFAULT }} />
      <LoadingPlaceholder width={150} />
      <Margin size={{ top: Size.DEFAULT }} />
      <LoadingPlaceholder width={100} />
      <Margin size={{ top: Size.DEFAULT }} />
      <LoadingPlaceholder width={100} />
    </Padding>
  );
};
