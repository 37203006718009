import { startLoading, stopLoading } from 'loading-manager';
import { createEntities } from 'entman';

import schemas from 'schemas';


export const LOAD_INVOICES = 'LOAD_INVOICES';

export function loadInvoices(organizationId) {
  return {
    type: LOAD_INVOICES,
    payload: { organizationId },
    meta: { loading: startLoading('invoices') },
  };
}


export const LOAD_INVOICES_SUCCESS = 'LOAD_INVOICES_SUCCESS';

export function loadInvoicesSuccess(invoices) {
  return createEntities(schemas.Invoice, 'payload.invoices', {
    type: LOAD_INVOICES_SUCCESS,
    payload: { invoices },
    meta: { loading: stopLoading('invoices') },
  });
}


export const LOAD_INVOICES_FAIL = 'LOAD_INVOICES_FAIL';

export function loadInvoicesFail(error) {
  return {
    type: LOAD_INVOICES_FAIL,
    meta: { error, loading: stopLoading('invoices') },
  };
}
