import sv from '@drawbotics/drylus-style-vars';
import {
  ButtonLink,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Icon,
  Margin,
  Shade,
  ShowDateTime,
  Size,
  Text,
  Tier,
  Title,
  formatDate,
  formatPrice,
} from '@drawbotics/react-drylus';
import dayjs from 'dayjs';
import { css } from 'emotion';
import React, { Fragment } from 'react';

import { PaymentDocument, PaymentDocumentStatus } from '~/pods/dashboard/types';
import { run } from '~/utils';
import { createTranslate, translate as t } from '~/utils/translation';

const tt = createTranslate('pods.dashboard.routes.billing');

const styles = {
  totalContainer: css`
    padding: ${sv.paddingExtraSmall} ${sv.paddingSmall};
    border-radius: ${sv.defaultBorderRadius};
    background: ${sv.neutralLight};
    width: calc(100% + ${sv.paddingSmall} * 2);
    margin-left: -${sv.marginSmall};
  `,
};

interface PaymentDocumentInfoProps {
  paymentDocument: PaymentDocument;
}

export const PaymentDocumentInfo = ({ paymentDocument }: PaymentDocumentInfoProps) => {
  const priceWithouTaxes = paymentDocument.priceWithTaxes - paymentDocument.paidTaxes;
  const totalOverallCost =
    priceWithouTaxes / ((paymentDocument.downPaymentPercentage || 100) / 100);

  return (
    <Fragment>
      <Margin size={{ bottom: Size.DEFAULT }}>
        <Flex style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
          <FlexItem flex>
            <Margin size={{ bottom: Size.SMALL }}>
              <Text shade={Shade.LIGHT} light>
                {tt('status')}
              </Text>
            </Margin>
            <Text>
              {t(`pods.dashboard.components.estate_payments.status.${paymentDocument.status}`)}
            </Text>
          </FlexItem>
          <FlexSpacer size={Size.SMALL} />
          <FlexItem flex>
            <Margin size={{ bottom: Size.SMALL }}>
              <Text shade={Shade.LIGHT} light>
                {tt('invoice_date')}
              </Text>
            </Margin>
            <Text dateOptions={{ showTime: ShowDateTime.NEVER }}>
              {new Date(paymentDocument.sentAt)}
            </Text>
          </FlexItem>
          <FlexSpacer size={Size.SMALL} />
          <FlexItem flex>
            <Margin size={{ bottom: Size.SMALL }}>
              <Text shade={Shade.LIGHT} light>
                {tt('ref_number')}
              </Text>
            </Margin>
            <Text>{`#${paymentDocument.reference}`}</Text>
          </FlexItem>
          <FlexSpacer size={Size.SMALL} />
          <FlexItem flex>
            <Margin size={{ bottom: Size.SMALL }}>
              <Text shade={Shade.LIGHT} light>
                {tt('date_paid')}
              </Text>
            </Margin>
            <Text dateOptions={{ showTime: ShowDateTime.NEVER }}>
              {run(() => {
                if (paymentDocument.status === PaymentDocumentStatus.LATE) {
                  return tt('late_by', {
                    amount: dayjs().diff(dayjs(paymentDocument.paymentDeadline), 'day'),
                  });
                } else if (paymentDocument.status === PaymentDocumentStatus.PAID) {
                  return new Date(paymentDocument.debitTransaction.performedAt);
                } else {
                  return tt('due_on', {
                    date: formatDate({
                      date: new Date(paymentDocument.paymentDeadline),
                      options: { showTime: ShowDateTime.NEVER },
                    }),
                  });
                }
              })}
            </Text>
          </FlexItem>
          <FlexSpacer size={Size.SMALL} />
          <FlexItem flex>
            <Margin size={{ bottom: Size.SMALL }}>
              <Text>
                {tt('amount')}{' '}
                <Text shade={Shade.LIGHT} size={Size.SMALL}>
                  {tt('percent_amount', {
                    percent: paymentDocument.downPaymentPercentage || 100,
                    amount: formatPrice({
                      price: { value: totalOverallCost, currency: paymentDocument.currency },
                    }),
                  })}
                </Text>
              </Text>
            </Margin>
            <Text>
              {tt('vat')}{' '}
              <Text shade={Shade.LIGHT} size={Size.SMALL}>
                ({paymentDocument.vatRate}%)
              </Text>
            </Text>
          </FlexItem>
          <FlexSpacer size={Size.SMALL} />
          <FlexItem flex style={{ textAlign: 'right' }}>
            <Margin size={{ bottom: Size.SMALL }}>
              <Text>{{ value: priceWithouTaxes, currency: paymentDocument.currency }}</Text>
            </Margin>
            <Text>{{ value: paymentDocument.paidTaxes, currency: paymentDocument.currency }}</Text>
          </FlexItem>
        </Flex>
      </Margin>
      <Flex justify={FlexJustify.END} style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
        <FlexItem flex>{null}</FlexItem>
        <FlexItem flex style={{ textAlign: 'right' }}>
          {paymentDocument.status !== PaymentDocumentStatus.PAID ? (
            <a href={paymentDocument.url} download target="_blank" rel="noopener noreferrer">
              <ButtonLink tier={Tier.SECONDARY} leading={<Icon name="download" />}>
                {t(`pods.dashboard.components.estate_payments.download_invoice`)}
              </ButtonLink>
            </a>
          ) : null}
        </FlexItem>
        <FlexSpacer size={Size.LARGE} />
        <FlexItem flex>
          <div className={styles.totalContainer}>
            <Flex justify={FlexJustify.SPACE_BETWEEN}>
              <FlexItem>
                <Text>{tt('total')}</Text>
              </FlexItem>
              <FlexItem>
                <Title noMargin size={4}>
                  {formatPrice({
                    price: {
                      value: paymentDocument.priceWithTaxes,
                      currency: paymentDocument.currency,
                    },
                  })}
                </Title>
              </FlexItem>
            </Flex>
          </div>
        </FlexItem>
      </Flex>
    </Fragment>
  );
};
