import { graphql } from 'react-apollo-redux';
import compose from 'lodash/flowRight';
import { connect } from 'react-redux';

import Component from './Component';
import {
  BriefingQuery,
  ServicesQuery,
  UpdateEstateMutation,
  UpdateItemsMutation,
  ConfirmItemBriefing,
  ConfirmEstateBriefing,
} from './graphql';

const mapStateToProps = (state, ownProps) => ({
  estateId: ownProps.match.params.estateId,
});

export default compose(
  connect(mapStateToProps, undefined),
  graphql(BriefingQuery, {
    name: 'estateData',
    options: (ownProps) => ({ variables: { estateId: ownProps.estateId }}),
  }),
  graphql(ServicesQuery, {
    name: 'servicesData',
  }),
  graphql(UpdateItemsMutation, { name: 'updateItems' }),
  graphql(UpdateEstateMutation, { name: 'updateEstate' }),
  graphql(ConfirmItemBriefing, { name: 'confirmItemBriefing' }),
  graphql(ConfirmEstateBriefing, { name: 'confirmEstateBriefing' }),
)(Component);
