import React from 'react';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';

import { createTranslate } from '~/utils/translation';


const t = createTranslate('pods.order.services');


const styles = {
  textField: css`
    margin: ${sv.baseMarginSmall} 0;
  `,
  title: css`
    font-family: ${sv.baseFontFamilyAlt};
    text-transform: uppercase;
    color: ${sv.textPrimaryDark};
    font-weight: 600;
    font-size: 0.65rem;
  `,
  text: css`
    padding: ${sv.basePaddingSmall};
    color: ${sv.textPrimaryDark};
    background: ${sv.grey100};
    font-size: 0.9em;
    margin-top: ${sv.baseMarginSmall};
    border-radius: ${sv.baseBorderRadius};
    white-space: pre-wrap;
  `,
};


const TextField = ({ text, title }) => {
  return (
    <div className={styles.textField}>
      <div className={styles.title}>
        {t(title)}
      </div>
      <div className={styles.text}>
        {text}
      </div>
    </div>
  );
}


export default TextField;
