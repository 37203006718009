const specs = {
  apartment: {
    operation: 'create',
    create: {
      product: 'shoebox_standard',
      type: 'number',
      with: {
        type: 'apartment',
      },
    },
  },
  duplex: {
    operation: 'create',
    create: {
      product: 'shoebox_standard',
      type: 'number',
      with: {
        type: 'duplex',
      },
    },
  },
  house: {
    operation: 'create',
    create: {
      product: 'shoebox_standard',
      type: 'number',
      with: {
        type: 'house',
      },
    },
  },
  commercial: {
    operation: 'create',
    create: {
      product: 'shoebox_standard',
      type: 'number',
      with: {
        type: 'commercial',
      },
    },
  },
};


const dependencies = {
  hard: [],
  soft: [],
};


export default { specs, dependencies };
