import React from 'react';
import autobind from 'autobind-decorator';
import { Grid, Cell } from 'react-ittsu/layout';
import gql from 'fraql';

import BaseServiceModal from '../BaseServiceModal';
import Paragraph from '../Paragraph';
import SectionTitle from '../SectionTitle';
import { ProductCard, Stripe, StripeGroup } from '../../forms';
import { priceItem, applyPrice } from '../../../utils/pricing';
import { specsToItems, itemsToSpecs } from '~/pods/order/utils/services/specs-to-items';
import applyChanges from '~/pods/order/utils/services/specs-to-items/apply-changes';
import { getItemsByService, getFeaturesForProduct, openIntercom, merge } from '~/pods/order/utils';
import { createTranslate, translate as t } from '~/utils/translation';
import { EstateEnums } from '~/pods/order/utils/estate-enums';

import serviceImages from '~/pods/order/images/services';


const tt = createTranslate('pods.order.services.landing_page.order');
const tm = createTranslate('pods.order.components.service_modal');

const MAX_LANGUAGES = 3;


class LandingPage extends React.Component {

  static fragments = {
    Item: gql`
      fragment _ on Item {
        id
        details {
          ... on LandingPageDetails {
            copywriting
            numberOfLanguages
          }
        }
        product {
          id @client(type: Product)
          custom
          name
          features {
            locale
            content
          }
        }
      }
    `,
  }

  state = {
    custom: null,
    specs: {
      standard: false,
      copywriting: false,
      numberOfLanguages: 1,
    },
    price: undefined,
  };

  componentDidUpdate(prevProps) {
    const { specs } = this.state;
    const { service, estate } = this.props;
    const { service: prevService } = prevProps;
    if (service?.id === 'landingPage' && ! prevService) {
      const newSpecs = itemsToSpecs({ estate, service });
      const items = getItemsByService(estate).landingPage || [];
      const customItem = items.find((item) => item.product.custom);
      const price = items != null ? items.reduce((memo, i) => memo + priceItem(i, this.props.sessionUser.currency.code, true), 0) : 0;
      this.setState({ specs: merge(specs, newSpecs), price, custom: customItem ? true : null });
    }
  }

  render() {
    const { onClickClose, isVisible, service, estate, sessionUser } = this.props;
    const { specs: { standard, copywriting, numberOfLanguages }, custom, price } = this.state;
    const { landingPage } = serviceImages;
    const items = getItemsByService(estate).landingPage || [];
    const customItem = items.find((item) => item.product.custom);
    const customFeatures = custom !== null ? getFeaturesForProduct(customItem?.product) : null;
    const isResidential = estate.projectType === EstateEnums.ProjectType.RESIDENTIAL;
    return (
      <BaseServiceModal
        isVisible={isVisible && service?.id === 'landingPage'}
        title={service?.id ? t(`services.${service.id}`) : ''}
        images={Object.values(landingPage)}
        price={sessionUser.addCurrency(price)}
        onClickConfirm={this._handleClickConfirm}
        confirmAsUpdate={items?.length > 0}
        onClickClose={onClickClose}
        addDisabled={price === 0}>
        <div className="childcontent">
          <Paragraph>
            {tt('description')}
          </Paragraph>
          <SectionTitle>
            {tt('select_type')}
          </SectionTitle>
          <Grid withVGutters withHGutters>
            <Cell size="1of2">
              <ProductCard
                name="standard"
                label={tm('standard')}
                note={tm('from_price', { price: applyPrice(isResidential ? { EUR: 1850, GBP: 1750, USD: 2220 } : { EUR: 2350, GBP: 2075, USD: 2820 }, sessionUser) })}
                fullHeight
                formComponent="radio"
                features={[{
                  text: tt('features.first'),
                }, {
                  text: tt('features.second'),
                }, {
                  text: tt('features.third'),
                }, {
                  text: tt('features.fourth'),
                }, {
                  text: tt('features.fifth'),
                }, {
                  text: tt('features.sixth'),
                }, {
                  text: tt('features.seventh'),
                }, {
                  text: tt('features.eighth'),
                }]}
                value={standard}
                onChange={this._handleClickOption} />
            </Cell>
            <Cell size="1of2">
              <ProductCard
                name="custom"
                label={custom !== null ? `${customItem?.product.name}` : tm('custom')}
                note={custom !== null && customItem ? `${sessionUser.addCurrency(customItem?.price)}` : tm('contact_us')}
                formComponent="radio"
                fullHeight
                features={customFeatures}
                onChange={this._handleClickCustom}
                value={!! custom} />
            </Cell>
          </Grid>
          <SectionTitle>
            Customise your landing page
          </SectionTitle>
          <StripeGroup>
            <Stripe
              title={tt('copywriting')}
              subtitle={tm('variable_price')}
              component="switch"
              features={[{
                text: tt('copywriting_features.first.text'),
                infoText: tt('copywriting_features.first.info'),
              }]}
              disabled={! standard}
              value={copywriting}
              onChange={() => this._handleChangeSpec('copywriting', ! copywriting)} />
            <Stripe
              title={tt('number_of_languages')}
              subtitle={tt('minimum')}
              component="counter"
              features={[{
                text: tt('number_of_languages_features.first.text'),
                infoText: tt('number_of_languages_features.first.info'),
              }]}
              disabled={! standard}
              value={numberOfLanguages}
              onChange={(v) => this._handleChangeSpec('numberOfLanguages', v === 0 ? 1 : v === MAX_LANGUAGES + 1 ? MAX_LANGUAGES : v)} />
          </StripeGroup>
        </div>
      </BaseServiceModal>
    );
  }

  @autobind
  _syncState() {
    const { estate, service } = this.props;
    const { specs } = this.state;
    const changes = specsToItems({ specs, estate, service });
    const updatedEstate = applyChanges(changes, estate);
    const items = getItemsByService(updatedEstate).landingPage;
    const price = items != null
      ? items
          .map((item) => item.estate != null ? item : { ...item, estate: updatedEstate })
          .reduce((memo, i) => memo + priceItem(i, this.props.sessionUser.currency.code, true), 0)
      : 0;
    this.setState({ price });
    return { changes, updatedEstate, price };
  }

  @autobind
  _handleClickOption(_, key) {
    const { specs, custom } = this.state;
    const { copywriting, numberOfLanguages } = specs;
    const newSpecs = { ...specs, standard: false, [key]: ! specs[key] };
    const disableOptions =! newSpecs.standard;
    this.setState({
      custom: custom ? ! custom : custom,
      specs: {
        ...newSpecs,
        copywriting: disableOptions ? false : copywriting,
        numberOfLanguages: disableOptions ? 1 : numberOfLanguages,
      }
    }, this._syncState);
  }

  @autobind
  _handleClickCustom() {
    const { custom } = this.state;
    if (custom === null) {
      openIntercom();
      this._resetSpecs(this._syncState);
    }
    else {
      const newValue = ! custom;
      this._resetSpecs(() => this.setState({ custom: newValue }, this._syncState));
    }
  }

  @autobind
  _resetSpecs(onReset) {
    this.setState({ specs: { standard: false, copywriting: false, numberOfLanguages: 1 } }, onReset);
  }

  @autobind
  _handleChangeSpec(specKey, specValue) {
    this.setState({ specs: { ...this.state.specs, [specKey]: specValue } }, this._syncState);
  }

  @autobind
  _handleClickConfirm() {
    const { onModifyService, estate } = this.props;
    const { custom } = this.state;
    const extraRemove = custom === false ? [getItemsByService(estate).landingPage.find((item) => item.product.custom)] : [];
    const { changes } = this._syncState();
    onModifyService({ ...changes, remove: [ ...changes.remove, ...extraRemove ] });
  }
}


export default LandingPage;
