import sv from '@drawbotics/drylus-style-vars';
import {
  Avatar,
  Color,
  Dropdown,
  DropdownLink,
  DropdownOption,
  DropdownSeparator,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Icon,
  ListTile,
  Margin,
  Padding,
  Popover,
  Position,
  Separator,
  Shade,
  Size,
  Text,
  TextLink,
  ThemeProvider,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import gql from 'graphql-tag';
import logo from 'images/logo.svg';
import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

import { openIntercom } from '~/pods/order/utils';
import { getHelpSeen, setHelpSeen } from '~/utils';
import { createTranslate } from '~/utils/translation';

import { removeSession } from '../utils/bootstrap/setup-session';
import { useLoadSession } from '../utils/hooks';
import { LanguageSwitcher } from './LanguageSwitcher';

const tt = createTranslate('containers.navbar');

const CartQuery = gql`
  query CartQuery {
    estates: cart {
      id
    }
  }
`;

const Dimensions = {
  height: 60,
};

const styles = {
  navbar: css`
    width: 100%;
    height: ${Dimensions.height}px;
    background: ${sv.white};
    box-shadow: ${sv.elevation2};
  `,
  dropdown: css`
    > div:first-child {
      display: flex;
    }
  `,
};

const LinkComponent = ({ href, ...rest }: { href: string }) => {
  return <Link to={href} {...rest} />;
};

export const Navbar = () => {
  const helpSeen = getHelpSeen();
  const { sessionUser, session } = useLoadSession();
  const [res] = useQuery<{ estates: Array<{ id: string }> }>({
    query: CartQuery,
  });
  const hasControls = session != null && session.type !== 'public' && sessionUser != null;

  useEffect(() => {
    if (!helpSeen) {
      setTimeout(() => {
        setHelpSeen();
      }, 5000);
    }
  }, []);

  return (
    <ThemeProvider injectGlobal={false} style={{ width: '100%' }}>
      <div className={styles.navbar}>
        <Padding size={{ horizontal: Size.DEFAULT }} style={{ height: '100%' }}>
          <Flex justify={FlexJustify.SPACE_BETWEEN} style={{ height: '100%' }}>
            <FlexItem flex>
              <div style={{ display: 'inline-block' }}>
                <Link to="/">
                  <img src={logo} height="30" />
                </Link>
              </div>
            </FlexItem>
            <FlexItem>
              <Popover
                exitOnClick
                style={{ marginTop: sv.defaultMargin }}
                openOnMount={!helpSeen}
                side={Position.BOTTOM}
                content={
                  <Fragment>
                    <Text size={Size.SMALL} inversed>
                      {tt('got_questions')}
                    </Text>
                    <Margin size={{ bottom: Size.EXTRA_SMALL }} />
                    <Text inversed size={Size.SMALL}>
                      {tt('dont_hesitate_to')}
                      &nbsp;
                      <span onClick={openIntercom}>
                        <TextLink>{tt('contact_us')}</TextLink>
                      </span>
                    </Text>
                  </Fragment>
                }>
                <Avatar text="?" color={Color.BLUE} size={Size.SMALL} />
              </Popover>
            </FlexItem>
            {hasControls ? (
              <FlexItem>
                <Flex>
                  <FlexSpacer size={Size.DEFAULT} />
                  <FlexItem>
                    <Link to="/">
                      <Text style={{ textTransform: 'capitalize' }}>{tt('home')}</Text>
                    </Link>
                  </FlexItem>
                  <FlexSpacer size={Size.DEFAULT} />
                  <FlexItem style={{ display: 'flex' }}>
                    <Dropdown
                      className={styles.dropdown}
                      trigger={
                        <ListTile
                          leading={<Icon shade={Shade.DARK} name="credit" />}
                          title={`${tt('credits')} (${sessionUser!.credits})`}
                        />
                      }>
                      <DropdownLink
                        linkComponent={LinkComponent}
                        text={tt('buy_credits')}
                        href="/transactions/new"
                      />
                      <Margin size={{ top: Size.EXTRA_SMALL }} />
                      <DropdownLink
                        linkComponent={LinkComponent}
                        text={tt('invoices')}
                        href="/transactions"
                      />
                      <Margin size={{ top: Size.EXTRA_SMALL }} />
                      <DropdownLink
                        linkComponent={LinkComponent}
                        text={tt('orders')}
                        href="/transactions/orders"
                      />
                    </Dropdown>
                  </FlexItem>
                  <FlexSpacer size={Size.DEFAULT} />
                  <FlexItem>
                    <Link to="/order/cart" style={{ display: 'flex' }}>
                      <ListTile
                        leading={<Icon shade={Shade.DARK} name="shopping-cart" />}
                        title={`${tt('cart')} (${res.data?.estates.length ?? 0})`}
                      />
                    </Link>
                  </FlexItem>
                </Flex>
              </FlexItem>
            ) : null}
            <FlexSpacer size={Size.DEFAULT} />
            <FlexItem>
              <Separator style={{ height: 30, backgroundColor: sv.neutralDark }} vertical />
            </FlexItem>
            <FlexSpacer size={Size.SMALL} />
            <FlexItem>
              <LanguageSwitcher />
            </FlexItem>
            <FlexSpacer size={Size.SMALL} />
            <FlexItem>
              <Dropdown
                trigger={<Text>{sessionUser?.firstName ?? <Icon name="chevron-down" />}</Text>}>
                {hasControls ? (
                  <Fragment>
                    <DropdownLink
                      linkComponent={LinkComponent}
                      text={tt('profile')}
                      href="/settings"
                    />
                    <Margin size={{ top: Size.EXTRA_SMALL }} />
                    <DropdownLink
                      linkComponent={LinkComponent}
                      text={tt('address_book')}
                      href="/addresses"
                    />
                    <DropdownSeparator />{' '}
                  </Fragment>
                ) : null}
                <DropdownOption
                  style={{ paddingRight: sv.paddingHuge }}
                  text={tt('sign_out')}
                  leading={<Icon name="log-out" />}
                  onClick={() => {
                    removeSession();
                    window.location.replace('/auth/login');
                  }}
                />
              </Dropdown>
            </FlexItem>
          </Flex>
        </Padding>
      </div>
    </ThemeProvider>
  );
};
