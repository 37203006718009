import { css } from 'emotion';


export default {
  briefing: css`
    display: flex;
    flex-direction: column;
    flex: 1;
  `,
};
