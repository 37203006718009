import { DrylusProvider } from '@drawbotics/react-drylus';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { RouterProvider } from '~/components';
import Route, { Switch } from '~/components/Route';

import { SettingsView } from './settings';

interface SettingsProps extends RouteComponentProps {}

export const SettingsRoute = ({ history, location, match }: SettingsProps) => {
  return (
    <DrylusProvider
      injectGlobal={false}
      style={{
        width: '100%',
        height: '100%',
      }}>
      <RouterProvider value={{ history, location, match }}>
        <Switch>
          <Route path="/" component={SettingsView} />
        </Switch>
      </RouterProvider>
    </DrylusProvider>
  );
};
