const k = (key) => `media_kit.briefing.${key}`;


const residential = {
  1: {
    type: 'section',
    filter: {
      type: 'service',
      value: 'brandId',
    },
    components: [
      {
        type: 'component',
        label: k('branding'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('select_branding'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'SingleSelect',
        key: 'brandId',
        keyLabel: k('keys.brand_id'),
        required: 'all',
        values: [
          {
            image: require('../images/shared/company-branding.jpg'),
            value: 'company',
            label: k('branding'),
            meta: {
              type: 'meta',
              noToggle: true,
              components: [
                {
                  type: 'question-component',
                  label: k('upload_company_files'),
                  component: 'FileUploader',
                  key: 'brandIdAttachments',
                  keyLabel: k('keys.brand_attachments'),
                  required: 'all',
                },
              ],
            },
          }, {
            image: require('../images/shared/project-branding.jpg'),
            value: 'project',
            label: k('project_branding'),
            meta: {
              type: 'meta',
              condition: {
                required: 'one',
                conditions: [{
                  type: 'service',
                  value: 'brandId',
                }],
              },
              components: [
                {
                  type: 'question-component',
                  label: k('upload_project_files'),
                  component: 'FileUploader',
                  key: 'brandIdAttachments',
                  keyLabel: k('keys.brand_attachments'),
                  required: 'all',
                },
              ],
            },
          },
        ],
      },
    ],
  },
  2: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'shared.visuals',
        component: 'Title',
      },
      {
        type: 'component',
        label: 'shared.visuals_note',
        component: 'Note',
      },
      
      {
        type: 'question-component',
        component: 'SingleSelect',
        key: 'visuals',
        keyLabel: 'shared.keys.visuals',
        required: 'all',
        values: [
          {
            value: 'drawbotics',
            label: 'shared.drawbotics',
            image: require('../images/shared/drawbotics.jpg'),
          },
          {
            value: 'upload_my_own',
            image: require('../images/shared/my-own.jpg'),  
            label: 'shared.upload_my_own',
            meta: {
              type: 'meta',
              noToggle: true,
              components: [
                {
                  type: 'question-component',
                  label: 'shared.upload_visuals',
                  component: 'FileUploader',
                  key: 'visualsAttachments',
                  keyLabel: 'shared.keys.visuals_attachments',
                },
              ],
            },
          },
          {
            value: 'other',
            label: 'shared.other',
            image: require('../images/shared/other.jpg'),            
            meta: {
              type: 'meta',
              noToggle: true,
              components: [
                {
                  type: 'question-component',
                  component: 'Input',
                  label: 'shared.other_visuals',
                  key: 'otherVisuals',
                  keyLabel: 'shared.keys.other_visuals',
                },
              ],
            },
          },
        ],
      },
    ],
  },
  3: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'shared.additional_info',
        component: 'Title',
      }, {
        type: 'component',
        label: 'shared.anything_to_add',
        component: 'Note',
      }, {
        type: 'question-component',
        label: 'shared.additional_comments',
        component: 'Input',
        key: 'otherInfo',
        keyLabel: 'shared.keys.other_info',
      }, {
        type: 'question-component',
        label: 'shared.upload_files',
        component: 'FileUploader',
        key: 'otherInfoAttachments',
        keyLabel: 'shared.keys.other_info_attachments',
      },
    ],
  },
};


export default { residential };
