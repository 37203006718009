import { gqlFetch, ze } from '@tokamakjs/common';
import { Injectable } from '@tokamakjs/react';
import urljoin from 'url-join';

import { AuthService } from '~/services';

import { ProjectStatus } from '../types';
import { ProjectsCountQuery, ProjectsQuery } from './queries';
import { ProjectDto } from './validation';

@Injectable()
export class ProjectsApi {
  constructor(private readonly _authService: AuthService) {}

  public async fetchProjects(
    start: number,
    count: number,
    search?: string,
    status?: ProjectStatus,
  ): Promise<{ projects: Array<ProjectDto>; total: number }> {
    const { authToken } = this._authService;

    const response = await gqlFetch({
      url: urljoin(process.env.GRAPHQL_HOST, '/api/frontoffice/v1/graphql'),
      headers: { Authorization: `Bearer ${authToken}` },
      query: ProjectsQuery,
      variables: {
        skip: start,
        first: count,
        filters: { search, status },
      },
    });

    const body = await response.json();
    const { projects = [] } = body?.data;

    const total = await this.countProjects(search, status);

    return { projects: projects.map((p: unknown) => ze.validate(p, ProjectDto)), total };
  }

  public async countProjects(search?: string, status?: ProjectStatus): Promise<number> {
    const { authToken } = this._authService;

    const response = await gqlFetch({
      url: urljoin(process.env.GRAPHQL_HOST, '/api/frontoffice/v1/graphql'),
      headers: { Authorization: `Bearer ${authToken}` },
      query: ProjectsCountQuery,
      variables: { filters: { search, status } },
    });

    const body = await response.json();
    const { estateCount } = body?.data;

    return Number(estateCount);
  }
}
