import sv from '@drawbotics/style-vars';
import { css } from 'emotion';

export default {
  payment: css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  `,
  container: css`
    width: 100%;
    height: 100%;
    max-width: 1200px;
    flex: 1;
    display: flex;
    margin: auto;
    padding: 0 ${sv.basePadding};

    @media ${sv.ipad} {
      background: ${sv.white};
    }

    @media ${sv.phoneXl} {
      padding: 0;
    }
  `,
};
