import React, { CSSProperties, ReactNode } from 'react';

interface FormProps {
  onSubmit: VoidFunction;
  children: ReactNode;
  style?: CSSProperties;
}

export const Form = ({ onSubmit, children, style }: FormProps) => {
  return (
    <form
      style={style}
      onKeyDown={(event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault();
          onSubmit();
        }
      }}>
      {children}
    </form>
  );
};
