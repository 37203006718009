import React, { Fragment } from 'react';
import {
  Tooltip,
  showTooltip,
  hideTooltip,
  Icon,
} from 'react-ittsu/components';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';
import autobind from 'autobind-decorator';
import PropTypes from 'prop-types';


export const styles = {
  feature: css`
    display: flex;
    align-items: center;
    font-size: 0.9rem;
  `,
  check: css`
    color: ${sv.brandGreen};
    font-size: 0.7rem;
    margin-right: 7px;
  `,
  close: css`
    color: ${sv.brandRed};
    font-size: 0.7rem;
    margin-right: 7px;
  `,
  label: css`
    color: ${sv.textPrimaryDark};
    font-size: 0.9em;
  `,
  tooltip: css`
    color: ${sv.textTertiaryDark};
    margin-left: 5px;
  `,
}


class Feature extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    infoText: PropTypes.string,
  };

  state = {
    tooltipState: {
      visible: false,
      parentRect: null,
    },
  }

  render() {
    const { tooltipState } = this.state;
    const { text, infoText, isCheck=true } = this.props;
    return (
      <div className={styles.feature}>
        <Icon name={isCheck ? 'check-bold' : 'close-bold'} className={isCheck ? styles.check : styles.close} />
        <div className={styles.label}>
          {text}
        </div>
        {do{
          if (infoText) {
            <Fragment>
              <div className={styles.tooltip} data-test-selector="tooltip">
                <i
                  className="ion-help-circled"
                  onMouseEnter={this._handleShowTooltip}
                  onMouseLeave={this._handleHideTooltip} />
              </div>
              <Tooltip
                label="tooltipState"
                text={true}
                visible={tooltipState.visible}
                parentRect={tooltipState.parentRect}>
                {infoText}
              </Tooltip>
            </Fragment>
          }
        }}
      </div>
    );
  }

  @autobind
  _handleShowTooltip(e) {
    const { tooltipState } = this.state;
    this.setState({ tooltipState: showTooltip(tooltipState, e) });
  }

  @autobind
  _handleHideTooltip() {
    const { tooltipState } = this.state;
    this.setState({ tooltipState: hideTooltip(tooltipState) });
  }
}


export default Feature;
