import { Add, Switch, RelativeAdd } from 'drawbotics-pricer';


const description = {
  Base: [
    Switch('product.id').is({
      'model360_standard': [
        Add({ EUR: 1250, GBP: 1230, USD: 1500 }),
      ],
      'model360_premium': [
        Add({ EUR: 2000, GBP: 1800, USD: 2400 }),
      ],
    }),
  ],
  Delivery: [
    Switch('estate.delivery').is({
      'relaxed': RelativeAdd(-0.1).from('Base'),
      'standard': RelativeAdd(0).from('Base'),
      'express': RelativeAdd(0.5).from('Base'),
    })
  ],
};


export default description;
