import React from 'react';
import gql from 'fraql';
import { Radio } from 'react-ittsu/forms';
import { css, cx } from 'emotion';
import sv from '@drawbotics/style-vars';

import { createTranslate } from '~/utils/translation';
import countries from '~/utils/countries';


const t = createTranslate('pods.payments.components.address');


export const styles = {
  address: css`
    padding: 0 ${sv.baseMarginSmall};
  `,
  noRadioButton: css`
    padding-left: 0;

    [data-element="addressHeader"] {
      padding-top: 0;
    }

    [data-element="addressInfo"] {
      padding-left: 0;
    }
  `,
  addressHeader: css`
    border-bottom: 1px solid ${sv.grey200};
    display: flex;
    align-items: baseline;
    padding: ${sv.baseMarginSmall} 0;
    margin-bottom: ${sv.baseMarginSmall};
  `,
  addressName: css`
    font-size: 1.1rem;
  `,
  addressEdit: css`
    justify-self: flex-end;
    margin-left: auto;
    color: ${sv.brandPrimary};

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  `,
  addressInfo: css`
    padding-left: ${sv.baseMargin};
    line-height: 1.5;
  `,
};


const Address = ({
  address,
  checked,
  noRadioButton,
  onClickEdit,
  onChange,
}) => {
  const country = countries[address.countryCode] || {};
  return (
    <div className={cx(styles.address, { [styles.noRadioButton]: noRadioButton })}>
      <div className={styles.addressHeader} data-element="addressHeader">
        {do {
          if ( ! noRadioButton) {
            <Radio checked={checked} onChange={() => onChange(address)}>
              <div className={styles.addressName}>
                {address.label}
              </div>
            </Radio>
          }
          else {
            <div className={styles.addressName}>
              {address.label}
            </div>
          }
        }}
        {do{
          if (onClickEdit) {
            <div className={styles.addressEdit} onClick={() => onClickEdit(address)}>
              {t('edit')}
            </div>
          }
        }}
      </div>
      <div className={styles.addressInfo} data-element="addressInfo">
        {address.street} <br />
        {address.zipCode} {address.city} <br />
        {country.name} <br />
        {address.vat} <br />
      </div>
    </div>
  );
};


Address.fragments = {
  Address: gql`
    fragment _ on Address {
      id
      label
      street
      zipCode
      city
      countryCode
      vat
    }
  `,
};


export default Address;
