import isMatch from 'lodash/isMatch';
import isEmpty from 'lodash/isEmpty';

import { add, remove } from '../utils';


function _equalDetails(itemDetails, defaultDetails) {
  if (isEmpty(itemDetails) && isEmpty(defaultDetails)) {
    return true;
  }
  if (isEmpty(itemDetails) && defaultDetails == null) {
    return true;
  }
  if (itemDetails == null && isEmpty(defaultDetails)) {
    return true;
  }
  return isMatch(itemDetails, defaultDetails);
}


function _handleBoolType({ memo, create, estate, service, specValue }) {
  const { product, with: defaultDetails } = create;
  const productItem = estate.items
    .filter((item) => _equalDetails(item.details, defaultDetails))
    .find((item) => item.product.id === product);
  if (specValue) {
    return add(memo, productItem != null
      ? []
      : [{ product: { id: product }, details: defaultDetails == null ? {} : defaultDetails, service }]);
  }
  else {
    return remove(memo, productItem != null ? [productItem] : []);
  }
}


function _handleNumberType({ memo, create, estate, service, specValue }) {
  const { product, with: defaultDetails } = create;
  const productItems = estate.items
    .filter((item) => item.product.id === product)
    .filter((item) => _equalDetails(item.details, defaultDetails));
  if (specValue > productItems.length) {
    const newItems = new Array(specValue - productItems.length).fill(0).map((_) => ({
      product: { id: product },
      details: defaultDetails == null ? {} : defaultDetails,
      service,
    }));
    return add(memo, newItems);
  }
  else if (specValue < productItems.length) {
    // return remove(memo, productItems.slice(specValue));
    return remove(memo, productItems.slice((-1) * (productItems.length - specValue)));
  }
  else {
    return memo;
  }
}


export default function runCreate(memo, descriptionValue, specValue, { service, estate }) {
  const { create } = descriptionValue;
  const { type } = create;
  if (type === 'bool') {
    return _handleBoolType({ memo, create, estate, service, specValue });
  }
  else if (type === 'number') {
    return _handleNumberType({ memo, create, estate, service, specValue });
  }
  else {
    throw new Error(`Unknown type ${type} in create operation`);
  }
}
