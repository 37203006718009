import React from 'react';

import { translate as t, createTranslate } from 'utils/translation';
import Link from 'components/Link';

import styles from 'pods/studio/styles/components/status-bar';
import stylesSelector from 'pods/studio/styles/components/draft-selector';


const tt = createTranslate('pods.studio.components.status_bar');

const getServiceName = (service) => t(`services.${service}`, null, service);


const ProjectInfo = ({
  id,
  serviceSlug,
  reference,
  name,
  estateId,
}) => {
  return (
    <div className={styles.projectInfo}>
      <div className={styles.serviceIcon}>
        <i className={`dbi dbi-${serviceSlug}`} />
      </div>
      <div className={styles.projectDetails}>
        <span className={styles.serviceName}>{getServiceName(serviceSlug)}</span>
        <span className={styles.projectRef}>
          <Link href={`/projects/${estateId}/production/${id}`}>REF: {reference}</Link>
        </span>
        <h1 className={styles.projectName}>{name}</h1>
      </div>
    </div>
  );
};


const Followers = ({
  followers=[],
}) => {
  return (
    <div className={styles.projectMembers}>
      {followers.map((follower, i) => (
        <div key={i}>{follower.getInitials()}</div>
      ))}
    </div>
  );
};


const DraftSelector = ({
  drafts,
  currentDraft,
  onChangeDraft,
}) => {
  return (
    <select
      className={stylesSelector.draftSelector}
      value={currentDraft}
      onChange={e => onChangeDraft(Number(e.target.value))}>
      {drafts.map((draft, i) => (
        <option key={i} value={draft.id}>
          ({tt('draft_number', { number: drafts.length - i })})
        </option>
      ))}
    </select>
  );
};


const StatusBar = ({
  project,
  currentDraftId,
  onChangeDraft,
}) => {
  return (
    <div className={styles.statusBar}>
      <ProjectInfo {...project} />
      <div>
        <Followers followers={project.followers} />
        <div className={styles.projectStatus}>
          <span>
            <strong>{tt('status')}:</strong> {t(`pods.dashboard.status.${project.status}.title`)}
          </span>
          <DraftSelector
            drafts={project.sortedDrafts()}
            currentDraft={currentDraftId}
            onChangeDraft={onChangeDraft} />
        </div>
      </div>
    </div>
  );
};


export default StatusBar;
