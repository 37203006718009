import React, { Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  Panel,
  PanelHeader,
  PanelTitle,
  PanelBody,
  Button,
  Tag,
} from 'react-ittsu/components';
import { Input, FormGroup, Label, Select, Form } from 'react-ittsu/forms';
import { Grid, Cell, Container } from 'react-ittsu/layout';
import countries, { europeanCountryCodes } from 'utils/countries';

import { translate as t, createTranslate } from 'utils/translation';
import isAuthenticated from 'utils/is-authenticated';
import { updateSessionUser, showAlert } from 'actions';
import { getSessionUser } from 'selectors';
import {
  createAddress,
  deleteAddress,
  updateOrganization,
  updateAddress,
} from 'pods/profile/actions';
import { updateInvitation, createInvitation } from 'actions/invitation';
import RouteContainer from 'components/RouteContainer';
import Spinner from 'components/Spinner';

import styles from 'pods/profile/styles/routes/profile';


const et = createTranslate('forms.errors');
const tt = createTranslate('pods.profile.routes.profile');


class Profile extends React.Component {

  state = {
    addresses: [],
    showOrganizationForm: false,
    organizationName: '',
    addressLoading: false,
    countryCode: '',
    newOrganizationName: '',
    oldEmail: '',
  };

  constructor(props) {
    super(props);
    this._handleOrganizationNameField = this._handleOrganizationNameField.bind(this);
    this._createOrganization = this._createOrganization.bind(this);
    this._updateUser = this._updateUser.bind(this);
    this._addNewAddress = this._addNewAddress.bind(this);
    this._setAddressAsDefault = this._setAddressAsDefault.bind(this);
    this._createInvitation = this._createInvitation.bind(this);
    this._addNewOrganization = this._addNewOrganization.bind(this);
    this._changeInvitationRole = this._changeInvitationRole.bind(this);

    // eslint-disable-next-line
    this.state.organizationName = this._getDefaultOrganizationName(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ organizationName: this._getDefaultOrganizationName(props), oldEmail: props.sessionUser.email });
  }

  render() {
    const { sessionUser } = this.props;
    if ( ! sessionUser) {return null}
    const { countryCode, showOrganizationForm, organizationName, newOrganizationName } = this.state;
    const addresses = get(sessionUser, 'organization.addresses', []);
    const organizationExists = organizationName !== '';
    return (
      <RouteContainer>
        <Container>
          <div className={styles.profile}>
            <div className={styles.section}>
              <Panel>
                <PanelHeader>
                  <PanelTitle>
                    {tt('organisation')}
                  </PanelTitle>
                </PanelHeader>
                <PanelBody>
                  {do {
                    if (! organizationExists && ! showOrganizationForm) {
                      <div className={styles.createOrganization}>
                        <div>{tt('no_organisation')}</div>
                        <Button category="success" variation="inversed" onClick={this._createOrganization}>
                          {tt('create_organisation')}
                        </Button>
                      </div>
                    }
                  }}
                  {do {
                    if (organizationExists || showOrganizationForm) {
                      <Fragment>
                        <div className={styles.section}>
                          <Grid withHGutters>
                            <Cell size="1of2" responsive="m1of1">
                              <Form>
                                <FormGroup>
                                  <Label>{tt('organisation_name')}</Label>
                                  <Input
                                    name="newOrganizationName"
                                    placeholder={tt('organisation_name')}
                                    value={organizationExists ? organizationName : (newOrganizationName || '')}
                                    disabled={organizationExists || ! showOrganizationForm}
                                    onChange={this._handleOrganizationNameField} />
                                </FormGroup>
                              </Form>
                            </Cell>
                          </Grid>
                        </div>
                        {addresses.sort((a, b) => a.id - b.id).sort((a) => a.default ? -1 : 1).map((address, i) => (
                          <div key={i} className={styles.section}>
                            <Grid withHGutters style={{ marginBottom: '30px' }}>
                              <Cell size="1of2" responsive="m1of1">
                                <Label>
                                  <span>
                                    {`${tt('address.street')} #${i+1}`} &nbsp;
                                  </span>
                                  {do {
                                    if (address.default) {
                                      <Tag color="brand">{tt('is_default')}</Tag>
                                    }
                                  }}
                                  {do {
                                    if ( ! address.hasOwnProperty('default')) {
                                      <span className={styles.spinner}>
                                        <Spinner size={25} />
                                      </span>
                                    }
                                  }}
                                </Label>
                                <FormGroup>
                                  <Input disabled value={address.label} />
                                </FormGroup>
                                <FormGroup>
                                  <Input disabled value={address.street} />
                                </FormGroup>
                                <FormGroup>
                                  <Input disabled value={address.zipCode} />
                                </FormGroup>
                                <FormGroup>
                                  <Input disabled value={address.city} />
                                </FormGroup>
                                <FormGroup>
                                  <Select
                                    disabled
                                    values={countries.all.map(a => ({ value: a.alpha2, label: a.name }))}
                                    value={address.countryCode} />
                                </FormGroup>
                              </Cell>
                              <Cell size="1of2" responsive="m1of1" className={styles.actionCell}>
                                {do {
                                  if (europeanCountryCodes.includes(address.countryCode)) {
                                    <FormGroup>
                                      <Label>{tt('vat')}</Label>
                                      <Input disabled value={address.vat || ''} />
                                    </FormGroup>
                                  }
                                }}
                                <FormGroup>
                                  <Label>Options</Label>
                                  <Button
                                    category="primary"
                                    disabled={address.default || ! address.hasOwnProperty('default')}
                                    style={{ marginRight: '15px' }}
                                    type="button"
                                    onClick={() => this._setAddressAsDefault(address)}>
                                    {tt('set_default')}
                                  </Button>
                                  <Button
                                    category="danger"
                                    disabled={address.default}
                                    variation="inversed"
                                    type="button"
                                    onClick={() => this._deleteAddress(address)}>
                                    {tt('remove_address')}
                                  </Button>
                                </FormGroup>
                              </Cell>
                            </Grid>
                          </div>
                        ))}
                        <Form
                          name="address"
                          defaultValues={this._getDefaultNewAddressValues()}
                          onSubmit={showOrganizationForm ? this._addNewOrganization : this._addNewAddress}>
                          <Grid withHGutters>
                            <Cell size="1of2" responsive="m1of1">
                              <Label>{tt('add_address')}</Label>
                              <FormGroup>
                                <Input
                                  name="label"
                                  validation={(v) => v.isEmpty(et('empty_field'))}
                                  placeholder={tt('billing_name')} />
                              </FormGroup>
                              <FormGroup>
                                <Input
                                  name="street"
                                  validation={(v) => v.isEmpty(et('empty_field'))}
                                  placeholder={tt('address.street')} />
                              </FormGroup>
                              <FormGroup>
                                <Input
                                  name="zipCode"
                                  validation={(v) => v.isEmpty(et('empty_field'))}
                                  placeholder={tt('address.zip_code')} />
                              </FormGroup>
                              <FormGroup>
                                <Input
                                  name="city"
                                  validation={(v) => v.isEmpty(et('empty_field'))}
                                  placeholder={tt('address.city')} />
                              </FormGroup>
                              <FormGroup>
                                <Select
                                  name="countryCode"
                                  values={countries.all.map(a => ({ value: a.alpha2, label: a.name }))}
                                  onChange={(v, n, onChangeField) => {
                                    this.setState({ countryCode: v });
                                    onChangeField(v, n);
                                  }}/>
                              </FormGroup>
                            </Cell>
                            <Cell size="1of2" responsive="m1of1" className={styles.actionCell}>
                              <FormGroup>
                                {do {
                                  if (europeanCountryCodes.includes(countryCode)) {
                                    <Fragment>
                                      <Label>{tt('address.vat')}</Label>
                                      <Input
                                        name="vat"
                                        placeholder={tt('address.vat_example')} />
                                    </Fragment>
                                  }
                                }}
                              </FormGroup>
                              <FormGroup>
                                <Label>{tt('actions')}</Label>
                                {do {
                                  if (showOrganizationForm && ! organizationExists) {
                                    <Button category="primary">
                                      {tt('add_organization')}
                                    </Button>
                                  }
                                  else {
                                    <Button category="primary">
                                      {tt('address.add')}
                                    </Button>
                                  }
                                }}
                              </FormGroup>
                            </Cell>
                          </Grid>
                        </Form>
                      </Fragment>
                    }
                  }}
                </PanelBody>
              </Panel>
            </div>
          </div>
        </Container>
      </RouteContainer>
    );
  }

  _createOrganization() {
    this.setState({ showOrganizationForm: true });
  }

  _handleOrganizationNameField(value, name) {
    this.setState({ [name]: value });
  }

  _updateUser(user) {
    const { oldEmail } = this.state;
    const { updateSessionUser } = this.props;
    updateSessionUser(user, oldEmail !== user.email);
  }

  _addNewAddress(address, resetValues) {
    const { sessionUser, createAddress } = this.props;
    const organization = get(sessionUser, 'organization.id');
    createAddress({ ...address, organization });
    resetValues();
  }

  _addNewOrganization(address, resetValues) {
    const { sessionUser, updateOrganization, createAddress, showAlert } = this.props;
    const { newOrganizationName } = this.state;
    const organization = get(sessionUser, 'organization.id');
    if (newOrganizationName === '') {
      showAlert('MISSING_ORG_NAME', 'error', t('containers.alerts.profile.errors.organization.name'));
      return;
    }
    updateOrganization({ name: newOrganizationName, id: organization });
    createAddress({ ...address, organization });
    resetValues();
  }

  _deleteAddress(address) {
    const { deleteAddress, sessionUser } = this.props;
    const organization = get(sessionUser, 'organization.id');
    deleteAddress({ ...address, organization });
  }

  _setAddressAsDefault(address) {
    const { updateAddress, sessionUser } = this.props;
    const organization = get(sessionUser, 'organization.id');
    const addresses = get(sessionUser, 'organization.addresses', []);
    const newDefaultAddress = { ...address, default: true };
    const defaultAddress = addresses.find((a) => a.default === true);
    if (defaultAddress) {
      updateAddress({ ...defaultAddress, default: false }, 'no-server');
    }
    updateAddress({ ...newDefaultAddress, organization });
  }

  _createInvitation(invitation) {
    const { sessionUser, createInvitation } = this.props;
    const organization = get(sessionUser, 'organization.id');
    createInvitation({ ...invitation, organization });
  }

  _changeInvitationRole(client) {
    const { updateInvitation, sessionUser } = this.props;
    const organization = get(sessionUser, 'organization.id');
    updateInvitation( { ...client, organization });
  }

  _getDefaultUserValues() {
    const { sessionUser } = this.props;
    return {
      id: get(sessionUser, 'id', ''),
      firstName: get(sessionUser, 'firstName', ''),
      lastName: get(sessionUser, 'lastName', ''),
      email: get(sessionUser, 'email', ''),
      phone: get(sessionUser, 'phone', ''),
      password: '',
      oldPassword: '',
    };
  }

  _getDefaultNewAddressValues() {
    const { sessionUser } = this.props;
    return {
      label: '',
      street: '',
      zipCode: '',
      city: '',
      countryCode: '',
      vat: '',
      organization: get(sessionUser, 'organization.id'),
    };
  }

  _getDefaultOrganizationName(props) {
    const { sessionUser } = props;
    return get(sessionUser, 'organization.name', '');
  }

}


const mapStateToProps = (state) => ({
  sessionUser: getSessionUser(state),
});


const mapDispatchToProps = {
  createAddress,
  deleteAddress,
  updateSessionUser,
  createInvitation,
  updateOrganization,
  updateInvitation,
  updateAddress,
  showAlert,
};


export default compose(
  isAuthenticated(),
  connect(mapStateToProps, mapDispatchToProps),
)(Profile);
