function _handleBoolType(specs, product, key, items, withDetails) {
  const prevValue = specs[key] || false;
  const detailKeys = Object.keys(withDetails);
  const newValue = items.reduce((memo, item) => {
    const hasWithValues = detailKeys.every((detailKey) => item.details[detailKey] === withDetails[detailKey]);
    return item.product.id === product && hasWithValues ? true : memo;
  }, prevValue);
  return { ...specs, [key]: newValue };
}


function _handleNumberType(specs, product, key, items, withDetails) {
  const prevValue = specs[key] || 0;
  const detailKeys = Object.keys(withDetails);
  const newValue = items.reduce((count, item) => {
    const hasWithValues = detailKeys.every((detailKey) => item.details[detailKey] === withDetails[detailKey]);
    return item.product.id === product && hasWithValues ? count + 1 : count;
  }, prevValue);
  return { ...specs, [key]: newValue };
}


function _handleCreate(specs, description, key, items) {
  const { type, product, with: withDetails={} } = description.create;
  switch (type) {
    case 'number':
      return _handleNumberType(specs, product, key, items, withDetails);
    case 'bool':
      return _handleBoolType(specs, product, key, items, withDetails);
    default:
      throw new Error(`Uknown operation type ${type} for product ${product}`);
  }
}


function _handleUpdate(specs, description, key, items) {
  const { type } = description.update;
  const detailKeys = description.update[type];
  const detailValues = items.length > 0
    ? detailKeys.map((detailKey) => items[0].details[detailKey])
    : [undefined];
  // NOTE: different detail keys will have the same values with mapTo
  return { ...specs, [key]: detailValues[0] };
}


export function descriptionToSpecs(description, estate, service) {
  const items = estate.items.filter((item) => item.service.id === service.id);

  const operations = Object.keys(description).map((descriptionKey) => {
    const descriptionValue = description[descriptionKey];
    if (descriptionValue == null) {
      throw new Error(`Unknown spec key "${descriptionKey}"`);
    }
    return { operation: descriptionValue.operation, descriptionKey };
  });

  return operations
    .slice()
    .sort((a, b) => a.operation === 'create' ? -1 : 1)
    .map((operation) => operation.descriptionKey)
    .reduce((memo, descriptionKey) => {
      const descriptionValue = description[descriptionKey];
      switch (descriptionValue.operation) {
        case 'create':
          return _handleCreate(memo, descriptionValue, descriptionKey, items);
        case 'update':
          return _handleUpdate(memo, descriptionValue, descriptionKey, items);
        default:
          throw new Error(`Uknown operation ${descriptionValue.operation}`);
      }
    }, {});
}
