export async function fetchFragmentTypes(graphqlUrl, token) {
  const response = await fetch(graphqlUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      variables: {},
      query: `
        {
          __schema {
            types {
              kind
              name
              possibleTypes {
                name
              }
            }
          }
        }
      `,
    }),
  });
  const { data } = await response.json();
  const filteredTypes = data.__schema.types.filter((type) => type.possibleTypes !== null);
  data.__schema.types = filteredTypes;
  return data;
}
