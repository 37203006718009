import React from 'react';
import { Card, Icon } from 'react-ittsu/components';
import autobind from 'autobind-decorator';
import { css, cx } from 'emotion';
import sv from '@drawbotics/style-vars';


const styles = {
  briefingCard: css`
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 230px;
    display: flex;
    flex-direction: column;

    @media ${sv.phone} {
      height: 190px;
    }
  `,
  cardConfirmed: css`  
    &:after {
      content: " ";
      z-index: 10;
      display: block;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      background: ${sv.brandGreen};
      opacity: 0.4;
    }

    border: 3px solid ${sv.brandGreen};
  `,
  cardCompleted: css`  
    &:after {
      content: " ";
      z-index: 10;
      display: block;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      background: ${sv.brandPrimary};
      opacity: 0.4;
    }

    border: 3px solid ${sv.brandPrimary};
  `,
  horizontal: css`
    flex-direction: row;
  `,
  asRow: css`
    @media ${sv.phone} {
      height: 100px;
      flex-direction: row;

      & [data-element="footer"] {
        flex: 1;
        display: flex;
        align-items: center;
      }

      & [data-element="header"] {
        flex: none;
        width: 100px;
        margin-top: 0;
      }
    }
  `,
  disabled: css`
    cursor: not-allowed;
    opacity: 0.4;
  `,
  header: css`
    position: relative;
    flex: 1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    color: ${sv.white};
    background-color: ${sv.white};
    margin-top: 10px;
  `,
  footer: css`
    text-align: center;
    margin-top: 5px;
  `,
  title: css`
    color: ${sv.textPrimaryDark};
  `,
  info: css`
    flex: 0.8;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-right: ${sv.basePadding};
  `,
  description: css`
    margin-top: ${sv.baseMarginSmall};
    color: ${sv.textSecondaryDark};
    font-size: 0.7rem;
  `,
  checksContainer: css`
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    z-index: 99;
  `,
  checkcircle: css`
    height: 30px;
    width: 30px;
    border-radius: 1000px;
    background: ${sv.grey200};
    display: flex;
    align-items: center;
    justify-content: center;
    color: transparent;
    margin-left: 10px;
    font-size: 0.8rem;

    &:hover {
      [data-element="tooltip"] {
        display: block
      }
    }

    @media ${sv.phone} {
      height: 20px;
      width: 20px;
      font-size: 0.6rem;
    }
  `,
  confirmed: css`
    background: ${sv.brandGreen};
    color: ${sv.white};
  `,
  completed: css`
    background: ${sv.brandPrimary};
    color: ${sv.white};
  `,
  tooltip: css`
    position: fixed;
    z-index: 9999;
    background: ${sv.grey800};
    color: ${sv.white};
    border-radius: ${sv.baseBorderRadius};
    left: 0;
    bottom: 100%;
    transform: translateX(50%);
    padding: 5px;
    text-align: center;
    display: none;
  `,
};


class BriefingCard extends React.Component {
  render() {
    const { title, image, description, confirmed, disabled, asRow, completed } = this.props;
    return (
      <Card
        clickable={! disabled}
        onClick={disabled ? null : this._handleClick}
        className={cx(styles.briefingCard, {
          [styles.horizontal]: description,
          [styles.disabled]: disabled,
          [styles.asRow]: asRow,
          [styles.cardCompleted]: completed,
          [styles.cardConfirmed]: confirmed,
        })}>
        <div className={styles.checksContainer}>
          <div className={cx(styles.checkcircle, {
            [styles.completed]: completed,
            [styles.confirmed]: confirmed,
          })}>
            {do {
              if (confirmed) {
                <Icon name="check-bold" />
              }
              else if (completed) {
                <Icon bold name="clock" />
              }
            }}
            
          </div>
        </div>
        <div
          ref={(cover) => this.cover = cover}
          data-element="header"
          className={styles.header}
          style={{ backgroundImage: `url(${image})` }}>
        </div>
        {do{
          if (description) {
            <div className={styles.info}>
              <div className={styles.title}>{title}</div>
              <div className={styles.description}>{description}</div>
            </div>
          }
          else {
            <div className={styles.footer} data-element="footer">
              <div className={styles.title}>{title}</div>
            </div>
          }
        }}
      </Card>
    );
  }

  @autobind
  _handleClick() {
    const { onClick } = this.props;
    const { width, height, left, top } = this.cover.getBoundingClientRect();
    onClick({ width, height, left, top });
  }

}


export default BriefingCard;
