import React from 'react';
import { Paragraph } from 'react-ittsu/components';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';

import { createTranslate } from '~/utils/translation';


const t = createTranslate('pods.order.services');


const styles = {
  note: css`
    color: ${sv.textSecondaryDark};
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: calc(${sv.baseMarginSmall} * -1);
  `,
  paragraph: css`
    max-width: 800px;
    margin: 0;
  `,
};


class Note extends React.Component {
  render() {
    const { label } = this.props;
    return (
      <div className={styles.note}>
        <Paragraph className={styles.paragraph}>{t(label, null, label)}</Paragraph>
      </div>
    );
  }
}


export default Note;
