import morphey from 'morphey';


const translationsFromServer = (address) => ({
  id: 'id',
  street: 'street',
  zipCode: 'zip_code',
  countryCode: 'country_code',
  city: 'city',
  organization: 'organisation',
  vat: 'vat',
  isValidVat: 'vat_validated',
  default: 'main',
  label: address.recipient_label ? 'recipient_label' : 'label',
});


export function addressFromServer(address) {
  return morphey(address, translationsFromServer);
}


const translationsToServer = {
  id: 'id',
  street: 'street',
  zip_code: 'zipCode',
  country_code: 'countryCode',
  city: 'city',
  organisation_id: 'organization',
  vat: 'vat',
  vat_validated: 'isValidVat',
  main: 'default',
  label: 'label',
};


export function addressToServer(address) {
  return morphey(address, translationsToServer);
}


export default {
  fromServer: addressFromServer,
  toServer: addressToServer,
};
