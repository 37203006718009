import { fork, put, call, takeLatest } from 'redux-saga/effects';

import history from 'utils/history';
import { Preview } from 'pods/studio/utils/api';
import * as PreviewsActions from 'pods/studio/actions/previews';
import * as UIActions from 'pods/studio/actions/ui';


/**
 * REGULAR SAGAS
 */
function* handleValidatePreview(action) {
  const { id, preview } = action.payload;
  yield put(UIActions.disableValidatePreviewButton());
  const result = yield call(Preview.validate, id);
  yield put(PreviewsActions.updatePreview(id, {
    validatedAt: result.validated_at,
    validatedBy: result.validator_id,
  }));
  yield put(UIActions.enableValidatePreviewButton());
  history.push(`/studio/project-item/${preview.draft.projectItem.id}`);
}

function* handleInvalidatePreview(action) {
  const { id } = action.payload;
  yield put(PreviewsActions.updatePreview(id, {
    validatedAt: null,
    validatedBy: null,
  }));
}


/**
 * WATCHERS
 */
function* watchValidatePreview() {
  yield takeLatest(PreviewsActions.VALIDATE_PREVIEW, handleValidatePreview);
}

function* watchInvalidatePreview() {
  yield takeLatest(PreviewsActions.INVALIDATE_PREVIEW, handleInvalidatePreview);
}


/**
 * ROOT
 */
export default function* root() {
  yield fork(watchValidatePreview);
  yield fork(watchInvalidatePreview);
}
