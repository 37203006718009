import morphey, { fromValue, fromKey } from 'morphey';
import Stripe from 'stripe';

import Return from './Return';
import Bancontact from './bancontact';
import CreditCard from './credit-card';
import Ideal from './ideal';


if (process.env.APP_ENV === 'production') {
  Stripe.setPublishableKey('pk_live_4X44TrIsigOxHQyAEZoOTZTu');
}
else {
  Stripe.setPublishableKey('pk_test_CinUcOV5l4rxcVqXWbZGtwMp');
}


function getCurrency(currency) {
  if ( ! currency) {
    return currency;
  }
  switch (currency) {
    case '$': {
      return 'usd';
    }
    case '€': {
      return 'eur';
    }
    case '£': {
      return 'gbp';
    }
    default: {
      return currency;
    }
  }
}


export function extractDataFromTransaction(type, transaction) {
  return morphey(transaction, {
    type: fromValue(type),
    amount: fromKey('priceWithTaxes').using((v) => Math.round(v * 100)),
    currency: fromKey('currency').using(getCurrency).defaultTo('eur'),
    'owner.name': fromKey('buyer').using((v) => v.getName()),
    redirect: fromValue({
      return_url: window.location.origin + '/return',
    }),
  });
}


export {
  Bancontact,
  Return,
  CreditCard,
  Ideal,
};
