import React from 'react';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';
import { FormGroup, Input, Label, TextArea } from 'react-ittsu/forms';
import { Grid, Cell } from 'react-ittsu/layout';
import { Paragraph } from 'react-ittsu/components';
import autobind from 'autobind-decorator';
import gql from 'fraql';

import { translate as t, createTranslate } from '~/utils/translation';


const tt = createTranslate('pods.order.routes.briefing_estate.id_card');


const styles = {
  idCard: css`
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.15);
  `,
  header: css`
    padding: calc(${sv.basePadding} * 1.5) ${sv.basePadding};
    color: ${sv.white};
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(to right, ${sv.brandBlue}, ${sv.brandPrimary});

    @media ${sv.phone} {
      flex-direction: column;
      align-items: flex-start;
      padding: ${sv.basePaddingSmall};
    }
  `,
  title: css`
    font-size: 1.4rem;
    margin-bottom: ${sv.baseMarginSmall};
  `,
  subtitle: css`
    font-size: 0.9rem;
  `,
  address: css`
    max-width: 250px;

    @media ${sv.phone} {
      margin-top: ${sv.baseMargin};
    }
  `,
  body: css`
    background: ${sv.white};
    padding: ${sv.basePadding};

    @media ${sv.phoneXl} {
      padding: ${sv.basePadding} ${sv.basePaddingSmall};
    }
  `,
  delete: css`
    margin-left: 5px;
    font-size: 0.7rem;

    &:hover {
      cursor: pointer;
    }
  `,
  note: css`
    color: ${sv.textSecondaryDark};
    font-size: 0.75rem;
    font-style: italic;
  `,
  error: css`
    color: ${sv.brandRed};
    font-size: 0.9rem;
    font-weight: bold;
    margin-bottom: ${sv.baseMargin};
    text-align: center;
  `,
  paragraph: css`
    color: ${sv.textSecondaryDark};
  `,
};


class IDCard extends React.Component {
  state = {
    fields: {},
  }

  static fragments = {
    Estate: gql`
      fragment _ on Estate {
        id
        name
        projectType
        address {
          id
          label
        }
        itemsByService @client(type: Estate)
        items {
          id
          service {
            id @client(type: ProductService)
          }
        }
        details {
          keyPoints
          description
          targetAudience
          projectTimeline
          location
          contactEmail
          contactPhone
          contactWebsite
        }
      }
    `,
  }

  render() {
    const { estate, displayErrors, communicationServices=[] } = this.props;
    const { fields } = this.state;
    const details = estate.details ? estate.details : {};
    const projectContactRequired = Object.keys(estate.itemsByService).some((id) => communicationServices.find((service) => service.id === id));
    return (
      <div className={styles.idCard}>
        <div className={styles.header}>
          <div>
            <div className={styles.title}>
              {estate.name}
            </div>
            <div className={styles.subtitle}>
              {t('pods.order.type_of_project', { type: t(`pods.order.project_type.${estate.projectType.toLowerCase()}`) })}
            </div>
          </div>
          <div className={styles.address}>
            {estate.address?.label}
          </div>
        </div>
        <div className={styles.body}>
          {do{
            if (displayErrors) {
              <div className={styles.error}>
                {tt('red_fields_required')}
              </div>
            }
          }}
          <Grid withHGutters>
            <Cell size="1of2" responsive="l1of1">
              <FormGroup error={(! details.description && ! fields.description) && displayErrors ? '' : null}>
                <Label>{tt('description')}*</Label>
                <TextArea
                  name="description"
                  value={this._getFieldValue(fields.description, details.description)}
                  placeholder={tt('description_placeholder')}
                  onChange={this._handleChangeValue} />
              </FormGroup>
              <FormGroup error={(! details.targetAudience && ! fields.targetAudience) && displayErrors ? '' : null}>
                <Label>{tt('target_audience')}*</Label>
                <Input
                  name="targetAudience"
                  value={this._getFieldValue(fields.targetAudience, details.targetAudience)}
                  placeholder={tt('target_audience_placeholder')}
                  onChange={this._handleChangeValue} />
              </FormGroup>
              <FormGroup error={(! details.projectTimeline && ! fields.projectTimeline) && displayErrors ? '' : null}>
                <Label>{tt('development_phase')}*</Label>
                <Input
                  name="projectTimeline"
                  value={this._getFieldValue(fields.projectTimeline, details.projectTimeline)}
                  placeholder={tt('development_phase_placeholder')}
                  onChange={this._handleChangeValue} />
              </FormGroup>
            </Cell>
            <Cell size="1of2" responsive="l1of1">
              <FormGroup error={(! details.keyPoints && ! fields.keyPoints) && displayErrors ? '' : null}>
                <Label>{tt('keywords')}*</Label>
                <Input
                  name="keyPoints"
                  value={this._getFieldValue(fields.keyPoints, details.keyPoints)}
                  placeholder={tt('keywords_placeholder')}
                  onChange={this._handleChangeValue} />
              </FormGroup>
              <FormGroup>
                <Label>{tt('surroundings')}</Label>
                <TextArea
                  name="location"
                  value={this._getFieldValue(fields.location, details.location)}
                  placeholder={tt('surroundings_placeholder')}
                  onChange={this._handleChangeValue} />
              </FormGroup>
            </Cell>
          </Grid>
          <div className={styles.note}>
            *{tt('fields_mandatory')}
          </div>
        </div>
        {do{
          if (projectContactRequired) {
            <div className={styles.body} style={{ paddingTop: 0 }}>
              <Paragraph className={styles.paragraph}>
                {tt('contact_info_description')}
              </Paragraph>
              <Grid withHGutters>
                <Cell size="1of2" responsive="l1of1">
                  <FormGroup>
                    <Label>{tt('contact_email')}</Label>
                    <Input
                      name="contactEmail"
                      value={this._getFieldValue(fields.contactEmail, details.contactEmail)}
                      placeholder={tt('contact_email_placeholder')}
                      onChange={this._handleChangeValue} />
                  </FormGroup>
                </Cell>
                <Cell size="1of2" responsive="l1of1">
                  <FormGroup>
                    <Label>{tt('contact_phone')}</Label>
                    <Input
                      name="contactPhone"
                      value={this._getFieldValue(fields.contactPhone, details.contactPhone)}
                      placeholder={tt('contact_phone_placeholder')}
                      onChange={this._handleChangeValue} />
                  </FormGroup>
                </Cell>
                {do{
                  if (! estate.itemsByService.website) {
                    <Cell size="1of1">
                      <FormGroup>
                        <Label>{tt('contact_website')}</Label>
                        <Input
                          name="contactWebsite"
                          value={this._getFieldValue(fields.contactWebsite, details.contactWebsite)}
                          placeholder={tt('contact_website_placeholder')}
                          onChange={this._handleChangeValue} />
                      </FormGroup>
                    </Cell>
                  }
                }}
              </Grid>
            </div>
          }
        }}
      </div>
    );
  }

  @autobind
  async _handleChangeValue(value, key) {
    const { onChange } = this.props;
    clearTimeout(this[`${key}timeout`]);
    this.setState({ fields: { ...this.state.fields, [key]: value } });
    this[`${key}timeout`] = setTimeout(async () => {
      await onChange({ [key]: value });
    }, 500);
  }

  _getFieldValue(current, fallback) {
    if (current || current === '') {
      return current;
    }
    else if (fallback) {
      return fallback;
    }
    return '';
  }
}


export default IDCard;
