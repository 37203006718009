import createQueries from './queries';
import createMutations from './mutations';
import { readSession } from '../index';
import { cleanFileName, request } from './utils';


const entitiesContext = require.context('./entities', true, /\.js$/);
const translatorsContext = require.context('./translators', true, /\.js$/);


const translators = translatorsContext.keys().reduce((memo, key) => {
  const name = cleanFileName(key);
  const translator = translatorsContext(key).default;
  return {
    ...memo,
    [name]: translator,
  };
}, {});


const entities = entitiesContext.keys().reduce((memo, key) => {
  const name = cleanFileName(key);
  const entity = entitiesContext(key).default;
  return {
    ...memo,
    [name]: entity(request, memo, translators),
  };
}, {});


const mutations = createMutations(entities);
const queries = createQueries(entities);


export default {
  mutations,
  entities,
  queries,
  utils: { readSession },
};
