import {
  Avatar,
  Button,
  Category,
  Color,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  FormGroup,
  Icon,
  Label,
  Margin,
  Modal,
  Padding,
  RoundIcon,
  SearchInput,
  Separator,
  Shade,
  Size,
  Spinner,
  Text,
  TextLink,
  Tier,
  useAlert,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import gql from 'fraql';
import React, { Fragment, useState } from 'react';
import { useMutation } from 'urql';

import { Item } from '~/pods/dashboard/types';
import { generateColorFromString, useFetchOrganizationUsers } from '~/pods/dashboard/utils';
import { User } from '~/types';
import { run } from '~/utils';
import { createTranslate } from '~/utils/translation';

const tt = createTranslate('pods.dashboard.routes.notifications');

const styles = {
  scrollableList: css`
    max-height: 200px;
    overflow: auto;
  `,
};

interface FollowItemsVariables {
  id: string;
  itemIds: Array<string>;
}

interface FollowItemsResult {
  orderItems: Array<Item>;
}

const followItemsMutation = gql`
  mutation followItems($id: ID!, $itemIds: [ID]!) {
    followItems(input: { followerId: $id, itemIds: $itemIds }) {
      orderItems {
        id
      }
    }
  }
`;

interface UserListProps {
  users: Array<User>;
  onClickAdd: (user: User) => void;
  onClickRemove: (id: string) => void;
  selectedIds: Array<string>;
}

const UserList = ({ users, onClickAdd, onClickRemove, selectedIds }: UserListProps) => {
  return (
    <div className={styles.scrollableList}>
      {users.length === 0 ? (
        <Text>{tt('no_users')}</Text>
      ) : (
        users.map((user, i) => (
          <Fragment key={user.id}>
            {i !== 0 ? <Separator /> : null}
            <Padding size={{ vertical: Size.SMALL }}>
              <Flex justify={FlexJustify.START}>
                <FlexItem>
                  <Avatar
                    text={user.fullName[0]}
                    backgroundColor={generateColorFromString(user.fullName)}
                  />
                </FlexItem>
                <FlexSpacer size={Size.SMALL} />
                <FlexItem flex>
                  <Text>{user.fullName}</Text>
                </FlexItem>
                <FlexSpacer size={Size.SMALL} />
                {selectedIds.includes(user.id) ? (
                  <FlexItem>
                    <Button
                      onClick={() => onClickRemove(user.id)}
                      size={Size.SMALL}
                      tier={Tier.TERTIARY}>
                      {tt('undo')}
                    </Button>
                  </FlexItem>
                ) : null}
                <FlexSpacer size={Size.SMALL} />
                <FlexItem>
                  {!selectedIds.includes(user.id) ? (
                    <Button
                      onClick={() => onClickAdd(user)}
                      size={Size.SMALL}
                      tier={Tier.SECONDARY}
                      leading={<Icon name="plus" />}
                    />
                  ) : (
                    <RoundIcon inversed size={Size.LARGE} name="check" color={Color.GREEN} />
                  )}
                </FlexItem>
              </Flex>
            </Padding>
          </Fragment>
        ))
      )}
    </div>
  );
};

interface AddUsersModalProps {
  existingUserIds: Array<string>;
  visible: boolean;
  onClickClose: VoidFunction;
  estateId: string;
  itemIds?: Array<string>;
  refetchUsers: VoidFunction;
}

export const AddUsersModal = ({
  visible,
  onClickClose,
  estateId,
  existingUserIds,
  refetchUsers,
  itemIds,
}: AddUsersModalProps) => {
  const [userSearch, setUserSearch] = useState('');
  const [followItemsLoading, setFollowItemsLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<Array<User>>([]);
  const { showAlert } = useAlert();

  const { isLoading, users = [], error } = useFetchOrganizationUsers(estateId);
  const [, followItems] = useMutation<FollowItemsResult, FollowItemsVariables>(followItemsMutation);

  const filteredUsers = users.filter(
    (user) =>
      !existingUserIds.includes(user.id) &&
      user.fullName.toLowerCase().includes(userSearch.toLowerCase()),
  );

  const handleAddFollowers = async (users: Array<User>) => {
    if (itemIds != null) {
      setFollowItemsLoading(true);
      const resArray = await Promise.all(
        users.map((user) => followItems({ id: user.id, itemIds })),
      );
      const failedUsers = resArray.reduce(
        (memo, res, i) => (res.error != null ? [...memo, users[i].fullName] : memo),
        [] as Array<string>,
      );
      if (failedUsers.length > 0) {
        showAlert({
          category: Category.DANGER,
          text: tt('could_not_add', { users: failedUsers.join(', ') }),
        });
      } else {
        showAlert({
          category: Category.SUCCESS,
          hideDelay: 6000,
          text: tt('will_receive_nofications', {
            users: users.map((user) => user.fullName).join(', '),
          }),
        });
        setSelectedUsers([]);
        onClickClose();
      }
      refetchUsers();
      setFollowItemsLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      onClickClose={onClickClose}
      title={tt('add_members')}
      footer={
        <Flex justify={FlexJustify.SPACE_BETWEEN}>
          <FlexItem>
            <Button tier={Tier.TERTIARY} onClick={onClickClose}>
              {tt('cancel')}
            </Button>
          </FlexItem>
          <FlexItem>
            <Button
              onClick={() => handleAddFollowers(selectedUsers)}
              category={Category.BRAND}
              disabled={followItemsLoading}
              leading={followItemsLoading ? <Spinner size={Size.SMALL} /> : null}>
              {tt('add')}
            </Button>
          </FlexItem>
        </Flex>
      }>
      {run(() => {
        if (isLoading) {
          return (
            <div style={{ height: 300, width: '100%' }}>
              <Spinner fullSize />
            </div>
          );
        } else if (error != null) {
          return <Text shade={Shade.LIGHT}>{tt('could_not_load')}</Text>;
        } else {
          return (
            <Fragment>
              <UserList
                users={filteredUsers}
                onClickAdd={(user) => setSelectedUsers([...selectedUsers, user])}
                onClickRemove={(id) =>
                  setSelectedUsers(selectedUsers.filter((user) => user.id !== id))
                }
                selectedIds={selectedUsers.map((user) => user.id)}
              />
              <Margin size={{ top: Size.SMALL }} />
              <FormGroup
                label={<Label>{tt('search_company')}</Label>}
                input={
                  <SearchInput
                    placeholder={tt('search')}
                    value={userSearch}
                    onChange={setUserSearch}
                  />
                }
              />
              <Margin size={{ vertical: Size.DEFAULT }}>
                <Separator />
              </Margin>
              <Margin size={{ right: Size.EXTRA_SMALL }} style={{ display: 'inline' }}>
                <Label>{tt('not_listed')}</Label>
              </Margin>
              <a
                href={`${process.env.DBS_HOST}/settings/company-team`}
                target="_blank"
                rel="noopener noreferrer">
                <TextLink>{tt('invite_them')}</TextLink>
              </a>
            </Fragment>
          );
        }
      })}
    </Modal>
  );
};
