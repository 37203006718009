import { mergeEntities } from 'urql-computed-exchange';

// import { podsEntities } from '~/pods';
import ProductService from './ProductService';
import Product from './Product';
import Report from './Report';
import Unit from './Unit';
import ClientUser from './ClientUser';
import Item from './Item';


export default mergeEntities(
  // ...podsEntities,
  ProductService,
  Product,
  Unit,
  Report,
  ClientUser,
  Item,
);
