import { QueryEntity } from '@datorama/akita';
import { Injectable, onModuleInit } from '@tokamakjs/react';

import { ProjectsApi } from '../api';
import { ProjectsState, ProjectsStore } from '../stores';
import { ProjectStatus } from '../types';

@Injectable()
export class ProjectsService extends QueryEntity<ProjectsState> {
  private readonly _isLoading$ = this.selectLoading();
  private readonly _projects$ = this.selectAll();
  private readonly _totalProjects$ = this.select((state) => state.ui.total);

  get isLoading$() {
    return this._isLoading$;
  }

  get projects$() {
    return this._projects$;
  }

  get totalProjects$() {
    return this._totalProjects$;
  }

  constructor(private readonly _store: ProjectsStore, private readonly _api: ProjectsApi) {
    super(_store);
  }

  @onModuleInit()
  protected onModuleInit(): void {
    // make sure the store is always in a loading state when we boot the app
    this._store.setLoading(true);
  }

  public async resetAndLoadProjects(
    start: number,
    count: number,
    search?: string,
    filter?: ProjectStatus,
  ): Promise<void> {
    this._store.setLoading(true);
    const { projects, total } = await this._api.fetchProjects(start, count, search, filter);

    this._store.resetAndAdd(projects, total);
    this._store.setLoading(false);
  }
}
