import {
  Avatar,
  Button,
  Category,
  EmptyState,
  EmptyStateVariation,
  Icon,
  ListTile,
  Size,
  Spinner,
  Table,
  Tier,
  useAlert,
} from '@drawbotics/react-drylus';
import gql from 'fraql';
import React, { useState } from 'react';
import { useMutation } from 'urql';

import { Item } from '~/pods/dashboard/types';
import { ErrorType, generateColorFromString } from '~/pods/dashboard/utils';
import { User } from '~/types';
import { run } from '~/utils';
import { createTranslate } from '~/utils/translation';

const tt = createTranslate('pods.dashboard.routes.notifications');

interface UnfollowItemsVariables {
  id: string;
  itemIds: Array<string>;
}

interface UnfollowItemsResult {
  orderItems: Array<Item>;
}

const unfollowItemsMutation = gql`
  mutation unfollowItems($id: ID!, $itemIds: [ID]!) {
    unfollowItems(input: { followerId: $id, itemIds: $itemIds }) {
      orderItems {
        id
      }
    }
  }
`;

interface UsersTableProps {
  isLoading: boolean;
  error?: ErrorType;
  users: Array<User>;
  refetchUsers: VoidFunction;
  itemIds?: Array<string>;
  readOnly: boolean;
}

export const UsersTable = ({
  isLoading,
  users,
  refetchUsers,
  itemIds,
  error,
  readOnly,
}: UsersTableProps) => {
  const [{ fetching: isUnfollowingItems }, unfollowItems] = useMutation<
    UnfollowItemsResult,
    UnfollowItemsVariables
  >(unfollowItemsMutation);
  const [userToRemove, setUserToRemove] = useState<string>();
  const { showAlert } = useAlert();

  const handleRemoveUser = async (user: User) => {
    if (itemIds != null) {
      const { error } = await unfollowItems({ id: user.id, itemIds });
      if (error != null) {
        showAlert({
          category: Category.DANGER,
          text: tt('could_not_remove', { user: user.fullName }),
        });
      } else {
        showAlert({
          category: Category.SUCCESS,
          hideDelay: 6000,
          text: tt('removed', { user: user.fullName }),
        });
      }
      setUserToRemove(undefined);
      refetchUsers();
    }
  };

  const data = users.map((user) => ({
    id: user.id,
    name: (
      <ListTile
        title={user.fullName}
        subtitle={user.email}
        leading={
          <Avatar
            text={user.fullName[0]}
            backgroundColor={generateColorFromString(user.fullName)}
          />
        }
      />
    ),
    actions: (
      <Button
        onClick={() => {
          setUserToRemove(user.id);
          handleRemoveUser(user);
        }}
        disabled={(isUnfollowingItems && userToRemove === user.id) || readOnly}
        tier={Tier.SECONDARY}
        size={Size.SMALL}
        leading={
          isUnfollowingItems && userToRemove === user.id ? (
            <Spinner size={Size.SMALL} />
          ) : (
            <Icon name="user-minus" />
          )
        }>
        {tt('remove')}
      </Button>
    ),
  }));

  return (
    <Table
      data={data}
      style={readOnly ? { textAlignLast: 'start' } : undefined}
      isLoading={isLoading}
      emptyContent={run(() => {
        if (error != null) {
          return (
            <EmptyState
              variation={EmptyStateVariation.FAILED}
              description={tt('something_went_wrong')}
            />
          );
        } else if (users.length === 0) {
          return <EmptyState description={tt('no_notified_members')} />;
        }
      })}
      header={
        readOnly
          ? [{ value: 'name', label: tt('name') }]
          : [
              { value: 'name', label: tt('name') },
              { value: 'actions', label: tt('actions') },
            ]
      }
    />
  );
};
