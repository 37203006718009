import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Modal, { BaseModal } from '@drawbotics/modal';
import { css } from 'emotion';
import { Icon, T3, Paragraph, Button } from 'react-ittsu/components';
import { Input, Label } from 'react-ittsu/forms';
import sv from '@drawbotics/style-vars';

import Spinner from '~/components/Spinner';


const styles = {
  baseModal: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100vw - ${sv.baseMargin});
    max-width: 600px;
    min-width: auto;
    text-align: center;
    color: ${sv.textPrimaryDark};
    margin: ${sv.baseMarginSmall};
  `,
  actions: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: ${sv.baseMarginSmall};
  `,
  itemRow: css`
    max-width: 300px;
    width: 100%;
    margin-bottom: ${sv.baseMarginSmall};
    display: flex;
    align-items: center;
  `,
  name: css`
    color: ${sv.textPrimaryDark};
    margin-right: ${sv.baseMarginSmall};
  `,
  buttonContent: css`
    display: flex;
    align-items: center;
  `,
};


const CopyFromPreviousModal = ({
  visible,
  onClickClose,
  items,
  sourceItem,
  onClickCancel,
  onClickSave,
}) => {
  const [fillDetailVisible, setFillDetailVisible] = useState(false);
  const [units, setUnits] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  return (
    <Modal isVisible={visible} onClickOverlay={onClickClose}>
      <BaseModal
        className={styles.baseModal}
        onClickClose={onClickClose}
        closeIcon={<Icon name="close-bold" />}>
        <T3>Copy the briefing from the previous item</T3>
        {do {
          if (! fillDetailVisible) {
            <Fragment>
              <Paragraph>If you want to use the same settings as the item you just filled in, you can copy them to all the items for this service. These can them be individually tweaked if needed. Remember, you still need to confirm your choice afterwards!</Paragraph>
              <div className={styles.actions}>
                <Button onClick={onClickCancel} variation="borderless">
                  No thanks
                </Button>
                <Button onClick={() => setFillDetailVisible(true)} category="info" round>
                  Copy from previous
                </Button>
              </div>
            </Fragment>
          }
          else {
            <Fragment>
              <div style={{ marginBottom: sv.baseMarginSmall }}>
                <Paragraph>Fill in the missing information before we can copy over all the details:</Paragraph>
              </div>
              <Label>Unit reference</Label>
              <div className={styles.itemRow}>
                <div className={styles.name}>
                  {sourceItem.reference}
                </div>
                <Input disabled value={sourceItem.details.unit} />
              </div>
              {items.map((item) => (
                <div key={item.id} className={styles.itemRow}>
                  <div className={styles.name}>
                    {item.reference}
                  </div>
                  <Input
                    onChange={(v) => setUnits({ ...units, [item.id]: v })}
                    placeholder="E.g. 4A"
                    value={units[item.id] ?? ''} />
                </div>
              ))}
              <div className={styles.actions}>
                <Button onClick={onClickCancel} variation="borderless">
                  Cancel
                </Button>
                <Button
                  disabled={Object.keys(units).length !== items.length}
                  onClick={() => {
                    setIsSaving(true);
                    onClickSave(units);
                  }}
                  category="success"
                  round>
                  <div className={styles.buttonContent}>
                    <span>Save</span>
                    {do {
                      if (isSaving) {
                        <Spinner size={15} containerStyle={{ marginLeft: 5 }} inversed />
                      }
                    }}
                  </div>
                </Button>
              </div>
            </Fragment>
          }
        }}
      </BaseModal>
    </Modal>
  );
};


CopyFromPreviousModal.propTypes = {
  visible: PropTypes.bool,
  onClickClose: PropTypes.func,
  onClickCancel: PropTypes.func,
  sourceItem: PropTypes.shape({
    id: PropTypes.string,
    jsonMeta: PropTypes.shape({
      briefingCompleted: PropTypes.bool,
    }),
    reference: PropTypes.string,
    details: PropTypes.any,
  }),
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    jsonMeta: PropTypes.shape({
      briefingCompleted: PropTypes.bool,
    }),
    reference: PropTypes.string,
    details: PropTypes.any,
  })),
};


export default CopyFromPreviousModal;