import { ze } from '@tokamakjs/common';
import dayjs, { Dayjs } from 'dayjs';
import { z } from 'zod';

import { OrganizationRole } from '~/types';

import { InvitationStatus } from '../../types';

const UserSchema = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  // @ts-ignore Type instantiation is excessively deep and possibly infinite.
  role: z.nativeEnum(OrganizationRole),
});

const MemberSchema = UserSchema.extend({
  createdAt: z.string(),
});

export type MemberData = z.infer<typeof MemberSchema>;

export class Member extends ze.ClassFrom(MemberSchema) {
  public static fromData(member: MemberData): Member {
    return ze.validate(member, Member);
  }

  public toData(): MemberData {
    return JSON.parse(JSON.stringify(this));
  }

  get fullName(): string {
    return this.firstName + ' ' + this.lastName;
  }

  get type(): 'member' {
    return 'member';
  }

  get memberSince(): Dayjs {
    return dayjs(this.createdAt);
  }
}

const PartialMemberSchema = MemberSchema.partial();

export type PartialMemberData = z.infer<typeof PartialMemberSchema>;

export class PartialMember extends ze.ClassFrom(PartialMemberSchema) {
  public static fromData(member: PartialMemberData): PartialMember {
    return ze.validate(member, PartialMember);
  }
}

const InvitationSchema = UserSchema.extend({
  status: z.nativeEnum(InvitationStatus),
});

export type InvitationData = z.infer<typeof InvitationSchema>;

export class Invitation extends ze.ClassFrom(InvitationSchema) {
  public static fromData(invitation: InvitationData): Invitation {
    return ze.validate(invitation, Invitation);
  }

  public toData(): InvitationData {
    return JSON.parse(JSON.stringify(this));
  }

  get fullName(): string {
    return this.firstName + ' ' + this.lastName;
  }

  get type(): 'invitation' {
    return 'invitation';
  }
}

const PartialInvitationSchema = InvitationSchema.partial();

export type PartialInvitationData = z.infer<typeof PartialInvitationSchema>;

export class PartialInvitation extends ze.ClassFrom(PartialInvitationSchema) {
  public static fromData(invitation: PartialInvitationData): PartialInvitation {
    return ze.validate(invitation, PartialInvitation);
  }
}

export type TeammateData = MemberData | InvitationData;

export type Teammate = Member | Invitation;

export function isInvitation(teammate: TeammateData): teammate is InvitationData {
  return 'status' in teammate;
}

export function isMember(teammate: TeammateData): teammate is MemberData {
  return !isInvitation(teammate);
}
