import React from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash/flowRight';

import { withUser } from '~/utils/with-user';


const PriceLabel = ({
  amount,
  discountedAmount,
  sessionUser,
  style,
}) => {
  if (discountedAmount == null) {
    return (
      <span style={style}>
        {sessionUser.addCurrency(amount)}
      </span>
    );
  }

  return (
    <span style={style}>
      <span style={{ textDecoration: 'line-through', opacity: 0.6, marginRight: 5 }}>
        {sessionUser.addCurrency(amount)}
      </span>
      <span>
        {sessionUser.addCurrency(discountedAmount)}
      </span>
    </span>
  );
};


PriceLabel.propTypes = {
  amount: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired,
  discountedAmount: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  sessionUser: PropTypes.shape({
    addCurrency: PropTypes.func.isRequired,
  }).isRequired,
  style: PropTypes.object,
};


export default compose(
  withUser(),
)(PriceLabel);
