import React from 'react';
import { css, cx } from 'emotion';
import sv from '@drawbotics/style-vars';


const styles = {
  help: css`
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 8px);
    padding: 5px;
    background: rgba(0, 0, 0, 0.7);
    color: ${sv.white};
    font-size: 0.7rem;
    border-radius: ${sv.baseBorderRadius};
    opacity: 0;
    transition: all ${sv.baseTransitionTimeShort} ease-in-out;
    z-index: 9999;
    min-width: 50px;
    max-width: 300px;
    width: 100%;
    text-align: center;
  `,
  visible: css`
    opacity: 1;
    transform: translate(-50%, 10px);
  `,
};


const Tooltip = ({ children, visible }) => {
  return (
    <div className={cx(styles.help, { [styles.visible]: visible })} data-element="tooltip">{children}</div>
  );
};


export default Tooltip;
