import elevationPlans from '../../images/estate-file-examples/elevation-plan.jpg';
import floorPlans from '../../images/estate-file-examples/floorplan-example.png';
import sitePlan from '../../images/estate-file-examples/site-plan-example.png';


export default {
  elevationPlans,
  floorPlans,
  sitePlan,
};
