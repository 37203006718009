import sv from '@drawbotics/style-vars';
import { css, cx } from 'emotion';
import gql from 'fraql';
import compose from 'lodash/flowRight';
import React, { useEffect, useState } from 'react';
import { graphql } from 'react-apollo-redux';
import { Button, Icon } from 'react-ittsu/components';
import { FormGroup, Input, Label } from 'react-ittsu/forms';
import { connect } from 'react-redux';

import { changeCurrency } from '~/actions/session';
import Spinner from '~/components/Spinner';
import Address from '~/pods/order/components/Address';
import AddressModal from '~/pods/order/components/AddressModal';
import MainPanel from '~/pods/order/components/MainPanel';
import Title from '~/pods/order/components/Title';
import { getSessionUser } from '~/selectors';
import { currencyWithSymbol, run } from '~/utils';
import { isVatRequired } from '~/utils/countries';
import { createTranslate, translate as t } from '~/utils/translation';

import { ApolloMutation, ID } from '../types';
import { PaymentProcess, PaymentProcessAddress } from './Payment/Component';

const tt = createTranslate('pods.payments.routes.address');

export const AddressesQuery = gql`
  query Addresses {
    addresses {
      id
      main
      ${Address.fragments.Address}
    }
  }
`;

export const CreateAddressMutation = gql`
  mutation CreateAddress(
    $street: String!
    $zipCode: String!
    $city: String!
    $countryCode: String!
    $label: String
    $organizationId: ID!
    $vat: String
  ) {
    payload: createAddress(
      input: {
        street: $street
        zipCode: $zipCode
        city: $city
        countryCode: $countryCode
        label: $label
        organisationId: $organizationId
        vat: $vat
      }
    ) {
      createdAddress: address {
        id
        main
        ${Address.fragments.Address}
      }
    }
  }
`;

export const UpdateSalesOrderMutation = gql`
  mutation UpdateSalesOrder($salesOrderId: ID!, $clientReference: String!) {
    payload: updateSalesOrder(
      input: { salesOrderId: $salesOrderId, clientReference: $clientReference }
    ) {
      salesOrder {
        id
        clientReference
      }
    }
  }
`;

export const UpdatePaymentProcessMutation = gql`
  mutation UpdatePaymentProcess(
    $paymentProcessId: ID!
    $addressId: ID!
  ) {
    payload: updatePaymentProcess(
      input: {
        paymentProcessId: $paymentProcessId
        addressId: $addressId
      }
    ) {
      paymentProcess {
        id
        address {
          id
          main
          ${Address.fragments.Address}
        }
        downPaymentTransaction {
          id
          currency
        }
      }
    }
  }
`;

const styles = {
  addresses: css`
    color: ${sv.textPrimaryDark};
  `,
  addressesNote: css`
    font-size: 0.85rem;
    color: ${sv.textSecondaryDark};
    margin-bottom: ${sv.baseMargin};

    @media ${sv.phoneXl} {
      margin-bottom: ${sv.baseMarginSmall};
    }
  `,
  addAddressButton: css`
    margin: ${sv.baseMargin} 0;
    margin-bottom: calc(${sv.baseMargin} * 2);
  `,
  reference: css`
    max-width: 50%;

    @media ${sv.phoneXl} {
      max-width: 100%;
    }
  `,
  spinnerContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  `,
  addressesContainer: css`
    display: flex;
  `,
  list: css`
    flex: 1;
    margin-right: ${sv.baseMarginSmall};
  `,
  selectedAddress: css`
    flex: 1;
    margin-left: ${sv.baseMarginSmall};
  `,
  address: css`
    position: relative;
    margin-bottom: ${sv.baseMargin};
    padding: 8px;
    border-radius: ${sv.baseBorderRadius};
    transition: all ${sv.baseTransitionTimeShort} ease-in-out;

    &:hover {
      cursor: pointer;
      background: ${sv.grey50};
      box-shadow: 0px 0px 0px 1px ${sv.textPrimaryDark};

      & [data-element='arrow'] {
        opacity: 1;
        right: ${sv.baseMargin};
      }
    }
  `,
  arrow: css`
    position: absolute;
    top: 50%;
    right: calc(${sv.baseMargin} + 5px);
    transform: translateY(-50%);
    color: ${sv.brandPrimary};
    z-index: 99;
    font-size: 1.4rem;
    opacity: 0;
    transition: all ${sv.baseTransitionTimeShort} ease-in-out;

    @media ${sv.ipadLandscape} {
      display: none;
    }
  `,
  title: css`
    color: ${sv.textPrimaryDark};
    font-size: 1.1rem;
    margin-bottom: ${sv.baseMargin};
    text-align: center;
  `,
  addressContainer: css`
    background: ${sv.grey50};
    padding: ${sv.basePaddingSmall};
    box-shadow: 0px 0px 0px 1px ${sv.textPrimaryDark};
    border-radius: ${sv.baseBorderRadius};
  `,
  valid: css`
    box-shadow: 0px 0px 0px 1px ${sv.brandPrimary};
  `,
  noAddress: css`
    color: ${sv.textSecondaryDark};
    text-align: center;
  `,
  withCheck: css`
    position: relative;
  `,
  withSpinner: css`
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  check: css`
    height: 25px;
    width: 25px;
    border-radius: 1000px;
    background: ${sv.brandPrimary};
    color: ${sv.white};
    font-size: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -2px;
    right: -2px;
  `,
};

interface PaymentAddressProps {
  changeCurrency: (...args: Parameters<typeof changeCurrency>) => void;
  paymentProcess: PaymentProcess;
  refetch: () => Promise<void>;
  addressesData: {
    addresses: Array<PaymentProcessAddress>;
    loading: boolean;
  };
  sessionUser: {
    organization: {
      id: ID;
    };
  };
  goNext: VoidFunction;
  updatePaymentProcess: ApolloMutation<
    { paymentProcessId: ID; addressId: ID },
    { payload: { paymentProcess: PaymentProcess } }
  >;
  updateSalesOrder: ApolloMutation<{ salesOrderId: ID; clientReference: string }, {}>;
  checkVat: ApolloMutation<{ vat: string }, { payload: { validVAT: boolean } }>;
  updateAddress: ApolloMutation<
    PaymentProcessAddress,
    { payload: { updatedAddress: PaymentProcessAddress } }
  >;
  createAddress: ApolloMutation<
    PaymentProcessAddress & { organizationId: ID },
    { payload: { createdAddress: PaymentProcessAddress } }
  >;
}

const PaymentAddress = ({
  changeCurrency,
  addressesData,
  paymentProcess,
  sessionUser,
  updatePaymentProcess,
  updateSalesOrder,
  goNext,
  refetch,
  checkVat,
  updateAddress,
  createAddress,
}: PaymentAddressProps) => {
  const [isModalShown, setIsModalShown] = useState(false);
  const [checkedAddress, setCheckedAddress] = useState<PaymentProcessAddress>();
  const [modalExtra, setModalExtra] = useState({});
  const [updatingAddress, setUpdatingAddress] = useState(false);
  const [clientReference, setClientReference] = useState('');
  const [invalidVAT, setInvalidVAT] = useState(false);
  const [switchingAddress, setSwitchingAddress] = useState(false);

  const { addresses, loading: loadingAddresses } = addressesData;

  const _getCheckedAddress = () => {
    return paymentProcess.address ?? addresses?.find((a) => a.main) ?? checkedAddress;
  };

  useEffect(() => {
    const address = _getCheckedAddress();
    if (address != null) setCheckedAddress(address);
  }, []);

  useEffect(() => {
    const { currency } = paymentProcess.downPaymentTransaction;
    if (window.userCurrency.code !== currency) changeCurrency(currencyWithSymbol(currency));
  }, []);

  useEffect(() => {
    if (checkedAddress == null) {
      const address = _getCheckedAddress();
      if (address != null) setCheckedAddress(address);
    }
  }, [checkedAddress]);

  if (loadingAddresses) {
    return (
      <div className={styles.spinnerContainer}>
        <Spinner size={50} text={t('pods.order.loading')} />
      </div>
    );
  }

  const _getClientReference = () => {
    const defaultClientReference = paymentProcess.salesOrder?.clientReference;
    return clientReference ?? defaultClientReference ?? '';
  };

  const _goNext = async () => {
    const clientReference = _getClientReference();
    const address = _getCheckedAddress();
    await updatePaymentProcess({
      variables: { paymentProcessId: paymentProcess.id, addressId: address.id },
    });
    if (clientReference != null && paymentProcess.salesOrder != null) {
      const salesOrderId = paymentProcess.salesOrder.id;
      await updateSalesOrder({ variables: { salesOrderId, clientReference } });
    }
    goNext();
  };

  const _handleCheckAddress = async (address: PaymentProcessAddress) => {
    setCheckedAddress(address);
    setSwitchingAddress(true);
    const { data } = await updatePaymentProcess({
      variables: { paymentProcessId: paymentProcess.id, addressId: address.id },
    });
    if (
      data.payload?.paymentProcess?.downPaymentTransaction?.currency != window.userCurrency.code
    ) {
      changeCurrency(
        currencyWithSymbol(data.payload?.paymentProcess?.downPaymentTransaction?.currency),
      );
      await refetch();
    }
    setSwitchingAddress(false);
  };

  const _handleAddressChange = async (values: PaymentProcessAddress, resetValues: VoidFunction) => {
    setUpdatingAddress(true);
    let vatValidated = undefined;

    if (isVatRequired(values.countryCode) && values.vat != null) {
      const { data: result } = await checkVat({ variables: { vat: values.vat } });
      if (!result.payload.validVAT) {
        setInvalidVAT(true);
        vatValidated = false;
        setUpdatingAddress(false);
        return;
      } else {
        vatValidated = true;
      }
    }

    setInvalidVAT(false);

    if (values.id) {
      // update address
      const { data: result } = await updateAddress({ variables: { ...values, vatValidated } });
      setIsModalShown(false);
      setUpdatingAddress(false);
      _handleCheckAddress(result.payload.updatedAddress);
    } else {
      // create address
      const { data: result } = await createAddress({
        variables: { ...values, organizationId: sessionUser.organization.id, vatValidated },
        refetchQueries: [{ query: AddressesQuery }],
      });
      setIsModalShown(false);
      setUpdatingAddress(false);
      _handleCheckAddress(result.payload.createdAddress);
    }

    // Resets the address modal's inputs
    resetValues();
  };

  const { estate } = paymentProcess;
  const finalClientReference = _getClientReference();
  return (
    <MainPanel onClickNext={_goNext} disableNext={!checkedAddress}>
      <Title>{tt('title')}</Title>
      <div className={styles.addresses}>
        <div className={styles.addressesNote}>{tt('subtitle')}</div>
        {addresses.length > 0 ? (
          <div className={styles.addressesContainer}>
            <div className={styles.list}>
              {addresses.map((address) => (
                <div
                  className={styles.address}
                  key={address.id}
                  onClick={() => _handleCheckAddress(address)}>
                  <div className={styles.arrow} data-element="arrow">
                    <i className="ion-android-arrow-forward" />
                  </div>
                  <Address
                    noRadioButton
                    address={address}
                    checked={checkedAddress?.id === address.id}
                    onClickEdit={(address) => {
                      setModalExtra({ address });
                      setIsModalShown(true);
                    }}
                  />
                </div>
              ))}
            </div>
            <div className={styles.selectedAddress}>
              <div className={styles.title}>{tt('invoicing_address')}</div>
              <div
                className={cx(styles.addressContainer, { [styles.valid]: checkedAddress != null })}>
                {run(() => {
                  if (checkedAddress == null) {
                    return <div className={styles.noAddress}>{tt('no_address_set')}</div>;
                  } else if (switchingAddress) {
                    return (
                      <div className={styles.withSpinner}>
                        <Spinner />
                      </div>
                    );
                  } else {
                    return (
                      <div className={styles.withCheck}>
                        <div className={styles.check}>
                          <Icon name="check-bold" />
                        </div>
                        <Address noRadioButton address={checkedAddress} />
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        ) : null}
        <Button
          round
          category="default"
          className={styles.addAddressButton}
          onClick={() => {
            setModalExtra({});
            setIsModalShown(!isModalShown);
          }}>
          {tt('buttons.add_invoicing_address')}
        </Button>
      </div>
      {estate.isNewDevelopment ? (
        <FormGroup>
          <Label>{tt('internal_reference.label')}</Label>
          <Input
            className={styles.reference}
            placeholder={tt('internal_reference.placeholder')}
            value={finalClientReference}
            onChange={(clientReference: string) => setClientReference(clientReference)}
          />
        </FormGroup>
      ) : null}
      <AddressModal
        isVisible={isModalShown}
        modalExtra={modalExtra}
        isLoading={updatingAddress}
        invalidVAT={invalidVAT}
        onClickCancel={() => setIsModalShown(false)}
        onClickAccept={_handleAddressChange}
      />
    </MainPanel>
  );
};

PaymentAddress.fragments = {
  PaymentProcess: () => gql`
    fragment _ on PaymentProcess {
      id
      address {
        ${PaymentAddress.fragments.Address()}
      }
      estate: orderEstate {
        id
        isNewDevelopment @client(type: Estate)
      }
      downPaymentTransaction {
        id
        currency
      }
      invoices {
        id
        currency
      }
    }
  `,
  Address: () => gql`
    fragment _ on Address {
      id
      main
      ${Address.fragments.Address}
    }
  `,
};

const mapStateToProps = (state: any) => ({
  sessionUser: getSessionUser(state),
});

const mapDispatchToProps = {
  changeCurrency,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql(AddressesQuery, { name: 'addressesData' }),
  graphql(CreateAddressMutation, { name: 'createAddress' }),
  graphql(UpdateSalesOrderMutation, { name: 'updateSalesOrder' }),
  graphql(UpdatePaymentProcessMutation, { name: 'updatePaymentProcess' }),
)(PaymentAddress);
