import isEmpty from 'lodash/isEmpty';
import { getEntity } from 'entman';

import schemas from 'schemas';
import { readSession } from 'utils';


export function getSession(state) {
  return readSession();
}


export function getSessionUser(state) {
  const session = getSession(state);
  const user = isEmpty(session) ? null : getEntity(state, schemas.User, session.user);
  return user ? { ...user, session } : null;
}


export function getCurrency(state) {
  return state.session?.currency;
}
