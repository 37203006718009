import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { getJwt, TargetDomain } from '@drawbotics/auth';

import { getSession } from 'selectors';
import { showAlert } from 'actions';
import { translate as t } from 'utils/translation';
import { decodeToken } from '~/utils/bootstrap/setup-session';


function _handlePublicStudio(session, location) {
  const authorizedProjectId = get(session, 'extra.item', null);
  const [ , currentProjectId ] = location.pathname.match(/project-item\/(\d+)/);
  return session.authToken != null && parseInt(currentProjectId) === authorizedProjectId;
}

export default function isAuthenticated() {

  const mapStateToProps = (state, ownProps) => ({
    session: getSession(state),
    push: ownProps.history.push,
  });

  const mapDispatchToProps = {
    showAlert,
  };

  return (Component) => {
    class AuthenticatedRoute extends React.Component {

      // TODO replace this whole thing with the hooks from the Auth library
      componentDidMount() {
        this._handleCheckSession();
      }

      componentDidUpdate() {
        this._handleCheckSession();
      }

      _sessionIsAuthenticated() {
        const { session = {}, location } = this.props;
        if (session?.type === 'public') {
          return _handlePublicStudio(session, location);
        }

        const user = decodeToken(getJwt());

        return user != null && user.confirmed && user.organisation != null && user.organisation.id != null && user.target_domain === TargetDomain.DRAWBOTICS;
      }

      _handleCheckSession() {
        const { session, showAlert, location, push } = this.props;
        const { search, pathname } = location;
        const user = decodeToken(getJwt());
        const isAuthenticated = this._sessionIsAuthenticated();

        if (session?.type === 'public') {
          if (!isAuthenticated) {
            showAlert('GUEST_NOT_ALLOWED', 'error', t('containers.alerts.errors.access_not_allowed'));
          }
        }
        else {
          if (user == null) {
            if (search.includes('code')) {
              push(`/auth/sign-up${search}`);
            } else {
              push(`/auth/login?next=${pathname + search}`);
            }
          } else if (!user.confirmed) {
            push(
              `/auth/confirmation${
                pathname.includes('confirmation') ? search : `?next=${pathname + search}`
              }`,
            );
            // checking for object details due to backend issue
          } else if (user.organisation == null || user.organisation.id == null) {
            push(
              `/auth/create-join-company${
                pathname.includes('create-join-company') ? search : `?next=${pathname + search}`
              }`,
            );
          }
        }
      }

      render() {
        const { session } = this.props;
        if (!this._sessionIsAuthenticated()) {
          return null;
        }
        return React.createElement(Component, { ...this.props, session });
      }

    }

    return connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRoute);
  };

}
