import sv from '@drawbotics/drylus-style-vars';
import { Color } from '@drawbotics/react-drylus';

import { ItemStatus } from '../types';

export function statusToColor(status: ItemStatus): Color | string | undefined {
  if (status === ItemStatus.ARCHIVED) {
    return sv.pink;
  }
  if (status === ItemStatus.AWAITING) {
    return Color.ORANGE;
  }
  if (status === ItemStatus.ONGOING || status === ItemStatus.REVIEWING_BRIEFING) {
    return Color.BRAND;
  }
  if (status === ItemStatus.FINISHED) {
    return Color.GREEN;
  }
  if (status === ItemStatus.PROBLEM || status === ItemStatus.BRIEFING_ERROR) {
    return Color.RED;
  }
}
