import React from 'react';
import { css, cx } from 'emotion';
import sv from '@drawbotics/style-vars';

import { createTranslate } from '~/utils/translation';


const t = createTranslate('pods.order.services');


const styles = {
  meta: css`
    padding-top: ${sv.baseMargin};
  `,
  hidden: css`
    margin-top: 0;
  `,
  trigger: css`
    text-align: center;
  `,
  label: css`
    color: ${sv.textSecondaryDark};
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  `,
  components: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: ${sv.baseMargin};
  `,
  section: css`
    flex: 1;
    width: 500px;
    margin-bottom: ${sv.baseMargin};

    &:last-child {
      margin-bottom: 0;
    }

    @media ${sv.ipad} {
      max-width: 500px;
      width: 100%;
    }
  `,
};


class Meta extends React.Component {
  state = {
    componentsVisible: false,
  }

  render() {
    const { generatedComponents, label, noToggle=false, condition } = this.props;
    const { componentsVisible } = this.state;
    return (
      <div className={cx(styles.meta, { [styles.hidden]: condition !== null && ! noToggle })}>
        {do{
          if (condition === false || ! noToggle) {
            <div className={styles.trigger}>
              <span className={styles.label} onClick={() => this.setState({ componentsVisible: true })}>
                {t(label, null, label)}
              </span>
            </div>
          }
        }}
        {do{
          if (condition === false || noToggle || componentsVisible) {
            <div className={styles.components}>
              {generatedComponents.map((component, i) => (
                <div key={i} className={styles.section}>
                  <component.type {...component.props} />
                </div>
              ))}
            </div>
          }
        }}
      </div>
    );
  }
}


export default Meta;
