import {
  Avatar,
  Button,
  Category,
  Color,
  EmptyState,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Icon,
  ListTile,
  RoundIcon,
  Select,
  Shade,
  Size,
  Spinner,
  Table,
  Text,
  Tooltip,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import { capitalize } from 'lodash';
import React, { ReactNode } from 'react';

import { OrganizationRole } from '~/types';
import { createTranslate } from '~/utils/translation';

import { Invitation, Teammate, User, isMember } from '../api/domain';
import { InvitationStatus } from '../types';

const tt = createTranslate('pods.profile.components.teammates_table');

function _getContentForStatus(status: InvitationStatus): ReactNode {
  if (status === InvitationStatus.INITIATED) {
    return (
      <Tooltip content={tt('invitation_error')}>
        <RoundIcon color={Color.RED} size={Size.LARGE} name="alert-triangle" />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip content={tt('not_validated')}>
        <RoundIcon size={Size.LARGE} name="clock" />
      </Tooltip>
    );
  }
}

interface TeammatesTableProps {
  teammates: Array<Teammate>;
  currentUser?: User;
  isLoading: boolean;
  onChangeTeammate: (id: string, teammate: Partial<Teammate>) => Promise<void>;
  teammateSavingId?: string;
  onResendInvitation: (id: string) => Promise<void>;
  teammateSendingInvitation?: string;
}

export const TeammatesTable = ({
  teammates,
  currentUser,
  isLoading,
  onChangeTeammate,
  teammateSavingId,
  onResendInvitation,
  teammateSendingInvitation,
}: TeammatesTableProps) => {
  const data = [...teammates]
    .sort((a, b) => a.fullName.localeCompare(b.fullName))
    .filter((teammate) => (teammate as Invitation).status !== InvitationStatus.ACCEPTED)
    .map((teammate) => ({
      id: teammate.id,
      name: (
        <ListTile
          leading={<Avatar text={`${teammate.firstName[0]}${teammate.lastName[0]}`} />}
          subtitle={
            <Text size={Size.SMALL} shade={Shade.LIGHT}>
              {teammate.email}
            </Text>
          }
          title={<Text size={Size.SMALL}>{teammate.fullName}</Text>}
        />
      ),
      role:
        currentUser?.role === OrganizationRole.ADMIN && isMember(teammate) ? (
          currentUser.id === teammate.id ? (
            <Tooltip content={tt('errors.invalid_params')}>
              <Select
                options={Object.values(OrganizationRole).map((r) => ({
                  value: r,
                  label: capitalize(tt(r)),
                }))}
                value={teammate.role}
                onChange={() => {}}
                size={Size.SMALL}
                disabled
              />
            </Tooltip>
          ) : (
            <Select
              options={Object.values(OrganizationRole).map((r) => ({
                value: r,
                label: capitalize(tt(r)),
              }))}
              value={teammate.role}
              loading={teammateSavingId === teammate.id}
              onChange={(role) => onChangeTeammate(teammate.id, { role })}
              size={Size.SMALL}
            />
          )
        ) : (
          <Text>{tt(teammate.role)}</Text>
        ),
      status: isMember(teammate) ? (
        <Tooltip content={tt('valid_account')}>
          <RoundIcon size={Size.LARGE} name="check" color={Color.GREEN} />
        </Tooltip>
      ) : (
        <Flex justify={FlexJustify.START}>
          <FlexItem>{_getContentForStatus(teammate.status)}</FlexItem>
          <FlexSpacer size={Size.SMALL} />
          <FlexItem>
            <Tooltip content={tt('resend_invitation')}>
              <Button
                onClick={() => onResendInvitation(teammate.id)}
                size={Size.SMALL}
                category={Category.BRAND}
                leading={
                  teammateSendingInvitation === teammate.id ? (
                    <Spinner inversed size={Size.SMALL} />
                  ) : (
                    <Icon name="mail" />
                  )
                }
              />
            </Tooltip>
          </FlexItem>
        </Flex>
      ),
      memberSince:
        isMember(teammate) && teammate.memberSince ? (
          <div
            className={css`
              &::first-letter {
                text-transform: capitalize;
              }
            `}>
            <Text>{new Date(teammate.memberSince.toDate())}</Text>
          </div>
        ) : null,
    }));

  return (
    <Table
      header={[
        { label: tt('name'), value: 'name' },
        { label: tt('role'), value: 'role' },
        { label: tt('status'), value: 'status' },
        { label: tt('member_since'), value: 'memberSince' },
      ]}
      data={data}
      isLoading={isLoading}
      emptyContent={data.length === 0 ? <EmptyState description={tt('no_members_yet')} /> : null}
    />
  );
};
