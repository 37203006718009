import cloneDeep from 'lodash/cloneDeep';


function sqftToSqm(value) {
  return Math.floor(value * 0.092903);
}


export function estateWithSqm(rawEstate) {
  const estate = cloneDeep(rawEstate);
  if (estate.surface?.unit === 'sqft') {
    estate.surface = { unit: 'sqm', value: sqftToSqm(estate.surface.value) };
  }
  return estate;
}


export function itemWithSqm(rawItem) {
  if (rawItem.estate) {
    const item = cloneDeep(rawItem);
    const estate = estateWithSqm(item.estate);
    return { ...item, estate };
  }
  return rawItem;
}


export function applyPrice(currencies, sessionUser) {
  return sessionUser.addCurrency(currencies[sessionUser.currency.code]);
}
