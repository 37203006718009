import sv from '@drawbotics/style-vars';
import { translate as t } from '~/utils/translation';
import { css, cx } from 'emotion';
import React from 'react';
import { Icon } from 'react-ittsu/components';

export const styles = {
  timeLine: css`
    text-align: center;
    padding-bottom: ${sv.basePaddingSmall};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  step: css`
    display: inline-block;
    text-align: center;
    position: relative;

    &:hover {
      cursor: pointer;

      & [data-element='help'] {
        opacity: 1;
        transform: translate(-50%, 10px);
      }
    }
  `,
  dot: css`
    width: 18px;
    height: 18px;
    border-radius: 10000px;
    margin: 0 5px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.5rem;
    color: ${sv.brandPrimary};
    opacity: 0.6;
    background: ${sv.grey400};
    transition: all ${sv.baseTransitionTimeShort} ease-in-out;
    transform: scale(0.7);

    @media ${sv.phoneXl} {
      margin: 0 10px;
    }
  `,
  activeDot: css`
    opacity: 1;
    background: ${sv.brandPrimary};
    transform: scale(1);
  `,
  checkedDot: css`
    opacity: 1;
    background: ${sv.brandPrimary};
  `,
  check: css`
    color: ${sv.white};
  `,
  help: css`
    position: absolute;
    z-index: 10;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 8px);
    padding: 5px;
    background: rgba(0, 0, 0, 0.7);
    color: ${sv.white};
    font-size: 0.7rem;
    white-space: nowrap;
    border-radius: ${sv.baseBorderRadius};
    opacity: 0;
    transition: all ${sv.baseTransitionTimeShort} ease-in-out;
  `,
};

export interface TimelineStep {
  id: string;
  checked: boolean;
  active: boolean;
  title: string;
  index: number;
}

interface StepProps {
  step: TimelineStep;
  onClick: (step: TimelineStep) => void;
}

export const Step = ({ step, onClick }: StepProps) => {
  const { checked, active, title } = step;
  return (
    <div className={styles.step} onClick={() => onClick(step)}>
      <div
        className={cx(styles.dot, {
          [styles.activeDot]: active,
          [styles.checkedDot]: checked,
        })}
        data-element="dot">
        {checked && !active ? (
          <div className={styles.check}>
            <Icon name="check-bold" vOffset={-1} bold />
          </div>
        ) : null}
      </div>
      <div className={styles.help} data-element="help">
        {title}
      </div>
    </div>
  );
};

const PROJECT_STEP_IDS = ['property-type', 'type', 'subtype'];

function _groupProjectSteps(steps: Array<TimelineStep>) {
  const groupedStep = { ...steps[0], title: t('pods.order.project') };
  const shouldGroupedStep = steps.some((step) => PROJECT_STEP_IDS.includes(step.id));
  return shouldGroupedStep
    ? [groupedStep, ...steps.filter((step) => !PROJECT_STEP_IDS.includes(step.id))]
    : steps;
}

function _markActiveStep(steps: Array<TimelineStep>, activeStep: TimelineStep) {
  return steps.map((step) => ({
    ...step,
    active:
      step.id === activeStep.id ||
      (PROJECT_STEP_IDS.includes(activeStep.id) && step.id === 'property-type'),
  }));
}

interface TimelineProps {
  steps: Array<TimelineStep>;
  activeStep: TimelineStep;
  onClickStep: (step: TimelineStep) => void;
}

const Timeline = ({ steps, activeStep, onClickStep }: TimelineProps) => {
  if (steps == null || steps.length === 0) {
    return null;
  }
  steps = _markActiveStep(_groupProjectSteps(steps), activeStep);
  return (
    <div className={styles.timeLine}>
      {steps.map((step, index) => (
        <Step key={step.id} step={{ ...step, index: index + 1 }} onClick={onClickStep} />
      ))}
    </div>
  );
};

export default Timeline;
