import morphey, { fromKey } from 'morphey';


const translationsFromServer = {
  id: 'id',
  comment: 'description',
  shape: 'shape',
  createdAt: 'created_at',
  createdBy: 'created_by',
  preview: 'preview',
  attachments: 'attachments',
  userType: 'user_type',
  problemDescriptions: 'problem_descriptions',
  problems: 'problems',
  actionable: 'actionable',
};


export function annotationFromServer(annotation) {
  return morphey(annotation, translationsFromServer);
}


const translationsToServer = {
  id: 'id',
  description: 'comment',
  shape: 'shape',
  created_at: 'createdAt',
  created_by: fromKey('createdBy').using((u) => u.id ? u.id : u),
  preview_id: fromKey('preview').using((p) => p.id ? p.id : p),
  attachments: fromKey('attachments').using((as) => as.map((a) => a.id)),
  actionable: fromKey('actionable'),
};


export function annotationToServer(annotation) {
  return morphey(annotation, translationsToServer);
}


export default {
  fromServer: annotationFromServer,
  toServer: annotationToServer,
};
