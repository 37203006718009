import React from 'react';
import { Checkbox } from 'react-ittsu/forms';

import { createTranslate } from 'utils/translation';

import styles from 'pods/studio/styles/components/options.less';


const tt = createTranslate('pods.studio.components.option');


export const OptionLayout = ({ children }) => (
  <div className={styles.layout}>
    {children}
  </div>
);


const Option = ({
  name,
  price,
  picked,
  onChange,
}) => (
  <div className={styles.option}>
    <span className={styles.name}>{name}</span>
    <span className={styles.price}>{price} {tt('credits')}</span>
    <Checkbox checked={picked} onChange={onChange} />
  </div>
);


export default Option;
