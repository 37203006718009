const residential = {
  1: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'brand_id.briefing.logo_types',
        component: 'Title',
      }, {
        type: 'component',
        label: 'brand_id.briefing.like_dislike_styles',
        component: 'Note',
      }, {
        type: 'question-component',
        component: 'LikeDislike',
        key: 'logos',
        keyLabel: 'brand_id.briefing.keys.logos',
        dislikedKey: 'dislikedLogos',
        required: 'one',
        values: [
          {
            image: require('../images/brand-id/logos/brooklyn.jpg'),
            zoom: require('../images/brand-id/logos/with-context/brooklyn.jpg'),
            value: 'logo_style_1',
          }, {
            image: require('../images/brand-id/logos/cavallo.jpg'),
            zoom: require('../images/brand-id/logos/with-context/cavallo.jpg'),
            value: 'logo_style_2',
          }, {
            image: require('../images/brand-id/logos/duin.jpg'),
            zoom: require('../images/brand-id/logos/with-context/duin.jpg'),
            value: 'logo_style_3',
          }, {
            image: require('../images/brand-id/logos/greco.jpg'),
            zoom: require('../images/brand-id/logos/with-context/greco.jpg'),
            value: 'logo_style_4',
          }, {
            image: require('../images/brand-id/logos/greenlab.jpg'),
            zoom: require('../images/brand-id/logos/with-context/greenlab.jpg'),
            value: 'logo_style_5',
          }, {
            image: require('../images/brand-id/logos/hauts-du-port.jpg'),
            zoom: require('../images/brand-id/logos/with-context/hauts-du-port.jpg'),
            value: 'logo_style_6',
          }, {
            image: require('../images/brand-id/logos/mercator.jpg'),
            zoom: require('../images/brand-id/logos/with-context/mercator.jpg'),
            value: 'logo_style_7',
          }, {
            image: require('../images/brand-id/logos/nest.jpg'),
            zoom: require('../images/brand-id/logos/with-context/nest.jpg'),
            value: 'logo_style_8',
          }, {
            image: require('../images/brand-id/logos/park-west.jpg'),
            zoom: require('../images/brand-id/logos/with-context/park-west.jpg'),
            value: 'logo_style_9',
          }, {
            image: require('../images/brand-id/logos/solent.jpg'),
            zoom: require('../images/brand-id/logos/with-context/solent.jpg'),
            value: 'logo_style_10',
          }, {
            image: require('../images/brand-id/logos/valentin.jpg'),
            zoom: require('../images/brand-id/logos/with-context/valentin.jpg'),
            value: 'logo_style_11',
          }, {
            image: require('../images/brand-id/logos/harmonie.jpg'),
            zoom: require('../images/brand-id/logos/with-context/harmonie.jpg'),
            value: 'logo_style_12',
          },
        ],
      }, {
        type: 'meta',
        label: 'brand_id.briefing.show_my_own',
        components: [
          {
            type: 'question-component',
            label: 'brand_id.briefing.upload_example',
            component: 'FileUploader',
            key: 'otherLogoAttachments',
            keyLabel: 'brand_id.briefing.keys.submitted_examples',
          }, {
            type: 'question-component',
            label: 'brand_id.briefing.example_description',
            component: 'Input',
            key: 'otherLogo',
            keyLabel: 'brand_id.briefing.keys.example_comments',
          },
        ],
      },
    ],
  },
  2: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'brand_id.briefing.font_style',
        component: 'Title',
      }, {
        type: 'component',
        label: 'brand_id.briefing.like_dislike_styles',
        component: 'Note',
      }, {
        type: 'question-component',
        component: 'LikeDislike',
        key: 'fonts',
        keyLabel: 'brand_id.briefing.keys.fonts',
        dislikedKey: 'dislikedFonts',
        required: 'one',
        values: [
          {
            image: require('../images/brand-id/fonts/chic.jpg'),
            value: 'font_style_1',
            label: 'Chic',
          }, {
            image: require('../images/brand-id/fonts/classic.jpg'),
            value: 'font_style_2',
            label: 'Classic',
          }, {
            image: require('../images/brand-id/fonts/condensed.jpg'),
            value: 'font_style_3',
            label: 'Condensed',
          }, {
            image: require('../images/brand-id/fonts/handwritten.jpg'),
            value: 'font_style_4',
            label: 'Handwritten',
          }, {
            image: require('../images/brand-id/fonts/light.jpg'),
            value: 'font_style_5',
            label: 'Light',
          }, {
            image: require('../images/brand-id/fonts/modern.jpg'),
            value: 'font_style_6',
            label: 'Modern',
          }, {
            image: require('../images/brand-id/fonts/signature.jpg'),
            value: 'font_style_7',
            label: 'Signature',
          }, {
            image: require('../images/brand-id/fonts/slab-serif.jpg'),
            value: 'font_style_8',
            label: 'Slab Serif',
          }, {
            image: require('../images/brand-id/fonts/vintage.jpg'),
            value: 'font_style_9',
            label: 'Vintage',
          },
        ],
      }, {
        type: 'meta',
        label: 'brand_id.briefing.show_my_own',
        components: [
          {
            type: 'question-component',
            label: 'brand_id.briefing.example_description',
            component: 'Input',
            key: 'otherFont',
            keyLabel: 'brand_id.briefing.keys.example_comments',
          }, {
            type: 'question-component',
            label: 'brand_id.briefing.upload_example',
            component: 'FileUploader',
            key: 'otherFontAttachments',
            keyLabel: 'brand_id.briefing.keys.submitted_examples',
          },
        ],
      },
    ],
  },
  3: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'brand_id.briefing.palettes',
        component: 'Title',
      }, {
        type: 'component',
        label: 'brand_id.briefing.choose_multiple',
        component: 'Note',
      }, {
        type: 'question-component',
        component: 'MultiSelect',
        key: 'palettes',
        keyLabel: 'brand_id.briefing.keys.palettes',
        required: 'all',
        values: [
          {
            image: require('../images/brand-id/palettes/colorful.jpg'),
            value: 'palette_style_1',
            label: 'Colorful/Young',
          }, {
            image: require('../images/brand-id/palettes/dark-luxury.jpg'),
            value: 'palette_style_2',
            label: 'Dark/Luxurious',
          }, {
            image: require('../images/brand-id/palettes/green-nature.jpg'),
            value: 'palette_style_3',
            label: 'Green/Nature',
          }, {
            image: require('../images/brand-id/palettes/light-fresh.jpg'),
            value: 'palette_style_4',
            label: 'Light/Fresh',
          }, {
            image: require('../images/brand-id/palettes/minimalistic.jpg'),
            value: 'palette_style_5',
            label: 'Minimalistic',
          }, {
            image: require('../images/shared/drawbotics.jpg'),
            value: 'drawbotics_choice',
            label: 'shared.drawbotics_choice',
          }, {
            image: require('../images/shared/other.jpg'),
            value: 'other',
            label: 'shared.other_option',
            meta: {
              type: 'meta',
              noToggle: true,
              required: 'one',
              components: [
                {
                  type: 'question-component',
                  label: 'brand_id.briefing.example_description',
                  component: 'Input',
                  key: 'otherPalettes',
                  keyLabel: 'brand_id.briefing.keys.example_comments',
                }, {
                  type: 'question-component',
                  label: 'brand_id.briefing.upload_example',
                  component: 'FileUploader',
                  key: 'otherPalettesAttachments',
                  keyLabel: 'brand_id.briefing.keys.submitted_examples',
                },
              ],
            },
          },
        ],
      },
    ],
  },
  4: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'brand_id.briefing.brand_id_styles',
        component: 'Title',
      }, {
        type: 'component',
        label: 'brand_id.briefing.only_examples',
        component: 'Note',
      }, {
        type: 'question-component',
        component: 'LikeDislike',
        key: 'styles',
        keyLabel: 'brand_id.briefing.keys.styles',
        dislikedKey: 'dislikedStyles',
        required: 'one',
        values: [
          {
            image: require('../images/brand-id/styles/thumbs/bailli.jpg'),
            zoom: require('../images/brand-id/styles/bailli.jpg'),
            value: 'brand_id_style_1',
          }, {
            image: require('../images/brand-id/styles/thumbs/brooklyn.jpg'),
            zoom: require('../images/brand-id/styles/brooklyn.jpg'),
            value: 'brand_id_style_2',
          }, {
            image: require('../images/brand-id/styles/thumbs/duin.jpg'),
            zoom: require('../images/brand-id/styles/duin.jpg'),
            value: 'brand_id_style_3',
          }, {
            image: require('../images/brand-id/styles/thumbs/greenlab.jpg'),
            zoom: require('../images/brand-id/styles/greenlab.jpg'),
            value: 'brand_id_style_4',
          }, {
            image: require('../images/brand-id/styles/thumbs/harmonie.jpg'),
            zoom: require('../images/brand-id/styles/harmonie.jpg'),
            value: 'brand_id_style_5',
          }, {
            image: require('../images/brand-id/styles/thumbs/klinkerof.jpg'),
            zoom: require('../images/brand-id/styles/klinkerof.jpg'),
            value: 'brand_id_style_6',
          }, {
            image: require('../images/brand-id/styles/thumbs/nest.jpg'),
            zoom: require('../images/brand-id/styles/nest.jpg'),
            value: 'brand_id_style_7',
          }, {
            image: require('../images/brand-id/styles/thumbs/park-west.jpg'),
            zoom: require('../images/brand-id/styles/park-west.jpg'),
            value: 'brand_id_style_8',
          }, {
            image: require('../images/brand-id/styles/thumbs/rimbaud.jpg'),
            zoom: require('../images/brand-id/styles/rimbaud.jpg'),
            value: 'brand_id_style_9',
          },
        ],
      }, {
        type: 'meta',
        label: 'brand_id.briefing.show_my_own',
        components: [
          {
            type: 'question-component',
            label: 'brand_id.briefing.example_description',
            component: 'Input',
            key: 'otherStyle',
            keyLabel: 'brand_id.briefing.keys.example_comments',
          }, {
            type: 'question-component',
            label: 'brand_id.briefing.upload_example',
            component: 'FileUploader',
            key: 'otherStyleAttachments',
            keyLabel: 'brand_id.briefing.keys.submitted_examples',
          },
        ],
      },
    ],
  },
  5: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'shared.additional_info',
        component: 'Title',
      }, {
        type: 'component',
        label: 'shared.anything_to_add',
        component: 'Note',
      }, {
        type: 'question-component',
        label: 'shared.additional_comments',
        component: 'Input',
        key: 'otherInfo',
        keyLabel: 'shared.keys.other_info',
      }, {
        type: 'question-component',
        label: 'shared.upload_files',
        component: 'FileUploader',
        key: 'otherInfoAttachments',
        keyLabel: 'shared.keys.other_info_attachments',
      },
    ],
  },
};


export default { residential };
