import React from 'react';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';

import sidebarContainerIcon from '../images/icons/computer-icon.svg';


const styles = {
  sidebarLayout: css`
    width: 100%;
    height: 100%;
    display: flex;
  `,
  contentContainer: css`
    flex: 1;

    @media ${sv.ipad} {
      ${'' /* background: ${sv.white}; */}
    }
  `,
  sidebarContainer: css`
    width: 400px;
    flex-shrink: 0;
    background: rgba(0, 0, 0, 0.05);
    position: relative;

    @media print {
      display: none;
    }

    @media ${sv.ipadLandscape} {
      width: 300px;
    }

    @media ${sv.ipad} {
      width: 0;
      background: transparent;
    }
  `,
  sidebarIcon: css`
    width: calc(100% - ${sv.baseMargin});
    position: absolute;
    bottom: ${sv.baseMargin};
    margin: 0 ${sv.baseMarginSmall};
    display: flex;
    justify-content: center;

    @media ${sv.ipad} {
      display: none;
    }
  `
};


class SidebarLayout extends React.Component {
  render() {
    const { sidebar, content } = this.props;
    return (
      <div className={styles.sidebarLayout}>
        <div className={styles.contentContainer}>
          {content ? content() : null}
        </div>
        <div className={styles.sidebarContainer}>
          {sidebar ? sidebar() : null}
          <div className={styles.sidebarIcon}>
            <img src={sidebarContainerIcon} />
          </div>
        </div>
      </div>
    );
  }
}


export default SidebarLayout;
