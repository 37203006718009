import dsv from '@drawbotics/drylus-style-vars';
import {
  Button,
  ButtonLink,
  Category,
  Color,
  FormGroup,
  Icon,
  IconType,
  Icons,
  Input,
  Label,
  ListTile,
  Margin,
  Paragraph,
  RoundIcon,
  Size,
  Spinner,
  Text,
  Tier,
  formatPrice,
  useAlert,
} from '@drawbotics/react-drylus';
import copyToClipboard from 'copy-to-clipboard';
import { css } from 'emotion';
import gql from 'fraql';
import React, { Fragment, ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from 'urql';

import { SolveProblemModal } from '~/pods/dashboard/components';
import { Item, ItemStatus } from '~/pods/dashboard/types';
import { camelize, downloadAll, statusToColor } from '~/pods/dashboard/utils';
import { openIntercom } from '~/pods/order/utils';
import { Id } from '~/types';
import { run } from '~/utils';
import { createTranslate, translate as t } from '~/utils/translation';

import { ReopenItemModal } from './ReopenItemModal';

const tt = createTranslate('pods.dashboard.routes.production');

const styles = {
  statusPanel: css`
    background: white;
    overflow: hidden;
    border-radius: ${dsv.defaultBorderRadius};
  `,
};

interface GenerateLinkVariables {
  itemId: Id;
}

interface GenerateLinkResult {
  generateStudioLink: {
    orderItem: Item;
  };
}

export const generateLinkMutation = gql`
  mutation generateLink($itemId: ID!) {
    generateStudioLink(input: { itemId: $itemId }) {
      orderItem {
        id
        publicIdentifier
      }
    }
  }
`;

interface ReopenItemVariables {
  itemId: Id;
}

interface ReopenItemResult {
  reopenItem: {
    orderItem: Item;
  };
}

export const reopenItemMutation = gql`
  mutation reopenItem($itemId: ID!) {
    reopenItem(input: { itemId: $itemId }) {
      orderItem {
        id
        status
      }
    }
  }
`;

function _statusToIcon(status: ItemStatus): IconType {
  switch (status) {
    case ItemStatus.AWAITING_BRIEFING:
      return Icons.briefing;
    case ItemStatus.REVIEWING_BRIEFING:
      return Icons.clock;
    case ItemStatus.BRIEFING_ERROR:
      return Icons.alertTriangle;
    case ItemStatus.AWAITING_PAYMENT:
      return Icons.billing;
    case ItemStatus.ONGOING:
      return Icons.clock;
    case ItemStatus.AWAITING:
      return Icons.conversation;
    case ItemStatus.PROBLEM:
      return Icons.alertTriangle;
    case ItemStatus.ON_HOLD:
      return Icons.pause;
    case ItemStatus.FINISHED:
      return Icons.box;
    case ItemStatus.ARCHIVED:
      return Icons.box;
    case ItemStatus.CANCELLED:
      return Icons.slash;
    default:
      throw new Error(`Invalid ItemStatus: ${status}`);
  }
}

function _itemToAction(item: Item, onClick?: VoidFunction): ReactNode {
  const [isGeneratingZip, setIsGeneratingZip] = useState(false);
  const attachments = item.attachments.filter((attachment) => attachment.final);
  switch (item.status) {
    case ItemStatus.PROBLEM:
      return (
        <Margin size={{ top: Size.SMALL }}>
          <Link
            to={`/studio/project-item/${item.id}/preview/${item.problems[0].previewTargetId}#${item.problems[0].annotationTargetId}`}>
            <ButtonLink fullWidth category={Category.INFO}>
              {tt('go_to_studio')}
            </ButtonLink>
          </Link>
        </Margin>
      );
    case ItemStatus.AWAITING:
      return (
        <Margin size={{ top: Size.SMALL }}>
          <Link to={`/studio/project-item/${item.id}`}>
            <ButtonLink fullWidth category={Category.INFO}>
              {tt('go_to_studio')}
            </ButtonLink>
          </Link>
        </Margin>
      );
    case ItemStatus.FINISHED:
      if (attachments.length === 0) {
        return null;
      }
      return (
        <Margin size={{ top: Size.SMALL }}>
          <Button
            onClick={async () => {
              setIsGeneratingZip(true);
              await downloadAll(attachments, 'final');
              setIsGeneratingZip(false);
            }}
            trailing={isGeneratingZip ? <Spinner size={Size.SMALL} inversed /> : null}
            fullWidth
            category={Category.INFO}>
            {tt('download_files')}
          </Button>
        </Margin>
      );
    case ItemStatus.AWAITING_PAYMENT:
      return (
        <Margin size={{ top: Size.SMALL }}>
          <Link to={`/projects/${item.estate.id}/billing`}>
            <ButtonLink fullWidth category={Category.INFO}>
              {tt('go_to_billing')}
            </ButtonLink>
          </Link>
        </Margin>
      );
    case ItemStatus.AWAITING_BRIEFING:
      return (
        <Margin size={{ top: Size.SMALL }}>
          <Link to={`/order/briefing/${item.estate.id}/${camelize(item.service.slug)}/${item.id}`}>
            <ButtonLink fullWidth category={Category.INFO}>
              {tt('complete_briefing')}
            </ButtonLink>
          </Link>
        </Margin>
      );
    case ItemStatus.BRIEFING_ERROR:
      return (
        <Margin size={{ top: Size.SMALL }}>
          <Button onClick={onClick} fullWidth category={Category.INFO}>
            {tt('solve_problems')}
          </Button>
        </Margin>
      );
    case ItemStatus.ON_HOLD:
      return (
        <Margin size={{ top: Size.SMALL }}>
          <Button onClick={openIntercom} fullWidth category={Category.INFO}>
            {t('routes.critical_error.contact_us')}
          </Button>
        </Margin>
      );
    default:
      return null;
  }
}

interface StatusPanelProps {
  item: Item;
}

export const StatusPanel = ({ item }: StatusPanelProps) => {
  const [isSolveProblemsModalVisible, setIsSolveProblemsModalVisible] = useState(false);
  const [isReopenItemModalVisible, setIsReopenItemModalVisible] = useState(false);
  const { showAlert } = useAlert();
  const [generateLinkRes, generateLink] = useMutation<GenerateLinkResult, GenerateLinkVariables>(
    generateLinkMutation,
  );
  const [reopenItemRes, reopenItem] = useMutation<ReopenItemResult, ReopenItemVariables>(
    reopenItemMutation,
  );

  const copyLinkToClipboard = (link: string) => {
    const fullLink = `${window.location.origin}/studio/${link}`;
    copyToClipboard(fullLink);
    showAlert({
      text: t('pods.dashboard.routes.item.studio_link_copied'),
      category: Category.INFO,
    });
  };

  const handleCopyLink = async () => {
    if (item.publicIdentifier == null) {
      const { data } = await generateLink({ itemId: item.id });
      if (data?.generateStudioLink.orderItem.publicIdentifier != null) {
        copyLinkToClipboard(data.generateStudioLink.orderItem.publicIdentifier);
      }
    } else {
      copyLinkToClipboard(item.publicIdentifier);
    }
  };

  const handleReopenItem = async () => {
    const { error } = await reopenItem({ itemId: item.id });
    if (error != null) {
      setIsReopenItemModalVisible(false);

      showAlert({
        category: Category.DANGER,
        text: t('pods.dashboard.routes.item.something_went_wrong_reopening'),
      });
    }
  };

  const itemUrl =
    item.url != null && item.url !== '' ? (
      <Margin size={{ top: Size.DEFAULT }}>
        <FormGroup
          label={<Label>{t('pods.dashboard.routes.item.link')}</Label>}
          input={
            <Input
              value={item.url}
              trailing={
                <Button
                  onClick={() => {
                    copyToClipboard(item.url!);
                    showAlert({
                      category: Category.INFO,
                      text: t('pods.dashboard.routes.item.link_copied'),
                    });
                  }}
                  category={Category.INFO}>
                  {t('pods.dashboard.routes.item.copy')}
                </Button>
              }
            />
          }
        />
      </Margin>
    ) : null;

  return (
    <div className={styles.statusPanel}>
      <Margin>
        <ListTile
          leading={
            <RoundIcon
              name={_statusToIcon(item.status)}
              size={48}
              color={statusToColor(item.status)}
            />
          }
          title={
            <Text
              bold
              style={{
                color: item.status === ItemStatus.ARCHIVED ? statusToColor(item.status) : undefined,
              }}
              color={
                item.status !== ItemStatus.ARCHIVED
                  ? (statusToColor(item.status) as Color)
                  : undefined
              }
              size={Size.LARGE}>
              {t(`pods.dashboard.status.${item.status}.title`)}
            </Text>
          }
        />
        <Paragraph>{t(`pods.dashboard.status.${item.status}.long_description`)}</Paragraph>
        {_itemToAction(item, () => setIsSolveProblemsModalVisible(true))}
        {run(() => {
          if (item.status === ItemStatus.AWAITING) {
            return (
              <Fragment>
                <Margin size={{ top: Size.EXTRA_SMALL }}>
                  <Button
                    onClick={handleCopyLink}
                    leading={<Icon name="link" />}
                    trailing={generateLinkRes.fetching ? <Spinner size={Size.SMALL} /> : null}
                    fullWidth
                    tier={Tier.SECONDARY}>
                    {t('pods.dashboard.routes.item.copy_guest_link')}
                  </Button>
                </Margin>
                {itemUrl}
              </Fragment>
            );
          } else if (item.status === ItemStatus.FINISHED || item.status === ItemStatus.ARCHIVED) {
            return (
              <Fragment>
                <Margin size={{ top: Size.EXTRA_SMALL }}>
                  <Button
                    onClick={() => setIsReopenItemModalVisible(true)}
                    fullWidth
                    tier={Tier.SECONDARY}
                    category={Category.INFO}>
                    {item.estate.paymentProcess?.type === 'sales_order'
                      ? t('pods.dashboard.routes.item.reopen_invoice', {
                          price: formatPrice({
                            price: {
                              value: item.reopeningPrice ?? 0,
                              currency: item.estate.paymentProcess?.salesOrder?.currency,
                            },
                          }),
                        })
                      : t('pods.dashboard.routes.item.reopen', { credits: item.reopeningPrice })}
                  </Button>
                </Margin>
                {itemUrl}
              </Fragment>
            );
          }
        })}
      </Margin>
      <ReopenItemModal
        price={
          item.estate.paymentProcess?.type === 'sales_order'
            ? formatPrice({
                price: {
                  value: item.reopeningPrice ?? 0,
                  currency: item.estate.paymentProcess?.salesOrder?.currency,
                },
              })
            : t('pods.dashboard.routes.item.credits', { credits: item.reopeningPrice })
        }
        loading={reopenItemRes.fetching}
        visible={isReopenItemModalVisible}
        onClickClose={() => setIsReopenItemModalVisible(false)}
        onClickConfirm={handleReopenItem}
      />
      {item.problems.length && item.problems.some((p) => !p.correction) ? (
        <SolveProblemModal
          problems={item.problems}
          visible={isSolveProblemsModalVisible}
          onClickClose={() => setIsSolveProblemsModalVisible(false)}
        />
      ) : null}
    </div>
  );
};
