import { RelativeAdd, Switch, ForEach, Add } from 'drawbotics-pricer';


const description = {
  Base: [
    Add({ EUR: 250, GBP: 230, USD: 300 }),
    Switch('projectType').is({
      'residential': [
        Switch('projectSubtype').is({
          'house': [
            ForEach('surface.value').threshold(100).limit(200).do([
              Add(0.25),
            ]),
            ForEach('surface.value').threshold(200).limit(300).do([
              Add(0.5),
            ]),
            ForEach('surface.value').threshold(300).do([
              Add(1),
            ]),
          ],
          'building': [
            ForEach('unitCount').limit(10).do(Add(15)),
            ForEach('unitCount').threshold(10).limit(20).do(Add(10)),
            ForEach('unitCount').threshold(20).limit(35).do(Add(9)),
            ForEach('unitCount').threshold(35).limit(50).do(Add(8)),
            ForEach('unitCount').threshold(50).limit(75).do(Add(7)),
            ForEach('unitCount').threshold(75).limit(100).do(Add(6)),
            ForEach('unitCount').threshold(100).do(Add(5)),
          ],
          'complex': [
            ForEach('unitCount').limit(10).do(Add(15)),
            ForEach('unitCount').threshold(10).limit(20).do(Add(10)),
            ForEach('unitCount').threshold(20).limit(35).do(Add(9)),
            ForEach('unitCount').threshold(35).limit(50).do(Add(8)),
            ForEach('unitCount').threshold(50).limit(75).do(Add(7)),
            ForEach('unitCount').threshold(75).limit(100).do(Add(6)),
            ForEach('unitCount').threshold(100).do(Add(5)),
            ForEach('houseCount').threshold(1).limit(2).do(Add(150)),
            ForEach('houseCount').threshold(2).limit(3).do(Add(100)),
            ForEach('houseCount').threshold(3).limit(5).do(Add(75)),
            ForEach('houseCount').threshold(5).limit(10).do(Add(50)),
            ForEach('houseCount').threshold(10).limit(20).do(Add(40)),
            ForEach('houseCount').threshold(20).limit(50).do(Add(30)),
            ForEach('houseCount').threshold(50).do(Add(25)),
            ForEach('structureCount').threshold(1).do(Add(250)),
            ForEach('structureCount').threshold(1).do([
              ForEach('unitCount').limit(10).do(Add(15 * 0.2)),
              ForEach('unitCount').threshold(10).limit(20).do(Add(10 * 0.2)),
              ForEach('unitCount').threshold(20).limit(35).do(Add(9 * 0.2)),
              ForEach('unitCount').threshold(35).limit(50).do(Add(8 * 0.2)),
              ForEach('unitCount').threshold(50).limit(75).do(Add(7 * 0.2)),
              ForEach('unitCount').threshold(75).limit(100).do(Add(6 * 0.2)),
              ForEach('unitCount').threshold(100).do(Add(5 * 0.2)),
            ]),
          ],
        }),
      ],
      'commercial': [
        ForEach('surface.value').minimum(100).limit(1000).do(Add(0.25)),
        ForEach('surface.value').threshold(1000).limit(2500).do(Add(0.2)),
        ForEach('surface.value').threshold(2500).limit(5000).do(Add(0.15)),
        ForEach('surface.value').threshold(5000).limit(10000).do(Add(0.1)),
        ForEach('surface.value').threshold(10000).do(Add(0.05)),
        ForEach('structureCount').threshold(1).do(Add(250)),
        ForEach('structureCount').threshold(1).do([
          ForEach('unitCount').limit(10).do(Add(15 * 0.2)),
          ForEach('unitCount').threshold(10).limit(20).do(Add(10 * 0.2)),
          ForEach('unitCount').threshold(20).limit(35).do(Add(9 * 0.2)),
          ForEach('unitCount').threshold(35).limit(50).do(Add(8 * 0.2)),
          ForEach('unitCount').threshold(50).limit(75).do(Add(7 * 0.2)),
          ForEach('unitCount').threshold(75).limit(100).do(Add(6 * 0.2)),
          ForEach('unitCount').threshold(100).do(Add(5 * 0.2)),
        ]),
      ],
    }),
  ],
  Delivery: [
    Switch('delivery').is({
      'relaxed': RelativeAdd(-0.1).from('Base'),
      'standard': RelativeAdd(0).from('Base'),
      'express': RelativeAdd(0.5).from('Base'),
    })
  ],
};


export default description;
