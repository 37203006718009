import React, { Fragment } from 'react';
import autobind from 'autobind-decorator';
import PropTypes from 'prop-types';
import { cx } from 'emotion';
import { Icon, Paragraph, Button, Tooltip, showTooltip, hideTooltip } from 'react-ittsu/components';
import { Checkbox } from 'react-ittsu/forms';
import gql from 'fraql';
import dayjs from 'dayjs';

import IDCard from './IDCard';
import Attachments from './Attachments';
import Checklist from './Checklist';
import ConfirmModal from '../../components/ConfirmModal';
import { createTranslate } from '~/utils/translation';

import estateCover from '~/images/estate.svg';

import styles from './styles';


const tt = createTranslate('pods.order.routes.briefing_estate');


class BriefingEstate extends React.Component {

  static propTypes = {
    estate: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      })),
    }),
    updateEstate: PropTypes.func.isRequired,
  }

  static fragments = {
    Estate: () => gql`
      fragment _ on Estate {
        id
        briefingConfirmedAt
        briefingConfirmator {
          firstName
          lastName
        }
        items {
          id
          milestones {
            id
            name
          }
        }
        canConfirmBriefing @client(type: Estate)
        ${IDCard.fragments.Estate}
        ${Attachments.fragments.Estate}
        ${Checklist.fragments.Estate}
      }
    `,
  }

  state = {
    coverStyle: null,
    coverCanTransition: false,
    confirmModalOpen: false,
    tooltipState: {},
    confirmationAccepted: false,
    isUploading: false,
    displayErrors: false,
  }

  componentDidMount() {
    const { history } = this.props;
    const { style } = history.location.state || {};
    this._calculateCoverStyle(style);
    history.replace({ state: {} });
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps, prevState) {
    const { coverStyle } = this.state;
    const { coverStyle: prevCoverStyle } = prevState;
    if (!! coverStyle && ! prevCoverStyle) {
      setTimeout(() => { this.setState({ coverStyle: null, coverCanTransition: true }) }, 0);
    }
  }

  render() {
    const { goBack, estate, updateEstate, serviceCategories } = this.props;
    const { coverStyle, coverCanTransition, confirmModalOpen, tooltipState, confirmationAccepted, isUploading, displayErrors } = this.state;
    const briefingConfirmed = !! estate.briefingConfirmedAt;
    const briefingAccepted = estate.items.some((item) =>
      item.milestones.find((milestone) => milestone.name === 'accepted'),
    );
    return (
      <div className={styles.briefingEstate} ref={(pageContainer) => this.pageContainer = pageContainer}>
        <div
          className={cx(styles.cover, { [styles.coverWithTransition]: coverCanTransition, [styles.confirmed]: briefingConfirmed, [styles.review]: briefingConfirmed && !briefingAccepted })}
          style={{ ...coverStyle, backgroundImage: `url(${estateCover})` }}>
          <div className={styles.coverContent}>
            <div className={styles.back} onClick={goBack}>
              <Icon name="caret-left-bold" size="lg" />
              <div className={styles.backLabel}>{tt('back_to_overview')}</div>
            </div>
            <div className={styles.coverTitle}>
              {do{
                if (!briefingAccepted) {
                  <div>
                    <Icon bold name="clock" vOffset={-5} />
                    <div>
                      {tt('briefing_under_review')}
                    </div>
                  </div>
                }
                else if (briefingConfirmed) {
                  <div>
                    <Icon name="check-bold" vOffset={-5} />
                    <div>
                      {tt('briefing_confirmed_by', {
                        person: `${estate.briefingConfirmator.firstName} ${estate.briefingConfirmator.lastName}`,
                        day: dayjs(estate.briefingConfirmedAt).format('DD/MM/YYYY'),
                      })}
                    </div>
                  </div>
                }
                else {
                  tt('general_briefing')
                }
              }}
            </div>
          </div>
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.contentColumn}>
            <div className={styles.content} style={briefingConfirmed ? { pointerEvents: 'none' } : null}>
              <Paragraph className={styles.subtitle}>{briefingConfirmed ? tt('information_confirmed') : tt('fill_id_card')}</Paragraph>
              <div className={styles.card}>
                <IDCard
                  displayErrors={displayErrors}
                  estate={estate}
                  onChange={this._handleChangeEstateDetails}
                  communicationServices={serviceCategories.find((cat) => cat.id === 'communication')?.services} />
              </div>
              <div className={styles.uploads}>
                <div className={styles.checklist}>
                  <Checklist estate={estate} />
                </div>
                <div className={styles.attachments}>
                  {do {
                    if (displayErrors) {
                      <div className={styles.error}>
                        {tt('upload_one_file')}
                      </div>
                    }
                  }}
                  <Attachments
                    uploadHidden={briefingConfirmed}
                    attachments={estate.attachments}
                    onChange={updateEstate}
                    setIsUploading={(value) => this.setState({ isUploading: value })} />
                </div>
              </div>
              {do{
                if (! briefingConfirmed) {
                  <div className={styles.confirmationDisclaimer}>
                    <Checkbox checked={confirmationAccepted} onChange={(v) => this.setState({ confirmationAccepted: v })}>
                      {tt('certify_upload')}
                    </Checkbox>
                  </div>
                }
              }}
            </div>
              <div className={styles.actions}>
                {do{
                  if (! briefingConfirmed) {
                    <Fragment>
                      <Button onClick={goBack}>{tt('save_as_draft')}</Button>
                      <Button
                        disabled={isUploading || ! confirmationAccepted}
                        category="primary"
                        onClick={() => { estate.canConfirmBriefing ? this.setState({ confirmModalOpen: true, displayErrors: false }) : this.setState({ displayErrors: true }); window.scrollTo(0,0) }}
                        onMouseEnter={(e) => this.setState({ tooltipState: showTooltip(tooltipState, e) })}
                        onMouseLeave={() => this.setState({ tooltipState: hideTooltip(tooltipState) })}>{tt('confirm')}</Button>
                    </Fragment>
                  }
                  else {
                    <Button category="primary" onClick={goBack}>{tt('back_to_overview')}</Button>
                  }
                }}
            </div>
          </div>
        </div>
        <Tooltip
          text={true}
          label="tooltip"
          position="top"
          visible={tooltipState?.visible && (! estate.canConfirmBriefing || ! confirmationAccepted)}
          parentRect={tooltipState?.parentRect}>
          {tt('tips.complete_briefing')}
        </Tooltip>
        <ConfirmModal
          onClickSave={() => this.setState({ confirmModalOpen: false })}
          onClickConfirm={this._handleConfirmBriefing}
          onClickClose={() => this.setState({ confirmModalOpen: false })}
          visible={confirmModalOpen} />
      </div>
    );
  }

  @autobind
  _calculateCoverStyle(cardCoverStyle) {
    const { coverStyle } = this.state;
    if (! cardCoverStyle || !! coverStyle) return null;
    const containerStyle = this.pageContainer.getBoundingClientRect();
    const style = {
      height: cardCoverStyle.height,
      width: cardCoverStyle.width,
      left: cardCoverStyle.left - containerStyle.left,
      top: cardCoverStyle.top - containerStyle.top,
    };
    this.setState({ coverStyle: style });
  }

  @autobind
  async _handleChangeEstateDetails(details) {
    const { updateEstate } = this.props;
    await updateEstate({ details });
  }

  @autobind
  async _handleConfirmBriefing() {
    const { confirmBriefing, estate, goBack } = this.props;
    await confirmBriefing(estate);
    window.scrollTo(0, 0);
    this.setState({ confirmModalOpen: false }, goBack);
  }
}


export default BriefingEstate;
