import React from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { compose } from 'redux';
import get from 'lodash/get';
import { Container } from 'react-ittsu/layout';

import { createTranslate } from 'utils/translation';
import { getSessionUser, getSession } from 'selectors';
import { getProject, getCurrentDraft, checkIfCorrectionChecked, getEnableApproveButton } from 'pods/studio/selectors';
import { loadProject } from 'pods/studio/actions/project';
import { changeCurrentDraft, toggleCorrectionRound } from 'pods/studio/actions/ui';
import { validateDraft, loadDraft } from 'pods/studio/actions/drafts';
import LoadingContainer from 'containers/LoadingContainer';
import StatusBar from 'pods/studio/components/StatusBar';
import Notification from 'pods/studio/components/Notification';
import Previews from 'pods/studio/components/Previews';
import Summary from 'pods/studio/components/Summary';


const tt = createTranslate('pods.studio.routes.overview');


const getNotificationText = (sessionUser, draft) => {
  const canValidateDraft = get(sessionUser, 'permissions.draft.validate', false);
  const canAddAnnotation = get(sessionUser, 'permissions.annotations.add', false);
  const draftCanBeValidated = draft.previews.every(p => p.validatedAt);
  const confirmedAt = draft.confirmedAt
    ? dayjs(draft.confirmedAt).format('Do MMMM YYYY')
    : null;
  const confirmedBy = draft.confirmedBy || 'Drawbotics';

  if (draft.status === 'problem') {
    return null;
  }

  if (draft.confirmed) {
    const byText = tt('by') + ' ' + confirmedBy;
    if (draft.hasAnnotations()) {
      return confirmedAt ?
        `${tt('corrections_sent_on')} ${confirmedAt} ${byText}` : tt('corrections_sent_to_production');
    }
    else {
      return confirmedAt ?
        `${tt('project_confirmed_on')} ${confirmedAt} ${byText}` : tt('project_confirmed') ;
    }
  }
  if ( ! canValidateDraft) {
    return tt('not_manage');
  }
  if ( ! canAddAnnotation) {
    return tt('permission_placeholder');
  }
  if (draftCanBeValidated && canValidateDraft) {
    if (draft.hasAnnotations()) {
      return tt('send_to_production');
    } else {
      return tt('validate');
    }
  }

  return null;
};


class Overview extends React.Component {

  componentDidMount() {
    const { project, loadProject, loadDraft, params, currentDraftId } = this.props;
    project?.id !== Number(params?.projectId) ? loadProject(params.projectId) : null;
    currentDraftId ? loadDraft(currentDraftId) : null;
  }

  componentDidUpdate(prevProps) {
    const { currentDraftId, loadDraft, params } = this.props;
    const { currentDraftId: prevDraftId } = prevProps;
    if (currentDraftId !== prevDraftId) {
      loadDraft(currentDraftId, params.projectId);
    }
  }

  render() {
    const {
      sessionUser,
      session,
      project,
      currentDraftId,
      isCorrectionChecked,
      changeCurrentDraft,
      toggleCorrectionRound,
      validateDraft,
      enableApproveButton,
    } = this.props;
    return (
      <LoadingContainer
        loadingKeys="project draft"
        whenLoaded={() => {
          if ( ! project ) {
            console.error('Project is falsy');
            // eslint-disable-next-line
            console.log(this.props);
            return null;
          }
          else if ( ! project.drafts) {
            console.error('Project has no drafts');
            // eslint-disable-next-line
            console.log(this.props);
            return null;
          }
          const currentDraft = project.drafts.find(d => d.id === currentDraftId);
          if ( ! currentDraft) {
            console.error('No draft matches current draft ID');
            // eslint-disable-next-line
            console.log(this.props);
            return null;
          }
          const notificationText = getNotificationText(sessionUser, currentDraft);
          const user = { ...sessionUser, type: session.type };
          return (
            <Container>
              {do {
                if (notificationText) {
                  <Notification text={notificationText} />
                }
              }}
              <StatusBar
                project={project}
                currentDraftId={currentDraft.id}
                onChangeDraft={changeCurrentDraft} />
              <Previews previews={currentDraft.previews} />
              <Summary
                user={user}
                draft={currentDraft}
                correctionChecked={isCorrectionChecked}
                onApprove={validateDraft}
                enableApproveButton={enableApproveButton}
                toggleCorrectionRound={toggleCorrectionRound} />
            </Container>
          );
        }}/>
    );
  }

}


const mapStateToProps = (state, ownProps) => {
  return {
    project: getProject(state, ownProps.match.params.projectId),
    currentDraftId: getCurrentDraft(state),
    sessionUser: getSessionUser(state),
    session: getSession(state),
    isCorrectionChecked: checkIfCorrectionChecked(state),
    params: ownProps.match.params,
    enableApproveButton: getEnableApproveButton(state),
  };
};


const mapDispatchToProps = {
  changeCurrentDraft,
  toggleCorrectionRound,
  validateDraft,
  loadProject,
  loadDraft,
};


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Overview);
