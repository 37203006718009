import Rx from 'rxjs-legacy';
import { combineEpics } from 'redux-observable';

import * as FollowersActions from 'pods/dashboard/actions/followers';
import * as ProjectItemActions from 'pods/dashboard/actions/project-item';
import * as AlertsActions from 'actions/alerts';
import { translate as t } from 'utils/translation';


function addFollower(action$, store, extra) {
  const { api } = extra;
  return action$
    .filter((action) => action.type === FollowersActions.ADD_FOLLOWER)
    .flatMap((action) => {
      const { follower, item, project } = action.payload;
      return Rx.Observable.fromPromise(project ? api.mutations.addProjectFollower(follower) : api.mutations.addFollower(follower))
        .flatMap((newFollower) => Rx.Observable.concat(
          Rx.Observable.of(FollowersActions.updateFollower(follower.id, newFollower)),
          Rx.Observable.of(FollowersActions.addFollowerSuccess(follower.id, newFollower.id)),
          Rx.Observable.of(ProjectItemActions.updateProjectItem(item.id, { followers: [ ...item.followers.map((i) => i.id), newFollower.id ]})),
          Rx.Observable.of(AlertsActions.showAlert(FollowersActions.ADD_FOLLOWER_SUCCESS, 'success', t('containers.alerts.project_item.follower_added'))),
        ))
        .catch((err) => Rx.Observable.concat(
          Rx.Observable.of(AlertsActions.showAlert(FollowersActions.ADD_FOLLOWER_FAIL, 'error', t('containers.alerts.project_item.errors.follower_not_added'))),
        ));
    });
}


function removeFollower(action$, store, extra) {
  const { api } = extra;
  return action$
    .filter((action) => action.type === FollowersActions.REMOVE_FOLLOWER)
    .flatMap((action) => {
      const { follower, project } = action.payload;
      return Rx.Observable.fromPromise(project ? api.mutations.removeProjectFollower(follower) : api.mutations.removeFollower(follower))
        .flatMap(() => Rx.Observable.concat(
          Rx.Observable.of(FollowersActions.removeFollowerSuccess(follower.id)),
          Rx.Observable.of(AlertsActions.showAlert(FollowersActions.REMOVE_FOLLOWER_SUCCESS, 'success', t('containers.alerts.project_item.follower_removed'))),
        ))
        .catch((err) => Rx.Observable.concat(
          Rx.Observable.of(AlertsActions.showAlert(FollowersActions.REMOVE_FOLLOWER_FAIL, 'error', t('containers.alerts.project_item.errors.follower_not_removed'))),
        ));
    });
}


export default combineEpics(
  addFollower,
  removeFollower,
);
