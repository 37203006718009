import {
  Align,
  Button,
  Category,
  Flex,
  FlexDirection,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Modal,
  Paragraph,
  Shade,
  Text,
  Tier,
  Title,
} from '@drawbotics/react-drylus';
import React from 'react';

import { openIntercom } from '~/pods/order/utils';
import { createTranslate } from '~/utils/translation';

import hello from '../images/hello.svg';

const tt = createTranslate('pods.order.components.unverified_user_modal');

interface UnverifiedUserModal {
  visible: boolean;
  onClickClose: VoidFunction;
  navigateToDashboard: VoidFunction;
}

export const UnverifiedUserModal = ({
  visible,
  onClickClose,
  navigateToDashboard,
}: UnverifiedUserModal) => {
  return (
    <Modal
      style={{ maxWidth: 500 }}
      visible={visible}
      title={tt('hello')}
      onClickClose={onClickClose}
      footer={
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <Button tier={Tier.TERTIARY} onClick={navigateToDashboard}>
              {tt('back_to_dashboard')}
            </Button>
          </FlexItem>
          <FlexSpacer />
          <FlexItem>
            <Button
              category={Category.BRAND}
              onClick={() => {
                openIntercom();
              }}>
              {tt('contact_us')}
            </Button>
          </FlexItem>
        </Flex>
      }>
      <Flex direction={FlexDirection.VERTICAL}>
        <FlexItem>
          <img src={hello} />
        </FlexItem>
        <FlexItem>
          <Title size={4}>{tt('get_to_know')}</Title>
        </FlexItem>
        <FlexItem>
          <Paragraph align={Align.CENTER}>
            <Text shade={Shade.MEDIUM}>{tt('understand_project')}</Text>
          </Paragraph>
        </FlexItem>
      </Flex>
    </Modal>
  );
};
