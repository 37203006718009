import gql from 'fraql';
import { useQuery } from 'urql';

import { Estate, Item } from '../../types';
import { ErrorType, buildError } from '../../utils';

interface EstateQueryData {
  estate: Estate;
}

interface EstateQueryVariables {
  estateId: string;
}

const EstateQuery = gql`
  query EstateQuery($estateId: ID!) {
    estate(estateId: $estateId) {
      id
      items {
        id
        name
        status @computed(type: Item)
        reference
        url
        estate {
          id
        }
        product {
          custom
          slug
          name
        }
        service {
          slug
        }
        estimatedDelivery
        briefingConfirmedAt
        milestones {
          id
          name
          date
          inducedState
        }
      }
    }
  }
`;

export function useFetchItems(
  estateId: string,
): { isLoading: boolean; items?: Array<Item>; error?: ErrorType; refetch: VoidFunction } {
  const [res, reexecuteQuery] = useQuery<EstateQueryData, EstateQueryVariables>({
    query: EstateQuery,
    variables: { estateId },
  });

  return {
    isLoading: res.fetching,
    items: res.data?.estate?.items,
    error: buildError(res.error),
    refetch: () => reexecuteQuery({ requestPolicy: 'network-only' }),
  };
}
