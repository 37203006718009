import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { Provider as UrqlProvider } from 'urql';

import { MediaProvider } from './utils/media-provider';
import bootstrap from 'bootstrap';
import Root from 'routes/Root';
import { DiContainerProvider } from '@tokamakjs/react';


bootstrap((store, history, client, urqlClient, diContainer) => {
  const App = () => {
    return (
      <Provider store={store}>
        <ApolloProvider client={client}>
          <UrqlProvider value={urqlClient}>
            <MediaProvider>
              <Router history={history}>
                <DiContainerProvider value={diContainer}>
                  <Root />
                </DiContainerProvider>
              </Router>
            </MediaProvider>
          </UrqlProvider>
        </ApolloProvider>
      </Provider>
    );
  };

  const renderApp = (Root) => {
    ReactDOM.render(
      <App />,
      document.getElementById('root'),
    );
  };

  renderApp(Root);
});
