import { Content } from '@drawbotics/react-drylus';
import React, { Fragment, ReactNode } from 'react';

interface EstateLayoutProps {
  children: ReactNode;
  header: ReactNode;
}

export const EstateLayout = ({ children, header }: EstateLayoutProps) => {
  return (
    <Fragment>
      {header}
      <Content style={{ background: 'transparent' }}>{children}</Content>
    </Fragment>
  );
};
