const k = (key) => `photo_editing.briefing.${key}`;

const residential = {
  1: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('photos_to_edit'),
        component: 'Title',
      },
      {
        required: 'all',
        type: 'question-component',
        label: k('upload_all_photos'),
        component: 'FileUploader',
        key: 'photosToEdit',
        keyLabel: k('photos_to_edit'),
      },
    ],
  },
  2: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('description'),
        component: 'Title',
      }, {
        type: 'question-component',
        label: k('description_note'),
        component: 'Input',
        key: 'description',
        required: 'all',
        keyLabel: k('keys.description'),
      },
    ],
  },
};

export default { residential };
