import React from 'react';
import { connect } from 'react-redux';
import { getIsLoading } from 'loading-manager';
import cn from 'classnames';

import Spinner from 'components/Spinner';

import styles from 'styles/containers/loading-container';


const className = (props) => cn({
  [styles.loadingContainer]: ! props.smallSize,
  [styles.loadingContainerSmall]: props.smallSize,
}, props.className);


const LoadingContainer = (props) => {
  const {
    required,
    isLoading,
    whenLoaded,
    size=50,
    children,
  } = props;
  const requiredFulfilled = required ? required.reduce((memo, r) => memo && r != null, true) : true;

  if (isLoading || ! requiredFulfilled) {
    if (children) {
      return children;
    }
    return (
      <div className={className(props)}>
        <Spinner size={size} />
      </div>
    );
  }

  return whenLoaded();
};


const mapStateToProps = (state, ownProps) => ({
  isLoading: ownProps.loadingKeys.replace(/\s+/, ' ').split(' ').reduce((memo, k) => {
    const loading = getIsLoading(state.ui, k);
    const defaultTo = ownProps.defaultTo === undefined ? true : ownProps.defaultTo;
    return memo || loading === undefined ? defaultTo : loading;
  }, false),
});


export default connect(mapStateToProps)(LoadingContainer);
