import React from 'react';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';

import { createTranslate } from '~/utils/translation';


const t = createTranslate('pods.order.services');


const styles = {
  detailsSection: css`
    margin-bottom: ${sv.baseMargin};
  `,
  title: css`
    position: relative;
    margin-bottom: ${sv.baseMargin};
    font-family: ${sv.baseFontFamilyAlt};
    text-transform: uppercase;
    color: ${sv.textPrimaryDark};
    font-weight: 600;
    font-size: 0.85em;

    &::after {
      content: ' ';
      position: absolute;
      bottom: calc(-1 * ${sv.baseMarginSmall});
      left: 0;
      width: 100%;
      height: 1px;
      background: ${sv.grey300};
    }
  `
};


const DetailsSection = ({ title, components }) => {
  return (
    <div className={styles.detailsSection}>
      <div className={styles.title}>{t(title)}</div>
      {components.map((component, i) => (
        <component.type {...component.props} key={i} />
      ))}
    </div>
  );
}


export default DetailsSection;
