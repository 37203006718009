import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import { flatten } from 'utils';
import { generateSchemas, defineSchema, hasMany } from 'entman';
import get from 'lodash/get';


const servicesNames = [
  'exterior3d',
  'interior3d',
  'shoebox',
  'visit3d',
  'commercialFloorplan',
  'website',
  'exteriorRestyling',
  'restyling',
  'plan2d',
  'photoEditing',
  'branding',
  'billboard',
];


const organization = defineSchema('Organization', {
  attributes: {
    addresses: hasMany('Address'),
    invitations: hasMany('Invitation'),

    getClients() {
      return this.invitations.filter((i) => i.status !== 'pending');
    },
  },
});


const user = defineSchema('User', {
  attributes: {
    organization: 'Organization',
    cart: 'Cart',

    getName() {
      return this.firstName + ' ' + this.lastName;
    },
    getThumbnail() {
      return get(this, 'profilePicture.thumbnail', null) ?
        this.profilePicture.thumbnail :
        get(this, 'profilePicture', null);
    },
    addCurrency(amount) {
      return new Intl.NumberFormat(
        window.navigator.userLanguage || window.navigator.language || this.locale, {
          style: 'currency',
          currency: window.userCurrency.code || this.currency.code,
          minimumFractionDigits: 0,
        }).format(amount).replace(/([$|£|€])(\D*)/, '$1');
    },
  },
});


const address = defineSchema('Address', {
  attributes: {
    organization: 'Organization',
  },
});


const invitation = defineSchema('Invitation', {
  attributes: {
    organization: 'Organization',
    user: 'User',

    getName() {
      return this.user.getName();
    },
    getThumbnail() {
      return this.user.getThumbnail();
    },
  },
});


const project = defineSchema('Project', {
  attributes: {
    items: hasMany('ProjectItem'),
  },
});


const projectItem = defineSchema('ProjectItem', {
  attributes: {
    project: 'Project',
    attachments: hasMany('Attachment'),
    followers: hasMany('Follower'),
    invitations: hasMany('Invitation'),
    drafts: hasMany('Draft'),
    problems: hasMany('Problem'),
    sessionUser: 'User',
    service: 'Service',

    sortedDrafts() {
      return this.drafts.sort((d1, d2) => d2.valueOf() - d1.valueOf());
    },
  },
});


const problem = defineSchema('Problem', {
});


const follower = defineSchema('Follower', {
  attributes: {
    user: 'User',

    getName() {
      return this.user.getName();
    },
    getInitials() {
      return this.user.firstName[0] + this.user.lastName[0];
    },
  },
});


const invoice = defineSchema('Invoice', {
});


const salesOrder = defineSchema('SalesOrder', {
});


const order = defineSchema('Order', {
  attributes: {
    projects: hasMany('Project'),
  },
});


const attachment = defineSchema('Attachment', {
  attributes: {
    annotation: 'Annotation',
  },
});


const transaction = defineSchema('Transaction', {
  attributes: {
    buyer: 'User',
    recipientOrganization: 'Organization',
    recipientUser: 'User',
  },
});


const draft = defineSchema('Draft', {
  attributes: {
    projectItem: 'ProjectItem',
    previews: hasMany('Preview'),
    sessionUser: 'User',

    isEditable() {
      return this.status === 'need_action';
    },
    hasAnnotations() {
      return this.previews.some(p => p.annotations.length > 0);
    },
    remainingCorrectionRounds() {
      return this.correctionRounds.filter(r => ! r.used).length;
    },
  },
});


const preview = defineSchema('Preview', {
  attributes: {
    draft: 'Draft',
    annotations: hasMany('Annotation'),
    validatedBy: 'User',

    hasAnnotations() {
      return this.annotations.length > 0;
    },
    isValidated() {
      return !!this.validatedAt;
    },
  },
});


const annotation = defineSchema('Annotation', {
  attributes: {
    preview: 'Preview',
    createdBy: 'User',
    attachments: hasMany('Attachment'),

    hasProblems() {
      return this.actionable && this.activeProblems()?.length > 0;
    },
    activeProblems() {
      return this.problems.filter(p => p.solved_at == null);
    }
  },
});


const cart = defineSchema('Cart', {
  attributes: {
    estates: hasMany('Estate'),

    getNumberOfItems() {
      return this.estates ? this.estates.length : this.count;
    },
  },
});


const estate = defineSchema('Estate', {
  attributes: {
    cart: 'Cart',
    items: hasMany('Item'),
    quickService: 'Service',

    getNumberOfApartments() {
      return this.apartments + ' Apartments';
    },

    isQuickService() {
      return ! isEmpty(this.quickService);
    },


    hasCustomService() {
      return this.items.some((i) => i.service.isCustomService);
    },

    getCustomServices() {
      return this.items
        .filter((i) => i.service.isCustomService)
        .sort((i1, i2) => i1.service.id - i2.service.id);
    },

    isFieldDisabled(name) {
      return this._disabled.includes(name);
    },

    getSelectedServices(order) {
      const selectedServicesNames = Object.entries(this.services)
        .map(s => s[1] && s[0])
        .filter(x => !!x);
      const result = Array.from(new Set(this.items
        .filter(i => selectedServicesNames.includes(i.service.name))
        .map(i => i.service)));
      if ( ! order) {
        return result;
      }
      const sortedResult = result.sort((s1, s2) => {
        return order.indexOf(s1.name) - order.indexOf(s2.name);
      });
      // Add selected services not present in the order at the end
      return uniqBy([ ...sortedResult, ...result ], 'id');
    },

    getSelectedItems() {
      const selectedServices = this.getSelectedServices();
      return selectedServices.reduce((memo, s) => {
        return [...memo, ...this.getItemsByService(s.name)];
      }, []);
    },

    getLongestService() {
      return this.getSelectedServices()
        .sort((s1, s2) => {
          if ( ! s1.durations || ! s2.durations) {
            return null;
          }
          return s2.durations[this.delivery].preview - s1.durations[this.delivery].preview;
        })[0];
    },

    getItemsByService(serviceName) {
      if ( ! serviceName) {
        const selectedServices = this.getSelectedServices();
        return selectedServices.reduce((memo, serviceName) => ({
          ...memo,
          [serviceName.name]: this.getItemsByService(serviceName.name),
        }), {});
      }
      return this.items.filter(i => i.service.name === serviceName);
    },

    getPrice(currency) {
      const price = this.getRawPrice(currency);
      const priceWithDiscounts = this.getDiscountAmounts(currency).reduce((memo, d) => {
        return memo - memo * d;
      }, price);
      return Math.round(priceWithDiscounts);
    },

    getRawPrice(currency) {
      if ( ! currency) {
        throw new Error('You should specify a currency');
      }
      if (this.isQuickService()) {
        return this.items
          .filter(i => i.service.name === this.quickService.name)[0].getPrice(currency);
      }
      const selectedServices = this.getSelectedServices().map(s => s.id);
      return this.items
        .filter(i => selectedServices.includes(i.service.id))
        .reduce((memo, i) => i.getPrice(currency) + memo, 0);
    },

    getDiscountAmounts(currency) {
      return this.discounts
        .sort((d1, d2) => d1.id - d2.id)
        .filter((d) => d.min_amount <= this.getRawPrice(currency))
        .map((d) => d.rate);
    },

    getQuickServicePrice(currency) {
      if ( ! currency) {
        throw new Error('You should specify a currency');
      }
      if ( ! this.isQuickService()) {
        console.warn('Trying to get quick service price of a non quick service estate');
        return null;
      }
      return this.getPrice(currency);
    },

    isValid() {
      return isEmpty(flatten(this.getValidation()));
    },

    getValidation() {
      // const estateValidation = validateEstate(this);
      // return {
      //   ...estateValidation,
      //   selectedServices: estateValidation.services || {},
      //   services: this.getValidationByService(),
      // };
    },

    getValidationByService(service) {
      // const result = validateItems(this.getSelectedItems());
      // return isEmpty(service) ? result : result[service];
    },

    getQuickServiceValidation() {
      if ( ! this.isQuickService()) {
        console.warn('Trying to get quick service validation of a non quick service estate');
        return null;
      }
      return this.getValidationByService(this.quickService.name);
    },

    getServicesNames() {
      return [
        ...servicesNames,
        ...this.getCustomServices().map((i) => i.service).map((s) => s.name),
      ];
    },

    prepareToSave() {
      return {
        ...this.toEntity(),
        items: this.items.map(i => i.prepareToSave()),
        valid: this.isValid(),
      };
    },

    toEntity() {
      const serialized = JSON.stringify({
        ...this,
        cart: this.cart.id,
        items: this.items.map(i => i.id),
      });
      return JSON.parse(serialized);
    },
  },
});


const item = defineSchema('Item', {
  attributes: {
    service: 'Service',
    estate: 'Estate',
    extraVisuals: hasMany('ExtraVisual'),

    isFieldDisabled(name) {
      return this._disabled.includes(name);
    },

    getNumberOfVisuals() {
      if (this.extraVisuals && this.extraVisuals.length > 0) {
        return this.extraVisuals.length;
      }
      return 1;
    },

    getPrice(currency) {
      if (this.service.isCustomService) {
        return this.price;
      }
      // return priceItem(this, currency);
    },

    getValidation() {
      // return validateItem(this);
    },

    prepareToSave() {
      return {
        ...this,
        price: this.getPrice(),
      };
    },
  },
});


const service = defineSchema('Service', {
});


const extraVisual = defineSchema('ExtraVisual', {
  attributes: {
    item: 'Item',
  },
});


const schemas = generateSchemas([
  organization,
  user,
  address,
  invitation,
  project,
  projectItem,
  attachment,
  follower,
  transaction,
  draft,
  preview,
  annotation,
  cart,
  estate,
  item,
  service,
  extraVisual,
  invoice,
  salesOrder,
  order,
  problem,
]);


export default schemas;
