import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';


function sendDataBack(data) {
  const receiveFunctionName = window.opener.receiveFunctionName;
  if (window.opener[receiveFunctionName]) {
    window.opener[receiveFunctionName](data);
  }
  window.close();
}


class Return extends React.Component {

  componentDidMount() {
    const { query } = this.props;
    if (query) {
      sendDataBack(query);
    }
  }

  componentDidUpdate() {
    const { query } = this.props;
    if (query) {
      sendDataBack(query);
    }
  }

  render() {
    return (
      <div></div>
    );
  }

}


export default connect((state, ownProps) => ({
  query: queryString.parse(ownProps.location.search),
}))(Return);
