import {
  createEntities,
  updateEntities,
  deleteEntities,
} from 'entman';

import schemas from 'schemas';


export const CREATE_ATTACHMENT = 'CREATE_STUDIO_ATTACHMENT';

export function createAttachment(data) {
  return createEntities(schemas.Attachment, 'payload.data', {
    type: CREATE_ATTACHMENT,
    payload: {
      data: { ...data, isDangling: true },
    },
  });
}


export const DELETE_ATTACHMENT = 'DELETE_STUDIO_ATTACHMENT';

export function deleteAttachment(id) {
  return deleteEntities(schemas.Attachment, id, {
    type: DELETE_ATTACHMENT,
    payload: {
      id,
    },
  });
}


export const UPDATE_ATTACHMENT = 'UPDATE_ATTACHMENT';

export function updateAttachment(id, data) {
  return updateEntities(schemas.Attachment, id, 'payload.data', {
    type: UPDATE_ATTACHMENT,
    payload: {
      data,
    },
  });
}


export const SAVE_ATTACHMENTS = 'SAVE_ATTACHMENTS';

export function saveAttachments(attachments, annotationId) {
  return {
    type: SAVE_ATTACHMENTS,
    payload: {
      attachments,
      annotationId,
    },
  };
}


export const SAVE_ATTACHMENTS_DONE = 'SAVE_ATTACHMENTS_DONE';

export function saveAttachmentsDone() {
  return {
    type: SAVE_ATTACHMENTS_DONE,
  };
}
