import React from 'react'
import { css, cx } from 'emotion';
import sv from '@drawbotics/style-vars';


const stepSize = '15px';

const styles = {
  steps: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  step: css`
    height: ${stepSize};
    width: ${stepSize};
    border-radius: 10000px;
    background: ${sv.grey400};
    margin: 0 ${sv.basePaddingSmall};
  `,
  visited: css`
    background: ${sv.brandPrimary};
  `,
  clickable: css`
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  `,
}


const Steps = ({ steps, activeStep, goTo }) => {
  const normalizedSteps = Object.keys(steps).map((s) => parseInt(s));
  return (
    <div className={styles.steps}>
      {normalizedSteps.map((stepNumber) =>
        <div
          key={stepNumber}
          onClick={goTo ? () => goTo(stepNumber) : null}
          className={cx(styles.step, {
            [styles.visited]: activeStep >= stepNumber,
            [styles.clickable]: goTo,
          })} />
      )}
    </div>
  );
}


export default Steps;
