import {
  Button,
  Category,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  FormGroup,
  Input,
  Label,
  Margin,
  Modal,
  Select,
  Size,
  Spinner,
  Tier,
} from '@drawbotics/react-drylus';
import { useForm } from '@drawbotics/use-form';
import React from 'react';
import { z } from 'zod';

import { Form } from '~/components';
import { OrganizationRole } from '~/types';
import { createTranslate } from '~/utils/translation';

import { Invitation } from '../api/domain';

const tt = createTranslate('components.invite_modal');

function _createSchema() {
  return z.object({
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    email: z
      .string()
      .min(1, tt('cannot_be_empty'))
      .email(tt('invalid_email')),
    role: z.nativeEnum(OrganizationRole),
  });
}

interface InviteModalProps {
  visible: boolean;
  isSendingInvitation: boolean;
  onClickClose: VoidFunction;
  onClickInvite: (invitation: Partial<Invitation>) => Promise<void>;
}

export const InviteModal = ({
  onClickInvite,
  visible,
  onClickClose,
  isSendingInvitation,
}: InviteModalProps) => {
  const inviteForm = useForm(_createSchema(), {
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      role: OrganizationRole.MEMBER,
    },
  });

  const handleClickClose = () => {
    onClickClose();
    setTimeout(() => {
      inviteForm.reset();
    }, 300);
  };

  const handleClickInvite = async () => {
    const validationResult = inviteForm.validateAll();

    if (validationResult.data) {
      await onClickInvite(validationResult.data);
      handleClickClose();
    }
  };

  return (
    <Modal
      onClickClose={handleClickClose}
      title={tt('invite_title')}
      visible={visible}
      footer={
        <Flex justify={FlexJustify.END}>
          <FlexItem>
            <Button tier={Tier.TERTIARY} onClick={handleClickClose}>
              {tt('cancel')}
            </Button>
          </FlexItem>
          <FlexSpacer size={Size.SMALL} />
          <FlexItem>
            <Button
              trailing={isSendingInvitation ? <Spinner size={Size.SMALL} inversed /> : null}
              category={Category.BRAND}
              onClick={handleClickInvite}>
              {tt('send_invitation')}
            </Button>
          </FlexItem>
        </Flex>
      }>
      <Form onSubmit={handleClickInvite}>
        <FormGroup
          label={<Label>{tt('first_name')}</Label>}
          input={
            <Input
              name="firstName"
              validate={inviteForm.validate}
              error={!!inviteForm.getError('firstName')}
              value={inviteForm.get}
              onChange={inviteForm.set}
              placeholder={tt('first_name_placeholder')}
            />
          }
        />
        <Margin size={{ bottom: Size.SMALL }} />
        <FormGroup
          label={<Label>{tt('last_name')}</Label>}
          input={
            <Input
              name="lastName"
              validate={inviteForm.validate}
              value={inviteForm.get}
              error={!!inviteForm.getError('lastName')}
              onChange={inviteForm.set}
              placeholder={tt('last_name_placeholder')}
            />
          }
        />
        <Margin size={{ bottom: Size.SMALL }} />
        <FormGroup
          label={<Label>{tt('email')}</Label>}
          input={
            <Input
              name="email"
              type="email"
              validate={inviteForm.validate}
              error={inviteForm.getError}
              value={inviteForm.get}
              onChange={inviteForm.set}
              placeholder={tt('email_placeholder')}
            />
          }
        />
        <Margin size={{ bottom: Size.SMALL }} />
        <FormGroup
          label={<Label>{tt('role')}</Label>}
          input={
            <Select
              options={[
                {
                  value: OrganizationRole.ADMIN,
                  label: tt('admin'),
                },
                {
                  value: OrganizationRole.MEMBER,
                  label: tt('member'),
                },
              ]}
              name="role"
              value={inviteForm.get}
              onChange={inviteForm.set}
              placeholder={tt('role_placeholder')}
            />
          }
        />
      </Form>
    </Modal>
  );
};
