import React from 'react';
import gql from 'fraql';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';

import DetailsSection from './DetailsSection';
import { getSectionsInfo } from '../utils';


const styles = {
  briefingDetails: css`
    margin-top: ${sv.baseMargin};
    display: flex;
    align-items: flex-start;

    @media ${sv.phone} {
      flex-direction: column;
      align-items: stretch;
    }
  `,
  leftColumn: css`
    flex: 1;
    margin-right: ${sv.baseMargin};

    @media ${sv.phone} {
      margin-right: 0;
    }
  `,
  rightColumn: css`
    flex: 1;
    margin-left: ${sv.baseMargin};

    @media ${sv.phone} {
      margin-left: 0;
    }
  `,
};


class BriefingDetails extends React.Component {

  static propTypes = {
    details: PropTypes.object.isRequired,
    sections: PropTypes.object.isRequired,
    estate: PropTypes.object.isRequired,
  };

  static fragments = {
    BriefingDetails_on_Item: gql`
      fragment _ on Item {
        id,
      },
    `,
  };

  render() {
    const { details, sections, estate } = this.props;
    const arrayOfSections = getSectionsInfo(details, sections, estate);
    const halfSections = Math.ceil(arrayOfSections.length / 2);
    return (
      <div className={styles.briefingDetails}>
        <div className={styles.leftColumn}>
          {arrayOfSections.slice(0, halfSections).map((section, i) => (
            <DetailsSection title={section.title} components={section.components} key={i} />
          ))}
        </div>
        <div className={styles.rightColumn}>
          {arrayOfSections.slice(halfSections, arrayOfSections.length).map((section, i) => (
            <DetailsSection title={section.title} components={section.components} key={i} />
          ))}
        </div>
      </div>
    );
  }
}


export default BriefingDetails;
