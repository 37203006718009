const specs = {
  standard: {
    operation: 'create',
    create: {
      type: 'bool',
      product: 'panorama360_standard',
    },
  },
};


const dependencies = {
  hard: [
    {
      operation: 'createOrUpdate',
      createOrUpdate: {
        product: 'droneShooting_standard',
        service: 'droneShooting',
        with: { panorama360: true },
      },
    },
  ],
  soft: [],
};


export default { specs, dependencies };
