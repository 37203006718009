import morphey, { fromKey } from 'morphey';

import { previewFromServer } from 'utils/api/translators/preview';


const translationsFromServer = {
  id: 'id',
  confirmed: 'confirmed',
  confirmedAt: 'confirmed_at',
  confirmedBy: 'confirmed_by',
  createdAt: 'created_at',
  previews: fromKey('previews').defaultTo([]).using((p) => p.map(previewFromServer)),
  projectItem: 'projectItem',
  status: fromKey('status'),
  correctionRoundPrice: 'correction_round_price',
};


export function draftFromServer(draft) {
  return morphey(draft, translationsFromServer);
}


export default {
  fromServer: draftFromServer,
};
