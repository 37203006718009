const specs = {
  standard: {
    operation: 'create',
    create: {
      type: 'bool',
      product: 'revo_standard',
    },
  },
  droneShooting: {
    operation: 'update',
    update: {
      type: 'mapTo',
      target: '@{product.id} == "revo_standard"',
      mapTo: [ 'droneShooting' ],
    },
  },
  project360Rotation: {
    operation: 'update',
    update: {
      type: 'mapTo',
      target: '@{product.id} == "revo_standard"',
      mapTo: [ 'project360Rotation' ],
    },
  },
  floorplansIn3d: {
    operation: 'update',
    update: {
      type: 'mapTo',
      target: '@{product.id} == "revo_standard"',
      mapTo: [ 'floorplansIn3d' ],
    },
  },
  furnishedFloors: {
    operation: 'update',
    update: {
      type: 'mapTo',
      target: '@{product.id} == "revo_standard"',
      mapTo: [ 'furnishedFloors' ],
    },
  },
  shoebox360Rotation: {
    operation: 'update',
    update: {
      type: 'mapTo',
      target: '@{product.id} == "revo_standard"',
      mapTo: [ 'shoebox360Rotation' ],
    },
  },
  googleMapsIntegration: {
    operation: 'update',
    update: {
      type: 'mapTo',
      target: '@{product.id} == "revo_standard"',
      mapTo: [ 'googleMapsIntegration' ],
    },
  },
  environmentIn3d: {
    operation: 'update',
    update: {
      type: 'mapTo',
      target: '@{product.id} == "revo_standard"',
      mapTo: [ 'environmentIn3d' ],
    },
  },
};


const dependencies = {
  hard: [],
  soft: [
    {
      if: {
        key: 'droneShooting',
        value: true,
      },
      then: {
        operation: 'createOrUpdate',
        createOrUpdate: {
          product: 'droneShooting_standard',
          service: 'droneShooting',
          with: { revo: true },
        },
      },
    }
  ],
};


export default { specs, dependencies };
