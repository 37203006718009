import React from 'react';
import { css } from 'emotion';
import sv, { fade } from '@drawbotics/style-vars';
import MapboxMap, { Marker as MapboxMarker } from 'react-mapbox-wrapper';
import { LngLatBounds } from 'mapbox-gl';
import autobind from 'autobind-decorator';

import { createTranslate } from '~/utils/translation';


const t = createTranslate('pods.order.services');


const styles = {
  title: css`
    font-family: ${sv.baseFontFamilyAlt};
    text-transform: uppercase;
    color: ${sv.textPrimaryDark};
    font-weight: 600;
    font-size: 0.65rem;
    margin-bottom: ${sv.baseMarginSmall};
  `,
  mapWrapper: css`
    height: 200px;
  `,
  map: css`
    height: 100%;

    & .mapboxgl-control-container {
      display: none;
    }
  `,
  marker: css`
    position: relative;
    height: 20px;
    width: 20px;
    border-radius: 1000px;
    background: ${fade(sv.brandPrimary, 30)};
    border: 1px solid ${sv.brandPrimary};
  `,
  label: css`
    position: absolute;
    top: calc(-100% - 5px);
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px 10px;
    border-radius: 10px;
    color: ${sv.white};
    background: ${sv.brandPrimary};
  `,
};


class Map extends React.Component {
  render() {
    const { values, title, estate } = this.props;
    const pois = values.map((address) => ({ label: address.label, coordinates: { lng: parseFloat(address.longitude), lat: parseFloat(address.latitude) }}));
    const estatePoi = { label: 'Project', coordinates: { lng: parseFloat(estate.address.longitude), lat: parseFloat(estate.address.latitude) } };
    const allPois = [estatePoi, ...pois];
    return (
      <div>
        <div className={styles.title}>
          {t(title)}
        </div>
        <div className={styles.mapWrapper}>
          <MapboxMap
            onLoad={this._handleFitPois}
            className={styles.map}
            accessToken={process.env.MAPBOX_ACCESS_TOKEN}
            interactive={false}
            // eslint-disable-next-line react/style-prop-object
            style="mapbox://styles/mapbox/light-v9"
            coordinates={estatePoi.coordinates}>
            {do{
              if (this.map) {
                allPois.map((poi, i) => (
                  <MapboxMarker
                    key={i}
                    coordinates={poi.coordinates}
                    map={this.map}>
                    <div className={styles.marker}>
                      <div className={styles.label}>{poi.label}</div>
                    </div>
                  </MapboxMarker>
                ));
              }
            }}
          </MapboxMap>
        </div>
      </div>
    );
  }

  @autobind
  _handleFitPois(map) {
    const { values, estate } = this.props;
    this.map = map;
    this.forceUpdate();
    const projectCoordinates = [estate.address.longitude, estate.address.latitude];
    const bounds = new LngLatBounds(projectCoordinates, projectCoordinates);
    const coordinatesToFit = values.reduce((coords, poi) =>
      coords.extend([ poi.longitude, poi.latitude ]), bounds);
    this.map.fitBounds(coordinatesToFit, { padding: { top: 60, bottom: 60, left: 60, right: 60 } });
  }
}


export default Map;
