import { ApolloLink } from 'apollo-link';
import isPlainObject from 'lodash/isPlainObject';


function _camelize(str) {
  return str
    .replace(/(\d)(_)/g, '$1')
    .replace(/(_)(\S{1})/g, (match, a, b) => b.toUpperCase());
}


function _getServiceId(service) {
  return _camelize(service.slug);
}


function _getServiceIdFromProduct(product) {
  if (product.service?.slug != null) {
    return product.service.slug;
  }
  else {  // try to guess it
    const [ serviceId ] = product.slug.split(/_(?=(standard|premium))/);
    return serviceId;
  }
}


function _getProductId(product) {
  if (product.custom) {
    return product.slug;
  }
  else {
    const serviceId = _getServiceIdFromProduct(product);
    const productVariation = product.slug.replace(serviceId, '');
    return (productVariation != null && productVariation !== '')
      ? `${_camelize(serviceId)}_${_camelize(productVariation.slice(1))}`
      : _camelize(serviceId);
  }
}


export function slugToId(data) {
  if (Array.isArray(data)) {
    return data.map(slugToId);
  }
  else if (isPlainObject(data)) {
    return Object.keys(data).reduce((memo, key) => {
      if (key === 'slug') {
        if (data.__typename === 'Product') {
          return { ...memo, id: _getProductId(data) };
        }
        else if (data.__typename === 'ProductService') {
          return { ...memo, id: _getServiceId(data) };
        }
      }
      return { ...memo, [key]: slugToId(data[key]) };
    }, data);
  }
  else {
    return data;
  }
}


/**
 * Quick and dirty hack to get the id of a product
 * or service at request time so we can use it at
 * computation time.
 *
 * TODO: Find a cleaner solution.
 */
export function withSlugToId() {
  return new ApolloLink((operation, forward) => {
    return forward(operation).map((data) => {
      if (Array.isArray(data)) {
        return data.map(slugToId);
      }
      else {
        return slugToId(data);
      }
    });
  });
}
