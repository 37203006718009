import React from 'react';
import {
  Panel,
  PanelHeader,
  PanelTitle,
  PanelBody,
  Button,
  Paragraph,
} from 'react-ittsu/components';
import {
  Container,
  Grid,
  Offset,
  Cell,
} from 'react-ittsu/layout';
import {
  Input,
  Select,
  FormGroup,
  Form,
} from 'react-ittsu/forms';
import { connect } from 'react-redux';
import countries from 'utils/countries';
import get from 'lodash/get';

import RouteContainer from 'components/RouteContainer';
import LoadingContainer from 'containers/LoadingContainer';
import { loadTransaction, updateTransactionAddress, confirmSalesOrder } from 'pods/transactions/actions';
import { getTransaction } from 'pods/transactions/selectors';
import { createTranslate } from 'utils/translation';

import styles from 'pods/transactions/styles/routes/edit-sales-order';


function isValidAddress(address) {
  if (! address) {return false}
  return address.street && address.city && address.label && address.zipCode && address.countryCode;
}


const et = createTranslate('forms.errors');
const tt = createTranslate('pods.transactions.routes.edit_sales_order');


class EditSalesOrder extends React.Component {

  state = {
    address: {},
  }

  constructor(props) {
    super(props);
    this._confirmSalesOrder = this._confirmSalesOrder.bind(this);
    this._updateTransactionAddress = this._updateTransactionAddress.bind(this);
    this._getDefaultAddressValues = this._getDefaultAddressValues.bind(this);
    this._handleChangeField = this._handleChangeField.bind(this);
  }

  componentDidMount() {
    const { loadTransaction, params } = this.props;
    loadTransaction(params.id);
  }

  render() {
    const { address } = this.state;
    const { transaction } = this.props;
    const isAddressValid = isValidAddress(get(transaction, 'billingAddress', null));
    const defaultAddress = this._getDefaultAddressValues(this.props);
    return (
      <LoadingContainer
        loadingKeys="transaction sessionUser"
        defaultTo={false}
        required={[
          transaction,
          get(transaction, 'buyer'),
        ]}
        whenLoaded={() => (
          <RouteContainer>
            <Container>
              <Grid>
                <Offset size="1of4" responsive="m1of1"/>
                <Cell size="2of4" responsive="m1of1">
                  <Panel>
                    <PanelHeader>
                      <PanelTitle>
                        {tt('billing_info')}
                      </PanelTitle>
                    </PanelHeader>
                    <PanelBody>
                      <Form
                        name="address"
                        resetWhenDefaultsChange
                        defaultValues={this._getDefaultAddressValues()}
                        onSubmit={this._confirmSalesOrder}>
                        {do {
                          if (isAddressValid) {
                            <div className={styles.section}>
                              <div className={styles.title}>
                                {tt('choose_label')}
                              </div>
                              <FormGroup>
                                <Select
                                  name="address"
                                  values={get(transaction, 'buyer.organization.addresses', [])
                                    .map((a) => ({ label: a.label , value: a.id }))}
                                  value={address.id || defaultAddress.id}
                                  onChange={this._updateTransactionAddress}/>
                              </FormGroup>
                            </div>
                          }
                          else {
                            <Paragraph className={styles.noAddress}>{tt('no_address')}</Paragraph>
                          }
                        }}
                        <div className={styles.section}>
                          <div className={styles.title}>
                            {tt('modify_address')}
                          </div>
                          <FormGroup>
                            <Input
                              name="label"
                              validation={(v) => v.isEmpty(et('empty_field'))}
                              placeholder={tt('billing_name')} />
                          </FormGroup>
                          <FormGroup>
                            <Input
                              name="street"
                              validation={(v) => v.isEmpty(et('empty_field'))}
                              placeholder={tt('address.street')} />
                          </FormGroup>
                          <Grid halfHGutters>
                            <Cell size="1of2" responsive="s1of1">
                              <FormGroup>
                                <Input
                                  name="zipCode"
                                  validation={(v) => v.isEmpty(et('empty_field'))}
                                  placeholder={tt('address.zip_code')} />
                              </FormGroup>
                            </Cell>
                            <Cell size="1of2" responsive="s1of1">
                              <FormGroup>
                                <Input
                                  name="city"
                                  validation={(v) => v.isEmpty(et('empty_field'))}
                                  placeholder={tt('address.city')}
                                  error={address.isValidVat ? null : tt('invalid_tva')}/>
                              </FormGroup>
                            </Cell>
                          </Grid>
                          <FormGroup>
                            <Select
                              name="countryCode"
                              values={countries.all.map(a => ({ value: a.alpha2, label: a.name }))} />
                          </FormGroup>
                          <Grid halfHGutters>
                            <Cell size="1of2" responsive="s1of1">
                              <FormGroup style={{ marginBottom: 0 }}>
                                <Input
                                  name="vat"
                                  placeholder="e.g. BE0845186239"
                                  validation={(v) => v.isEmpty(et('empty_field'))}
                                  error={address.isValidVat ? null : tt('invalid_tva')}/>
                              </FormGroup>
                            </Cell>
                            <Cell size="1of2" responsive="s1of1">
                              <div className={styles.vatInfo}>{tt('vat_validating')}</div>
                            </Cell>
                          </Grid>
                        </div>
                        <div className={styles.section}>
                          <div className={styles.title}>
                            {tt('add_internal_reference')}
                          </div>
                          <FormGroup style={{ marginBottom: 0 }}>
                            <Input
                              name="internalReference"
                              placeholder={tt('internal_reference')}
                              value={address.internalReference || ''}
                              onChange={this._handleChangeField} />
                          </FormGroup>
                        </div>
                        <div className={styles.rightAlign}>
                          <LoadingContainer
                            loadingKeys="confirmSalesOrder"
                            defaultTo={false}
                            size={25}
                            smallSize
                            whenLoaded={() => (
                              <Button category="primary">{tt('validate')}</Button>
                            )} />
                        </div>
                      </Form>
                    </PanelBody>
                  </Panel>
                </Cell>
                <Offset size="1of4" responsive="m1of1"/>
              </Grid>
            </Container>
          </RouteContainer>
        )} />
    );
  }

  _confirmSalesOrder(address) {
    const { confirmSalesOrder, transaction } = this.props;
    const cartId = get(transaction, 'relatedObjectId');
    confirmSalesOrder(address, cartId, transaction.id);
  }

  _handleChangeField(value, name) {
    this.setState({
      address: {
        ...this.state.address,
        [name]: value,
      },
    });
  }

  _updateTransactionAddress(addressId) {
    const { updateTransactionAddress, transaction } = this.props;
    this.setState({ address: {} });
    updateTransactionAddress(transaction, addressId);
  }

  _getDefaultAddressValues() {
    const { transaction } = this.props;
    return {
      label: get(transaction, 'billingAddress.label') ? get(transaction, 'billingAddress.label') : '',
      street: get(transaction, 'billingAddress.street') ? get(transaction, 'billingAddress.street') : '',
      city: get(transaction, 'billingAddress.city') ? get(transaction, 'billingAddress.city') : '',
      zipCode: get(transaction, 'billingAddress.zipCode') ? get(transaction, 'billingAddress.zipCode') : '',
      countryCode: get(transaction, 'billingAddress.countryCode') ? get(transaction, 'billingAddress.countryCode') : '',
      vat: get(transaction, 'billingAddress.vat') ? get(transaction, 'billingAddress.vat') : '',
      isValidVat: get(transaction, 'billingAddress.isValidVat', false),
    };
  }
}


const mapStateToProps = (state, ownProps) => {
  return {
    params: ownProps.match.params,
    transaction: getTransaction(state, ownProps.match.params.id),
  };
};


const mapDispatchToProps = {
  loadTransaction,
  updateTransactionAddress,
  confirmSalesOrder,
};


export default connect(mapStateToProps, mapDispatchToProps)(EditSalesOrder);
