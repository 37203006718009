const k = (key) => `drone_shooting.briefing.${key}`;


const residential = {
  1: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('accent_points'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('describe'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'Input',
        required: 'all',
        key: 'accentPoints',
        keyLabel: k('keys.accent_points'),
      },
    ],
  },
  2: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'shared.additional_info',
        component: 'Title',
      }, {
        type: 'component',
        label: 'shared.anything_to_add',
        component: 'Note',
      }, {
        type: 'question-component',
        label: 'shared.additional_comments',
        component: 'Input',
        key: 'otherInfo',
        keyLabel: 'shared.keys.other_info',
      }, {
        type: 'question-component',
        label: 'shared.upload_files',
        component: 'FileUploader',
        key: 'otherInfoAttachments',
        keyLabel: 'shared.keys.other_info_attachments'
      },
    ],
  },
};

export default { residential };
