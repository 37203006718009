import { Injectable } from '@tokamakjs/injection';

import { SettingsApi } from '../api';

@Injectable()
export class UserService {
  constructor(private readonly _settingsApi: SettingsApi) {}

  public async loadUser() {
    const res = await this._settingsApi.fetchUser();
    if (res.error != null) {
      return;
    } else {
      return res.data.user;
    }
  }

  public async updateUser(newUser: { firstName?: string; lastName?: string }) {
    const res = await this._settingsApi.updateUser(newUser);
    if (res.error != null) {
      return;
    } else {
      return res.data.user;
    }
  }
}
