import { css, keyframes } from 'emotion';
import sv, { fade } from '@drawbotics/style-vars';


const fadeInContent = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export default {
  briefingEstate: css`
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
  `,
  cover: css`
    position: absolute;
    height: 300px;
    width: 100%;
    top: 0;
    left: 0;
    background-position: 100% 10%;
    background-repeat: no-repeat;
    background-size: 30%;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.44, 0.11, 0.07, 1);
    transition-duration: 0;

    &:before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.25);
      z-index: 1;
    }
  `,
  coverWithTransition: css`
    transition-duration: ${sv.baseTransitionTime};
  `,
  confirmed: css`
    &::before {
      background: ${fade('#52bf7f', 90)};
    }
  `,
  review: css`
    &::before {
      background: ${fade('#3DB5D0', 90)};
    }
  `,
  coverContent: css`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: ${sv.basePadding};
    z-index: 2;
    margin: auto;
    align-items: center;
  `,
  back: css`
    position: absolute;
    top: ${sv.baseMargin};
    left: ${sv.baseMargin};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ${sv.baseTransitionTimeShort} ease-in-out;
    color: ${sv.white};

    &:hover {
      cursor: pointer;
      color: ${sv.grey100};
    }
  `,
  backLabel: css`
    margin-left: 5px;
  `,
  coverTitle: css`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: ${sv.white};
    font-size: 1.6rem;
    font-weight: 600;
    pointer-events: none;
    opacity: 0;
    animation: ${fadeInContent} ${sv.baseTransitionTime} ${sv.baseTransitionTime} forwards;
    max-width: 500px;
    text-align: center;
    margin-top: calc(${sv.baseMargin} * -2);

    @media ${sv.phone} {
      font-size: 1.2rem;
    }
  `,
  contentWrapper: css`
    height: 100%;
    margin-top: 300px;
  `,
  contentColumn: css`
    position: relative;
    top: -100px;
    z-index: 999;
    max-width: 1000px;
    width: 100%;
    margin: auto;
  `,
  content: css`
    animation: ${fadeInContent} ${sv.baseTransitionTimeShort} ${sv.baseTransitionTime} forwards;
    opacity: 0;
    transform: translateY(0);
    background: ${sv.white};
    padding: calc(${sv.basePadding} * 2);
    border-radius: ${sv.baseBorderRadius};

    @media ${sv.ipad} {
      padding: ${sv.basePadding};
    }

    @media ${sv.phone} {
      padding: ${sv.basePaddingSmall};
      padding-bottom: ${sv.basePadding};
    }
  `,
  subtitle: css`
    text-align: center;
    color: ${sv.textPrimaryDark};
  `,
  card: css`
    margin-top: ${sv.baseMargin};
  `,
  uploads: css`
    display: flex;
    justify-content: stretch;
    margin-top: calc(${sv.baseMargin} * 2);

    @media ${sv.phone} {
      flex-direction: column;
    }
  `,
  attachments: css`
    flex: 1;
    margin-left: ${sv.baseMarginSmall};
    min-width: 0;

    @media ${sv.phone} {
      margin-left: 0;
    }
  `,
  checklist: css`
    flex: 1;
    margin-right: ${sv.baseMarginSmall};
    min-width: 0;

    @media ${sv.phone} {
      margin-top: ${sv.baseMargin};
      margin-right: 0;
    }
  `,
  actions: css`
    margin-top: ${sv.baseMargin};
    display: flex;
    align-items: center;
    justify-content: center;

    & button:first-child {
      margin-right: ${sv.baseMargin};
    }
  `,
  confirmationDisclaimer: css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${sv.baseMargin};
  `,
  error: css`
    color: ${sv.brandRed};
    font-size: 0.9em;
    margin-bottom: ${sv.baseMarginSmall};
  `,
};
