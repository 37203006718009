import Rx from 'rxjs-legacy';
import { combineEpics } from 'redux-observable';

import * as AlertsActions from 'actions/alerts';
import { getTransaction } from 'pods/transactions/selectors';
import * as TransactionsActions from 'pods/transactions/actions/transactions';
import { translate as t } from 'utils/translation';


function buyCredits(action$, store, extra) {
  const { api } = extra;
  return action$
    .filter((action) => action.type === TransactionsActions.BUY_CREDITS)
    .flatMap((action) => {
      const { amount, bonus } = action.payload;
      return Rx.Observable.fromPromise(api.mutations.buyCredits(amount, bonus))
        .map((transaction) => TransactionsActions.createCreditsTransaction(transaction))
        .catch((err) => Rx.Observable.of(TransactionsActions.buyCreditsFail(err)));
    })
}


function redirectToCreditsTransaction(action$, store, extra) {
  const { history } = extra;
  return action$
    .filter((action) => action.type === TransactionsActions.CREATE_CREDITS_TRANSACTION)
    .map((action) => {
      const { transaction } = action.payload;
      return history.push(`/transactions/${transaction.id}`);
    })
    .ignoreElements();  // Stop flow to not having "Actions must be an object..." error
}


function loadTransaction(action$, store, extra) {
  const { api } = extra;
  return action$
    .filter((action) => action.type === TransactionsActions.LOAD_TRANSACTION)
    .flatMap((action) => {
      const { id } = action.payload;
      const transaction = getTransaction(store.getState(), id, 'raw');
      if ( ! transaction) {
        return Rx.Observable.fromPromise(api.queries.getTransaction(id))
          .map((transaction) => TransactionsActions.loadTransactionSuccess(transaction))
          .catch((err) => {
            return Rx.Observable.concat(
              Rx.Observable.of(TransactionsActions.loadTransactionFail(err)),
            );
          });
      }
      return Rx.Observable.of(TransactionsActions.loadTransactionSuccess(transaction));
    });
}


function updateTransactionAddress(action$, store, extra) {
  const { api } = extra;
  return action$
    .filter((action) => action.type === TransactionsActions.UPDATE_TRANSACTION_ADDRESS)
    .flatMap((action) => {
      const { transaction, addressId } = action.payload;
      return Rx.Observable.fromPromise(api.mutations.updateTransactionAddress(transaction.id, addressId))
        .map((transaction) => TransactionsActions.updateTransactionAddressSuccess(transaction))
        .catch((err) => Rx.Observable.concat(
          Rx.Observable.of(AlertsActions.showAlert(TransactionsActions.UPDATE_TRANSACTION_ADDRESS_FAIL, 'error', t('containers.alerts.transactions.payments.errors.update_address_fail'))),
          Rx.Observable.of(TransactionsActions.updateTransactionAddressFail(err))
        ));
    });
}


function redirectTransactionAlreadyPaid(action$, store, extra) {
  const { history } = extra;
  return action$
    .filter((action) => action.type === TransactionsActions.REDIRECT_TRANSACTION_ALREADY_PAID)
    .flatMap((action) => Rx.Observable.concat(
      Rx.Observable.of(AlertsActions.showAlert(TransactionsActions.REDIRECT_TRANSACTION_ALREADY_PAID, 'error', t('containers.alerts.transactions.payments.errors.already_paid'))),
      Rx.Observable.of(history.push('/')),
    ));
}


export default combineEpics(
  buyCredits,
  loadTransaction,
  updateTransactionAddress,
  redirectTransactionAlreadyPaid,
  redirectToCreditsTransaction,
);
