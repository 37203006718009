import cn from 'classnames';
import React from 'react';
import { get } from 'lodash';
import { Button } from 'react-ittsu/components';
import autobind from 'autobind-decorator';

import { createTranslate } from 'utils/translation';
import { coordinatesInStudio } from 'pods/studio/utils';
import Text from './Text';
import ActionIcons from './ActionIcons';
import ActionBar from './ActionBar';
import Attachments from './Attachments';
import api from 'pods/studio/utils/api';

import friendlyAlexia from 'images/friendly_alexia.png';

import styles from 'pods/studio/styles/components/annotation';


const tt = createTranslate('pods.studio.components.annotation');


const calcStyle = (annotation, isFullScreen, displayData, height) => {
  const width = 400;
  const offset = { x: 35, y: 20 };
  const { top, left, arrow } = coordinatesInStudio(
    annotation.shape,
    displayData,
    { width, height: height ?? 200 },
    offset,
    isFullScreen
  );
  return {
    style: {
      width,
      top,
      left,
    },
    arrow,
  };
};


const TabsBar = ({
  canBeDeleted,
  children,
  onClickDelete,
}) => (
  <div className={styles.tabsBar}>
    <div className={styles.tabs}>
      {children}
    </div>
    <div className={styles.tabsBarActions}>
      {canBeDeleted &&
        <i
          className={cn('dbi dbi-trash', styles.tabsBarAction)}
          onClick={onClickDelete} />}
    </div>
  </div>
);


const ConfirmationOverlay = ({
  onClickDelete,
  onClickCancel,
}) => (
  <div className={styles.confirmationOverlay}>
    <p>
      {tt('delete_this')}&nbsp;
      <strong>{tt('annotation')}</strong>?
    </p>
    <div>
      <button
        className={styles.deleteOk}
        onClick={onClickDelete}>
        {tt('delete')}
      </button>
      <button
        className={styles.deleteCancel}
        onClick={onClickCancel}>
        {tt('cancel')}
      </button>
    </div>
  </div>
);


class Annotation extends React.Component {

  state = {
    confirmationShown: false,
    upload: {
      progress: 0,
      filename: '',
    },
    uploading: false,
    value: '',
    problemResponse: '',
    disableProblemButtons: false,
  };

  constructor(props) {
    super(props);
    // eslint-disable-next-line
    this.state.value = this.state.value ? this.state.value : props.annotation.comment;
    this.annotationRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { annotation } = this.props;
    const { annotation: prevAnnotation } = prevProps;

    if (annotation.id !== prevAnnotation.id) {
      this.setState({ value: annotation.comment });
    }
  }

  componentDidMount() {
    window.dispatchEvent(new Event('resize'));
  }

  render() {
    const {
      user,
      displayData,
      annotation,
      isFullScreen,
      onClickClose,
      onClickDelete,
      onCreateAttachment,
      onDeleteAttachment,
    } = this.props;
    const { value, problemResponse } = this.state;
    const annotationHeight = this.annotationRef?.current?.clientHeight;
    const { style, arrow } = calcStyle(annotation, isFullScreen, displayData, annotationHeight);
    const userIsAuthor = annotation.createdBy.id === user.id;
    const userCanEdit = get(user, 'permissions.annotations.edit', false) && userIsAuthor;
    const enableSaveButton = !!value && !this.state.uploading;
    const name = annotation.userType === 'BackUser' ? 'Drawbotics' : annotation.createdBy.firstName;
    return (
      <div className={styles[arrow]} style={style} ref={this.annotationRef}>
        {this.state.confirmationShown &&
          <ConfirmationOverlay
            onClickDelete={() => onClickDelete(annotation)}
            onClickCancel={this._hideConfirmation} />
        }
        <div className={styles.header}>
          <TabsBar
            onClickDelete={this._showConfirmation}
            canBeDeleted={this._canBeDeleted()} >
            <div className={styles.isActive}>
              {tt('annotation')}
            </div>
          </TabsBar>
        </div>
        <div className={styles.body}>
          <Text
            text={value}
            isEditable={userCanEdit && annotation.preview.draft.isEditable()}
            onChange={(e) => this._handleChangeComment(e.target.value)} />
          {userCanEdit && annotation.preview.draft.isEditable() &&
            <ActionIcons
              annotation={annotation}
              onUploadProgress={(file, e) => {
                const progress = Math.floor(e.loaded / e.total * 100);
                this.setState({ upload: { progress, filename: file.name }, uploading: true });
              }}
              onUploadFinish={async (file, signingResult) => {
                const { attachment_id: attachmentId } = await api.Attachment.create({
                  signedId: signingResult.signed_id,
                  filename: signingResult.filename,
                  fieldType: 'annotation',
                  annotation: annotation.id,
                });
                onCreateAttachment({
                  id: attachmentId,
                  filename: signingResult.filename,
                  fieldType: 'annotation',
                  annotation: annotation.id,
                });
                this._resetProgress();
                this._saveAnnotation(false);
              }} />
          }
          <Attachments
            attachments={annotation.attachments}
            uploadStatus={this.state.upload}
            onClickDelete={onDeleteAttachment} />
          <ActionBar
            enableSaveButton={enableSaveButton}
            enableCloseButton={ ! this.state.uploading}
            onClickClose={() => {
              onClickClose(annotation.id);
              window.dispatchEvent(new Event('resize'));
            }}
            onClickSave={() => {
              this._saveAnnotation(true);
              window.dispatchEvent(new Event('resize'));
            }} />
        </div>
        { do {
          if (annotation.hasProblems()) {
            <div className={styles.problemSolver}>
              <div className={styles.problemHeader}>
                <div className={styles.avatar}>
                  <img src={friendlyAlexia} />
                </div>
                <div className={styles.description}>
                  {tt('greeting') + ' ' + name + ',\n\n'}
                  {annotation.problemDescriptions[annotation.problemDescriptions.length-1].input}
                </div>
              </div>
              <div className={styles.problemAnswer}>
                <textarea
                  rows="3"
                  placeholder={tt('placeholder')}
                  className={styles.problemAnswerInput}
                  value={problemResponse}
                  onChange={e => this.setState({ problemResponse: e.target.value })}/>
                <ActionIcons
                  annotation={annotation}
                  onUploadProgress={(file, e) => {
                    const progress = Math.floor(e.loaded / e.total * 100);
                    this.setState({ upload: { progress, filename: file.name }, uploading: true });
                  }}
                  onUploadFinish={async (file, signingResult) => {
                    const { attachment_id: attachmentId } = await api.Attachment.create({
                      signedId: signingResult.signed_id,
                      filename: signingResult.filename,
                      fieldType: 'annotation',
                      annotation: annotation.id,
                    });
                    onCreateAttachment({
                      id: attachmentId,
                      filename: signingResult.filename,
                      fieldType: 'annotation',
                      annotation: annotation.id,
                    });
                    this._resetProgress();
                    this._saveAnnotation(false);
                  }} />
                <div className={styles.problemActions}>
                  <Button
                    className={styles.problemActionsButton}
                    onClick={this._onClickDisregard}
                    disabled={this.state.disableProblemButtons}
                    category="danger"
                    size="small">
                    {tt('disregard_annotation')}
                  </Button>
                  <Button
                    className={styles.problemActionsButton}
                    onClick={this._onClickSolveProblem}
                    disabled={this.state.disableProblemButtons
                      || this.state.problemResponse.length === 0}
                    category="info"
                    size="small">
                    {tt('save_and_solve_problem')}
                  </Button>
                </div>
              </div>
            </div>
          }
        }}
      </div>
    );
  }

  _canBeDeleted() {
    const { user, annotation } = this.props;

    const userIsAuthor = annotation.createdBy.id === user.id;
    const userIsOwner = get(user, 'permissions.annotations.destroy_others', false);
    const userCanDelete = userIsAuthor || userIsOwner;
    const draftIsEditable = annotation.preview.draft.isEditable();

    return draftIsEditable && userCanDelete;
  }

  @autobind
  _showConfirmation() {
    this.setState({
      confirmationShown: true,
    });
  }

  @autobind
  _hideConfirmation() {
    this.setState({
      confirmationShown: false,
    });
  }

  @autobind
  _onClickDisregard() {
    const { annotation, updateAnnotation, saveAnnotation } = this.props;
    this.setState({ disableProblemButtons: true });
    updateAnnotation(annotation.id, { actionable: false });
    saveAnnotation(annotation);
    window.dispatchEvent(new Event('resize'));
  }

  @autobind
  _onClickSolveProblem() {
    const { annotation, solveProblem } = this.props;
    const { problemResponse } = this.state;
    this.setState({ disableProblemButtons: true });
    const problemIds = annotation.activeProblems().map(p => p.id);
    solveProblem(annotation.id, problemResponse, problemIds);
    window.dispatchEvent(new Event('resize'));
  }

  @autobind
  _resetProgress() {
    this.setState({ upload: { progress: 0, filename: '' }, uploading: false });
  }

  @autobind
  _saveAnnotation(closeAnnotation) {
    const { annotation, onChangeAnnotation, onClickSave } = this.props;
    const { value } = this.state;

    onChangeAnnotation(annotation.id, { comment: value });
    onClickSave(annotation, closeAnnotation);
  }

  @autobind
  _handleChangeComment(value) {
    this.setState({ value });
  }
}


export default Annotation;
