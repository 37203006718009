import { TargetDomain, clearJwt, clearRefreshToken, getLatestJwt, setJwt } from '@drawbotics/auth';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import join from 'url-join';

import { JwtTokenData } from '~/types';
import { writeSession } from '~/utils';
import { fetchSessionData } from '~/utils/session';

export function getRootDomain(): string {
  if (location.hostname.includes('localhost')) {
    const [, domain] = location.hostname.match(/(localhost:\d+)/) ?? [];
    return domain as string;
  }
  if (location.hostname.includes('netlify')) {
    return location.hostname;
  } else {
    return `.${location.hostname
      .split('.')
      .slice(-2)
      .join('.')}`;
  }
}

export async function logout() {
  await fetch(join(process.env.AUTH_HOST, '/v1/logout'), {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getLatestJwt(process.env.AUTH_HOST, TargetDomain.DRAWBOTICS)}`,
      'Target-Domain': TargetDomain.DRAWBOTICS,
    },
  });
}

export function decodeToken(jwtToken?: string): JwtTokenData | undefined {
  try {
    return jwtDecode<JwtTokenData>(jwtToken ?? '');
  } catch {
    return;
  }
}

export function removeSession() {
  logout();
  Cookies.remove('session');
  clearJwt();
  clearRefreshToken();
}

export async function setupSession(): Promise<void> {
  const token = await getLatestJwt(process.env.AUTH_HOST, TargetDomain.DRAWBOTICS);
  const tokenData = decodeToken(token);

  if (token == null || tokenData == null) {
    return;
  }

  if (tokenData.target_domain !== TargetDomain.DRAWBOTICS) {
    Cookies.remove('session');
    clearJwt();
    clearRefreshToken();

    location.href = '/auth/login';

    return;
  }

  const session = await fetchSessionData(token);

  if (session != null) {
    writeSession(session);
    setJwt(token);
  }

  const expires = dayjs.unix(tokenData.exp);
  const difference = expires.diff(dayjs());

  setTimeout(setupSession, difference);
}
