import React from 'react';
import autobind from 'autobind-decorator';
import { Grid, Cell } from 'react-ittsu/layout';
import gql from 'fraql';

import BaseServiceModal from '../BaseServiceModal';
import Paragraph from '../Paragraph';
import SectionTitle from '../SectionTitle';
import { ProductCard, Stripe, StripeGroup } from '../../forms';
import { priceItem } from '../../../utils/pricing';
import { specsToItems, itemsToSpecs } from '~/pods/order/utils/services/specs-to-items';
import applyChanges from '~/pods/order/utils/services/specs-to-items/apply-changes';
import { getItemsByService, getFeaturesForProduct, openIntercom, merge } from '~/pods/order/utils';
import { createTranslate, translate as t } from '~/utils/translation';
import { EstateEnums } from '~/pods/order/utils/estate-enums';

import serviceImages from '~/pods/order/images/services';


const tt = createTranslate('pods.order.services.revo.order');
const tm = createTranslate('pods.order.components.service_modal');


class Revo extends React.Component {

  static fragments = {
    Item: gql`
      fragment _ on Item {
        id
        details {
          ... on RevoDetails {
            droneShooting
            project360Rotation
            floorplansIn3d
            furnishedFloors
            shoebox360Rotation
            googleMapsIntegration
            environmentIn3d
          }
        }
        product {
          id @client(type: Product)
          custom
          name
          features {
            locale
            content
          }
        }
      }
    `,
  }

  state = {
    custom: null,
    specs: {
      droneShooting: false,
      standard: false,
      project360Rotation: false,
      floorplansIn3d: false,
      furnishedFloors: false,
      shoebox360Rotation: false,
      googleMapsIntegration: false,
      environmentIn3d: false,
    },
    price: undefined,
  };

  componentDidUpdate(prevProps) {
    const { service, estate } = this.props;
    const { service: prevService } = prevProps;
    if (service?.id === 'revo' && ! prevService) {
      const specs = itemsToSpecs({ estate, service });
      const items = getItemsByService(estate).revo || [];
      const customItem = items.find((item) => item.product.custom);
      const price = items != null ? items.reduce((memo, i) => memo + priceItem(i, this.props.sessionUser.currency.code, true), 0) : 0;
      this.setState({ specs: merge(this.state.specs, specs), price, custom: customItem ? true : null });
    }
  }

  render() {
    const { onClickClose, isVisible, service, estate, sessionUser } = this.props;
    const { specs: {
      standard,
      droneShooting,
      project360Rotation,
      floorplansIn3d,
      furnishedFloors,
      shoebox360Rotation,
      googleMapsIntegration,
      environmentIn3d,
    }, price, custom } = this.state;
    const { revo } = serviceImages;
    const items = getItemsByService(estate).revo || [];
    const customItem = items.find((item) => item.product.custom);
    const customFeatures = custom !== null ? getFeaturesForProduct(customItem?.product) : null;
    const isResidential = estate.projectType === EstateEnums.ProjectType.RESIDENTIAL;
    return (
      <BaseServiceModal
        isVisible={isVisible && service?.id === 'revo'}
        title={service?.id ? t(`services.${service.id}`) : ''}
        images={Object.values(revo)}
        price={sessionUser.addCurrency(price)}
        disclaimer={{
          label: tt('disclaimer.text'),
          description: tt('disclaimer.info'),
        }}
        priceDisclaimer={{
          label: tt('price_disclaimer.text'),
          description: tt('price_disclaimer.info'),
        }}
        onClickConfirm={this._handleClickConfirm}
        confirmAsUpdate={items?.length > 0}
        onClickClose={onClickClose}
        addDisabled={price === 0}>
        <div className="childcontent">
          <Paragraph>
            {tt('description')}
          </Paragraph>
          <SectionTitle>
            {tt('select_type')}
          </SectionTitle>
          <Grid withVGutters withHGutters>
            <Cell size="1of2">
              <ProductCard
                name="standard"
                label={tm('standard')}
                fullHeight
                formComponent="radio"
                features={[
                  {
                    text: tt('static_image'),
                  },
                  {
                    text: tt('empty_floors'),
                  },
                  {
                    text: tt('lead_gen'),
                  },
                  isResidential ? {
                    text: tt('wood_board'),
                  } : null,
                ]}
                nonFeatures={[
                  {
                    text: tt('no_environment'),
                  },
                ]}
                value={standard}
                onChange={() => this._handleClickCard('standard')} />
            </Cell>
            <Cell size="1of2">
              <ProductCard
                name="custom"
                label={custom !== null ? `${customItem?.product.name}` : tm('custom')}
                note={custom !== null && customItem ? `${sessionUser.addCurrency(customItem?.price)}` : tm('contact_us')}
                formComponent="radio"
                fullHeight
                features={customFeatures}
                onChange={this._handleClickCustom}
                value={!! custom} />
            </Cell>
          </Grid>
          <SectionTitle>
            {tt('additional_options')}
          </SectionTitle>
          <StripeGroup>
            <Stripe
              name="project360Rotation"
              title={tt('project_360_rotation')}
              component="switch"
              features={[{
                text: tt('project_360_rotation_description'),
              }]}
              disabled={! standard}
              value={project360Rotation}
              onChange={() => this.setState({ specs: { ...this.state.specs, project360Rotation: ! project360Rotation } }, this._syncState)} />
            <Stripe
              name="floorplansIn3d"
              title={tt('floorplans_in_3d')}
              component="switch"
              features={[{
                text: tt('floorplans_in_3d_description'),
              }]}
              disabled={! standard}
              value={floorplansIn3d}
              onChange={() => this.setState({ specs: { ...this.state.specs, floorplansIn3d: ! floorplansIn3d } }, this._syncState)} />
            <Stripe
              name="furnishedFloors"
              title={tt('furnished_floors')}
              component="switch"
              disabled={! standard}
              value={furnishedFloors}
              onChange={() => this.setState({ specs: { ...this.state.specs, furnishedFloors: ! furnishedFloors } }, this._syncState)} />
            <Stripe
              name="shoebox360Rotation"
              title={tt('shoebox_360_rotation')}
              component="switch"
              features={[{
                text: tt('shoebox_360_rotation_description'),
              }]}
              disabled={! standard}
              value={shoebox360Rotation}
              onChange={() => this.setState({ specs: { ...this.state.specs, shoebox360Rotation: ! shoebox360Rotation } }, this._syncState)} />
            <Stripe
              name="googleMapsIntegration"
              title={tt('google_maps_integration')}
              component="switch"
              features={[{
                text: tt('google_maps_integration_description'),
              }]}
              disabled={! standard}
              value={googleMapsIntegration}
              onChange={() => this.setState({ specs: { ...this.state.specs, googleMapsIntegration: ! googleMapsIntegration } }, this._syncState)} />
            <Stripe
              name="environmentIn3d"
              title={tt('environment_in_3d')}
              component="switch"
              features={[{
                text: tt('environment_in_3d_description'),
              }]}
              disabled={! standard}
              value={environmentIn3d}
              onChange={() => this.setState({ specs: { ...this.state.specs, environmentIn3d: ! environmentIn3d } }, this._syncState)} />
          </StripeGroup>

          <SectionTitle>
            {tt('add_drone_shooting')}
          </SectionTitle>
          <StripeGroup>
            <Stripe
              name="droneShooting"
              title={tt('drone_shooting')}
              subtitle={tm('separate_service')}
              component="switch"
              features={[{
                text: tt('drone_shooting_description'),
              }]}
              disabled={! standard}
              value={droneShooting}
              onChange={() => this.setState({ specs: { ...this.state.specs, droneShooting: ! droneShooting } }, this._syncState)} />
          </StripeGroup>
        </div>
      </BaseServiceModal>
    );
  }

  @autobind
  _syncState() {
    const { estate, service } = this.props;
    const { specs } = this.state;
    const changes = specsToItems({ specs, estate, service });
    const updatedEstate = applyChanges(changes, estate);
    const items = getItemsByService(updatedEstate).revo;
    const price = items != null
      ? items
          .map((item) => item.estate != null ? item : { ...item, estate: updatedEstate })
          .reduce((memo, i) => memo + priceItem(i, this.props.sessionUser.currency.code, true), 0)
      : 0;
    this.setState({ price });
    return { changes, updatedEstate, price };
  }

  @autobind
  _handleClickCard(quality) {
    const { specs, custom } = this.state;
    const standard = quality === 'standard' && ! specs.standard;
    this.setState({
      custom: custom === null ? null : false,
      specs: {
        standard,
        droneShooting: ! standard ? false : specs.droneShooting,
        project360Rotation: ! standard ? false : specs.project360Rotation,
        floorplansIn3d: ! standard ? false : specs.floorplansIn3d,
        furnishedFloors: ! standard ? false : specs.furnishedFloors,
        shoebox360Rotation: ! standard ? false : specs.shoebox360Rotation,
        googleMapsIntegration: ! standard ? false : specs.googleMapsIntegration,
        environmentIn3d: ! standard ? false : specs.environmentIn3d,
      },
    }, this._syncState);
  }

  @autobind
  _handleClickCustom() {
    const { custom } = this.state;
    if (custom === null) {
      openIntercom();
      this._resetSpecs(this._syncState);
    }
    else {
      const newValue = ! custom;
      this._resetSpecs(() => this.setState({ custom: newValue }, this._syncState));
    }
  }

  @autobind
  _resetSpecs(onReset) {
    this.setState({ specs: {
      standard: false,
      droneShooting: false,
      project360Rotation: false,
      floorplansIn3d: false,
      furnishedFloors: false,
      shoebox360Rotation: false,
      googleMapsIntegration: false,
      environmentIn3d: false,
    } }, onReset);
  }

  @autobind
  _handleClickConfirm() {
    const { onModifyService, estate } = this.props;
    const { custom } = this.state;
    const extraRemove = custom === false ? [getItemsByService(estate).revo.find((item) => item.product.custom)] : [];
    const { changes } = this._syncState();
    onModifyService({ ...changes, remove: [ ...changes.remove, ...extraRemove ] });
  }
}


export default Revo;
