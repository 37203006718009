import * as ModalsActions from 'actions/modals';


const initialState = {
};


export default function modals(state=initialState, action) {
  switch (action.type) {
    case ModalsActions.SHOW_MODAL: {
      const { id, extra } = action.payload;
      return {...state,
        [id]: {
          shown: true,
          extra,
        },
      };
    }
    case ModalsActions.HIDE_MODAL: {
      const { id } = action.payload;
      return {...state,
        [id]: {
          ...state[id],
          shown: false,
        },
      };
    }
    default:
      return state;
  }
}
