import React from 'react';
import get from 'lodash/get';

import Images from './components/Images';
import TextField from './components/TextField';
import Attachments from './components/Attachments';
import Map from './components/Map';
import BooleanField from './components/BooleanField';
import Views from './components/Views';
import Emails from './components/Emails';


const imagesTypes = [ 'MultiSelect', 'SingleSelect', 'LikeDislike' ];


function getComponents(component, details, estate, index) {
  if (component.type === 'question-component') {
    if (imagesTypes.includes(component.component)) {
      const key = component.key.replace('%{n}', index);
      const values = component.values.filter((value) =>
        typeof get(details, key) === 'boolean' || ! Array.isArray(get(details, key)) ? get(details, key) === value.value : get(details, key)?.includes(value.value));
      const dislikedValues = component.values.filter((value) => details[component.dislikedKey]?.includes(value.value));
      const extraMetaComponents = values.reduce((rest, value) => value.meta ? [ ...rest, ...getComponents(value.meta, details, estate) ] : rest, []);
      if (values.some((v) => typeof v.value === 'boolean' && ! v.image)) {
        // eslint-disable-next-line
        return [ <BooleanField values={values} title={component.keyLabel} />, ...extraMetaComponents ];
      }
      if (values.length === 0) return [];
      // eslint-disable-next-line
      return [ <Images values={values} dislikedValues={dislikedValues} title={component.keyLabel} />, ...extraMetaComponents ];
    }
    else {
      const key = component.key.replace('%{n}', index);
      if (component.component === 'Input') {
        const value = get(details, key);
        // eslint-disable-next-line
        return value ? [ <TextField text={value} title={component.keyLabel} /> ] : [];
      }
      else if (component.component === 'EmailSelect') {
        const value = get(details, key);
        // eslint-disable-next-line
        return value ? [ <Emails emails={value} title={component.keyLabel} /> ] : [];
      }
      else if (component.component === 'FileUploader') {
        const values = get(details, key);
        // eslint-disable-next-line
        return values && values.length > 0 ? [ <Attachments attachments={values} title={component.keyLabel} /> ] : [];
      }
      else if (component.component === 'MapSearch') {
        const values = get(details, key);
        // eslint-disable-next-line
        return values && values.length > 0 ? [ <Map values={values} title={component.keyLabel} estate={estate} /> ] : [];
      }
      else {
        return [];
      }
    }
  }
  else if (component.type === 'meta') {
    return component.components.reduce((rest, description) => [ ...rest, ...getComponents(description, details, estate) ], []);
  }
  else if (component.type === 'component-group') {
    const key = component.key.replace('%{n}', index)
    if (component.component === 'ViewOptions') {
      const values = get(details, key);
      const componentsGroup = values.map((_, i) => component.components.reduce((rest, description) => [ ...rest, ...getComponents(description, details, estate, i) ], []));
      // eslint-disable-next-line
      return values ? [ <Views components={componentsGroup} title={component.keyLabel} /> ] : [];
    }
    else {
      return [];
    }
  }
  else {
    return [];
  }
}


function getTitleFromDescription(sectionDescription) {
  return sectionDescription.components.find((component) => component.component === 'Title')?.label;
}


export function getSectionsInfo(details, sections, estate) {
  return Object.values(sections).map((section) => {
    const title = getTitleFromDescription(section);
    const components = section.components.reduce((rest, component) => [ ...rest, ...getComponents(component, details, estate) ], []);
    if (components.length === 0) return null;
    return {
      title,
      components,
    };
  }).filter((section) => !! section);
}
