import {
  Button,
  ButtonLink,
  Category,
  EmptyState,
  EmptyStateVariation,
  Flex,
  FlexAlign,
  FlexItem,
  FlexJustify,
  Icon,
  Layout,
  Margin,
  Padding,
  Panel,
  PanelBody,
  PanelHeader,
  Position,
  Size,
  Spinner,
  Table,
  Text,
  Tier,
  Title,
  useAlert,
} from '@drawbotics/react-drylus';
import copyToClipboard from 'copy-to-clipboard';
import dayjs from 'dayjs';
import { groupBy } from 'lodash';
import React, { Fragment, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import { createTranslate } from '~/utils/translation';

import { EstateHeader } from '../../components';
import { PropertyType } from '../../types';
import { ErrorType, camelize, downloadAll, useFetchItem } from '../../utils';
import { ItemSidebar } from './components';

const tt = createTranslate('pods.dashboard.routes.item');

interface ItemProps extends RouteComponentProps<{ itemId: string }> {
  estateId: string;
  triggerNotFound: VoidFunction;
  triggerCritical: VoidFunction;
}

export const Item = ({ estateId, match, triggerCritical, triggerNotFound }: ItemProps) => {
  const { showAlert } = useAlert();
  const { isLoading, item, error } = useFetchItem(match.params.itemId);
  const [zipDownloadRoundId, setZipDownloadRoundId] = useState<string>();

  if (error === ErrorType.NOT_FOUND || error === ErrorType.NOT_AUTHORIZED) {
    triggerNotFound();
    return null;
  }
  if (error === ErrorType.CRITICAL) {
    triggerCritical();
    return null;
  }

  const finalFiles = item?.attachments.filter((a) => a.final) ?? [];
  const originalFiles = item?.attachments.filter((a) => !a.final && !a.preview) ?? [];
  const previewFiles = item?.attachments.filter((a) => a.preview) ?? [];

  const correctionRounds = Object.entries(groupBy(previewFiles, 'draftId'))
    .map(([id, attachments]) => ({ id, attachments }))
    .sort((a, b) =>
      a.attachments[0].correctionRoundDate == null
        ? 1
        : dayjs(b.attachments[0].correctionRoundDate).diff(
            dayjs(a.attachments[0].correctionRoundDate),
          ),
    );

  let groupedAttachments = [];

  if (finalFiles.length > 0) {
    groupedAttachments.push({ id: 'final', attachments: finalFiles });
  }
  groupedAttachments.push(...correctionRounds);
  if (originalFiles.length > 0) {
    groupedAttachments.push({ id: 'original', attachments: originalFiles });
  }
  const hasOriginalsRound = originalFiles.length > 0;

  const itemUrl = item?.url;

  const correctionRoundsData =
    groupedAttachments.map((c, i, correctionRounds) => {
      const index = hasOriginalsRound
        ? correctionRounds.length - i
        : correctionRounds.length - i + 1;
      const label =
        c.id === 'final'
          ? tt('final_files')
          : c.id === 'original'
          ? tt('original_files')
          : tt('correction_round', { count: index - 1 })?.join(' ');
      return {
        _id: c.id,
        label,
        date:
          c.attachments[0].correctionRoundDate != null ? (
            <Text dateOptions={{ format: 'D MMM YYYY, H:mm' }}>
              {new Date(c.attachments[0].correctionRoundDate)}
            </Text>
          ) : null,
        actions: (
          <Flex justify={FlexJustify.END} wrap>
            {itemUrl != null && itemUrl !== '' ? (
              <FlexItem>
                <Margin size={{ right: Size.EXTRA_SMALL }}>
                  <Button
                    onClick={() => {
                      copyToClipboard(itemUrl);
                      showAlert({
                        text: tt('link_copied'),
                        category: Category.INFO,
                      });
                    }}
                    size={Size.SMALL}
                    tier={Tier.SECONDARY}
                    leading={<Icon name="link" />}>
                    {tt('copy_task_link')}
                  </Button>
                </Margin>
              </FlexItem>
            ) : null}
            <FlexItem>
              <Button
                onClick={async () => {
                  setZipDownloadRoundId(c.id);
                  await downloadAll(c.attachments, label);
                  setZipDownloadRoundId(undefined);
                }}
                leading={
                  zipDownloadRoundId === c.id ? (
                    <Spinner size={Size.SMALL} />
                  ) : (
                    <Icon name="download" />
                  )
                }
                size={Size.SMALL}
                tier={Tier.SECONDARY}>
                {tt('download_documents')}
              </Button>
            </FlexItem>
          </Flex>
        ),
        data: c.attachments.map((a) => ({
          _id: a.id,
          filename: a.filename,
          download: (
            <a href={a.url} download target="_blank" rel="noreferrer">
              <ButtonLink
                size={Size.SMALL}
                tier={Tier.SECONDARY}
                leading={<Icon name="download" />}
              />
            </a>
          ),
        })),
      };
    }) ?? [];

  return (
    <Fragment>
      <EstateHeader estateId={estateId} item={item} />
      {item == null && !isLoading ? (
        <Padding>
          <EmptyState
            title={tt('something_went_wrong')}
            description={tt('could_not_load')}
            variation={EmptyStateVariation.FAILED}
          />
        </Padding>
      ) : (
        <Layout position={Position.RIGHT} bar={<ItemSidebar isLoading={isLoading} item={item} />}>
          <Padding size={{ vertical: Size.EXTRA_LARGE, horizontal: Size.LARGE }}>
            <Panel
              header={
                <PanelHeader>
                  <Flex justify={FlexJustify.SPACE_BETWEEN} align={FlexAlign.CENTER}>
                    <FlexItem flex>
                      <Title noMargin size={4}>
                        {tt('downloads')}
                      </Title>
                    </FlexItem>
                    <FlexItem>
                      <Link
                        to={
                          item?.estate.propertyType === PropertyType.EXISTING_PROPERTY
                            ? `/order/briefing/${estateId}`
                            : `/order/briefing/${estateId}/${camelize(item?.service.slug ?? '')}/${
                                item?.id
                              }`
                        }>
                        <ButtonLink tier={Tier.SECONDARY}>{tt('see_briefing')}</ButtonLink>
                      </Link>
                    </FlexItem>
                  </Flex>
                </PanelHeader>
              }
              body={
                <PanelBody noPadding>
                  <Table
                    isLoading={isLoading}
                    emptyContent={
                      correctionRoundsData.length === 0 ? (
                        <EmptyState title={tt('no_assets')} description={tt('download_soon')} />
                      ) : null
                    }
                    data={correctionRoundsData}
                    header={[
                      {
                        label: tt('round'),
                        value: 'label',
                      },
                      { label: tt('date'), value: 'date' },
                      { label: tt('actions'), value: 'actions' },
                    ]}
                  />
                </PanelBody>
              }
            />
          </Padding>
        </Layout>
      )}
    </Fragment>
  );
};
