import { combineEpics } from 'redux-observable';


import * as AlertsActions from 'actions/alerts';
import { translate as t, createTranslate } from 'utils/translation';


const tt = createTranslate('containers.alerts.project');


function followItems(action$, store, extra) {
  return action$
    .filter((action) => action.type === '@@MUTATION/FOLLOW_ITEMS_SUCCESS')
    .map((action) => {
      const { args } = action.payload;
      const { variables, extra } = args;
      const { role } = variables;
      const { itemRefs, follower } = extra;
      const text = itemRefs ?
        tt('added_to_items', {
          name: follower.name,
          items: itemRefs.reduce((memo, reference, i) => memo.concat(`${i === 0 ? '' : ','} ${reference}`),''),
          role: t(`roles.${role}`),
        }) : tt('changed_role', { name: follower.name, role: t(`roles.${role}`) });
      return AlertsActions.showAlert('@@MUTATION/FOLLOW_ITEMS_SUCCESS', 'success', text);
    })
    .catch((err) => {
      console.error(err);
      return AlertsActions.showAlert('@@MUTATION/FOLLOW_ITEMS_FAIL', 'error', tt('could_not_add'));
    });
}


function unfollowItems(action$, store, extra) {
  return action$
    .filter((action) => action.type === '@@MUTATION/UNFOLLOW_ITEMS_SUCCESS')
    .map((action) => {
      const { args } = action.payload;
      const { extra } = args;
      const { itemRefs, follower } = extra;
      const from = itemRefs ? itemRefs.reduce((memo, reference, i) => memo.concat(`${i === 0 ? '' : ','} ${reference}`),'') : tt('all_items');
      return AlertsActions.showAlert('@@MUTATION/UNFOLLOW_ITEMS_SUCCESS', 'success', tt('removed_from_items', { name: follower.name, from: from }));
    })
    .catch((err) => {
      console.error(err);
      return AlertsActions.showAlert('@@MUTATION/UNFOLLOW_ITEMS_FAIL', 'error', tt('could_not_remove'));
    });
}


function archiveProject(action$, store, extra) {
  return action$
    .filter((action) => action.type === '@@MUTATION/ARCHIVE_ESTATE_SUCCESS')
    .map((action) => {
      const { args } = action.payload;
      return AlertsActions.showAlert('@@MUTATION/ARCHIVE_ESTATE_SUCCESS', 'success', tt('estate_archived', { name: args.extra.name }));
    })
    .catch((err) => {
      console.error(err);
      return AlertsActions.showAlert('@@MUTATION/ARCHIVE_ESTATE_FAIL', 'error', tt('estate_not_archived'));
    });
}



function setCoverPicture(action$, store, extra) {
  return action$
    .filter((action) => action.type === '@@MUTATION/SET_COVER_PICTURE_SUCCESS')
    .map((action) => {
      return AlertsActions.showAlert('@@MUTATION/SET_COVER_PICTURE_SUCCESS', 'success', tt('cover_picture_set'));
    })
    .catch((err) => {
      console.error(err);
      return AlertsActions.showAlert('@@MUTATION/SET_COVER_PICTURE_FAIL', 'error', tt('cover_picture_not_set'));
    })
}


function sendConfirmationEmail(action$, store, extra) {
  return action$
    .filter((action) => action.type === '@@MUTATION/SEND_CONFIRMATION_EMAIL_SUCCESS')
    .map((action) => {
      const { args } = action.payload;
      const { extra } = args;
      const { fullName } = extra;
      return AlertsActions.showAlert('@@MUTATION/SEND_CONFIRMATION_EMAIL_SUCCESS', 'success', tt('confirmation_email_sent', { name: fullName }));
    })
    .catch((err) => {
      console.error(err);
      return AlertsActions.showAlert('@@MUTATION/SEND_CONFIRMATION_EMAIL_FAIL', 'error', tt('confirmation_email_not_sent'));
    });
}


export default combineEpics(
  followItems,
  unfollowItems,
  archiveProject,
  setCoverPicture,
  sendConfirmationEmail,
);
