import gql from 'fraql';
import { useQuery } from 'urql';

import {
  CurrencyType,
  Estate,
  EstateHolderReason,
  EstateProblem,
  PaymentDocumentStatus,
} from '../../types';
import { ErrorType, buildError } from '../../utils';

interface EstateQueryData {
  estate: Estate;
}

interface EstateQueryVariables {
  estateId: string;
}

const EstateQuery = gql`
  query EstateQuery($estateId: ID!) {
    estate(estateId: $estateId) {
      id
      briefingConfirmedAt
      problems {
        id
        description
        reason
        attachments {
          id
          filename
          url
        }
      }
      holder {
        reasons
      }
      briefingConfirmedAt
      paymentProcess {
        invoices {
          id
          status
          amount
          currency
        }
      }
    }
  }
`;

export function useFetchEstateProblems(
  estateId: string,
): {
  isLoading: boolean;
  data: {
    owedAmount?: { value: number; currency?: CurrencyType };
    problems: Array<EstateProblem>;
    holders: Array<EstateHolderReason>;
    missingBriefing: boolean;
  };
  error?: ErrorType;
} {
  const [res] = useQuery<EstateQueryData, EstateQueryVariables>({
    query: EstateQuery,
    variables: { estateId },
    requestPolicy: 'cache-and-network',
  });

  const estate = res.data?.estate;
  const owedAmount = estate?.paymentProcess?.invoices.reduce(
    (memo, invoice) =>
      invoice.status === PaymentDocumentStatus.LATE ? memo + invoice.amount : memo,
    0,
  );

  return {
    isLoading: res.fetching,
    data: {
      missingBriefing: estate?.briefingConfirmedAt == null,
      owedAmount: owedAmount
        ? { value: owedAmount, currency: estate?.paymentProcess?.invoices[0].currency ?? 'EUR' }
        : undefined,
      problems: estate?.problems ?? [],
      // Filter any unnecessary reasons (related to payments or briefing)
      holders:
        estate?.holder?.reasons.filter((reason) =>
          Object.values(EstateHolderReason).includes(reason),
        ) ?? [],
    },
    error: buildError(res.error),
  };
}
