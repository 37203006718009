import { createEntities, deleteEntities, updateEntityId, updateEntities } from 'entman';
import v4 from 'uuid/v4';

import schemas from 'schemas';


export const ADD_FOLLOWER = 'ADD_FOLLOWER';

export function addFollower(follower, item, project) {
  return createEntities(schemas.Follower, 'payload.follower', {
    type: ADD_FOLLOWER,
    payload: {
      follower: {
        ...follower,
        id: v4(),
      },
      item,
      project,
    },
  });
}


export const ADD_FOLLOWER_SUCCESS = 'ADD_FOLLOWER_SUCCESS';

export function addFollowerSuccess(oldId, newId) {
  return updateEntityId(schemas.Follower, oldId, newId, {
    type: ADD_FOLLOWER_SUCCESS,
  });
}


export const ADD_FOLLOWER_FAIL = 'ADD_FOLLOWER_FAIL';

export function addFollowerFail(error, follower) {
  return deleteEntities(schemas.Follower, follower.id, {
    type: ADD_FOLLOWER_FAIL,
    payload: { follower },
    meta: { error },
  });
}


export const REMOVE_FOLLOWER = 'REMOVE_FOLLOWER';

export function removeFollower(follower, item, project) {
  return {
    type: REMOVE_FOLLOWER,
    payload: {
      follower,
      item,
      project,
    },
  };
}


export const REMOVE_FOLLOWER_SUCCESS = 'REMOVE_FOLLOWER_SUCCESS';

export function removeFollowerSuccess(id) {
  return deleteEntities(schemas.Follower, id, {
    type: REMOVE_FOLLOWER_SUCCESS,
    meta: {
      message: 'Follower removed from project',
    },
  });
}


export const REMOVE_FOLLOWER_FAIL = 'REMOVE_FOLLOWER_FAIL';

export function removeFollowerFail(error) {
  return {
    type: REMOVE_FOLLOWER_FAIL,
    meta: { error },
  };
}


export const UPDATE_FOLLOWER = 'UPDATE_FOLLOWER';

export function updateFollower(id, data) {
  return updateEntities(schemas.Follower, id, 'payload.data', {
    type: UPDATE_FOLLOWER,
    payload: {
      data,
    },
  });
}
