export default (request, entities, translators) => ({
  findAll(organisationId) {
    return request('GET', `organisations/${organisationId}/sales-orders`)
      .then((salesOrders) => salesOrders.sales_orders)
      .then((salesOrders) => salesOrders.map(translators.SalesOrder.fromServer));
  },
  confirm(address, cart, transaction) {
    const data = translators.SalesOrder.toServer(address);
    return request('PUT', `orders/carts/${cart}/pay-sales-order/${transaction}`, { sales_order: { ...data } })
      .then(translators.Transaction.fromServer);
  },
});
