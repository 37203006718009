const k = (key) => `interior_3d.briefing.interior_guide.${key}`;


export const residential = [
  {
    images: [
      require('../images/interior-3d/styles/luxe/luxe-1.jpg'),
      require('../images/interior-3d/styles/luxe/luxe-2.jpg'),
      require('../images/interior-3d/styles/luxe/luxe-3.jpg'),
      require('../images/interior-3d/styles/luxe/luxe-4.jpg'),
    ],
    label: k('modern_luxurious'),
    value: 'modern_luxurious',
    description: k('modern_luxurious_description'),
  },
  {
    images: [
      require('../images/interior-3d/styles/scandinavian/scandinavian-1.jpg'),
      require('../images/interior-3d/styles/scandinavian/scandinavian-2.jpg'),
      require('../images/interior-3d/styles/scandinavian/scandinavian-3.jpg'),
      require('../images/interior-3d/styles/scandinavian/scandinavian-4.jpg'),
    ],
    label: k('scandinavian'),
    value: 'scandinavian',
    description: k('scandinavian_description'),
  },
  {
    images: [
      require('../images/interior-3d/styles/traditional/traditional-1.jpg'),
      require('../images/interior-3d/styles/traditional/traditional-2.jpg'),
      require('../images/interior-3d/styles/traditional/traditional-3.jpg'),
      require('../images/interior-3d/styles/traditional/traditional-4.jpg'),
    ],
    label: k('traditional'),
    value: 'traditional',
    description: k('traditional_description'),
  },
];


export const commercial = [
  {
    images: [
      // require('../images/interior-3d/commercial-styles/color-fun/color-fun-1.jpg'),
      require('../images/interior-3d/commercial-styles/color-fun/color-fun-2.jpg'),
      require('../images/interior-3d/commercial-styles/color-fun/color-fun-3.jpg'),
      require('../images/interior-3d/commercial-styles/color-fun/color-fun-4.jpg'),
      require('../images/interior-3d/commercial-styles/color-fun/color-fun-5.jpg'),
      require('../images/interior-3d/commercial-styles/color-fun/color-fun-6.jpg'),
    ],
    label: k('color_fun'),
    value: 'color_fun',
  },
  {
    images: [
      // require('../images/interior-3d/commercial-styles/green-nature/green-nature-1.jpg'),
      require('../images/interior-3d/commercial-styles/green-nature/green-nature-2.jpg'),
      require('../images/interior-3d/commercial-styles/green-nature/green-nature-3.jpg'),
      require('../images/interior-3d/commercial-styles/green-nature/green-nature-4.jpg'),
      require('../images/interior-3d/commercial-styles/green-nature/green-nature-5.jpg'),
      require('../images/interior-3d/commercial-styles/green-nature/green-nature-6.jpg'),
    ],
    label: k('green_nature'),
    value: 'green_nature',
  },
  {
    images: [
      require('../images/interior-3d/commercial-styles/industrial/industrial-1.jpg'),
      require('../images/interior-3d/commercial-styles/industrial/industrial-2.jpg'),
      // require('../images/interior-3d/commercial-styles/industrial/industrial-3.jpg'),
      require('../images/interior-3d/commercial-styles/industrial/industrial-4.jpg'),
      require('../images/interior-3d/commercial-styles/industrial/industrial-5.jpg'),
      require('../images/interior-3d/commercial-styles/industrial/industrial-6.jpg'),
    ],
    label: k('industrial'),
    value: 'industrial',
  },
  {
    images: [
      require('../images/interior-3d/commercial-styles/modern/modern-1.jpg'),
      require('../images/interior-3d/commercial-styles/modern/modern-2.jpg'),
      require('../images/interior-3d/commercial-styles/modern/modern-3.jpg'),
      // require('../images/interior-3d/commercial-styles/modern/modern-4.jpg'),
      require('../images/interior-3d/commercial-styles/modern/modern-5.jpg'),
      require('../images/interior-3d/commercial-styles/modern/modern-6.jpg'),
    ],
    label: k('modern'),
    value: 'modern',
  },
];
