import set from 'lodash/set';
import LogRocket from 'logrocket';

const SHOULD_ENABLE_LOGROCKET = process.env.APP_ENV === 'production' || process.env.APP_ENV === 'staging';
const LOGROCKET_API_KEY = process.env.APP_ENV === 'production'
  ? 'kpprsc/platform-ff-ubbt0'
  : 'kpprsc/platform-ff-dev';


export const logRocketMiddleware = LogRocket.reduxMiddleware({
  stateSanitizer(state) {
    if (state.lastAction.meta && state.lastAction.meta.ignoreLogrocket) {
      return {
        ...state,
        lastAction: undefined,
      };
    }
    return state;
  },
  actionSanitizer(action) {
    if (action.meta && action.meta.ignoreLogrocket) {
      return null;
    }
    return action;
  },
});


export async function setupLogRocket() {
  if (process.env.APP_ENV !== 'production') {
    set(window, '_utils.getLogRocket', () => LogRocket);
  }

  if ( ! SHOULD_ENABLE_LOGROCKET) {
    return null;
  }

  const response = await fetch('/version.json');
  const body = response.status === 200 || response.status === 201 ? await response.json() : {};
  LogRocket.init(LOGROCKET_API_KEY, {
    release: body.version,
    network: {
      isEnabled: true,
      requestSanitizer(request) {
        if (request.headers['Content-Type'] === 'application/json') {
          const body = (typeof request.body === 'string') ? request.body : JSON.stringify(request.body);
          const cleanedBody = body ? body.replace(/,?"[\w-]*(password|creditCard)[-\w:"\s]*,?/, '') : null;
          request.body = (typeof request.body === 'string') ? cleanedBody : JSON.parse(cleanedBody);
          return request;
        }
        else {
          if (request.body && (typeof request.body === 'string')) {
            return request.body.includes('password') ? null : request;
          }
        }
      },
    },
  });
}


export default LogRocket;
