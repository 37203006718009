import React from 'react';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';
import { Icon, Button } from 'react-ittsu/components';
import Gallery from '@drawbotics/gallery';
import Modal from '@drawbotics/modal';
import compose from 'lodash/flowRight';

import Disclaimer from './Disclaimer';
import { withMedia } from '~/utils/media-provider';
import { createTranslate } from '~/utils/translation';


const tt = createTranslate('pods.order.components.service_modal');


const styles = {
  baseServiceModal: css`
    position: relative;
    background: ${sv.white};
    max-width: 1200px;
    max-height: 700px;
    width: calc(100vw - ${sv.baseMargin});
    height: calc(100vh - ${sv.baseMargin});
    display: flex;
    align-items: stretch;

    @media ${sv.ipadLandscape} {
      height: calc(100vh - ${sv.baseMargin} * 3);
    }

    @media ${sv.ipad} {
      width: 100vw;
      height: 100vh;
      max-height: 100%;
      max-width: 100%;
      flex-direction: column;
      overflow-y: scroll;
    }

    @media ${sv.phoneXl} {
      & [data-element="priceDisclaimer"] {
        display: none;
      }
    }
  `,
  gallery: css`
    flex: 1;
    width: 50%;
    max-width: 50%;

    @media ${sv.ipadLandscape} {
      flex: none;
      width: 400px;
    }

    @media ${sv.ipad} {
      height: 400px;
      width: 100%;
      max-width: 100%;
      flex: none;
      margin-top: calc(${sv.baseMargin} + ${sv.baseMarginSmall});
    }

    @media ${sv.phoneXl} {
      height: 250px;
    }
  `,
  panel: css`
    flex: 1;
    padding: ${sv.basePadding};
    padding-bottom: ${sv.basePaddingSmall};
    position: relative;
    display: flex;
    flex-direction: column;

    @media ${sv.ipad} {
      padding: ${sv.basePaddingSmall};
      height: 100%;
    }
  `,
  close: css`
    position: absolute;
    top: ${sv.baseMarginSmall};
    right: ${sv.baseMarginSmall};
    color: ${sv.textSecondaryDark};

    &:hover {
      cursor: pointer;
      color: ${sv.textPrimaryDark};
    }
  `,
  header: css`
    @media ${sv.ipad} {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background: ${sv.white};
      padding-top: 10px;
      z-index: 99;
    }
  `,
  content: css`
    flex: 1;
    overflow-y: scroll;
    margin-bottom: calc(${sv.baseMargin} * -2);
    padding: 5px;
    padding-bottom: calc(${sv.baseMargin} * 2 + ${sv.basePaddingSmall});

    @media ${sv.ipad} {
      overflow-y: visible;
      padding-bottom: calc(${sv.baseMargin} * 4);
    }
  `,
  title: css`
    color: ${sv.brandPrimary};
    text-align: center;
    font-size: 1.4em;
    margin-bottom: 10px;
  `,
  disclaimer: css`
    text-align: center;
    margin-bottom: 10px;
  `,
  footer: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: ${sv.basePaddingSmall} 0;
    border-top: 1px solid ${sv.grey300};
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(3px);
    z-index: 999;

    @media ${sv.ipad} {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: ${sv.basePaddingSmall};
    }
  `,
  addButton: css`
    margin-left: auto;
    white-space: nowrap;

    @media ${sv.ipad} {
      border-radius: 0px !important;
      margin: -15px;
      margin-left: auto;
      padding-top: calc(${sv.basePaddingSmall} + 10px);
      padding-bottom: calc(${sv.basePaddingSmall} + 10px);
      font-size: 1em;
    }
  `,
  price: css`
    color: ${sv.brandPrimary};
    font-size: 1.5em;
    margin-right: ${sv.baseMarginSmall};
  `,
}


const BaseServiceModal = ({
  children,
  title,
  images,
  videos,
  price,
  description,
  disclaimer,
  priceDisclaimer,
  onClickConfirm,
  onClickClose,
  isVisible,
  autoplayGallery=true,
  mediaSize,
  confirmAsUpdate=false,
  addDisabled=false,
}) => {
  return (
    <Modal isVisible={isVisible} onClickOverlay={onClickClose}>
      <div className={styles.baseServiceModal}>
        <div className={styles.gallery}>
          <Gallery
            images={images}
            videos={videos}
            cover={mediaSize.isTablet ? false : true}
            autoplay={autoplayGallery}
            minimal={mediaSize.isMobile}
            videoProps={{ playsInline: true }} />
        </div>
        <div className={styles.panel}>
          <div className={styles.header}>
            <div className={styles.close} onClick={onClickClose}>
              <Icon name="close-bold" />
            </div>
            <div className={styles.title}>
              {title}
            </div>
          </div>
          {do{
            if (disclaimer) {
              <div className={styles.disclaimer}>
                <Disclaimer disclaimer={disclaimer} />
              </div>
            }
          }}
          <div className={styles.content} data-element="modal-content">
            {children}
          </div>
          <div className={styles.footer}>
            <div className={styles.price}>
              {price}
            </div>
            {do{
              if (priceDisclaimer) {
                <div data-element="priceDisclaimer">
                  <Disclaimer disclaimer={priceDisclaimer} />
                </div>
              }
            }}
            <Button disabled={confirmAsUpdate === true ? false : addDisabled} className={styles.addButton} category="primary" round onClick={onClickConfirm}>
              {confirmAsUpdate ? tt('update_cart') : tt('add_to_cart')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}


export default compose(
  withMedia(),
)(BaseServiceModal);
