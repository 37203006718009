import { Module } from '@tokamakjs/react';

import { AuthService, RouterService } from '~/services';

import { SettingsApi } from './api';
import { TeammatesService, UserService } from './services';
import { TeammatesStore } from './stores';

@Module({
  providers: [
    SettingsApi,
    TeammatesService,
    TeammatesStore,
    RouterService,
    AuthService,
    UserService,
  ],
})
export class ProfileModule {}
