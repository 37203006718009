export default (request, entities, translators) => ({
  findAll(organisationId) {
    return request('GET', `organisations/${organisationId}/orders`)
      .then((orders) => orders.orders)
      .then((orders) => orders.map(translators.Orders.fromServer));
  },
  find(id) {
    return request('GET', `orders/${id}`)
      .then(translators.Orders.fromServer);
  },
});
