import { residential as residentialGuide, commercial as commercialGuide } from '../utils/interior-guide';

const k = (key) => `interior_3d.briefing.${key}`;


const residential = {
  1: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('unit'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('specify_unit'),
        component: 'Note',
      },
      {
        type: 'question-component',
        label: k('unit_number'),
        component: 'Input',
        required: 'all',
        key: 'unit',
        keyLabel: k('keys.unit'),
      },
      {
        type: 'component',
        label: k('specify_room'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'SingleSelect',
        options: {
          gridSpan: 4,
        },
        key: 'room',
        keyLabel: k('keys.room'),
        required: 'all',
        values: [
          {
            image: require('../images/interior-3d/room-type/living-kitchen.jpg'),
            value: 'living_and_kitchen',
            label: k('living_kitchen_dining'),
            meta: {
              type: 'meta',
              noToggle: true,
              components: [{
                  type: 'question-component',
                  label: k('add_kitchen_description'),
                  component: 'Input',
                  key: 'kitchenFlooring',
                  keyLabel: k('keys.kitchen_flooring'),
                }, {
                  type: 'question-component',
                  label: k('upload_kitchen_flooring'),
                  component: 'FileUploader',
                  key: 'kitchenFlooringAttachments',
                  keyLabel: k('keys.kitchen_flooring_attachments'),
                }, {
                  type: 'question-component',
                  label: k('add_kitchen_materials_description'),
                  component: 'Input',
                  key: 'kitchenMaterials',
                  keyLabel: k('keys.kitchen_materials'),
                }, {
                  type: 'question-component',
                  label: k('upload_kitchen_materials'),
                  component: 'FileUploader',
                  key: 'kitchenMaterialsAttachments',
                  keyLabel: k('keys.kitchen_materials_attachments'),
                }, {
                  type: 'question-component',
                  label: k('add_living_flooring_description'),
                  component: 'Input',
                  key: 'livingRoomFlooring',
                  keyLabel: k('keys.living_flooring'),
                }, {
                  type: 'question-component',
                  label: k('upload_living_flooring'),
                  component: 'FileUploader',
                  key: 'livingRoomFlooringAttachments',
                  keyLabel: k('keys.living_flooring_attachments'),
                },
              ],
            },
          }, {
            image: require('../images/interior-3d/room-type/bathroom.jpg'),
            value: 'bathroom',
            label: k('bathroom'),
            meta: {
              type: 'meta',
              noToggle: true,
              components: [{
                  type: 'question-component',
                  label: k('add_bathroom_flooring_description'),
                  component: 'Input',
                  key: 'bathroomFlooring',
                  keyLabel: k('keys.bathroom_flooring')
                }, {
                  type: 'question-component',
                  label: k('upload_bathroom_flooring'),
                  component: 'FileUploader',
                  key: 'bathroomFlooringAttachments',
                  keyLabel: k('keys.bathroom_flooring_attachments'),
                }, {
                  type: 'question-component',
                  label: k('add_wall_tiles_description'),
                  component: 'Input',
                  key: 'wallTiles',
                  keyLabel: k('keys.wall_tiles'),
                }, {
                  type: 'question-component',
                  label: k('upload_wall_tiles'),
                  component: 'FileUploader',
                  key: 'wallTilesAttachments',
                  keyLabel: k('keys.wall_tiles_atttachments'),
                },
              ],
            },
          }, {
            image: require('../images/interior-3d/room-type/bedroom.jpg'),
            value: 'bedroom',
            label: k('bedroom'),
            meta: {
              type: 'meta',
              noToggle: true,
              components: [
                {
                  type: 'question-component',
                  label: k('add_bedroom_flooring_description'),
                  component: 'Input',
                  key: 'bedroomFlooring',
                  keyLabel: k('keys.bedroom_flooring'),
                }, {
                  type: 'question-component',
                  label: k('upload_bedroom_flooring'),
                  component: 'FileUploader',
                  key: 'bedroomFlooringAttachments',
                  keyLabel: k('keys.bedroom_flooring_attachments'),
                },
              ],
            },
          },  {
            image: require('../images/shared/other.jpg'),
            value: 'other',
            label: 'shared.other_option',
            meta: {
              type: 'meta',
              noToggle: true,
              components: [
                {
                  type: 'question-component',
                  label: k('add_room_description'),
                  component: 'Input',
                  key: 'otherRoom',
                  keyLabel: k('keys.other_room'),
                  required: 'all',
                }, {
                  type: 'question-component',
                  label: k('upload_room_type'),
                  component: 'FileUploader',
                  key: 'otherRoomAttachments',
                  keyLabel: k('keys.other_room_attachments'),
                  required: 'all',
                },
              ],
            },
          },
        ],
      },
    ],
  },
  2: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('interior_style'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('choose_style'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'SingleSelect',
        key: 'style',
        keyLabel: k('keys.style'),
        required: 'all',
        values: residentialGuide,
      },
      {
        type: 'meta',
        label: 'shared.show_my_own',
        components: [
          {
            type: 'question-component',
            label: 'A description of your example',
            component: 'Input',
            key: 'otherStyle',
            keyLabel: k('keys.other_style'),
          }, {
            type: 'question-component',
            label: 'shared.upload_example',
            component: 'FileUploader',
            key: 'otherStyleAttachments',
            keyLabel: k('keys.other_style_attachments'),
          },
        ],
      },
    ],
  },
  3: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('environment'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('choose_environment'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'SingleSelect',
        key: 'environment',
        keyLabel: k('keys.environment'),
        required: 'all',
        values: [
          {
            image: require('../images/shared/drone-shooting-integration.jpg'),
            value: 'drone_shooting',
            label: k('drone_integration'),
            disabled: (answers) => ! answers.droneShooting,
            disabledText: k('drone_not_available'),
          }, {
            image: require('../images/interior-3d/environment/3d-environment.jpg'),
            value: 'environment_3d',
            label: k('environment_3d'),
          }, {
            image: require('../images/shared/other.jpg'),
            value: 'other',
            label: 'shared.other_option',
            meta: {
              type: 'meta',
              noToggle: true,
              components: [
                {
                  type: 'question-component',
                  label: k('additional_integration_info'),
                  component: 'Input',
                  key: 'otherEnvironment',
                  keyLabel: k('keys.other_environment'),
                }, {
                  type: 'question-component',
                  label: k('upload_environment_files'),
                  component: 'FileUploader',
                  key: 'otherEnvironmentAttachments',
                  keyLabel: k('keys.environment_files'),
                  required: 'all',
                },
              ],
            },
          },
        ],
      },
    ],
  },
  4: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'shared.additional_info',
        component: 'Title',
      }, {
        type: 'component',
        label: 'shared.anything_to_add',
        component: 'Note',
      }, {
        type: 'question-component',
        label: 'shared.additional_comments',
        component: 'Input',
        key: 'otherInfo',
        keyLabel: 'shared.keys.other_info',
      }, {
        type: 'question-component',
        label: 'shared.upload_files',
        component: 'FileUploader',
        key: 'otherInfoAttachments',
        keyLabel: 'shared.keys.other_info_attachments',
      },
    ],
  },
};


const commercial = {
  1: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('interior_style'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('choose_style'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'SingleSelect',
        key: 'style',
        keyLabel: k('keys.style'),
        required: 'all',
        values: commercialGuide,
      },
      {
        type: 'meta',
        label: 'shared.show_my_own',
        components: [
          {
            type: 'question-component',
            label: 'shared.example_description',
            component: 'Input',
            key: 'otherStyle',
            keyLabel: k('keys.other_style'),
          }, {
            type: 'question-component',
            label: 'shared.upload_example',
            component: 'FileUploader',
            key: 'otherStyleAttachments',
            keyLabel: k('keys.other_style_attachments'),
          },
        ],
      },
    ],
  },
  2: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('additional_attachments'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('upload_required_files'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'Input',
        key: 'flooring',
        keyLabel: k('keys.flooring_description'),
        label: k('add_flooring_description'),
      },
      {
        type: 'question-component',
        component: 'FileUploader',
        key: 'flooringAttachments',
        keyLabel: k('keys.flooring_attachments'),
        label: k('upload_flooring'),
        required: 'all',
      },
    ],
  },
  3: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'shared.additional_info',
        component: 'Title',
      }, {
        type: 'component',
        label: 'shared.anything_to_add',
        component: 'Note',
      }, {
        type: 'question-component',
        label: 'shared.additional_comments',
        component: 'Input',
        key: 'otherInfo',
        keyLabel: 'shared.keys.other_info',
      }, {
        type: 'question-component',
        label: 'shared.upload_files',
        component: 'FileUploader',
        key: 'otherInfoAttachments',
        keyLabel: 'shared.keys.other_info_attachments',
      },
    ],
  },
};


export default { residential, commercial };
