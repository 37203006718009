import React from 'react';
import autobind from 'autobind-decorator';
import { Grid, Cell } from 'react-ittsu/layout';
import gql from 'fraql';

import BaseServiceModal from '../BaseServiceModal';
import Paragraph from '../Paragraph';
import SectionTitle from '../SectionTitle';
import { ProductCard, Stripe, StripeGroup, Slider } from '../../forms';
import { priceItem, applyPrice } from '../../../utils/pricing';
import { specsToItems, itemsToSpecs } from '~/pods/order/utils/services/specs-to-items';
import applyChanges from '~/pods/order/utils/services/specs-to-items/apply-changes';
import { getItemsByService, merge, getFeaturesForProduct, openIntercom } from '~/pods/order/utils';
import { createTranslate, translate as t } from '~/utils/translation';
import { EstateEnums } from '~/pods/order/utils/estate-enums';

import serviceImages from '~/pods/order/images/services';


const tt = createTranslate('pods.order.services.brochure.order');
const tm = createTranslate('pods.order.components.service_modal');

const MAX_LANGUAGES = 3;

const sliderValues = {
  max: 40,
  min: 4,
  step: (currentValue) => {
    if (currentValue === 4 || currentValue === 6) {
      return 2;
    }
    else {
      return 4;
    }
  },
  pois: [
    { value: 5, label: tt('leaflet.title'), offset: true },
    { value: 8, label: tt('standard.title') },
    { value: 32, label: tt('deluxe.title') },
  ],
};

class Brochure extends React.Component {

  static fragments = {
    Item: gql`
      fragment _ on Item {
        id
        details {
          ... on BrochureDetails {
            copywriting
            numberOfLanguages
            numberOfPages
            printing
          }
        }
        product {
          id @client(type: Product)
          custom
          name
          features {
            locale
            content
          }
        }
      }
    `,
  }

  state = {
    specs: {
      standard: false,
      copywriting: false,
      // printing: false,
      numberOfLanguages: 1,
      numberOfPages: 4,
    },
    custom: null,
    price: undefined,
  };

  componentDidUpdate(prevProps) {
    const { specs } = this.state;
    const { service, estate } = this.props;
    const { service: prevService } = prevProps;
    if (service?.id === 'brochure' && ! prevService) {
      const newSpecs = itemsToSpecs({ estate, service });
      const items = getItemsByService(estate).brochure || [];
      const customItem = items.find((item) => item.product.custom);
      const price = items != null ? items.reduce((memo, i) => memo + priceItem(i, this.props.sessionUser.currency.code, true), 0) : 0;
      this.setState({ specs: merge(specs, newSpecs), price, custom: customItem ? true : null });
    }
  }

  render() {
    const { onClickClose, isVisible, service, estate, sessionUser } = this.props;
    const { specs: { standard, copywriting, numberOfLanguages, numberOfPages }, price, custom } = this.state;
    const { brochure } = serviceImages;
    const items = getItemsByService(estate).brochure || [];
    const customItem = items.find((item) => item.product.custom);
    const customFeatures = custom !== null ? getFeaturesForProduct(customItem?.product) : null;
    const isResidential = estate.projectType === EstateEnums.ProjectType.RESIDENTIAL;
    return (
      <BaseServiceModal
        isVisible={isVisible && service?.id === 'brochure'}
        title={service?.id ? t(`services.${service.id}`) : ''}
        images={Object.values(brochure)}
        price={sessionUser.addCurrency(price)}
        onClickConfirm={this._handleClickConfirm}
        confirmAsUpdate={items?.length > 0}
        onClickClose={onClickClose}
        addDisabled={price === 0 || numberOfPages === 0}>
        <div className="childcontent">
          <Paragraph>
            {tt('description')}
          </Paragraph>
          <SectionTitle>
            {tt('select_type')}
          </SectionTitle>
          <Grid withVGutters withHGutters>
            <Cell size="1of2">
              <ProductCard
                name="standard"
                label={tm('standard')}
                note={tm('from_price', { price: applyPrice(isResidential ? { EUR: 1480, USD: 1840, GBP: 1480 } : { EUR: 2060, USD: 2600, GBP: 2060 }, sessionUser) })}
                fullHeight
                formComponent="checkbox"
                features={[{
                  text: tt('features.first'),
                }, {
                  text: tt('features.second.text'),
                  infoText: tt('features.second.info'),
                }, {
                  text: tt('features.third'),
                }]}
                value={standard}
                onChange={this._handleClickStandard} />
            </Cell>
            <Cell size="1of2">
              <ProductCard
                name="custom"
                label={custom !== null ? `${customItem?.product.name}` : 'Custom'}
                note={custom !== null && customItem ? `${sessionUser.addCurrency(customItem?.price)}` : 'Contact us'}
                formComponent="checkbox"
                fullHeight
                features={customFeatures}
                onChange={this._handleClickCustom}
                value={!! custom} />
            </Cell>
          </Grid>
          <SectionTitle>
            Number of pages
          </SectionTitle>
          <Paragraph>
            {tt('choose_pages')}
          </Paragraph>
          <Slider
            defaultValue={0}
            min={sliderValues.min}
            max={sliderValues.max}
            step={sliderValues.step(numberOfPages)}
            value={numberOfPages}
            pois={sliderValues.pois}
            formatDisplayValue={(v) => tt('pages', { count: String(v) })}
            disabled={! standard}
            onChange={(v) => this.setState({ specs: { ...this.state.specs, numberOfPages: v }}, this._syncState)} />
          <SectionTitle>
            {tt('options')}
          </SectionTitle>
          <StripeGroup>
            <Stripe
              title={tt('copywriting')}
              subtitle={tm('variable_price')}
              component="switch"
              features={[{
                text: tt('features.fourth.text'),
                infoText: tt('features.fourth.info'),
              }]}
              disabled={! standard}
              value={copywriting}
              onChange={() => this.setState({ specs: { ...this.state.specs, copywriting: ! copywriting } }, this._syncState)} />
            <Stripe
              title={tt('number_of_languages')}
              subtitle={tt('minimum')}
              component="counter"
              features={[{
                text: tt('features.fifth.text'),
                infoText: tt('features.fifth.info'),
              }]}
              disabled={! standard}
              value={numberOfLanguages}
              onChange={(v) => this.setState({ specs: { ...this.state.specs, numberOfLanguages: v === 0 ? 1 : v === MAX_LANGUAGES + 1 ? MAX_LANGUAGES : v } }, this._syncState)} />
          </StripeGroup>
        </div>
      </BaseServiceModal>
    );
  }

  @autobind
  _syncState() {
    const { estate, service } = this.props;
    const { specs } = this.state;
    const changes = specsToItems({ specs, estate, service });
    const updatedEstate = applyChanges(changes, estate);
    const items = getItemsByService(updatedEstate).brochure;
    const price = items != null
      ? items
          .map((item) => item.estate != null ? item : { ...item, estate: updatedEstate })
          .reduce((memo, i) => memo + priceItem(i, this.props.sessionUser.currency.code, true), 0)
      : 0;
    this.setState({ price });
    return { changes, updatedEstate, price };
  }

  @autobind
  _handleClickStandard() {
    const { specs: { standard, copywriting, numberOfLanguages, numberOfPages } } = this.state;
    const newValue = ! standard;
    this.setState({
      specs: {
        standard: newValue,
        numberOfLanguages: newValue ? numberOfLanguages : 1,
        numberOfPages: newValue ? numberOfPages : 4,
        copywriting: newValue ? copywriting : false,
      }
    }, this._syncState);
  }

  @autobind
  _handleClickCustom() {
    const { custom } = this.state;
    if (custom === null) {
      openIntercom();
    }
    else {
      const newValue = ! custom;
      this.setState({ custom: newValue }, this._syncState);
    }
  }

  @autobind
  _handleClickConfirm() {
    const { onModifyService, estate } = this.props;
    const { custom } = this.state;
    const extraRemove = custom === false ? [getItemsByService(estate).brochure.find((item) => item.product.custom)] : [];
    const { changes } = this._syncState();
    onModifyService({ ...changes, remove: [ ...changes.remove, ...extraRemove ] });
  }
}


export default Brochure;
