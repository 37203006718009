import { Add, ForEach, RelativeAdd, Switch } from 'drawbotics-pricer';


const description = {
  Base: [
    Switch('estate.projectType').is({
      'residential': [
        ForEach('details.hotspots').limit(2).do(Add({ EUR: 1200, USD: 1500, GBP: 1200 })),
        ForEach('details.hotspots').threshold(2).limit(5).do(Add({ EUR: 1050, USD: 1300, GBP: 1050 })),
        ForEach('details.hotspots').threshold(5).do(Add({ EUR: 850, USD: 1050, GBP: 850 })),
      ],
      'commercial': [
        ForEach('details.hotspots').limit(2).do(Add({ EUR: 1400, USD: 1700, GBP: 1400 })),
        ForEach('details.hotspots').threshold(2).limit(5).do(Add({ EUR: 1200, USD: 1450, GBP: 1200 })),
        ForEach('details.hotspots').threshold(5).do(Add({ EUR: 1050, USD: 1300, GBP: 1050 })),
      ],
    }),
  ],
  Delivery: [
    Switch('estate.delivery').is({
      'relaxed': RelativeAdd(-0.1).from('Base'),
      'standard': RelativeAdd(0).from('Base'),
      'express': RelativeAdd(0.5).from('Base'),
    })
  ],
};


export default description;
