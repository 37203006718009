export const EstateEnums = {
  PropertyType: {
    RENOVATION: 'renovation',
    NEW_DEVELOPMENT: 'new_development',
    EXISTING_PROPERTY: 'existing_property',
    NONE: null,
  },
  ProjectType: {
    RESIDENTIAL: 'residential',
    COMMERCIAL: 'commercial',
    NONE: null,
  },
  ProjectSubtype: {
    HOUSE: 'house',
    BUILDING: 'building',
    COMPLEX: 'complex',
    APARTMENT: 'apartment',
    DUPLEX: 'duplex',
    COMMERCIAL: 'commercial',
    NONE: null,
  },
  Delivery: {
    RELAXED: 'relaxed',
    STANDARD: 'standard',
    EXPRESS: 'express',
  },
};
