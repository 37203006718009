import { Add, Switch, RelativeAdd } from 'drawbotics-pricer';


const description = {
  Base: [
    Switch('estate.projectType').is({
      'residential': [
        Add({ EUR: 1250, USD: 1500, GBP: 1100 }),
      ],
      'commercial': [
        Add({ EUR: 1500, USD: 1800, GBP: 1350, }),
      ],
    }),
  ],
  Delivery: [
    Switch('estate.delivery').is({
      'relaxed': RelativeAdd(-0.1).from('Base'),
      'standard': RelativeAdd(0).from('Base'),
      'express': RelativeAdd(0.5).from('Base'),
    })
  ],
};


export default description;
