import * as SessionActions from 'actions/session';
import { readSession } from 'utils';


function createEmptySession() {
  return {};
}


function getInitialState() {
  const session = readSession();
  return session ? session : createEmptySession();
}


export default function user(state=getInitialState(), action) {
  switch (action.type) {
    case SessionActions.SIGN_IN: {
      return createEmptySession();
    }
    case SessionActions.SIGN_IN_SUCCESS: {
      const { session } = action.payload;
      return session;
    }
    case SessionActions.SIGN_UP: {
      return createEmptySession();
    }
    case SessionActions.SIGN_UP_SUCCESS: {
      const { session } = action.payload;
      return session;
    }
    case SessionActions.SIGN_OUT_SUCCESS: {
      return createEmptySession();
    }
    case SessionActions.GUEST_SIGN_UP: {
      return createEmptySession();
    }
    case SessionActions.GUEST_SIGN_UP_SUCCESS: {
      const { session } = action.payload;
      return session;
    }
    case SessionActions.UPDATE_SESSION: {
      const { session } = action.payload;
      return { ...state, ...session };
    }
    default: {
      return state;
    }
  }
}
