import set from 'lodash/set';

import reportException from './report-exception';


export default function setupErrors(store) {
  // Monkeypatch console adding reportException error
  console.reportException = (err, extra) => reportException(err, {
    extra: {
      action: store.getState().lastAction,
      state: store.getState(),
      from: 'console.reportException',
      ...extra,
      info: err ? err.info : {},
    },
  });

  // Add test trigger errors functions
  // This functions are only used to test error reports
  set(window, '_utils.triggerErrorInInit', () => { setTimeout(() => { throw new Error('TEST ERROR INIT') }, 1000) });
  window._utils.triggerErrorInEpics = () => store.dispatch({ type: 'TRIGGER_ERROR_IN_EPICS' });
  window._utils.triggerErrorInReducer = (code) => store.dispatch({ type: 'TRIGGER_ERROR_IN_REDUCER' });

  // Bind to window onerror
  window.addEventListener('error', (e) => {
    const err = e.error;
    if (err) {
      console.reportException(err, { from: 'app/bootstrap.js' });
    }
  });
}
