import morphey, { fromValue, fromKey } from 'morphey';

import { userFromServer } from './user';


const translationsFromServer = (invitation) => ({
  id: 'id',
  status: fromKey('status').defaultTo('default'),
  organization: 'organisation_id',
  organizationName: 'organisation_name',
  token: 'token',
  user: fromValue(userFromServer(invitation)),
});


export function invitationFromServer(invitation) {
  return morphey(invitation, translationsFromServer);
}


const translationsToServer = {
  id: 'id',
  email: 'user.email',
  first_name: 'user.firstName',
  last_name: 'user.lastName',
  organisation_id: 'organization',
  token: 'token',
  item_ids: 'items',
};


export function invitationToServer(invitation) {
  return morphey(invitation, translationsToServer);
}


export default {
  fromServer: invitationFromServer,
  toServer: invitationToServer,
};
