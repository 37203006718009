const k = (key) => `billboard.briefing.${key}`;


const residential = {
  1: {
    type: 'section',
    components: [
      {
        type: 'component',
        component: 'Title',
        label: 'billboard.briefing.format',
      },
      {
        type: 'component',
        component: 'Note',
        label: 'billboard.briefing.choose_format',
      },
      {
        type: 'question-component',
        component: 'SingleSelect',
        key: 'format',
        keyLabel: 'billboard.briefing.keys.format',
        required: 'all',
        values: [
          {
            value: 'format_2x4m',
            label: '2x4M',
            image: require('../images/billboard/2x4-billboard.jpg'),
          },
          {
            value: 'format_3x4m',
            label: '3x4M',
            image: require('../images/billboard/3x4-billboard.jpg'),
          },
          {
            value: 'format_2x6m',
            label: '2x6M',
            image: require('../images/billboard/2x6-billboard.jpg'),
          },
          {
            value: 'precise_measures',
            label: 'billboard.briefing.precise_measures',
            warning: 'billboard.briefing.precise_measures_warning',
            image: require('../images/shared/precise-measures.jpg'),
            meta: {
              type: 'meta',
              noToggle: true,
              components: [
                {
                  type: 'component',
                  component: 'Note',
                  label: 'billboard.briefing.measures_note',
                },
                {
                  type: 'question-component',
                  component: 'Input',
                  label: 'billboard.briefing.measurements',
                  keyLabel: 'billboard.briefing.keys.measures',
                  key: 'measures',
                  required: 'all',
                }
              ]
            }
          },
          {
            value: 'other',
            label: 'shared.other_option',
            image: require('../images/shared/other.jpg'),
            meta: {
              type: 'meta',
              noToggle: true,
              components: [
                {
                  type: 'question-component',
                  component: 'Input',
                  label: 'billboard.briefing.other_format',
                  keyLabel: 'billboard.briefing.other_format',
                  key: 'otherFormat',
                  required: 'all',
                }
              ]
            }
          }
        ]
      }
    ],
  },
  2: {
    type: 'section',
    filter: {
      type: 'service',
      value: 'brandId',
    },
    components: [
      {
        type: 'component',
        label: 'billboard.briefing.branding',
        component: 'Title',
      },
      {
        type: 'component',
        label: 'billboard.briefing.branding_selection',
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'SingleSelect',
        key: 'brandId',
        keyLabel: 'billboard.briefing.keys.brand_id',
        required: 'all',
        values: [
          {
            image: require('../images/shared/company-branding.jpg'),
            value: 'company',
            label: 'billboard.briefing.company_branding',
            meta: {
              type: 'meta',
              noToggle: true,
              components: [
                {
                  type: 'question-component',
                  label: 'billboard.briefing.upload_company_files',
                  keyLabel: 'billboard.briefing.keys.brand_id_attachments',
                  component: 'FileUploader',
                  key: 'brandIdAttachments',
                  required: 'all',
                },
              ],
            },
          },
          {
            image: require('../images/shared/project-branding.jpg'),
            value: 'project',
            label: 'billboard.briefing.project_branding',
            meta: {
              type: 'meta',
              condition: {
                required: 'one',
                conditions: [{
                  type: 'service',
                  value: 'brandId',
                }],
              },
              components: [
                {
                  type: 'question-component',
                  label: 'billboard.briefing.upload_project_files',
                  keyLabel: 'billboard.briefing.keys.brand_id_attachments',
                  component: 'FileUploader',
                  key: 'brandIdAttachments',
                  required: 'all',
                },
              ],
            },
          },
        ],
      },
    ]
  },
  3: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'shared.visuals',
        component: 'Title',
      },
      {
        type: 'component',
        label: 'shared.visuals_note',
        component: 'Note',
      },
      
      {
        type: 'question-component',
        component: 'SingleSelect',
        key: 'visuals',
        keyLabel: 'shared.keys.visuals',
        required: 'all',
        values: [
          {
            value: 'drawbotics',
            label: 'shared.drawbotics',
            image: require('../images/shared/drawbotics.jpg'),
          },
          {
            value: 'upload_my_own',
            image: require('../images/shared/my-own.jpg'),  
            label: 'shared.upload_my_own',
            meta: {
              type: 'meta',
              noToggle: true,
              components: [
                {
                  type: 'question-component',
                  label: 'shared.upload_visuals',
                  component: 'FileUploader',
                  key: 'visualsAttachments',
                  keyLabel: 'shared.keys.visuals_attachments',
                },
              ],
            },
          },
          {
            value: 'other',
            label: 'shared.other',
            image: require('../images/shared/other.jpg'),            
            meta: {
              type: 'meta',
              noToggle: true,
              components: [
                {
                  type: 'question-component',
                  component: 'Input',
                  label: 'shared.other_visuals',
                  key: 'otherVisuals',
                  keyLabel: 'shared.keys.other_visuals',
                },
              ],
            },
          },
        ],
      },
    ],
  },
  4: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('language'),
        component: 'Title',
      }, {
        type: 'component',
        label: k('language_note'),
        component: 'Note',
      }, {
        type: 'question-component',
        component: 'SingleSelect',
        key: 'language',
        keyLabel: k('keys.language'),
        required: 'all',
        values: [
          {
            value: 'en',
            image: require('../images/shared/EN.jpg'),
            label: 'shared.english',
          },
          {
            value: 'fr',
            image: require('../images/shared/FR.jpg'),
            label: 'shared.french',
          },
          {
            value: 'nl',
            image: require('../images/shared/NL.jpg'),
            label: 'shared.dutch',
          },
        ],
      },
    ],
  },
  5: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'shared.additional_info',
        component: 'Title',
      }, {
        type: 'component',
        label: 'shared.anything_to_add',
        component: 'Note',
      }, {
        type: 'question-component',
        label: 'shared.additional_comments',
        component: 'Input',
        keyLabel: 'shared.keys.other_info',
        key: 'otherInfo',
      }, {
        type: 'question-component',
        label: 'shared.upload_files',
        component: 'FileUploader',
        keyLabel: 'shared.keys.other_info_attachments',
        key: 'otherInfoAttachments',
      },
    ],
  },
};

export default { residential };
