export default `
  details {
    ... on BillboardDetails {
      printing
    }
    ... on BrandIdDetails {
      projectName
    }
    ... on BrochureDetails {
      copywriting
      numberOfLanguages
      numberOfPages
    }
    ... on Exterior3DDetails {
      droneShooting
      viewCount
    }
    ... on ExteriorRestylingDetails {
      numberOfImages
    }
    ... on ExteriorTour3DDetails {
      hotspots
    }
    ... on FlyerDetails {
      printing
    }
    ... on Interior3DDetails {
      droneShooting
    }
    ... on LandingPageDetails {
      copywriting
      numberOfLanguages
    }
    ... on Model360Details {
      droneShooting
    }
    ... on NewsletterDetails {
      copywriting
      numberOfLanguages
    }
    ... on PhotoEditingDetails {
      numberOfImages
      logoAndWatermark
    }
    ... on Plan2DDetails {
      style
      dimensions
      garden
      surfaceArea
      logo
      disclaimer
      view3d
    }
    ... on RevoDetails {
      droneShooting
      project360Rotation
      floorplansIn3d
      furnishedFloors
      shoebox360Rotation
      googleMapsIntegration
      environmentIn3d
    }
    ... on ShoeboxDetails {
      type
    }
    ... on SitePlanDetails {
      droneShooting
    }
    ... on Tour3DDetails {
      type
      rooms
      terraceHotspots
      gardenHotspots
      commercialHotspots
    }
    ... on VideoAnimationDetails {
      droneShooting
      videoShooting
      duration
    }
    ... on VrHeadsetDetails {
      printing
    }
    ... on WebsiteDetails {
      copywriting
      numberOfLanguages
    }
    ... on DroneShootingDetails {
      exterior3d
      interior3d
      sitePlan
      videoAnimation
      revo
      panorama360
      model360
    }
  }
`;
