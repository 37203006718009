import { AuthModule, TargetDomain, APP_CONTEXT } from '@drawbotics/auth';
import React, { useEffect } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import { Color } from '@drawbotics/react-drylus';
import { TokamakApp } from '@tokamakjs/react';

import Alerts from '~/containers/Alerts';
import { Switch } from '~/components/Route';
import Application from './Application';
import StudioGuest from './StudioGuest';
import RecoverPassword from './RecoverPassword';
import ConfirmEmail from './ConfirmEmail';
import { Return } from 'pods/transactions/utils/payments';
import Transaction from 'pods/order/routes/Transaction';
import { AlertsProvider } from '@drawbotics/react-drylus';
import { AuthRoutesWrapper, createViewWrapper } from '~/components';


const Auth = () => {
  useEffect(() => {
    (async () => {
      const context = {
        color: Color.BRAND,
        targetDomain: TargetDomain.DRAWBOTICS,
        env: {
          AUTH_HOST: process.env.AUTH_HOST,
          GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID,
          AZURE_CLIENT_ID: process.env.AZURE_CLIENT_ID,
        },
        onFinish: () => {
          const urlSearchParams = new URLSearchParams(window.location.search);
          const next = urlSearchParams.get('next');
          window.location.href = decodeURIComponent(next ?? '/');
        },
      };
      const app = await TokamakApp.create(AuthModule, {
        basePath: '/auth',
        globalProviders: [{ provide: APP_CONTEXT, useValue: context }],
      });

      app.render('#auth-routes-root', context);
    })();
  }, []);

  return (
    <AuthRoutesWrapper>
      <div id="auth-routes-root" />
    </AuthRoutesWrapper>
  )
}

const Root = ({ location }) => {
  const { search } = location;
  
  return (
    <AlertsProvider style={{ height: '100%' }}>
      <Switch handleNotFound>
        <Route path="/auth/*" component={createViewWrapper(Auth)} />
        <Redirect from="/login" to="/auth/login" />
        <Redirect from="/sign-up" to="/auth/sign-up" />
        <Route path="/return" component={Return} />
        <Route path="/studio/:token" exact component={StudioGuest} />
        <Route path="/order/transaction/:transactionToken/settle" component={Transaction} />
        <Route path="/order/transaction/:transactionToken/confirmation" component={Transaction} />
        <Redirect from="/payments/:transactionToken/settle" to="/order/transaction/:transactionToken/settle" />
        <Route path="/password-recovery" component={RecoverPassword} />
        <Route path="/users/password/edit" component={RecoverPassword} />
        <Redirect from="/client_users/password/edit" to={{ pathname: '/users/password/edit', search }} />
        <Route path="/users/confirmation" component={ConfirmEmail} />
        <Redirect from="/client_users/confirmation" to={{ pathname: '/users/confirmation', search }} />
        {/* <Route path="/dev-auth" component={createViewWrapper(DevAuthRoute)} /> */}
        <Route path="/" component={Application} />
      </Switch>
      <Alerts />
    </AlertsProvider>
  );
};


export default hot(withRouter(Root));
