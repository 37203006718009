import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import { Link as IttsuLink } from 'react-ittsu/components';

import { buildUrl } from 'utils';


const Link = (props) => {
  const { children, raw, to, href, basePath, ...rest } = props;
  return (
    <IttsuLink underlined="none" {...rest} to={buildUrl(basePath, href || to)} component={raw ? undefined : RRLink}>
      {children}
    </IttsuLink>
  );
};


export default Link;
