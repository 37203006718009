import React from 'react';
import { T3 } from 'react-ittsu/components';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';

import { createTranslate } from '~/utils/translation';


const t = createTranslate('pods.order.services');


const styles = {
  title: css`
    text-align: center;
    margin-top: calc(${sv.baseMargin} / 2);
  `,
};


class Title extends React.Component {
  render() {
    const { label } = this.props;
    return (
      <div className={styles.title}>
        <T3 brand={true} style={{ margin: 0 }}>{t(label, null, label)}</T3>
      </div>
    );
  }
}


export default Title;
