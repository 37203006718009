import { IconType } from '@drawbotics/react-drylus';

import { ServiceEnum } from '../types';

export function serviceToIcon(slug: ServiceEnum): IconType {
  if (slug === ServiceEnum.PHOTO_SHOOTING) {
    return 'photo-shoot';
  }
  if (slug === ServiceEnum.VIDEO_SHOOTING) {
    return 'video-shoot';
  }
  return slug.replace('_', '-') as IconType;
}
