import React from 'react';
import { Grid, Cell } from 'react-ittsu/layout';
import PropTypes from 'prop-types';
import gql from 'fraql';
import autobind from 'autobind-decorator';
import { css, cx } from 'emotion';
import sv from '@drawbotics/style-vars';

import Title from '../components/Title';
import MainPanel from '../components/MainPanel';
import OrderOption from '../components/OrderOption';
import ErrorMessage from '../components/ErrorMessage';
import { EstateEnums } from '../utils/estate-enums';
import { createTranslate, translate as t } from '~/utils/translation';

import relaxedIcon from '../images/icons/relaxed-icon.svg';
import standardIcon from '../images/icons/standard-icon.svg';
import expressIcon from '../images/icons/express-icon.svg';


const tt = createTranslate('pods.order.routes.estate_delivery');


const styles = {
  gridWrapper: css`
    @media ${sv.phoneXl} {
      overflow: scroll;
      width: calc(100vw - 40px);
      padding: 15px;
    }
  `,
  grid: css`
    @media ${sv.phoneXl} {
      width: calc(100vw + 120px);
      margin-left: calc((${sv.baseMarginSmall} + ${sv.baseMargin}) * -1);
    }
  `,
  productsTable: css`
    width: 100%;
    font-size: 0.9em;
  `,
  productsTableHeader: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    border-bottom: 1px solid ${sv.brandPrimary};
    color: ${sv.textSecondaryDark};

    @media ${sv.phoneXl} {
      font-size: 0.7rem;
    }
  `,
  productsTableRow: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    border-bottom: 1px solid ${sv.grey100};
  `,
  firstColumn: css`
    text-align: left;
    justify-content: flex-start;
    color: ${sv.textPrimaryDark};
  `,
  productsTableColumn: css`
    width: 25%;
    text-align: center;
    padding: ${sv.basePaddingSmall};
    display: flex;
    align-items: center;
    justify-content: center;

    > span:nth-child(1) {
      font-size: 2em;
    }

    > span:nth-child(2) {
      color: ${sv.textSecondaryDark};
      font-size: 0.8em;
      margin-left: 5px;
    }
  `,
};


class EstateDelivery extends React.Component {

  static propTypes = {
    estate: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      delivery: PropTypes.string,
      items: PropTypes.array.isRequired,
    }).isRequired,
    updateEstate: PropTypes.func.isRequired,
  };

  static fragments = {
    Delivery: () => gql`
      fragment _ on Estate {
        id
        delivery
        items {
          ${EstateDelivery.fragments.Item()}
        }
      },
    `,
    Item: () => gql`
      fragment _ on Item {
        id
        price @client(type: Item)
        service {
          id @client(type: ProductService)
        }
        product {
          id @client(type: Product)
          name
          custom
          durations {
            delivery
            preview
            correction
          }
        }
      }
    `,
  };

  state = {
    showError: false,
  };

  componentDidMount() {
    this.grid.scrollLeft = 130 / 2;
  }

  render() {
    const { estate, goBack } = this.props;
    const { showError } = this.state;
    const chosenProducts = Object.values(estate.items.reduce((memo, item) => {
      return {
        ...memo,
        [item.product.id]: item.product,
      };
    }, {}))
      .slice()
      .sort((a, b) => a.id.localeCompare(b.id));
    return (
      <MainPanel
        onClickBack={goBack}
        onClickNext={this._goNext}>
        <Title>{tt('select_delivery')}</Title>
        {do {
          if (showError) {
            <ErrorMessage>
              {tt('errors.select_delivery')}
            </ErrorMessage>
          }
        }}
        <div className={styles.gridWrapper} ref={(grid) => this.grid = grid}>
          <Grid withHGutters className={styles.grid}>
            <Cell size="1of3">
              <OrderOption
                image={relaxedIcon}
                title={tt('relaxed')}
                checked={estate.delivery === EstateEnums.Delivery.RELAXED}
                onClick={() => this._updateDelivery(EstateEnums.Delivery.RELAXED)}>
                {tt('relaxed_discount')}
              </OrderOption>
            </Cell>
            <Cell size="1of3">
              <OrderOption
                image={standardIcon}
                title={tt('standard')}
                checked={estate.delivery === EstateEnums.Delivery.STANDARD}
                onClick={() => this._updateDelivery(EstateEnums.Delivery.STANDARD)}>
              </OrderOption>
            </Cell>
            <Cell size="1of3">
              <OrderOption
                image={expressIcon}
                title={tt('express')}
                checked={estate.delivery === EstateEnums.Delivery.EXPRESS}
                onClick={() => this._updateDelivery(EstateEnums.Delivery.EXPRESS)}>
                {tt('express_markup')}
              </OrderOption>
            </Cell>
          </Grid>
        </div>
        {do{
          if (estate.delivery) {
            <div className={styles.productsTable}>
              <div className={styles.productsTableHeader}>
                <div className={styles.productsTableColumn}></div>
                <div className={styles.productsTableColumn}>
                  {tt('time_first_preview')}
                </div>
                <div className={styles.productsTableColumn}>
                  {tt('time_correction_round')}
                </div>
              </div>
              {chosenProducts.map((product) => (
                <div key={product.id} className={styles.productsTableRow}>
                  <div className={cx(styles.productsTableColumn, styles.firstColumn)}>
                    {product.custom ? product.name : t(`products.${product.id}`)}
                  </div>
                  <div className={styles.productsTableColumn}>
                    <span>{this._getHumanizedDelivery(product, estate.delivery, 'preview')}</span>
                    <span>{tt('working_days')}</span>
                  </div>
                  <div className={styles.productsTableColumn}>
                    <span>{this._getHumanizedDelivery(product, estate.delivery, 'correction')}</span>
                    <span>{tt('working_days')}</span>
                  </div>
                </div>
              ))}
            </div>
          }
        }}
      </MainPanel>
    );
  }

  @autobind
  async _updateDelivery(delivery) {
    const { updateEstate, estate, modifyService } = this.props;
    const updatedEstate = await updateEstate({ delivery });
    if (updatedEstate.delivery !== delivery) {
      console.error('Something happened when updating the delivery');
    }
    else {
      this.setState({ showError: false });
      modifyService({ update: estate.items, add: [], remove: [] });
    }
  }

  @autobind
  _goNext() {
    const { estate, goNext } = this.props;
    estate.delivery ? goNext() : this.setState({ showError: true });
  }

  _getHumanizedDelivery(product, delivery, stage) {
    const durationsForDelivery = product.durations.find((d) => d.delivery === delivery) || {};
    const hours = durationsForDelivery[stage]
    const days = hours / 24;
    return days < 1 ? 1 : Math.ceil(days);
  }
}


export default EstateDelivery;
