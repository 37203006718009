import get from 'lodash/get';
import startCase from 'lodash/startCase';
import cache from 'memory-cache';

import { buildUrl } from 'utils';
import { createNetworkError } from 'utils/errors';
import { readSession } from '../index';


export const API_HOST = process.env.API_HOST;
export const API_BASEPATH = '/api/frontoffice';
export const API_VERSION = 'v1';
export const ANALYTICS_HOST = process.env.ANALYTICS_HOST;


export function extractResource(response) {
  if (response.resource) {
    return response.resource;
  }
  return response;
}


export function delay(ms) {
  if (process.env.NODE_ENV === 'development') {
    return (value) => new Promise((resolve) => {
      setTimeout(() => resolve(value), ms);
    });
  }
  return (value) => value;
}


export function log(value) {
  // eslint-disable-next-line
  console.log(value);
  return value;
}


export function request(method, url, data, host) {
  let finalUrl = '';
  if (url.startsWith('http')) {
    finalUrl = url;
  }
  else if (host === 'analytics') {
    finalUrl = buildUrl(ANALYTICS_HOST, url);
  }
  else {
    finalUrl = url.startsWith('/') ?
      buildUrl(API_HOST, url) : buildUrl(API_HOST, API_BASEPATH, API_VERSION, url);
  }
  const jsonData = JSON.stringify(data);
  const session = readSession();
  const authToken = get(session, 'authToken', '');
  const cachedData = cache.get(url);
  if (cachedData && method === 'GET') {
    return new Promise((resolve) => {
      resolve(cachedData);
    });
  }
  if (method !== 'GET') {
    cache.clear();
  }
  return fetch(finalUrl, {
    method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      ...(authToken != null && authToken.length < 30 ? { 'Authorization': `Token token=${authToken}` } : {}),
      ...(authToken != null && authToken.length > 30 ? { 'Authorization': `Bearer ${authToken}` } : {}),
    },
    body: jsonData,
    credentials: 'same-origin',
  })
    .then((response) => {
      return response.status.toString().startsWith('5') ?
        [{}, response.status] : Promise.all([response.json(), Promise.resolve(response.status)]);
    })
    .then(([ body, status ]) => [ extractResource(body), status ])
    .then(([ body, status ]) => {
      if (status.toString().startsWith('2')) {
        cache.put(url, body, 120 * 1000);
        return body;
      }
      const request = { data, url: finalUrl, method };
      const error = createNetworkError(status, body, request);
      throw error;
    });
}


export function cleanFileName(fileName) {
  const cleaned = fileName.replace('./', '').replace('.js', '');
  return startCase(cleaned).replace(' ', '');  // Return the cleaned string capitalized
}
