function nonEmptyJoin(strings, character) {
  return strings.filter((s) => !! s).join(character);
}


function createFindContext(context) {
  return (id) => context?.find((c) => c.id.includes(id))?.text;
}


export function mapboxFeatureToAddress(feature) {
  const { address: streetNumber, context, text, matching_text: matchingText } = feature;
  const findText = createFindContext(context);
  const postcode = findText('postcode');
  const country = findText('country');
  const locality = findText('locality');
  const city = findText('place');

  const address = nonEmptyJoin([streetNumber, matchingText || text], ' ');
  const district = nonEmptyJoin([postcode, locality], ' ');
  return nonEmptyJoin([address, district, city, country], ', ');
}


export function mapboxFeatureToAddressParts(feature) {
  const { address: streetNumber, context, text, matching_text: matchingText } = feature;
  const findText = createFindContext(context);
  const street = nonEmptyJoin([streetNumber, matchingText || text], ' ');
  const zipCode = findText('postcode');
  const city = findText('place');
  const countryCode = feature.context?.find((c) => c.id.startsWith('country')).short_code.toUpperCase();

  return { street, zipCode, city, countryCode };
}
