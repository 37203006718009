export default (request, entities, translators) => ({
  create(address) {
    const organization = address.organization;
    const data = translators.Address.toServer(address);
    return request('POST', `organisations/${organization}/addresses`, data)
      // .then((response) => response.resource)
      .then((address) => ({ ...address, organisation: organization }))
      .then(translators.Address.fromServer);
  },
  delete(address) {
    const organization = address.organization.id ? address.organization.id : address.organization;
    return request('DELETE', `organisations/${organization}/addresses/${address.id}`);
  },
  update(address) {
    const organization = address.organization.id ? address.organization.id : address.organization;
    const data = translators.Address.toServer(address);
    return request('PUT', `organisations/${organization}/addresses/${address.id}`, data)
      // .then((response) => response.resource)
      .then(translators.Address.fromServer);
  },
});
