import React from 'react';
import { css, cx } from 'emotion';
import sv from '@drawbotics/style-vars';

import { createTranslate } from '~/utils/translation';


const t = createTranslate('pods.order.components.briefing_details');


const styles = {
  views: css`
  `,
  header: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  group: css`
    padding: ${sv.basePaddingSmall};
    color: ${sv.textPrimaryDark};
    font-size: 0.9em;
    margin-top: ${sv.baseMarginSmall};
    border-radius: ${sv.baseBorderRadius};
    border: 2px solid ${sv.grey200};
  `,
  open: css`
    pointer-events: all !important;
    color: ${sv.brandBlue};

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  `,
  content: css`
    display: none;
    margin-top: ${sv.baseMargin};
  `,
  visible: css`
    display: block;
  `,
};


class Views extends React.Component {
  state = {
    viewOpen: null,
  }

  render() {
    const { viewOpen } = this.state;
    const { components } = this.props;
    return (
      <div className={styles.views}>
        {components.map((group, j) => (
          <div className={styles.group} key={j}>
            <div className={styles.header}>
              <div>
                {`${t('image')} ${j + 1}`}
              </div>
              <div className={styles.open} onClick={() => this.setState({ viewOpen: viewOpen === j ? null : j })}>
                {viewOpen === j ? t('close_details') : t('open_details')}
              </div>
            </div>
            <div className={cx(styles.content, { [styles.visible]: viewOpen === j })}>
              {group.map((component, i) => (
                <component.type {...component.props} key={i} />
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }
}


export default Views;
