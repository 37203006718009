import gql from 'graphql-tag';

export const ProjectsQuery = gql`
  query ProjectsQuery($first: Int, $skip: Int, $filters: EstateFilterInput!, $orderBy: SortByEnum) {
    projects: estates(first: $first, skip: $skip, filters: $filters, orderBy: $orderBy) {
      id
      name
      briefingConfirmedAt
      problems {
        id
      }
      items {
        id
        status
        briefingConfirmedAt
        problems {
          id
        }
      }
      paymentProcess {
        invoices {
          status
        }
      }
    }
  }
`;
