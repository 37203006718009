import { Add, RelativeAdd, Switch } from 'drawbotics-pricer';


const description = {
  Base: [
    Add({ EUR: 480, GBP: 440, USD: 580 }),
  ],
  Delivery: [
    Switch('estate.delivery').is({
      'relaxed': RelativeAdd(-0.1).from('Base'),
      'standard': RelativeAdd(0).from('Base'),
      'express': RelativeAdd(0.5).from('Base'),
    })
  ],
};


export default description;
