const k = (key) => `site_plan.briefing.${key}`;


const residential = {
  1: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('options'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('options_note'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'MultiSelect',
        key: 'options',
        keyLabel: k('keys.options'),
        values: [
          {
            image: require('../images/site-plan/options/street-names.jpg'),
            value: 'street_names',
            label: k('street_names'),
          },
          {
            image: require('../images/site-plan/options/building-names.jpg'),
            value: 'building_names',
            label: k('building_names'),
          },
          {
            image: require('../images/site-plan/options/entrances.jpg'),
            value: 'entrances',
            label: k('entrances'),
          },
          {
            image: require('../images/site-plan/options/wind-rose.jpg'),
            value: 'wind_rose',
            label: k('wind_rose'),
          },
        ],
      },
    ],
  },
  2: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: k('style'),
        component: 'Title',
      },
      {
        type: 'component',
        label: k('style_note'),
        component: 'Note',
      },
      {
        type: 'question-component',
        component: 'SingleSelect',
        key: 'surroundingsStyle',
        keyLabel: k('keys.surroundings_style'),
        values: [
          {
            value: 'map_integration',
            label: k('map_integration'),
            image: require('../images/site-plan/options/3.jpg'),
          },
          {
            value: 'clay',
            label: k('clay'),
            image: require('../images/site-plan/options/1.jpg'),
          },
        ],
      },
    ],
  },
  3: {
    type: 'section',
    components: [
      {
        type: 'component',
        label: 'shared.additional_info',
        component: 'Title',
      }, {
        type: 'component',
        label: 'shared.anything_to_add',
        component: 'Note',
      }, {
        type: 'question-component',
        label: 'shared.additional_comments',
        component: 'Input',
        key: 'otherInfo',
        keyLabel: 'shared.keys.other_info',
      }, {
        type: 'question-component',
        label: 'shared.upload_files',
        component: 'FileUploader',
        key: 'otherInfoAttachments',
        keyLabel: 'shared.keys.other_info_attachments',
      },
    ],
  },
};


export default { residential };
