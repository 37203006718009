import React from 'react';
import autobind from 'autobind-decorator';
import { Grid, Cell } from 'react-ittsu/layout';
import gql from 'fraql';

import BaseServiceModal from '../BaseServiceModal';
import Paragraph from '../Paragraph';
import SectionTitle from '../SectionTitle';
import { ProductCard, Stripe, StripeGroup } from '../../forms';
import { priceItem, applyPrice } from '../../../utils/pricing';
import { specsToItems, itemsToSpecs } from '~/pods/order/utils/services/specs-to-items';
import applyChanges from '~/pods/order/utils/services/specs-to-items/apply-changes';
import { getItemsByService, merge, getFeaturesForProduct, openIntercom } from '~/pods/order/utils';
import { createTranslate, translate as t } from '~/utils/translation';
import { EstateEnums } from '~/pods/order/utils/estate-enums';

import serviceImages from '~/pods/order/images/services';


const tt = createTranslate('pods.order.services.interior_3d.order');
const tm = createTranslate('pods.order.components.service_modal');


class Interior3D extends React.Component {

  static fragments = {
    Item: gql`
      fragment _ on Item {
        id
        details {
          ... on Interior3DDetails {
            droneShooting
          }
        }
      }
    `,
  }

  state = {
    custom: null,
    specs: {
      droneShooting: false,
      standard: 0,
    },
    price: undefined,
  };

  componentDidUpdate(prevProps) {
    const { specs } = this.state;
    const { service, estate } = this.props;
    const { service: prevService } = prevProps;
    if (service?.id === 'interior3d' && ! prevService) {
      const newSpecs = itemsToSpecs({ estate, service });
      const items = getItemsByService(estate).interior3d;
      const customItem = items?.find((item) => item.product.custom);
      const price = items != null ? items.reduce((memo, i) => memo + priceItem(i, this.props.sessionUser.currency.code, true), 0) : 0;
      this.setState({ specs: merge(specs, newSpecs), price, custom: customItem ? true : null });
    }
  }

  render() {
    const { onClickClose, isVisible, service, estate, sessionUser } = this.props;
    const { specs: { standard, droneShooting }, price, custom } = this.state;
    const { interior3d } = serviceImages;
    const items = getItemsByService(estate).interior3d || [];
    const customItem = items.find((item) => item.product.custom);
    const customFeatures = custom !== null ? getFeaturesForProduct(customItem?.product) : null;
    const isResidential = estate.projectType === EstateEnums.ProjectType.RESIDENTIAL;
    return (
      <BaseServiceModal
        isVisible={isVisible && service?.id === 'interior3d'}
        title={service?.id ? t(`services.${service.id}`) : ''}
        images={Object.values(interior3d)}
        price={sessionUser.addCurrency(price)}
        onClickConfirm={this._handleClickConfirm}
        confirmAsUpdate={items?.length > 0}
        priceDisclaimer={{
          label: tt('price_disclaimer.text'),
          description: tt('price_disclaimer.info'),
        }}
        onClickClose={onClickClose}
        addDisabled={price === 0}>
        <div className="childcontent">
          <Paragraph>
            {tt('description')}
          </Paragraph>
          <SectionTitle>
            {tt('select_number')}
          </SectionTitle>
          <Grid withVGutters withHGutters>
            <Cell size="2of4">
              <ProductCard
                name="standard"
                label={tm('standard')}
                note={tm('from_price', { price: `${applyPrice(isResidential ? { EUR: 700, USD: 900, GBP: 650 } : { EUR: 800, USD: 1050, GBP: 750 }, sessionUser)}/img` })}
                fullHeight
                formComponent="checkbox"
                features={[
                  {
                    text: tt('features.first'),
                  },
                  {
                    text: tt('features.third.text'),
                    infoText: tt('features.third.tooltip'),
                  },
                ]}
                value={standard}
                onChange={this._handleClickStandard}
                withCounter />
            </Cell>
            <Cell size="1of2">
              <ProductCard
                name="custom"
                label={custom !== null ? `${customItem?.product.name}` : tm('custom')}
                note={custom !== null ? `${sessionUser.addCurrency(customItem?.price)}` : tm('contact_us')}
                formComponent="checkbox"
                fullHeight
                features={customFeatures}
                onChange={this._handleClickCustom}
                value={!! custom} />
            </Cell>
          </Grid>
          <SectionTitle>
            {tt('order_drone_shooting')}
          </SectionTitle>
          <StripeGroup>
            <Stripe
              title={tt('drone_shooting')}
              subtitle={tm('separate_service')}
              component="switch"
              features={[{
                text: tt('drone_shooting_features.first'),
              }]}
              disabled={standard === 0}
              value={droneShooting}
              onChange={() => this.setState({ specs: { ...this.state.specs, droneShooting: ! droneShooting } }, this._syncState)} />
          </StripeGroup>
        </div>
      </BaseServiceModal>
    );
  }

  @autobind
  _syncState() {
    const { estate, service } = this.props;
    const { specs } = this.state;
    const changes = specsToItems({ specs, estate, service });
    const updatedEstate = applyChanges(changes, estate);
    const items = getItemsByService(updatedEstate).interior3d;
    const price = items != null
      ? items
          .map((item) => item.estate != null ? item : { ...item, estate: updatedEstate })
          .reduce((memo, i) => memo + priceItem(i, this.props.sessionUser.currency.code, true), 0)
      : 0;
    this.setState({ price });
    return { changes, updatedEstate, price };
  }

  @autobind
  _handleClickStandard(value) {
    const { specs: { standard, droneShooting } } = this.state;
    const newValue = value ? value : (standard === 0 ? 1 : 0);
    this.setState({
      specs: {
        standard: newValue,
        droneShooting: newValue === 0 ? false : droneShooting,
      }
    }, this._syncState);
  }

  @autobind
  _handleClickCustom(value) {
    const { custom } = this.state;
    if (custom === null) {
      // open intercom
      openIntercom();
    }
    else {
      this.setState({ custom: ! custom });
    }
  }

  @autobind
  _handleClickConfirm() {
    const { onModifyService, service } = this.props;
    const { changes } = this._syncState();
    onModifyService(changes, service);
  }
}


export default Interior3D;
