import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { Icon, Button, Paragraph } from 'react-ittsu/components';
import gql from 'fraql';
import queryString from 'query-string';
import sv from '@drawbotics/style-vars';
import { cx } from 'emotion';
import QuestionsGenerator from 'questions-generator';
import compose from 'lodash/flowRight';
import dayjs from 'dayjs';

import { withMedia } from '~/utils/media-provider';
import { getItemsByService, deepOmit, getRequiredAttachmentsForProduct, addNoteToCustomDescription } from '../../utils';
import { descriptions, components as questionsComponents } from '../../utils/briefing/item-to-questions';
import ItemDetails, { RevoDefaultDetails, SitePlanDefaultDetails } from '../../utils/briefing/item-details';
import filterEstateAttachments from '../../utils/briefing/filter-estate-attachments';
import BriefingDetails from '../../components/BriefingDetails';
import ConfirmModal from '../../components/ConfirmModal';
import { createTranslate, translate as t } from '~/utils/translation';
import Title from '../../components/Title';

import styles from './styles';

import images from '~/images/services-illustrations';


const tt = createTranslate('pods.order.routes.briefing_item');


class BriefingItem extends React.Component {

  static propTypes = {
    estate: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        service: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
      })),
    }),
    updateItem: PropTypes.func.isRequired,
  };

  static fragments = {
    Item: () => gql`
      fragment _ on Item {
        id
        briefingConfirmedAt
        briefingConfirmator {
          firstName
          lastName
        }
        reference
        product {
          id @client(type: Product)
          slug
          custom
          name
          requiredAttachments {
            content
            locale
          }
        }
        milestones {
          id
          name
        }
        service {
          id @client(type: ProductService)
          slug
        }
        jsonMeta @client(type: Item)
        ${ItemDetails}
      }
    `,
    Estate: () => gql`
      fragment _ on Estate {
        id
        projectType
        address {
          id
          latitude
          longitude
          countryCode
        }
        itemsByService @client(type: Estate)
        items {
          id
          ${BriefingItem.fragments.Item()}
        }
      }
    `,
  };

  state = {
    coverStyle: null,
    coverCanTransition: false,
    showQuestions: false,
    confirmModalOpen: false,
  }

  componentDidMount() {
    const { history, location, estate, match } = this.props;
    const { rect } = history.location.state || {};
    const item = estate.items.find((i) => i.id == match.params.itemId);
    const briefingNotStarted = item.briefingConfirmedAt != null ? false : (! item.jsonMeta.briefingOngoing && ! item.jsonMeta.briefingCompleted);

    if (briefingNotStarted) {
      this._handleInitBriefing();
    }

    this._calculateCoverStyle(rect);
    const questionsShouldOpen = !! queryString.parse(location.search)?.step || briefingNotStarted;
    this.setState({ showQuestions: questionsShouldOpen });
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps, prevState) {
    const { coverStyle } = this.state;
    const { coverStyle: prevCoverStyle } = prevState;
    if (!! coverStyle && ! prevCoverStyle) {
      setTimeout(() => { this.setState({ coverStyle: null, coverCanTransition: true }) }, 0);
    }
  }

  render() {
    const { match, estate, location, history, mediaSize } = this.props;
    const { coverStyle, coverCanTransition, showQuestions, confirmModalOpen } = this.state;
    const item = estate.items.find((i) => i.id == match.params.itemId);
    const query = queryString.parse(location.search);
    const description = descriptions[item.product.custom ? 'customProduct' : item.service.id];
    const descriptionForItem = description[estate.projectType.toLowerCase()] || description.residential;
    const filteredDescription = filterEstateAttachments(estate, descriptionForItem);
    const itemTitle = item.product.custom ? item.product.name : t(`services.${item.service.id}`);
    const detailsConfirmed = !! item.briefingConfirmedAt;
    const underReview = !item.milestones.find((milestone) => milestone.name === 'accepted') && detailsConfirmed;
    return (
      <div className={styles.briefingItem} ref={(pageContainer) => this.pageContainer = pageContainer}>
        <div
          className={cx(styles.cover, { [styles.coverWithTransition]: coverCanTransition, [styles.confirmed]: detailsConfirmed, [styles.review]: underReview })}
          style={{ ...coverStyle, backgroundImage: `url(${images[item.service.id]})` }}>
          <div className={styles.coverContent}>
            <div className={styles.back} onClick={this._handleGoBack}>
              <Icon name="caret-left-bold" size="lg" />
              <div className={styles.backLabel}>
                {tt('back_to_overview')}
              </div>
            </div>
            <div className={styles.coverTitle}>
              {do{
                if (underReview) {
                  <div>
                    <Icon bold name="clock" vOffset={-5} />
                    <div>
                      {tt('briefing_under_review')}
                    </div>
                  </div>
                }
                else if (detailsConfirmed) {
                  <div>
                    <Icon name="check-bold" vOffset={-5} />
                    <div>
                      {tt('confirmed_at', {
                        item: itemTitle,
                        name: `${item.briefingConfirmator.firstName} ${item.briefingConfirmator.lastName}`,
                        date: dayjs(item.briefingConfirmedAt).format('DD/MM/YYYY'),
                      })}
                    </div>
                  </div>
                }
                else {
                  <span>
                    {itemTitle}
                    <br />
                    ({tt('item_reference', { reference: item.reference })})
                  </span>
                }
              }}
            </div>
          </div>
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            <div className={styles.checksContainer}>
              <div className={cx(styles.checkcircle, { [styles.checked]: !underReview && detailsConfirmed })}>
                <Icon name="check-bold" />
              </div>
            </div>
            {do{
              if (item.jsonMeta?.briefingCompleted) {
                <Title>{tt('statuses.completed_title')}</Title>
              }
            }}
            <Paragraph className={styles.subtitle}>
              {do{
                if (detailsConfirmed) tt('statuses.confirmed')
                else if (item.jsonMeta?.briefingCompleted) tt('statuses.completed')
                else if (item.jsonMeta?.briefingOngoing) tt('statuses.ongoing')
                else tt('statuses.not_started')
              }}
            </Paragraph>
            {do{
              if (item.jsonMeta?.briefingCompleted) {
                <Fragment>
                  <BriefingDetails estate={estate} details={item.details} sections={filteredDescription} />
                  {do{
                    if (! detailsConfirmed) {
                      <div className={styles.actions}>
                        <Button
                          size={mediaSize.isMobile ? 'small' : null}
                          round
                          onClick={() => this.setState({ showQuestions: true })}
                          style={{ marginRight: sv.baseMarginSmall }}>{tt('modify_briefing')}</Button>
                        <Button
                          size={mediaSize.isMobile ? 'small' : null}
                          category="success"
                          round
                          onClick={() => this.setState({ confirmModalOpen: true })}>{tt('confirm_briefing')}</Button>
                      </div>
                    }
                  }}
                </Fragment>
              }
              else if (item.jsonMeta?.briefingOngoing) {
                <Fragment>
                  <BriefingDetails estate={estate} details={item.details} sections={filteredDescription} />
                  <div className={styles.intro}>
                    <Button
                      round
                      category="primary"
                      onClick={() => this.setState({ showQuestions: true })}
                      style={{ marginRight: sv.baseMarginSmall }}>{tt('continue_briefing')}</Button>
                  </div>
                </Fragment>
              }
              else {
                <div className={styles.intro}>
                  <Button
                    category="primary"
                    round
                    variation="inverse"
                    onClick={async () => {
                      await this._handleInitBriefing();
                      this.setState({ showQuestions: true });
                    }}>{tt('start_briefing')}</Button>
                </div>
              }
            }}
          </div>
          {do{
            if (detailsConfirmed) {
              <div className={styles.backButton}>
                <Button
                  size={mediaSize.isMobile ? 'small' : null}
                  category="primary"
                  onClick={this._handleGoBack}
                  style={{ marginRight: sv.baseMarginSmall }}>{tt('back_to_overview')}</Button>
              </div>
            }
          }}
        </div>
        <QuestionsGenerator
          visible={showQuestions}
          history={history}
          query={query}
          estate={estate}
          values={item.details}
          completed={item.jsonMeta?.briefingCompleted}
          components={questionsComponents}
          description={item.product.custom ? addNoteToCustomDescription(getRequiredAttachmentsForProduct(item.product), filteredDescription): filteredDescription}
          onGoNext={this._handleUpdateBriefing}
          onFinish={this._handleCompleteBriefing}
          onClickClose={() => {
            if (item.jsonMeta.briefingOngoing || item.jsonMeta.briefingCompleted) {
              this.setState({ showQuestions: false });
            }
            else {
              document.body.style.overflow = 'initial'; // Prevent preserving style from modal
              this._handleGoBack();
            }
          }} />
        <ConfirmModal
          onClickSave={() => this.setState({ confirmModalOpen: false })}
          onClickConfirm={this._handleConfirmBriefing}
          onClickClose={() => this.setState({ confirmModalOpen: false })}
          visible={confirmModalOpen} />
      </div>
    );
  }

  @autobind
  _handleGoBack() {
    const { estate, goBack, match } = this.props;
    const { serviceType } = match.params;
    const itemsByService = getItemsByService(estate);
    const skipServices = itemsByService[serviceType].length === 1;
    goBack(serviceType, { skipServices });
  }

  @autobind
  _calculateCoverStyle(cardCoverStyle) {
    const { coverStyle } = this.state;
    if (! cardCoverStyle || !! coverStyle) return null;
    const containerStyle = this.pageContainer.getBoundingClientRect();
    const style = {
      height: cardCoverStyle.height,
      width: cardCoverStyle.width,
      left: cardCoverStyle.left - containerStyle.left,
      top: cardCoverStyle.top - containerStyle.top,
    };
    this.setState({ coverStyle: style });
  }

  @autobind
  async _handleUpdateBriefing(result) {
    const { updateItem, estate, match } = this.props;
    const item = estate.items.find((i) => i.id == match.params.itemId);
    const { views, ...details } = result;
    await updateItem({
      id: item.id,
      details: deepOmit(details, ['__typename', 'filename', 'url']),
      metadata: JSON.stringify({
        briefingOngoing: item.jsonMeta.briefingCompleted ? false : true,
        briefingCompleted: item.jsonMeta.briefingCompleted,
      }),
      views: views ? deepOmit(views, ['__typename', 'filename', 'url']) : null,
      serviceId: item.service.id,
      custom: item.product.custom,
      productSlug: item.product.slug,
    });
  }

  @autobind
  async _handleCompleteBriefing(result) {
    const { updateItem, estate, match } = this.props;
    const item = estate.items.find((i) => i.id == match.params.itemId);
    const { views, ...details } = result;
    await updateItem({
      id: item.id,
      details: deepOmit(details, ['__typename', 'filename', 'url']),
      metadata: JSON.stringify({ briefingCompleted: true, briefingOngoing: false }),
      serviceId: item.service.id,
      custom: item.product.custom,
      productSlug: item.product.slug,
      views: views ? deepOmit(views, ['__typename', 'filename', 'url']) : null,
    });
    this.setState({ showQuestions: false });
  }

  @autobind
  async _handleConfirmBriefing() {
    const { confirmBriefing, estate, match } = this.props;
    const item = estate.items.find((i) => i.id == match.params.itemId);
    await confirmBriefing(item);
    this.setState({ confirmModalOpen: false }, this._handleGoBack);
  }

  @autobind
  async _handleInitBriefing() {
    const { updateItem, match, estate } = this.props;
    const item = estate.items.find((i) => i.id == match.params.itemId);

    if (item.service.id === 'revo') {
      const typology = do {
        if (estate.projectType === 'commercial') {
          'workstations';
        }
        else if (estate.address.countryCode === 'FR') {
          'french_typology';
        }
        else {
          'rooms';
        }
      }

      await updateItem({
        id: item.id,
        details: { ...RevoDefaultDetails, typology },
        productSlug: item.product.slug,
        serviceId: item.service.id,
        custom: item.product.custom,
      });
    }
    else if (item.service.id === 'sitePlan') {
      await updateItem({
        id: item.id,
        details: SitePlanDefaultDetails,
        productSlug: item.product.slug,
        serviceId: item.service.id,
        custom: item.product.custom,
      });
    }
  }
}


export default compose(withMedia())(BriefingItem);
