import React from 'react';
import Modal from '@drawbotics/modal';
import { css } from 'emotion';
import sv from '@drawbotics/style-vars';


const styles = {
  warningModal: css`
    background: ${sv.white};
    padding: ${sv.basePaddingSmall};
    border-radius: ${sv.baseBorderRadius};
    margin: ${sv.baseMarginSmall};
  `,
  icon: css`
    text-align: center;
    font-size: 2.7em;
    color: ${sv.brandRed};
  `,
  content: css`
    padding: ${sv.basePaddingSmall};
  `,
};


const WarningModal = ({ visible, isVisible, children }) => {
  return (
    <Modal isVisible={visible || isVisible}>
      <div className={styles.warningModal}>
        <div className={styles.icon}>
          <i className="ion-alert-circled" />
        </div>
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </Modal>
  );
}


export default WarningModal;
