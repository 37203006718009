import gql from 'graphql-tag';

export const CreateInvitation = gql`
  mutation CreateInvitation($invitation: CreateInvitationInput!) {
    createInvitation(input: $invitation) {
      invitation {
        id
        firstName
        lastName
        role
        email
        status
      }
    }
  }
`;
