import { request } from '~/utils/api/utils';
import { attachmentToServer } from './translators';


export const Attachment = {
  async create(data) {
    const entity = data.toEntity ? data.toEntity() : data;
    const attachment = attachmentToServer(entity);
    const response = await request('POST', `attachments`, attachment);
    return response.message.attachment[0];
  },
};
