import sv from '@drawbotics/drylus-style-vars';
import {
  Badge,
  Button,
  Color,
  Content,
  Flex,
  FlexItem,
  FlexJustify,
  FlexSpacer,
  Icon,
  LoadingPlaceholder,
  Margin,
  Padding,
  Size,
  Spinner,
  TabMenu,
  TabMenuLink,
  Text,
  Tier,
  Title,
} from '@drawbotics/react-drylus';
import { css } from 'emotion';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { HrefLink } from '~/components';
import { createTranslate, translate as t } from '~/utils/translation';

import { Item, ItemStatus, PaymentDocumentStatus } from '../types';
import { camelize, useFetchBasicEstate } from '../utils';

const tt = createTranslate('pods.dashboard.components.estate_header');

const styles = {
  estateHeader: css`
    background: ${sv.white};
    box-shadow: ${sv.elevation2};
  `,
};

interface EstateHeaderProps extends RouteComponentProps<{ itemId?: string }> {
  estateId: string;
  item?: Item;
}

export const EstateHeader = withRouter(
  ({ estateId, history, item, match, location }: EstateHeaderProps) => {
    const { isLoading, estate } = useFetchBasicEstate(estateId);
    const isItemPage = match.params.itemId != null;

    // Production related actions
    const itemActionsCount =
      estate?.items.reduce(
        (memo, item) =>
          item.status === ItemStatus.PROBLEM ||
          item.status === ItemStatus.AWAITING ||
          (item.briefingConfirmedAt == null &&
            ![ItemStatus.ONGOING, ItemStatus.ARCHIVED, ItemStatus.FINISHED].includes(item.status))
            ? memo + 1
            : memo,
        0,
      ) ?? 0;
    const estateActionsCount =
      ((estate?.problems.length ?? 0) > 0 ? 1 : 0) + (estate?.briefingConfirmedAt == null ? 1 : 0);
    const totalProductionActionsCount = itemActionsCount + estateActionsCount;

    // Billing related actions
    const billingActionsCount =
      estate?.paymentProcess?.invoices.reduce(
        (memo, invoice) =>
          invoice.status === PaymentDocumentStatus.LATE ||
          invoice.status === PaymentDocumentStatus.SENT
            ? memo + 1
            : memo,
        0,
      ) ?? 0;

    return (
      <div className={styles.estateHeader}>
        <Content
          style={{ background: 'transparent', boxShadow: `inset 0px -1px 0px ${sv.neutral}` }}>
          <Padding size={{ horizontal: Size.LARGE, vertical: Size.DEFAULT }}>
            <Flex justify={FlexJustify.SPACE_BETWEEN}>
              <FlexItem>
                <Flex>
                  <FlexItem>
                    <Button
                      onClick={() => {
                        if (isItemPage) {
                          history.push(
                            location.pathname.substring(0, location.pathname.lastIndexOf('/')),
                          );
                        } else {
                          history.push('/');
                        }
                      }}
                      leading={<Icon name="arrow-left" />}
                      tier={Tier.SECONDARY}>
                      {tt('back')}
                    </Button>
                  </FlexItem>
                  <FlexSpacer size={Size.DEFAULT} />
                  <FlexItem>
                    {isLoading ? (
                      <LoadingPlaceholder height={33} />
                    ) : (
                      <Title size={3} noMargin>
                        {estate?.name}
                      </Title>
                    )}
                  </FlexItem>
                  <FlexSpacer size={Size.EXTRA_SMALL} />
                  {item != null ? (
                    <FlexItem>
                      <Margin size={{ top: Size.EXTRA_SMALL }}>
                        <Text>/ {t(`services.${camelize(item.service.slug)}`)}</Text>
                      </Margin>
                    </FlexItem>
                  ) : null}
                </Flex>
              </FlexItem>
            </Flex>
          </Padding>
          <Padding size={{ horizontal: Size.LARGE }}>
            <TabMenu>
              <TabMenuLink
                href={`/projects/${estateId}/production`}
                linkComponent={HrefLink}
                active={location.pathname.includes('production')}
                text={tt('production')}
                trailing={
                  isLoading ? (
                    <Spinner size={Size.SMALL} />
                  ) : totalProductionActionsCount > 0 ? (
                    <Badge color={Color.RED} value={totalProductionActionsCount} />
                  ) : (
                    undefined
                  )
                }
              />
              <TabMenuLink
                href={`/projects/${estateId}/billing`}
                linkComponent={HrefLink}
                active={location.pathname.includes('billing')}
                text={tt('billing')}
                trailing={
                  isLoading ? (
                    <Spinner size={Size.SMALL} />
                  ) : billingActionsCount > 0 ? (
                    <Badge color={Color.RED} value={billingActionsCount} />
                  ) : (
                    undefined
                  )
                }
              />
              <TabMenuLink
                href={`/projects/${estateId}/notifications`}
                linkComponent={HrefLink}
                active={location.pathname.includes('notifications')}
                text={tt('notified_people')}
              />
            </TabMenu>
          </Padding>
        </Content>
      </div>
    );
  },
);
