import * as UIActions from 'pods/dashboard/actions/ui';
import * as ProjectsActions from 'pods/dashboard/actions/projects';


const initialState = {
  filter: 'active',
  sortBy: 'recent',
  search: '',
  page: 1,
  lastPage: false,
};


export default function ui(state=initialState, action) {
  switch(action.type) {
    case UIActions.SET_PROJECTS_FILTER: {
      const { filter } = action.payload;
      return {
        ...state,
        filter,
      };
    }
    case UIActions.SET_PROJECTS_SORT_BY: {
      const { sort } = action.payload;
      return {
        ...state,
        sortBy: sort,
      };
    }
    case UIActions.SET_PROJECTS_SEARCH: {
      const { search } = action.payload;
      return { ...state, search };
    }
    case UIActions.SET_PROJECTS_PAGE: {
      const { page } = action.payload;
      return { ...state, page };
    }
    case ProjectsActions.SET_PROJECTS_LAST_PAGE: {
      const { lastPage } = action.payload;
      return { ...state, lastPage };
    }
    default:
      return state;
  }
}
